import React, {Component} from 'react'
import PropTypes from "prop-types";
import {$$} from "../../../helpers/localization";
import CenteredModal from "../../shared/CenteredModal";
import {DOCUMENTS_TYPES, getResolvedOptions} from "../../../constants/select_options";
import Select from '../../shared/Select'
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormWithLoading from "../../shared/FormWithLoading";
import {connect} from "react-redux";
import classnames from 'classnames'
class DocumentAddEditModal extends Component {
    constructor(props) {
        super(props);
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if( this.props.documentType !== prevProps.documentType ||
            this.props.documentTitle !== prevProps.documentTitle ||
            this.props.typeDescription !== prevProps.typeDescription ||
            this.props.documentFileName !== prevProps.documentFileName
        ){
            if(this.props.formclass === "was-validated") {
                this.props.clearValidation()
                this.props.reValidate()
            }
        }
    }

    render() {
        return <div>
            <CenteredModal
                title={this.props.title }
                show={this.props.show}
                onHide={this.props.onHide}
                confirmBtnLabel={$$('save_btn_label')}
                cancelBtnLabel={$$('cancel_btn')}
                onConfirm={this.props.onSaveAddUpdateModal}>
                <FormWithLoading
                    formDisabled={this.props.formDisabled}
                    currentForm={this.props.currentForm}
                    marginTop="30%"
                    marginLeft="calc(50% - 70px)"
                    id="documentForm"
                    onSubmit={this.props.onSubmit}
                    className={this.props.className}
                    noValidate={true}>
                    {this.props.errorMsg}
                    <div className="row" style={{'marginTop': '4%'}}>
                        <div className="col-sm-12 col-md-12">
                            <div className="row form-group">
                                <div className="col-sm-6">
                                    <label
                                        style={{"textAlignLast": "center"}}>
                                        {$$("document_title")}</label>
                                    <input type="text"
                                           className="form-control"
                                           value={this.props.documentTitle || ''}
                                           placeholder={$$('document_title')}
                                           name="documentTitle"
                                           required
                                           onChange={this.props.onInputChange}
                                           onBlur={this.props.validate}
                                    />
                                    {this.props.formclass && <div
                                        className={this.props.errors?.title ? "custom-invalid-feedback" : "invalid-feedback"}
                                        style={{"textAlignLast": "center"}}>
                                        {this.props.errors?.title ? $$(this.props.errors?.title) : $$("document_title_required_message")}
                                    </div>}
                                </div>

                                <div className="col-sm-6">
                                    <Select
                                        name="type"
                                        label={$$("document_type_label")}
                                        options={getResolvedOptions(DOCUMENTS_TYPES.TYPE)}
                                        value={this.props.documentType}
                                        onChange={this.props.onSelectChange}
                                        placeholder={$$('type_label')}/>
                                </div>
                            </div>

                            {this.props.documentType === "OTHER" && <div className="form-group">
                                {this.props.typeDescription !== "" && <label>{$$("type_description_label")}</label>}
                                <input type="text" className="form-control"
                                       value={this.props.typeDescription}
                                       placeholder={$$('type_description_label')} name="typeDescription"
                                       onChange={this.props.onInputChange} required/>
                                {this.props.formclass === "was-validated" && <div
                                    className={this.props.errors?.typeDescription ? "custom-invalid-feedback" : "invalid-feedback"}
                                    style={{"textAlignLast": "center"}}>
                                    {this.props.errors?.typeDescription ? $$(this.props.errors.typeDescription) : $$("type_description_required_message")}
                                </div>}
                            </div>}

                            <div className="row form-group">

                                <div className='col-sm-6'>
                                    <MuiPickersUtilsProvider locale={this.props.locale}
                                                             utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            variant="inline"
                                            format={this.props.format}
                                            margin="normal"
                                            id="date-picker-inline"
                                            label={$$("log_symptoms_date_label")}
                                            value={this.props.documentDate}
                                            onChange={this.props.onDateChange}
                                            KeyboardButtonProps={{'aria-label': 'change date',}}
                                            required
                                            invalidDateMessage={$$('invalid_date_format')}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className='col-sm-6'>
                                    <MuiPickersUtilsProvider locale={this.props.locale}
                                                             utils={DateFnsUtils}>
                                        <KeyboardTimePicker
                                            ampm={this.props.ampm}
                                            margin="normal"
                                            id="time-picker"
                                            okLabel={$$("OK")}
                                            cancelLabel={$$("cancel_btn")}
                                            label={$$("log_symptoms_time_label")}
                                            value={this.props.documentTime}
                                            KeyboardButtonProps={{'aria-label': 'change time',}}
                                            onChange={this.props.onTimeChange}
                                            required
                                            invalidDateMessage={$$('invalid_time_format')}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div
                                    className={this.props.errors?.datetime ? "custom-invalid-feedback" : "invalid-feedback"}
                                    style={{"textAlignLast": "center"}}>
                                    {this.props.errors?.datetime ? $$(this.props.errors?.datetime) : $$("datetime_required_message")}
                                </div>


                            </div>
                            <label htmlFor="file">
                                <div className="form-control btn-primary text-nowrap w-100 pointer">
                                    <span className="flaticon-add"/>
                                    &nbsp;&nbsp;&nbsp;
                                    {$$("upload_document")}
                                </div>
                            </label>


                            <input type="file"
                                   name="file"
                                   id="file"
                                   className="dropzone-file-input"
                                   onChange={this.props.onFileChange}
                            />
                            <div>
                                <div
                                    className={classnames("form-control", {"is-invalid": this.props.errors?.file && this.props.formclass})}
                                    style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}>{this.props.documentFileName ? this.props.documentFileName : $$("no_file_chosen")}</div>
                            </div>
                            {!this.props.selectEntry && this.props.formclass &&
                                <div
                                    className={this.props.errors?.file ? "custom-invalid-feedback" : "invalid-feedback"}
                                    style={{"textAlignLast": "center"}}>
                                    {this.props.errors?.file ? $$(this.props.errors?.file) : $$("file_required_message")}
                                </div>
                            }
                        </div>
                    </div>
                </FormWithLoading>
            </CenteredModal>
        </div>
    }
}

DocumentAddEditModal.propTypes = {
    errors: PropTypes.any,
    selectEntry: PropTypes.any,
    format: PropTypes.any,
    title: PropTypes.any,
    typeDescription: PropTypes.any,
    locale: PropTypes.any,
    ampm: PropTypes.any,
    documentTime: PropTypes.any,
    currentForm: PropTypes.any,
    formDisabled: PropTypes.any,
    documentDate: PropTypes.any,
    show: PropTypes.bool,
    onFileChange: PropTypes.func,
    onSelectChange: PropTypes.func,
    onHide: PropTypes.func,
    reValidate: PropTypes.func,
    onSaveAddUpdateModal: PropTypes.func,
    onDateChange: PropTypes.func,
    clearValidation: PropTypes.func,
    validate: PropTypes.func,
    documentFileName: PropTypes.any,
    documentTitle: PropTypes.any,
    onInputChange: PropTypes.any,
    documentType: PropTypes.any,
    className: PropTypes.any,
    onSubmit: PropTypes.func,
    errorMsg: PropTypes.any,
    onTimeChange: PropTypes.func,
    formclass: PropTypes.any,
}

function mapStateToProps(state) {
    return {
        i18n: state.language.selected,
        settings: state.settings,
        formDisabled: state.formInteractions,
        userInfo: state.userInfo.data
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentAddEditModal);
