import React, {Component} from 'react'
import {connect} from 'react-redux'
import {$$} from '../../helpers/localization';
import {
    fetchSelectedUserMedicalProfile,
    selectAppointmentInProgress,
    updateAppointmentStatus
} from '../../actions/users_actions'
import {fetchSelectedUserMedicationPlan} from "../../actions/medications_actions";
import Search from '../shared/Search';
import Select from '../shared/Select'
import {medicalHistoryHelper} from '../../helpers/medical_history_helper'
import {APPOINTMENTS_APPOINTMENT_VIDEO} from '../../constants/pages'
import {COMPLETED} from '../../constants/appointment_payment_status';
import {APPOINTMENTS_FILTER, getColorForOption, getResolvedOptions} from '../../constants/select_options'
import {Routes} from '../../constants/routes'
import {appointmentUtils} from '../../utils/appointmentUtils'
import {formatUtils} from '../../utils/formatUtils'
import moment from 'moment'
import {addDays, endOfWeek, isThisWeek, startOfWeek, subDays} from 'date-fns'
import _ from 'underscore';
import OpenAppointmentButton from './OpenAppointmentButton';
import MessagesButton from '../shared/MessagesButton';
import EmailButton from '../shared/EmailButton';
import CancelAppointmentButton from './CancelAppointmentButton';
import CenteredModal from '../shared/CenteredModal';
import {infoUtils} from '../../utils/infoUtils';
import no_appointments from '../../resources/images/no_appointments.png'
import PropTypes from "prop-types";
import {paymentStatusUtils} from "../../utils/paymentStatusUtils";
import UserImageWrap from "../shared/UserImageWrap";
import CancelAppointmentModal from "../appointment/CancelAppointmentModal";


export class AppointmentsListView extends Component {
    state = {
        searchValue: "",
        calendarDisabled: true,
        requestSent: false,
        cancelAppointmentModalOpened: false,
        appointmentToCancel: null
    }

    constructor(props) {
        super(props);
        this.index = 0;
    }

    componentDidMount() {
        let date = new Date()
        this.fetchAppointments(date)
        this.setState({
            AppointmentDate: new Date()
        });
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps && prevProps.providerAppointments.data !== this.props.providerAppointments.data && !this.state.requestSent) {
            this.setState({
                requestSent: true
            });
        }

        if (prevProps && prevProps.i18n !== this.props.i18n) {
            let date = this.state.AppointmentDate;
            this.fetchAppointments(date)
        }
    }


    /**
     * Creates the health issues list
     *
     * @param {object} appointment - the appointment object
     * @returns {ReactElement} the created table cell
     */
    getHealthIssueList = (appointment) => {
        if (appointment.health_issues) {
            let healthIssueList = appointment.health_issues.map((h, idx) => {
                return <div key={idx} className='patient-health-issue-list'>{h.name}</div>
            });

            return <span key={++this.index} className='text-center appointment-health-issue-list-cell'>
                <div className='left-justify'>{healthIssueList}</div>
            </span>;
        }
    }

    /**
     * Sets the state based on what is entered by the user
     *
     * @param {string} searchValue - the value entered by the user, used for filtering purposes
     */
    filterUsers = (searchValue) => {
        this.setState({searchValue});
    }

    /**
     * Cancel appointment on confirmation
     */
    cancelAppointment = (app, note) => {
        this.props.updateAppointmentStatus(this.state.appointmentToCancel.id, "CANCELED_BY_PROVIDER", note);
        this.setState({cancelAppointmentModalOpened: false});
    }


    /**
     * Filters the appointments based on the search value
     *
     * @returns {Array} the list of filtered appointsments
     */
    getFilteredAppointments = () => {
        return this.state.requestSent && this.props.providerAppointments && this.props.providerAppointments.data.length ? this.props.providerAppointments.data.filter(appointment => {
            if (this.state.type && appointment.status !== this.state.type) {
                return false;
            }
            if (!this.state.searchValue) {
                return true;
            }
            for (let i = 0; i < appointment.participants.length; i++) {
                if (appointment.participants[i].participant_role === "PATIENT") {
                    return appointment.participants[i].fullname.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1;
                }
            }
            return false;
        }).sort((a, b) => a.starts_at - b.starts_at) : [];
    }

    /**
     * Set the state to the latest selected option.
     *
     * @param {object} evt - The event handler argument
     */
    onSelectChange = ({name, value}) => {
        const fields = Object.assign({}, this.state);
        fields[name] = value;
        this.setState(fields);
    };


    /**
     * creates the date label button used for navigation
     *
     */
    dateLabel = () => {
        let label = "";

        if (isThisWeek(this.state.AppointmentDate)) {
            label = $$('this_week');
        } else {
            let start = moment(startOfWeek(this.state.AppointmentDate, {
                locale: moment.locale(), weekStartsOn: moment.locale() === "bg" ? 1 : 0
            })).format('DD MMM');
            let end = moment(endOfWeek(this.state.AppointmentDate, {
                locale: moment.locale(), weekStartsOn: moment.locale() === "bg" ? 1 : 0
            })).format('DD MMM');

            label = start.toString() + " - " + end.toString()
        }

        return (
            <button onClick={this.handleReset} className='btn btn-secondary weeks-btn'>{label}</button>
        )
    }
    /**
     * Resets the date to today and updates appointments for this week
     *
     */

    handleReset = () => {
        let date = new Date();
        this.setState({AppointmentDate: date});
        this.fetchAppointments(date);
    }

    /**
     * Sets the date to next week and updates appointments for this week
     *
     */
    handleNextWeekClick = () => {
        let date = this.state.AppointmentDate;
        date = addDays(date, 7);

        this.setState({AppointmentDate: date});

        this.fetchAppointments(date)
    }

    /**
     * Sets the date to last week and updates appointments for this week
     *
     */
    handlePreviousWeekClick = () => {
        let date = this.state.AppointmentDate;
        date = subDays(date, 7);
        this.setState({AppointmentDate: date});
        this.fetchAppointments(date)
    }

    fetchAppointments = (date) => {
        let start = startOfWeek(date, {
            locale: moment.locale(), weekStartsOn: moment.locale() === "bg" ? 1 : 0
        });
        let end = endOfWeek(date, {
            locale: moment.locale(), weekStartsOn: moment.locale() === "bg" ? 1 : 0
        });
        this.props.fetchProviderAppointments(Date.parse(start), Date.parse(end));
    }

    /**
     * Prepares the object to be shown in case there are no appointments
     *
     * @returns {HTMLElement} the appropriate message when there's no data wrapped in a html div
     */
    getNoAppointmentsObj = () => {
        let noAppointmentssObj = {
            imgClass: 'no-pending-appointments-img',
            primaryLabelClass: 'no-pending-appointments-primary-label',
            secondaryLabelClass: '',
            src: no_appointments,
            primaryLabel: $$('no_pending_appointments_primary_label'),
            secondaryLabel: ''
        }

        return infoUtils.noDataLargerObj(noAppointmentssObj);
    }

    render() {
        let lang = _.contains(['en', 'sq'], this.props.i18n) ? 'en' : this.props.i18n.lang;
        moment.locale(lang);

        return (
            <div className={"minus-twenty-margin-top"}>
                <div className='patient-card-header'>
                    <div className='row space-between-justify align-items-center'>
                        <div className='search-box'>
                            <Search filterUsers={this.filterUsers}
                                    placeholder={$$('search_patient_label')}/>
                        </div>
                        <div className='row patient-view-form-container form-margin'>
                            <Select
                                name="type"
                                options={getResolvedOptions(APPOINTMENTS_FILTER.TYPE)}
                                value={this.state.type}
                                onChange={this.onSelectChange}
                                placeHolder={$$('document_type_label')}/>
                            <div className={"left-margin-ten"}/>
                            <div className="btn-group align-self-center" role="group" aria-label="Basic example">
                                <button onClick={this.handlePreviousWeekClick} className='btn btn-secondary'><i
                                    className="fa fa-angle-left"/></button>
                                {this.dateLabel()}
                                <button onClick={this.handleNextWeekClick} className='btn btn-secondary'><i
                                    className="fa fa-angle-right"/></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    {this.getFilteredAppointments().length > 0 &&
                        <div className='card-body patient-list-view'>
                            <div className='row zero-margin-row'>
                                <div className='col-xs-12 col-md-12'>
                                    <div className='row'>
                                        <div className="patient-table-container mt-3">
                                            <div className="w-100 d-table management-patients-table">
                                                {this.getFilteredAppointments().map(u => {
                                                    let patient;
                                                    for (let i = 0; i < u.participants.length; i++) {
                                                        if (u.participants[i].participant_role === "PATIENT") {
                                                            patient = u.participants[i];
                                                        }
                                                    }
                                                    let paymentStatus = paymentStatusUtils.paymentStatus(u)
                                                    let isClickable = !appointmentUtils.isAppointmentCanceled(u.status);

                                                    return <div key={u.id} className="d-table-row">
                                                            <div
                                                                className={"d-table-cell p-2"}>
                                                                <UserImageWrap
                                                                    userID={appointmentUtils.isGroupEventParent(u) ? undefined : patient.user_id}
                                                                    show_red_dot={appointmentUtils.isGroupEventParent(u) ? false : patient.has_not_seen_patient_documents}
                                                                    classnames="patient-img"/>
                                                            </div>
                                                            <div className={"d-table-cell p-2"}>
                                                                {!appointmentUtils.isGroupEventParent(u) &&
                                                                    isClickable &&
                                                                    <a href="#" onClick={(e) => e.preventDefault()}>
                                                                        <h5 onClick={() => {
                                                                            let patient = u.participants.find(a => a.participant_role === 'PATIENT');
                                                                            medicalHistoryHelper.prepareMedicalHistoryData(APPOINTMENTS_APPOINTMENT_VIDEO, patient, u, Routes.APPOINTMENT);
                                                                        }}
                                                                        >{patient.fullname}</h5>
                                                                    </a>
                                                                }
                                                                {!appointmentUtils.isGroupEventParent(u) && !isClickable &&
                                                                    <h5>{patient.fullname}</h5>}

                                                                {!appointmentUtils.isGroupEventParent(u) &&
                                                                    <span
                                                                        className="medrec-grey-2 small">{appointmentUtils.appointmentType(u)} </span>}
                                                                {appointmentUtils.isGroupEventParent(u) &&
                                                                    <div style={{maxWidth: "120px"}}
                                                                         className={isClickable ? "dropdown-link" : ""}>
                                                                        <span className='medrec-grey-2 small'
                                                                              onClick={isClickable ? () => {
                                                                                  medicalHistoryHelper.openGroupAppointmentPage(u)
                                                                              } : undefined}
                                                                              style={{'cursor': isClickable ? 'pointer' : 'default'}}>{appointmentUtils.appointmentType(u)}</span>
                                                                    </div>}
                                                            </div>

                                                            <div className={"d-table-cell p-2"}>
                                                                <div className={"appointment-start"}>
                                                                    <span className='font-weight-bold patient-fullname'>
                                                                        {moment(u.starts_at).format('DD MMM')}
                                                                    </span>
                                                                    <br/>
                                                                    <span className="patient-age">
                                                                        <i className='flaticon2-time medrec-grey-2'/>
                                                                        &nbsp;{moment(u.starts_at).format('HH:mm')}
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div className={"d-table-cell p-2"}>
                                                                 <span>
                                                                     {appointmentUtils.location(u)}
                                                                 </span>
                                                            </div>

                                                            <div className={"d-table-cell p-2"}>
                                                                 <span
                                                                     className={u.payment_status === COMPLETED ? "medrec-green-1" : "medrec-grey-2"}>
                                                                     {formatUtils.currencyFormat(u.appointment_price.price_cents, u.appointment_price.currency)}
                                                                </span>
                                                            </div>

                                                            <div className={"d-table-cell p-2"}>
                                                                {this.getHealthIssueList(u)}
                                                            </div>

                                                            <div className={"d-table-cell p-2"}>
                                                                 <span className="patient-age">
                                                                     {!appointmentUtils.isGroupEventParent(u) ?
                                                                         <div className={paymentStatus.colour}>
                                                                             {u.appointment_price.price_cents > 0 ? paymentStatus.label : "-"}
                                                                         </div> :
                                                                         <div>{u.used_slots} / {u.max_patients}</div>}
                                                                 </span>
                                                            </div>

                                                            <div className={"d-table-cell p-2"}>
                                                                 <span className="patient-age"
                                                                       style={{"color": getColorForOption(APPOINTMENTS_FILTER.COLOR, u.status)}}>
                                                                     {$$(u.status.toLowerCase())}
                                                                 </span>
                                                            </div>

                                                            <div className={"d-table-cell p-2 text-right"}>

                                                                {!appointmentUtils.isGroupEventParent(u) &&
                                                                    <MessagesButton title={$$('messages_label')}
                                                                                    style={{'marginLeft': '0.625rem'}}
                                                                                    onClick={() => {
                                                                                        this.props.onMessagesClick(u.participants.filter(a => a.participant_role === 'PATIENT')[0]);
                                                                                    }}/>}
                                                                {!appointmentUtils.isGroupEventParent(u) &&
                                                                    <EmailButton title={$$('email_label')}
                                                                                 style={{'marginLeft': '0.625rem'}}
                                                                                 onClick={() => {
                                                                                     window.location = "mailto:" + u.participants.filter(a => a.participant_role === 'PATIENT')[0].email;
                                                                                 }}/>}
                                                                {appointmentUtils.canCancel(u) &&
                                                                    <CancelAppointmentButton
                                                                        title={$$('cancel_appointment_label')}
                                                                        style={{'marginLeft': '0.625rem'}}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                cancelAppointmentModalOpened: true,
                                                                                appointmentToCancel: u
                                                                            });
                                                                        }}/>}
                                                                {!appointmentUtils.isAppointmentCanceled(u.status) &&
                                                                    <OpenAppointmentButton
                                                                        title={$$('open_appointment_label')}
                                                                        style={{'marginLeft': '0.625rem'}}
                                                                        onClick={() => {
                                                                            if (appointmentUtils.isGroupEventParent(u)) {
                                                                                medicalHistoryHelper.openGroupAppointmentPage(u)
                                                                            } else {
                                                                                let patient = u.participants.find(a => a.participant_role === 'PATIENT');
                                                                                medicalHistoryHelper.prepareMedicalHistoryData(APPOINTMENTS_APPOINTMENT_VIDEO, patient, u, Routes.APPOINTMENT);
                                                                            }
                                                                        }}/>}

                                                            </div>
                                                        </div>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div>
                    {!(this.getFilteredAppointments(null).length > 0) &&
                        <div className="card documents-inner-card border-bottom-0">
                            <div className="card-body inner-card-body pt-0">
                                <div className={"no-appointment"}>{this.getNoAppointmentsObj()}</div>
                            </div>
                        </div>}
                </div>
                {this.state.appointmentToCancel && <CancelAppointmentModal
                    appointment={this.state.appointmentToCancel}
                    show={this.state.cancelAppointmentModalOpened}
                    onHide={() => {
                        this.setState({cancelAppointmentModalOpened: false})
                    }}
                    onConfirm={this.cancelAppointment}
                />}
            </div>
        )
    }
}

AppointmentsListView.propTypes = {
    clearProviderAppointments: PropTypes.func,
    clearSelectedUserData: PropTypes.func,
    fetchProviderAppointments: PropTypes.func,
    fetchSelectedUserMedicalProfile: PropTypes.func,
    fetchSelectedUserMedicationPlan: PropTypes.func,
    i18n: PropTypes.object,
    onMessagesClick: PropTypes.func,
    providerAppointments: PropTypes.object,
    selectAppointmentInProgress: PropTypes.func,
    updateAppointmentStatus: PropTypes.func,
    users_data: PropTypes.object
}

const mapStateToProps = (state) => ({
    i18n: state.language.selected,
    users_data: state.users
})

export default connect(mapStateToProps, {
    selectAppointmentInProgress,
    fetchSelectedUserMedicationPlan,
    fetchSelectedUserMedicalProfile,
    updateAppointmentStatus
})(AppointmentsListView)
