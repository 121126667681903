import React from "react";
import "react-daterange-picker/dist/css/react-calendar.css";
import DateRangePicker from "react-daterange-picker"
import {$$} from "../../helpers/localization";
import moment from "moment";
import PropTypes from "prop-types";


class DateRangePick extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isOpen: false,
            value: moment().range(new Date(), new Date()),
            selectedValues: {start: "", end: ""},
            selectedCalendar: "",
            dates: []
        };
    }

    onSelect = (value, states) => {
        this.setState({value, states});

        this.setState({selectedValues: value});

    };

    onToggle = () => {
        this.setState({isOpen: !this.state.isOpen});
    };

    onSubmit = () => {
        if (this.state.selectedValues.end) {
            this.setState({isOpen: !this.state.isOpen});

            let form = {
                days_of_the_week: [],
                end: this.state.value.end.clone().endOf("day")._d.getTime(),
                end_time: "23:59",
                not_available: true,
                provider_id: this.props.providerId,
                organization_id: this.state.selectedCalendar,
                start: this.state.value.start._d.getTime(),
                start_time: "00:00"
            }

            this.props.bookTimeOff(form);

            if (this.state.selectedValues.start !== "") {
                const dateRange = {
                    start: this.state.value.start,
                    end: this.state.value.end
                };
                this.state.dates.push(dateRange);
                this.props.onSetDates(this.state.dates);
                this.setState({value: moment().range(new Date(), new Date())})

            }
        } else {
            this.setState({isOpen: !this.state.isOpen});
        }
    };

    getSubmitLabel = () => {
        if (this.state.selectedValues.start) {
            return $$("set")
        } else {
            return $$("cancel")
        }
    }

    handleCalendarChange(e) {
        this.setState({selectedCalendar: e.target.value});
    }

    renderSelectionValue = (el) => {
        if (this.state.selectedValues.start !== "") {
            return (
                <div className="date-picker-selected-dates">
                    <span>
                        {this.state.selectedValues.start.locale(this.props.i18n.selected.lang).format("D MMM YYYY")}
                    </span>
                    <span className="mr-1 ml-1">-</span>
                    <span>{this.state.selectedValues.end.locale(this.props.i18n.selected.lang).format("D MMM YYYY")}</span>
                    {el}
                </div>
            );
        } else {
            return (
                <div className="date-picker-selected-dates">
                    <span>
                        &nbsp;
                    </span>
                    {el}
                </div>
            );
        }
    };

    render() {
        moment.locale(this.props.i18n.selected.lang)
        return (
            <div className="date-picker">


                <input
                    type="button"
                    className="btn btn-tertiary"
                    value={$$("book_time_off")}
                    onClick={this.onToggle}
                />

                {this.state.isOpen && (
                    <div className="date-picker-popup">

                        { !this.props.management && (
                            <span className="date-picker-selected-dates">
                        <select id="calendars"
                                className="custom-select calendar-select w-100"
                                onChange={(e)=>{this.handleCalendarChange(e)}}
                                value={this.state.selectedCalendar}>
                            {this.props.createCalendarOptions()}
                        </select>
                    </span>)}

                        {this.renderSelectionValue()}

                        <DateRangePicker
                            locale={moment.locale()}
                            firstOfWeek={moment.locale() === "bg" ? 1:0}
                            value={this.state.value}
                            dateFormat={this.props.settings?.data.dateFormat ? this.props.settings.data.dateFormat : "dd/MM/yyyy"}
                            onSelect={this.onSelect}
                            singleDateRange={true}
                        />

                        <div className="text-center">
                            <input
                                type="button"
                                className="btn btn-primary"
                                value={this.getSubmitLabel()}
                                onClick={this.onSubmit}
                            />
                        </div>

                    </div>
                )}
            </div>
        );
    }
}

DateRangePick.propTypes = {
    bookTimeOff: PropTypes.func,
    management: PropTypes.any,
    i18n: PropTypes.object,
    settings: PropTypes.any,
    organizations: PropTypes.any,
    onSetDates: PropTypes.func,
    createCalendarOptions: PropTypes.func,
    providerId: PropTypes.string
}

export default DateRangePick;
