import React, {Component} from 'react'
import {ListGroup, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {ReactComponent as DeleteIcon} from "../../../public/media/icons/Delete.svg";
import {ReactComponent as EditIcon} from "../../../public/media/icons/Edit.svg";
import PropTypes from "prop-types";
import {getPrescriptionDetailsString, isNonMedicinalProduct} from "../../utils/medicationUtils";
import {$$} from "../../helpers/localization";
import {replaceAllNewLines, replaceFirstNewLine} from "../../utils/reportUtils";
import {dateTimeUtils} from "../../utils/dateTimeUtils";


function sortByNrn() {
    return (a, b) => {
        if (a.nhis_prescription_nrn === b.nhis_prescription_nrn) {
            return 0;
        }

        if (a.nhis_prescription_nrn > b.nhis_prescription_nrn || !b.nhis_prescription_nrn) {
            return 1;
        }

        return -1;
    };
}

function getNhisPrescriptions(list) {
    const nhisPrescriptions = {};
    const nonMedForms = {}
    for (const p of list) {
        let nrn = p.nhis_prescription_nrn;
        if (nrn) {
            if (isNonMedicinalProduct(p)) {
                nonMedForms[nrn] = p
                continue;
            }

            let groupCode = p.nhis_prescription_group_code ? p.nhis_prescription_group_code : "A";
            if (groupCode !== "A") {
                continue;
            }

            let nhisP = nhisPrescriptions[nrn];
            if (!nhisP) {
                nhisP = [];
                nhisPrescriptions[nrn] = nhisP;
            }
            let meds = nhisP[p.nhis_prescription_medication_sequence_id]
            if (!meds) {
                meds = [];
                nhisP[p.nhis_prescription_medication_sequence_id] = meds;
            }
            meds[p.nhis_prescription_dosage_instruction_sequence - 1] = p;
        }
    }
    return {nhisPrescriptions, nonMedForms};
}


export class PrescriptionList extends Component {
    constructor(props) {
        super(props)
        let model = getNhisPrescriptions(props.prescriptionList);
        this.state = {
            list: [...props.prescriptionList].filter(p => !p.nhis_prescription_nrn).sort(sortByNrn()),
            nhisPrescriptions: model.nhisPrescriptions,
            nonMedForms: model.nonMedForms
        }
    }

    editTooltip = props => (
        <Tooltip {...props} show={"true"}>{$$("edit_label")}</Tooltip>
    );

    deleteTooltip = props => (
        <Tooltip {...props} show={"true"}>{$$("delete_label")}</Tooltip>
    );

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.prescriptionList !== prevProps.prescriptionList) {
            let model = getNhisPrescriptions(this.props.prescriptionList);
            this.setState({
                list: [...this.props.prescriptionList].filter(p => !p.nhis_prescription_nrn).sort(sortByNrn()),
                nhisPrescriptions: model.nhisPrescriptions,
                nonMedForms: model.nonMedForms
            })
        }
    }

    render() {
        return (
            <ListGroup className={"shadow-boxes-list"}>
                {this.state.list.map((entry, idx) => {
                    return <PrescriptionItem key={idx} entry={entry} deleteTooltip={this.deleteTooltip}
                                             editTooltip={this.editTooltip}
                                             showDeletedConfirmationModal={this.props.showDeletedConfirmationModal}
                                             showAddUpdateModal={this.props.showAddUpdateModal}/>
                })}
                <NhisPrescriptions prescriptions={this.state.nhisPrescriptions} nonMedForms={this.state.nonMedForms} deleteTooltip={this.deleteTooltip} onDelete={this.props.onDeleteNhisPrescription}/>
                {this.props.children}
            </ListGroup>
        )
    }
}


PrescriptionList.propTypes = {
    prescriptionList: PropTypes.array,
    children: PropTypes.any,
    showAddUpdateModal: PropTypes.func,
    showDeletedConfirmationModal: PropTypes.func
}

export default PrescriptionList

function NhisPrescriptions({prescriptions, nonMedForms, onDelete, deleteTooltip}) {
    return <>
        {Object.keys(prescriptions).map((nrn) => {
            return <div key={nrn} className="list-group-item">
                <div>
                    <p>{$$("nhis.tab.prescription")}: {nrn}
                    <br/>
                        {$$("date_label")}: {dateTimeUtils.getFormattedDate(new Date(prescriptions[nrn][0][0].server_created_timestamp))}
                    </p>

                    <span className="pointer prescription-delete-icon"
                          onClick={() => {
                              onDelete(nrn)
                          }}
                    >
                    <OverlayTrigger
                        placement={"top"}
                        overlay={deleteTooltip}
                        popperConfig={{
                            modifiers: {
                                preventOverflow: {
                                    enabled: false
                                },
                                hide: {
                                    enabled: false
                                }
                            }
                        }}
                    >
                        <DeleteIcon className="svg-blue" height="17px" width="17px"/>
                    </OverlayTrigger>
                        </span>
                </div>
                {prescriptions[nrn].map((arr, i) => {
                    return <NhisMedications meds={arr} key={i} />
                })}
                <NonMedForm form={nonMedForms[nrn]}/>
            </div>
        })}
    </>
}

function NonMedForm({form}) {

    if (!form) {
        return null;
    }

    return (<div className="ml-2 mb-2">
        <span>{$$("nhis.supplement")}</span>
        <div className={"smaller-text medrec-grey-2"}>{replaceAllNewLines(form.notes, "; ").trim()}</div>
        <hr/>
    </div>)
}

function NhisMedications({meds}) {
    return <div className="ml-2 mb-2">
        <span>{meds[0].name}</span>
        {meds.map((m, i) => {
            return <div key={i} className={"smaller-text medrec-grey-2"}>{replaceAllNewLines(m.notes, "; ")}</div>
        })}
        <hr/>
    </div>
}

function PrescriptionItem({entry, deleteTooltip, editTooltip, showAddUpdateModal, showDeletedConfirmationModal}) {
    return (<ListGroup.Item>
        <div style={{marginRight: '0.5rem', marginTop: '0.5rem'}}>
            <h6 className="card-title">{entry.name}</h6>
            <div className={"smaller-text medrec-grey-2"}>{getPrescriptionDetailsString(entry)}</div>
        </div>
        <span onClick={() => {
            showDeletedConfirmationModal(entry)
        }}
              className="pointer prescription-delete-icon">
                                                                        <OverlayTrigger
                                                                            placement={"top"}
                                                                            overlay={deleteTooltip}
                                                                            popperConfig={{
                                                                                modifiers: {
                                                                                    preventOverflow: {
                                                                                        enabled: false
                                                                                    },
                                                                                    hide: {
                                                                                        enabled: false
                                                                                    }
                                                                                }
                                                                            }}
                                                                        >
                        <DeleteIcon className="svg-blue" height="17px" width="17px"/>
                    </OverlayTrigger>
                        </span>
        <span onClick={() => {
            showAddUpdateModal(entry)
        }}
              className="pointer prescription-edit-icon">
                            <OverlayTrigger
                                placement={"top"}
                                overlay={editTooltip}
                                popperConfig={{
                                    modifiers: {
                                        preventOverflow: {
                                            enabled: false
                                        },
                                        hide: {
                                            enabled: false
                                        }
                                    }
                                }}
                            >
                        <EditIcon className="svg-blue" height="17px" width="17px"/>
                    </OverlayTrigger>
                        </span>
    </ListGroup.Item>);
}