import React, {useState} from "react";
import {useNhisEReferralFetchContext} from "./NhisEReferralFetchNomenclatureProvider";
import {OptionalRow} from "./ViewComponents";
import {dateTimeUtils} from "../../../../utils/dateTimeUtils";

export function HospitalizationsView({hospitalizations}) {
    const [selectedIdx, setSelectedIdx] = useState(0);
    return <>
        {hospitalizations.length !== 1 &&
        <HospitalizationSelector hospitalizations={hospitalizations} selectedIdx={selectedIdx}
                                 setSelectedIdx={setSelectedIdx}/>}

        <HospitalizationView hospitalization={hospitalizations[selectedIdx]}/>
    </>
}

function HospitalizationSelector({hospitalizations, selectedIdx, setSelectedIdx}) {
    return false
}

function HospitalizationView({hospitalization}) {
    const {
        hospitalizationStatusOptions,
        admissionTypeOptions,
        cl061,
        cl066,
        cl075,
        cl080,
        cl081,
        cl011,
        isLoading
    } = useNhisEReferralFetchContext();

    if (isLoading) {
        return false;
    }

    console.log(hospitalization)

     return (<div className="pt-2">
         <table className="table table-sm table-borderless medrec-default-text-color">
             <tbody>
                 <OptionalRow label="nhis.ereferral.hospitalization.status" opt={hospitalizationStatusOptions} val={hospitalization.status}/>
                 <OptionalRow label="nhis.ereferral.hospitalization.admissionDate" val={hospitalization.admissionDate} convertor={dateTimeUtils.getFormattedDate}/>
                 <OptionalRow label="nhis.ereferral.hospitalization.admissionType" opt={admissionTypeOptions} val={hospitalization.admissionType}/>
                 <OptionalRow label="nhis.ereferral.hospitalization.reason" opt={cl061} val={hospitalization.reasonCode}/>
                 <OptionalRow label="nhis.ereferral.hospitalization.severity" opt={cl066} val={hospitalization.severity}/>
                 <OptionalRow label="nhis.ereferral.hospitalization.note" val={hospitalization.note}/>
                 <OptionalRow label="nhis.ereferral.hospitalization.dischargeDate" val={hospitalization.dischargeDate} convertor={dateTimeUtils.getFormattedDate}/>
                 <OptionalRow label="nhis.ereferral.hospitalization.dischargeDiagnoses" val={hospitalization.dischargeDiagnosis} opt={cl011} isDiagnosis={true}/>
                 <OptionalRow label="nhis.ereferral.hospitalization.comorbidity" val={hospitalization.comorbidity} opt={cl011} isDiagnosis={true}/>
                 <OptionalRow label="nhis.ereferral.hospitalization.outcome" opt={cl075} val={hospitalization.outcome}/>
                 <OptionalRow label="nhis.ereferral.hospitalization.dischargeDisposition" opt={cl080} val={hospitalization.dischargeDisposition}/>
                 <OptionalRow label="nhis.ereferral.hospitalization.dischargeNote" val={hospitalization.dischargeNote}/>
                 <OptionalRow label="nhis.ereferral.hospitalization.daysHospitalized" val={hospitalization.daysHospitalized}/>
                 <OptionalRow label="nhis.ereferral.hospitalization.deceasedDate" val={hospitalization.deceasedDate} convertor={dateTimeUtils.getFormattedDate}/>
                 <OptionalRow label="nhis.ereferral.hospitalization.autopsyDate" val={hospitalization.autopsyDate} convertor={dateTimeUtils.getFormattedDate}/>
                 <OptionalRow label="nhis.ereferral.hospitalization.workability" opt={cl081} val={hospitalization.workability}/>
             </tbody>
         </table>
         <Epicrisis epicrisis={hospitalization.epicrisis} />
     </div>)
}

function Epicrisis({epicrisis}) {
    //todo: implement - contains document refs and files only?
    return false;
}



