import {
    CLEAR_APPOINTMENT_FILTERS,
    RESTORE_APPOINTMENT_FILTERS,
    SAVE_APPOINTMENT_FILTERS} from "./actions";

export function saveAppointmentFilters(filters) {
    return (dispatch) => {
        dispatch({type: SAVE_APPOINTMENT_FILTERS, filters: filters})
    }
}

export function restorePreviousFilters(shouldRetore) {
    return (dispatch) => {
        dispatch({type: RESTORE_APPOINTMENT_FILTERS, restore: shouldRetore})
    }
}

export function clearFilters() {
    return (dispatch) => {
        dispatch({type: CLEAR_APPOINTMENT_FILTERS})
    }
}