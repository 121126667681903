import {Button, ButtonGroup} from "react-bootstrap";
import React, {useRef} from "react";

export function BoolSwitch({labelOn, labelOff, offFirst, checked, onChange, ...rest}) {
    const parent = useRef(null)

    const offButton = <Button tabIndex="-1" type="button" variant={"outline-primary"} className={"w-50"} active={checked !== true}
                              onClick={() => {
                                  if (checked) {
                                      onChange()
                                  }
                                  parent.current.focus()
                              }}
                              disabled={!!rest?.disabledOff}
                              onMouseDown={(e)=>{
                                  e.preventDefault();
                              }}>{labelOff}</Button>
    const onButton = <Button tabIndex="-1" type="button" variant={"outline-primary"} className={"w-50"} active={checked === true}
                             onClick={() => {
                                 if (!checked) {
                                     onChange()
                                 }
                                 parent.current.focus()
                             }}
                             disabled={!!rest?.disabledOn}
                             onMouseDown={(e)=>{
                                 e.preventDefault();
                             }}>{labelOn}</Button>

    return <ButtonGroup ref={parent} size={rest?.size ? rest.size : "sm"} tabIndex="0" className="w-100" onKeyDown={(e) => {
        if (e.key === " ") {
            e.preventDefault();
            onChange()
        }
    }}>
        {offFirst ? offButton : onButton}
        {offFirst ? onButton : offButton}
    </ButtonGroup>
}