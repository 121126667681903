import React, {useState} from "react";
import {NhisExaminationProvider, useNhisExaminationContext} from "./NhisExaminationProvider";
import {$$} from "../../../../helpers/localization";
import {OptionalRow} from "./ViewComponents";
import {dateTimeUtils} from "../../../../utils/dateTimeUtils";

export function ExaminationsView({examinations}) {
    const [selectedIdx, setSelectedIdx] = useState(0)
    return <NhisExaminationProvider>
        {examinations.length !== 1 &&
        <ExaminationSelector examinations={examinations} selectedIdx={selectedIdx} setSelectedIdx={setSelectedIdx}/>}
        <ExaminationView examination={examinations[selectedIdx]}/>
    </NhisExaminationProvider>
}

function ExaminationSelector({examinations, selectedIdx, setSelectedIdx}) {
    return false
}

function ExaminationView({examination}) {
    const {
        examClassOptions,
        conclusionOptions,
        dischargeDispositionOptions,
        medicationCodeOptions,
        icdCodeOptions,
        isLoading
    } = useNhisExaminationContext()

    if (isLoading) {
        return false;
    }

    //todo: implement assessment, resulting documents and sick leave?

    return (<div className="pt-2">
        <table className="table table-sm table-borderless medrec-default-text-color">
            <tbody>
                <OptionalRow label="nhis.examination.examination" val={examination.nrnExamination}/>
                <OptionalRow label="nhis.examination.is_secondary_label" val={examination.isSecondary ? $$(`nhis.examination.yes_label`) : ""}/>
                <OptionalRow label="nhis.examination.status" val={$$(`nhis.examination.status_${examination.status}`)}/>
                <OptionalRow label="nhis.examination.classification" val={examination.examinationClass}
                             opt={examClassOptions}/>
                <OptionalRow label="nhis.examination.open_date" val={examination.openDate} convertor={dateTimeUtils.getFormattedDate}/>
                <OptionalRow label="nhis.examination.close_date" val={examination.closeDate} convertor={dateTimeUtils.getFormattedDate}/>
                <OptionalRow label="nhis.examination.medical_history_label" val={examination.medicalHistory}/>
                <OptionalRow label="nhis.examination.objective_condition_label" val={examination.objectiveCondition}/>
                <OptionalRow label="nhis.examination.main_diagnosis" val={examination.diagnosis} opt={icdCodeOptions} isDiagnosis={true}/>
                <OptionalRow label="nhis.examination.additional_diagnoses" val={examination.comorbidity} opt={icdCodeOptions} isDiagnosis={true}/>
                <OptionalRow label="nhis.examination.conclusion" val={examination.conclusion} opt={conclusionOptions}/>
                <OptionalRow label="nhis.examination.dischargeDisposition" val={examination.dischargeDisposition}
                             opt={dischargeDispositionOptions}/>
                <OptionalRow label="nhis.examination.medications" val={examination.therapy?.medicationCode}
                             opt={medicationCodeOptions}/>
                <OptionalRow label="nhis.examination.therapy_notes_label" val={examination.therapy?.note}/>
            </tbody>
        </table>
    </div>)
}

