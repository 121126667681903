import React, {createContext, useCallback, useContext, useEffect, useState} from "react";
import {nhisGetNomenclatures} from "../../../../service/nhis_service";
import {sortByLabel, sortByProp} from "./utils";

export const NhisPrescriptionContext = createContext(null);



export function NhisPrescriptionProvider({children}) {
    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState(false);
    const [nomenclatures, setNomenclatures] = useState();
    const [options, setOptions] = useState();
    const [priorityOptions, setPriorityOptions] = useState();
    const [nhifMedicationCodeOptions, setNhifMedicationCodeOptions] = useState();
    const [medicationCodeOptions, setMedicationCodeOptions] = useState();
    const [medicationFormCodeOptions, setMedicationFormCodeOptions] = useState();
    const [doseQuantityCodesOptions, setDoseQuantityCodesOptions] = useState();
    const [periodUnitOptions, setPeriodUnitOptions] = useState();
    const [boundsUnitOptions, setBoundsUnitOptions] = useState();
    const [intakeTimeOptions, setIntakeTimeOptions] = useState();
    const [intakeRouteOptions, setIntakeRouteOptions] = useState();
    const [specialtyOptions, setSpecialtyOptions] = useState();

    const convertToOptions = useCallback((nomenclature, sortFunction = sortByLabel) => {
        const items = nomenclature.items;
        let propNames = Object.getOwnPropertyNames(items);
        let map = propNames.map(prop => ({
            value: prop,
            label: nomenclature.nomenclatureId === "CL011" ? prop + " " + items[prop].description : items[prop].description,
            text: items[prop].description,
            item: items[prop]
        }));
        if (sortFunction) {
            return map.sort(sortFunction);
        }
        return map;
    }, []);

    useEffect(() => {
        load();
    }, []);

    const reload = () => {
        setLoading(true);
        load();
    }

    const load = () => {
        nhisGetNomenclatures(["CL009", "CL006", "CL007", "CL010", "CL027", "CL026", "CL011", "CL015", "CL035", "CL020", "CL034", "CL013"]).then((res) => {
            const object = res.reduce(
                (obj, item) => Object.assign(obj, {[item.nomenclatureId]: item}), {});
            setNomenclatures(object);
            const items = Object.getOwnPropertyNames(object);
            const opt = {};
            const toSortbyValue = ["CL007", "CL015"];
            const unsorted = [/*"CL020"*/];
            for (const item of items) {
                opt[item] = toSortbyValue.includes(item) ? convertToOptions(object[item], sortByProp("value")) : (unsorted.includes(item) ? convertToOptions(object[item], null) : convertToOptions(object[item], sortByProp("label")));
                //console.log(item + " -> ", opt[item].length)
            }

            const cl026WithCL009 = opt["CL026"].map(option => {
                const cloned = _.cloneDeep(option);
                cloned.label = cloned.value + " " + cloned.text;
                let cl009 = option.item.meta.CL009;
                if (cl009) {
                    let med = opt["CL009"].find((mkb) => mkb.value == cl009);
                    if (med) {
                        cloned.item.meta["units"] = med.item.meta["units"];
                        cloned.item.meta["quantityStrength"] = med.item.meta["quantity"];
                        cloned.item.meta["inn code"] = med.item.meta["inn code"];
                        cloned.item.meta["form id"] = med.item.meta["form id"];
                        cloned.label = cloned.label + " (" + cloned.item.meta["inn code"] + ")" + `, ${cloned.item.meta["quantityStrength"]} ${cloned.item.meta["units"] ? cloned.item.meta["units"] : ""}`
                    }
                }
                return cloned;
            });
            opt["CL026"] = cl026WithCL009;
            opt["CL006"].forEach(opt => opt.label = opt.value + " " + opt.label)
            setPriorityOptions(opt["CL027"]);
            setNhifMedicationCodeOptions(opt["CL026"]);
            setMedicationCodeOptions(opt["CL009"]);
            setMedicationFormCodeOptions(opt["CL010"])
            setDoseQuantityCodesOptions(opt["CL035"])
            setPeriodUnitOptions(opt["CL020"].filter(opt => ["h", "d"].includes(opt.value)))
            setBoundsUnitOptions(opt["CL020"].filter(opt => ["mo", "d"].includes(opt.value)))
            setIntakeTimeOptions(opt["CL034"])
            setIntakeRouteOptions(opt["CL013"])
            setSpecialtyOptions(opt["CL006"].filter(o => o.item.meta.role?.includes('Лекари') || o.value === "0000"))
            setOptions(opt);
            setError(false);
            setLoading(false);
        }).catch(e => {
            setError(true);
            setLoading(false);
        });
    }


    return <NhisPrescriptionContext.Provider
        value={{
            nomenclatures,
            options,
            priorityOptions,
            nhifMedicationCodeOptions,
            medicationCodeOptions,
            medicationFormCodeOptions,
            doseQuantityCodesOptions,
            periodUnitOptions,
            boundsUnitOptions,
            intakeTimeOptions,
            intakeRouteOptions,
            specialtyOptions,
            isLoading,
            isError,
            reload
        }}
    >{children}</NhisPrescriptionContext.Provider>
}

export function useNhisPrescriptionContext() {
    const context = useContext(NhisPrescriptionContext);
    if (!context) {
        throw new Error('NhisPrescriptionContext must be used within a PrescriptionProvider');
    }
    return context;
}