import {usersService} from "../service/users_service"
import {
    ALL_USERS_ERROR,
    ALL_USERS_REQUEST_SENT,
    ALL_USERS_SUCCESS,
    CHANGE_APPOINTMENT_IN_APPOINTMENTS_LIST,
    CHANGE_CHARTS_FILTERS,
    CLEAR_GROUP_APPOINTMENT,
    CLEAR_PATIENTS,
    CLEAR_PROVIDER_APPOINTMENTS,
    CLEAR_SELECTED_APPOINTMENT,
    CLEAR_SELECTED_APPOINTMENT_IN_PROGRESS,
    CLEAR_SELECTED_ENCOUNTER,
    CLEAR_SELECTED_USER,
    CLEAR_USER_APPOINTMENTS,
    CLEAR_USER_PASSWORD,
    CREATE_ENCOUNTER_REQUEST_ERROR,
    CREATE_ENCOUNTER_REQUEST_SENT,
    CREATE_ENCOUNTER_REQUEST_SUCCESS,
    CREATE_NEW_CONSULTATION_MENU_ITEM,
    CREATE_NEW_CONSULTATION_MENU_ITEM_ERROR,
    CREATE_NEW_DATE_RANGE,
    CREATE_NEW_DATE_RANGE_ERROR,
    CREATE_NOTE_REQUEST_ERROR,
    CREATE_NOTE_REQUEST_SENT,
    DELETE_CONSULTATION_MENU_ITEM_ERROR,
    DELETE_CONSULTATION_MENU_ITEM_SUCCESS,
    DELETE_DATE_RANGE_ERROR,
    DELETE_DATE_RANGE_SUCCESS,
    DELETE_NOTE_REQUEST_ERROR,
    DELETE_NOTE_REQUEST_SENT,
    DISPLAY_PROVIDER_IMAGE,
    DUMMY,
    FETCH_APPOINTMENT_USER_ERROR,
    FETCH_APPOINTMENT_USER_REQUEST_SENT,
    FETCH_APPOINTMENT_USER_SUCCESS,
    FETCH_APPOINTMENTS_ERROR,
    FETCH_APPOINTMENTS_REQUEST_SENT,
    FETCH_APPOINTMENTS_SUCCESS,
    FETCH_CHARTS_DATA_ERROR,
    FETCH_CHARTS_DATA_SUCCESS,
    FETCH_DASHBOARD_CHARTS_DATA_ERROR,
    FETCH_DASHBOARD_CHARTS_DATA_SUCCESS,
    FETCH_LAST_APPOINTMENT_ERROR,
    FETCH_LAST_APPOINTMENT_REQUEST_SENT,
    FETCH_LAST_APPOINTMENT_SUCCESS,
    FETCH_PRICE_LIST_REQUEST_SENT,
    FETCH_PROVIDER_APPOINTMENTS_STATS_ERROR,
    FETCH_PROVIDER_APPOINTMENTS_STATS_SUCCESS,
    FETCH_SELECTED_USER_ENTRIES_ERROR,
    FETCH_SELECTED_USER_ENTRIES_REQUEST_SENT,
    FETCH_SELECTED_USER_ENTRIES_SUCCESS,
    FETCH_SELECTED_USER_LATEST_MEASUREMENTS_ERROR,
    FETCH_SELECTED_USER_LATEST_MEASUREMENTS_SUCCESS,
    FETCH_SELECTED_USER_MEDICAL_PROFILE_ERROR,
    FETCH_SELECTED_USER_MEDICAL_PROFILE_SUCCESS,
    FETCH_SPECIALTIES_ERROR,
    FETCH_SPECIALTIES_REQUEST_SENT,
    FETCH_SPECIALTIES_SUCCESS,
    FETCH_USER_APPOINTMENTS_ERROR,
    FETCH_USER_APPOINTMENTS_REQUEST_SENT,
    FETCH_USER_APPOINTMENTS_SUCCESS,
    FETCH_USER_NOTES_ERROR,
    FETCH_USER_NOTES_REQUEST_SENT,
    FETCH_USER_NOTES_SUCCESS,
    GET_DATE_RANGE_ERROR,
    GET_DATE_RANGE_SUCCESS,
    GET_PRICE_LIST_ERROR,
    GET_PRICE_LIST_SUCCESS,
    GET_USER_PROFILE,
    GET_USER_PROFILE_ERROR,
    LOGIN_REQUEST_SUCCESS,
    NO_MORE_ENTRIES,
    NOTES,
    PRICES,
    PROCESS_PROVIDER_IMAGE,
    PROFILE,
    REQUEST_SENT,
    RESET_SELECTED_USER_ENTRIES,
    RESET_SELECTED_USER_ENTRIES_SUCCESS,
    RESET_SELECTED_USER_SYMPTOMS,
    SELECT_APPOINTMENT,
    SELECT_APPOINTMENT_IN_PROGRESS,
    SELECT_GROUP_APPOINTMENT,
    SELECT_USER,
    SET_FORM_TO_DISABLED,
    SET_FORM_TO_ENABLED,
    UPDATE_APPOINTMENT_STATUS_REQUEST_ERROR,
    UPDATE_APPOINTMENT_STATUS_REQUEST_SENT,
    UPDATE_CONSULTATION_MENU_ITEM,
    UPDATE_CONSULTATION_MENU_ITEM_ERROR,
    UPDATE_DATE_RANGE,
    UPDATE_DATE_RANGE_ERROR,
    UPDATE_ENCOUNTER_REQUEST_ERROR,
    UPDATE_ENCOUNTER_REQUEST_SENT,
    UPDATE_ENCOUNTER_REQUEST_SUCCESS,
    UPDATE_NOTE_REQUEST_ERROR,
    UPDATE_NOTE_REQUEST_SENT,
    UPDATE_USER_PASSWORD,
    UPDATE_USER_PASSWORD_ERROR,
    UPDATE_USER_PROFILE,
    UPDATE_USER_PROFILE_ERROR
} from "./actions"
import history from '../helpers/history'
import {Routes} from "../constants/routes";
import {fetchSelectedUserSymptomsLog} from "./symptoms_actions";
import {fetchSelectedUserDocuments} from "./documents_actions";
import {fetchSelectedUserMedicationPlan} from "./medications_actions";
import {fetchSelectedUserGeneralPractitioner} from "./generalPractitioner_actions";
import {fetchSelectedUserLabResults} from "./lab-results_actions";
import {LOG_ENTRY} from '../constants/information-retrieval-types';
import {fetchConfiguration, getUserSettings} from "./settings_actions";
import {appointmentUtils} from "../utils/appointmentUtils";
import store from '../store'

/**
 * Get all users action, call the service and dispatch the appropiate reducer method
 *
 * @param {object} params - params to send to service
 * @param {string} userToSelect - id of the user to select after the data is fetched
 * @param {boolean} resetList - flag, if set to true reset the users list, otherwise append to it
 * @returns {function} dispatch function
 */
export function getAllPatients(params, userToSelect, resetList) {
    if (!params) {
        params = {
            size: 1000,
            page: 0
        }
    }
    return (dispatch, getState) => {
        dispatch({type: ALL_USERS_REQUEST_SENT});
        return usersService.getAllPatients(params)
            .then((res) => {
                const selectedUser = getState().selectedUser.data;
                dispatch({type: ALL_USERS_SUCCESS, result: res, reset: resetList});
                if (res.content.length > 0) {
                    //Check if we should select an user after the refresh or if there is any selected already
                    if (userToSelect || !selectedUser.id) {
                        let user;
                        //Get the user to select
                        if (userToSelect) {
                            user = res.content.filter(u => u.id === userToSelect)
                        }
                        //If the user to select is in the list, select it
                        if (user && user.length > 0) {
                            user = user[0];
                        } else {
                            user = null;
                        }

                        if (user) {
                            dispatch(selectUser(user, true));
                        }
                    }
                }
            })
            .catch((err) => {
                dispatch({type: ALL_USERS_ERROR, result: err});
            })
    }
}

/**
 * Get user info action.
 *
 * @returns {function} dispatch function
 */
export function getUserInfo(login) {
    return (dispatch) => {
        return usersService.getUserInfo()
            .then((res) => {
                if (res) {
                    dispatch({type: GET_USER_PROFILE, result: res});
                    if (login) {
                        dispatch(getUserSettings(res.id));
                        dispatch(fetchConfiguration())
                    }
                    return res
                }
            })
            .catch((err) => {
                dispatch({type: GET_USER_PROFILE_ERROR, result: err});
            })
    }
}

/**
 * Get user info action.
 *
 * @returns {function} dispatch function
 */
export function getAppointmentStats(timeZone, params) {
    return (dispatch) => {
        usersService.getAppointmentsStats(timeZone, params)
            .then((res) => {
                if (res) {
                    dispatch({type: FETCH_PROVIDER_APPOINTMENTS_STATS_SUCCESS, result: res});
                }
            })
            .catch((err) => {
                dispatch({type: FETCH_PROVIDER_APPOINTMENTS_STATS_ERROR, result: err});
            })
    }
}


/**
 * Post practitioner timetable
 *
 * @param {object} timeTable the new practitioner timetable object
 * @returns {function} dispatch function
 */
export function postNewTimetable(timeTable) {
    return (dispatch) => {
        return usersService.createNewDateRange(timeTable)
            .then((res) => {
                if (res) {
                    dispatch({type: CREATE_NEW_DATE_RANGE, result: res});
                }
            })
            .catch((err) => {
                dispatch({type: CREATE_NEW_DATE_RANGE_ERROR, result: err});
            })
    }
}

/**
 * put practitioner timetable
 *
 * @param {object} timeTable the new practitioner timetable object
 * @returns {function} dispatch function
 */
export function updateTimetable(timeTable) {
    return (dispatch) => {
        return usersService.updateDateRange(timeTable)
            .then((res) => {
                if (res) {
                    dispatch({type: UPDATE_DATE_RANGE, result: res});
                }
            })
            .catch((err) => {
                dispatch({type: UPDATE_DATE_RANGE_ERROR, result: err});
            })
    }
}

/**
 * Delete practitioner timetable
 *
 * @param {object} timeTable the new practitioner timetable object
 * @returns {function} dispatch function
 */
export function deleteTimetable(id) {
    return (dispatch) => {
        return usersService.deleteDateRange(id)
            .then((res) => {
                if (res) {
                    dispatch({type: DELETE_DATE_RANGE_SUCCESS, result: res});
                }
            })
            .catch((err) => {
                dispatch({type: DELETE_DATE_RANGE_ERROR, result: err});
            })
    }
}

/**
 * Post practitioner timetable
 *
 * @param {object} timeTable the new practitioner timetable object
 * @returns {function} dispatch function
 */
export function fetchTimeTable() {
    return (dispatch) => {
        dispatch({type: FETCH_APPOINTMENTS_REQUEST_SENT});
        return usersService.getDateRanges(null).then((res) => {
            if (res) {
                dispatch({type: GET_DATE_RANGE_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: GET_DATE_RANGE_ERROR, result: err});
        });
    }
}


/**
 * Fetch practitioner priceList
 *
 * @returns {function} dispatch function
 */
export function fetchPriceList() {
    return (dispatch) => {
        dispatch({type: FETCH_PRICE_LIST_REQUEST_SENT});
        return usersService.getPriceList(null).then((res) => {
            if (res) {
                dispatch({type: GET_PRICE_LIST_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: GET_PRICE_LIST_ERROR, result: err});
        });
    }
}


/**
 * Delete consultation menu option
 *
 * @param {string} id menu option to delete
 * @returns {function} dispatch function
 */
export function deleteConsultationMenuOption(id) {
    return (dispatch) => {
        return usersService.deleteConsultationMenuOption(id)
            .then((res) => {
                if (res) {
                    dispatch({type: DELETE_CONSULTATION_MENU_ITEM_SUCCESS, result: res});
                }
            })
            .catch((err) => {
                dispatch({type: DELETE_CONSULTATION_MENU_ITEM_ERROR, result: err});
            })
    }
}

/**
 * Post Consultation Menu Option
 *
 * @param {object} consultationMenuOption the new Consultation Menu Option object
 * @returns {function} dispatch function
 */
export function postNewConsultationMenuOption(consultationMenuOption) {
    return (dispatch) => {
        dispatch({type: SET_FORM_TO_DISABLED, formName: PRICES});
        return usersService.createNewConsultationMenuOption(consultationMenuOption)
            .then((res) => {
                if (res) {
                    dispatch({type: CREATE_NEW_CONSULTATION_MENU_ITEM, result: res});
                    dispatch({type: SET_FORM_TO_ENABLED});
                }
            })
            .catch((err) => {
                dispatch({type: CREATE_NEW_CONSULTATION_MENU_ITEM_ERROR, result: err});
                dispatch({type: SET_FORM_TO_ENABLED});
            })
    }
}

/**
 * put Consultation Menu Option
 *
 * @param {object} consultationMenuOption the Consultation Menu Option object to update
 * @returns {function} dispatch function
 */
export function updateConsultationMenuOption(consultationMenuOption) {
    return (dispatch) => {
        return usersService.updateConsultationMenuOption(consultationMenuOption)
            .then((res) => {
                if (res) {
                    dispatch({type: UPDATE_CONSULTATION_MENU_ITEM, result: res});
                }
            })
            .catch((err) => {
                dispatch({type: UPDATE_CONSULTATION_MENU_ITEM_ERROR, result: err});
            })
    }
}


/**
 * Update user info action
 *
 * @param {object} userInfo the new user info data
 * @returns {function} dispatch function
 */
export function updateUserInfo(userInfo) {
    return (dispatch, getState) => {
        dispatch({type: SET_FORM_TO_DISABLED, formName: PROFILE});
        return usersService.putUserInfo(userInfo)
            .then((res) => {
                if (res) {
                    dispatch({type: UPDATE_USER_PROFILE, result: res});
                    const selectedUser = getState().selectedUser.data;
                    if (selectedUser && selectedUser.id === res.id) {
                        dispatch(selectUser(res, true));
                    }
                    dispatch({type: SET_FORM_TO_ENABLED});
                }
                return res;
            })
            .catch((err) => {
                dispatch({type: UPDATE_USER_PROFILE_ERROR, result: err});
                dispatch({type: SET_FORM_TO_ENABLED});
            })
    }
}

/**
 * Update user password action
 *
 * @param {object} userPassword the new user password
 * @returns {function} dispatch function
 */
export function updateUserPassword(userPassword) {
    return (dispatch) => {
        dispatch({type: REQUEST_SENT});
        usersService.updateUserPassword(userPassword)
            .then((res) => {
                if (res) {
                    if (res.access_token && res.refresh_token) {
                        dispatch({type: LOGIN_REQUEST_SUCCESS, response: res});
                        dispatch({type: UPDATE_USER_PASSWORD, result: res});
                    }
                }
            })
            .catch((err) => {
                dispatch({type: UPDATE_USER_PASSWORD_ERROR, result: err});
            })
    }
}

/**
 * Clear the user password action.
 *
 * @returns {function} dispatch function
 */
export function clearUserPassword() {
    return (dispatch) => {
        dispatch({type: CLEAR_USER_PASSWORD});
    }
}

/**
 * Clear the user list action.
 *
 * @returns {function} dispatch function
 */
export function clearPatients() {
    return (dispatch) => {
        dispatch({type: CLEAR_PATIENTS});
    }
}

/**
 * Select user action, call the service and dispatch the appropiate reducer method
 *
 * @param {object} user - the selected user object
 * @param {boolean} stopRedirect - flag, if set to true do not redirect to main page after selecting user
 * @returns {function} dispatch function
 */
export function selectUser(user, stopRedirect) {
    return (dispatch) => {
        dispatch({type: SELECT_USER, user: user});
        dispatch(fetchSelectedUserMedicalProfile(user.id));
        dispatch(fetchDashboardChartsData(user.id));
        dispatch(fetchSelectedUserLatestMeasurements(user.id));
        dispatch(fetchSelectedUserLogbookEntries(user.id));
        dispatch(fetchSelectedUserSymptomsLog(user.id));
        dispatch(fetchChartsData(user.id));
        dispatch(fetchSelectedUserDocuments(user.id));
        dispatch(fetchSelectedUserMedicationPlan(user.id));
        dispatch(fetchSelectedUserGeneralPractitioner(user.id));
        dispatch(fetchSelectedUserLabResults(user.id));
        //Redirect to main page
        if (history.location !== Routes.MAIN_PAGE && !stopRedirect) {
            history.push(Routes.MAIN_PAGE);
        }
    }
}

/**
 * Clear the seleced user data action
 *
 * @returns {function} dispatch function
 */
export function clearSelectedUserData() {
    return (dispatch) => {
        dispatch({type: CLEAR_SELECTED_USER})
    }
}

/**
 * Fetch the medical profile for the given user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @returns {function} dispatch function
 */
export function fetchSelectedUserMedicalProfile(userId) {
    return (dispatch, getState) => {
        return usersService.fetchSelectedUserMedicalProfile(userId).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: FETCH_SELECTED_USER_MEDICAL_PROFILE_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: FETCH_SELECTED_USER_MEDICAL_PROFILE_ERROR, result: err});
        })
    }
}

/**
 * Fetch latest logged data for the given user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @returns {function} dispatch function
 */
export function fetchSelectedUserLatestMeasurements(userId) {
    return (dispatch, getState) => {
        return usersService.fetchSelectedUserLatestMeasurements(userId).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res && res.length > 0) {
                dispatch({type: FETCH_SELECTED_USER_LATEST_MEASUREMENTS_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: FETCH_SELECTED_USER_LATEST_MEASUREMENTS_ERROR, result: err});
        })
    }
}

/**
 * Fetch the logbook entries for the given user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @param {object} params - params to send with the request
 * @param {boolean} resetList - flag, if set to true reset the logbook list
 * @returns {function} dispatch function
 */
export function fetchSelectedUserLogbookEntries(userId, params, resetList) {
    return (dispatch, getState) => {
        dispatch({type: FETCH_SELECTED_USER_ENTRIES_REQUEST_SENT});
        return usersService.fetchSelectedUserLogbookEntries(userId, params).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            res = res.data[LOG_ENTRY];
            if (res && res.length > 0) {
                if (resetList) {
                    dispatch({type: RESET_SELECTED_USER_ENTRIES_SUCCESS, result: res});
                } else {
                    dispatch({type: FETCH_SELECTED_USER_ENTRIES_SUCCESS, result: res});
                }

            } else {
                dispatch({type: NO_MORE_ENTRIES});
                if (resetList) {
                    dispatch({type: RESET_SELECTED_USER_ENTRIES_SUCCESS, result: res});
                }
            }
        }).catch((err) => {
            dispatch({type: FETCH_SELECTED_USER_ENTRIES_ERROR, result: err});
        })
    }
}

/**
 * Explicitly reset selected user logbook entries
 * @return {function} dispatch function
 */
export function resetSelectedUserLogbookEntries() {
    return (dispatch) => {
        dispatch({type: RESET_SELECTED_USER_ENTRIES});
    }
}

/**
 * Explicitly reset selected user symptoms list
 * @return {function} dispatch function
 */
export function resetSelectedUserSymptoms() {
    return (dispatch) => {
        dispatch({type: RESET_SELECTED_USER_SYMPTOMS});
    }
}

/**
 * Fetch the charts data for the given user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @returns {function} dispatch function
 */
export function fetchChartsData(userId) {
    return (dispatch, getState) => {
        const state = getState();
        let params = {
            language: state.language.selected.lang,
            period: state.charts.filters.period
        };
        if (state.charts.filters.before_date_time) {
            params.before_date_time = state.charts.filters.before_date_time;
        }
        if (state.charts.filters.after_date_time) {
            params.after_date_time = state.charts.filters.after_date_time;
        }
        return usersService.fetchSelectedUserChartsData(userId, params).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: FETCH_CHARTS_DATA_SUCCESS, result: res, userId: userId})
            }
        }).catch((err) => {
            dispatch({type: FETCH_CHARTS_DATA_ERROR, result: err});
        });
    }
}

/**
 * Fetch the latest charts data for the given user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @returns {function} dispatch function
 */
export function fetchDashboardChartsData(userId) {
    return (dispatch, getState) => {
        const state = getState();
        let params = {
            language: state.language.selected.lang,
            period: 'WEEK'
        };
        return usersService.fetchSelectedUserChartsData(userId, params).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: FETCH_DASHBOARD_CHARTS_DATA_SUCCESS, result: res, userId: userId})
            }
        }).catch((err) => {
            dispatch({type: FETCH_DASHBOARD_CHARTS_DATA_ERROR, result: err});
        });
    }
}

/**
 * Change the filters to apply to chart requests.
 *
 * @param {object} filters - the new set of filters
 * @returns {function} dispatch function
 */
export function changeChartsFilters(filters) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_CHARTS_FILTERS,
            period: filters.period,
            beforeDateTime: filters.beforeDateTime,
            afterDateTime: filters.afterDateTime
        });
    }
}

/**
 * Fetch the appointments for the provider, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @param  intervalStart - Appointment start
 * @param  intervalEnd - Appointment end
 * @returns {function} dispatch function
 */
export function fetchUserAppointments(userId) {
    return (dispatch, getState) => {
        let params = {
            after: 0,
            before: appointmentUtils.calculateMaxFetchAppointmentsTimestamp(),
            userId: userId
        };
        dispatch({type: FETCH_USER_APPOINTMENTS_REQUEST_SENT});
        return usersService.fetchUserAppointments(userId, params).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: FETCH_USER_APPOINTMENTS_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: FETCH_USER_APPOINTMENTS_ERROR, result: err});
        });
    }
}

/**
 * Fetch the appointments for the provider, dispatch the appropriate action.
 *
 * @param {object} encounterId - id of the selected Encounter
 * @returns {function} dispatch function
 */
export function fetchAppointmentEncounter(encounterId) {
    return (dispatch, getState) => {
        let params = {};
        dispatch({type: FETCH_APPOINTMENT_USER_REQUEST_SENT});
        return usersService.fetchAppointmentEncounter(encounterId, params).then((res) => {
            // eslint-disable-next-line no-undef
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: FETCH_APPOINTMENT_USER_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: FETCH_APPOINTMENT_USER_ERROR, result: err});
        });
    }
}

/**
 * Fetch the appointments for the provider, dispatch the appropriate action.
 *
 * @param  intervalStart - Appointment start
 * @param  intervalEnd - Appointment end
 * @returns {function} dispatch function
 */
export function fetchProviderAppointments(intervalStart, intervalEnd) {
    return (dispatch, getState) => {
        let params = {
            after: intervalStart,
            before: intervalEnd,
        };

        dispatch({type: FETCH_APPOINTMENTS_REQUEST_SENT});
        return usersService.fetchProviderAppointments(params).then((res) => {
            if (res) {
                dispatch({type: FETCH_APPOINTMENTS_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: FETCH_APPOINTMENTS_ERROR, result: err});
        });
    }
}

/**
 * Clear provider appointments action
 *
 * @returns {function} dispatch function
 */
export function clearProviderAppointments() {
    return (dispatch) => {
        dispatch({type: CLEAR_PROVIDER_APPOINTMENTS})
    }
}

/**
 * Fetch the last appointment for a patient, dispatch the appropriate action.
 *
 * @param {string} userId - id of the selected patient
 * @param {Date} intervalStart - the after date and time
 * @param {Date} intervalEnd - the before date and time
 * @param {number} limit - the limit of the records to fetch
 * @returns {function} dispatch function
 */
export function fetchLastAppointment(userId, intervalEnd) {
    return (dispatch, getState) => {
        let params = {
            before: intervalEnd,
            userId: userId,
        };
        dispatch({type: FETCH_LAST_APPOINTMENT_REQUEST_SENT});
        return usersService.lastCompletedAppointment(params).then((res) => {
            /*if (getState().selectedAppointment.data.id !== res[0].id) {
                dispatch({type: DUMMY});
            }*/
            if (res) {
                dispatch({type: FETCH_LAST_APPOINTMENT_SUCCESS, result: [res]});
            } else {
                dispatch({type: FETCH_LAST_APPOINTMENT_SUCCESS, result: []});
            }
        }).catch((err) => {
            dispatch({type: FETCH_LAST_APPOINTMENT_ERROR, result: err});
        });
    }
}

/**
 * Set the selected appointment.
 *
 * @param {object} selectedAppointment - the selected appointment object
 * @returns {function} dispatch function
 */
export function selectAppointment(selectedAppointment) {
    return (dispatch) => {
        dispatch({type: SELECT_APPOINTMENT, result: selectedAppointment});
    }
}

/**
 * Clear the selected appointment action
 *
 * @returns {function} dispatch function
 */
export function clearSelectedAppointment() {
    return (dispatch) => {
        dispatch({type: CLEAR_SELECTED_APPOINTMENT})
    }
}

/**
 * Set the selected appointment to be used for Appointments page.
 *
 * @param {object} selectedAppointment - the selected appointment object
 * @returns {function} dispatch function
 */
export function selectAppointmentInProgress(selectedAppointment) {
    return (dispatch) => {
        dispatch({type: SELECT_APPOINTMENT_IN_PROGRESS, appointment: selectedAppointment});
    }
}

export function selectGroupAppointment(groupAppointment) {
    return (dispatch) => {
        dispatch({type: SELECT_GROUP_APPOINTMENT, appointment: groupAppointment});
    }
}

export function clearGroupAppointment() {
    return (dispatch) => {
        dispatch({type: CLEAR_GROUP_APPOINTMENT});
    }
}

/**
 * Clear the selected appointment for Appointments page.
 *
 * @returns {function} dispatch function
 */
export function clearSelectedAppointmentInProgress() {
    return (dispatch) => {
        dispatch({type: CLEAR_SELECTED_APPOINTMENT_IN_PROGRESS})
    }
}

/**
 * Fetch all notes for the selected user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @returns {function} dispatch function
 */
export function fetchUserNotes(userId) {
    return (dispatch, getState) => {
        dispatch({type: FETCH_USER_NOTES_REQUEST_SENT});
        return usersService.fetchUserNotes(userId).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: FETCH_USER_NOTES_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: FETCH_USER_NOTES_ERROR, result: err});
        });
    }
}

/**
 * Fetch all notes for the selected user, dispatch the appropriate action.
 *
 * @param {string} userId - id of the selected user
 * @param {string} appointmentId - id of the appointment
 * @returns {function} dispatch function
 */
export function fetchUserNotesForAppointment(userId, appointmentId) {
    return (dispatch, getState) => {
        dispatch({type: FETCH_USER_NOTES_REQUEST_SENT});
        return usersService.fetchUserNotesForAppointment(userId, appointmentId).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: FETCH_USER_NOTES_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: FETCH_USER_NOTES_ERROR, result: err});
        });
    }
}

/**
 * Update selected note, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @param {object} note - note to be updated
 * @param {object} appointmentId - appointmentid
 * @returns {function} dispatch function
 */
export function updateNote(userId, note, appointmentId) {
    return (dispatch, getState) => {
        dispatch({type: UPDATE_NOTE_REQUEST_SENT});
        return usersService.editNote(note).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                if (appointmentId) {
                    dispatch(fetchUserNotesForAppointment(userId, appointmentId));
                } else {
                    dispatch(fetchUserNotes(userId));
                }
            }
        }).catch((err) => {
            dispatch({type: UPDATE_NOTE_REQUEST_ERROR, result: err});
        });
    }
}

/**
 * Delete selected note, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @param {object} noteId - id of the note to be deleted
 * @param {object} appointmentId - appointmentid
 * @returns {function} dispatch function
 */
export function deleteNote(userId, noteId, appointmentId) {
    return (dispatch, getState) => {
        dispatch({type: DELETE_NOTE_REQUEST_SENT});
        return usersService.deleteNote(noteId).then(() => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (appointmentId) {
                dispatch(fetchUserNotesForAppointment(userId, appointmentId));
            } else {
                dispatch(fetchUserNotesForAppointment(userId, appointmentId));
            }
        }).catch((err) => {
            dispatch({type: DELETE_NOTE_REQUEST_ERROR, result: err});
        });
    }
}

/**
 * Create new note for selected user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @param {object} note - the new note to be created
 * @param {string} appointmentId id of the appointment to create note for
 * @param {string} encounterId id of the encounter to create note for
 * @returns {function} dispatch function
 */
export function createNote(userId, note, appointmentId, encounterId) {
    return (dispatch, getState) => {
        dispatch({type: SET_FORM_TO_DISABLED, formName: NOTES});
        dispatch({type: CREATE_NOTE_REQUEST_SENT});
        return usersService.createNote(note, appointmentId, encounterId).then(() => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (appointmentId) {
                dispatch(fetchUserNotesForAppointment(userId, appointmentId));
            } else {
                dispatch(fetchUserNotes(userId));
            }
            dispatch({type: SET_FORM_TO_ENABLED});
        }).catch((err) => {
            dispatch({type: CREATE_NOTE_REQUEST_ERROR, result: err});
            dispatch({type: SET_FORM_TO_ENABLED});
        });
    }
}

/**
 * Create new encounter for selected appointment, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @param {string} appointmentId id of the appointment to create encounter for
 * @param {string} encounter the new encounter to be created
 * @returns {function} dispatch function
 */
export function createEncounter(userId, appointmentId, encounter) {
    return (dispatch, getState) => {
        dispatch({type: CREATE_ENCOUNTER_REQUEST_SENT});
        return usersService.createEncounter(appointmentId, encounter).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: CREATE_ENCOUNTER_REQUEST_SUCCESS, result: res});
            }
            return res;
        }).catch((err) => {
            dispatch({type: CREATE_ENCOUNTER_REQUEST_ERROR, result: err});
        });
    }
}


export function createEncounterFromNhisImport(appointmentId, encounter) {
    return usersService.createEncounter(appointmentId, encounter).then((res) => {
        if (res) {
            store.dispatch({type: CREATE_ENCOUNTER_REQUEST_SUCCESS, result: res});
        }
        return res;
    });
}

/**
 * Update encounter for selected appointment, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @param {object} encounter - encounter to be updated
 * @returns {function} dispatch function
 */
export function updateEncounter(userId, encounter) {
    return (dispatch, getState) => {
        dispatch({type: UPDATE_ENCOUNTER_REQUEST_SENT});
        return usersService.updateEncounter(encounter).then((res) => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: UPDATE_ENCOUNTER_REQUEST_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: UPDATE_ENCOUNTER_REQUEST_ERROR, result: err});
        });
    }
}

/**
 * Update status for selected appointment, dispatch the appropriate action.
 *
 * @param {object} appointmentId - id of the selected appointment
 * @param {object} status - new status for selected appointment
 * @param {object} note - note to be sent with the appointment
 * @returns {function} dispatch function
 */
export function updateAppointmentStatus(appointmentId, status, note, preselect) {
    return (dispatch) => {
        dispatch({type: UPDATE_APPOINTMENT_STATUS_REQUEST_SENT});
        return usersService.updateAppointmentStatus(appointmentId, status, note).then((res) => {
            if (res) {
                if (res.status.indexOf('CANCELED_BY') === -1) {
                    dispatch(selectAppointment(res));
                    dispatch(selectAppointmentInProgress(res));
                } else {
                    dispatch(preselect ? selectAppointment(res) : clearSelectedAppointment())
                    dispatch(clearSelectedAppointmentInProgress());
                }
                dispatch({type: CHANGE_APPOINTMENT_IN_APPOINTMENTS_LIST, result: res});
            }
        }).catch((err) => {
            dispatch({type: UPDATE_APPOINTMENT_STATUS_REQUEST_ERROR, result: err});
        });
    }
}

/**
 * Update payment status to COMPLETED for selected appointment, dispatch the appropriate action.
 *
 * @param {object} appointmentId - id of the selected appointment
 * @returns {function} dispatch function
 */
export function markAppointmentAsPaid(appointmentId) {
    return (dispatch) => {
        dispatch({type: UPDATE_APPOINTMENT_STATUS_REQUEST_SENT});
        return usersService.markAppointmentAsPaid(appointmentId).then((res) => {
            if (res) {
                dispatch(selectAppointment(res));
                dispatch(selectAppointmentInProgress(res));
                dispatch({type: CHANGE_APPOINTMENT_IN_APPOINTMENTS_LIST, result: res});
            }
        }).catch((err) => {
            dispatch({type: UPDATE_APPOINTMENT_STATUS_REQUEST_ERROR, result: err});
        });
    }
}

/**
 * Clear the selected encounter for Appointments page.
 *
 * @returns {function} dispatch function
 */
export function clearSelectedEncounter() {
    return (dispatch) => {
        dispatch({type: CLEAR_SELECTED_ENCOUNTER});
    }
}

/**
 * Fetch all the available specialties
 *
 * @param {object} params params sent together with the request
 * @returns {object} dispatch function
 */
export function fetchSpecialties(params) {
    return (dispatch) => {
        dispatch({type: FETCH_SPECIALTIES_REQUEST_SENT});
        return usersService.fetchSpecialties(params).then((res) => {
            if (res && res.length > 0) {
                dispatch({type: FETCH_SPECIALTIES_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: FETCH_SPECIALTIES_ERROR, result: err});
        })
    }
}

/**
 * Updates the provider image
 *
 * @param {object} image the image file
 * @returns {object} dispatch function
 */
export function processImage(image) {
    return (dispatch) => {
        dispatch({type: PROCESS_PROVIDER_IMAGE, result: image});
    }
}

/**
 * Sets the value of the 'should display provider image' field
 *
 * @param {boolean} shouldDisplay the boolean value
 * @returns {object} dispatch function
 */
export function displayProviderImage(shouldDisplay) {
    return (dispatch) => {
        dispatch({type: DISPLAY_PROVIDER_IMAGE, result: shouldDisplay});
    }
}

/**
 * Clear the user appointments list
 * @return {function} dispatch function
 */
export function clearUserAppointments() {
    return (dispatch) => {
        dispatch({type: CLEAR_USER_APPOINTMENTS})
    }
}