import React, {Component} from 'react'
import {updateAppointmentStatus, usersService} from "../../service/users_service";
import {$$, _$$} from "../../helpers/localization";
import PropTypes from "prop-types";
import {Link} from "react-router-dom"
import {Routes} from "../../constants/routes";
import {Modal} from "react-bootstrap";
import {formatDate} from "../../utils/converter";
import history from '../../helpers/history'
import {Consultation} from "../consultations/Consultations";
import CenteredModal from "../shared/CenteredModal";
import {appointmentUtils} from "../../utils/appointmentUtils";
import moment from "moment";
import CancelAppointmentModal from "../appointment/CancelAppointmentModal";

export default class ActiveTextConsultations extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            consultations: [],
            initialState: true
        }
    }

    componentDidMount() {
        usersService.fetchProviderActiveTextConsultations().then(res => {
            if (res) {
                let urgent = firstUrgentConsultation(res);
                let showAlert = urgent && !alertShown(urgent);
                this.setState({consultations:res, stats:createStats(res, this.props.userId), urgent: urgent, showAlert:showAlert})
            }
            this.setState({initialState:false, err:undefined});
        }).catch((err) => {
            this.setState({initialState:false, err:err});
        });
    }

    cancelConsultation = (app, note) => {
        // eslint-disable-next-line no-unused-vars
        updateAppointmentStatus(this.state.consultationToCancel.id, "CANCELED_BY_PROVIDER", note).then(r => {
            this.setState({showCancelConsultationModal: false, consultationToCancel: undefined})
            this.componentDidMount();
        });
    }

    render() {

        if (this.state.initialState) {
            return <div className="text-center p-3">
                {$$("loading_data_label")}
            </div>
        }

        if (this.state.consultations.length == 0) {
            return null;
        }

        return  <div>

            {this.state.urgent && <Modal show={this.state.showAlert} onHide={()=>{
                this.setState({showAlert:false})
                storeInSessionStorage(this.state.urgent)
            }} backdrop={"static"}>
                <Modal.Header closeButton>
                    <h5 className={"text-danger"}>{$$("warning_title")}</h5>
                </Modal.Header>
                <Modal.Body>
                    <p dangerouslySetInnerHTML={{ __html: _$$(this.state.stats.no_action === 1 ? "new_text_consultations_popup_body_singular" : "new_text_consultations_popup_body", [this.state.stats.no_action]) }} />
                    <p dangerouslySetInnerHTML={{ __html: _$$(this.state.stats.no_action === 1 ? "new_text_consultations_popup_body_2_singular" :"new_text_consultations_popup_body_2", [formatDate(this.state.urgent.ends_at)])}} />
                </Modal.Body>
                <Modal.Footer>
                    <button className={"btn btn-secondary mr-auto"} onClick={()=>{
                        this.setState({showAlert:false});
                        storeInSessionStorage(this.state.urgent)
                    }}>{$$("close_btn_label")}</button>
                    <button className={"btn btn-primary"} onClick={()=>{
                        this.setState({showAlert:false});
                        storeInSessionStorage(this.state.urgent)
                        history.push(Routes.CONSULTATIONS);
                    }}>{$$("open_consultations")}</button>
                </Modal.Footer>
            </Modal>}


            {this.state.urgent && <div className="d-flex justify-content-between align-items-center">
                <div className="next-patient-header">
                    {$$("earliest_expiring_consultation")}
                </div>
                <div>
                    <Link className="see-all-notifications"
                          to={Routes.CONSULTATIONS}>{$$('see_all_label')}</Link>
                </div>
            </div>
            }

            {this.state.urgent && <div className="low-shadow-container mb-3">
                <table className="table table-borderless medrec-default-text-color consultations-page-table">
                    <Consultation c={this.state.urgent} onCancel={(c) => {this.setState({consultationToCancel:c, showCancelConsultationModal:true})}}/>
                </table>
            </div>
            }

            {this.state.consultationToCancel &&
            <CancelAppointmentModal
                appointment={this.state.consultationToCancel}
                show={this.state.showCancelConsultationModal}
                onHide={() => {
                    this.setState({showCancelConsultationModal: false, consultationToCancel: undefined})
                }}
                onConfirm={this.cancelConsultation}
            />}


            <div className="d-flex justify-content-between align-items-center">
                <div className="next-patient-header">
                    {$$("consultations_dashboard")}
                </div>
                <div>
                    <Link className="see-all-notifications"
                          to={Routes.CONSULTATIONS}>{$$('see_all_label')}</Link>
                </div>
            </div>
            <div className="low-shadow-container d-flex justify-content-between">
                <span className="now-next-later-icons text-consultations-panel"><i
                    className="kt-menu__link-icon now-next-later-icon flaticon-notes"></i>
                </span>
                <div className="w-100">
                    <h1 className="p-3 text-center text-thin medrec-red-2">{this.state.stats.no_action}</h1>
                    <div className="w-100 text-center medrec-red-2">{$$("urgent_consultations")}</div>
                </div>
                <div className="text-align-center w-100">

                    <h1 className="p-3 text-center text-thin">{this.state.stats.not_completed}</h1>
                    <div className="w-100 text-center">{$$("uncompleted_consultations")}</div>
                </div>
                <div className="text-align-center w-100">
                    <h1 className="p-3 text-center text-thin">{this.state.stats.completed}</h1>
                    <div className="w-100 text-center">{$$("completed_consultations")}</div>
                </div>
            </div>
        </div>
    }
}


// eslint-disable-next-line no-unused-vars
function createStats(res, userId) {
    let stats = {
        no_action:0,
        not_completed:0,
        completed: 0
    }

    for (let i = 0; i < res.length; i++) {
        const re = res[i];
        if (re.first_response_time === 0) {
            stats.no_action += 1;
        } else if (re.status !== "COMPLETED") {
            stats.not_completed += 1;
        } else {
            stats.completed += 1;
        }
    }
    return stats;
}

function firstUrgentConsultation(consultations) {
    for (let i = 0; i < consultations.length; i++) {
        const consultation = consultations[i];
        if (consultation.first_response_time === 0) {
            return consultation;
        }
    }
    return null;
}

function alertShown(consultation) {
    return sessionStorage.getItem("utcpopup") === consultation.id;
}

function storeInSessionStorage(consultation) {
    sessionStorage.setItem("utcpopup", consultation.id);
}

ActiveTextConsultations.propTypes = {
    userId: PropTypes.string,
}