import React from 'react'
import {connect} from 'react-redux'
import {
    displayProviderImage,
    fetchSpecialties
} from '../../actions/users_actions'
import {clearRegistrationData, register} from '../../actions/register_actions'
import {uploadLoggedUserDocument} from '../../actions/documents_actions'
import {logout} from '../../actions/auth_actions'
import {Wizard} from './wizard/Wizard'
import CenteredModal from '../shared/CenteredModal'
import {Routes} from '../../constants/routes'
import {$$} from '../../helpers/localization'
import PropTypes from "prop-types";
import {BoolSwitch} from "../examination/nhis/bg/BoolSwitch";


class Register extends React.Component {
    state = {
        showModal: false,
        showWizard: true,
        chooseType: true,
        internal: false
    }

    constructor(props) {
        super(props)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.registration.response && props.registration.response.id) {
            props.clearRegistrationData();
            props.displayProviderImage(false);
            return {
                showModal: true,
                showWizard: false,
            }
        }
        return state;
    }

    componentDidMount() {
        this.props.fetchSpecialties();
    }

    /**
     * Confirm the registration success modal, navigate to login
     */
    onConfirmModal = () => {
        try {
            Notification.requestPermission()
        } catch (error) {
            console.log(error)
        }

        if (this.props.userInfo.data.internal) {
            this.props.history.push(Routes.SELECT_PRACTICE);
        } else {
            this.props.history.push(Routes.DASHBOARD);
        }
    }

    /**
     * If canceling the modal, logout is performed and navigation to login
     */
    onCancelModal = () => {
        this.props.logout()
        this.props.history.push(Routes.LOGIN);
    }

    /**
     * Registers a particular provider and also saves his/her documents
     */
    registerProvider = (registrationFields, documents) => {
        this.props.register(registrationFields, documents || []);
    }

    render() {
        if (this.state.chooseType) {
            return <div className="d-flex flex-column ml-auto mr-auto max-width-800px">
                <div className="form-group">
                        <p>{$$("register_choose_registration_type")}</p>
                        <BoolSwitch size="normal"
                                    onChange={()=> this.setState({internal: !this.state.internal})}
                                    labelOn={$$("register_internal_unselected_option_text")}
                                    labelOff={$$("register_internal_selected_option_text")} checked={!this.state.internal}
                        />
                        <p className="mt-3">{this.state.internal ? $$("register_other_type_chosen") : $$("register_clinician_type_chosen")}</p>

                </div>

                <div className='form-group'>
                    <div className='row flex-end-justify' style={{'float': 'right', 'margin': '0rem'}}>
                        <button type='button' onClick={this.onCancelModal} className='btn btn-secondary'
                                style={{'marginRight': '0.3125rem'}}>{$$('wizard_back_label')}</button>
                        <button type='button' onClick={() => {this.setState({chooseType:false})}}
                                className='btn btn-success'>{$$('wizard_continue_label')}</button>
                    </div>
                </div>
            </div>
        }

        return (
            <div className="d-flex flex-column ml-auto mr-auto">
                {/** The Provider Vertical Card - displayed when the route is /profile (mode: update) */}
                <Wizard registration={this.props.registration}
                        specialties={this.props.specialties.specialties}
                        registerProvider={this.registerProvider}
                        showWizard={this.state.showWizard}
                        isInternal={this.state.internal}
                        i18n={this.props.i18n}/>
                <CenteredModal title={$$('wizard_completed_modal_title')}
                               show={this.state.showModal}
                               onHide={() => this.setState({showModal: false})}
                               onConfirm={this.onConfirmModal}
                               onCancel={this.onCancelModal}
                               confirmBtnLabel={$$('login_label')}
                               cancelBtnLabel={$$('logout_label')}>
                    {$$('wizard_completed_modal_message')}
                </CenteredModal>
            </div>
        )
    }
}

Register.propTypes = {
    clearRegistrationData: PropTypes.func,
    displayProviderImage: PropTypes.func,
    fetchSpecialties: PropTypes.func,
    history: PropTypes.object,
    i18n: PropTypes.object,
    location: PropTypes.object,
    logout: PropTypes.func,
    match: PropTypes.object,
    register: PropTypes.func,
    registration: PropTypes.object,
    specialties: PropTypes.object,
    uploadLoggedUserDocument: PropTypes.func,
    userInfo: PropTypes.object
}

const mapStateToProps = (state) => ({
    userInfo: state.userInfo,
    registration: state.register,
    specialties: state.specialties,
    i18n: state.language
})

const mapDispatchToProps = {
    fetchSpecialties,
    register,
    clearRegistrationData,
    uploadLoggedUserDocument,
    displayProviderImage,
    logout
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)
