import React, {useCallback, useEffect, useState} from 'react'
import {$$} from "../../helpers/localization";
import CenteredModal2 from "../shared/CenteredModal2";
import {Select} from "../shared/Select";
import {getResolvedOptions, getResolvedText, PATIENT_ID_TYPE} from "../../constants/select_options";
import {DASH} from "../../constants/dash";
import {CONVERTER} from "../../utils/converter";
import {clinicService} from "../../service/clinic_service";
import * as classnames from "classnames";
import {documentsService} from "../../service/docments_service";
import {downloadUtils} from "../../utils/downloadUtils";
import moment from "moment";
import {appointmentUtils} from "../../utils/appointmentUtils";
import {getClinicianNameWithTitle} from "../../utils/getClinicianNameWithTitle";
import {getPhone} from "../../utils/userUtils";

const Reports = [
    {name: "document_type_ambulance_sheet", type: "EncounterOutcome"},
    {name: "document_type_prescription", type: "Prescription"},
    {name: "document_type_epicrisis", type: "Epicrisis"},
    {name: "document_type_medical_report", type: "MedicalReport"}
]

function reportTypeToText(reportType) {
    return $$(Reports.find(value => value.type === reportType).name);
}


export function ReportsWizard({patient, orgId, onClose, getData, lang, updateEncounter}) {
    const [reportType, setReportType] = useState();
    const [patientInfo, setPatientInfo] = useState();
    const [noMedicationsForPrescription, setNoMedicationsForPrescription] = useState(false);

    if (noMedicationsForPrescription) {
        return <NoMedicationsForPrescription onClose={onClose} onBack={()=> {
            setNoMedicationsForPrescription(false)
            setReportType(null)
        }}/>
    }

    if (!reportType) {
        return <ReportPickerModal updateReportType={(type)=> {
            if (type === "Prescription") {
                let data = getData("Prescription", {})
                if (data.examDetails.medications.length === 0) {
                    setNoMedicationsForPrescription(true);
                }
            }
            setReportType(type);
        }} onClose={onClose}/>
    }

    if (!patientInfo){
        return <PersonalInfoModal reportType={reportType} orgId={orgId} requiredFields={personalInfoFieldsRequiredFieldsPerType[reportType]} patient={patient}
                                  onClose={onClose}
                                  onHide={()=>setReportType(undefined)} onSuccess={(pi)=>setPatientInfo(pi)}/>
    }

    return <ReportFormModal lang={lang} patientInfo={patientInfo} reportType={reportType} onBack={()=>{setPatientInfo(undefined)}} onClose={onClose} getData={getData} updateEncounter={updateEncounter}/>
}

export function NoMedicationsForPrescription({onClose, onBack}) {
    return <CenteredModal2 show={true} header={<h3 className="text-danger">{$$("warning_title")}</h3>}
                           onHide={onClose}
                           onCancel={onBack}
                           primary
                           cancelBtnLabel={$$("back")}>
        <h5 className="text-danger">
            {$$("prescription_report_cant_create")}
        </h5>
        <br/>
        <p>
            {$$("no_medications_for_prescription_report_body")}
        </p>
    </CenteredModal2>
}

export function ReportPickerModal({updateReportType, onClose}) {
    const [reportType, setReportType] = useState()
    return <CenteredModal2 show={true} header={<h3>{$$("document_picker_title")}</h3>} onHide={()=>{updateReportType(undefined); onClose()}} onConfirm={()=>{updateReportType(reportType)}} confirmBtnLabel={$$("forward_btn")}>
        <p>{$$("choose_document")}</p>
        <ReportPicker selectReport={setReportType} reportType={reportType}/>
    </CenteredModal2>
}

export function ReportPicker({selectReport, reportType}) {
    return <div className="list-group">
        {
            Reports.map((report, idx) => {
                return <a href="#" className={classnames("list-group-item", {"active":reportType === report.type})} key={idx} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    selectReport(report.type)
                }}>{$$(report.name)}</a>
            })
        }
    </div>
}

const fieldNames = ["patientName", "patientAge", "patientID", "patientAddress", "patientPhone"];

export function PersonalInfoFormFieldset({values, updateValues, requiredFields}) {
    const [required, setRequired] = useState({"patientName": true});
    useEffect(() => {
        let rf = {...required};
        if (requiredFields) {
            for (let i = 0; i < fieldNames.length; i++) {
                let name = fieldNames[i];
                if (requiredFields.indexOf(name) !== -1) {
                    rf[name] = true;
                }
            }
        }
        setRequired(rf)
    }, [requiredFields])

    const updateValueField = (e) => {
        let updatedValues = {...values}
        updatedValues[e.target.name] = e.target.value;
        updateValues(updatedValues)
    }

    const updateSelectField = ({name, value}) => {
        let updatedValues = {...values}
        updatedValues[name] = value;
        updateValues(updatedValues)
    }

    return <fieldset>
        <div className="form-group form-group-md">
            {values.patientName && <label>{$$("title_label")}</label>}
            <input type="text" className="form-control" name="patientName" required={required.patientName}
                   placeholder={$$("title_label")} value={values.patientName} onChange={updateValueField}/>
        </div>
        <div className="form-group form-group-md">
            {values.patientAge && <label>{$$("age_label")}</label>}
            <input type="text" className="form-control" name="patientAge" required={required.patientAge}
                   placeholder={$$("age_label")} value={values.patientAge} onChange={updateValueField}/>
        </div>

        <div className="d-flex">
            <div className="form-group form-group-md">
                <Select name="patientIDType"
                        options={getResolvedOptions(PATIENT_ID_TYPE.TYPE_OPTIONS)}
                        value={values.patientIDType}
                        onChange={updateSelectField}
                        placeHolder={$$('document_type_label')}>
                </Select>
            </div>
            <div className="form-group form-group-md ml-3 flex-fill">
                <input type="text" className="form-control" name="patientID" required={required.patientID}
                       placeholder={getResolvedText(PATIENT_ID_TYPE.TYPE_OPTIONS, values.patientIDType)}
                       value={values.patientID} onChange={updateValueField}/>
            </div>
        </div>
        <div className="form-group form-group-md">
            {values.patientPhone && <label>{$$("phone_label")}</label>}
            <input type="text" className="form-control" name="patientPhone" required={required.patientPhone}
                   placeholder={$$("phone_label")} value={values.patientPhone} onChange={updateValueField}/>
        </div>
        <div className="form-group form-group-md">
            {values.patientAddress && <label>{$$("address_label")}</label>}
            <input type="text" className="form-control" name="patientAddress" required={required.patientAddress}
                   placeholder={$$("address_label")} value={values.patientAddress} onChange={updateValueField}/>
        </div>
    </fieldset>
}

function getAge(birthday) {
    if (birthday === 0) {
        return DASH;
    }
    let age = CONVERTER.millisecondsToAge(birthday);
    return age === 1 ? age + ' ' + $$('year_old') : age + ' ' + $$('years_old');
}

export function PersonalInfoModal({patient, reportType, requiredFields, orgId, onHide, onSuccess, onClose}) {
    const [validated, setValidated] = useState(false)
    const [values, setValues] = useState({
        patientName: patient.fullname,
        patientAge: getAge(patient.birthday),
        patientAddress: "",
        patientIDType: "EGN",
        patientID: "",
        patientPhone: getPhone(patient) || ""
    });

    useEffect(() => {
        clinicService.fetchEncPatientInfo(patient.user_id ?? patient.id, orgId).then(patient => {
            if (patient) {
                setValues(Object.assign({}, values, patient))
            }
        })
    }, [patient])


    const isValid = function () {
        let res = values.patientName !== "";
        if (requiredFields) {
            for (let i = 0; i < requiredFields.length; i++) {
                res = res && !!values[requiredFields[i]];
            }
        }
        return res;
    }

    return <CenteredModal2 show={true} backdrop="static" header={<h3>{reportTypeToText(reportType)}</h3>}
                           confirmBtnLabel={$$("forward_btn")}
                           cancelBtnLabel={$$("back")}
                           onConfirm={() => {

                               if (isValid()) {
                                   const {patientAge, ...rest} = values;
                                   clinicService.updateEncPatientInfo(patient.user_id ?? patient.id, orgId, rest).then(patient => {
                                       if (patient) {
                                           setValues(Object.assign({}, values, patient))
                                       }
                                   })
                                   onSuccess(values);
                               } else {
                                   setValidated(true);
                               }
                           }}
                           onCancel={onHide}
                           onHide={onClose}
    >
        <h4>{$$("next_patient_label")}</h4>
        <form className={validated ? "was-validated" : ""}>
            <PersonalInfoFormFieldset values={values} updateValues={setValues} requiredFields={requiredFields}/>
        </form>
    </CenteredModal2>


}

function reportTypeToDocumentTypeDescription(reportType) {
    return reportType === "MedicalReport" ? $$("document_type_medical_report") : "";
}

function ReportFormModal({patientInfo, reportType, onBack, getData, lang, onClose, updateEncounter}) {

    const [data, setData] = useState();
    const [shouldCreateDocument, setShouldCreateDocument] = useState(false)
    const [validated, setValidated] = useState();

    useEffect(()=>{
        setData(getData(reportType, patientInfo));
    }, [])

    const createDocument = (fileName) => {
        let formData = createDocumentForm(fileName);
        return documentsService.createProviderEncounterDocument(formData, data.encounter.id);
    }

    const createDocumentForm = (fileName) => {

        let providerNames = getClinicianNameWithTitle(appointmentUtils.getProvider(data.encounter));
        let dateTime = Date.now();
        let form = {
            type: reportToDocumentType[reportType],
            typeDescription: reportTypeToDocumentTypeDescription(reportType),
            title: `${reportTypeToText(reportType)} - ${providerNames}`,
            filename: fileName,

            user_id: appointmentUtils.getPatientId(data.encounter),
            provider_names: providerNames,
            providerId: data.providerId,
            created_by_provider: true,
            has_metadata: true,
            metadata: JSON.stringify(data.examDetails),
            reportType: reportType,
            lang: lang,
            date_time: dateTime,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            health_issues_ids: data.encounter.healthIssueIds,
            mime_type: "application/pdf",
            created_timestamp: dateTime,
            updated_timestamp: dateTime
        }

        const formData = new FormData();

        formData.append('document', new Blob([JSON.stringify(form)], {type: "application/json"}));
        return formData;
    }


    const validate = () => {
        setValidated(true)
        if (reportType === 'EncounterOutcome') {
            return data.examDetails.documentNo;
        }
        return true;
    }

    const onSubmit = async () => {
        if (!validate()) {
            return;
        }


        let fileName = `${filePrefixPerType[reportType]}_${moment(data.encounter.server_created_timestamp).format("YYYYMMDD")}.pdf`;
        if (shouldCreateDocument) {
            await createDocument(fileName);
            updateEncounter();
        }
        documentsService.downloadReportResult(lang, data.examDetails, reportType, data.providerId).then((file) => {
            downloadUtils.download(file, fileName);
            onClose();
        });
    }

    return <CenteredModal2 backdrop="static" show={true} header={<h3>{reportTypeToText(reportType)}</h3>} onHide={onClose} confirmBtnLabel={$$("forward_btn")}
                           onConfirm={onSubmit}
                           onCancel={onBack}
                           cancelBtnLabel={$$("back")}>
        <PersonalInfoDisplay patientInfo={patientInfo} reportType={reportType} />
        {data && <ReportFields reportType={reportType} validated={validated} data={data} onUpdate={(name, value) => {
            data.examDetails[name] = value;
            let newVar = {...data, examDetails: {...data.examDetails}};
            setData(newVar)
        }}/>}

            <div className="form-check p-0 d-flex mb-3">
                <input className="big-checkbox mr-2" type="checkbox"
                       name="create-document"
                       checked={shouldCreateDocument}
                       style={{cursor: "pointer"}}
                       onChange={() => {
                           setShouldCreateDocument(!shouldCreateDocument)
                       }}
                />
                <label className="form-check-label"
                       htmlFor="create-document"
                       style={{cursor: "pointer"}}
                       onClick={() => {
                           setShouldCreateDocument(!shouldCreateDocument)
                       }}>
                    {$$('attach_provider_document_from_report')}
                </label>
            </div>

    </CenteredModal2>
}


const personalInfoFieldsRequiredFieldsPerType = {
    EncounterOutcome: ["patientName"],
    Prescription: ["patientName", "patientAge"],
    Epicrisis: ["patientName", "patientAge", "patientID", "patientAddress"],
    MedicalReport: ["patientName", "patientAge", "patientID", "patientAddress", "patientPhone"]
}

const personalInfoFieldsPerType = {
        EncounterOutcome: ["patientName", "patientAge", "patientID", "patientAddress"],
        Prescription: ["patientName", "patientAge", "patientAddress"],
        Epicrisis: ["patientName", "patientAge", "patientID", "patientAddress"],
        MedicalReport: ["patientName", "patientAge", "patientID", "patientAddress", "patientPhone"]
}

const filePrefixPerType = {
    EncounterOutcome: "ExamReport ",
    Prescription: "Prescription ",
    Epicrisis: "Epicrisis ",
    MedicalReport: "Medical Report "
}

const reportToDocumentType = {
    EncounterOutcome: "AMBULANCE_SHEET",
    Prescription: "PRESCRIPTION",
    Epicrisis: "EPICRISIS",
    MedicalReport: "OTHER"
}

function PersonalInfoDisplay({patientInfo, reportType}) {

    const showField = useCallback((f) => {
        return personalInfoFieldsPerType[reportType].indexOf(f) !== -1;
    }, [])

    return <div className="mb-3">
        <div>{$$("title_label")}: {patientInfo.patientName}</div>
        {showField("patientAge") && patientInfo.patientAge && <div>{$$("age_label")}: {patientInfo.patientAge}</div>}
        {showField("patientID") && patientInfo.patientID && <div>{getResolvedText(PATIENT_ID_TYPE.TYPE_OPTIONS, patientInfo.patientIDType)}: {patientInfo.patientID}</div>}
        {showField("patientAddress") && patientInfo.patientAddress && <div>{$$("address_label")}: {patientInfo.patientAddress}</div>}
    </div>
}

function ReportFields({data, onUpdate, reportType, validated}) {

    if (reportType === "MedicalReport") {
        return <div>
            <div className="form-group form-group-md">
                {data.examDetails.reportSubject && <label>{$$("med_report_subject_field_label")}</label>}
                <input type="text" className="form-control" name="reportSubject" required={false}
                       placeholder={$$("med_report_subject_field_label")} value={data.examDetails.reportSubject || ""} onChange={(e)=>{onUpdate("reportSubject", e.target.value)}}/>
            </div>
        </div>
    }

    if (reportType === "EncounterOutcome") {
        return <div className={classnames("", {"was-validated":validated})}>
            <div className="form-group form-group-md">
                {data.examDetails.documentNo && <label>{$$("encounter_outcome_document_no_field_label")}</label>}
                <input type="text" className="form-control" name="documentNo" required={true}
                       placeholder={$$("encounter_outcome_document_no_field_label")} value={data.examDetails.documentNo || ""} onChange={(e)=>{onUpdate("documentNo", e.target.value)}}/>
            </div>
        </div>
    }

    if (reportType === "Epicrisis") {
        return <div>
            <div className="form-group form-group-md">
                {data.examDetails.reportPurpose && <label>{$$("epicrisis_report_purpose")}</label>}
                <input type="text" className="form-control" name="reportPurpose" required={false}
                       placeholder={$$("epicrisis_report_purpose")} value={data.examDetails.reportPurpose || ""} onChange={(e)=>{onUpdate("reportPurpose", e.target.value)}}/>
            </div>
        </div>
    }

    return <div>

    </div>
}