import {
    LOGIN_REQUEST_ERROR,
    LOGIN_REQUEST_SENT,
    LOGIN_REQUEST_SUCCESS,
    LOGIN_REQUEST_CLEAR_STATUS,
    LOGOUT,
    REQUEST_ERROR,
    REQUEST_SENT,
    REQUEST_SUCCESS, SELECT_ORGANISATION_TO_MANAGE,
    TOKEN_REFRESH_REQUEST_ERROR,
    TOKEN_REFRESH_REQUEST_SENT,
    TOKEN_REFRESH_REQUEST_SUCCESS, AUTO_LOGOUT
} from "../actions/actions";
import moment from 'moment'
import {requestStatus} from './requests_reducers';

const initialState = {data: {}, request: requestStatus(undefined, {}), refreshRequest: requestStatus(undefined, {})}

/**
 * Authentication reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the authentication
 * @param {action} action the action to execute on the state
 */
export function authentication(state = initialState, action) {
    switch (action.type) {
        case LOGIN_REQUEST_SENT: {
            return {
                ...state,
                request: requestStatus(state.request, {type: REQUEST_SENT}),
                refreshRequest: initialState.refreshRequest
            }
        }
        case LOGIN_REQUEST_SUCCESS: {
            action.response.authenticated_time = moment().valueOf();
            return {
                ...state,
                management_login: action.management_login,
                data: action.response,
                request: requestStatus(state.request, {type: REQUEST_SUCCESS})
            }
        }
        case LOGIN_REQUEST_CLEAR_STATUS:
            return {...state, request: requestStatus(undefined, {})}
        case SELECT_ORGANISATION_TO_MANAGE: {
            return {
                ...state, management_login: true
            }
        }
        case LOGIN_REQUEST_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {data, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_ERROR, response: action.response})}
        }
        case TOKEN_REFRESH_REQUEST_SENT: {
            return {...state, refreshRequest: requestStatus(state.refreshRequest, {type: REQUEST_SENT})}
        }
        case TOKEN_REFRESH_REQUEST_SUCCESS: {
            action.response.authenticated_time = moment().valueOf();
            return {
                ...state,
                data: action.response,
                refreshRequest: requestStatus(state.refreshRequest, {type: REQUEST_SUCCESS})
            }
        }
        case TOKEN_REFRESH_REQUEST_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {request, ...rest} = state;
            return {
                ...rest,
                data: {},
                refreshRequest: requestStatus(rest.refreshRequest, {type: REQUEST_ERROR, response: action.response})
            }
        }
        case AUTO_LOGOUT: {
            // eslint-disable-next-line no-unused-vars
            const {request, ...rest} = state;
            return {
                ...rest,
                data: {},
                refreshRequest: {...rest, finished: true, success: false, error: {message: action.message}}
            }
        }
        case LOGOUT: {
            sessionStorage.removeItem("utcpopup");
            sessionStorage.removeItem("sstfa");
            return {...initialState}
        }
        default: {
            return state;
        }
    }
}


export function two_fa_settings(state = {settings: {}, result: '', totp: {}}, action) {
    switch (action.type) {
        case 'ACTION_2FA_LOADED':
            return {settings: action.settings, result: 'loaded', totp: state.totp}
        case 'ACTION_2FA_CHANGED':
            return {settings: action.settings, result: 'changed', totp: state.totp}
        case 'ACTION_2FA_CLEAR_RESULT':
            return {settings: state.settings, result: '', totp: {}}
        case 'ACTION_TOTP_LOADED':
            return {settings: state.settings, totp: action.totp}
        case 'ACTION_TOTP_VERIFY_RESP':
            return Object.assign({}, state, {totp_verify_result: action.result});
        case 'ACTION_TOTP_VERIFY_RESP_CLEAR':
            const {totp_verify_result, ...rest} = state;
            return Object.assign({}, rest);
        case LOGIN_REQUEST_SUCCESS :
        case LOGOUT :
            return {settings: {}, result: '', totp: {}};
        default:
            return state;
    }
}
