import React, {Component} from 'react'
import {VideoManager} from "./video_manager";
import classnames from 'classnames'
import {faCog} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";


export class CameraDevices extends Component {


    state = {
        deviceId: this.props.deviceId && this.props.deviceId.exact
    }

    selectCamera = (cameraNo, e) => {
        VideoManager.onSwitchVideoCamera(cameraNo, this.props.cameraDevices);
        this.setState({deviceId: this.props.cameraDevices[cameraNo].deviceId})
        e.preventDefault();
        e.stopPropagation();
    }


    render() {

        return (
            <div
                 className={classnames("dropup", "kt-header__topbar-item", "kt-header__topbar-item--langs", "dropdown-link")}>
                {/* eslint-disable react/no-string-refs */}
                <button
                    className={classnames("camera-button", "center-content", "dropdown-toggle", "nav-link", "video-control-btn", this.props.modal ? "video-control-btn" : "video-control-btn1")}

                    ref='#'
                    onClick={e => e.preventDefault()} data-toggle="dropdown" data-flip="false" aria-expanded="false"
                    >
                    <FontAwesomeIcon icon={faCog}/>
                </button>
                {/* eslint-enable react/no-string-refs */}
                <div
                    className={classnames("dropdown-menu-top", "dropdown-menu-fit", "dropdown-menu-right", "dropdown-menu-anim", "dropdown-menu-top-unround", "dropdown-menu")}>
                    {this.props.cameraDevices && this.props.cameraDevices.map(function (camera, idx) {
                        return (

                            <a key={idx}
                               className={this.state.deviceId && this.state.deviceId === camera.deviceId ? "camera-selected dropdown-item" : "dropdown-item"}
                               href="#" onClick={(e) => this.selectCamera(idx, e)}>
                                <span>&nbsp;{camera.label}</span>
                            </a>

                        )
                    }.bind(this))}
                </div>
            </div>
        )
    }
}

CameraDevices.propTypes = {
    cameraDevices : PropTypes.array,
    deviceId : PropTypes.object,
    modal : PropTypes.bool
};