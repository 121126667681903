import React, {Component, useCallback, useEffect, useState} from 'react'
import {ReactComponent as Seen} from '../../../../public/media/icons/double_tick_seen.svg'
import {ReactComponent as Delivered} from '../../../../public/media/icons/double_tick_delivered.svg'
import classNames from 'classnames'
import {$$} from '../../../helpers/localization'
import {
    COMPLETED,
    IN_PROGRESS,
    INITIATED,
    MISSED,
    REJECTED
} from '../../../constants/video_message_status'
import moment from 'moment'
import {CONVERTER} from '../../../utils/converter';
import {isSameDay} from 'date-fns'
import PropTypes from "prop-types";
import extractUrls from 'extract-urls';


export class Message extends Component {

    constructor(props) {
        super(props);
    }

    /**
     * Get the vide message text to display in the last message item
     *
     * @returns {string} text to display for the video call
     */
    getVideoMessageText = () => {
        if (this.props.message.type === "VIDEO") {
            switch (this.props.message.video_status) {
                case MISSED:
                    return <b>{$$('missed_call_label')}</b>;
                case REJECTED:
                    return <b>{$$('rejected_call_label')}</b>;
                case IN_PROGRESS:
                    return <b>{$$('in_progress_call_label')}</b>;
                case COMPLETED:
                    return <span><b>{$$('completed_call_label')}</b>: {moment.utc(this.props.message.video_duration).format('HH:mm:ss')}</span>;
            }
        }
        return '';
    }

    isMessageInSearch = () => {
        let res = false

        for (let i in this.props.selectedMessages) {
            if (this.props.selectedMessages[i].id === this.props.message.id) {
                res = true
            }
        }

        return res
    }

    render() {

        const messageClass = classNames('message', {
            'sent': this.props.isSent === true,
            'received': this.props.isSent === false,
            'highlighted-chat-message': this.isMessageInSearch()
        });
        let time = moment(this.props.message.date_time).format(CONVERTER.getSelectedDateTimeFormat())
        if (isSameDay(this.props.message.date_time, new Date())) {
            let res = time.split(",");
            time = res[1];
        }
        return (
            <div>
                {this.props.message.type === "TEXT" &&
                    <>
                        <div id={this.props.message.id} className={messageClass}>
                            {this.props.message.message}
                        </div>
                        <Links text={this.props.message.message} isSent={this.props.isSent}/>
                        <span className={classNames({'metadata': true, 'metadata-sent': this.props.isSent})}>
                            <span className="name">{this.props.message.from.split(" ")[0]}, &nbsp;</span>
                            <span className="time">{time}</span>
                            <span className="tick">
                                {this.props.message.seen && this.props.isSent && <Seen/>}
                                {!this.props.message.seen && this.props.isSent && <Delivered/>}
                            </span>
                        </span>
                    </>
                }
                {(this.props.message.type === "VIDEO" && this.props.message.video_status !== INITIATED) &&
                    <div className="chat-video-message">
                        <div className="text">
                            <div>{time}</div>
                            <div>{this.getVideoMessageText()}</div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

Message.propTypes = {
    isSent: PropTypes.bool,
    messageID: PropTypes.object,
    selectedMessages: PropTypes.array,
    message: PropTypes.object
}

export default Message


function Links({text, isSent}) {
    const [links, setLinks] = useState([]);
    const [className, setClassName] = useState(classNames({'metadata': true, 'metadata-sent': isSent}));
    const [linkClassName, setLinkClassName] = useState(`d-block mt-1 text-${isSent ? 'right' : 'left'}`);
    const [truncatedLinks, setTruncatedLinks] = useState([]);
    const parseLinks = useCallback(()=>{
        if (text) {
            let extractedUrls = extractUrls(text, true) || [];
            let trunkLinks = [];
            for (const extractedUrl of extractedUrls) {
                trunkLinks.push(_.truncate(extractedUrl, {length:40}))
            }
            setLinks(extractedUrls);
            setTruncatedLinks(trunkLinks);
        }
    }, [text])

    useEffect(()=>{
        parseLinks();
    }, [text])

    useEffect(()=>{
        setClassName(classNames({'metadata': true, 'metadata-sent': isSent}));
        setLinkClassName(`d-block mt-1 text-${isSent ? 'right' : 'left'}`);
    }, [isSent])

    if (links.length === 0) {
        return null;
    }

    return <div className={className}>
        {links.map((link, i)=> <div key={i} className={linkClassName}><span className="medrec-grey-2 fa fa-link"/>&nbsp;<a href={link} title={link} className={""} target="_blank">{truncatedLinks[i]}</a></div>)}
    </div>
}
