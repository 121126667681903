export const bg = {
    text: {
        title: "Medrec:M Clinic",
        main_page_label: "Медицински картон",
        select_language_label: "Избери език",
        login_label: "Влизане",
        logout_label: "Изход",
        save_btn_label: "Запази",
        username_label: "Електронна поща",
        email_label: "Електронна поща",
        password_creation_title: "Паролата трябва да съдържа:",
        at_Lest_eight_characters: "най-малко 8 символа",
        at_Lest_one_uppercase_characters: "поне една главна буква (A-Z)",
        at_Lest_one_lowercase_characters: "поне една малка буква (A-Z)",
        at_Lest_one_number: "поне едно число (0-9)",
        at_Lest_one_special: "поне един специален знак (~!@#$%^&*_-+=`|\\(){}[]:;\"'<>,.?/)",
        password_is_not_valid: "Паролата е невалидна (виж изисквания за паролата)",
        password_label: "Парола",
        new_password_label: "Нова парола",
        repeat_password_label: "Повтори паролата",
        change_password_label: "Промяна на паролата",
        user_password_updated_message: 'Паролата е актуализирана успешно',
        user_password_update_error_message: 'Сървърна грешка!',
        email_required_message: "Моля, въведете електронната си поща!",
        email_required_message_patient_registration: "Електронна поща е задължително поле!",
        password_required_message: "Моля, въведете парола!",
        login_form_invalid_credentials_message: "Невалидни поща/парола!",
        register_label: "Регистриране",
        register_form_email_exists_message: "Електронната поща вече е регистрирана!",
        register_form_email_not_correct_message: "Електронната поща е грешна!",
        register_form_passwords_not_match_message: "Паролите не съвпадат!",
        register_form_password_length_message: "Паролата трябва да е поне 8 символа!",
        register_form_password_whitespace_message: "Паролата не може да започне или да завърши с интервали!",
        no_records_found: "Няма намерени записи",
        register_form_successful_message: "Пациентът %1 е регистриран успешно!",
        register_form_website_not_correct_message: 'Уебсайт не съдържа коректна стойност!',
        confirm_btn_label: "Потвърди",
        close_btn_label: "Затвори",
        Charts: "Графики",
        Logbook: "Дневник",
        no_results_found: "Няма намерени резултати",
        logbook_entries_header: " ",
        charts_header: " ",
        Systolic: "Систолично",
        Diastolic: "Диастолично",
        Pulse: "Пулс",
        notes: "Бележки",
        messages: "Съобщения",
        am: "AM",
        pm: "PM",
        peak_flow_report: "ВЕД дневник",
        download_peak_flow: "Изтегли ВЕД дневник",
        download_medications: "Изтегли справка за лекарствата",
        download_therapy: "Изтегли справка за придържане към терапията с лекарства",
        results_are_saved: "Резултатът от прегледа е записан.",
        consultation_results_are_saved: "Резултатът от консултацията е записан.",
        do_you_really_want_to_complete: "Искате ли да приключите прегледа?",
        total_dose_intake: "Общо приета доза",
        summary: "Общо",
        min: "МИН",
        max: "МАКС",
        average: "СРЕДНО",
        previous: "Предишен",
        next: "Следващ",
        forward_btn: "Напред",
        close_notes: "Затвори съобщенията",
        total_cholesterol: "Общ холестерол",
        triglycerides: "Триглицериди",
        Cholesterol: "Холестерол",
        glucose_units: "Мерни единици за кръвна захар",
        ketones_units: "Мерни единици за кетони",
        hba1c_units: "Мерни единици за HbA1c",
        documents_no_data_primary_label: "Потребителят не е качил документи",
        documents_no_data_secondary_label: " ",
        Weight: "Тегло",
        Height: "Височина",
        weight_and_height: "Тегло и височина",
        chronic_conditions: "Хронични заболявания",
        allergies: "Алергии",
        blood_group: "Кръвна група",
        details: "Детайли",
        blood_pressure: "Кръвно налягане",
        settings_label: "Настройки",
        unit_of_measurement_label: "Мерни единици",
        cholesterol_units_label: "Мерна единица на холестерола",
        classification_method_label: "Класификация",
        date_format_label: "Формат на датата",
        time_24hour_label: "Използвай 24 часов формат",
        settings_updated_message: "Настройките са запазени успешно",
        US: "САЩ (lbs, oz, fl oz, inch)",
        metric: "Метрична (kg, gram, ml, cm)",
        kg: "кг",
        pound: "фунт(а)",
        inches: "инча",
        cm: "см",
        None: "Няма",
        diagnosed_in: "Диагностициран на",
        mild: "Лека",
        mild_to_moderate: "Лека до умерена",
        moderate: "Умерена",
        moderate_to_severe: "Умерена до тежка",
        severe: "Тежка",
        life_threatening: "Животозастрашаваща",
        drug_allergy: "Медикаментозна алергия",
        dust_allergy: "Алергия към домашен прах",
        food_allergy: "Хранителна алергия",
        insect_allergy: "Алергия към насекоми",
        latex_allergy: "Алергия към латекс",
        mold_allergy: "Алергия към плесени",
        pet_allergy: "Алергия към животни",
        pollen_allergy: "Алергия към полени",
        other_allergy: "Друга алергия",
        Severity: "Степен",
        Medications: "Лекарства",
        millimol_per_litre: "ммол/л",
        percent: "%",
        millimol_per_mol: "ммол/мол",
        milligram_per_decilitre: "mg/dL",
        millimetre_of_mercury: "mmHg",
        week_label: "7 дни",
        fortnight_label: "14 дни",
        month_label: "30 дни",
        quarter_label: "90 дни",
        custom_label: "Персонален",
        Symptoms: "Симптоми",
        Temperature: "Температура",
        Hydration: "Хидратация",
        Respiratory_rate: "Дихателна честота",
        Saturation: "Насищане с кислород",
        celcius: "°C",
        ml: "ml",
        bpm: "bpm",
        fahrenheight: "°F",
        floz: "fl oz",
        refresh_token_expired_message: 'Изтекла сесия',
        sharing_label: "Споделяне",
        schedule_label: "Работно време",
        messages_label: "Чат",
        no_users_after_filtering: "Няма върнати резултати",
        sort_by_name: "Сортирай по име",
        sort_by_most_recent: "Сортирай по време",
        new_messages_only: "Само непрочетени чат-съобщения",
        pending_permission_list_label: "Очакващи потвърждение заявки за достъп",
        approved_permission_list_label: "Потвърдени заявки за достъп",
        deny_permission_label: "Откажи",
        close_confirm_appointment: "Откажи",
        approve_permission_label: "Разреши",
        request_label: "Заявка",
        request_permission_label: "Поискай достъп",
        requested_user_does_not_exist_message: "Не може да бъде намерен потребител с този имейл.",
        premission_request_success_message: "Заявката е успешно изпратена.",
        forgot_password: "Забравена парола?",
        privacy_policy: "Политика за поверителност",
        gender_label: "Пол",
        country_label: "Страна",
        male: "Мъж",
        female: "Жена",
        close_search: "Затвори",
        this_month: "Този месец",
        search_for_medications: "Търсене",
        medications_found: "Намерени медикаменти",
        needs_prescription: "С лекарско предписание",
        doesnt_need_prescription: "Без лекарско предписание",
        city_label: "Град",
        birthday_label: "Рождена дата",
        Dashboard: "Основен панел",
        user_info_label: "Персонална информация",
        latest_measurements_label: "Последни записи в дневника",
        last_week_data: "Седмични графики",
        no_recent_measurements: "Няма намерени записи.",
        loading_data_label: "Зареждане...",
        logbook_no_data_label: "Потребителят няма записани измервания.",
        vitals_logbook_no_appointments: "Няма предстоящи прегледи",
        vitals_logbook_no_notes: "Няма съобщения",
        symptoms_no_data_label: "Потребителят няма записани симптоми.",
        search_placeholder_label: "Търсене...",
        latest_symptoms_label: "Симптоми от последните 24 часа",
        no_latest_symptoms_label: "Няма намерени симптоми от последните 24 часа.",
        profile_label: 'Профил',
        edit_profile_label: 'Редактиране на профила',
        cancel: 'Отмени',
        not_available: 'не в наличност',
        available: 'Налично',
        edit_schedule: "Промени график",
        delete: "Изтрий",
        update: "Промени",
        holiday: "отпуск",
        update_btn: 'Промени',
        upload_image_label: 'Качване на профилна снимка',
        user_info_updated_message: 'Профилът е променен',
        unsupported_image_format: 'Този файлов формат не се поддържа',
        choose_file_label: 'Избери снимка',
        Documents: "Документи",
        category_lobal: "Категория",
        document_type_label: "Тип документ",
        type_description_label: "Описание на типа",
        document_download_dialog_title: "Свали документ",
        confirmation_dialog_message: "Документът ({fileName}), който искате да свалите принадлежи на {owner} и може да съдържа лична информация. Съхранявайте документа на сигурно място или го изтрийте след прочитането му.",
        documents_no_data_label: "Този потребител няма качени документи.",
        download_btn: "Свали",
        cancel_btn: "Откажи",
        document_type_none: "Без",
        document_type_epicrisis: "Епикриза",
        document_type_prescription: "Рецепта",
        document_type_lab_results: "Лабораторни резултати",
        document_type_ambulance_sheet: "Амбулаторен лист",
        document_type_referral: "Направление",
        document_type_other: "Друго",
        document_type_diagnostic_imaging: "Образна диагностика",
        document_type_medical_report: "Заключение",
        all_label: "Всички",
        all_appointments_label: "Всички прегледи",
        select_category: "Избери категория",
        permanent_link_explanation: "Копирайте тази връзка и я изпратете на пациент, за да избере час за преглед.",
        documents_no_data_for_filters_label: "Няма намерени документи отговарящи на избраните критерии.",
        pending_for_user_approval_label: "Потребители, които трябва да разрешат достъп",
        start_date: "От дата",
        end_date: "До дата",
        load_more: "Покажи още",
        afternoon: "Следобед",
        morning: "Сутрин",
        evening: "Вечер",
        select_gender: "Избери пол",
        my_documents_label: "Моите документи",
        Blood_sugar: "Кръвна захар",
        HBA1C: "HbA1c (Гликиран хемоглобин)",
        Ketones: "Кетони",
        Flow: "Течение",
        Color: "Цвят",
        Consistency: "Консистенция",
        Urine_pH: "pH на урина",
        new_message_from: "От",
        no_flow: "Няма",
        light: "Слабо",
        medium: "Средно",
        heavy: "Силно",
        unexpected: "Неочаквано",
        none: "Няма",
        black: "Черен",
        brown: "Кафяв",
        dark_red: "Тъмно червен",
        bright_red: "Ярко червен",
        pink: "Розов",
        orange: "Оранжев",
        gray: "Сив",
        waterly: "Водниста",
        clots: "Съсиреци",
        mmol_mol: "ммол/мол",
        no_chronic_conditions: "Няма хронични заболявания",
        no_allergies: "Няма алергии",
        Communication: "Съобщения",
        my_communication_label: "Моите съобщения",
        new_chat: "Нов разговор",
        recent_chats_label: "Последни разговори",
        available_users_label: "Достъпни потребители",
        back: "Назад",
        type_message: "Напиши чат-съобщение...",
        call: "Входящо повикване",
        incoming_call_message: "Входящо повикване от %1",
        incoming_call_another_user_message: 'Входящо повикване от друг потребител',
        accept: "Приемам",
        no_user_selected_message: 'Няма избран потребител',
        ok: 'Потвърждавам',
        no_answer: "Няма отговор",
        no_answer_message: "Няма отговор от %1",
        you_wrote: "Вие написахте",
        missed_call_label: "Пропуснато обаждане",
        rejected_call_label: "Отказано обаждане",
        in_progress_call_label: "Текущ разговор",
        completed_call_label: "Приключен разговор",
        ongoing_call: "Текущ разговор!",
        general_practitioner: "Общопрактикуващ лекар",
        practice_name: "Име на клиника",
        practice_address: "Адрес на клиника",
        practice_contact: "Телефон за контакти",
        practice_centre_code: "Номер на клиника",
        practice_practitioner_code: "Номер на доктор",
        na: "Не е приложимо",
        medication_plan: "График на лекарствата",
        current_medications: "Лекарства",
        times_per_day: "%1 %2, един път дневно",
        times_per_day_plural: "%1 %2, %3 пъти дневно",
        every_x_hours: "%1 %2 на всеки час",
        every_x_hours_plural: "%1 %2 на всеки %3 часа",
        times_per_day_intakes_only: "един път дневно",
        times_per_day_plural_intakes_only: "%1 пъти дневно",
        every_x_hours_intakes_only: "на всеки час",
        every_x_hours_plural_intakes_only: "на всеки %1 часа",
        daily: 'Всеки ден',
        every_x_days: "Всеки X ден(а)",
        every_days: "Всеки ден",
        every_days_plural: "Всеки %1 дни",
        take_x_rest_x: "Взимане X ден(а) и почивка Y ден(а)",
        take_rest_x_plural: "Взимане %1 дена и почивка един ден",
        take_rest_y_plural: "Взимане един ден и почивка %2 дни",
        take_rest_xy_plural: "Взимане %1 дни и почивка %2 дни",
        take_rest: "Взимане един ден и почивка един ден",
        when_needed: "При нужда",
        ongoing: "Безсрочно",
        until_date: 'До дата',// %1',
        for_x_days_until: "За X ден(а)",// до %2",
        for_days_until: "за 1 ден",// до %2",
        for_days_until_plural: "за %1 дни",
        effective_from: "В действие от %1",
        get_help_label: "Помощ",
        appointments_label: 'Прегледи',
        appointments_for: 'Прегледи за ',
        search_within_selected_messages_label: "Търсете в чат-съобщенията си",
        no_unread_messages: "Няма непрочетени чат-съобщения",
        eccounter_data_separator: "Повече информация",
        today: "Днес",
        yesterday: "Вчера",
        month: "Месец",
        week: "Седмица",
        day: "Ден",
        this_week: "Тази седмица",
        list: "Списък",
        Lab_Results: "Лабораторни резултати",
        select_laboratory_label: "Избери лаборатория",
        lab_results_no_data_primary_label: "Няма намерени резултати от изследвания",
        all_patients: "Всички пациенти",
        lab_results_no_data_secondary_label: "Пациентът няма въведени изследвания",
        lab_test_col_name_name: "Група показател",
        lab_test_col_name_result: "Резултат",
        lab_test_col_name_units: "Мерни единици",
        lab_test_col_name_flag: "Флаг",
        lab_test_col_name_range: "Референтни стойности",
        lab_microbiology_col_name_antibiogram: "Антибиограма",
        select_image: 'Избери',
        preview_image: 'Преглед',
        OK: 'OK',
        delete_image_modal_title: 'Премахни снимка',
        delete_image_modal_message: 'Сигурни ли сте, че искате да премахнете вашата снимка?',
        upload_documents: 'Качи нов документ',
        delete_document_entry_modal_title: 'Изтрий документ',
        delete_document_entry_modal_message: 'Сигурни ли сте, че искате да изтриете този документ?',
        delete_template_modal_message: 'Сигурни ли сте, че искате да изтриете този образец?',
        patients_label: 'Пациенти',
        search_for_patients_label: 'Търсене на пациенти',
        search_patient_label: 'Търсене на пациент',
        search_in_messages_label: "Търсене",
        view_patients_details_label: 'Виж детайли',
        grid_view_label: 'Таблица',
        list_view_label: 'Списък',
        year_old: 'година',
        years_old: 'години',
        medical_history: 'Медицинска История',
        about: 'За нас',
        contact: 'Контакти',
        select_today_label: 'Днес',
        this_week_label: 'Тази седмица',
        this_month_label: 'Този месец',
        next_patient_label: 'Пациент',
        no_pending_appointments_primary_label: 'Няма предстоящи прегледи',
        no_pending_appointments_primary_label_for_today: 'Няма предстоящи прегледи за деня',
        go_to_next_date_with_appointment: "Натисни тук за най-близкия предстоящ преглед",
        no_patients_primary_label: 'Нямате пациенти',
        no_staff_primary_label: 'Нямате персонал, който да управлявате',
        appointment_label: 'Преглед',
        calendar_label: 'Календар',
        calendars_label: 'Календари',
        mark_all: "Изберете всички",
        unmark_all: "Отменете избраните",
        holidays_label: "Ваканции",
        book_time_off: "Запази отпуск",
        new_schedule: "Нов график",
        start: "Начало",
        time_on: "Приемно време",
        time_off: "Почивка",
        from: "От",
        to: "До",
        applies_to: "Важи за",
        end: "Край",
        all_day: "цял ден",
        mon: "Пон",
        tue: "Вт",
        wed: "Ср",
        thu: "Четв",
        fri: "Пет",
        sat: "Съб",
        sun: "Нед",
        repeat_for: "Повтори",
        do_not_repeat: "Не повтаряй",
        one_week: "1 седмица",
        one_month: "1 месец",
        one_year: "1 година",
        set: "Запази",
        set_new_schedule: "Запази Нов график",
        appointment_type_label: 'Тип преглед',
        last_appointment_label: 'Последен преглед',
        country_city_label: 'Страна, Град',
        recent_messages_title: 'Последни чат-съобщения',
        age_label: 'Възраст',
        address_label: 'Адрес',
        lab_results_filter_label: 'Филтър',
        price_settings_label: 'Ценоразпис',
        new_appointment_type: 'Добави нов преглед',
        edit_appointment_type: 'Редактирай преглед',
        create: 'Създай',
        create_new_appointment: 'Създай преглед',
        create_new_consultation: 'Създай писмена консултация',
        opened_communication_duration: "Брой дни за последващи въпроси от пациента след завършване на консултацията",
        average_response_time_hours: "Вашето приблизително време за отговор (часове)",
        new_consultation_type: 'Нов вид консултация',
        edit_consultation_type: ' Промени консултация',
        tooltip_schedule_unavailable: "Графикът е недостъпен за пациенти",
        tooltip_schedule_available: "Графикът е достъпен за пациенти",
        tooltip_appointment_unavailable: "Прегледът е недостъпен за пациенти",
        tooltip_independent_schedule: "Независим график",
        edit: 'Промени',
        exam_type: 'Вид преглед',
        name: 'Наименование',
        location: 'Място на провеждане',
        organisation: 'Организация',
        add: "Добави",
        add_organisation: "Добави организация",
        all: "Всички",
        provider_calendars_all: "Всички организации",
        currency: 'Валута',
        price: 'Цена',
        online: 'Дистанционен',
        on_site: 'На място',
        billing_type_online: 'Онлайн',
        billing_type_on_site: 'На място',
        billing_type_online_and_on_site: "Онлайн или на място",
        payment_location_label: "Плащане",
        allow_video_for_on_site_input_label: "Разреши пациента да смени на дистанционен преглед",
        lock_location_in_hours_label: "Време за смяна към онлайн преглед (часове)",
        primary_exam: 'Първичен преглед',
        follow_up_exam: 'Вторичен преглед',
        delete_modal_header: "Сигурни ли сте, че искате да изтриете този преглед {name}?",
        delete_modal_body: "Прегледът ще бъде изтрит веднага. Не можете да върнете това действие.",
        other: 'Друг',
        your_price_list_is_empty: "Вашата ценова листа е празна",
        lab_results_date_label: 'Дата',
        lab_results_tests_label: 'Изследвания',
        waiting_for_doctor_approval: "Очаква потвърждение",
        waiting_for_doctor_approval_consultation: "Очаква потвърждение",
        waiting_for_patient_approval: "Очаква потвърждение от пациент",
        waiting_for_patient_approval_consultation: "Очаква потвърждение от пациент",
        need_more_info: "Нуждае се от повече информация",
        need_more_info_consultation: "Нуждае се от повече информация",
        accepted: "Потвърден",
        accepted_consultation: "Потвърдена",
        completed: "Завършен",
        completed_consultation: "Завършена",
        rejected_by_patient: "Отхвърлен от пациент",
        rejected_by_patient_consultation: "Отхвърлена от пациент",
        canceled_by_patient: "Отказан от пациент",
        canceled_by_patient_consultation: "Отказана от пациент",
        rejected_by_provider: "Отхвърлен от лекар",
        rejected_by_provider_consultation: "Отхвърлена от лекар",
        canceled_by_provider: "Отказан от лекар",
        canceled_by_provider_consultation: "Отказана от лекар",
        canceled_response_overdue: "Анулиран (Просрочен отговор)",
        canceled_response_overdue_consultation: "Анулирана (Просрочен отговор)",
        canceled_not_paid: "Анулиран (Просрочено плащане)",
        canceled_not_paid_consultation: "Анулирана (Просрочено плащане)",
        date_label: "Дата",
        condition_label: "Състояние",
        status_label: "Статус",
        main_diagnosis_label: "Основна диагноза",
        conclusion: "Заключение",
        preliminary_diagnosis: "Предварителна диагноза",
        additional_info: "Допълнителна информация",
        mark_document_entry_deleted_modal_title: "Маркирай записа за документ като изтрит",
        mark_document_entry_deleted_modal_message: "Сигурни ли сте, че искате да изтриете този документ?",
        document_label: "Добави Документи",
        update_document: "Редактирай Документ",
        document_title: "Наименование",
        clear_form: "Изчисти формата",
        type_description_required_message: "Описание на типа е задължително поле",
        confirm_clear_warning: "Сигурни ли сте, че искате да изчистите формата за записване?",
        copy_from_last: "Копирай от предходни резултати",
        document_title_required_message: "Наименование е задължително поле",
        no_file_chosen: "Не е избран файл",
        file_required_message: "Файл е задължително поле",
        upload_document: "Прикачи файл",
        log_symptoms_time_label: "Час",
        log_symptoms_date_label: "Дата",
        confirmation_provider_document_dialog_message: "Свали ({fileName})?",
        Test_Result_Documents: "Документи на пациент",
        concomitant_and_diseases_complications_label: "Придружаващи заболявания и усложнения",
        objective_data_label: "Обективно състояние",
        medications_report: "Лекарства",
        therapy_report: "Придържане към терапията с лекарства",
        of: "от",
        auto_logout: "Вашата сесия изтече",
        download_as_pdf: "Създай документ към прегледа",
        examination_outcome: "Резултат от преглед",
        taken_dose: "Приета доза:",
        subjective_data_label: "Анамнеза",
        therapy_label: "Терапия",
        therapy_tab_label: "Придържане към терапията",
        concomitant_diseases_label: "Придружаващи заболявания",
        tests_label: "Изследвания",
        appointment_details_missing_header: "Следните полета са празни:",
        appointment_details_missing_question: "Искате ли да приключите прегледа?",
        consultation_details_missing_question: "Искате ли да приключите консултацията?",
        no_encounter_found_label: "Не е намерен резултат от прегледа",
        category_label: "Категория",
        title_label: "Име",
        update_note: "Редактирай съобщение",
        create_note: "Създай съобщение",
        note_required_message: "Съобщението не може да бъде празно!",
        delete_note_modal_header: "Потвърдете действието",
        delete_note_modal_text: "Сигурни ли сте, че искате да изтриете това съобщение?",
        private_note_label: "Лично",
        to_all_note_label: "Всички",
        consultation_paid: " създаде нова писмена консултация на ",
        consultation_canceled: " отмени писмената консултация създадена на ",
        provider_only_note_label: "Всички лекари",
        patient_only_note_label: "Само пациент",
        update_visibility: "Промени",
        visibility_label: "Видимост",
        visibility_required_message: "Полето Видимост е задължително!",
        delete_label: "Изтриване",
        share_label: "Споделяне",
        edit_label: "Редактиране",
        edit_exam_results: "Редактиране на резултати от прегледа",
        general_note_label: "Обща бележка",
        new_note_label: "Нова бележка",
        new_message_label: "Ново съобщение",
        examination_result_label: "Резултати от прегледа",
        consultation_examination_result_label: "Резултати от консултацията",
        print_label: "Печат",
        diagnosis_label: "Диагноза",
        subjective_data_required_message: "Анамнеза е задължително поле!",
        objective_data_required_message: "Обективно състояние е задължително поле!",
        diagnosis_required_message: "Диагноза е задължително поле!",
        therapy_required_message: "Терапия е задължително поле!",
        Vitals_Logbook: "Дневник",
        health_issues_label: "Здравни състояния",
        user_logs_label: "Потребителски записи",
        Menstruation: "Менструация",
        vitals_logbook_no_data_primary_label: "Няма записи в потребителския дневник",
        note_label: "Съобщение",
        search_for_staff: "Търси в \"Персонал\"",
        staff: "Персонал",
        uin: "UIN:",
        patient_pin: "ПИН",
        administrative_portal_label: "Административен портал",
        manage_your_practice: "Влизане като администратор",
        select_a_practice: "Избери практика за управление",
        select_practice: "Избери практика",
        no_messages_to_show: "Няма чат-съобщение за показване",
        messages_will_appear_here: "Съобщенията ще се покажат тук",
        no_selected_user: "Изберете преглед",
        selected_user_will_appear_here: "Информацията за пациента ще се появи тук",
        short_sunday_label: "Нед",
        short_monday_label: "Пон",
        short_tuesday_label: "Вто",
        short_wednesday_label: "Сря",
        short_thursday_label: "Чет",
        short_friday_label: "Пет",
        short_saturday_label: "Съб",
        open_appointment_label: "Отвори преглед",
        open_consultation_label: "Отвори консултация",
        morning_time_label: "Час за сутрешен прием",
        noon_time_label: "Час за обеден прием",
        evening_time_label: "Час за вечерен прием",
        bed_time_label: "Час за прием преди сън",
        morning_time_required_message: "Час за сутрешен прием е задължително поле!",
        noon_time_required_message: "Час за обеден прием е задължително поле!",
        evening_time_required_message: "Час за вечерен прием е задължително поле!",
        bed_time_required_message: "Час за прием преди сън е задължително поле!",
        complete_appointment_button_label: "Приключи прегледа",
        complete_consultation_button_label: "Приключи консултацията",
        complete_appointment_modal_header: "Искате ли да продължите?",
        complete_appointment_modal_body: "Приключването на прегледа ще позволи на пациента да види резултатите. Това действие е необратимо!",
        complete_consultation_modal_body: "Приключването на консултацията ще позволи на пациента да види резултатите. Това действие е необратимо!",
        completed_label: "ЗАВЪРШЕН",
        completed_consultation_label: "ЗАВЪРШЕНА",
        notifications_label: "Нотификации",
        see_all_label: "Виж всички",
        minimize_label: "Смали",
        created_new_appointment_label: " запази нов час за ",
        canceled_their_appointment_label: " отмени часа си за ",
        seen_label: "видян",
        no_new_notifications_label: "Няма нови нотификации",
        no_notifications_label: "Няма нотификации",
        cancel_appointment_label: "Отмени преглед",
        cancel_consultation_label: "Отмени консултация",
        patient_information_label: "Информация за пациента",
        price_list_for: 'Ценоразпис за ',
        schedule_for: 'График за ',
        cancel_appointment_confirmation_header: "Сигурни ли сте, че искате да отмените прегледа на {fullname} за {date}?",
        cancel_consultation_confirmation_header: "Сигурни ли сте, че искате да отмените писмената консултация на {fullname} изтичаща на {date}?",
        cancel_appointment_confirmation_body: "Прегледът ще бъде отменен веднага. Това действие е необратимо!",
        cancel_consultation_confirmation_body: "Консултацията ще бъде отменена веднага. Това действие е необратимо!",
        remove_label: "Премахни",
        wizard_personal_information: 'Лична информация',
        wizard_biography: 'Биография',
        wizard_practice_details: 'Информация за практиката',
        wizard_upload_documents: 'Качи документи',
        wizard_personal_information_fullname_label: "Име*",
        wizard_personal_information_title_label: 'Титла',
        wizard_personal_information_password_confirm_label: "Потвърди парола*",
        wizard_personal_information_uin_label: 'Уникален идентификационен номер /УИН/*',
        wizard_personal_information_uin_label_2: 'Уникален идентификационен номер /УИН/',
        wizard_personal_information_specialties_label: 'Специалности*',
        specialties_label: 'Специалности',
        wizard_personal_information_fullname_required_label: "Името е задължително поле!",
        wizard_personal_information_password_confirm_required_label: "Потвърдете паролата!",
        wizard_personal_information_uin_required_label: "УИН е задължително поле!",
        wizard_personal_information_specialties_required_label: 'Специалности е задължително поле!',
        wizard_biography_languages_label: 'Езици*',
        wizard_biography_languages_required_label: 'Езици е задължително поле!',
        wizard_biography_label: 'Биография*',
        wizard_biography_label_2: 'Биография',
        wizard_biography_required_label: 'Биография е задължително поле!',
        wizard_practice_details_org_name_label: 'Име на практиката*',
        wizard_practice_details_address_label: 'Адрес*',
        wizard_practice_details_website_label: 'Уебсайт',
        wizard_practice_details_org_name_required_label: 'Име на практика е задължително поле!',
        wizard_practice_details_uin_required_label: "УИН е задължително поле!",
        wizard_practice_details_uin_label: 'Уникален идентификационен номер /УИН/*',

        name_required_label: 'Име е задължително поле!',
        bgn: "Български лев",
        euro: "Евро",
        lek: "Албански лек",
        gbp: "Бротнаски Паунд",
        usd: "Американски Долар",
        organisation_already_registered: "Организацията е вече регистрирана!",
        wizard_practice_details_address_required_label: 'Адрес е задължително поле!',
        wizard_practice_details_label: 'Информация за практиката*',
        wizard_practice_details_required_label: 'Информация за практиката е задължително поле!',
        wizard_upload_documents_title: 'Влачете и пуснете вашият документ тук или кликнете за да изберете файл',
        wizard_upload_documents_unsupported_file_message: 'Моля, премахнете всички неподдържани файлове!',
        wizard_upload_documents_dropzone_label: 'JPG, PNG, PDF, DOC, DOCX, HTML, TXT, CSV и по-малки от 10MB',
        wizard_upload_documents_file_error_message: 'Този тип файл не е разрешен или е надвишен максималния размер на файла',
        wizard_upload_documents_required_label: 'Документите са задължителни!',
        wizard_city_required_label: 'Град е задължително поле!',
        wizard_phone_numbers_label: 'Телефонни номера*',
        phone_numbers_label: 'Телефонни номера',
        wizard_phone_numbers_required_label: 'Телефонни номера е задължително поле!',
        wizard_provider_image_label: 'Качете своята снимка',
        wizard_back_label: 'Назад',
        wizard_continue_label: 'Продължи',
        wizard_completed_modal_title: 'Регистрацията е завършена!',
        wizard_completed_modal_message: 'Благодарим Ви, за регистрацията в Medrec:M Clinic! Вашата информация ще бъде прегледана възможно най-скоро и ще се свържем с Вас. Междувременно може да разгледате приложението!',
        wizard_back_modal_title: 'Регистрация',
        wizard_back_modal_message: 'Сигурни ли сте, че искате да се върнете към началната страница?',
        obstetrics_gynecology: 'Акушерство и гинекология',
        allergology: 'Алергология',
        angiology: 'Ангиология',
        venereology: 'Венерология',
        internal_medicine: 'Вътрешни болести',
        gastroenterology: 'Гастроентерология',
        thoracic_surgery: 'Гръдна хирургия',
        dental_medicine: 'Дентална медицина',
        dermatology: 'Дерматология',
        pediatric_gastroenterology: 'Детска гастроентерология',
        pediatric_endocrinology: 'Детска ендокринология',
        pediatric_neurology: 'Детска неврология',
        pediatric_nephrology: 'Детска нефрология',
        child_psychiatry: 'Детска психиатрия',
        pediatric_pulmonology: 'Детска пулмология',
        pediatric_rheumatology: 'Детска ревматология',
        endocrinology: 'Ендокринология',
        immunology: 'Имунология',
        cardiology: 'Кардиология',
        cardiac_surgery: 'Кардиохирургия',
        clinical_genetics: 'Клинична генетика',
        speech_therapy: 'Логопедия',
        mammology: 'Мамология',
        microbiology: 'Микробиология',
        neurology: 'Неврология',
        neurological_surgery: 'Неврохирургия',
        neonatology: 'Неонатология',
        nephrology: 'Нефрология',
        nuclear_medicine: 'Нуклеарна медицина',
        imaging_diagnostics: 'Образна диагностика',
        general_medicine: 'Обща медицина',
        oncology: 'Онкология',
        orthodontics: 'Ортодонтия',
        orthopedics_traumatology: 'Ортопедия и травматология',
        otoneurology: 'Отоневрология',
        ophthalmology: 'Офталмология',
        pediatrics: 'Педиатрия',
        psychiatry: 'Психиатрия',
        psychology: 'Психология',
        psychotherapy: 'Психотерапия',
        pulmonology: 'Пулмология',
        rheumatology: 'Ревматология',
        vascular_surgery: 'Съдова хирургия',
        ent: 'УНГ',
        urology: 'Урология',
        physical_medicine_rehabilitation: 'Физикална медицина и рехабилитация',
        hematology: 'Хематология',
        surgery: 'Хирургия',
        homeopathy: 'Хомеопатия',
        payment_initial: "Неплатен",
        payment_initial_consultation: "Неплатена",
        payment_in_progress: "В процес на плащане",
        payment_in_progress_consultation: "В процес на плащане",
        payment_rejected: "Неплатен",
        payment_rejected_consultation: "Неплатена",
        payment_completed: "Платен",
        payment_completed_consultation: "Платена",
        accept_appointment_confirmation_header: "Сигурни ли сте. че искате да потвърдите прегледа на {fullname} за {date}?",
        accept_appointment_confirmation_body: "Прегледът ще бъде потвърден веднага.",
        accept_appointment_label: "Потвърди",
        table_header_morning_label: "Сутрин",
        table_header_afternoon_label: "Следобед",
        table_header_evening_label: "Вечер",
        table_header_night_label: "През нощта",
        pulse_pressure_label: "Пулсово налягане",
        mean_arterial_pressure_label: "Ср. артериално налягане",
        table_header_min_label: "Минимално",
        table_header_max_label: "Максимално",
        table_header_avg_label: "Средно",
        table_header_low_label: "Ниско",
        table_header_normal_label: "Нормално",
        table_header_high_label: "Високо",
        last_7_days: "Последни 7 дена",
        last_14_days: "Последни 14 дена",
        last_30_days: "Последни 30 дена",
        last_90_days: "Последни 90 дена",
        daily_avg_title: "Среднодневни",
        min_max_avg_title: "Минимални, максимални и средни",
        category_counts_title: "Бройки по категории",
        total_count_measurements_label: "Брой измервания",
        classification_category_label: "Категория",
        category_low: 'Ниско',
        category_optimal: 'Оптимално',
        category_normal: 'Нормално',
        category_normal_high: 'Завишено нормално',
        category_grade_1: 'Хипертония I степен',
        category_grade_2: 'Хипертония II степен',
        category_grade_3: 'Хипертония III степен',
        category_elevated: 'Завишено',
        category_stage_1: 'Хипертония стадий 1',
        category_stage_2: 'Хипертония стадий 2',
        category_hypertensive_crysis: 'Хипертонична криза',
        category_prehypertension: 'Предхипертонично състояние',
        category_stage_3: 'Хипертония стадий 3',
        category_high: 'Завишено',
        cardiac_tab_label: 'Кардио',
        medications_tab_label: "Лекарства",
        register_form_password_confirm_required_message: "Потвърдете паролата",
        email_required_label: "Електронна поща*",
        country_required_label: "Страна*",
        contact_email: "Имейл за връзка",
        city_required_label: "Град*",
        password_required_label: "Парола*",
        just_now_label: 'точно сега',
        dictate: "Започни диктуване",
        stop_dictating: "Спри диктуване",
        add_prescription: "Добави нов медикамент",
        add_medication: "Добави медикамент",
        update_prescription: "Редактирай медикамент",
        medication_name_label: "Име на лекарството",
        unit_label: "Единица",
        dose_label: "Доза",
        days_between_intake: "Брой на приемане",
        days: "дни",
        every: "Всеки",
        number_times_per_day: "Брой приеми на ден",
        number_of_days: "Брой дни",
        schedule: "График",
        duration: "Продължителност",
        frequency: "Колко често",
        frequency_times: "Брой на приеми",
        frequency_hours: "Брой часове",
        frequency_times_day: "Х пъти на ден",
        frequency_every_hours: " Всеки Х часа",
        take: "Вземи",
        rest: "Почивай",
        cycle_day: "Ден на цикъла",
        unit_type_ampule: "ампула(и)",
        unit_type_application: "прилагане(ия)",
        unit_type_capsule: "капсула(и)",
        unit_type_drop: "капка(и)",
        unit_type_gram: "г",
        unit_type_injection: "инжекция(ии)",
        unit_type_mg: "мг",
        unit_type_ml: "мл",
        unit_type_packet: "саше(та)",
        reset_btn_label: "Отмени",
        unit_type_patch: "пластир(и)",
        unit_type_piece: "част(и)",
        unit_type_tablet: "таблетка(и)",
        unit_type_puff: "инхалиране(ия)",
        unit_type_spray: "впръскване(ия)",
        unit_type_suppository: "супозитория(ии)",
        unit_type_tbsp: "с.л.",
        unit_type_tsp: "ч.л.",
        unit_type_unit: "единица(и)",
        no_label: "He",
        start_date_label: "Начална дата",
        end_date_label: "Крайна дата",
        delete_prescription: "Изтрий медикамент",
        delete_prescription_message: "Сигурни ли сте, че искате да изтриете този медикамент?",
        prescriptions: "Медикаменти",
        version: "версия",
        create_new_patient_btn: "Създай нов пациент",
        create_and_register_new_patient_btn: "Създай и регистрирай",
        create_and_register_new_patient_label: "Създаване и регистрация на нов пациент",
        blocked: "Блокирай съобщенията от този пациент",
        unblocked: "Разреши съобщенията от този пациент",
        default: "Автоматичен контрол",
        fullname_label: "Име",
        phone_label: "Телефон",
        phone_number_required: "Телефон*",
        phone_number_required_error: "Телефон е задължително поле!",
        appointment_table_provider_label: "При",
        first_available_hour_label: "Запазете първия свободен час на",
        book_label: "Запази първия свободен час",
        select_first_available_hour: "Избери първия свободен час",
        select_appointment_type_message: "Моля, изберете преглед за да видите кои са свободните часове за него",
        no_hours_available_primary_label: "Няма свободни часове",
        appointment_created: "Вие успешно запазихте час.",
        create_appointment: "Запазване на час",
        price_list_is_empty: "Празна ценова листа!",
        choose_clinician_modal_title: "Изберете лекар за провеждане на прегледа",
        latest_registrations: "Последни регистрации",
        clinicians_label: "Лекари",
        total_amount: "Обща сума",
        amount: "Сума",
        accounting_menu: "Справки",
        accounting_page_title: "Справки",
        login_form_temp_locked_down_message: "Вашият акаунт е временно заключен заради няколко последователни неуспешни опити за влизане. Моля, изчакайте няколко минути преди да се опитате да влезете отново.",
        login_form_must_reset_password_message: "Вашият акаунт е заключен заради последователни неуспешни опити за влизане. Можете да отключите Вашият акаунт като смените паролата си през функцията Забравена парола.",
        appointments_configuration: "Настройки на прегледи",
        auto_approve_appointments_label: "Автоматично потвърждаване на нови прегледи",
        start_video: "Включи видео",
        stop_video: "Изключи видео",
        no_video: "Няма видео",
        no_audio: "Няма аудио",
        mute: "Изключи",
        unmute: "Включи",
        join_now: "Влез в стаята",
        enter_examination_room: "Влез в стаята за преглед",
        entering_examination_room: "Влизане в стаята за преглед",
        audio: "Аудио",
        audio_input: "Аудио устройства",
        video: "Видео",
        video_input: "Видео камери",
        audio_and_video_settings: "Аудио и видео настройки",
        no_local_audio: "Няма аудио устройства",
        done_btn: "Готово",
        no_local_video: "Няма видео камера",
        You: "Вие",
        patient_label: "Пациент",
        clinician_label: "Лекар",
        free_label:"Безплатен",
        paid_label:"Платен",
        independent_timetable:"Независим график",
        create_new_schedule :"Нов график",
        time_on_button : "Приемно време",
        time_off_button :"Почивка",
        zoom_in_schedule:"Увеличи графика",
        zoom_out_schedule:"Намали графика",
        delete_time_off_description:"Искате ли да изтриете тази почивка от графика?",
        create_new_busy_slot_description:"Искате ли да добавите тази почивка към графика?",
        choose_action_timeslot_or_calendar:"Изберете между добавяне на почивка и създаване на нов преглед за избрания период.",
        mark_time_off:"Добавяне на почивка",
        select_appointment_type_message_for_schedule: "Изберете преглед",
        appointment_creation_failed: "Сървърна грешка при създаване на преглед!",
        appointment_update_failed: "Сървърна грешка при редакция на преглед!",
        appointment_starting_time_unavailable: "Началното време е заето от друг преглед!",
        date_of_payment: "Дата на плащане",
        date_of_appointment: "Дата на прегледа",
        camera_permissions_denied_heading: "Няма достъп до видеокамера:",
        camera_permissions_denied_message: "Отказан достъп до видеокамерата. Моля, разрешете на браузера да достъпи видеокамерата.",
        microphone_permissions_denied_heading: "Няма достъп до микрофон:",
        microphone_permissions_denied_message: 'Отказан достъп до микрофон. Моля, разрешете на браузера да достъпи микрофона.',
        not_allowed_error_heading: 'Няма достъп до входни устройства:',
        not_allowed_error_message: 'Операционната система е блокирала достъпа на браузера до микрофона или видеокамерата. Моля, проверете настройките на операционната си система.',
        not_allowed_error_message_alt: 'Отказан достъп до микрофон и камера. Моля, разрешете на браузера да достъпи микрофона и видеокамерата.',
        not_found_error_heading: 'Не са намерени микрофон или видеокамера:',
        not_found_error_message: 'Браузерът Ви не може да достъпи микрофона или видеокамерата. Моля, проверете дали всички устройства са включени.',
        connection_error_heading: 'Комуникационна грешка:',
        connection_error_message: 'Прекъсната връзка или прекратяване на медия. Моля, влезте отново в стаята и изчакайте другите участници да се включат.',
        error_acquiring_media_heading: 'Грешка при опит за достъп до видеокамера или микрофон:',
        no_camera_or_microphone_detected_heading: 'Не са намерени видеокамера или микрофон:',
        no_camera_or_microphone_detected_message: 'Другите участници няма да могат да ви чуват и виждат.',
        no_camera_detected_heading: 'Не е намерена камера:',
        no_camera_detected_message: 'Другите участници няма да могат да ви виждат.',
        no_microphone_detected_heading: 'Не е намерен микрофон:',
        no_microphone_detected_message: 'Другите участници няма да могат да ви чуват.',
        account_closed: "Потребителят е премахнал своя акаунт!",
        create_template: "Запази образец на медикамента",
        template_name_label: "Име на образеца",
        from_prescription_template: "Добави медикамент по образец",
        search_templates: "Търси в образците",
        pick_prescription_template: "Образци на медикаменти",
        pick_prescription_template_message: "Моля, изберете създаден от Вас образец на медикамент от списъка",
        save_prescription_prerequisite_description: "За да добавите медикамент, рецепта, амбулаторен лист или друг документ, моля, първо запазете резултатите от прегледа.",
        please_wait_for_the_other_participants_to_join: "Моля, изчакайте другите участници да се присъединят към стаята",
        peak_flow: "ВЕД",
        liters_per_minute: "л/мин",
        peak_flow_inhaler_label: "Използван е инхалатор",
        peak_flow_asthma_symptoms_label: "Наблюдавани са симптоми, като задух, стягане в гърдите, кашляне или изтръпване",
        peak_flow_night_asthma_symptoms_label: "Събуждане през нощта от астматични симптоми",
        peak_flow_daily_activity_label: "Затруднение при извършването на нормалните ежедневни дейности",
        inhaler_is_used: "Използван е инхалатор",
        had_asthma_symptoms: "Наблюдавани са симптоми, като задух, стягане в гърдите, кашляне или изтръпване",
        had_night_asthma_symptoms: "Събуждане през нощта от астматични симптоми",
        cannot_keep_daily_activities: "Затруднение при извършването на нормалните ежедневни дейности",
        questionnaire: "Въпросник",
        occupied_seats: "Заети места",
        max_number_participants_label: "Максимален брой пациенти",
        payment_label: "Плащане",
        group_appointment: "Групов преглед",
        group_appointment_info_text: "Групов е преглед с повече от 1 пациент. Груповите прегледи не се включват в графика за работно време. Час за групов преглед се записва през График в Административния портал. При записване на часà се посочва максималният брой пациенти, които могат да се включат в груповия преглед.",
        private_price_info_text: "Този преглед няма да е достъпен за запазване на час от пациент, освен ако не изпратите директна връзка. За тези прегледи можете Вие да запазвате час през Административния портал.",
        allow_switch_to_online_info_text: "Достъпно само за прегледи провеждани на място, за които сте ограничили плащането да е само онлайн. При избор разрешавате на пациентите да променят на дистанционно провеждане след като часа е вече запазен. В меню настройки можете да зададете колко време преди началото на часа тази опция ще бъде разрешена. По подразбиране настройката е не по-късно от 4 чàса преди прегледа.",
        payment_info_text: "При прегледи провеждани на място имате възможност да изберете следните опции за плащане:\n" +
            "• Онлайн – заплащането е само онлайн и при неплащане в срок се анулира от системата. Сроковете за плащане са до половин час, ако е избран час в рамките на 24 часа от момента на запазване и до 24 чàса ако е избран по-късен час.\n" +
            "• На място – може да се заплати само при посещение на място. В този случай е необходимо Вие да отбележите часа като платен в системата.\n" +
            "• Онлайн или на място – няма срок за заплащане и не се анулира от системата. Има възможност да се плати онлайн или да отбележите прегледа като платен, когато плащането се извърши на място.",
        independent_schedule_info_text: "При избран независим график, свободните часове за този преглед се изчисляват без да се взима под внимание запазен час за друг преглед в същия времеви диапазон.\n",
        create_and_register_new_patient_info_text: "С тази форма създавате нов профил в системата и го регистрирате във Вашата организация. За детски профил е необходимо да въведете и информация за връзка с родител/настойник, с които ще бъде създаден и родителски профил.\n" +
            "Ако въведете имейл, родителят/настойникът ще получи данни за достъп до системата и ще може да управлява двата профила (родителски и детски) – да въвежда данни, да запазва часове за преглед, да прикача резултати от изследвания и др.\n" +
            "В случай, че родителят вече има създаден профил в системата, изберете „Съществуващ потребител“ в раздела Информация за контакт и въведете неговия ПИН. По този начин детският профил, който ще регистрирате ще бъде добавен към вече съществуващия в системата родителски профил.",
        create_and_register_new_patient_adult_info_text: "С тази форма създавате нов профил в системата и го регистрирате във Вашата организация. Пациентът ще получи данни за достъп до системата по имейл и ще може да управлява своя профил – да въвежда данни, да запазва часове за преглед, да прикача резултати от изследвания и др.\n",
        create_new_schedule_info_text: "За редакция, изберете съществуващ график от календара.",
        new_schedule_form_info_text:"Създайте график (приемно време/почивка) за прегледи като изберете дни от седмицата и часови диапазон, за които ще е активен.",
        private_range_info_text:"Часовете от този график не могат да бъдат запазвани от пациент. Час за преглед от този график се запазва през Административния портал.",
        organisation_schedule_info_text:"Изберете организация, в която се провежда прегледът.",
        location_schedule_info_text:"Изберете място на провеждане – онлайн или на място.",
        holiday_info_text:"Приемното време от създаден график не е достъпно за пациенти в дни със запазен отпуск.",
        download_report_info_text:"Генериране на амбулаторен лист, рецепта, епикриза или заключение, от записаните резултати от прегледа.",
        template_info_text:"Създава образец за многократно използване на създадения медикамент.",
        auto_approve_info_text:"Изберете дали прегледите да бъдат потвърждавани от системата автоматично или ръчно. Непотвърдени прегледи не могат да бъдат заплатени от пациента.",
        auto_chat_info_text:"Пациентите могат да Ви изпращат чат-съобщения в следните случаи:\n" +
            "• имат запазен час за преглед;\n" +
            "• до 14 дни след приключване на преглед.",
        lock_chat_info_text:"Пациентите нe могат да Ви изпращат чат-съобщения.",
        schedule_unlock_info_text:"Изберете дните от седмицата и часовия диапазон, в който ще са разрешени чат-съобщенията.",
        start_video_call: "Започни видео разговор",
        invalid_date_format: "Невалиден формат на датата",
        invalid_time_format: "Невалиден формат на времето",
        provider_documents: "Качени документи",
        document_delete_label: "ще бъде изтрито (това действие не може да бъде отменено",
        days_before_appointment_label: "Брой дни преди датата на прегледа ",
        end_hour_label: "Краен час",
        end_time_input_label: "Краен час",
        start_time_input_label: "Начален час",
        end_time_for_appointment_booking: "Краен срок за запазване на час",
        avg_by_category: "Средни по категории",
        cardiac_report_title: "Кръвно налягане",
        download_cardiac_report: "Изтегли справка за кръвно налягане",
        code_2fa_mismatch: "Грешен код за влизане!",
        two_fa_current_method: "Вашият текущ двуфакторен метод за влизане е ",
        two_fa_state_off: "Изключен",
        two_fa_state_not_set: "Не е избран",
        two_fa_code: "Kод за влизане",
        two_fa_code_explanation: "Можете да промените метода на двуфакторна автентикация или да я изключите от страницата Сигурност.",
        email_2fa_sent: "Проверете пощата си за нов код за влизане",
        send_2fa_email: "Цъкнете тук за да изпратим нов код за влизане на Вашият имейл",
        authenticator_label: "Приложение за автентикация",
        two_fa_off: "Изключи",
        select_2fa_method_step_1: "Изберете двуфакторен метод за влизане",
        clinician_change_2fa_modal_header: "Двуфакторен метод за влизане",
        add_new_document: "Прикачи нов документ",
        email_two_fa_selector: "Получавайте имейл с код за влизане, който да използвате при влизане в приложението",
        totp_two_fa_selector: "Използвайте Authenticator мобилно приложение за получаване на код за влизане, който да използвате при влизане",
        turn_off_two_fa_selector: "Изключете двуфакторният метод за влизане (не се препоръчва)",
        email_two_fa_step_1: "Когато влизате в акаунта си в Medrec:M Clinic, на имейла ви ще бъде изпратен код от шест символа, който да използвате по време на влизане. Щракнете върху \"Активиране\", за да включите този двуфакторен метод за влизане.",
        activate_btn: "Активиране",
        verify_btn: "Провери",
        two_fa_method_successfully_deactivated: "Двуфакторният метод за влизане е изключен.",
        two_fa_method_totp_successfully_activated: "Двуфакторният метод за влизане посредством мобилно приложение за автентикация е активиран.",
        two_fa_method_email_successfully_activated: "Двуфакторният метод за влизане посредством имейл е активиран.",
        print_btn: "Печат",
        two_fa_warning_off: "Профилът ви е защитен по-добре, когато се нуждаете от парола и код за влизане, за да влезете в него. Ако премахнете тази допълнителна мярка за сигурност, при вход ще ви бъде искана само парола. Така някой друг може по-лесно да проникне в профила ви.",
        verify_totp_input: "Използвайки мобилно приложение за автентикация, генерирайте код за влизане, въведете 6-цифрения код в полето и натиснете Провери, за да потвърдите. Ако кодът за влизане е правилен, ще бъдете пренасочени към следващата стъпка, в противен случай ще бъдете помолени да въведете нов код за влизане.",
        recovery_codes_description: "Отпечатайте или изтеглете тези резервни кодове за влизане за еднократна употреба. Тези кодове могат да се използват вместо код за влизане, за да влезете в акаунта си, в случай че нямате достъп до приложението за автентикация.",
        two_fa_totp_qr_description: "Свържете приложението си за автентикация с вашия акаунт в Medrec:M Clinic, като сканирате QR кода или въведете кода за активиране ръчно. (Ако все още нямате инсталирано приложение на вашето устройство, ще трябва да изтеглите такова. Можете да използвате всяко приложение за автентикация с еднократна парола (TOTP), като Google Authenticator, Authy, Duo Mobile, 1Password и др.)",
        processing: "Моля, изчакайте. Вашата заявка се обработва.",
        security_page_title: "Сигурност",
        security_label: "Сигурност",
        continue_btn: "Продължи",
        change_btn: "Промени",
        login_verification_code_by_email: "Моля, проверете имейла си за 8-цифрен код за влизане и го въведете в полето отдолу",
        login_verification_code_by_totp: "Моля, отворете вашето мобилно приложение за автентикация, намерете 6-цифрения код за влизане и го въведете в полето отдолу",
        additional_results: "Допълнителни тестове и процедури",
        pick_test_results_template: "Допълнителни тестове и процедури",
        pick_test_template: "Допълнителни тестове и процедури",
        delete_test_results: "Изтриване",
        delete_test_results_message: "Сигурни ли сте, че искате да изтриете тези допълнителни тестове и процедури?",
        description: "Описание",
        disclaimer: "Допълнителни условия (трябва да се приеме от пациента)",
        duration_range_picker_label: "Период на справката",
        pf_norm: "Средно",
        pf_norm_stddev: "Стандартно отклонение",
        pf_norm_count: "Брой",
        pf_norm_max: "Максимум",
        pf_norm_standard: "Стандартна стойност",
        pf_norm_title: "ВЕД норма",
        peak_flow_weekly_diary: "ВЕД - седмична справка",
        not_selected_value_label: "Неизбран",
        not_selected_value_feminine_noun_gender_label: "Неизбрана",
        yes_value_label: "Да",
        no_value_label: "Не",
        main_examination_result_fields_are_empty: "Основни полета в резултата са празни",
        mark_as_paid_appointment_confirmation_header: "Сигурни ли сте, че искате да маркирате прегледа на {fullname} за {date} като \"Платен\"?",
        mark_appointment_as_paid_btn: "Маркирай като \"Платен\"",
        mark_as_paid__appointment_confirmation_body: "Използвайте тази опция, ако потребителят е платил за прегледа извън платформата на Medrec:M.",
        irreversible_action: "Това действие е необратимо!",
        peak_flow_norm: "ВЕД норма (л/мин)",
        consultations_menu_label: "Писмени консултации",
        consultations_dashboard: "Активни писмени консултации",
        consultations_page_title: "Писмени консултации",
        active_consultations_label: "Активни консултации",
        no_active_consultations: "Няма активни консултации",
        no_consultations: "Няма писмени консултации",
        all_consultations_label: "Всички консултации",
        urgent_consultations: "Нови",
        uncompleted_consultations: "Незавършени",
        completed_consultations: "Завършени",
        last_message: "%1 написа",
        duration_d_h_m: "%1 дни, %2ч. %3мин.",
        duration_h_m: "%1ч. %2мин.",
        duration_m: "%1 минути",
        expires_after: "Изтича след: ",
        expires_on: "Отговорете до ",
        remaining: "Остават ",
        last_modified: "Последна промяна: ",
        text_consultation: "Писмена консултация",
        confirm_note_visible_to_patient_modal_header: "Видимост на съобщението",
        confirm_note_visible_to_patient_modal_body: "Съобщението ще може да бъде видяно от пациента веднага след запазването му!",
        confirm_note_visible_to_patient_modal_body_2: "Моля, изберете бутона \"Потвърди\" за да запазите съобщението, в противен случай изберете бутона \"Затвори\" за да продължите редакцията му.",
        complete_text_button_missing_explanation: "Можете да приключите прегледа, след като бъде заплатен от пациента.",
        unpaid_video_explanation: "Можете да започнете видео прегледа, след като бъде заплатен от пациента.",
        note_visible_to_patient_visibility_form_warning: "Съобщението ще може да бъде видяно от пациента!",
        more: "Повече",
        less: "По-малко",
        range_restriction: "Валидните стойности са между %1 и %2.",
        overdue_modal_body: "Съжаляваме, но времето за отговор на тази писмена консултация изтече и тя ще бъде затворена.",
        warning_title: "Внимание!",
        overdue_will_be_canceled: "Времето за отговор е изтекло и тази консултация ще бъде затворена автоматично.",
        end_before_start_warning: "Началното време трябва да бъде преди крайното!",
        id_type_egn: "ЕГН",
        id_type_lnz: "ЛНЧ",
        id_type_ssn: "SSN",
        id_type_pass: "Паспорт",
        id_type_other: "Друг",
        attach_provider_document_from_report: "Създай документ и го прикачи към прегледа",
        document_picker_title: "Генериране на документ",
        choose_document: "Изберете типа на документ, който искате да генерирате от списъка отдолу",
        med_report_subject_field_label: "Предмет на документа",
        epicrisis_report_purpose: "Предназначение",
        encounter_outcome_document_no_field_label: "Номер на документ",
        packs: "опаковки",
        pack: "опаковка",
        quantity: "Количество",
        medication_form: "Лекарствена форма",
        quantity_by_form: "Количество по форма",
        instructions: "Инструкции за прием",
        invalid_value: "Невалидна стойност",
        MORNING: "сутрин",
        EARLY_MORNING: "рано сутрин",
        LATE_MORNING: "късно сутрин",
        NOON: "по обед",
        AFTERNOON: "следобед",
        EARLY_AFTERNOON: "ранен следобед",
        LATE_AFTERNOON: "късен следобед",
        EVENING: "вечер",
        EARLY_EVENING: "рано вечер",
        LATE_EVENING: "късно вечер",
        NIGHT: "през нощта",
        AFTER_SLEEP: "след сън",
        BEFORE_SLEEP: "преди сън",
        UPON_WAKING: "при събуждане",
        WITH_MEAL: "с храна",
        WITH_BREAKFAST: "със закуска",
        WITH_LUNCH: "с обяд",
        WITH_DINNER: "с вечеря",
        BEFORE_MEAL: "преди храна",
        BEFORE_BREAKFAST: "преди закуска",
        BEFORE_LUNCH: "преди обяд",
        BEFORE_DINNER: "преди вечеря",
        AFTER_MEAL: "след храна",
        AFTER_BREAKFAST: "след закуска",
        AFTER_LUNCH: "след обяд",
        AFTER_DINNER: "след вечеря",
        jump_to_date: "Избери свободна дата",
        reschedule: "Пренасрочване",
        confirm_update_appointment_body: 'Натиснете "Запази", за да запишете промените',
        chat_configuration: "Настройки на чата",
        lock_chat_input_label: "Заключи чатовете",
        auto_chat_input_label: "Автоматичен контрол",
        schedule_chat_input_label: "Използвай график за отключване",
        private_price_input_label: "Забрани избирането от пациенти",
        permanent_link_button: "Директна връзка",
        copy_to_clipboard: "Копирай в клипборда",
        link_copied_toast: "Връзката е копирана",
        contact_information_title: "Информация за връзка",
        child_button_label: "Дете",
        adult_button_label: "Възрастен",
        email_or_phone_required: "Поне едно от полетата Електронна поща или Телефон не трябва да бъде празно!",
        patients_management_label: "Управление на пациенти",
        paid_online_only: "Само платени онлайн",
        paid_outside_medrec: "платен извън системата",
        select_clinicians_to_see_schedule: "Няма избрани лекари",
        user_not_found: "Няма такъв потребител!",
        new_contact_btn: "Нов потребител",
        existing_contact_btn: "Съществуващ потребител",
        appointment_filter_all: "Всички",
        appointment_filter_completed: "Завършени прегледи",
        appointment_filter_pending: "Предстоящи прегледи",
        appointment_filter_active_consults: "Активни консултации",
        private_range_input_label: "Недостъпен за пациенти",
        appointment_on: 'преглед "%1" на',
        accounting_appointment_status_canceled: "анулиран",
        edit_contact_information_btn: "Редактирай информацията за връзка",
        add_another_child_btn: "Добави дете за този контакт",
        order_index_label: "Пореден номер в списъка",
        allergen: "Алерген",
        confirm_delete_chronic_condition_modal_body: "Сигурни ли сте, че искате да изтриете това хронично заболяване?",
        confirm_delete_allergy_modal_body: "Сигурни ли сте, че искате да изтриете тази алергия?",
        diagnose_date: "Дата на диагноза",
        edit_chronic_condition: "Редакция на хронично заболяване",
        new_chronic_condition: "Ново хронично заболяване",
        chronic_disease: "Хронично заболяване",
        chronic_disease_required_label: "Наименование е задължително поле!",
        allergen_required_label: "Алерген е задължително поле!",
        add_button: "Добави",
        new_allergy: "Нова алергия",
        allergy_type: "Тип на алергията",
        allergy_severity: "Сила на алергията",
        ICB10_classification: "Класификация (МКБ-10)",
        allergy_medications:"Лекарства",
        edit_allergy:"Редакция на алергия",
        new_text_consultations_popup_body:"Имате <span class='text-danger'>%1</span> нови писмени консултации.",
        new_text_consultations_popup_body_singular:"Имате <span class='text-danger'>%1</span> нова писмена консултация.",
        new_text_consultations_popup_body_2:"Моля, отговорете до <span class='text-danger'>%1</span> на най-рано изтичащата от тях.",
        new_text_consultations_popup_body_2_singular:"Моля, отговорете до <span class='text-danger'>%1</span>, в противен случай консултацията ще бъде анулирана.",
        open_consultations:"Отвори Писмени Консултации",
        earliest_expiring_consultation:"Най-рано изтичаща писмена консултация",
        year:"година",
        years:"години",
        history:"История",
        period_title:"Менструация",
        cycle_title:"Цикъл",
        cycle_day_label:"Ден от цикъла",
        average_label:"Средно",
        last_cycle_length_label:"Последен цикъл",
        last_period_length_label:"Последна менструация",
        shortest_male_label:"Най-къс",
        longest_male_label:"Най-дълъг",
        shortest_female_label:"Най-къса",
        longest_female_label:"Най-дълга",
        download_menstruation_report:"Изтегли справка за менструация",
        menstruation_report:"Справка за менструация",
        hint_examination_tests:"Попълвайте всяко изследване на нов ред.",
        id_type_newborn: "Новородено",
        gender_undefined: "Неопределен",
        no_option_found: "Няма намерени резултати",
        no_medications_for_prescription_report_body:"Няма предписани медикаменти или всичките са включени в електронни рецепти.",
        prescription_report_cant_create:"Рецептата не може да бъде празна!",
        add_medical_notice:"Създай медицинска бележка",
        force_create_appointment: "Не проверявай за друг запазен час в избраното време",
        nonmedical_personnel:"Немедицински персонал",
        ORG_ROLE_ADMIN:"Администратор",
        ORG_ROLE_CLINICIAN:"Лекар",
        ORG_ROLE_REGISTRATION_DESK:"Регистратура",
        ORG_ROLE_CONSULTING_CLINICIAN:"Консултиращ лекар",
        ORG_ROLE_LAB_ASSISTANT:"Лаборант",
        ORG_ROLE_CUSTOM:"Друго",
        register_choose_registration_type:"Изберете дали да създадете лекарски профил на администраторския акаунт.",
        register_clinician_type_chosen:"Избраната опция ще създаде лекарски профил с административни права към клиниката. Подходящо за индивидуални практики или практики, в които Вие ще провеждате прегледите.",
        register_other_type_chosen:"Избраната опция ще създаде администраторски профил без право да провежда прегледи. За да започнете да провеждате прегледи, ще трябва да регистрирате допълнително лекари в клиниката от менюто Персонал.",
        register_internal_unselected_option_text: "Лекарски профил",
        register_internal_selected_option_text: "Администраторски профил",
        invitation_page_title:"Здравейте",
        invite_page_info_message:"Поканени сте да се присъедините към %1.",
        existing_user_invitation_accept_header: "Ако имате акаунт в Medrec:M Clinic, въведете Вашите идентификационни данни за вход",
        new_user_user_invitation_accept_header: "Ако нямате акаунт натиснете бутона по-долу за да се присъедините към %1 с нов акаунт.",
        role_mismatch_error_message:"Неуспешна операция - грешна роля!",
        account_type_mismatch_error_message:"Неуспешна операция - грешен тип акаунт!",
        switch_practice_menu_item:"Смени организация",
        email_does_not_match_invitation:"Вашата електронна поща не съвпада с тази на поканата.",
        invitation_not_found_or_invalid:"Поканата не е намерена или е невалидна.",
        staff_management: {
            add_new_staff_member_title: "Създай нов член на персонала",
            invite_new_staff_member_title: "Покани нов член на персонала",
            give_administrative_rights: "Администраторски права",
            is_non_medical_staff: "Принадлежи към немедицинския персонал (няма да провежда прегледи)",
            is_visible_to_patients: "Видим е за пациентите",
            has_a_role: "Има следната роля",
            buttons: {
                add_new_staff_member: "Създай нов",
                invite_new_staff_member: "Изпрати покана",
                manage: "Смени роля",
                edit: "Редактирай",
                active_invitations: "Активни покани"
            },
            invitation_delete_body:"Сигурни ли сте че искате да изтриете поканата към %1, %2?",
            invitation_delete_modal_title:"Изтрий покана",
            no_active_invitations:"Няма активни покани",
            cant_remove_admin:"Организацията трябва да има поне един администратор!",
            lose_staff_management_rights: "Избраната опция ще премахне Вашите права да управлявате данните за персонала - " +
                "административни права, роля, видимост от пациенти, лични данни и други, " +
                "както и да добавяте нови членове или редактирате съществуващи членове на персонала!"
        },
        nhis: {
            delete_prescription:"Анулиране на електронна рецепта",
            delete_prescription_message:"Сигурни ли сте, че искате да анулирате тази електронна рецепта?",
            processing_body:"Моля, изчакайте...",
            sign_error:"Грешка при подписване! Моля, проверете дали подписа ви е включен в компютъра и Medrec:M Sign Server е пуснат.",
            service_error:"НЗИС сървис грешка! Моля, опитайте по-късно!",
            nhis_specialties_search:"Специалности по НЗИС",
            remove_supplement_modal_message: "Сигурни ли сте, че искате да изтриете нелекарственият продукт?",
            remove_medication_modal_message: "Сигурни ли сте, че искате да изтриете това лекарство?",
            from_medication_template: "Избери лекарство по образец",
            pick_medication_template: "Образци на лекарства",
            pick_medication_template_message: "Моля, изберете образец на лекарство от списъка",
            supplement_label:"Опишете в свободен текст",
            from_prescription_template: "Създай електронна рецепта по образец",
            create_template_label: "Създай образец по тази електронна рецепта",
            create_prescription_label: "Създай електронна рецепта",
            pick_prescription_template: "Образци на електронни рецепти",
            pick_prescription_template_message: "Моля, изберете образец на електронна рецепта от списъка",
            template_name_label: "Име на образеца",
            successfully_created_and_sent_to_nhis: "Издадена е рецепта с НРН номер",
            confirm_exit_body: "Сигурни ли сте, че искате да излезете? Въведените данни няма да бъдат запазени.",
            search_placeholder: "Търси...",
            prescription_based_on_label: "НРН на преглед, към който се издава рецептата",
            prescription_number_of_executions_label: "Брой на изпълненията",
            prescription_category_label: "Вид на рецептата",
            is_protocol_based_label: "Базирана на протокол",
            prescription_protocol_number_label: "Номер на протокол",
            prescription_protocol_date_label: "Дата на издаване на протокола",
            prescription_rhif_number_label: "РЗОК област",
            no_label: "НЕ",
            yes_label: "ДА",
            select: "Избери...",
            unlimited: "Неограничен",
            supplement: "Нелекарствени продукти",
            revoke_reason:"Причина за анулиране",
            confirm_delete_dosage_instruction: "Сигурни ли сте, че искате да изтриете тази инструкция за прием?",
            loading_data_message:"Зареждане...",
            error_loading_nomenclatures:"Възникна грешка при зареждане на номенклатурите!",
            try_again:"Опитай отново",
            send_to_nhis:"Изпрати към НЗИС",
            send_correction_to_nhis:"Коригирай в НЗИС",
            annul_nhis:"Анулирай в НЗИС",
            tab: {
                group: "Отрязък",
                patient: "Пациент",
                prescription: "Рецепта",
                medications: "Предписания",
            },
            btn: {
                leave_prescription: "Излез",
                confirm: "Потвърди",
                cancel: "Откажи",
                save: "Запиши",
                next: "Напред",
                add: "Добави",
                add_medication: "Добави лекарство",
                add_supplement: "Добави нелекарствен продукт",
                complete: "Издай рецептата",
                complete_exam: "Изпрати прегледа към НЗИС",
                complete_med_notice: "Изпрати бележката към НЗИС",
                delete_dosage_instruction: "Изтрий тази инструкция за прием",
                convert_to_nhis_exam:"Преобразувай към НЗИС преглед",
                complete_ereferral:"Изпрати направлението към НЗИС",
                check_ereferral_results:"Провери резултата в НЗИС"
            },
            patient: {
                title: "Пациент",
                first_name_label: "Име",
                last_name_label: "Фамилия",
                personal_id: "Идентификатор",
                county:"Област",
                idType:"Тип идентификатор",
                ekatte:"EKATTE",
            },
            provider: {
                medical_practitioner_practice: "Медицински специалист / Практика",
                pmi: "УИН",
                practiceNumber: "Код на лечебно заведение или практика",
                qualification: "Специалност",
                phone: "Телефонен номер",
                rhifAreaNumber:"РЗОК здравен район на лекаря",
            },
            medication: {
                create_template_label:"Създай образец по това лекарство",
                template_name_label:"Име на образеца",
                icb_label: "МКБ код на заболяване",
                medication: "Лекарство",
                form_label: "Лекарствена форма",
                quantity_label: "Предписано количество",
                is_quantity_by_form_off_label: "Опаковка",
                is_quantity_by_form_on_label: "Лекарствена форма",
                is_substitution_allowed_label: "Разрешава се замяна",
                priority_label: "Приоритет за издаване",
                effective_dose_period_start: "Начална дата на прием",
                note_for_pharmacists_label: "Указания към фармацевта"
            },
            dosage_instr: {
                title: "Дозировка",
                is_as_needed_label: "При необходимост",
                intake_frequency_label: "Честота на приемане",
                intake_period_label: "На всеки",
                quantity_for_single_intake_label: "К-во за еднократен прием",
                dose_quantity_code_label: "Форма на дозировката",
                intake_route_label: "Метод на приемане",
                intake_when_label: "Време или събитие, според което да се приеме лекарството",
                intake_offset_in_minutes_label: "Интервал в минути спрямо времето или събитието",
                intake_bounds_duration_label: "Продължителност на приема",
                bounds_duration_unit_label: "За времеви период",
                note_label: "Текстови указания за прием",
                form_label: "Лекарствена форма",
                unit_label: "Мерна единица",
                doseQuantityCodeInputPlaceholder: 'примерно "ml"'
            },
            examination: {
                choose_exam_type_table_heading: "Изберете дали резултатите от прегледа да бъдат изпратени към Националната Здравна Информационна Система (НЗИС) или да останат само в системата на Medrec:M.",
                exam_type_nhis_description: "Изберете тази опция, ако ще изпращате резултатите от прегледа към Националната Здравна Информационна Система (НЗИС). За издаване на медицински бележки е задължително да изберете тази опция.",
                exam_type_medrec_m_description: "Изберете тази опция, ако няма да изпращате резултатите от прегледа към Националната Здравна Информационна Система (НЗИС). Можете да преобразувате към НЗИС вариант на по-късен етап.",
                basedOn: "Номер на направление или на предходен преглед",
                directedBy: "Изпратен от",
                classification: "Тип преглед",
                purpose: "Цел на прегледа",
                incidental_visit_label: "Инцидентно посещение",
                is_secondary_label: "Вторичен преглед",
                financingSource: "Източник на финансиране",
                rhifAreaNumber: "РЗОК област",
                adverse_conditions_label: "Неблагоприятни условия в населеното място",
                is_pregnant_label: "Бременност",
                is_breastfeeding_label: "Кърмачка",
                gestational_week_label: "Гестационна седмица",
                medical_history_label: "Анамнеза",
                objective_condition_label: "Обективно състояние",
                conclusion: "Заключение",
                dischargeDisposition: "Насочи към",
                medications:"Лекарствени продукти",
                therapy_notes_label: "Бележки към терапията",
                therapy_section:"Терапия",
                main_diagnosis: "Основна диагноза",
                icdCode: "Диагноза",
                additionalIcdCodes: "Уточнения към диагнозата",
                role_label: "Роля",
                clinicalStatus: "Клиничен статус",
                verificationStatus: "Верификационен статус",
                onsetDateTime: "Първо проявление на симптомите",
                diagnosis_notes_label: "Бележки към диагнозата",
                additional_diagnoses: "Допълнителни диагнози",
                has_empty_diagnosis: "В този преглед има непопълнени диагнози! Моля, попълнете диагнозите преди да изпратите към НЗИС.",
                e_examination: "Преглед по НЗИС",
                examination: "Преглед",
                regular_examination: "Medrec:M преглед",
                results:"Резултати",
                tests_label:"Тестове",
                successfully_sent_to_nhis: "Прегледът е успешно изпратен към НЗИС.",
                nrn_number: "НРН",
                cancellation_reason:"Причина за анулиране",
                annul_success:"Прегледът е анулиран успешно!",
                confirm_convert_body:"Внимание! При преобразуването някои данни трябва бъдат въведени наново!",
                converted_successfully: "Успешно преобразуване! Прегледът бе преобразуван до НЗИС преглед.",
                need_to_edit_before_send:"За изпращане към НЗИС редактирайте резултатите от прегледа и попълнете задължителните полета!",
                need_to_complete_before_send: "Завършете прегледа за да можете да изпратите към НЗИС!",
                cancel_modal_header:"Анулиране на преглед",
                cancel_modal_body:"Сигурни ли сте, че искате да анулирате този преглед?",
                remove_diagnosis_modal_message: "Сигурни ли сте, че искате да изтриете тази диагноза?",
                import_examination_btn:"Търси в НЗИС",
                import_examination_button_description:"Търсене в НЗИС по НРН или ЕГН и дата на отваряне на прегледа. Резултатът може да бъде импортиран в Medrec:M.",
                search_nhis:"Търси",
                open_date:"Дата на отваряне",
                close_date:"Дата на затваряне",
                import_btn: "Импортирай",
                import_exam_modal_body: "Сигурни ли сте, че искате да импортирате преглед с НРН: %1 от дата %2?\n\nРезултатите ще бъдат прехвърлени в Medrec:M и прегледът ще бъде маркиран като ЗАВЪРШЕН.",
                import_exam_modal_title: "Импортиране на резултати от НЗИС преглед",
                import_server_error: "Сървърна грешка!",
                import_server_error_conflict: "Резултатите не могат да бъдат импортирани!\n\nИзбраните резултати вече са импортирани към друг преглед!\n\n",
                import_action_link: "Импортирай",
                status: "Статус",
                status_1:"Отворен",
                status_2:"Затворен",
                status_3:"Анулиран"
            },
            ereferral: {
                authoredOn:"Дата на издаване на направлението",
                add_ereferral:"Създай направление",
                ereferrals:"Направления",
                title:"Направление",
                financingSource: "Източник на финансиране",
                rhifAreaNumber: "РЗОК област",
                category: "Категория на направлението",
                type:"Тип на направлението",
                activity:"Назначени медико-диагностични дейности",
                qualificationTarget: "Лекарска специалност, за която се насочва пациента",
                qualificationTargets: "Лекарски специалности, за която се насочва пациента",
                admissionType:"Тип на приема",
                directedBy:"Насочен от",
                specializedActivityCode: "Специализирани дейности или манипулации, или процедури за физикална и рехабилитационна медицина",
                examType:"Вид на прегледа по НЗОК, за който се издава направлението за медицинска експертиза",
                workIncapacityReason:"Причина за издаване на направлението",
                workIncapacityAddressType:"Насочване към РКМЕ по тип адрес на пациента",
                clinicalPathway:"Клинична пътека по НЗОК",
                outpatientProcedure:"Амбулаторни процедури по НЗОК, за които се изпраща пациента",
                home_visit:"Необходимо е домашно посещение",
                employer:"Данни за работодател",
                employer_address:"Адрес на работодателя",
                employer_name:"Име на работодател",
                employer_indent_code:"ЕИК на работодател",
                employer_phone:"Телефон за връзка с работодател",
                employer_email:"Имейл за връзка с работодател",
                employer_country:"Държава",
                employer_county:"Област",
                employer_ekatte:"ЕКАТТЕ",
                employer_city:"Град",
                employer_line:"Пълен адрес",
                employer_postalCode:"Пощенски код",
                employer_position:"Длъжност на пациента при посочения работодател",
                documents:"Документи",
                document_date:"Дата на издаване на документа",
                document_number:"Номер на документа",
                document_isNrn:"Подадения номер е НРН",
                document_description:"Допълнителна информация за документа",
                documentType:"Тип на документа",
                remove_document_modal_message:"Сигурни ли сте, че искате да изтриете този документ?",
                main_diagnoses:"Насочващи основни диагнози",
                additionalDiagnoses:"Придружаващи заболявания",
                note:"Бележки в свободен текст към направлението от назначаващия лекар",
                successfully_sent_to_nhis: "Направлението е успешно изпратено към НЗИС.",
                nrn_number:"НРН",
                cancel_success:"Направлението е анулирано успешно!",
                delete_success: "Направлението е изтрито успешно!",
                modal_header_cancel:"Анулиране на направление",
                modal_header_delete:"Изтриване на направление",
                modal_body_cancel:"Сигурни ли сте, че искате да анулирате това направление?",
                modal_body_delete:"Сигурни ли сте, че искате да изтриете това направление?",
                cancellation_reason:"Причина за анулиране",
                no_execution_results:"Не са намерени резултати от изпълнение на това направление.",
                status: "Статус на направление",
                modal_header_check:"Проверка на резултати",
                close_ereferral_title:"Изход от направление",
                close_ereferral_body:"Моля потвърдете, че искате да излезете от направлението. Направлението няма да бъде запазено!",
                lab_res: {
                    name:"Наименование",
                    value:"Стойност",
                    unit:"Мерна единица",
                    reference:"Референтни стойности",
                    under:"под",
                    over:"над",
                    execution_date:"Дата на изпълнение"
                },
                hospitalization: {
                    status: "Статус на хоспитализация",
                    admissionDate: "Дата на прием",
                    admissionType: "Тип на прием",
                    reason: "Причина",
                    severity: "Състояние",
                    note: "Бележки",
                    dischargeDate: "Дата на изписване/прехвърляне",
                    outcome: "Резултат",
                    dischargeDisposition: "Насочен към",
                    dischargeNote: "Бележки относно изписване/прехвърляне",
                    daysHospitalized: "Болничен престой в дни",
                    deceasedDate: "Дата на смъртта",
                    autopsyDate: "Дата на аутопсията",
                    workability: "Работоспособност",
                    dischargeDiagnoses:"Окончателни диагнози при изписване",
                    comorbidity: "Придружаващи диагнози"
                }
            },
            medicalNotice: {
                medical_notices: "Медицински бележки",
                modal_header:"Медицинска бележка",
                based_on_label:"НРН на преглед",
                reason_label:"Основание",
                location_label:"Място на провеждане на лечението",
                location_view_label:"Място на лечението",
                institution_label:"Да послужи пред",
                code_label:"Заболяване",
                note_label:"Уточнения към бележката",
                successfully_sent_to_nhis: "Медицинската бележка е успешно изпратена към НЗИС.",
                nrn_number:"НРН",
                issued_on_label: "Издадена на",
                for_period:"За период",
                cancellation_reason:"Причина за анулиране",
                annul_success:"Медицинската бележка е анулирана успешно!",
                modal_header_cancel:"Анулиране на медицинска бележка",
                modal_body_cancel:"Сигурни ли сте, че искате да анулирате тази медицинска бележка?",
            }
        },
        medication: {
            unit: {
                label: {
                    ampule: {
                        display_long: 'ампула',
                        display_short: '',
                        display_long_plural: 'ампули',
                        display_short_plural: ''
                    },
                    application: {
                        display_long: 'прилагане',
                        display_short: '',
                        display_long_plural: 'прилагания',
                        display_short_plural: ''
                    },
                    capsule: {
                        display_long: 'капсула',
                        display_short: '',
                        display_long_plural: 'капсули',
                        display_short_plural: ''
                    },
                    drop: {
                        display_long: 'капка',
                        display_short: '',
                        display_long_plural: 'капки',
                        display_short_plural: ''
                    },
                    gram: {
                        display_long: 'грам',
                        display_short: 'г',
                        display_long_plural: 'грама',
                        display_short_plural: 'г'
                    },
                    injection: {
                        display_long: 'инжекция',
                        display_short: '',
                        display_long_plural: 'инжекции',
                        display_short_plural: ''
                    },
                    mg: {
                        display_long: 'милиграм',
                        display_short: 'мг',
                        display_long_plural: 'милиграма',
                        display_short_plural: 'мг'
                    },
                    ml: {
                        display_long: 'милилитър',
                        display_short: 'мл',
                        display_long_plural: 'милилитра',
                        display_short_plural: 'мл'
                    },
                    packet: {
                        display_long: 'саше',
                        display_short: '',
                        display_long_plural: 'сашета',
                        display_short_plural: ''
                    },
                    patch: {
                        display_long: 'пластир',
                        display_short: '',
                        display_long_plural: 'пластири',
                        display_short_plural: ''
                    },
                    piece: {
                        display_long: 'част',
                        display_short: '',
                        display_long_plural: 'части',
                        display_short_plural: ''
                    },
                    tablet: {
                        display_long: 'таблетка',
                        display_short: '',
                        display_long_plural: 'таблетки',
                        display_short_plural: ''
                    },
                    puff: {
                        display_long: 'инхалиране',
                        display_short: '',
                        display_long_plural: 'инхалирания',
                        display_short_plural: ''
                    },
                    spray: {
                        display_long: 'впръскване',
                        display_short: '',
                        display_long_plural: 'впръсквания',
                        display_short_plural: ''
                    },
                    suppository: {
                        display_long: 'супозитория',
                        display_short: '',
                        display_long_plural: 'супозитории',
                        display_short_plural: ''
                    },
                    tbsp: {
                        display_long: 'супена лъжица',
                        display_short: 'с.л.',
                        display_long_plural: 'супени лъжици',
                        display_short_plural: 'с.л.'
                    },
                    tsp: {
                        display_long: 'чаена лъжица',
                        display_short: 'ч.л.',
                        display_long_plural: 'чаени лъжици',
                        display_short_plural: 'ч.л.'
                    },
                    unit: {
                        display_long: 'единица',
                        display_short: '',
                        display_long_plural: 'единици',
                        display_short_plural: ''
                    }
                }
            }
        }
    }
}
