import Pagination from "react-bootstrap/Pagination";
import React, {useEffect} from "react";
import PropTypes from "prop-types";

function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });

}

const PaginationComponent = ({
                                 itemsCount,
                                 itemsPerPage,
                                 currentPage,
                                 setCurrentPage,
                                 alwaysShown = true
                             }) => {
    const pagesCount = Math.ceil(itemsCount / itemsPerPage);
    const isPaginationShown = alwaysShown ? true : pagesCount > 1;
    const isCurrentPageFirst = currentPage === 1;
    const isCurrentPageLast = currentPage === pagesCount;
    const changePage = number => {
        if (currentPage === number) return;
        setCurrentPage(number);
        scrollToTop();
    };

    const onPageNumberClick = pageNumber => {
        changePage(pageNumber);
    };

    const onPreviousPageClick = () => {
        changePage(currentPage - 1);
    };

    const onFirstPageClick = () => {
        changePage(1);
    };

    const onNextPageClick = () => {
        changePage(currentPage + 1);
    };

    const onLastPageClick = () => {
        changePage(pagesCount);
    };

    const setLastPageAsCurrent = () => {
        if (currentPage > pagesCount) {
            setCurrentPage(pagesCount);
        }
    };

    let isPageNumberOutOfRange;

    const pageNumbers = [...new Array(pagesCount)].map((_, index) => {
        const pageNumber = index + 1;
        const isPageNumberFirst = pageNumber === 1;
        const isPageNumberLast = pageNumber === pagesCount;
        const isCurrentPageWithinTwoPageNumbers =
            Math.abs(pageNumber - currentPage) <= 2;

        if (
            isPageNumberFirst ||
            isPageNumberLast ||
            isCurrentPageWithinTwoPageNumbers
        ) {
            isPageNumberOutOfRange = false;
            return (
                <Pagination.Item
                    key={pageNumber}
                    onClick={() => onPageNumberClick(pageNumber)}
                    active={pageNumber === currentPage}
                >
                    {pageNumber}
                </Pagination.Item>
            );
        }

        if (!isPageNumberOutOfRange) {
            isPageNumberOutOfRange = true;
            return <Pagination.Ellipsis key={pageNumber} className="muted" />;
        }

        return null;
    });

    useEffect(setLastPageAsCurrent, [pagesCount]);

    return (
        <>
            {isPaginationShown && (
                <Pagination>
                    <Pagination.First
                        onClick={onFirstPageClick}
                        disabled={isCurrentPageFirst}
                    />
                    <Pagination.Prev
                        onClick={onPreviousPageClick}
                        disabled={isCurrentPageFirst}
                    />
                    {pageNumbers}
                    <Pagination.Next
                        onClick={onNextPageClick}
                        disabled={isCurrentPageLast}
                    />
                    <Pagination.Last
                        onClick={onLastPageClick}
                        disabled={isCurrentPageLast}
                    />
                </Pagination>
            )}
        </>
    );
};

PaginationComponent.propTypes = {
    itemsCount: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.any,
    currentPage: PropTypes.number.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    alwaysShown: PropTypes.bool
};

export default PaginationComponent;