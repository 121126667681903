import {CHANGE_LANGUAGE} from "../actions/actions.js";
import {languages, SELECTED_LANGUAGE} from '../constants/available_languages';

const selectedLanguage = localStorage.getItem(SELECTED_LANGUAGE) ? JSON.parse(localStorage.getItem(SELECTED_LANGUAGE)) : {
    key: "bg",
    lang: "bg",
    alt: "Bulgarian",
    title: "Български"
};

const initialState = {
    selected: selectedLanguage, available: languages
}

/**
 * Language reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the language
 * @param {action} action the action to execute on the state
 */
export function language(state = initialState, action) {
    switch (action.type) {
        case CHANGE_LANGUAGE: {
            return {...state, selected: action.selected};
        }
        default: {
            return state
        }
    }
}