import React from 'react'
import {$$} from '../../helpers/localization'
import classNames from 'classnames'
import Select from '../shared/Select';
import {countryOptions} from '../../constants/countries';
import CustomTagsInput from '../shared/CustomTagsInput'
import {URL_UTILS} from '../../utils/urlUtils'
import NewOrganisation from "./NewOrganisation";
import PropTypes from "prop-types";


export class PracticeDetails extends React.Component {
    state = {
        disabled: false,
        practiceDetailsFields: {
            id: this.props.registrationFields.organizations[0].id || '',
            name: this.props.registrationFields.organizations[0].name || '',
            address: this.props.registrationFields.organizations[0].address || '',
            city: this.props.registrationFields.organizations[0].city || '',
            uin: this.props.registrationFields.organizations[0].uin || '',
            country: this.props.registrationFields.organizations[0].country || countryOptions()[0].value || '',
            website: this.props.registrationFields.organizations[0].website || '',
            phone_numbers: this.props.registrationFields.organizations[0].phone_numbers || [],
            details: this.props.registrationFields.organizations[0].details || '',
            type: this.props.registrationFields.organizations[0].type || '',
        },

        validPhoneNumbersField: true,
        formclass: '',
        errors: {},
        selected: this.props.registrationFields.organizations[0].name,
        newOrganisationOpen: false
    }

    constructor(props) {
        super(props)
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.registrationFields.submiting && this.props.registrationFields.submiting) {
            this.displayValidity();
        }
    }

    componentDidMount() {
        if (this.props.registrationFields.submiting) {
            this.setState({validPhoneNumbersField: this.state.practiceDetailsFields.phone_numbers.length > 0});
            this.displayValidity();
        }

        let currentOrganisations = this.props.registrationFields.organizations
        for (let i in currentOrganisations) {
            if (currentOrganisations[i].name === this.state.practiceDetailsFields.name && this.props.profilePage) {
                let disabled = currentOrganisations[i].type !== "SELF_REGISTERED"
                this.setState({disabled: disabled})
            }
        }
    }


    displayValidity = () => {
        if (this.state.formclass !== 'was-validated') {
            this.setState({formclass: 'was-validated'});
        }

    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields['practiceDetailsFields'][evt.target.name] = evt.target.value;
        this.setState(fields, () => {
            let form = this.form;
            let valid = {name: "practiceValid", value: form.checkValidity()};

            let currentOrganisations = this.props.registrationFields.organizations

            for (let i in currentOrganisations) {
                if (currentOrganisations[i].id === this.state.practiceDetailsFields.id) {
                    currentOrganisations[i].name = this.state.practiceDetailsFields.name;
                    currentOrganisations[i].address = this.state.practiceDetailsFields.address;
                    currentOrganisations[i].city = this.state.practiceDetailsFields.city;
                    currentOrganisations[i].country = this.state.practiceDetailsFields.country;
                    currentOrganisations[i].website = this.state.practiceDetailsFields.website;
                    currentOrganisations[i].phone_numbers = this.state.practiceDetailsFields.phone_numbers;
                    currentOrganisations[i].details = this.state.practiceDetailsFields.details;
                    currentOrganisations[i].uin = this.state.practiceDetailsFields.uin;
                }
            }
            let updateOrganisation = {target: {name: "organizations", value: currentOrganisations}};
            this.props.dataChanged(updateOrganisation, valid);
        });
    };

    /**
     * Set the state to the latest selected option.
     *
     * @param {object} evt - The event handler argument
     */
    onSelectChange = ({value}) => {
        if (this.state.disabled) {
            return
        }
        const fields = Object.assign({}, this.state);
        fields['practiceDetailsFields']['country'] = value;
        this.setState(fields, () => {
            let form = this.form;
            let valid = {name: "practiceValid", value: form.checkValidity()};
            let currentOrganisations = this.props.registrationFields.organizations

            for (let i in currentOrganisations) {
                if (currentOrganisations[i].id === this.state.practiceDetailsFields.id) {
                    currentOrganisations[i].name = this.state.practiceDetailsFields.name;
                    currentOrganisations[i].address = this.state.practiceDetailsFields.address;
                    currentOrganisations[i].city = this.state.practiceDetailsFields.city;
                    currentOrganisations[i].country = this.state.practiceDetailsFields.country;
                    currentOrganisations[i].website = this.state.practiceDetailsFields.website;
                    currentOrganisations[i].phone_numbers = this.state.practiceDetailsFields.phone_numbers;
                    currentOrganisations[i].details = this.state.practiceDetailsFields.details;
                    currentOrganisations[i].uin = this.state.practiceDetailsFields.uin;
                }
            }
            let updateOrganisation = {target: {name: "organizations", value: currentOrganisations}};
            this.props.dataChanged(updateOrganisation, valid);
        });
    };

    /**
     * Adds the item in the phone numbers array and updates the state
     *
     * @param {string} tag - the tag entered
     */
    handleAddition = (tag) => {
        if (this.state.disabled) {
            return
        }
        const fields = Object.assign({}, this.state);
        fields['practiceDetailsFields']['phone_numbers'].push(tag.text);
        fields['validPhoneNumbersField'] = true;
        this.setState(fields, () => {
            if (this.props.profilePage) {
                let currentOrganisations = this.props.registrationFields.organizations

                for (let i in currentOrganisations) {
                    if (currentOrganisations[i].id === this.state.practiceDetailsFields.id) {
                        currentOrganisations[i].name = this.state.practiceDetailsFields.name;
                        currentOrganisations[i].address = this.state.practiceDetailsFields.address;
                        currentOrganisations[i].city = this.state.practiceDetailsFields.city;
                        currentOrganisations[i].country = this.state.practiceDetailsFields.country;
                        currentOrganisations[i].website = this.state.practiceDetailsFields.website;
                        currentOrganisations[i].phone_numbers = this.state.practiceDetailsFields.phone_numbers;
                        currentOrganisations[i].details = this.state.practiceDetailsFields.details;
                        currentOrganisations[i].uin = this.state.practiceDetailsFields.uin;
                    }
                }
                //this.setState({selected: this.state.practiceDetailsFields.name})
                let updateOrganisation = {target: {name: "organizations", value: currentOrganisations}};
                let valid = {name: "practiceValid", value: true};
                this.props.dataChanged(updateOrganisation, valid);

            }
        });
    };

    /**
     * Removes the item from the phone numbers array and updates the state
     *
     * @param {string} tag - the index of the removed tag
     */
    handleDelete = (i) => {
        if (this.state.disabled) {
            return
        }
        const fields = Object.assign({}, this.state);
        fields['practiceDetailsFields']['phone_numbers'] = fields['practiceDetailsFields']['phone_numbers'].filter((tag, index) => index !== i);
        let form = this.form;
        this.setState(fields, function () {
            if (form['phone_numbers'].checkValidity() === false && this.state.formclass !== '') {
                this.setState({validPhoneNumbersField: false});
            }

            if (this.props.profilePage) {
                let currentOrganisations = this.props.registrationFields.organizations
                for (let i in currentOrganisations) {
                    if (currentOrganisations[i].id === this.state.practiceDetailsFields.id) {
                        currentOrganisations[i].name = this.state.practiceDetailsFields.name;
                        currentOrganisations[i].address = this.state.practiceDetailsFields.address;
                        currentOrganisations[i].city = this.state.practiceDetailsFields.city;
                        currentOrganisations[i].country = this.state.practiceDetailsFields.country;
                        currentOrganisations[i].website = this.state.practiceDetailsFields.website;
                        currentOrganisations[i].phone_numbers = this.state.practiceDetailsFields.phone_numbers;
                        currentOrganisations[i].details = this.state.practiceDetailsFields.details;
                        currentOrganisations[i].uin = this.state.practiceDetailsFields.uin;
                    }
                }
                //this.setState({selected: this.state.practiceDetailsFields.name})
                let updateOrganisation = {target: {name: "organizations", value: currentOrganisations}};
                let valid = {name: "practiceValid", value: fields['practiceDetailsFields']['phone_numbers'].length > 0};
                this.props.dataChanged(updateOrganisation, valid);

            }
        });
    };


    /**
     * Validate form data.
     *
     * @returns {object} errors - Form errors after validate
     */
    validate = () => {
        const errors = {};
        if (this.state.practiceDetailsFields.website && !URL_UTILS.isUrl(this.state.practiceDetailsFields.website)) {
            errors.website = 'register_form_website_not_correct_message';
        }
        return errors;
    }

    getOptions = (options) => {
        let values = [];
        for (let i in options) {
            values.push(options[i].name);
        }
        return values;
    }

    createOption = option => (
        <option
            value={option}
            key={option}
        >
            {option}
        </option>
    );

    handleOrganisationChange = (e) => {
        let currentOrganisations = this.props.registrationFields.organizations
        for (let i in currentOrganisations) {
            if (currentOrganisations[i].name === e.target.value) {
                let disabled = currentOrganisations[i].type !== "SELF_REGISTERED"
                this.setState({
                    disabled: disabled,
                    practiceDetailsFields: {
                        id: currentOrganisations[i].id,
                        name: currentOrganisations[i].name,
                        address: currentOrganisations[i].address,
                        city: currentOrganisations[i].city,
                        country: currentOrganisations[i].country,
                        website: currentOrganisations[i].website,
                        phone_numbers: currentOrganisations[i].phone_numbers,
                        details: currentOrganisations[i].details,
                        uin: currentOrganisations[i].uin,
                    },
                    selected: e.target.value,
                    validPhoneNumbersField: currentOrganisations[i].phone_numbers.length > 0
                })
            }
        }
    }

    addClinic = () => {
        this.setState({newOrganisationOpen: true})
    }

    closeAddClinic = () => {
        this.setState({newOrganisationOpen: false})
    }

    handleAddOrganisation = async (form) => {
        this.setState({selected: form.name})

        let practiceDetailsFields = {...this.state.practiceDetailsFields};
        practiceDetailsFields.name = form.name;
        practiceDetailsFields.address = form.address;
        practiceDetailsFields.city = form.city;
        practiceDetailsFields.country = form.country;
        practiceDetailsFields.website = form.website;
        practiceDetailsFields.phone_numbers = form.phone_numbers;
        practiceDetailsFields.details = form.details;
        practiceDetailsFields.type = "SELF_REGISTERED";
        practiceDetailsFields.uin = form.uin;

        this.setState({practiceDetailsFields: practiceDetailsFields});

        let currentOrganisations = this.props.registrationFields.organizations
        currentOrganisations.push(form)

        let valid = {name: "practiceValid", value: true};
        let evt = {target: {name: "organizations", value: currentOrganisations}};

        try {
            await this.props.dataChanged(evt, valid)
        } catch (e) {
            console.log(e)
        } finally {
            this.handleUpdate(true)
        }
    }

    handleUpdate = (noRedirect) => {
        let formErrors = this.validate();
        this.setState({errors: formErrors});
        if (this.form.checkValidity() && !Object.keys(formErrors).length) {
            this.props.submitForm(noRedirect).then((res) => {
                let evt = {target: {name: "organizations", value: res.organizations}};
                this.props.dataChanged(evt, {name: "practiceValid", value: true})
            })
        } else {
            if (this.form['phone_numbers'].checkValidity() === false) {
                this.setState({validPhoneNumbersField: false});
            }
            this.displayValidity();
        }
    }

    createOrganisationOptions = () => this.getOptions(this.props.registrationFields.organizations).map(this.createOption);

    render() {
        let cityClassNames = classNames('col-sm-6', {
            'register-inline-control-filled': this.state.practiceDetailsFields.city,
            'register-inline-control': !this.state.practiceDetailsFields.city
        });

        return (
            <div>
                {!this.state.newOrganisationOpen && <div className=''>
                    <label>{$$('organisation')}</label> &nbsp; &nbsp;
                    <select id="organisations"
                            className='custom-select calendar-select'
                            name="organisations"
                            value={this.state.selected}
                            onChange={(e) => this.handleOrganisationChange(e)}>
                        {this.createOrganisationOptions()}
                    </select>
                    &nbsp; &nbsp;
                    <button type='button' onClick={() => {
                        this.addClinic()
                    }} className='btn btn-success'>{$$('add')}</button>
                    <br/>
                    <br/>
                    <form className={this.state.formclass} noValidate={true} ref={(form) => {
                        this.form = form
                    }}>
                        <div style={{'minHeight': '43.75rem'}}>
                            <div className='form-group'>
                                {this.state.practiceDetailsFields.name &&
                                    <label>{$$('wizard_practice_details_org_name_label')}</label>}
                                <input readOnly={this.state.disabled} type='text' className='form-control'
                                       value={this.state.practiceDetailsFields.name || ''}
                                       placeholder={$$('wizard_practice_details_org_name_label')} name='name'
                                       onChange={this.onInputChange} required/>
                                <div className='invalid-feedback'>
                                    {$$('wizard_practice_details_org_name_required_label')}
                                </div>
                            </div>
                            <div className='form-group'>
                                {this.state.practiceDetailsFields.uin &&
                                    <label>{$$('wizard_practice_details_uin_label')}</label>}
                                <input readOnly={this.state.disabled} type='text' className='form-control'
                                       value={this.state.practiceDetailsFields.uin || ''}
                                       placeholder={$$('wizard_practice_details_uin_label')} name='uin'
                                       onChange={this.onInputChange} required/>
                                <div className='invalid-feedback'>
                                    {$$('wizard_practice_details_uin_required_label')}
                                </div>
                            </div>
                            <div className='form-group'>
                                {this.state.practiceDetailsFields.address &&
                                    <label>{$$('wizard_practice_details_address_label')}</label>}
                                <input readOnly={this.state.disabled} type='text' className='form-control'
                                       value={this.state.practiceDetailsFields.address || ''}
                                       placeholder={$$('wizard_practice_details_address_label')} name='address'
                                       onChange={this.onInputChange} required/>
                                <div className='invalid-feedback'>
                                    {$$('wizard_practice_details_address_required_label')}
                                </div>
                            </div>
                            <div className='form-group'>
                                {this.state.practiceDetailsFields.website &&
                                    <label>{$$('wizard_practice_details_website_label')}</label>}
                                <input readOnly={this.state.disabled} type='text'
                                       className={this.state.errors.website ? 'custom-error form-control' : 'form-control'}
                                       value={this.state.practiceDetailsFields.website || ''}
                                       placeholder={$$('wizard_practice_details_website_label')} name='website'
                                       onChange={this.onInputChange}/>
                                <div className={this.state.errors.website ? 'custom-invalid-feedback' : ''}>
                                    {this.state.errors.website ? $$(this.state.errors.website) : ''}
                                </div>
                            </div>
                            <div className='form-group'>
                                <input readOnly={this.state.disabled} type='text' className='form-control'
                                       value={this.state.practiceDetailsFields.phone_numbers || ''}
                                       placeholder={$$('wizard_phone_numbers_label')} name='phone_numbers'
                                       onChange={this.onInputChange} required hidden/>
                                <CustomTagsInput tags={this.state.practiceDetailsFields.phone_numbers}
                                                 handleAddition={this.handleAddition}
                                                 handleDelete={this.handleDelete}
                                                 placeholder={$$('wizard_phone_numbers_label')}
                                                 autofocus={false}
                                                 isFieldValid={this.state.validPhoneNumbersField}
                                                 formClass={this.state.formclass}/>
                                <div className='invalid-feedback'>
                                    {$$('wizard_phone_numbers_required_label')}
                                </div>
                            </div>
                            <div className='form-group'>
                                {this.state.practiceDetailsFields.details &&
                                    <label>{$$('wizard_practice_details_label')}</label>}
                                <textarea readOnly={this.state.disabled} className='form-control'
                                          value={this.state.practiceDetailsFields.details}
                                          placeholder={$$('wizard_practice_details_label')} name="details" rows="4"
                                          cols="50" onChange={this.onInputChange} required/>
                                <div className='invalid-feedback'>
                                    {$$('wizard_practice_details_required_label')}
                                </div>
                            </div>
                            <div className='row' style={{'marginTop': '0.3125rem'}}>
                                <div className='col-sm-6'>
                                    <Select
                                        label={$$('country_required_label')}
                                        name='country'
                                        options={countryOptions(this.props.i18n.selected.lang)}
                                        value={this.state.practiceDetailsFields.country || ''}
                                        onChange={this.onSelectChange}/>
                                </div>
                                <div className={cityClassNames}>
                                    {this.state.practiceDetailsFields.city &&
                                        <label>{$$('city_required_label')}</label>}
                                    <input readOnly={this.state.disabled} type='text' className='form-control'
                                           value={this.state.practiceDetailsFields.city || ''}
                                           placeholder={$$('city_required_label')} name='city'
                                           onChange={this.onInputChange} required/>
                                    <div className='invalid-feedback'>
                                        {$$('wizard_city_required_label')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-group' style={{'marginTop': '5.25rem'}}>
                            <div className='row flex=end-justify'
                                 style={{'float': 'right', 'margin': '0rem'}}>
                                <button type='button' onClick={() => {
                                    this.handleUpdate(false)
                                }} className='btn btn-success'>{$$('update')}</button>
                            </div>
                        </div>
                    </form>
                </div>
                }
                {this.state.newOrganisationOpen && <NewOrganisation
                    close={this.closeAddClinic}
                    i18n={this.props.i18n}
                    handleAddOrganisation={this.handleAddOrganisation}
                    organisations={this.props.registrationFields.organizations}
                />}
            </div>
        )
    }
}

PracticeDetails.propTypes = {
    dataChanged: PropTypes.func,
    i18n: PropTypes.object,
    onWizardStepChange: PropTypes.func,
    profilePage: PropTypes.bool,
    registrationFields: PropTypes.object,
    submitForm: PropTypes.func,
    wizardStep: PropTypes.number
}

export default PracticeDetails
