import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import VitalsLogbookEntryDetails from './VitalsLogbookEntryDetails'
import {formatUtils} from '../../utils/formatUtils'
import moment from 'moment';
import classNames from 'classnames'
import _ from 'underscore'
import PropTypes from "prop-types";


export class VitalsLogbookEntry extends Component {
    state = {
        expandTable: false
    }

    constructor(props) {
        super(props);
        this.index = 0;
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps) {
        if (this.props.selectedVitalLogbook &&
            this.props.entry &&
            !_.isEqual(this.props.selectedVitalLogbook, this.props.entry) &&
            this.state.expandTable) {
            this.setState({expandTable: false});
        }
    }

    componentWillUnmount = () => {
        this.props.selectVitalLogbook({});
    }

    /**
     * Handles click event on a vital logbook details button
     *
     * @param {object} e the event object
     */
    onVitalLogbookDetailsClick = (e) => {
        e.preventDefault();
        const shouldExpandTable = !this.state.expandTable;
        this.setState({expandTable: shouldExpandTable});
    }

    /**
     * Returns the DOM element representing the health issues list of the particular vital logbook entry
     *
     * @returns {HTMLElement} the created DOM element based on the vital logbook entry
     */
    getHealthIssues = () => {
        if (this.props.healthIssues && this.props.healthIssues.length > 0) {
            let healthIssues = this.props.healthIssues.join(', ');
            return <div key={++this.index}>
                {healthIssues}
            </div>
        }
    }

    /**
     * Returns the DOM element representing the user log list of the particular vital logbook entry
     *
     * @returns {Array} the created DOM element based on the vital logbook entry
     */
    getUserLogs = () => {
        let userLogs = [];
        if (this.props.symptoms && this.props.symptoms.length > 0) {
            userLogs.push($$('Symptoms'));
        }

        if (this.props.medications && this.props.medications.length > 0) {
            userLogs.push($$('Medications'));
        }

        if (this.props.logbook && this.props.logbook.length > 0) {
            this.props.logbook.map(l => {
                if (!userLogs.includes(formatUtils.formatV2(l.entry_type))) {
                    userLogs.push(formatUtils.formatV2(l.entry_type));
                }
            });
        }

        return <div key={++this.index}>
            {userLogs.join(", ")}
        </div>
    }

    /**
     * Selects a laboratory result
     */
    selectVitalLogbook = () => {
        if (this.props.selectedVitalLogbook === '' || !_.isEqual(this.props.selectedVitalLogbook, this.props.entry)) {
            this.props.selectVitalLogbook(this.props.entry);
        }
    }

    render() {
        let iconClasses = classNames('kt-menu__link-icon patient-details-icon', {
            'fas fa-chevron-down': !this.state.expandTable && !this.props.inline,
            'fas fa-chevron-up': this.state.expandTable && !this.props.inline
        });

        const activeClass = classNames({
            'lab-entry-active': (_.isEqual(this.props.selectedVitalLogbook, this.props.entry)),
            'vitals-logbook-entry-container': this.props.isFirst,
            'lab-entry-container': !this.props.isFirst
        });

        return (
            <div id='lab-entry' className={activeClass} onClick={this.selectVitalLogbook}>
                <div>
                    <div className='row'>
                        <div className='col-xs-2 col-md-2 text-center vitals-logbook-entry-cols-padding'>
                            <div
                                className='medrec-grey-2 medical-history-entry-label'>{$$('lab_results_date_label')}</div>
                            <div><b>{moment(this.props.date_time).locale(this.props.i18n.lang).format('D MMM')}</b>
                            </div>
                            <div
                                className='lab-entry-year'>{moment(this.props.date_time).locale(this.props.i18n.lang).format('YYYY')}</div>
                        </div>

                        <div className='col-xs-4 col-md-4 vitals-logbook-entry-cols-padding'>
                            <div className='medrec-grey-2 medical-history-entry-label'>{$$('health_issues_label')}</div>
                            <div>{this.getHealthIssues()}</div>
                        </div>

                        <div className='col-xs-5 col-md-5 vitals-logbook-entry-cols-padding'>
                            <div className='medrec-grey-2 medical-history-entry-label'>{$$('user_logs_label')}</div>
                            <div>{!this.props.inline ? this.getUserLogs() :
                                <div className='inline-entry'><VitalsLogbookEntryDetails symptoms={this.props.symptoms}
                                                                                         medications={this.props.medications}
                                                                                         logbook={this.props.logbook}
                                                                                         nomenclature={this.props.nomenclature}
                                                                                         settings={this.props.settings}
                                                                                         i18n={this.props.i18n}
                                                                                         inline={true}/></div>}
                            </div>
                        </div>

                        <div className='col-xs-1 col-md-1 vitals-logbook-entry-cols-padding'>
                            <div className='icons border-0 float-right'
                                 onClick={this.onVitalLogbookDetailsClick}>
                                <i className={iconClasses + " centered-text"}/>
                            </div>
                        </div>
                    </div>
                    {this.state.expandTable && !this.props.inline &&
                    <div>
                        <VitalsLogbookEntryDetails symptoms={this.props.symptoms}
                                                   medications={this.props.medications}
                                                   logbook={this.props.logbook}
                                                   nomenclature={this.props.nomenclature}
                                                   settings={this.props.settings}
                                                   i18n={this.props.i18n}/>
                    </div>
                    }
                </div>
                <hr className={"no-margin"}/>
            </div>
        )
    }
}

VitalsLogbookEntry.propTypes = {
    date_time: PropTypes.string,
    entry: PropTypes.object,
    healthIssues: PropTypes.array,
    i18n:  PropTypes.object,
    inline: PropTypes.bool,
    isFirst: PropTypes.bool,
    logbook: PropTypes.array,
    medications: PropTypes.array,
    nomenclature: PropTypes.object,
    selectVitalLogbook: PropTypes.func,
    selectedVitalLogbook: PropTypes.object,
    settings: PropTypes.object,
    symptoms: PropTypes.array
}

export default VitalsLogbookEntry