import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from "prop-types";
import {$$} from "../../helpers/localization";


export class Select extends Component {

    state = {
        value: this.props.value,
        error: false
    };

    static getDerivedStateFromProps(nextProps) {
        return {value: nextProps.value, error: nextProps.error}
    }

    /**
     * Set the state to the latest selected option.
     *
     * @param {object} evt - The event handler argument
     */
    onChange = (evt) => {
        const name = this.props.name;
        const value = evt.target.value;
        this.setState({value});
        this.props.onChange({name, value});
    };

    render() {
        const options = this.props.options ? this.props.options.map((o, i) => {
            return <option disabled={o.disabled} value={o.value} key={i}>{o.text}</option>
        }) : [];

        return (
            <div className={this.props.containerClass ? this.props.containerClass : ""}>
                {this.props.label && <label>{this.props.label}</label>}
                <select onChange={this.onChange}
                        value={this.state.value}
                        className={this.props.class ? ("custom-select " + this.props.class) : "custom-select "}
                        required={this.props.required}
                        autoFocus={this.props.autoFocus}
                        disabled={this.props.disabled}
                >
                    {this.props.emptyOption ? <option hidden={!this.props.enableClear} disabled={!this.props.enableClear} value="">{this.props.placeHolder}</option> : ""}
                    {options}
                </select>
                <div className="invalid-feedback">{this.state.error}</div>
            </div>
        )
    }
}

Select.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    class: PropTypes.any,
    error: PropTypes.any,
    placeHolder:PropTypes.string,
    settingsPage: PropTypes.bool,
    required: PropTypes.bool,
    value: PropTypes.any,
    emptyOption: PropTypes.bool,
    enableClear: PropTypes.bool
};

const mapStateToProps = (state) => ({
    i18n: state.language.selected
})

export default connect(mapStateToProps)(Select)
