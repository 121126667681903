import moment from 'moment'
import store from '../store'
import {$$} from '../helpers/localization'
import {
    HEIGHT,
    HYDRATION,
    TEMPERATURE,
    WEIGHT
} from '../constants/entry_types';
import {DASH} from '../constants/dash'
import {GENDER} from "../constants/select_options";

export const CONVERTER = {
    cholesterolPerUnit,
    convertByUnit,
    formatDate,
    getSelectedDateTimeFormat,
    getEntryDateTimeFormat,
    getDayPeriodFromTime,
    getFormatedCholesterolYAxes,
    getFormatedWeightYAxes,
    getFormatedHydrationYAxes,
    getFormatedTemperatureYAxes,
    getFormatedRespiratoryRateYAxes,
    getFormatedSaturationYAxes,
    getFormattedCholesterolUnit,
    getUnitByType,
    getBloodGroupByType,
    getSeverityForDisplay,
    getAllergyNameForDisplay,
    ketonesPerUnit,
    triglyceridesPerUnit,
    getFormattedKetonesUnit,
    bloodSugarPerUnit,
    getFormattedBloodSugarUnit,
    hba1cPerUnit,
    getFormattedHBA1CUnit,
    millisecondsToAge,
    formatTime,
    decimalHexTwosComplement,
    hexTwosComplementDecimal,
    formatSex,
    getTimeFormat
};

/**
 * Convert cholesterol value to unit selected in settings
 *
 * @param {number} value the value to convert
 * @returns {number} the converted value
 */
export function cholesterolPerUnit(value) {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.cholesterolUnits;
        if (!selectedUnit || selectedUnit === 'MMOL_L') {
            return value;
        } else if (selectedUnit === 'MG_DL') {
            return value === 0 ? 0 : Math.round((value * 38.67) + 0.5);
        }
    }
}

/**
 * Convert ketones value to unit selected in settings
 *
 * @param {number} value the value to convert
 * @returns {number} the converted value
 */
export function ketonesPerUnit(value) {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.ketonesUnits;
        if (!selectedUnit || selectedUnit === 'MMOL_L') {
            return value;
        } else if (selectedUnit === 'MG_DL') {
            return Math.round(value * 5.81 + 0.5);
        }
    }
}

/**
 * Convert blood sugar value to unit selected in settings
 *
 * @param {number} value the value to convert
 * @returns {number} the converted value
 */
export function bloodSugarPerUnit(value) {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.glucoseUnits;
        if (!selectedUnit || selectedUnit === 'MMOL_L') {
            return value;
        } else if (selectedUnit === 'MG_DL') {
            return Math.round(value * 18.018);
        }
    }
}

/**
 * Convert hba1c value to unit selected in settings
 *
 * @param {number} value the value to convert
 * @returns {number} the converted value
 */
export function hba1cPerUnit(value) {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.hba1cUnits;
        if (!selectedUnit || selectedUnit === 'PERCENT') {
            return value;
        } else if (selectedUnit === 'MMOL_MOL') {
            return value === 0 ? 0 : Math.round((value - 2.15) * 10.929);
        }
    }
}

/**
 * Convert Triglycerides value to unit selected in settings
 *
 * @param {number} value the value to convert
 * @returns {number} the converted value
 */
export function triglyceridesPerUnit(value) {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.cholesterolUnits;
        if (!selectedUnit || selectedUnit === 'MMOL_L') {
            return value;
        } else if (selectedUnit === 'MG_DL') {
            return value === 0 ? 0 : Math.round(88.57 * value + 0.5);
        }
    }
}

/**
 * Convert the given value based on the entry type and the selected unit
 *
 * @param {string} entryType the type of the entry to convert
 * @param {number} value the value to convert
 * @returns {number} the converted value
 */
export function convertByUnit(entryType, value) {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.unitsOfMeasurement;
        if (!selectedUnit || selectedUnit === 'METRIC') {
            return value;
        } else if (selectedUnit === 'US') {
            switch (entryType) {
                case WEIGHT: {
                    return roundToTwo(value * 2.2046);
                }
                case HYDRATION: {
                    return roundToTwo(value * 0.03381402);
                }
                case TEMPERATURE: {
                    return roundToTwo((value * 1.8) + 32);
                }
                case HEIGHT: {
                    return roundToTwo(value / 2.54);
                }
                default: {
                    return value;
                }
            }
        }
    }
}

/**
 * Get the formated cholesterol unit
 *
 * @returns {string} formated unit
 */
export function getFormattedCholesterolUnit() {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.cholesterolUnits;
        if (!selectedUnit || selectedUnit === 'MMOL_L') {
            return $$('millimol_per_litre');
        } else if (selectedUnit === 'MG_DL') {
            return $$('milligram_per_decilitre');
        }
    }
}

/**
 * Get the formated ketones unit
 *
 * @returns {string} formated unit
 */
export function getFormattedKetonesUnit() {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.ketonesUnits;
        if (!selectedUnit || selectedUnit === 'MMOL_L') {
            return $$('millimol_per_litre');
        } else if (selectedUnit === 'MG_DL') {
            return $$('milligram_per_decilitre');
        }
    }
}

/**
 * Get the formated blood sugar unit
 *
 * @returns {string} formated unit
 */
export function getFormattedBloodSugarUnit() {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.glucoseUnits;
        if (!selectedUnit || selectedUnit === 'MMOL_L') {
            return $$('millimol_per_litre');
        } else if (selectedUnit === 'MG_DL') {
            return $$('milligram_per_decilitre');
        }
    }
}

/**
 * Get the formated hba1c unit
 *
 * @returns {string} formated unit
 */
export function getFormattedHBA1CUnit() {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.hba1cUnits;
        if (!selectedUnit || selectedUnit === 'PERCENT') {
            return '%';
        } else if (selectedUnit === 'MMOL_MOL') {
            return $$('millimol_per_mol');
        }
    }

}

/**
 * Get the formated unit for the entry
 *
 * @param {string} entryType the entry type
 * @returns {string} formated unit
 */
export function getUnitByType(entryType) {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.unitsOfMeasurement;
        if (!selectedUnit || selectedUnit === 'METRIC') {
            switch (entryType) {
                case WEIGHT: {
                    return $$('kg');
                }
                case HYDRATION: {
                    return $$('ml');
                }
                case TEMPERATURE: {
                    return $$('celcius');
                }
                case HEIGHT: {
                    return $$('cm');
                }
                default: {
                    return selectedUnit;
                }
            }
        } else if (selectedUnit === 'US') {
            switch (entryType) {
                case WEIGHT: {
                    return $$('pound');
                }
                case HYDRATION: {
                    return $$('floz');
                }
                case TEMPERATURE: {
                    return $$('fahrenheight');
                }
                case HEIGHT: {
                    return $$('inches');
                }
                default: {
                    return selectedUnit;
                }
            }
        }
    }
}

/**
 * Format date with the format selected in settings
 *
 * @param {date} date to format
 * @param {boolean} hideTime flag used to hide time
 * @returns {date} formated date
 */
export function formatDate(date, hideTime) {
    if (store.getState().settings.data) {
        return moment(date).format(getSelectedDateTimeFormat(hideTime));
    }
}

/**
 * Get the format of date time from the settings
 *
 * @param {boolean} hideTime flag used to hide time
 * @returns {string} the format to use
 */
export function getSelectedDateTimeFormat(hideTime) {
    const dateFormat = store.getState().settings.data.dateFormat;
    if (dateFormat) {
        if (hideTime) {
            return dateFormat.toUpperCase();
        } else {
            const timeFormat = store.getState().settings.data.time24hour ? "HH:mm" : "h:mm a";
            return dateFormat.toUpperCase() + ", " + timeFormat;
        }
    }
}

export function getTimeFormat() {
    return store.getState().settings.data.time24hour ? "HH:mm" : "h:mm a";
}

export function formatTime(date) {
    return moment(date).format(store.getState().settings.data.time24hour ? "HH:mm" : "h:mm a");
}

/**
 * Format the date time for the user entries
 *
 * @param {Date} dateTime date time of the entries to format
 * @param showDayOfTheWeek boolean if set - display also the day of the week before the time.
 * @returns {string} the formated date time
 */
export function getEntryDateTimeFormat(dateTime, showDayOfTheWeek) {
    if (dateTime) {
        const timeFormat = store.getState().settings.data.time24hour ? "HH:mm" : "h:mm a";
        const format = showDayOfTheWeek ? `dddd, ${timeFormat}` : timeFormat;
        return moment(dateTime).locale(store.getState().language.selected.lang).format(format);
    }
}

/**
 * Get the day period based on the time
 *
 * @param {Date} dateTime date time of the entries to format
 * @returns {string} day period, morning, afternoon, evening
 */
export function getDayPeriodFromTime(dateTime) {
    const hour = moment(dateTime).format('HH');
    if (!hour) {
        return moment(dateTime).locale(store.getState().language.selected.lang).format('dddd');
    }
    const splitAfternoon = 12;
    const splitEvening = 17;
    if (hour >= splitAfternoon && hour <= splitEvening) {
        return $$('afternoon');
    } else if (hour >= splitEvening) {
        return $$('evening');
    }
    return $$('morning');
}

/**
 * Rounds the given number to with precision of two decimals
 *
 * @param {number} num the number to round
 * @returns {number} the rounded number
 */
function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
}

/**
 * Gets the y axes for the cholesterol charts, depending on the settings chosen
 *
 * @returns {object} the boundaries for the axes
 */
export function getFormatedCholesterolYAxes() {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.cholesterolUnits;
        if (selectedUnit && selectedUnit === 'MMOL_L') {
            return {min: 0, max: 18, step: 1};
        } else if (selectedUnit === 'MG_DL') {
            return {min: 30, max: 240, step: 30};
        }
    }
}

/**
 * Gets the y axes for the weight charts, depending on the settings chosen
 *
 * @returns {object} the boundaries for the axes
 */
export function getFormatedWeightYAxes() {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.unitsOfMeasurement;
        if (selectedUnit && selectedUnit === 'METRIC') {
            return {min: 0, max: 180, step: 30};
        } else if (selectedUnit === 'US') {
            return {min: 60, max: 360, step: 60};
        }
    }
}

/**
 * Gets the y axes for the hydration charts, depending on the settings chosen
 *
 * @returns {object} the boundaries for the axes
 */
export function getFormatedHydrationYAxes() {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.unitsOfMeasurement;
        if (selectedUnit && selectedUnit === 'METRIC') {
            return {min: 0, max: 2100, step: 300};
        } else if (selectedUnit === 'US') {
            return {min: 0, max: 70, step: 10};
        }
    }
}

/**
 * Gets the y axes for the temperature charts, depending on the settings chosen
 *
 * @returns {object} the boundaries for the axes
 */
export function getFormatedTemperatureYAxes() {
    if (store.getState().settings.data) {
        const selectedUnit = store.getState().settings.data.unitsOfMeasurement;
        if (selectedUnit && selectedUnit === 'METRIC') {
            return {min: 35, max: 42, step: 0.5};
        } else if (selectedUnit === 'US') {
            return {min: 95, max: 110, step: 5};
        }
    }
}

/**
 * Gets the y axes for the respiratory chart
 *
 * @returns {object} the boundaries for the axes
 */
export function getFormatedRespiratoryRateYAxes() {
    return {min: 0, max: 100, step: 5};
}

/**
 * Gets the y axes for the saturation chart
 *
 * @returns {object} the boundaries for the axes
 */
export function getFormatedSaturationYAxes() {
    return {min: 70, max: 110, step: 5};
}

/**
 * Return blood group e.g. (A+, A-, AB+...) as string from Medical Profile blood_type value
 * @param bloodType as it comes from the medical profile
 */
export function getBloodGroupByType(bloodType) {
    switch (bloodType) {
        // NONE (display None)
        //     A_RhD_P (display A+)
        //     A_RhD_N (display A-)
        //     B_RhD_P (display B+)
        //     B_RhD_N (display B-)
        //     O_RhD_P (display 0+)
        //     O_RhD_N (display 0-)
        //     AB_RhD_P (display AB+)
        //     AB_RhD_N (display AB-)
        case "A_RhD_P":
            return "A+";
        case "A_RhD_N":
            return "A-";
        case "B_RhD_P":
            return "B+";
        case "B_RhD_N":
            return "B-";
        case "O_RhD_P":
            return "0+";
        case "O_RhD_N":
            return "0-";
        case "AB_RhD_P":
            return "AB+";
        case "AB_RhD_N":
            return "AB-";
        default :
            return DASH;
    }
}

/**
 * Return severity for display from the allergy severity int field
 * @param severity as it comes from the allergy
 */
export function getSeverityForDisplay(severity) {
    switch (severity) {
        // 0 (display Mild)
        // 1 (display Mild to moderate)
        // 2 (display Moderate)
        // 3 (display Moderate to severe)
        // 4 (display Severe)
        // 5 (display Life threatening)
        case 0:
            return $$("mild");
        case 1:
            return $$("mild_to_moderate");
        case 2:
            return $$("moderate");
        case 3:
            return $$("moderate_to_severe");
        case 4:
            return $$("severe");
        case 5:
            return $$("life_threatening");
        default :
            "";
    }
}

/**
 * Return localized allergy name for display
 * @param allergy
 * @return {string} Allergy name and type for display use
 */
export function getAllergyNameForDisplay(allergy) {
    let type = getAllergyTypeAsLocalizedString(allergy.type);
    if (type) {
        return `${type}: ${allergy.name}`;
    }
    //return `${$$("allergen")}: ${allergy.name}`;
    return allergy.name;
}

/**
 * Return localized human readable allergy type
 * @param type
 * @return {string|null} localized type
 */
function getAllergyTypeAsLocalizedString(type) {
    switch (type) {
        // DRUG_ALLERGY
        // DUST_ALLERGY
        // FOOD_ALLERGY
        // INSECT_ALLERGY
        // LATEX_ALLERGY
        // MOLD_ALLERGY
        // PET_ALLERGY
        // POLLEN_ALLERGY
        // OTHER_ALLERGY
        case "DRUG_ALLERGY":
            return $$("drug_allergy");
        case "DUST_ALLERGY":
            return $$("dust_allergy");
        case "FOOD_ALLERGY":
            return $$("food_allergy");
        case "INSECT_ALLERGY":
            return $$("insect_allergy");
        case "LATEX_ALLERGY":
            return $$("latex_allergy");
        case "MOLD_ALLERGY":
            return $$("mold_allergy");
        case "PET_ALLERGY":
            return $$("pet_allergy");
        case "POLLEN_ALLERGY":
            return $$("pollen_allergy");
        case "OTHER_ALLERGY":
            return $$("other_allergy");

        default : return null;
    }
}


/**
 * Return display value for given unit
 * @param unit medication unit
 * @param dose how many units
 */
export function getUnitValuefromKey(unit, dose) {
    let prefix = `medication.unit.label.${unit.toLowerCase()}`;

    if (dose > 1) {
        let shortPlural = $$(`${prefix}.display_short_plural`);
        if (shortPlural) { //short are with priority
            return shortPlural;
        }
        let longPlural = $$(`${prefix}.display_long_plural`);
        if (longPlural) {
            return longPlural;
        }
    }

    let short = $$(`${prefix}.display_short`);
    if (short) {
        return short;
    }
    let long = $$(`${prefix}.display_long`);
    if (long) {
        return long;
    }

    return $$("None");
}

export function displayPluralMedUnit(unit) {
    switch (unit.toUpperCase()) {
        case "AMPULE":
        case "APPLICATION":
        case "CAPSULE":
        case "DROP":
        case "GRAM":
        case "INJECTION":
        case "MG":
        case "ML":
        case "PACKET":
        case "PATCH":
        case "PIECE":
        case "TABLET":
        case "PUFF":
        case "SPRAY":
        case "SUPPOSITORY":
        case "TBSP":
        case "TSP":
        case "UNIT":
            return $$("medication.unit.label." + unit.toLowerCase() + ".display_short_plural").length ?
                $$("medication.unit.label." + unit.toLowerCase() + ".display_short_plural") : $$("medication.unit.label.unit.display_long_plural");
        default :
            return "-"
    }
}

/**
 * Convert milliseconds to age
 *
 * @param {number} value the value in milliseconds to convert
 * @returns {number} the converted value
 */
export function millisecondsToAge(value) {
    var ageDifMs = moment().valueOf() - value;
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function decimalHexTwosComplement(decimal){
    let hexadecimal;
    const size = 8;
    if (decimal === 0) {
        return "#ffffff"
    }
    if (decimal >= 0) {
        hexadecimal = decimal.toString();
        while ((hexadecimal.length % size) !== 0) {
            hexadecimal = "" + 0 + hexadecimal;
        }
        return hexadecimal;
    } else {
        hexadecimal = Math.abs(decimal).toString(16);
        while ((hexadecimal.length % size) !== 0) {
            hexadecimal = "" + 0 + hexadecimal;
        }
        let output = '';
        for (let i = 0; i < hexadecimal.length; i++) {
            output += (0x0F - parseInt(hexadecimal[i], 16)).toString(16);
        }
        output = (0x01 + parseInt(output, 16)).toString(16);
        output = "#" + output.substring(2);
        return output;
    }
}
export function hexTwosComplementDecimal(hex) {

    if (hex.toLowerCase() === "#ffffff") {
        return 0
    }

    hex = "FF" + hex.substring(1);
    if (hex.length % 2 !== 0) {
        hex = "0" + hex;
    }
    let num = parseInt(hex, 16);
    const maxVal = Math.pow(2, hex.length / 2 * 8);
    if (num > maxVal / 2 - 1) {
        num = num - maxVal
    }
    return num;
}

export function formatSex(value) {
    return value ? $$(value.toLowerCase()) : value;
}