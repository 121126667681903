import {
    CYCLE,
    DAILY, DURATION_FOR_X_DAYS, DURATION_UNTIL_DATE,
    EVERY_X_DAYS,
    SPECIFIC_DAYS_OF_WEEK,
    TAKE_EVERY_X_HOURS,
    TAKE_X_TIMES,
    WHEN_NEEDED
} from "../constants/medications_constants";
import {$$, _$$} from "../helpers/localization";
import {CONVERTER, getUnitValuefromKey} from "./converter";
import moment from "moment";
import {ONGOING} from "../constants/call_status";
import {replaceAllNewLines} from "./reportUtils";

/**
 * Determines how often to take the medication
 * @param {object} prescription
 */
export function getPrescriptionDetailsString (prescription) {
    let instr = getPrescriptionInstructions(prescription)
    let s = `${getPrescriptionFrequency(prescription)}, ${getPrescriptionScheduleString(prescription).toLowerCase()}`;
    if (instr) {
        s = s + ", " + instr.toLowerCase();
    }
    s = s + `, ${getPrescriptionDurationString(prescription).toLowerCase()}`
    if (prescription.notes) {
        s += "; " + replaceAllNewLines(prescription.notes.trim(), "; ")
    }
    return s;
}

function getPrescriptionFrequency(prescription) {
    if (prescription.scheduleType === WHEN_NEEDED) return `${prescription.dose} ${getUnitValuefromKey(prescription.unit, prescription.dose)}`
    switch (prescription.howToTake) {
        case TAKE_X_TIMES:
            return _$$(prescription.scheduleTimesPerDay > 1 ? "times_per_day_plural" : "times_per_day", prescription.dose, getUnitValuefromKey(prescription.unit, prescription.dose), prescription.scheduleTimesPerDay);
        case TAKE_EVERY_X_HOURS:
            return _$$(prescription.scheduleHours > 1 ? "every_x_hours_plural" : "every_x_hours", prescription.dose, getUnitValuefromKey(prescription.unit, prescription.dose), prescription.scheduleHours);
        default:
            return '';
    }
}

function getPrescriptionScheduleString(medication) {
    switch (medication.scheduleType) {
        case WHEN_NEEDED:
            return $$('when_needed');
        case DAILY:
            return $$('daily');
        case EVERY_X_DAYS:
            return _$$(medication.scheduleDaysInactive > 0 ? 'every_days_plural' : 'every_days', medication.scheduleDaysInactive + 1);
        case CYCLE:
            let key;
            if (medication.scheduleDaysActive > 1 &&  medication.scheduleDaysInactive > 1) {
                key = 'take_rest_xy_plural'
            } else if(medication.scheduleDaysActive == 1 &&  medication.scheduleDaysInactive > 1) {
                key = 'take_rest_y_plural'
            } else if(medication.scheduleDaysActive > 1 &&  medication.scheduleDaysInactive == 1) {
                key = 'take_rest_x_plural'
            } else {
                key = 'take_rest'
            }


            return _$$(key, medication.scheduleDaysActive, medication.scheduleDaysInactive);
        default:
            return '';
    }
}

export function getPrescriptionInstructions(med) {
    if (med.instructions) {
        return med.instructions.split(",").map(instr => $$(instr)).join(", ");
    }
    return null;
}

/**
 * Get the formatted medication duration string
 *
 * @returns {string} medication plan duration
 */
function getPrescriptionDurationString(medication) {
    switch (medication.durationType) {
        case ONGOING:
            return $$('ongoing');
        case DURATION_FOR_X_DAYS:
            return _$$(medication.durationInDays > 1 ? 'for_days_until_plural' : 'for_days_until', medication.durationInDays);
        default:
            return '';
    }
}

/**
 * Determines the medication database to search
 * @param {string} userCountry the country of the selected user
 * @param {string} currentLang the current selected language of the app
 *
 * @returns {string} the medication source to use
 *  */
export function getMedicationSource (userCountry, currentLang){
    let lang =  userCountry !== null ? userCountry : currentLang

    if (lang === "bg") {
        return "ncpr"
    } else {
        return "nih"
    }
}

/**
 * This checks a Prescription object to determine whether it is for a non-medicinal product and has to be handled separately since it has no name or other properties
 * @return {boolean|boolean}
 * @param prescription
 */
export function isNonMedicinalProduct(prescription) {
    return !prescription.name && prescription.nhis_prescription_lrn && !prescription.nhis_prescription_dosage_instruction_sequence
}

export function isNhisMedication(prescription) {
    return !isNonMedicinalProduct(prescription) && prescription.nhis_prescription_lrn
}