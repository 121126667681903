import React, {Component} from 'react'
import ListGroup from "react-bootstrap/ListGroup";
import {ListGroupItem} from "react-bootstrap";
import no_data from "../../resources/images/no_data.png";
import {$$} from "../../helpers/localization";
import {infoUtils} from "../../utils/infoUtils";
import PropTypes from "prop-types";

export default class TemplateSelector extends Component {
    constructor(props) {
        super(props)
        this.state = {templates:[]}
    }

    componentDidMount() {
        //fetch templates and store into the state
        this.setState({templates:[...this.props.templates], noResults: this.props.templates.length < 1})
    }

    render() {
        return <div>
            {
                this.state.noResults &&
                <div className="w-50 margin-auto">
                    {infoUtils.noData({
                    imgClass: 'no-lab-results-img w-75',
                    primaryLabelClass: 'no-lab-results-primary-label',
                    secondaryLabelClass: 'no-lab-results-secondary-label',
                    src: no_data,
                    primaryLabel: '',
                    secondaryLabel: $$('no_records_found')})}
                </div>
            }

            <ListGroup>
                {
                    this.state.templates.map((t, i) => {
                        // eslint-disable-next-line no-unused-vars
                        return <ListGroupItem key={i} action={true} onClick={e => {
                            this.props.onSelect(t);
                        }}>{t.name}</ListGroupItem>
                    })
                }
            </ListGroup>
        </div>
    }
}

TemplateSelector.propTypes = {
    onSelect:  PropTypes.func,
    templates:  PropTypes.any,
}