import React, {Component} from 'react'
import ReactDOM from 'react-dom';
import {$$} from '../../helpers/localization';
import NotesTabListItem from './NotesTabListItem'
import CenteredModal from '../shared/CenteredModal';
import NoteVisibilityForm from './NoteVisibilityForm';
import {getResolvedOptions, NOTE_VISIBILITY} from '../../constants/select_options'
import Select from '../shared/Select'
import NoteSpeechToText from './NoteSpeechToText';
import {PRICES} from "../../actions/actions";
import FormWithLoading from "../shared/FormWithLoading";
import PropTypes from "prop-types";
import no_data from "../../resources/images/no_data.png";
import {infoUtils} from "../../utils/infoUtils";


export class NotesTab extends Component {
    state = {
        events: [],
        selectedNoteId: '',
        selectedNote: null,
        createUpdateModalOpened: false,
        deleteModalOpened: false,
        visibilityModalOpened: false,
        isCreateMode: false,
        note: '',
        visibility: "PATIENT_ONLY",
        formclass: '',
        errors: {},
    }

    constructor(props) {
        super(props);
        this.index = 0;
    }

    /**
     * Creates the rows of the table
     *
     * @param {object} note - the note object
     * @param {int} idx - index number
     * @returns {Array} the table rows created
     */
    getRow = (note, idx) => {
        if (this.props.selectedAppointment) {
            return <NotesTabListItem key={idx}
                                     selectedAppointment={this.props.selectedAppointment}
                                     userAppointments={this.props.userAppointments}
                                     userId={this.props.userId}
                                     i18n={this.props.i18n}
                                     note={note}
                                     selectedNoteId={this.state.selectedNoteId}
                                     selectedNote={this.state.selectedNote}
                                     onNoteChange={this.onNoteChange}
                                     updateNote={this.updateNote}
                                     formDisabled={this.props.formDisabled}
                                     openDeleteModal={this.openDeleteModal}
                                     openVisibilityModal={this.openVisibilityModal}/>;
        }
    }

    noNotes = () => {
        let emptyStatsResultObj = {
            imgClass: 'no-lab-results-img',
            primaryLabelClass: 'no-lab-results-primary-label',
            secondaryLabelClass: 'no-lab-results-secondary-label',
            src: no_data,
            primaryLabel: $$('vitals_logbook_no_notes'),
            secondaryLabel: ''
        }
        return infoUtils.noData(emptyStatsResultObj);
    }

    /**
     * Creates the full body of the table
     *
     * @returns {Array} the table rows
     */
    getBody = () => {
        let allRows = [];
        let notes = this.props.notes;
        if ((!(notes && notes.length > 0)) || notes.length === 0) {
            return this.noNotes()
        }
        if (this.props.selectedAppointment.text_only) {
            for (let i = notes.length - 1; i >= 0; i--) {
                allRows.push(this.getRow(notes[i], i));
            }
        } else {
            for (let i = 0; i < notes.length; ++i) {
                allRows.push(this.getRow(notes[i], i));
            }
        }
        return [].concat.apply([], allRows);
    }

    /**
     * An event handler triggered when a new note is choosen
     * from the list of notes
     *
     * @param {object} note - the note object
     */
    onNoteChange = (note) => {
        let selectedNoteId = note.id;
        this.setState({
            selectedNoteId: selectedNoteId,
            selectedNote: note,
            isCreateMode: false
        });
    }

    onNoteSpeechChange = (text) => {
        this.setState({note: text});
    };

    /**
     * Open delete modal.
     */
    openDeleteModal = () => {
        this.setState({
            deleteModalOpened: true
        });
    }

    /**
     * Hide delete modal.
     */
    onHideDeleteModal = () => {
        this.setState({
            deleteModalOpened: false
        });
    }

    /**
     * Open visibility modal.
     */
    openVisibilityModal = () => {
        this.setState({
            visibilityModalOpened: true
        });
    }

    /**
     * Hide visibility modal.
     */
    onHideVisibilityModal = () => {
        this.setState({
            visibilityModalOpened: false
        });
    }

    /**
     * Update selected note.
     */
    updateNote = (note) => {
        this.props.updateNote(this.props.selectedUserId, note, this.props.selectedAppointment.id);
    }

    /**
     * Delete selected note.
     */
    deleteNote = () => {
        this.setState({
            deleteModalOpened: false
        });
        this.props.deleteNote(this.props.selectedUserId, this.state.selectedNote.id, this.props.selectedAppointment.id);
    }

    /**
     * Close create new note
     */
    closeCreateNewNote = () => {
        this.setState({
            isCreateMode: false,
            confirmNotePatientOnly:undefined
        });
    }

    /**
     * Open create new note
     */
    openCreateNewNote = () => {
        this.setState({
            isCreateMode: true,
            selectedNoteId: '',
            visibility: this.props.selectedAppointment.id ? "PATIENT_ONLY" : "PRIVATE",
            selectedNote: null,
            note: '',
            formclass: '',
            errors: {}
        }, ()=>{
            if (this.props.selectedAppointment.text_only) {
                let element = document.getElementById("logbookForm");
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            } else {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }
        });
    }

    /**
     * Form submit handler, validate data and set error in state if any. Call create logbook entry action.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        evt.preventDefault();
    }

    /**
     * Validate form data.
     *
     * @returns {object} errors - Form errors after validate
     */
    validate = () => {
        const errors = {};
        this.setState({errors: ''});
        if (!this.state.note) {
            errors.note = 'note_required_message';
        }
        this.setState({errors});
        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }
        return Object.keys(errors).length === 0;

    }

    /**
     * Create new note for selected user
     */
    saveNewNote = () => {
        if (this.validate()) {
            let note = {};
            note.value = this.state.note;
            note.owner_id = this.props.userId;
            note.subject_id = this.props.selectedUserId;
            note.visibility = this.state.visibility;

            if (note.visibility === 'PATIENT_ONLY') {
                this.setState({confirmNotePatientOnly: note})
            } else {
                this.props.createNote(this.props.selectedUserId, note, this.props.selectedAppointment.id, null);
                this.closeCreateNewNote();
            }
        }
    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        if (evt.target) {
            fields[evt.target.name] = evt.target.value;
        } else {
            fields[evt.name] = evt.value;
        }
        this.setState(fields);
    }

    getVisibilityOptions = () => {
        return getResolvedOptions(NOTE_VISIBILITY.TYPE).filter(option => {
            return (!this.props.selectedAppointment?.id && option.value !== 'PATIENT_ONLY' && option.value !== 'ALL') || this.props.selectedAppointment.id
        });
    }

    isMedHistoryNote = () => {
        return !this.props.selectedAppointment.id;
    }

    createEditForm = () => {
        return <div>
            <div id='appointment'
                 className="appointment-containter appointment-active"
                 onClick={this.onNoteClick}>
                <div className="row" style={{
                    "width": "100%",
                    "marginLeft": "20px",
                    "marginRight": "20px"
                }}>
                    <div style={{"width": "93%"}}>
                        <div style={{"textAlign": "right", "marginTop": "5px"}}>
                            <div className="note-title"
                                 style={{
                                     "display": "inline-block",
                                     "float": "left"
                                 }}>
                                {this.isMedHistoryNote() ? $$("new_note_label") : $$("new_message_label")}
                            </div>
                            <br/>
                        </div>
                        <div style={{"marginTop": "-25px", "marginBottom": "20px"}}>
                            <FormWithLoading
                                formDisabled={this.props.formDisabled}
                                currentForm={PRICES}
                                marginTop="10%"
                                marginLeft="calc(50% - 70px)"
                                id="logbookForm"
                                onSubmit={this.onSubmit}
                                className={this.state.formclass}
                                noValidate={true}>
                                <div className="form-group"
                                     style={{
                                         "float": "right",
                                         "marginBottom": "20px"
                                     }}>
                                    <div style={{"width": "15rem", "margin": "0"}}>
                                        <Select
                                            name="visibility"
                                            options={this.getVisibilityOptions()}
                                            value={this.state.visibility}
                                            onChange={this.onInputChange}
                                            placeHolder={$$('visibility_label')}/>
                                        <div className="invalid-feedback">
                                            {$$('visibility_required_message')}
                                        </div>
                                    </div>
                                </div>
                                <br/><br/><br/><br/>
                                <div className="form-group">
                                    <div>
                                        <NoteSpeechToText
                                            onInputChange={this.onInputChange}
                                            note={this.state.note}
                                            onNoteSpeechChange={this.onNoteSpeechChange}
                                            i18n={this.props.i18n}
                                        />
                                    </div>
                                    <div className="invalid-feedback">
                                        {$$('note_required_message')}
                                    </div>
                                </div>
                                <div className="form-group row" style={{
                                    "float": "right",
                                    "marginRight": "10px",
                                    "marginTop": "-5px",
                                    "zIndex": "100"
                                }}>
                                    <div>
                                        <button type="button"
                                                className="btn btn-secondary btn-block"
                                                onClick={this.closeCreateNewNote}>
                                            {$$("cancel_btn")}
                                        </button>
                                    </div>
                                    <div style={{"marginLeft": "7px"}}>
                                        <button type="button"
                                                className="btn btn-success btn-block"
                                                onClick={this.saveNewNote}>
                                            {$$("save_btn_label")}
                                        </button>
                                    </div>
                                </div>
                            </FormWithLoading>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
        </div>
    }

    render() {
        let domNode = document.getElementById('new-note-btn');
        return (
            <div className={"minus-twenty-margin-top"}>
                <div>
                    {
                        <div className='row' style={{'marginLeft': '0px', 'marginRight': '0px'}}>
                            <div className='col-xs-12 col-md-12'>
                                <div className='row'>
                                    <div className='w-100 appointments-tab pr-2'>
                                        {this.state.isCreateMode && !this.props.selectedAppointment.text_only &&
                                            this.createEditForm()}
                                        {this.getBody()}
                                        {this.state.isCreateMode && this.props.selectedAppointment.text_only &&
                                            this.createEditForm()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {!this.state.isCreateMode && ReactDOM.createPortal(
                    <div className="add-notes-button" onClick={this.openCreateNewNote}>
                        <i className="fa fa-plus" aria-hidden="true"
                           style={{"display": "table-cell", "verticalAlign": "middle"}}/>
                    </div>,
                    domNode
                )}

                <NoteVisibilityForm
                    show={this.state.visibilityModalOpened}
                    onHide={this.onHideVisibilityModal}
                    updateNote={this.updateNote}
                    selectedNote={this.state.selectedNote}
                    hidePatientOptions={!this.props.selectedAppointment.id && !this.state.selectedNote?.appointmentId}
                />
                <CenteredModal title={$$('delete_note_modal_header')}
                               show={this.state.deleteModalOpened}
                               onHide={this.onHideDeleteModal}
                               onConfirm={this.deleteNote}
                >
                    {$$('delete_note_modal_text')}
                </CenteredModal>
                <CenteredModal title={$$('confirm_note_visible_to_patient_modal_header')}
                               show={!!this.state.confirmNotePatientOnly}
                               onHide={()=>{this.setState({confirmNotePatientOnly:undefined})}}
                               onConfirm={()=>{
                                   this.props.createNote(this.props.selectedUserId, this.state.confirmNotePatientOnly, this.props.selectedAppointment.id, null);
                                   this.closeCreateNewNote();
                               }}
                >
                    <p className={"text-danger"}>{$$('confirm_note_visible_to_patient_modal_body')}</p>
                    <p >{$$('confirm_note_visible_to_patient_modal_body_2')}</p>
                </CenteredModal>
            </div>
        )
    }
}

NotesTab.propTypes = {
    clearAppointmentThatChanged: PropTypes.func,
    userAppointments: PropTypes.any,
    formDisabled: PropTypes.any,
    createNote: PropTypes.func,
    deleteNote: PropTypes.func,
    i18n: PropTypes.object,
    notes: PropTypes.any,
    selectedAppointment: PropTypes.object,
    selectedUser: PropTypes.object,
    selectedUserId: PropTypes.string,
    updateNote: PropTypes.func,
    userId: PropTypes.string
};
