import React, {Component} from "react";
import {$$} from "../../helpers/localization";
import DatePicker from "react-datepicker/es";
import SingleLineCheckbox from "./SingleLineCheckbox";
import endOfDay from "date-fns/endOfDay";
import {getResolvedOptions, LOCATION_TYPE} from '../../constants/select_options'
import PropTypes from "prop-types";
import {formatUtils} from "../../utils/formatUtils";
import {appointmentUtils} from "../../utils/appointmentUtils";
import moment from "moment";
import {registerLocale} from "react-datepicker";
import bg from 'date-fns/locale/bg';

registerLocale('bg', bg)

export class EditPopup extends Component {

    locationOptions = getResolvedOptions(LOCATION_TYPE.LOCATION_OPTIONS).map((o, i) => (
        <option value={o.value} key={i}>{o.text}</option>
    ));

    constructor(props) {
        super(props);
        this.state = {
            daysCheckboxes: this.getDaysOfWeek().reduce(
                (options, option) => ({
                    ...options,
                    [option]: false
                }),
                {}
            ),
            allDayCheckbox: this.getAllDay().reduce(
                (options, option) => ({
                    ...options,
                    [option]: false
                }),
                {}
            ),
            startTime: "",
            endTime: "",
            selectedCalendar: this.calculateSelectedCalendarInitially(this.props.timeTable.not_available),
            location_type: this.props.timeTable.location_type || "",
            endDate: "",
            startDate: this.props.selectedDate,
            timeOn: true,
            private_range: false
        };

        this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
        this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
        this.handleRepeatChange = this.handleRepeatChange.bind(this);
        this.handleCalendarChange = this.handleCalendarChange.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);

    }

    calculateSelectedCalendarInitially = (isOff) => {
        if (this.props.selectedOrganisationName) {
            return this.props.selectedOrganisationName;
        }
        if (isOff && !this.props.timeTable.organization_id) {
            return "";
        }
        return this.props.organizations[0].name;
    }

    calculateSelectedCalendar = (isOff) => {
        if (this.props.selectedOrganisationName) {
            return this.props.selectedOrganisationName;
        }

        if (isOff && !this.props.timeTable.organization_id) {
            return "";
        }
        return this.state.selectedCalendar ? this.state.selectedCalendar : this.props.organizations[0].name;
    }

    closeEditPopup = () => {
        this.props.closeEditPopup();
    }

    deleteDateRange = () => {
        let id = this.props.timeTable.id;
        this.props.deleteEvent(id);
    }

    componentDidMount() {
        let timeTable = this.props.timeTable;
        this.setState({
            timeOn: !timeTable.not_available,
            startDate: new Date(timeTable.start),
            endDate: timeTable.end !== null ? new Date(timeTable.end) : "",
            startTime: timeTable.start_time.substring(0, timeTable.start_time.length - 3),
            endTime: timeTable.end_time !== null ? timeTable.end_time.substring(0, timeTable.end_time.length - 3) : "",
            daysCheckboxes: {
                ["mon"]: timeTable.days_of_the_week.includes(1),
                ["tue"]: timeTable.days_of_the_week.includes(2),
                ["wed"]: timeTable.days_of_the_week.includes(3),
                ["thu"]: timeTable.days_of_the_week.includes(4),
                ["fri"]: timeTable.days_of_the_week.includes(5),
                ["sat"]: timeTable.days_of_the_week.includes(6),
                ["sun"]: timeTable.days_of_the_week.includes(7),
            },
            priceId: timeTable.encounter_price_id,
            private_range: timeTable.private_range
        })
        if (timeTable.start_time === "00:00:00" && timeTable.end_time === "23:59:00") {
            this.setState({
                allDayCheckbox: {
                    ["all_day"]: true
                },
                startTime: "",
                endTime: ""
            });
        }
    }

    getDaysOfWeek = () => {
        const days = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
        return days;
    }

    getAllDay = () => {
        const allDay = ["all_day"];
        return allDay;
    }

    getTimesInDay = () => {
        const times = ["00:00", "00:15", "00:30", "00:45", "01:00", "01:15", "01:30", "01:45", "02:00", "02:15", "02:30", "02:45",
            "03:00", "03:15", "03:30", "03:45", "04:00", "04:15", "04:30", "04:45", "05:00", "05:15", "05:30", "05:45",
            "06:00", "06:15", "06:30", "06:45", "07:00", "07:15", "07:30", "07:45", "08:00", "08:15", "08:30", "08:45",
            "09:00", "09:15", "09:30", "09:45", "10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45",
            "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45", "14:00", "14:15", "14:30", "14:45",
            "15:00", "15:15", "15:30", "15:45", "16:00", "16:15", "16:30", "16:45", "17:00", "17:15", "17:30", "17:45",
            "18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:45", "20:00", "20:15", "20:30", "20:45",
            "21:00", "21:15", "21:30", "21:45", "22:00", "22:15", "22:30", "22:45", "23:00", "23:15", "23:30", "23:45"];
        return times;
    }

    getCalendarOptions = () => {
        let values = this.state.timeOn ? [] : [$$('all')];
        for (let i in this.props.organizations) {
            values.push(this.props.organizations[i].name);
        }
        return values;
    }

    handleStartTimeChange(e) {
        this.setState({startTime: e.target.value});

        if (this.state.endTime !== "") {
            if (!this.isBeforeStart(this.state.endTime)) {
                this.setState({endTime: ""});
            }
        }
    }

    handleEndTimeChange(e) {
        this.setState({endTime: e.target.value});
    }

    handleRepeatChange(e) {
        this.setState({repeatFor: e.target.value});

    }

    handleCalendarChange(e) {
        this.setState({selectedCalendar: e.target.value});
    }

    handleLocationChange(e) {
        this.setState({location_type: e.target.value, priceId: 0});
    }

    handleDaysCheckboxChange = changeEvent => {
        const {name} = changeEvent.target;

        this.setState(prevState => ({
            daysCheckboxes: {
                ...prevState.daysCheckboxes,
                [name]: !prevState.daysCheckboxes[name]
            }
        }));
    };

    handleAllDayCheckboxChange = changeEvent => {
        const {name} = changeEvent.target;
        this.setState(prevState => ({
            allDayCheckbox: {
                ...prevState.allDayCheckbox,
                [name]: !prevState.allDayCheckbox[name]
            }
        }));

        this.setState({
            startTime: "",
            endTime: ""
        });
    };

    isBeforeStart = (end) => {
        let endPieces = end.split(":");
        let endTime = new Date();
        endTime.setHours(parseInt(endPieces[0]));
        endTime.setMinutes(parseInt(endPieces[1]));

        let start = this.state.startTime;
        let startPieces = start.split(":");
        let startTime = new Date();
        startTime.setHours(parseInt(startPieces[0]));
        startTime.setMinutes(parseInt(startPieces[1]));

        return endTime <= startTime && this.state.startTime !== "";
    }

    onChangeValue(event) {
        if (event.target.value === "On") {
            this.setState({timeOn: true, selectedCalendar: this.calculateSelectedCalendar(false)});
        } else {
            this.setState({timeOn: false, selectedCalendar: this.calculateSelectedCalendar(true)});
        }
    }

    createOption = option => (
        <option
            value={option}
            key={option}
        >
            {option}
        </option>
    );

    createEndOption = option => (
        <option disabled={this.isBeforeStart(option)}
                value={option}
                key={option}

        >
            {option}
        </option>
    );

    createDaysCheckbox = option => (
        <SingleLineCheckbox
            label={option}
            isSelected={this.state.daysCheckboxes[option]}
            onCheckboxChange={this.handleDaysCheckboxChange}
            key={option}
        />
    );

    createAllDayCheckbox = option => (
        <SingleLineCheckbox
            label={option}
            isSelected={this.state.allDayCheckbox[option] || false}
            onCheckboxChange={this.handleAllDayCheckboxChange}
            key={option}
        />
    );

    createDaysCheckboxes = () => this.getDaysOfWeek().map(this.createDaysCheckbox);

    createAllDayCheckboxes = () => this.getAllDay().map(this.createAllDayCheckbox);

    createTimeOptions = () => this.getTimesInDay().map(this.createOption);

    createEndTimeOptions = () => this.getTimesInDay().map(this.createEndOption);

    createCalendarOptions = () => this.getCalendarOptions().map(this.createOption);

    handleSubmit = () => {
        let form = null
        let days = [(this.state.daysCheckboxes["mon"]) ? 1 : null,
            (this.state.daysCheckboxes["tue"]) ? 2 : null,
            (this.state.daysCheckboxes["wed"]) ? 3 : null,
            (this.state.daysCheckboxes["thu"]) ? 4 : null,
            (this.state.daysCheckboxes["fri"]) ? 5 : null,
            (this.state.daysCheckboxes["sat"]) ? 6 : null,
            (this.state.daysCheckboxes["sun"]) ? 7 : null
        ].filter(function (el) {
            return el != null;
        });

        if (!this.state.timeOn) {
            let organisationId = "";

            for (let i in this.props.organizations) {
                if (this.props.organizations[i].name === this.state.selectedCalendar) {
                    organisationId = this.props.organizations[i].id
                }
            }
            form = {
                id: this.props.timeTable.id,
                days_of_the_week: days,
                end: endOfDay(this.state.endDate).getTime(),
                end_time: this.state.allDayCheckbox["all_day"] ? "23:59" : this.state.endTime,
                not_available: true,
                organization_id: organisationId,
                provider_id: this.props.timeTable.provider_id,
                start: this.state.startDate.getTime(),
                start_time: this.state.allDayCheckbox["all_day"] ? "00:00" : this.state.startTime
            }
        } else {
            if (this.state.startTime !== "" || this.state.allDayCheckbox["all_day"]) {
                let organisationId = "";

                for (let i in this.props.organizations) {
                    if (this.props.organizations[i].name === this.state.selectedCalendar) {
                        organisationId = this.props.organizations[i].id
                    }
                }
                form = {
                    id: this.props.timeTable.id,
                    days_of_the_week: days,
                    end: this.state.endDate ? endOfDay(this.state.endDate).getTime() : "",
                    end_time: this.state.allDayCheckbox["all_day"] ? "23:59" : this.state.endTime,
                    not_available: !this.state.timeOn,
                    provider_id: this.props.providerId,
                    organization_id: organisationId,
                    location_type: !this.not_available ? this.state.location_type : null,
                    start: this.state.startDate.getTime(),
                    start_time: this.state.allDayCheckbox["all_day"] ? "00:00" : this.state.startTime,
                    private_range: this.state.private_range
                }
                form.encounter_price_id = this.state.priceId == 0 || this.state.priceId == undefined ? null : this.state.priceId;
            }
        }
        this.props.updateTimetable(form);
        this.props.closeEditPopup();
    }

    setStartDate = (date) => {
        this.setState({startDate: date});
    }

    setEndDate = (date) => {
        this.setState({endDate: date ? endOfDay(date) : date});
    }

    isChecked = () => {
        return this.state.timeOn
    }

    isSubmitDisabled = () => {
        if (this.state.startDate && this.state.endDate && this.state.startDate > this.state.endDate) {
            return true;
        }
        if (this.state.endTime !== "") {
            return false;
        }
        return !this.state.allDayCheckbox["all_day"];
    }

    createPricesOptions = () => this.props.prices.filter(p => p.location_type === this.state.location_type && !appointmentUtils.isPriceForGroupEvent(p) && !appointmentUtils.isPriceForConsultation(p)).map((p) => {
        return <option key={p.id}
                       value={p.id}>{this.getEncounterType(p) + " - " + formatUtils.currencyFormat(p.price_cents, p.currency)}</option>
    });


    handleChangePrice = (e) => {
        this.setState({priceId: e.target.value})
    }

    getEncounterType = (menuItem) => {
        switch (menuItem.encounter_type) {
            case 'PRIMARY_EXAM':
                return $$('primary_exam');
            case 'FOLLOW_UP_EXAM':
                return $$('follow_up_exam');
            case'OTHER':
                return menuItem.name
        }
    }

    render() {
        moment.locale(this.props.i18n.selected.lang)
        return (
            <div>
                <span className="day-picker-popup-line">
                    {$$('edit_schedule')}
                    &nbsp;
                </span>

                <div onChange={this.onChangeValue} className="day-picker-popup-line-start">
                    <div className="custom-control custom-radio custom-control-inline">
                        <input className="custom-control-input" type="radio" name="TimeOnOff" id="inlineRadio1"
                               defaultChecked={!this.props.timeTable.not_available}
                               value="On"/>
                        <label className="custom-control-label" htmlFor="inlineRadio1">{$$("time_on")}</label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                        <input className="custom-control-input" type="radio" name="TimeOnOff" id="inlineRadio2"
                               defaultChecked={this.props.timeTable.not_available}
                               value="Off"/>
                        <label className="custom-control-label" htmlFor="inlineRadio2">{$$("time_off")}</label>
                    </div>
                </div>

                {this.state.timeOn && <span className="day-picker-popup-line">
                    <SingleLineCheckbox
                        label="private_range_input_label"
                        isSelected={this.state.private_range}
                        onCheckboxChange={() => {
                            this.setState({private_range: !this.state.private_range})
                        }}
                    />
                </span>}

                {!this.props.managementView && (
                    <span className="day-picker-popup-line">
                        <select id="calendars"
                                className="custom-select calendar-select w-100"
                                onChange={this.handleCalendarChange}
                                value={this.state.selectedCalendar}>
                            {this.createCalendarOptions()}
                        </select>
                    </span>)}

                {!this.state.not_available && this.state.timeOn &&
                    <span className="day-picker-popup-line">
                        <select id="location_type" value={this.state.location_type} name="location_type"
                                className="custom-select calendar-select w-100"
                                onChange={this.handleLocationChange}>
                            {this.locationOptions}
                        </select>
                    </span>}

                {!this.state.not_available && this.state.timeOn && <span className="day-picker-popup-line">
                     <label>{$$("applies_to")}</label>
                     <select id="prices" value={this.state.priceId || 0}
                             className="custom-select calendar-select w-100"
                             onChange={this.handleChangePrice}>
                         <option name={$$("all_appointments_label")} value={0}>{$$("all_appointments_label")}</option>
                         {this.createPricesOptions()}
                    </select>
                </span>}

                <span className="day-picker-popup-line">
                    <span>
                        {$$("from")}:&nbsp; <DatePicker
                        locale={moment.locale() === "bg" ? "bg" : null}
                        className="custom-select"
                        dateFormat={this.props.settings.data.dateFormat}
                        selected={this.state.startDate}
                        onChange={date => this.setStartDate(date)}/>
                    </span>
                    {this.state.timeOn && (<br/>)}
                    <span>
                        {$$("to")}:&nbsp;<DatePicker
                        locale={moment.locale() === "bg" ? "bg" : null}
                        selected={this.state.endDate}
                        className="custom-select"
                        dateFormat={this.props.settings.data.dateFormat}
                        onChange={date => this.setEndDate(date)}/>
                    </span>
                </span>

                <span>
                    <span className="day-picker-popup-line">
                        <select id="startTime"
                                onChange={this.handleStartTimeChange}
                                value={this.state.startTime}
                                className="custom-select time"
                                disabled={this.state.allDayCheckbox["all_day"]}>
                            <option>{$$("start")}</option>
                            {this.createTimeOptions()}
                        </select>
                        &nbsp;
                        {$$("to")}
                        &nbsp;
                        <select id="endTime"
                                onChange={this.handleEndTimeChange}
                                value={this.state.endTime}
                                className="custom-select time"
                                disabled={this.state.startTime === ""}>
                            <option>{$$("end")}</option>
                            {this.createEndTimeOptions()}
                        </select>
                        &nbsp;
                        {this.createAllDayCheckboxes()}
                    </span>

                    <span className="day-picker-popup-line">
                        {this.createDaysCheckboxes()}
                    </span>
                </span>

                <span className="day-picker-popup-line-end">
                    <input
                        type="button"
                        className="btn btn-secondary date-picker-button"
                        value={$$("cancel")}
                        onClick={this.closeEditPopup}
                    />
                    <input
                        type="button"
                        className="btn btn-secondary date-picker-button"
                        value={$$("delete")}
                        onClick={this.deleteDateRange}
                    />
                    <input
                        type="button"
                        className="btn btn-primary date-picker-button"
                        value={$$("update")}
                        onClick={this.handleSubmit}
                        disabled={this.isSubmitDisabled()}
                    />
                </span>
            </div>
        )
    }
}

EditPopup.propTypes = {
    closeEditPopup: PropTypes.func,
    deleteEvent: PropTypes.func,
    prices: PropTypes.any,
    settings: PropTypes.any,
    organizations: PropTypes.array,
    providerId: PropTypes.string,
    selectedDate: PropTypes.any,
    selectedOrganisationName: PropTypes.string,
    managementView: PropTypes.bool,
    timeTable: PropTypes.object,
    updateTimetable: PropTypes.func
}

export default EditPopup;