import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import CenteredModal from '../shared/CenteredModal';
import {documentsService} from '../../service/docments_service';
import {downloadUtils} from '../../utils/downloadUtils';
import {DocumentEntry} from './DocumentEntry'
import {deleteLoggedUserDocument} from '../../actions/documents_actions'
import {connect} from 'react-redux'
import PropTypes from "prop-types";


export class ProviderDocuments extends Component {
    state = {
        deleteModal: {
            show: false
        },
        downloadModal: {
            show: false
        },
        documentName: ''
    }

    constructor(props) {
        super(props);
    }

    /**
     * Set state to show the download modal
     */
    showDownloadModal = (documentName) => {
        this.setState({
            downloadModal: {
                show: true
            },
            documentName: documentName
        });
    }

    /**
     * Set state to hide the download modal
     */
    onHideDownloadModal = () => {
        this.setState({
            downloadModal: {
                show: false
            }
        });
    }

    /**
     * On confirm modal, download the file.
     */
    onConfirmDownloadModal = () => {
        const fileName = this.state.documentName;
        documentsService.downloadLoggedUserFile(fileName)
            .then((file) => {
                downloadUtils.download(file, fileName);
            });
        this.setState({
            downloadModal: {
                show: false
            }
        });
    }

    /**
     * Set state to show the delete modal
     */
    showDeleteModal = (documentName) => {
        this.setState({
            deleteModal: {
                show: true
            },
            documentName: documentName
        });
    }

    /**
     * Set state to hide the delete modal
     */
    onHideDeleteModal = () => {
        this.setState({
            deleteModal: {
                show: false
            }
        });
    }

    /**
     * On confirm modal, delete the file.
     */
    onConfirmDeleteModal = () => {
        let body = {
            filename: this.state.documentName
        };
        this.props.deleteLoggedUserDocument(body);
        this.setState({
            deleteModal: {
                show: false
            }
        });
    }

    /**
     * Get the documents to show.
     *
     * @returns {array} array of DocumentEntry
     */
    getDocuments = () => {
        if (this.props.documents && this.props.documents.entries && this.props.documents.entries.length > 0) {
            return this.props.documents.entries.map((entry, idx) => {
                return <div key={idx}>
                    <DocumentEntry
                        key={idx}
                        index={idx}
                        entry={entry}
                        showDownloadModal={this.showDownloadModal}
                        showDeleteModal={this.showDeleteModal}
                        providerDocuments={true}
                    />
                    <br/>
                </div>
            }, this);
        } else {
            return <div className="loader" key={0}>{$$('documents_no_data_label')}</div>;
        }
    }

    render() {
        return (
            <div className="card documents-inner-card">
                <div className="card-body inner-card-body">
                    <div ref={(ref) => this.listRef = ref}>
                        <div className="list-group">
                            {this.getDocuments()}
                        </div>
                    </div>
                </div>
                <CenteredModal
                    title={this.state.downloadModal.show ? $$('document_download_dialog_title') : $$('delete_document_entry_modal_title')}
                    show={this.state.downloadModal.show || this.state.deleteModal.show}
                    onHide={this.state.downloadModal.show ? this.onHideDownloadModal : this.onHideDeleteModal}
                    onConfirm={this.state.downloadModal.show ? this.onConfirmDownloadModal : this.onConfirmDeleteModal}
                    confirmBtnLabel={this.state.downloadModal.show ? $$('download_btn') : $$('OK')}
                    cancelBtnLabel={$$('cancel_btn')}
                >
                    <p className={"format-file-name"}>{this.state.downloadModal.show ? $$('confirmation_dialog_message').replace('{fileName}', this.state.documentName).replace('{owner}', this.props.selectedUser.fullname)
                        : $$('delete_document_entry_modal_message')} </p>
                </CenteredModal>
            </div>
        )
    }
}

ProviderDocuments.propTypes = {
    deleteLoggedUserDocument:  PropTypes.func,
    documents:  PropTypes.object,
    i18n:  PropTypes.object,
    selectedUser:  PropTypes.object
}

export default connect(null, {deleteLoggedUserDocument})(ProviderDocuments)