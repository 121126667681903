import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {validators} from '../../helpers/validators'
import {$$, _$$} from '../../helpers/localization'
import Select from "../shared/Select";
import {countryOptions} from "../../constants/countries";
import moment from "moment";
import {GENDER, getResolvedOptions} from '../../constants/select_options';
//import RegisterPageSuccess from './RegisterPageSuccess';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {PATIENT_UIN_URL, PRIVACY_POLICY_URL} from '../../constants/api_paths'
import enGB from "date-fns/locale/en-GB";
import bg from "date-fns/locale/bg";
import sq from "date-fns/locale/sq";
import classNames from 'classnames';
import {fetchHelper} from "../../helpers/request_helper";
import {InformationTooltip} from "../common/tooltips";

export class RegisterForm extends Component {

    static propTypes = {
        register: PropTypes.func
    }
    state = {
        fullname: '',
        email: '',
        city: '',
        country: 'bg',
        birthday: 0,
        child_fullname: '',
        gender: '',
        notes: '',
        phone: '',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        formclass: '',
        errors: {},
        showSuccessPage: false,
        agreementSelected: false,
        formType: 'SELECT',
        contact_uin: '',
        new_contact: true
    }

    emailInput = React.createRef();
    phoneInput = React.createRef();

    constructor(props) {
        super(props)
    }

    static getDerivedStateFromProps(props, state) {
        //Check if there has been an error returned from the register submition and if so se the error state
        if (props.registration.response && props.registration.response.status === 403) {
            props.clearRegistrationData();
            return {
                errors: {email: 'register_form_email_exists_message'}
            }
        } else {
            if (props.registration.response) {
                if (props.registration.response.status > 201) {
                    //error!
                } else {
                    state.showSuccessPage = true;
                }
            }
        }
        if (state.preselectedContactInfo !== props.preselectedContactInfo) {
            state.preselectedContactInfo = props.preselectedContactInfo;
            if (state.preselectedContactInfo) {
                state.formType = 'CHILD';
                state.new_contact = false;
                state.existing_user = {...state.preselectedContactInfo};
                if (!state.existing_user.email.includes("@")) {
                    state.existing_user.email = '';
                }
                state.errors = {...state.errors, user_not_found: false}
            }
        }
        return state;
    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.value;
        this.setState(fields);
        if (this.state.formclass == "was-validated" && this.state.formType === 'CHILD') {
            this.setState({errors: {}})
            if (evt.target.name === 'email' || evt.target.name === 'phone') {
                this.emailInput.current.setCustomValidity("")
                this.phoneInput.current.setCustomValidity("")
            }
        }
    };


    /**
     * Create a language aware Privacy Policy URL
     * @returns {string}
     */
    createPPUrl = () => {
        let lang = this.props.i18n.selected.lang;
        lang = lang.toString() === "sq" ? "en" : lang;
        return PRIVACY_POLICY_URL.replace("{lang}", lang)
    }


    /**
     * Open new tab with the privacy policy url
     */
    onPrivacyPolicyClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        window.open(this.createPPUrl(), "_blank");
    }

    /**
     * Set the state to the latest selected option.
     *
     * @param {object} evt - The event handler argument
     */
    onSelectChange = ({name, value}) => {
        const fields = Object.assign({}, this.state);
        fields[name] = value;
        this.setState(fields);
    };

    /**
     * Set the state to the latest selected date.
     *
     * @param {object} evt - The event handler argument
     */
    onDateChange = (date) => {
        const fields = Object.assign({}, this.state);
        fields["birthday"] = moment(date).valueOf();
        this.setState(fields);
    };


    /**
     * Form submit handler, validate data and set error in state if any. Call register action.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        evt.preventDefault();
        try {
            const formErrors = this.validate();
            this.setState({errors: formErrors});

            if (this.state.formclass !== "was-validated") {
                this.setState({formclass: "was-validated"});
            }

            if (Object.keys(formErrors).length) {
                return;
            }

            if (evt.target.checkValidity()) {

                // eslint-disable-next-line no-unused-vars
                const {
                    errors,
                    child_fullname,
                    formType,
                    agreementSelected,
                    showSuccessPage,
                    formclass,
                    new_contact,
                    existing_user,
                    preselectedContactInfo,
                    contact_uin,
                    ...data
                } = this.state;
                data.organization_id = this.props.orgId;
                if (formType === 'CHILD') {
                    if (!new_contact) {
                        data.contact_uin = preselectedContactInfo ? preselectedContactInfo.id : contact_uin;
                    }
                    data.child_fullname = child_fullname;
                }
                this.props.register(data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Validate form data.
     *
     * @returns {object} errors - Form errors after validate
     */
    validate = () => {
        const errors = {};
        if (this.state.email && !validators.validateEmail(this.state.email) && this.state.email !== "" && this.state.new_contact) {
            errors.email = 'register_form_email_not_correct_message';
        }
        if (this.state.formType === 'CHILD') {
            if (!this.state.email && !this.state.phone && this.state.new_contact) {
                errors.email_or_phone_required = true;
                this.emailInput.current.setCustomValidity($$("email_or_phone_required"));
                this.phoneInput.current.setCustomValidity($$("email_or_phone_required"));
            }
            if (!this.state.new_contact && !this.state.existing_user) {
                errors.user_not_found = true;
            }
        }

        return errors;
    }


    onBackClick = () => {
        this.props.clearRegistrationData();
        this.props.onHide();
    }


    agreementChanged = () => {
        this.setState({agreementSelected: !this.state.agreementSelected})
    }

    hideSuccessForm = (book) => {
        this.setState({
            showSuccessPage: false
        })
        if (book) {
            let u = {...this.props.registration.response};
            this.props.onBook(u);
        }
        this.props.clearRegistrationData();
        this.props.onHide();
    }

    getLocale = () => {
        switch (this.props.i18n.selected.lang) {
            case "en":
                return enGB
            case "bg":
                return bg
            case "sq":
                return sq
            default:
                return enGB
        }
    }

    addChild = () => {
        this.setState({
            child_fullname: '',
            formType: 'CHILD'
        })
    }

    addAdult = () => {
        this.setState({child_fullname: '', formType: 'ADULT'})
    }

    search = () => {
        if (this.state.contact_uin.length !== 10) {
            return;
        }
        fetchHelper.callGet(PATIENT_UIN_URL.replace("{org_uuid}", this.props.orgId).replace("{patient_uin}", this.state.contact_uin))
            .then(e => {
                if (e && e.parentContactInfos.length === 0) {
                    this.setState({existing_user: e, errors: {...this.state.errors, user_not_found: false}})
                } else {
                    this.setState({existing_user: undefined, errors: {user_not_found: true}})
                }
            });
    }

    render() {
        return (
            <div>
                {
                    !this.state.showSuccessPage ?
                        <div className="kt-login__body d-flex justify-content-center">
                            <div className="kt-login__form">
                                <div className="kt-login__title">
                                    <h3 className="pb-2">{$$('create_and_register_new_patient_label')}
                                        {this.state.formType !== "SELECT" && <>
                                            &nbsp;
                                            <InformationTooltip>
                                                {this.state.formType === "CHILD" ? $$('create_and_register_new_patient_info_text') : $$('create_and_register_new_patient_adult_info_text')}
                                            </InformationTooltip></>}
                                    </h3>
                                </div>

                                {this.state.formType === 'SELECT' &&
                                    <div className="d-flex flex-column justify-content-around"
                                         style={{minHeight: "400px"}}>
                                        <div className="d-flex p-2 space-around-justify">
                                            <button type='button' className='btn btn-primary w-25'
                                                    onClick={this.addAdult}>{$$("adult_button_label")}</button>
                                            <button type='button' className='btn btn-primary w-25'
                                                    onClick={this.addChild}>{$$("child_button_label")}</button>
                                        </div>
                                        <div className="">
                                            <hr/>
                                            <button type='button' onClick={this.onBackClick}
                                                    className='btn btn-secondary register-btn-back'>
                                                {$$('cancel_btn')}
                                            </button>
                                        </div>
                                    </div>}
                                {this.state.formType == 'CHILD' &&
                                    <form onSubmit={this.onSubmit} className={this.state.formclass} noValidate={true}>

                                        <div className="form-group">
                                            {this.state.child_fullname &&
                                                <label>{$$('wizard_personal_information_fullname_label')}</label>}
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.state.child_fullname}
                                                placeholder={$$('wizard_personal_information_fullname_label')}
                                                name="child_fullname"
                                                onChange={this.onInputChange}
                                                required
                                            />
                                            <div className="invalid-feedback">
                                                {$$('wizard_personal_information_fullname_required_label')}
                                            </div>
                                        </div>
                                        <div className="row form-group align-items-end">
                                            <div className="col-sm-6">
                                                <Select
                                                    label={$$('gender_label')}
                                                    name="gender"
                                                    value={this.state.gender}
                                                    options={getResolvedOptions(GENDER.OPTIONS)}
                                                    onChange={this.onSelectChange}/>
                                            </div>
                                            <div className="col-sm-6">
                                                <MuiPickersUtilsProvider locale={this.getLocale()} utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        variant="inline"
                                                        format="yyyy/MM/dd"
                                                        margin="normal"
                                                        id="date-picker-inline"
                                                        label={$$("birthday_label")}
                                                        value={this.state.birthday}
                                                        KeyboardButtonProps={{'aria-label': 'change date',}}
                                                        onChange={this.onDateChange}
                                                        required
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                        <div className="row form-group align-items-center">
                                            <div className="col-sm-6">
                                                <Select
                                                    label={$$('country_label')}
                                                    name="country"
                                                    options={countryOptions(this.props.i18n.selected.lang)}
                                                    value={this.state.country}
                                                    onChange={this.onSelectChange}/>
                                            </div>
                                            <div className="col-sm-6">
                                                <label>{$$('city_label')}</label>
                                                <input
                                                    type="text"
                                                    className="form-control my-1 mr-sm-2"
                                                    value={this.state.city}
                                                    placeholder={$$('city_label')}
                                                    name="city"
                                                    onChange={this.onInputChange}/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {this.state.notes && <label>{$$('notes')}</label>}
                                            <textarea
                                                className="form-control"
                                                value={this.state.notes}
                                                placeholder={$$('notes')}
                                                name="notes"
                                                onChange={this.onInputChange}
                                            />
                                        </div>
                                        <div className="mb-3 low-shadow-container">
                                            <p>{$$("contact_information_title")}</p>
                                            {!this.state.preselectedContactInfo && <div className="btn-group mb-3">
                                                <button
                                                    className={classNames("btn btn-secondary", {"active": this.state.new_contact})}
                                                    type="button"
                                                    aria-pressed={this.state.new_contact}
                                                    onClick={() => {
                                                        this.setState({new_contact: true})
                                                    }}>{$$("new_contact_btn")}
                                                </button>
                                                <button
                                                    className={classNames("btn btn-secondary", {"active": !this.state.new_contact})}
                                                    type="button"
                                                    aria-pressed={!this.state.new_contact}
                                                    onClick={() => {
                                                        this.setState({new_contact: false})
                                                    }}>{$$("existing_contact_btn")}
                                                </button>
                                            </div>}
                                            {!this.state.new_contact && <div className="form-group">
                                                {this.state.contact_uin && !this.state.preselectedContactInfo &&
                                                    <label>{$$('patient_pin')}</label>}
                                                {!this.state.preselectedContactInfo && <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={this.state.contact_uin}
                                                        placeholder={$$('patient_pin')}
                                                        name="contact_uin"
                                                        onChange={this.onInputChange}
                                                    />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-secondary" type="button"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        contact_uin: '',
                                                                        existing_user: undefined,
                                                                        errors: {
                                                                            ...this.state.errors,
                                                                            user_not_found: false
                                                                        }
                                                                    })
                                                                }}><span
                                                            className="fa fa-times"></span>
                                                        </button>
                                                        <button className="btn btn-secondary" type="button"
                                                                onClick={this.search}><span
                                                            className="fa fa-search"></span>
                                                        </button>
                                                    </div>
                                                </div>}
                                                {this.state.errors.user_not_found &&
                                                    <div className="custom-invalid-feedback">
                                                        {$$("user_not_found")}
                                                    </div>
                                                }
                                            </div>}

                                            {ContactInfoInputs.call(this)}
                                        </div>
                                        <div className="form-group">
                                            <div className='row flex-space-between register-btn-back-container'>
                                                <div className="col-xs-12 col-md-6 mx-auto mt-2">
                                                    <button type='button' onClick={this.onBackClick}
                                                            className='btn btn-secondary register-btn-back'>
                                                        {$$('cancel_btn')}
                                                    </button>
                                                </div>
                                                <div className="col-xs-12 col-md-6 mx-auto mt-2">
                                                    <button type='submit' className='btn btn-primary full-width'>
                                                        {$$('create_and_register_new_patient_btn')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>}
                                {this.state.formType == 'ADULT' &&
                                    <form onSubmit={this.onSubmit} className={this.state.formclass} noValidate={true}>
                                        <div className="form-group">
                                            {this.state.fullname &&
                                                <label>{$$('wizard_personal_information_fullname_label')}</label>}
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.state.fullname}
                                                placeholder={$$('wizard_personal_information_fullname_label')}
                                                name="fullname"
                                                onChange={this.onInputChange}
                                                required
                                            />
                                            <div className="invalid-feedback">
                                                {$$('wizard_personal_information_fullname_required_label')}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {this.state.email && <label>{$$('email_label')}</label>}
                                            <input
                                                type="email"
                                                className={this.state.errors.email ? "custom-error form-control" : "form-control"}
                                                value={this.state.email}
                                                placeholder={$$('email_label')}
                                                name="email"
                                                onChange={this.onInputChange}
                                            />
                                            <div
                                                className={this.state.errors.email ? "custom-invalid-feedback" : "invalid-feedback"}>
                                                {this.state.errors.email && $$(this.state.errors.email)}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {this.state.phone && <label>{$$('phone_number_required')}</label>}
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.state.phone}
                                                placeholder={$$('phone_number_required')}
                                                name="phone"
                                                onChange={this.onInputChange}
                                                required
                                            />
                                            <div className="invalid-feedback">
                                                {$$('phone_number_required_error')}
                                            </div>
                                        </div>
                                        <div className="row form-group align-items-end">
                                            <div className="col-sm-6">
                                                <Select
                                                    label={$$('gender_label')}
                                                    name="gender"
                                                    value={this.state.gender}
                                                    options={getResolvedOptions(GENDER.OPTIONS)}
                                                    onChange={this.onSelectChange}/>
                                            </div>
                                            <div className="col-sm-6">
                                                <MuiPickersUtilsProvider locale={this.getLocale()} utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        variant="inline"
                                                        format="yyyy/MM/dd"
                                                        margin="normal"
                                                        id="date-picker-inline"
                                                        label={$$("birthday_label")}
                                                        value={this.state.birthday}
                                                        KeyboardButtonProps={{'aria-label': 'change date',}}
                                                        onChange={this.onDateChange}
                                                        required
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                        <div className="row form-group align-items-center">
                                            <div className="col-sm-6">
                                                <Select
                                                    label={$$('country_label')}
                                                    name="country"
                                                    options={countryOptions(this.props.i18n.selected.lang)}
                                                    value={this.state.country}
                                                    onChange={this.onSelectChange}/>
                                            </div>
                                            <div className="col-sm-6">
                                                <label>{$$('city_label')}</label>
                                                <input
                                                    type="text"
                                                    className="form-control my-1 mr-sm-2"
                                                    value={this.state.city}
                                                    placeholder={$$('city_label')}
                                                    name="city"
                                                    onChange={this.onInputChange}/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {this.state.notes && <label>{$$('notes')}</label>}
                                            <textarea
                                                className="form-control"
                                                value={this.state.notes}
                                                placeholder={$$('notes')}
                                                name="notes"
                                                onChange={this.onInputChange}
                                            />
                                        </div>
                                        {/*<div className="form-check text-center">
                                        <input className="position-relative top-0" type="checkbox"
                                               onChange={this.agreementChanged} checked={this.state.agreementSelected}
                                               id="success-outlined"/>
                                        <label className="form-check-label pl-1" htmlFor="success-outlined">
                                            {$$('agreements_message')} <a href={this.createPPUrl()}
                                                                          onClick={this.onPrivacyPolicyClick}>{$$('agreements_message_ca')}</a>
                                        </label>
                                        <div
                                            className={this.state.errors.agreementSelected && !this.state.agreementSelected ? "custom-invalid-feedback" : "invalid-feedback"}>
                                            {$$('agreement_selected_required')}
                                        </div>
                                    </div>*/}
                                        <div className="form-group">
                                            <div className='row flex-space-between register-btn-back-container'>
                                                <div className="col-xs-12 col-md-6 mx-auto mt-2">
                                                    <button type='button' onClick={this.onBackClick}
                                                            className='btn btn-secondary register-btn-back'>
                                                        {$$('cancel_btn')}
                                                    </button>
                                                </div>
                                                <div className="col-xs-12 col-md-6 mx-auto mt-2">
                                                    <button type='submit' className='btn btn-primary full-width'>
                                                        {$$('create_and_register_new_patient_btn')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>}
                            </div>
                        </div>
                        :
                        <div className="p-3">
                            <div
                                className="p-3">{_$$("register_form_successful_message", this.props.registration.response.fullname)}</div>
                            {/* eslint-disable-next-line no-unused-vars */}
                            <div className="p-3">
                                <button type="button" className="btn btn-primary mr-3" onClick={(e) => {
                                    this.hideSuccessForm()
                                }}>{$$("close_btn_label")}</button>
                                <button type="button" className="btn btn-primary" onClick={(e) => {
                                    this.hideSuccessForm(true)
                                }}>{$$("create_appointment")}</button>
                            </div>
                        </div>
                }
            </div>
        )
    }
}

RegisterForm.propTypes = {
    clearRegistrationData: PropTypes.func,
    i18n: PropTypes.object,
    register: PropTypes.func,
    registration: PropTypes.object,
    onHide: PropTypes.func,
    orgId: PropTypes.string
};

export default (RegisterForm)


function ContactInfoInputs() {
    let userInfo = this.state.new_contact ? this.state : this.state.existing_user
    if (!userInfo) {
        return null;
    }
    return <>
        <div className="form-group">
            {userInfo.fullname && <label>{$$('wizard_personal_information_fullname_label')}</label>}
            <input
                type="text"
                className="form-control"
                value={userInfo.fullname}
                placeholder={$$('wizard_personal_information_fullname_label')}
                name="fullname"
                onChange={this.onInputChange}
                required
                readOnly={!this.state.new_contact}
            />
            <div className="invalid-feedback">
                {$$('wizard_personal_information_fullname_required_label')}
            </div>
        </div>
        <div className="form-group">
            {userInfo.email && <label>{$$('email_label')}</label>}
            <input ref={this.emailInput}
                   type="text"
                   className={this.state.errors.email && this.state.new_contact ? "custom-error form-control" : "form-control"}
                   value={userInfo.email || ""}
                   placeholder={$$('email_label')}
                   name="email"
                   onChange={this.onInputChange}
                   readOnly={!this.state.new_contact}
            />
            <div
                className={this.state.errors.email && this.state.new_contact ? "custom-invalid-feedback" : "invalid-feedback"}>
                {this.state.errors.email ? $$(this.state.errors.email) : $$('email_or_phone_required')}
            </div>
        </div>
        <div className="form-group">
            {userInfo.phone && <label>{$$('phone_label')}</label>}
            <input
                ref={this.phoneInput}
                type="text"
                className="form-control"
                value={userInfo.phone || ""}
                placeholder={$$('phone_label')}
                name="phone"
                onChange={this.onInputChange}
                readOnly={!this.state.new_contact}
            />
            <div className="invalid-feedback">
                {$$('email_or_phone_required')}
            </div>
        </div>
    </>;
}