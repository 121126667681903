import React, {Component} from 'react'
import {connect} from 'react-redux'
import History from './History';
import {clearSelectedUserData} from '../../actions/users_actions'
import {Routes} from '../../constants/routes'
import history from '../../helpers/history'
import _ from 'underscore'
import {medicalHistoryHelper} from "../../helpers/medical_history_helper";
import {APPOINTMENTS_APPOINTMENT_VIDEO} from "../../constants/pages";
import PropTypes from "prop-types";


export class MedHistoryPage extends Component {
    state = {
        clearOnUnmount: true,
    }

    constructor(props) {
        super(props);
        this.index = 0;
    }

    componentDidMount() {
        if (_.isEmpty(this.props.selectedUser.data)) {
            history.push(Routes.LOGIN);
        }
    }

    keepSelectedUserAndOpenAppointment = (patient, appointment) => {
        this.setState(
            {
                clearOnUnmount: false
            },
            function () {
                medicalHistoryHelper.prepareMedicalHistoryData(APPOINTMENTS_APPOINTMENT_VIDEO, patient, appointment, Routes.APPOINTMENT)
            }
        )

    }

    componentWillUnmount() {
        if (this.state.clearOnUnmount && !this.props.location.state?.videoBackButton) {
            this.props.clearSelectedUserData();
        }
    }

    render() {
        return (
            <div className="med-history-page">
                <History i18n={this.props.i18n}
                    videoBackButton= {this.props.location.state?.videoBackButton}
                         keepSelectedUserAndOpenAppointment={this.keepSelectedUserAndOpenAppointment}/>
            </div>
        )
    }
}

MedHistoryPage.propTypes = {
    clearSelectedUserData: PropTypes.func,
    history: PropTypes.object,
    i18n:PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    selectedUser: PropTypes.object
}

const mapStateToProps = (state) => ({
    i18n: state.language,
    selectedUser: state.selectedUser
})

export default connect(mapStateToProps, {clearSelectedUserData})(MedHistoryPage)