import {fetchHelper} from '../helpers/request_helper';
import {
    CLINICIAN_LOGIN_URL,
    REFRESH_TOKEN_URL,
    REGISTER_URL,
    LOGOUT_URL,
    SEND_2FA_EMAIL,
    GET_2FA_METHOD,
    GENERATE_TOTP_ENDPOINT,
    VALIDATE_TOTP_ENDPOINT,
    UPDATE_2FA_ENDPOINT
} from '../constants/api_paths';
import {v4} from "uuid";

export const authService = {
    login,
    register,
    refreshToken,
    logout,
    send2FAEmail,
    get2FAMethod,
    generateTotp,
    update2FA,
    verifyTotp,
    autoLogoutServer
};

/**
 * Perform the login request
 *
 * @param {string} email the user email
 * @param {string} password the user password
 * @param {string} code - 2FA code
 * @returns {object} promise object
 */
export function login(email, password, code) {
    const body = {
        username: email,
        password,
        c:code,
        app: "Medrec-M-Clinic",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        device_hash:  getDeviceHash()
    };
    return fetchHelper.callPost(CLINICIAN_LOGIN_URL, body);
}


function getDeviceHash() {
    let hash = localStorage.getItem("mdrcmc")
    if (!hash) {
        hash = "WA-" + v4();
        localStorage.setItem("mdrcmc", hash);
    }
    return hash;
    //return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}


/**
 * Perform the login request
 *
 * @returns {object} promise object
 */
export function refreshToken(refreshToken) {
    const body = {
        refreshToken: refreshToken,
    };
    return fetchHelper.callPost(REFRESH_TOKEN_URL, body);
}

/**
 * Perform the register request
 *
 * @param {object} user the user to create
 * @returns {object} promise object
 */
export function register(user) {
    return fetchHelper.callPost(REGISTER_URL, user);
}

export function logout() {
    //log out
    // eslint-disable-next-line no-unused-vars
    fetchHelper.callPost(LOGOUT_URL, {}).catch(reason => {
    });
}

export function autoLogoutServer() {
    //log out
    return fetchHelper.callPost(LOGOUT_URL, {}).catch(reason => {
        console.log(reason)
    });
}

export function send2FAEmail(email) {
    return fetchHelper.callPut(SEND_2FA_EMAIL, {email:email});
}

export function get2FAMethod() {
    return fetchHelper.callGet(GET_2FA_METHOD);
}

export function generateTotp() {
    return fetchHelper.callGet(GENERATE_TOTP_ENDPOINT)
}

export function update2FA(e) {
    return fetchHelper.callPut(UPDATE_2FA_ENDPOINT, e)
}

export function verifyTotp(r) {
    return fetchHelper.callPost(VALIDATE_TOTP_ENDPOINT, r)
}