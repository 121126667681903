import React, {Component} from "react";
import {ReactComponent as DeleteIcon} from "../../../public/media/icons/svg/Design/Delete.svg";
import {ReactComponent as EditIcon} from "../../../public/media/icons/svg/Design/Edit.svg";
import {ReactComponent as DownloadsIcon} from "../../../public/media/icons/svg/Design/Downloads.svg";
import PropTypes from "prop-types";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {$$} from "../../helpers/localization";

export default class DownloadEditDeleteButtons extends Component {
    constructor(props, context) {
        super(props, context);
    }

    downloadTooltip = props => (
        <Tooltip {...props} show={"true"}>{$$("download_btn")}</Tooltip>
    );

    editTooltip = props => (
        <Tooltip {...props} show={"true"}>{$$("edit_label")}</Tooltip>
    );

    deleteTooltip = props => (
        <Tooltip {...props} show={"true"}>{$$("delete_label")}</Tooltip>
    );


    render() {
        return <>
            <span style={{'display': 'inline'}}>
                {this.props.handleDownloadClick &&
                    <span onClick={this.props.handleDownloadClick} className="edit-delete-button">
                    <OverlayTrigger
                        placement={"top"}
                        overlay={this.downloadTooltip}
                        popperConfig={{
                            modifiers: {
                                preventOverflow: {
                                    enabled: false
                                }
                            }
                        }}
                    >
                        <DownloadsIcon className="svg-icon inline-icn" height="17px" width="17px"/>
                    </OverlayTrigger>
                </span>}

                {this.props.handleEditClick &&
                    <span onClick={this.props.handleEditClick} className="edit-delete-button">
                    <OverlayTrigger
                        placement={"top"}
                        overlay={this.editTooltip}
                        popperConfig={{
                            modifiers: {
                                preventOverflow: {
                                    enabled: false
                                }
                            }
                        }}
                    >
                        <EditIcon className="svg-icon inline-icn" height="17px" width="17px"/>
                    </OverlayTrigger>
                </span>}

                {this.props.handleDeleteClick &&
                    <span onClick={this.props.handleDeleteClick} className="user-profile-link edit-delete-button">
                    <OverlayTrigger
                        placement={"top"}
                        overlay={this.deleteTooltip}
                        popperConfig={{
                            modifiers: {
                                preventOverflow: {
                                    enabled: false
                                }
                            }
                        }}
                    >
                        <DeleteIcon className="svg-icon inline-icn" height="17px" width="17px"/>
                    </OverlayTrigger>
                </span>}
            </span>
        </>
    }
}

DownloadEditDeleteButtons.propTypes = {
    handleDeleteClick: PropTypes.func,
    handleDownloadClick: PropTypes.func,
    handleEditClick: PropTypes.func
};
