/* eslint-disable */
import React from 'react'
import {$$} from '../../../helpers/localization'
import {CONVERTER} from '../../../utils/converter'

export default function CholesterolEntry(props) {
    return (
        <div className="card logbook-entry-card">
            <div className='row zero-margin-row'>
                <div className='medrec-grey-2 vitals-logbook-time logbook-entry-time-icon'>
                    <i className='kt-menu__link-icon far fa-clock'/> &nbsp;
                    {CONVERTER.getEntryDateTimeFormat(props.entry.date_time)}
                </div>
                &nbsp;&nbsp;
                <div>
                    <div>HDL: {CONVERTER.cholesterolPerUnit(props.entry.hdl)} {CONVERTER.getFormattedCholesterolUnit()}</div>
                    <div>LDL: {CONVERTER.cholesterolPerUnit(props.entry.ldl)} {CONVERTER.getFormattedCholesterolUnit()}</div>
                    <div>{$$("triglycerides")}: {CONVERTER.triglyceridesPerUnit(props.entry.triglycerides)} {CONVERTER.getFormattedCholesterolUnit()}</div>
                    <div>{$$("total_cholesterol")}: {CONVERTER.cholesterolPerUnit(props.entry.total_cholesterol)} {CONVERTER.getFormattedCholesterolUnit()}</div>
                </div>
            </div>
        </div>
    )
}
