export const en = {
    text:
    {
        title: "Medrec:M Clinic",
        main_page_label: "Medical record",
        select_language_label: "Select language",
        login_label: "Log in",
        logout_label: "Log out",
        save_btn_label: "Save",
        username_label: "Username",
        email_label: "E-mail",
        password_creation_title:"Password must contain:",
        at_Lest_eight_characters:"At least 8 characters",
        at_Lest_one_uppercase_characters:"At least one Uppercase character (A-Z)",
        at_Lest_one_lowercase_characters:"At least one Lowercase character (A-Z)",
        at_Lest_one_number:"At least one number (0-9)",
        at_Lest_one_special:"At least one Special character (~!@#$%^&*_-+=`|\\(){}[]:;\"'<>,.?/)",
        password_is_not_valid:"Password is not Valid (See Password Requirements)",
        password_label: "Password",
        new_password_label: "New password",
        repeat_password_label: "Repeat password",
        change_password_label: "Change password",
        user_password_updated_message: 'Password updated successfully',
        user_password_update_error_message: 'Server error!',
        email_required_message: "E-mail is required!",
        email_required_message_patient_registration: "E-mail is required!",
        password_required_message: "Password is required!",
        login_form_invalid_credentials_message: "Invalid credentials!",
        register_label: "Register",
        register_form_email_exists_message: "E-mail already exists!",
        register_form_email_not_correct_message: "E-mail is not correct!",
        register_form_passwords_not_match_message: "Passwords do not match!",
        register_form_password_length_message: "Password should have minimum 8 characters!",
        register_form_password_whitespace_message: "Password cannot start or end with whitespace character!",
        register_form_successful_message: "Patient %1 is successfully registered!",
        register_form_website_not_correct_message: 'Website is not correct!',
        confirm_btn_label: "Confirm",
        close_btn_label: "Close",
        Charts: "Charts",
        Logbook: "Logbook",
        logbook_entries_header: " ",
        charts_header: " ",
        Systolic: "Systolic",
        Diastolic: "Diastolic",
        Pulse: "Pulse",
        notes: "Notes",
        messages: "Communication",
        am:"AM",
        pm:"PM",
        close_notes: "Close Communications",
        total_cholesterol: "Total Cholesterol",
        triglycerides: "Triglycerides",
        Cholesterol: "Cholesterol",
        glucose_units: "Glucose Unit",
        ketones_units: "Ketones Unit",
        hba1c_units: "HBA1C Unit",
        Weight: "Weight",
        Height: "Height",
        weight_and_height: "Weight and Height",
        chronic_conditions: "Chronic Conditions",
        allergies: "Allergies",
        blood_group: "Blood Group",
        details: "Details",
        blood_pressure: "Blood Pressure",
        settings_label: "Settings",
        unit_of_measurement_label: "Unit of Measurement",
        cholesterol_units_label: "Cholesterol Unit",
        classification_method_label: "Classification Method",
        date_format_label: "Date Format",
        time_24hour_label: "Use a 24 Hour Clock",
        settings_updated_message: "Settings Saved Successfully",
        US: "US (lbs, oz, fl oz, inch)",
        metric: "Metric (kg, gram, ml, cm)",
        kg: "kg",
        pound: "lbs",
        inches: "inches",
        cm: "cm",
        None: "None",
        diagnosed_in: "Diagnosed in",
        mild: "Mild",
        mild_to_moderate: "Mild to moderate",
        moderate: "Moderate",
        moderate_to_severe: "Moderate to severe",
        severe: "Severe",
        life_threatening: "Life threatening",
        drug_allergy: "Drug Allergy",
        dust_allergy: "Dust Allergy",
        food_allergy: "Food Allergy",
        insect_allergy: "Insect Allergy",
        latex_allergy: "Latex Allergy",
        mold_allergy: "Mold Allergy",
        pet_allergy: "Pet Allergy",
        pollen_allergy: "Pollen Allergy",
        other_allergy: "Other Allergy",
        Severity: "Severity",
        Medications: "Medications",
        millimol_per_litre: "mmol/L",
        percent: "%",
        millimol_per_mol: "mmol/mol",
        milligram_per_decilitre: "mg/dl",
        millimetre_of_mercury: "mmHg",
        week_label: "7 days",
        fortnight_label: "14 days",
        month_label: "30 days",
        quarter_label: "90 days",
        custom_label: "Custom",
        Symptoms: "Symptoms",
        Temperature: "Temperature",
        Hydration: "Hydration",
        Respiratory_rate: "Respiratory rate",
        Saturation: "Oxygen saturation",
        celcius: "°C",
        ml: "ml",
        bpm: "bpm",
        fahrenheight: "°F",
        floz: "fl oz",
        refresh_token_expired_message: 'Session timeout',
        sharing_label: "Sharing",
        schedule_label: "Work Time",
        messages_label: "Chat",
        new_message_from: "From",
        pending_permission_list_label: "Permissions pending for aproval",
        approved_permission_list_label: "Approved permissions",
        deny_permission_label: "Deny",
        close_confirm_appointment:"Cancel",
        no_users_after_filtering:"No Search Results",
        sort_by_name:"Sort By Name",
        sort_by_most_recent:"Sort By Most Recent",
        new_messages_only:"Unread Chat Messages Only",
        approve_permission_label: "Approve",
        request_label: "Request",
        request_permission_label: "Request permission",
        requested_user_does_not_exist_message: "User with requested e-mail does not exist",
        premission_request_success_message: "Permission requested successfuly",
        forgot_password: "Forgot password?",
        privacy_policy: "Privacy Policy",
        gender_label: "Sex",
        country_label: "Country",
        male: "Male",
        female: "Female",
        city_label: "City",
        auto_logout:"Your session has expired",
        birthday_label: "Date of Birth",
        of: "of",
        Dashboard: "Dashboard",
        user_info_label: "User info",
        latest_measurements_label: "Latest measurements",
        last_week_data: "Weekly charts",
        no_recent_measurements: "There are no measurements for this user.",
        loading_data_label: "Loading data...",
        logbook_no_data_label: "This user has not logged any entries yet.",
        symptoms_no_data_label: "This user has not logged any symptoms yet.",
        search_placeholder_label: "Search...",
        latest_symptoms_label: "Last 24 Hours Symptoms Log",
        no_latest_symptoms_label: "No Symptoms Logged in the Last 24 Hours.",
        profile_label: 'Profile',
        edit_profile_label: 'Edit profile',
        update_btn: 'Update',
        cancel: 'Cancel',
        not_available:'Not Available',
        available:'Available',
        edit_schedule:"Edit Schedule",
        delete:"Delete",
        update:"Update",
        holiday:"Holiday",
        upload_image_label: 'Upload profile picture',
        user_info_updated_message: 'User profile updated successfuly',
        unsupported_image_format: 'The selected file format is not supported',
        choose_file_label: 'Choose image',
        Documents: "Documents",
        category_lobal: "Category",
        document_type_label: "Document type",
        type_description_label: "Type Description",
        document_download_dialog_title: "Download Document",
        type_description_required_message: "Type Description Required",
        confirmation_dialog_message: "The document ({fileName}) you want to download belongs to {owner} and can contain sensitive data. Make sure you keep it in a safe location or delete it after use.",
        confirmation_provider_document_dialog_message: "Download ({fileName})?",
        documents_no_data_label: "This user has not uploaded any documents yet.",
        download_btn: "Download",
        cancel_btn: "Cancel",
        document_type_none: "None",
        document_type_epicrisis: "Epicrisis",
        document_type_prescription: "Prescription",
        document_type_lab_results: "Laboratory results",
        document_type_ambulance_sheet: "Examination report",
        document_type_referral: "Referral",
        document_type_other: "Other",
        document_type_diagnostic_imaging:"Diagnostic Imaging",
        document_type_medical_report: "Medical report",
        add_new_document:"Attach New Document",
        Test_Result_Documents: "Patient Documents",
        mark_document_entry_deleted_modal_title: "Mark document entry as deleted",
        mark_document_entry_deleted_modal_message: "Are you sure you want to delete this document?",
        document_label: "Add Document",
        update_document: "Update Document",
        document_title: "Document Title",
        document_title_required_message: "Document Title Required",
        no_file_chosen: "No File Chosen",
        file_required_message: "File Required",
        upload_document: "Upload Document",
        log_symptoms_time_label: "Time",
        log_symptoms_date_label: "Date",
        all_label: "All",
        all_appointments_label: "All appointments",
        select_category: "Select category",
        documents_no_data_for_filters_label: "There are no documents corresponding to the selected filters.",
        pending_for_user_approval_label: "Users who need to approve your requests",
        start_date: "From Date",
        taken_dose:"Taken Dose:",
        therapy_report:"Therapy Adherence Report",
        end_date: "To Date",
        load_more: "Show More",
        afternoon: "Afternoon",
        morning: "Morning",
        evening: "Evening",
        select_gender: "Select Gender",
        my_documents_label: "My Documents",
        Blood_sugar: "Blood sugar",
        HBA1C: "HbA1c",
        Ketones: "Ketones",
        Flow: "Flow",
        Color: "Color",
        Consistency: "Consistency",
        Urine_pH: "Urine pH",
        no_flow: "No flow",
        light: "Light",
        medium: "Medium",
        heavy: "Heavy",
        unexpected: "Unexpected",
        none: "None",
        black: "Black",
        brown: "Brown",
        dark_red: "Dark red",
        bright_red: "Bright red",
        pink: "Pink",
        orange: "Orange",
        gray: "Gray",
        waterly: "Waterly",
        clots: "Clots",
        mmol_mol: "mmol/mol",
        no_chronic_conditions: "No Chronic Conditions",
        no_allergies: "No Allergies",
        Communication: "Communication",
        my_communication_label: "My Communications",
        new_chat: "New chat",
        recent_chats_label: "Recent chats",
        available_users_label: "Available Users",
        back: "Back",
        type_message: "Chat Message...",
        call: "Incoming call",
        incoming_call_message: "Incoming call from %1",
        incoming_call_another_user_message: "Incoming call from another user",
        accept: "Accept",
        no_user_selected_message: 'No User Selected to Call',
        ok: 'OK',
        no_answer: "No Answer",
        no_answer_message: "No Answer from %1",
        you_wrote: "You wrote",
        missed_call_label: "Missed call",
        rejected_call_label: "Rejected call",
        in_progress_call_label: "Call in progress",
        completed_call_label: "Call ended",
        ongoing_call: "Call ongoing!",
        general_practitioner: "General Practitioner",
        practice_name:"Practice Name",
        practice_address:"Practice Address",
        practice_contact:"Contact number",
        practice_centre_code:"Centre code",
        practice_practitioner_code:"Practitioner Code",
        na:"N/A",
        medication_plan: "Medication plan",
        current_medications: "Current Medications",
        times_per_day: "%1 %2, once a day",
        times_per_day_plural: "%1 %2, %3 times a day",
        every_x_hours: "%1 %2, every hour",
        every_x_hours_plural: "%1 %2, every %3 hours",
        times_per_day_intakes_only: "once a day",
        times_per_day_plural_intakes_only: "%1 times a day",
        every_x_hours_intakes_only: "every hour",
        every_x_hours_plural_intakes_only: "every %1 hours",
        daily: 'Daily',
        every_x_days: "Every X day(s)",
        every_days: "Every day",
        every_days_plural: "Every %1 days",
        take_x_rest_x: "Take X day(s) and rest Y day(s)",
        take_rest_x_plural: "Take %1 days and rest one day",
        take_rest_y_plural: "Take one day and rest %2 days",
        take_rest_xy_plural: "Take %1 days and rest %2 days",
        take_rest: "Take one day and rest one day",
        when_needed: "When needed",
        ongoing: "Ongoing",
        until_date: 'Until date ',//%1',
        for_x_days_until: "For X day(s)", //until %2"
        for_days_until: "for %1 day",
        for_days_until_plural: "for %1 days",
        effective_from: "Effective from %1",
        get_help_label: "Get Help",
        appointments_label: 'Appointments',
        appointments_for: 'Appointments for ',
        today: "Today",
        yesterday: "Yesterday",
        month: "Month",
        week: "Week",
        day: "Day",
        this_month:"This Month",
        all_day: "All Day",
        mon: "Mon",
        tue: "Tue",
        wed: "Wed",
        thu: "Thu",
        fri: "Fri",
        sat: "Sat",
        sun: "Sun",
        this_week:"This Week",
        time_on: "Time on",
        time_off: "Time off",
        from:"From",
        to:"To",
        applies_to:"Applies to",
        new_schedule_starting: "New Schedule Starting",
        new_schedule: "New Schedule",
        set_new_schedule: "Set New Schedule",
        repeat_for:"Repeat For",
        do_not_repeat: "Do Not Repeat",
        start:"Start",
        end:"End",
        one_week:"One Week",
        one_month:"One Month",
        one_year:"One Year",
        list: "List",
        search_for_medications: "Search",
        medications_found: "Medications Found",
        needs_prescription:"Needs Prescription",
        doesnt_need_prescription:"No Prescription Required",
        close_search: "Close",
        Lab_Results: "Lab Results",
        select_laboratory_label: "Select laboratory",
        lab_results_no_data_primary_label: "Тhere aren't any lab tests available",
        lab_results_no_data_secondary_label: "Тhe patient doesn't have any lab results",
        documents_no_data_primary_label: "This user has not uploaded any documents yet.",
        documents_no_data_secondary_label: " ",
        all_patients: "All patients",
        lab_test_col_name_name: "Група показател",
        lab_test_col_name_result: "Резултат",
        lab_test_col_name_units: "Мерни единици",
        lab_test_col_name_flag: "Флаг",
        lab_test_col_name_range: "Референтни стойности",
        lab_microbiology_col_name_antibiogram: "Антибиограма",
        select_image: 'Select',
        preview_image: 'Preview',
        OK: 'OK',
        delete_image_modal_title: 'Remove image',
        delete_image_modal_message: 'Are you sure you want to remove your image?',
        upload_documents: 'Upload new document',
        delete_document_entry_modal_title: 'Delete document',
        delete_document_entry_modal_message: 'Are you sure you want to delete this document?',
        delete_template_modal_message: 'Are you sure you want to delete this template?',
        patients_label: 'Patients',
        search_for_patients_label: 'Search For Patients',
        search_patient_label: 'Search patient',
        search_in_messages_label: "Search For User",
        no_unread_messages: "No Unread Chat Messages",
        search_within_selected_messages_label: "Search Chat Messages",
        no_messages_to_show: "No Chat Messages To Show",
        messages_will_appear_here: "Chat Messages will appear here",
        view_patients_details_label: 'View details',
        grid_view_label: 'Grid View',
        list_view_label: 'List View',
        year_old: 'year old',
        years_old: 'years old',
        medical_history: 'Medical History',
        about: 'About',
        contact: 'Contact',
        select_today_label: 'Today',
        this_week_label: 'This Week',
        this_month_label:'This Month',
        next_patient_label: 'Patient',
        previous:"Previous",
        next:"Next",
        forward_btn:"Next",
        peak_flow_report:"Peak Flow Diary",
        download_peak_flow: "Download the Peak Flow Diary",
        download_medications: "Download the Medications Intake Report",
        download_therapy: "Download the Therapy Adherence Report",
        total_dose_intake: "Total Dose Intake",
        medications_report:"Medications Intake Report",
        no_pending_appointments_primary_label: 'No Pending Appointments',
        no_pending_appointments_primary_label_for_today: 'No Pending Appointments For Today',
        go_to_next_date_with_appointment: "Click here to go to the next appointment",
        no_patients_primary_label: 'You have no patients',
        no_results_found: "No results found",
        summary:"Summary",
        min:"MIN",
        max:"MAX",
        average:"AVERAGE",
        appointment_label: 'Appointment',
        eccounter_data_separator: "More information",
        tooltip_schedule_unavailable: "Schedule unavailable to patients",
        tooltip_schedule_available: "Schedule available to patients",
        tooltip_appointment_unavailable: "Appointment unavailable to patients",
        tooltip_independent_schedule: "Independent schedule",
        calendar_label: 'Calendar',
        calendars_label: 'Calendars',
        mark_all: "Mark All",
        unmark_all: "Unmark All",
        holidays_label:"Holidays",
        book_time_off:"Book time off",
        set:"Set",
        appointment_type_label: 'Appointment Type',
        last_appointment_label: 'Last Appointment',
        country_city_label: 'Country, City',
        recent_messages_title: 'Recent Chat Messages',
        age_label: 'Age',
        address_label: 'Address',
        price_settings_label: 'Price List',
        new_appointment_type:'New Appointment Type',
        edit_appointment_type:'Edit Appointment Type',
        create: 'Create',
        create_new_appointment: 'Create Appointment',
        create_new_consultation: 'Create Consultation',
        opened_communication_duration:"Number of days for followup questions from the patient after the consultation completion",
        average_response_time_hours:"Your Average Response Time (hours)",
        new_consultation_type: 'New Consultation Type',
        edit_consultation_type: 'Edit Consultation Type',
        edit:'Edit',
        exam_type:'Examination Type',
        organisation:'Organisation',
        add:"Add",
        add_organisation:"Add Organisation",
        all:"All",
        provider_calendars_all:"All organisations",
        name:'Name',
        location:'Location',
        currency:'Currency',
        price:'Price',
        online:'Online',
        on_site:'On Site',
        billing_type_online: 'Online',
        billing_type_on_site: 'On Site',
        billing_type_online_and_on_site:"Online or On Site",
        payment_location_label: "Payment",
        allow_video_for_on_site_input_label:"Allow patient to switch to online appointment",
        lock_location_in_hours_label:"Time for switching to online appointment (hours)",
        primary_exam:'Primary Examination',
        follow_up_exam:'Follow up Examination',
        other:'Other',
        your_price_list_is_empty:"Your price list is empty",
        delete_modal_header:"Are you sure you want to delete the listing {name}?",
        delete_modal_body:"This listing will be deleted immediately. You cannot undo this action.",
        waiting_for_doctor_approval: "Waiting for confirmation",
        waiting_for_doctor_approval_consultation: "Waiting for confirmation",
        waiting_for_patient_approval: "Waiting for confirmation",
        waiting_for_patient_approval_consultation: "Waiting for confirmation",
        need_more_info: "Need more information",
        need_more_info_consultation: "Need more information",
        accepted: "Confirmed",
        accepted_consultation: "Confirmed",
        completed: "Completed",
        completed_consultation: "Completed",
        rejected_by_patient: "Rejected by patient",
        rejected_by_patient_consultation: "Rejected by patient",
        canceled_by_patient: "Canceled by patient",
        canceled_by_patient_consultation: "Canceled by patient",
        rejected_by_provider: "Rejected by provider",
        rejected_by_provider_consultation: "Rejected by provider",
        canceled_by_provider: "Canceled by provider",
        canceled_by_provider_consultation: "Canceled by provider",
        canceled_response_overdue:"Canceled (Response Overdue)",
        canceled_response_overdue_consultation:"Canceled (Response Overdue)",
        canceled_not_paid:"Canceled (Payment Overdue)",
        canceled_not_paid_consultation:"Canceled (Payment Overdue)",
        lab_results_filter_label: 'Filter',
        lab_results_date_label: 'Date',
        lab_results_tests_label: 'Tests',
        date_label: "Date",
        condition_label: "Condition",
        status_label: "Status",
        download_as_pdf: "Generate Examination Document",
        clear_form:"Clear Form",
        confirm_clear_warning: "Are you sure you want to clear the appointment form?",
        copy_from_last:"Copy from last examination",
        examination_outcome:"Examination outcome",
        main_diagnosis_label: "Main Diagnosis",
        conclusion:"Conclusion",
        preliminary_diagnosis:"Preliminary diagnosis",
        additional_info: "Additional Information",
        concomitant_and_diseases_complications_label:"Concomitant diseases and complications",
        no_records_found: "No Records Found",
        objective_data_label: "Objective Data",
        subjective_data_label: "Subjective Data",
        therapy_label: "Therapy",
        therapy_tab_label: "Therapy Adherence",
        concomitant_diseases_label: "Concomitant Diseases",
        appointment_details_missing_header: "The following fields are empty:",
        appointment_details_missing_question: "Do you really want to complete the appointment?",
        consultation_details_missing_question: "Do you really want to complete the consultation?",
        tests_label: "Tests",
        no_encounter_found_label: "The Examination Result is not found",
        category_label: "Category",
        title_label: "Name",
        update_note: "Update Message",
        create_note: "Create Message",
        note_required_message: "Message are required!",
        delete_note_modal_header: "Confirm your action",
        delete_note_modal_text: "Are you sure you want to delete this Message?",
        private_note_label: "Private",
        to_all_note_label: "All",
        consultation_paid: " created a new text consultation on ",
        consultation_canceled: " has canceled their text consultation that they created on ",
        provider_only_note_label: "All Providers",
        patient_only_note_label: "Patient Only",
        update_visibility: "Update visibility",
        visibility_label: "Visibility",
        visibility_required_message: "Visibility is required!",
        delete_label: "Delete",
        share_label: "Share",
        edit_label: "Edit",
        edit_exam_results: "Edit examination results",
        general_note_label: "General message",
        new_note_label: "New note",
        new_message_label: "New message",
        examination_result_label: "Examination result",
        consultation_examination_result_label: "Consultation result",
        print_label: "Print",
        diagnosis_label: "Diagnosis",
        subjective_data_required_message: "Subjective data are required!",
        objective_data_required_message: "Objective data are required!",
        diagnosis_required_message: "Diagnosis is required!",
        therapy_required_message: "Therapy is required!",
        Vitals_Logbook: "Vitals Logbook",
        health_issues_label: "Health issues",
        user_logs_label: "User logs",
        administrative_portal_label: "Administrative portal",
        manage_your_practice: "Log in as an administrator",
        staff:"Staff",
        search_for_staff:"Search For Staff",
        uin: "UIN:",
        patient_pin: "PIN",
        select_a_practice : "Select a Practice to manage",
        select_practice : "Select Practice",
        Menstruation: "Menstruation",
        vitals_logbook_no_data_primary_label: "There are no records in the patient's Vitals logbook",
        vitals_logbook_no_appointments: "No pending appointments",
        vitals_logbook_no_notes: "No Messages",
        note_label: "Message",
        no_selected_user: "Select an appointment",
        selected_user_will_appear_here: "Patient information will appear here",
        short_sunday_label: "Sun",
        short_monday_label: "Mon",
        short_tuesday_label: "Tue",
        short_wednesday_label: "Wed",
        short_thursday_label: "Thu",
        short_friday_label: "Fri",
        short_saturday_label: "Sat",
        open_appointment_label: "Open appointment",
        open_consultation_label: "Open consultation",
        morning_time_label: "Morning pill time",
        noon_time_label: "Noon pill time",
        evening_time_label: "Evening pill time",
        bed_time_label: "Before bed pill time",
        morning_time_required_message: "Morning pill time is required!",
        noon_time_required_message: "Noon pill time is required!",
        evening_time_required_message: "Evening pill time is required!",
        bed_time_required_message: "Before bed pill time is required!",
        complete_appointment_button_label: "Complete appointment",
        results_are_saved : "Examination results are saved.",
        consultation_results_are_saved : "Consultation results are saved.",
        do_you_really_want_to_complete: "Do you really want to complete the appointment?",
        complete_consultation_button_label: "Complete consultation",
        complete_appointment_modal_header: "Do you want to continue?",
        complete_appointment_modal_body: "Completing the Appointment will allow the patient to view the examination results. This action is irreversible.",
        complete_consultation_modal_body: "Completing the Consultation will allow the patient to view the examination results. This action is irreversible.",
        completed_label: "COMPLETED",
        completed_consultation_label: "COMPLETED",
        notifications_label: "Notifications",
        see_all_label: "See all",
        minimize_label: "Minimize",
        created_new_appointment_label: " created a new appointment for ",
        canceled_their_appointment_label: " canceled their appointment for ",
        seen_label: "seen",
        no_new_notifications_label: "No New Notifications",
        no_notifications_label: "No Notifications",
        cancel_appointment_label: "Cancel appointment",
        cancel_consultation_label: "Cancel consultation",
        patient_information_label: "Patient information",
        cancel_appointment_confirmation_header: "Are you sure you want to cancel {fullname}'s appointment for {date}?",
        cancel_consultation_confirmation_header: "Are you sure you want to cancel {fullname}'s consultation expiring on {date}?",
        cancel_appointment_confirmation_body: "This appointment will be cancelled immediately. You cannot undo this action.",
        cancel_consultation_confirmation_body: "This consultation will be cancelled immediately. You cannot undo this action.",
        remove_label: "Remove",
        wizard_personal_information: 'Personal information',
        wizard_biography: 'Biography',
        wizard_practice_details: 'Practice Details',
        wizard_upload_documents: 'Upload documents',
        wizard_personal_information_fullname_label: "Full Name*",
        wizard_personal_information_title_label: 'Postnominals',
        wizard_personal_information_password_confirm_label: "Confirm password*",
        wizard_personal_information_uin_label: 'Unique Identifier Number*',
        wizard_personal_information_uin_label_2: 'Unique Identifier Number',
        wizard_personal_information_specialties_label: 'Specialties*',
        specialties_label: 'Specialties',
        wizard_personal_information_fullname_required_label: "Full Name is required!",
        wizard_personal_information_password_confirm_required_label: "Password confirmation is required!",
        wizard_personal_information_uin_required_label: "UIN is required!",
        wizard_personal_information_specialties_required_label: 'Specialties are required!',
        wizard_biography_languages_label: 'Languages*',
        wizard_biography_languages_required_label: 'Languages is required!',
        wizard_biography_label: 'Biography*',
        wizard_biography_label_2: 'Biography',
        wizard_biography_required_label: 'Biography is required!',
        wizard_practice_details_org_name_label: 'Organization name*',
        wizard_practice_details_address_label: 'Address*',
        wizard_practice_details_website_label: 'Website',
        wizard_practice_details_org_name_required_label: 'Organization Name is Required!',
        wizard_practice_details_uin_label: 'Unique Identifier Number*',
        wizard_practice_details_uin_required_label: "UIN is required!",
        name_required_label: 'Name is Required!',
        bgn:"Bulgarian Lev",
        euro:"Euro",
        lek:"Albanian Lek",
        gbp:"British Pound Sterling",
        usd:"United States Dollar",
        organisation_already_registered : "Organisation is Already Registered!",
        wizard_practice_details_address_required_label: 'Address is Required!',
        wizard_practice_details_label: 'Practice Details*',
        wizard_practice_details_required_label: 'Practice Details are required!',
        wizard_upload_documents_title: 'Drag & Drop your Document Below / Click Below to Select File',
        wizard_upload_documents_unsupported_file_message: 'Please Remove all Unsupported Files!',
        wizard_upload_documents_dropzone_label: 'JPG, PNG, PDF, DOC, DOCX, HTML, TXT, CSV and Smaller than 10MB',
        wizard_upload_documents_file_error_message: 'File Type Not Accepted / File exceeds Maximum Size',
        wizard_upload_documents_required_label: 'Documents are Required!',
        wizard_city_required_label: 'City is Required!',
        wizard_phone_numbers_label: 'Phone Numbers*',
        phone_numbers_label: 'Phone Numbers',
        wizard_phone_numbers_required_label: 'Phone Numbers are Required!',
        wizard_provider_image_label: 'Upload your image',
        wizard_back_label: 'Back',
        wizard_continue_label: 'Continue',
        wizard_completed_modal_title: 'Registration Completed!',
        wizard_completed_modal_message: 'Thank you for registering to the Medrec:M Clinic! Your submitted data will be reviewed shortly. Meanwhile you can navigate through the Medrec:M Clinic and get familiar with it. Thank you!',
        wizard_back_modal_title: 'Registration',
        wizard_back_modal_message: 'Are you sure you want to go back?',
        obstetrics_gynecology: 'Obstetrics and Gynecology',
        price_list_for: 'Price List For ',
        schedule_for: 'Schedule For ',
        allergology: 'Allergology',
        angiology: 'Angiology',
        venereology: 'Venereology',
        internal_medicine: 'Internal Medicine',
        gastroenterology: 'Gastroenterology',
        thoracic_surgery: 'Thoracic surgery',
        dental_medicine: 'Dental Medicine',
        dermatology: 'Dermatology',
        pediatric_gastroenterology: 'Pediatric gastroenterology',
        pediatric_endocrinology: 'Pediatric endocrinology',
        pediatric_neurology: 'Pediatric neurology',
        pediatric_nephrology: 'Pediatric nephrology',
        child_psychiatry: 'Child psychiatry',
        pediatric_pulmonology: 'Pediatric pulmonology',
        pediatric_rheumatology: 'Pediatric rheumatology',
        endocrinology: 'Endocrinology',
        immunology: 'Immunology',
        cardiology: 'Cardiology',
        cardiac_surgery: 'Cardiac surgery',
        clinical_genetics: 'Clinical genetics',
        speech_therapy: 'Speech therapy_tab',
        mammology: 'Mammology',
        microbiology: 'Microbiology',
        neurology: 'Neurology',
        neurological_surgery: 'Neurological Surgery',
        neonatology: 'Neonatology',
        nephrology: 'Nephrology',
        nuclear_medicine: 'Nuclear medicine',
        imaging_diagnostics: 'Imaging diagnostics',
        general_medicine: 'General medicine',
        oncology: 'Oncology',
        orthodontics: 'Orthodontics',
        orthopedics_traumatology: 'Orthopedics and Traumatology',
        otoneurology: 'Otoneurology',
        ophthalmology: 'Ophthalmology',
        pediatrics: 'Pediatrics',
        psychiatry: 'Psychiatry',
        psychology: 'Psychology',
        psychotherapy: 'Psychotherapy',
        pulmonology: 'Pulmonology',
        rheumatology: 'Rheumatology',
        vascular_surgery: 'Vascular Surgery',
        ent: 'ENT',
        urology: 'Urology',
        physical_medicine_rehabilitation: 'Physical Medicine and Rehabilitation',
        hematology: 'Hematology',
        surgery: 'Surgery',
        homeopathy: 'Homeopathy',
        payment_initial: "Not paid",
        payment_initial_consultation: "Not paid",
        payment_in_progress: "Payment in progress",
        payment_in_progress_consultation: "Payment in progress",
        payment_rejected: "Not Paid",
        payment_rejected_consultation: "Not Paid",
        payment_completed: "Paid",
        payment_completed_consultation: "Paid",
        accept_appointment_confirmation_header: "Are you sure you want to approve {fullname}'s appointment for {date}?",
        accept_appointment_confirmation_body: "This appointment will be approved immediately.",
        accept_appointment_label: "Approve",
        table_header_morning_label:"Morning",
        table_header_afternoon_label:"Afternoon",
        table_header_evening_label:"Evening",
        table_header_night_label:"Night",
        pulse_pressure_label: "Pulse pressure",
        mean_arterial_pressure_label: "Mean arterial pressure",
        table_header_min_label:"Minimal",
        table_header_max_label:"Maximal",
        table_header_avg_label:"Average",
        table_header_low_label:"Low",
        table_header_normal_label:"Normal",
        table_header_high_label:"High",
        last_7_days:"Last 7 days",
        last_14_days:"Last 14 days",
        last_30_days:"Last 30 days",
        last_90_days:"Last 90 days",
        daily_avg_title: "Daily averages",
        min_max_avg_title: "Minimal, maximal and average",
        category_counts_title: "Counts by category",
        total_count_measurements_label: "Total number of measurements",
        classification_category_label: "Category",
        category_low: 'Low',
        category_optimal: 'Optimal',
        category_normal: 'Normal',
        category_normal_high: 'High Normal',
        category_grade_1: 'Hypertension Grade I',
        category_grade_2: 'Hypertension Grade II',
        category_grade_3: 'Hypertension Grade III',
        category_elevated: 'Elevated',
        category_stage_1: 'Hypertension Stage 1',
        category_stage_2: 'Hypertension Stage 2',
        category_hypertensive_crysis: 'Hypertensive Crysis',
        category_prehypertension: 'Prehypertension',
        category_stage_3: 'Hypertension Stage 3',
        category_high: 'High',
        cardiac_tab_label: 'Cardiac',
        medications_tab_label: "Medications",
        adherence_tab_label: "Adherence",
        email_required_label: "E-mail*",
        contact_email:"Contact E-mail",
        register_form_password_confirm_required_message: "Password confirmation is required",
        country_required_label: "Country*",
        city_required_label: "City*",
        password_required_label: "Password*",
        just_now_label: 'Just now',
        dictate: "Dictate",
        stop_dictating: "Stop Dictating",
        add_prescription: "Add New Medication",
        add_medication: "Add Medication",
        update_prescription: "Edit Medication",
        medication_name_label: "Medication name",
        unit_label: "Unit",
        dose_label: "Dose",
        days_between_intake: "Days between intake",
        days: "Days",
        every: "Every",
        number_times_per_day: "Number of times per day",
        number_of_days: "Number of Days",
        schedule: "Schedule",
        duration: "Duration",
        frequency: "Frequency",
        frequency_times: "Intake number",
        frequency_hours: "Number of hours",
        frequency_times_day: "X times a day",
        frequency_every_hours: "Every X hours",
        take: "Take",
        rest: "Rest",
        cycle_day: "Cycle day ",
        unit_type_ampule: "ampule(s)",
        unit_type_application: "application(s)",
        unit_type_capsule: "capsule(s)",
        unit_type_drop: "drop(s)",
        unit_type_gram: "g(s)",
        unit_type_injection: "injection(s)",
        unit_type_mg: "mg(s)",
        unit_type_ml: "ml(s)",
        unit_type_packet: "sachet(s)",
        reset_btn_label: "Reset",
        unit_type_patch: "patch(es)",
        unit_type_piece: "piece(s)",
        unit_type_tablet: "tablet(s)",
        unit_type_puff: "puff(s)",
        unit_type_spray: "spray(s)",
        unit_type_suppository: "suppository(ies)",
        unit_type_tbsp: "tbsp(s)",
        unit_type_tsp: "tsp(s)",
        unit_type_unit: "unit(s)",
        no_label: "No",
        start_date_label: "Start date",
        end_date_label: "End date",
        delete_prescription: "Delete Medication",
        delete_prescription_message: "Are you sure you want to delete this medication?",
        blocked: "Block messages from this patient",
        unblocked: "Allow messages from this patient",
        default: "Automatic control",
        prescriptions: "Medications",
        version: "Version",
        create_new_patient_btn: "Create new Patient",
        create_and_register_new_patient_btn: "Create and Register",
        create_and_register_new_patient_label: "Create and Register new Patient",
        fullname_label:"Name",
        phone_label:"Phone",
        phone_number_required: "Phone*",
        phone_number_required_error: "Phone is required!",
        appointment_table_provider_label:"With",
        first_available_hour_label: "Early Appointment",
        book_label: "Book first available hour",
        select_first_available_hour: "Pick first available hour",
        select_appointment_type_message: "Please, select the appointment type in order to see the available hours",
        no_hours_available_primary_label: "No hours available",
        appointment_created:"You have successfully created a new appointment.",
        create_appointment: "Create Appointment",
        price_list_is_empty: "Empty price list!",
        choose_clinician_modal_title:"Choose a clinician for this appointment",
        latest_registrations: "Latest registrations",
        clinicians_label: "Clinicians",
        total_amount: "Total",
        amount: "Amount",
        accounting_menu: "Reports",
        accounting_page_title:"Reports",
        login_form_temp_locked_down_message: "Your account has been temporarily locked due to repeated failed log in attempts. Please wait for several minutes before trying to log in again.",
        login_form_must_reset_password_message: "Your account is locked due to repeated failed log in attempts. You can unlock it by resetting your password using the Forgot password function.",
        appointments_configuration:"Appointments configuration",
        auto_approve_appointments_label:"Automatically approve new appointments",
        start_video:"Start Video",
        stop_video:"Stop Video",
        no_video:"No Video",
        no_audio:"No Audio",
        mute:"Mute",
        unmute:"Unmute",
        join_now: "Join Now",
        enter_examination_room: "Enter Examination Room",
        entering_examination_room: "Entering Examination Room",
        audio:"Audio",
        audio_input:"Audio Input",
        video:"Video",
        video_input:"Video Input",
        audio_and_video_settings: "Audio and Video Settings",
        no_local_audio: "No Local Audio",
        done_btn:"Done",
        no_local_video:"No Local Video",
        You:"You",
        patient_label: "Patient",
        clinician_label: "Clinician",
        free_label:"Free",
        paid_label:"Paid",
        independent_timetable:"Independent schedule",
        create_new_schedule :"New schedule",
        time_on_button : "Time on",
        time_off_button :"Time off",
        zoom_in_schedule: "Zoom in schedule",
        zoom_out_schedule: "Zoom out schedule",
        delete_time_off_description: "Do you want to remove this Time Off period from the schedule?",
        create_new_busy_slot_description: "Do you want to add this Time Off period to the schedule?",
        choose_action_timeslot_or_calendar: "Choose between adding a Time Off or creating a new appointment for this period",
        mark_time_off: "Add Time Off",
        select_appointment_type_message_for_schedule: "Select an appointment",
        appointment_creation_failed: "Server error occurred while creating a new appointment!",
        appointment_update_failed: "Server error occurred while updating the appointment!",
        appointment_starting_time_unavailable: "Starting time is used by another appointment!",
        date_of_payment: "Payment date",
        date_of_appointment: "Appointment date",
        camera_permissions_denied_heading:"Unable to Access Media:",
        camera_permissions_denied_message:"The user has denied permission to use video. Please grant permission to the browser to access the camera.",
        microphone_permissions_denied_heading:"Unable to Access Media:",
        microphone_permissions_denied_message:'The user has denied permission to use audio. Please grant permission to the browser to access the microphone.',
        not_allowed_error_heading:'Unable to Access Media:',
        not_allowed_error_message:'The operating system has blocked the browser from accessing the microphone or camera. Please check your operating system settings.',
        not_allowed_error_message_alt:'The user has denied permission to use audio and video. Please grant permission to the browser to access the microphone and camera.',
        not_found_error_heading:'Cannot Find Microphone or Camera:',
        not_found_error_message:'The browser cannot access the microphone or camera. Please make sure all input devices are connected and enabled.',
        connection_error_heading:'Connection Error:',
        connection_error_message:'Media connection failed or Media activity ceased. Please, rejoin the room and wait for the other participant to join.',
        error_acquiring_media_heading:'Error Acquiring Media:',
        no_camera_or_microphone_detected_heading:'No Camera or Microphone Detected:',
        no_camera_or_microphone_detected_message:'Other participants in the room will be unable to see and hear you.',
        no_camera_detected_heading:'No Camera Detected:',
        no_camera_detected_message:'Other participants in the room will be unable to see you.',
        no_microphone_detected_heading:'No Microphone Detected:',
        no_microphone_detected_message:'Other participants in the room will be unable to hear you.',
        account_closed:"This user removed their account!",
        create_template:"Create Template",
        template_name_label:"Template name",
        from_prescription_template: "Create Medication from Your Templates",
        pick_prescription_template: "Medication Templates",
        search_templates: "Search Templates",
        pick_prescription_template_message:"Please pick a template from your Medication Templates",
        save_prescription_prerequisite_description:"Please save the examination result in order to add medications, examination reports, prescriptions, or other documents.",
        please_wait_for_the_other_participants_to_join:"Waiting for other participants to join the room",
        peak_flow:"Peak Flow",
        liters_per_minute: "L/min",
        peak_flow_inhaler_label: "Used reliever inhaler",
        peak_flow_asthma_symptoms_label: "Had asthma symptoms such as shortness of breath, tight chest, coughing or wheezing",
        peak_flow_night_asthma_symptoms_label: "Waking at night with asthma symptoms",
        peak_flow_daily_activity_label: "Felt like couldn’t keep up with normal day-to-day activities",
        inhaler_is_used: "Used reliever inhaler",
        had_asthma_symptoms: "Had asthma symptoms such as shortness of breath, tight chest, coughing or wheezing",
        had_night_asthma_symptoms: "Waking at night with asthma symptoms",
        cannot_keep_daily_activities: "Felt like couldn’t keep up with normal day-to-day activities",
        questionnaire:"Questionnaire",
        occupied_seats: "Occupied seats",
        max_number_participants_label: "Maximum number of patients",
        payment_label: "Payment",
        group_appointment: "Group Appointment",
        start_video_call: "Start Video Call",
        invalid_date_format: "Invalid Date Format",
        invalid_time_format: "Invalid Time Format",
        provider_documents: "Uploaded Documents",
        document_delete_label: "will be deleted (this action cannot be undone).",
        days_before_appointment_label:"Number of days before the appointment date",
        end_hour_label:"End time for booking an appointment",
        end_time_input_label:"End time",
        start_time_input_label:"Start time",
        end_time_for_appointment_booking:"Deadline to booking an appointment",
        avg_by_category:"Averages by category",
        cardiac_report_title:"Blood Pressure Report",
        download_cardiac_report: "Download Blood Pressure Report",
        two_fa_code: "Login verification code",
        two_fa_code_explanation:"You can change your two-factor authentication method or turn it off from the Security page.",
        code_2fa_mismatch:"The login verification code is not correct!",
        two_fa_current_method:"Your current two-factor authentication method is ",
        two_fa_state_off:"Deactivated",
        two_fa_state_not_set: "Not set",
        email_2fa_sent:"Check your email for a new login verification code!",
        send_2fa_email:"Click here to send a new login verification code to my email",
        authenticator_label:"Authentication App",
        two_fa_off:"Deactivate",
        select_2fa_method_step_1:"Choose your two-factor authentication method",
        clinician_change_2fa_modal_header:"Two-factor authentication",
        email_two_fa_selector:"Receive an e-mail with login verification code to enter every time you log in to Medrec:M Clinic",
        totp_two_fa_selector:"Use a mobile authentication app to get a login verification code to enter every time you log in to Medrec:M Clinic",
        turn_off_two_fa_selector:"Turn off two-factor authentication (not recommended)",
        email_two_fa_step_1:"When you log in to your account on Medrec:M Clinic, a six-symbol login verification code will be sent to your e-mail to use during login. Click \"Activate\" to turn on this two-factor authentication method.",
        activate_btn:"Activate",
        verify_btn:"Verify",
        two_fa_method_successfully_deactivated: "The two-factor authentication is turned off.",
        two_fa_method_totp_successfully_activated:"The two-factor authentication via mobile authentication app is turned on.",
        two_fa_method_email_successfully_activated:"The two-factor authentication via e-mail is turned on.",
        print_btn:"Print",
        two_fa_warning_off: "Your account is more secure when you need a password and a login verification code to sign in. If you remove this extra layer of security, you will only be asked for a password when you sign in. It might be easier for someone to break into your account.",
        verify_totp_input: "Using the authentication app, generate a login verification code, enter the 6-digit login verification code into the field, and press Verify to confirm. If the login security code is correct, you’ll be redirected to the next step, otherwise you’ll be asked to enter a new login verification code.",
        recovery_codes_description: "Print or download these single-use backup verification codes. These codes can be used instead of a login verification code to log into your account in case you don't have access to your authentication app.",
        two_fa_totp_qr_description: "Link your authentication app to your Medrec:M Clinic account by scanning the QR code or entering the activation code manually.(If you don’t already have an app installed on your device, you’ll need to download one. You can use any time-based one time password (TOTP) authentication app like Google Authenticator, Authy, Duo Mobile, 1Password, etc.)",
        processing: "Processing, please wait ...",
        security_page_title: "Security",
        security_label:"Security",
        continue_btn:"Continue",
        change_btn:"Change",
        login_verification_code_by_email:"Please, check your email for an 8 digit login verification code and type it in the field below",
        login_verification_code_by_totp:"Please, open your Authenticator app, find the 6 digit login verification code and type it in the field below",
        additional_results:"Additional Tests and Procedures",
        pick_test_results_template:"Pick Additional Tests and Procedures",
        pick_test_template:"Pick Additional Tests and Procedures",
        delete_test_results:"Delete",
        delete_test_results_message:"Are you sure you want to delete these Additional Tests and Procedures?",
        description: "Description",
        disclaimer: "Additional conditions (must be accepted by patient)",
        duration_range_picker_label:"Reporting period",
        pf_norm: "Average",
        pf_norm_stddev: "StdDev",
        pf_norm_count: "Count",
        pf_norm_max: "Maximum",
        pf_norm_standard: "Standard",
        pf_norm_title: "Peak Flow Normal Values",
        peak_flow_weekly_diary:"Peak Flow Weekly Diary",
        not_selected_value_label: "Not Selected",
        not_selected_value_feminine_noun_gender_label: "Not Selected",
        yes_value_label: "Yes",
        no_value_label: "No",
        main_examination_result_fields_are_empty: "The main Examination Result fields are empty",
        mark_as_paid_appointment_confirmation_header: "Are you sure you want to mark as paid {fullname}'s appointment for {date}?",
        mark_appointment_as_paid_btn: "Mark as Paid",
        mark_as_paid__appointment_confirmation_body: "Use this option if the patient has paid for their appointment outside the Medrec:M platform.",
        irreversible_action:"This action is irreversible!",
        peak_flow_norm:"Peak Flow Norm (L/min)",
        consultations_menu_label: "Text Consultations",
        consultations_dashboard: "Active Text Consultations",
        consultations_page_title: "Text Consultations",
        no_active_consultations: "No Active Text Consultations",
        no_consultations: "No Text Consultations",
        active_consultations_label: "Active Consultations",
        all_consultations_label: "All Consultations",
        urgent_consultations:"New",
        uncompleted_consultations:"Uncompleted",
        completed_consultations: "Completed",
        last_message: "%1 wrote",
        duration_d_h_m: "%1 days, %2h %3min",
        duration_h_m: "%1h %2min",
        duration_m: "%1 minutes",
        expires_after: "Expires after: ",
        expires_on: "Reply before ",
        remaining: "Remaining ",
        last_modified: "Last modified: ",
        text_consultation: "Text consultation",
        confirm_note_visible_to_patient_modal_header:"Confirm Message Visibility",
        confirm_note_visible_to_patient_modal_body:"This message will become visible to the patient immediately after saving it!",
        confirm_note_visible_to_patient_modal_body_2:"Please, choose the  \"Confirm\" button to save the message, else choose the \"Close\" button to continue editing.",
        complete_text_button_missing_explanation: "This appointment can't be completed until the patient pays for it.",
        unpaid_video_explanation: "You can start the video call when the patient pays for this appointment.",
        note_visible_to_patient_visibility_form_warning:"This message will become visible to the patient!",
        more: "More",
        less: "Less",
        range_restriction:"The valid values are between %1 and %2.",
        overdue_modal_body:"We are sorry but the time to respond to this text consultation has ran out. The consultation will be automatically closed.",
        warning_title:"Warning!",
        overdue_will_be_canceled: "This consultation is overdue and will be canceled automatically.",
        end_before_start_warning: "The start time must be before the end time!",
        id_type_egn: "Personal ID",
        id_type_lnz: "Personal foreigner ID",
        id_type_ssn: "SSN",
        id_type_pass: "Passport",
        id_type_other: "Other",
        attach_provider_document_from_report:"Create and attach a document",
        document_picker_title:"Generate Document",
        choose_document:"Choose what type of document you want to generate from the list below.",
        med_report_subject_field_label: "Subject",
        epicrisis_report_purpose: "Document purpose",
        encounter_outcome_document_no_field_label:"Document №",
        packs: "packs",
        pack: "pack",
        quantity: "Quantity",
        medication_form: "Medication Form",
        quantity_by_form: "Quantity by Form",
        instructions: "Instructions for use",
        invalid_value: "Invalid value",
        MORNING:"Morning",
        EARLY_MORNING:"Early Morning",
        LATE_MORNING:"Late Morning",
        NOON:"Noon",
        AFTERNOON:"Afternoon",
        EARLY_AFTERNOON:"Early Afternoon",
        LATE_AFTERNOON:"Late Afternoon",
        EVENING:"Evening",
        EARLY_EVENING:"Early Evening",
        LATE_EVENING:"Late Evening",
        NIGHT:"Night",
        AFTER_SLEEP:"After Sleep",
        BEFORE_SLEEP:"Before Sleep",
        UPON_WAKING:"Upon Waking",
        WITH_MEAL:"With Meal",
        WITH_BREAKFAST:"With Breakfast",
        WITH_LUNCH:"With Lunch",
        WITH_DINNER:"With Dinner",
        BEFORE_MEAL:"Before Meal",
        BEFORE_BREAKFAST:"Before Breakfast",
        BEFORE_LUNCH:"Before Lunch",
        BEFORE_DINNER:"Before Dinner",
        AFTER_MEAL:"After Meal",
        AFTER_BREAKFAST:"After Breakfast",
        AFTER_LUNCH:"After Lunch",
        AFTER_DINNER:"After Dinner",
        jump_to_date: "Choose available date",
        reschedule: "Reschedule",
        confirm_update_appointment_body:'Select "Save" to save your changes',
        chat_configuration:"Chat configuration",
        lock_chat_input_label: "Lock chats",
        auto_chat_input_label: "Auto control",
        schedule_chat_input_label: "Use schedule for unlock",
        private_price_input_label: "Disallow selection by patients",
        group_appointment_info_text: "Group appointments are performed with more than one patient. Group appointments are not included in work time schedules. Available hour for group appointment is assigned via the Administrative portal. On creation the maximum allowed number of patients to participate is required.",
        private_price_info_text: "This appointment is not available for patients unless you send them a direct link. Appointments can also be booked via Administrative portal.",
        allow_switch_to_online_info_text: "This is available only for on site appointments with online payment. Patients are allowed to change the location of booked appointment from onsite to online. In the Settings you can define how mush time prior ot the appointment patients can change the location. The default configuration is not later than 4 hours prior the appointment.\n",
        payment_info_text: "The following payment options are availabe for on site appointments:\n" +
            "• Online – can be paid only online and if not paid in required terms the appointment will be automatically canceled. Payment is due within half an hour if booked for the following 24 hours and up to 24 hours prior to booked hour if later hour is selected.\n" +
            "• On site – can be paid only during the visit on site.. You need to mark this appointment as paid in the system.\n" +
            "• Online or on site  – there is no due date for payment and no cancelation. Can be paid online or you will be able to mark this appointment as paid if paid during the visit on site.",
        independent_schedule_info_text: "The available hours to book appointment with independent schedule are calculated without considering already booked appointment in the same time range.",
        create_and_register_new_patient_info_text: "By completing this registration form you create a child's profile in the system and register it in your Clinic. A parent's / guardian's contact information is required, with which a parent's profile will also be created.\n" +
            "If an email is provided the parent will receive an email with login credentails and will be able to manage both profiles (parent/child) – log data, book appointments, attach laboratory results etc.\n" +
            "In case the parent already has a profile, select 'Existing profile' and and fill in the PIN of the parent. With this you will link the child profile to the already existing parent profile in the system.",
        create_and_register_new_patient_adult_info_text: "By completing this registration form you create an adult's profile in the system and register it in your Clinic. The patient will receive an email with login credentails and will be able to manage both parent and child profiles – data log, book appointments, attach laboratory results etc.",
        create_new_schedule_info_text: "Select existing schedule from the calendar to edit.",
        new_schedule_form_info_text:"Create schedule (time on/time off) for appointments by selecting the active days of week and time range.",
        private_range_info_text:"Patients will not be able to book these hours. Booking is via the Administrative portal.",
        organisation_schedule_info_text:"Select organisation, where the appointment is performed.",
        location_schedule_info_text:"Select location to perform the appointment – online or onsite.",
        holiday_info_text:"Appointment hours from any schedule are not available for patients in the holiday days.",
        download_report_info_text:"Generation of Examination report, Prescription, Epicrisis, medical report from saved examination results.",
        template_info_text:"Creates a template to use to add the same medication.",
        auto_approve_info_text:"Select how to confirm appointments - manually or automatically. Unconfirmed appointments cannot be paid by patients.",
        auto_chat_info_text:"Patients will be able to send you chat-messages:\n" +
            "• when they have a booked appointment;\n" +
            "• up to 14 days after appointment is completed.",
        lock_chat_info_text:"Patients will not be able to send chat-messages.",
        schedule_unlock_info_text:"Select the days of the week and time range, in which chat-messages will be allowed.",
        permanent_link_button: "Direct link",
        permanent_link_explanation: "Copy this link and send it to a patient to pick the appointment time:",
        copy_to_clipboard:"Copy to clipboard",
        link_copied_toast:"Copied",
        contact_information_title: "Contact Information",
        child_button_label: "Child",
        adult_button_label: "Adult",
        email_or_phone_required:"At least one of the fields E-mail or Telephone needs not to be empty!",
        patients_management_label: "Patient Management",
        paid_online_only:"Only Paid Online",
        paid_outside_medrec:"paid on site",
        select_clinicians_to_see_schedule: "No selected clinicians",
        user_not_found:"User not found!",
        new_contact_btn:"New Contact",
        existing_contact_btn:"Existing Contact",
        appointment_filter_all: "All",
        appointment_filter_completed: "Completed appointments",
        appointment_filter_pending: "Pending appointments",
        appointment_filter_active_consults: "Active consultations",
        private_range_input_label:"Unavailable to patients",
        appointment_on:'appointment "%1" on',
        accounting_appointment_status_canceled:"canceled",
        edit_contact_information_btn: "Edit Contact Information",
        add_another_child_btn: "Add another child for this contact",
        order_index_label:"Index number in the list",
        allergen:"Allergen",
        confirm_delete_chronic_condition_modal_body:"Are you sure you want to delete this chronic condition?",
        confirm_delete_allergy_modal_body:"Are you sure you want to delete this allergy?",
        diagnose_date: "Date Diagnosed",
        edit_chronic_condition: "Edit Chronic Condition",
        new_chronic_condition: "New Chronic Condition",
        chronic_disease: "Chronic Condition",
        chronic_disease_required_label: "Name is required field!",
        allergen_required_label: "Allergen is required field!",
        add_button: "Add",
        new_allergy: "New Allergy",
        allergy_type: "Allergy Type",
        allergy_severity: "Allergy Severity",
        ICB10_classification: "Classification (ICB-10)",
        allergy_medications:"Medications",
        edit_allergy: "Edit Allergy",
        new_text_consultations_popup_body:"You have <span class='text-danger'>%1</span> new text consultations.",
        new_text_consultations_popup_body_singular:"You have <span class='text-danger'>%1</span> new text consultation.",
        new_text_consultations_popup_body_2:"Please, respond before <span class='text-danger'>%1</span> to the earliest expiring one.",
        new_text_consultations_popup_body_2_singular:"Please, respond before <span class='text-danger'>%1</span>, otherwise, the consultation will be canceled.",
        open_consultations:"Open Text Consultations",
        earliest_expiring_consultation:"The earliest expiring text consultation",
        year:"year",
        years:"years",
        history:"History",
        period_title:"Period",
        cycle_title:"Cycle",
        cycle_day_label:"Cycle day",
        average_label:"Average",
        last_cycle_length_label:"Last cycle length",
        last_period_length_label:"Last period length",
        shortest_male_label:"Shortest",
        longest_male_label:"Longest",
        shortest_female_label:"Shortest",
        longest_female_label:"Longest",
        download_menstruation_report:"Download Menstruation Report",
        menstruation_report: "Menstruation Report",
        hint_examination_tests:"Use new line to separate multiple tests.",
        id_type_newborn: "Newborn",
        gender_undefined: "Undefined",
        no_option_found: "No Options Found",
        no_medications_for_prescription_report_body:"There are no prescribed medications or they are all included in E-prescriptions!",
        prescription_report_cant_create:"The prescription can't be empty!",
        add_medical_notice:"Add Medical Notice",
        force_create_appointment: "Ignore checking for an existing appointment at the selected time",
        nonmedical_personnel:"Non-medical personnel",
        ORG_ROLE_ADMIN:"Administrator",
        ORG_ROLE_CLINICIAN:"Clinician",
        ORG_ROLE_REGISTRATION_DESK:"Registration Desk",
        ORG_ROLE_CONSULTING_CLINICIAN:"Consulting Clinician",
        ORG_ROLE_LAB_ASSISTANT:"Lab Assistant",
        ORG_ROLE_CUSTOM:"Other",
        register_choose_registration_type: "Choose whether to create a clinician profile on the administrative account.",
        register_clinician_type_chosen: "The selected option will create a clinician profile with administrative rights for the clinic. Suitable for individual practices or practices where you will conduct the examinations.",
        register_other_type_chosen: "The selected option will create an administrative profile without the right to conduct examinations. To start conducting examinations, you will need to register additional doctors in the clinic from the Staff menu.",
        register_internal_unselected_option_text: "Clinician profile",
        register_internal_selected_option_text: "Administrative profile",
        invitation_page_title:"Welcome",
        invite_page_info_message:"You are invited to join %1.",
        existing_user_invitation_accept_header: "If you have an account at Medrec:M Clinic, enter your credentials below",
        new_user_user_invitation_accept_header: "If you don't have an account click the button below to join %1 with a new account.",
        role_mismatch_error_message:"Operation failed due to role mismatch!",
        account_type_mismatch_error_message:"Operation failed due to account type mismatch!",
        switch_practice_menu_item:"Switch Practice",
        email_does_not_match_invitation:"Your email does not match the invitation email",
        invitation_not_found_or_invalid:"Invitation not found or invalid.",
        staff_management: {
            add_new_staff_member_title: "Create new staff member",
            invite_new_staff_member_title: "Invite new staff member",
            give_administrative_rights: "Administrative rights",
            is_non_medical_staff: "Is non-medical (does not have appointments)?",
            is_visible_to_patients: "Is visible to patients?",
            has_a_role: "Has the following role",
            buttons: {
                add_new_staff_member: "Create new",
                invite_new_staff_member: "Send invitation",
                manage: "Change role",
                edit: "Edit",
                active_invitations: "Active Invitations"
            },
            invitation_delete_body:"Are you sure you want to delete the invitation for %1, %2?",
            invitation_delete_modal_title:"Delete Invitation",
            no_active_invitations:"No active invitations",
            cant_remove_admin: "The organization must have at least one administrator!",
            lose_staff_management_rights: "The selected option will remove your rights to manage staff data - " +
                "administrative rights, role, patient visibility, personal data, and others, " +
                "as well as to add new members or edit existing staff members!"
        },
        nhis: {
            delete_prescription:"Delete E-prescription",
            delete_prescription_message:"Are you sure you want to delete this E-prescription?",
            processing_body:"Please, wait...",
            sign_error:"Error while signing! Please, check whether the signature is plugged into your computer and whether the Medrec:M Sign Server is running.",
            service_error:"Nhis service error! Please, try again later!",
            nhis_specialties_search:"NHIS specialties",
            remove_supplement_modal_message: "Are you sure you want to delete the non-medicinal product?",
            remove_medication_modal_message: "Are you sure you want to delete this medication?",
            from_medication_template: "Choose medication template",
            pick_medication_template: "Medication templates",
            pick_medication_template_message: "Please choose medication template from the list below",
            supplement_label:"Describe in free text",
            from_prescription_template: "Create E-prescription from template",
            create_template_label: "Create E-prescription template",
            create_prescription_label: "Create E-prescription",
            pick_prescription_template: "E-prescription templates",
            pick_prescription_template_message: "Please choose E-prescription template from the list below",
            template_name_label: "Template name",
            successfully_created_and_sent_to_nhis: "The E-prescription is issued with NRN number",
            confirm_exit_body: "Are you sure you want to exit? All changes will be lost.",
            search_placeholder: "Search...",
            prescription_based_on_label: "Examination NRN for this E-prescription",
            prescription_number_of_executions_label: "Number of executions",
            prescription_category_label: "E-prescription type",
            is_protocol_based_label: "Based on protocol",
            prescription_protocol_number_label: "Protocol number",
            prescription_protocol_date_label: "Protocol issue date",
            prescription_rhif_number_label: "RHIF district",
            no_label: "NO",
            yes_label: "YES",
            select: "Choose...",
            unlimited: "Unlimited",
            supplement: "Non-medicinal products",
            revoke_reason:"Revocation reason",
            confirm_delete_dosage_instruction: "Are you sure you want to delete this dosage instruction?",
            error_loading_nomenclatures:"Error while loading nomenclatures!",
            loading_data_message:"Loading...",
            try_again:"Try again",
            send_to_nhis:"Send to NHIS",
            send_correction_to_nhis:"Update at NHIS",
            annul_nhis:"Cancel at NHIS",
            tab: {
                group: "Group",
                patient: "Patient",
                prescription: "E-prescription",
                medications: "Medications",
            },
            btn: {
                leave_prescription: "Exit",
                confirm: "Confirm",
                cancel: "Cancel",
                save: "Save",
                next: "Next",
                add: "Add",
                add_medication: "Add medication",
                add_supplement: "Add non-medicinal product",
                complete: "Issue the E-prescription",
                complete_exam: "Issue the E-examination",
                complete_med_notice: "Issue the Medical Notice",
                delete_dosage_instruction: "Delete this dosage instruction",
                convert_to_nhis_exam:"Convert to NHIS E-examination",
                complete_ereferral:"Issue the E-Referral",
                check_ereferral_results:"Check results in NHIS"
            },
            patient: {
                title: "Patient",
                first_name_label: "First Name",
                last_name_label: "Family Name",
                personal_id: "Identifier",
                county:"County",
                idType:"Id Type",
                ekatte:"EKATTE"
            },
            provider: {
                medical_practitioner_practice: "Medical Specialist / Practice",
                pmi: "UIN",
                practiceNumber: "Practice UIN",
                qualification: "Specialty",
                phone: "Phone number",
                rhifAreaNumber: "Clinician RHIF area"
            },
            medication: {
                create_template_label:"Create template from this medicine",
                template_name_label:"Template name",
                icb_label: "ICD code",
                medication: "Medicine",
                form_label: "Medicine form",
                quantity_label: "Prescribed quantity",
                is_quantity_by_form_off_label: "Pack",
                is_quantity_by_form_on_label: "Medicine Form",
                is_substitution_allowed_label: "Substitution is allowed",
                priority_label: "Issue priority",
                effective_dose_period_start: "Intake start date",
                note_for_pharmacists_label: "Instructions for the Pharmacy"
            },
            dosage_instr: {
                title: "Dosage",
                is_as_needed_label: "As needed",
                intake_frequency_label: "Frequency",
                intake_period_label: "every",
                quantity_for_single_intake_label: "Single intake quantity",
                dose_quantity_code_label: "Form",
                intake_route_label: "Intake route",
                intake_when_label: "Time or event related to the intake",
                intake_offset_in_minutes_label: "Interval in minutes regarding the time or event",
                intake_bounds_duration_label: "Duration",
                bounds_duration_unit_label: "For time period",
                note_label: "Intake text instructions",
                form_label: "Medication Form",
                unit_label: "Unit of Measurement",
                doseQuantityCodeInputPlaceholder: 'for example "ml"'
            },
            examination: {
                choose_exam_type_table_heading: "Choose whether the examination results should be sent to the National Health Information System (NHIS) or remain only in the Medrec:M system.",
                exam_type_nhis_description: "Select this option if you will be sending the examination results to the National Health Information System (NHIS). Choosing this option is mandatory for issuing medical certificates.",
                exam_type_medrec_m_description: "Select this option if you will not be sending the examination results to the National Health Information System (NHIS). You can convert to the NHIS option at a later stage.",
                basedOn: "Document number or previous examination number",
                directedBy: "Directed By",
                classification: "E-examination type",
                purpose: "Purpose",
                incidental_visit_label: "Incidental visit",
                is_secondary_label: "Secondary Examination",
                financingSource: "Financing Source",
                rhifAreaNumber: "RHIF area",
                adverse_conditions_label: "Adverse conditions at the locality",
                is_pregnant_label: "Pregnancy",
                is_breastfeeding_label: "Breastfeeding",
                gestational_week_label: "Gestational week",
                medical_history_label: "Medical history",
                objective_condition_label: "Objective condition",
                conclusion: "Conclusion",
                dischargeDisposition: "Discharge disposition",
                medications:"Medications",
                therapy_notes_label: "Therapy notes",
                therapy_section:"Therapy",
                main_diagnosis: "Main diagnosis",
                icdCode: "Diagnosis",
                additionalIcdCodes: "Additional diagnoses",
                role_label: "Role",
                clinicalStatus: "Clinical status",
                verificationStatus: "Verification status",
                onsetDateTime: "Onset",
                diagnosis_notes_label: "Diagnosis notes",
                additional_diagnoses: "Additional diagnoses",
                has_empty_diagnosis: "This E-examination has empty diagnoses! Please, fill in all diagnoses before sending.",
                e_examination: "NHIS examination",
                examination: "Examination",
                regular_examination: "Medrec:M examination",
                results:"Results",
                tests_label:"Tests",
                successfully_sent_to_nhis: "The E-examination is successfully sent to NHIS.",
                nrn_number: "NRN",
                cancellation_reason:"Cancellation reason",
                annul_success:"The E-examination is successfully canceled!",
                confirm_convert_body:"Warning! After the conversion some data might need to be added again!",
                converted_successfully: "Success! The examination was converted to NHIS E-examination.",
                need_to_edit_before_send:"Before sending to NHIS, edit the examination results and fill in the required fields!",
                need_to_complete_before_send: "You have to complete the examination before you can send the results to NHIS!",
                cancel_modal_header:"Delete E-examination",
                cancel_modal_body:"Are you sure you want to delete this E-examination?",
                remove_diagnosis_modal_message: "Are you sure you want to remove this diagnosis?",
                import_examination_btn:"Search in NHIS",
                import_examination_button_description:"Search in NHIS by NRN or user identifier and examination opening date. The result can be imported into Medrec:M.",
                search_nhis:"Search",
                open_date:"Opening Date",
                close_date:"Closing Date",
                import_btn: "Import",
                import_exam_modal_body: "Are you sure you want to import examination results from NHIS with NRN: %1 from %2?\n\nThe results will be imported into Medrec:M and the appointment will be marked as COMPLETED.",
                import_exam_modal_title: "Import an examination result from NHIS",
                import_server_error: "Server error!",
                import_server_error_conflict: "Can't import results!\n\nThese examination results are already imported and assigned to another appointment!",
                import_action_link: "Import",
                status: "Status",
                status_1:"Open",
                status_2:"Closed",
                status_3:"Canceled"
            },
            ereferral: {
                authoredOn: "Date of issuance",
                add_ereferral: "Create referral",
                ereferrals: "Referrals",
                title: "Referral",
                financingSource: "Source of financing",
                rhifAreaNumber: "RHIF area",
                category: "Referral category",
                type: "Referral type",
                activity: "Assigned medical diagnostic activities",
                qualificationTarget: "Medical specialty the patient is referred to",
                qualificationTargets: "Medical specialties the patient is referred to",
                admissionType: "Type of admission",
                directedBy: "Referred by",
                specializedActivityCode: "Specialized activities or manipulations, or procedures for physical and rehabilitation medicine",
                examType: "Type of examination under NHIF for which the referral for medical expertise is issued",
                workIncapacityReason: "Reason for issuing the referral",
                workIncapacityAddressType: "Referral to RFME by patient address type",
                clinicalPathway: "NHIF clinical pathway",
                outpatientProcedure: "NHIF outpatient procedures the patient is sent for",
                home_visit: "Home visit required",
                employer: "Employer details",
                employer_address: "Employer address",
                employer_name: "Employer name",
                employer_indent_code: "Employer identification code",
                employer_phone: "Employer contact phone",
                employer_email: "Employer contact email",
                employer_country: "Country",
                employer_county: "County",
                employer_ekatte: "EKATTE",
                employer_city: "City",
                employer_line: "Full address",
                employer_postalCode: "Postal code",
                employer_position: "Patient's position at the specified employer",
                documents: "Documents",
                document_date: "Document issuance date",
                document_number: "Document number",
                document_isNrn: "The submitted number is NRN",
                document_description: "Additional document information",
                documentType: "Document type",
                remove_document_modal_message: "Are you sure you want to delete this document?",
                main_diagnoses: "Main guiding diagnoses",
                additionalDiagnoses: "Accompanying diseases",
                note: "Free text notes to the referral from the issuing doctor",
                successfully_sent_to_nhis: "The referral has been successfully sent to NHIS.",
                nrn_number: "NRN",
                cancel_success: "The referral has been successfully cancelled!",
                delete_success: "The referral has been successfully deleted!",
                modal_header_cancel: "Cancel referral",
                modal_header_delete: "Delete referral",
                modal_body_cancel: "Are you sure you want to cancel this referral?",
                modal_body_delete: "Are you sure you want to delete this referral?",
                cancellation_reason: "Reason for cancellation",
                no_execution_results: "No execution results found for this referral.",
                status: "Referral status",
                modal_header_check: "Check results",
                close_ereferral_title:"Close Referral",
                close_ereferral_body:"Please, confirm you want to close this referral. The referral won't be saved!",
                lab_res: {
                    name: "Name",
                    value: "Value",
                    unit: "Unit",
                    reference: "Reference values",
                    under: "under",
                    over: "over",
                    execution_date: "Execution date"
                },
                hospitalization: {
                    status: "Hospitalization status",
                    admissionDate: "Admission date",
                    admissionType: "Admission type",
                    reason: "Reason",
                    severity: "Condition",
                    note: "Notes",
                    dischargeDate: "Discharge/transfer date",
                    outcome: "Outcome",
                    dischargeDisposition: "Referred to",
                    dischargeNote: "Notes on discharge/transfer",
                    daysHospitalized: "Days hospitalized",
                    deceasedDate: "Date of death",
                    autopsyDate: "Autopsy date",
                    workability: "Workability",
                    dischargeDiagnoses: "Final diagnoses upon discharge",
                    comorbidity: "Accompanying diagnoses"
                }
            },
            medicalNotice: {
                modal_header:"Medical Notice",
                medical_notices: "Medical Notices",
                based_on_label:"E-examination NRN",
                reason_label:"Reason",
                location_label:"Treatment location",
                location_view_label:"Treatment location",
                institution_label:"To serve before",
                code_label:"Disease",
                note_label:"Clarifying notes",
                successfully_sent_to_nhis: "The Medical Notice is successfully sent to NHIS.",
                nrn_number:"NRN number",
                issued_on_label: "Issued On",
                for_period:"Period",
                cancellation_reason:"Cancellation reason",
                annul_success:"The Medical Notice is successfully canceled!",
                modal_header_cancel:"Delete Medical Notice",
                modal_body_cancel:"Are you sure you want to delete this Medical Notice?",
            }
        },
        medication: {
            unit: {
                label: {
                    ampule: {
                        display_long: 'ampule',
                        display_short: '',
                        display_long_plural: 'ampules',
                        display_short_plural: ''
                    },
                    application: {
                        display_long: 'application',
                        display_short: '',
                        display_long_plural: 'applications',
                        display_short_plural: '',
                    },
                    capsule: {
                        display_long: 'capsule',
                        display_short: '',
                        display_long_plural: 'capsules',
                        display_short_plural: '',
                    },
                    drop: {
                        display_long: 'drop',
                        display_short: '',
                        display_long_plural: 'drops',
                        display_short_plural: '',
                    },
                    gram: {
                        display_long: 'gram',
                        display_short: 'g',
                        display_long_plural: 'grams',
                        display_short_plural: 'g',
                    },
                    injection: {
                        display_long: 'injection',
                        display_short: '',
                        display_long_plural: 'injections',
                        display_short_plural: '',
                    },
                    mg: {
                        display_long: 'milligram',
                        display_short: 'mg',
                        display_long_plural: 'milligrams',
                        display_short_plural: 'mg',
                    },
                    ml: {
                        display_long: 'milliliter',
                        display_short: 'ml',
                        display_long_plural: 'milliliters',
                        display_short_plural: 'ml'
                    },
                    packet: {
                        display_long: 'sachet',
                        display_short: '',
                        display_long_plural: 'sachets',
                        display_short_plural: ''
                    },
                    patch: {
                        display_long: 'patch',
                        display_short: '',
                        display_long_plural: 'patches',
                        display_short_plural: ''
                    },
                    piece: {
                        display_long: 'piece',
                        display_short: '',
                        display_long_plural: 'pieces',
                        display_short_plural: ''
                    },
                    tablet: {
                        display_long: 'tablet',
                        display_short: '',
                        display_long_plural: 'tablets',
                        display_short_plural: ''
                    },
                    puff: {
                        display_long: 'puff',
                        display_short: '',
                        display_long_plural: 'puffs',
                        display_short_plural: ''
                    },
                    spray: {
                        display_long: 'spray',
                        display_short: '',
                        display_long_plural: 'sprays',
                        display_short_plural: ''
                    },
                    suppository: {
                        display_long: 'suppository',
                        display_short: '',
                        display_long_plural: 'suppositories',
                        display_short_plural: ''
                    },
                    tbsp: {
                        display_long: 'tablespoon',
                        display_short: 'tbsp',
                        display_long_plural: 'tablespoons',
                        display_short_plural: 'tbsp(s)'
                    },
                    tsp: {
                        display_long: 'teaspoon',
                        display_short: 'tsp',
                        display_long_plural: 'teaspoons',
                        display_short_plural: 'tsp(s)'
                    },
                    unit: {
                        display_long: 'unit',
                        display_short: '',
                        display_long_plural: 'units',
                        display_short_plural: ''
                    }
                }
            }
        }
    }
}
