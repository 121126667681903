/* eslint-disable */
import React from 'react'
import {
    CONVERTER,
    getUnitValuefromKey
} from '../../../utils/converter'

export default function MedicationEntry(props) {
    return (
        <div className="card logbook-entry-card">
            <div className='row zero-margin-row'>
                <div className='medrec-grey-2 vitals-logbook-time logbook-entry-time-icon'>
                    <i className='kt-menu__link-icon far fa-clock'/> &nbsp;
                    {CONVERTER.getEntryDateTimeFormat(props.entry.date_time)}
                </div>
                &nbsp;&nbsp;
                {props.entry.name} - {props.entry.dose} {getUnitValuefromKey(props.entry.unit, props.entry.dose)}
            </div>
        </div>
    )
}
