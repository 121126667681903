import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import PropTypes from "prop-types";


class PriceSettingsHeader extends Component {
    state = {
        gridDisabled: false
    }

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='price-settings-card-header'>
                <div className='row space-between-justify'>
                    <h2 className='card-title card-header-title'>{$$('price_settings_label')}</h2>
                    <div className='row patient-view-form-container form-margin'>
                        <input
                            type="button"
                            className="btn btn-primary date-picker-button"
                            value={$$("create_new_consultation")}
                            onClick={() => {
                                this.props.openCreate(true)
                            }}
                        />
                        <input
                            type="button"
                            className="btn btn-primary date-picker-button"
                            value={$$("create_new_appointment")}
                            onClick={() => {
                                this.props.openCreate(false)
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

PriceSettingsHeader.propTypes = {
    openCreate: PropTypes.func
}

export default PriceSettingsHeader
