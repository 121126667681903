import React, {Component} from 'react'
import classNames from 'classnames'
import {$$} from '../../helpers/localization';
import {usersService} from "../../service/users_service"
import {Collapse} from 'react-collapse'
import {
    APPOINTMENTS_FILTER,
    getColorForOption
} from '../../constants/select_options'
import moment from 'moment'
import _ from 'underscore';
import OpenAppointmentButton from "./OpenAppointmentButton";
import PropTypes from "prop-types";
import {paymentStatusUtils} from "../../utils/paymentStatusUtils";
import EncounterDetails from "./EncounterDetails";
import {appointmentUtils} from "../../utils/appointmentUtils";


class AppointmentsTabListItem extends Component {
    state = {
        encounter: null,
        descriptionOpened: false
    }

    constructor(props) {
        super(props);
        this.participant = {};
    }



    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (this.props.selectedAppointmentId !== this.props.appointment.id && this.state.encounter != null) {
            this.setState({
                encounter: null,
                descriptionOpened: false
            });
        }

        if (this.props.selectedAppointmentId !== this.props.appointment.id && this.state.encounter == null && this.state.descriptionOpened !== false) {
            this.setState({descriptionOpened: false});
        }

        if (snapshot) {
            this.scrollElement(snapshot);
        }
    }

    getSnapshotBeforeUpdate(prevProps, prevState){
        if (this.state.descriptionOpened && !prevState.descriptionOpened) {
            return { offsetTop: this.ref.offsetTop, scrollTop: document.scrollingElement.scrollTop}
        }
        return null;
    }

    scrollElement= (o) =>{
        // Store a 'this' ref, and
        const _this = this;
        // wait for a paint before running scrollHeight dependent code.
        window.requestAnimationFrame(function() {
            let node = _this.ref;
            if (node && document.scrollingElement) {
                let offsetTop = node.offsetTop;
                if (Math.round(offsetTop) !== Math.round(o.offsetTop)) {
                    let diff = offsetTop - o.offsetTop;
                    let top = Math.max(o.scrollTop + diff, 0);
                    document.scrollingElement.scrollTo({top: top, behavior:"instant"});
                }
            }
        });
    }

    /**
     * Creates the time cell of the table
     *
     * @returns {ReactElement} the created table cell
     */
    getTimeCell = () => {

        const unreadDocsClasses = classNames('red-dot', {'text-danger':this.props.appointment.has_not_seen_patient_documents, 'd-none': !this.props.appointment.has_not_seen_patient_documents})

        return <div className='p-3 d-table-cell'>
                    <div className="d-flex">
                        <div className='d-table-cell p-3 kt-valign-middle' style={{width:"16px"}}>
                            <span className={unreadDocsClasses}></span>
                        </div>
                        <div>
                            <div className='text-center smaller-text medrec-grey-2'>{$$("date_label")}</div>
                            <div className='appointments-date-label text-center no-wrap'>
                                        {moment(this.props.appointment.starts_at).format('D MMM')}
                                <span className="appointments-year-label">&nbsp;
                                    {moment(this.props.appointment.starts_at).format('YYYY')}
                                    </span>
                            </div>
                            <div className="text-center appointments-year-label kt-font-lg">
                                <i className="flaticon2-time medrec-grey-2"/>
                                {moment(this.props.appointment.starts_at).format('LT')}
                            </div>
                        </div>
                    </div>

        </div>;
    }

    /**
     * Creates the health issues list cell of the table
     *
     * @returns {ReactElement} the created table cell
     */
    getHealthIssueListCell = () => {
        if (this.props.appointment.health_issues) {
            let healthIssueList = this.props.appointment.health_issues.map((h, idx) => {
                return <div key={idx} className='patient-health-issue-list'>{h.name}</div>
            });
            return <div className='text-center'>
                {/*<div className='smaller-text medrec-grey-2'>{$$("condition_label")}</div>*/}
                <div className="d-flex flex-wrap mb-3 justify-content-center">
                    {healthIssueList}
                </div>
            </div>;
        }
    }

    /**
     * Creates the status cell of the table
     *
     * @returns {ReactElement} the created table cell
     */
    getStatusCell = () => {
        let paymentStatus = paymentStatusUtils.paymentStatus(this.props.appointment)

        return <div className={"p-3 d-table-cell kt-valign-top"}>
                    <div className='text-center smaller-text medrec-grey-2'>{$$("status_label")}</div>
                    <div className="text-center"
                         style={{"color": getColorForOption(APPOINTMENTS_FILTER.COLOR, this.props.appointment.status)}}>
                        {appointmentUtils.statusText(this.props.appointment)}
                        <div className={`${paymentStatus.colour} text-center`}>
                            {this.props.appointment.appointment_price?.price_cents > 0 ? paymentStatus.label : $$("free_label") }
                        </div>
                    </div>
        </div>;
    }

    appointmentNameCell = () => {
        let h = this.props.appointment.appointment_price;
        return <div className='p-3 d-table-cell kt-valign-top'>
            <div className='text-center smaller-text medrec-grey-2'>{this.props.appointment.text_only ? $$("text_consultation") : $$("appointment_label")}</div>
            <div className="text-center"> {h.encounter_type === 'OTHER' ? h.name.toLowerCase() : $$(h.encounter_type.toLowerCase())}</div>
            {!this.props.appointment.text_only && <div className="text-center">{appointmentUtils.location(this.props.appointment)}</div>}
        </div>
    }

    /**
     * An event handler triggered when an appointment is clicked
     */
    onAppointmentClick = () => {
        this.props.onAppointmentChange(this.props.appointment);
        if (this.props.appointment.encounter_id && this.state.encounter == null) {
            usersService.fetchAppointmentEncounter(this.props.appointment.encounter_id, null).then((encounter) => {
                if (encounter) {
                    this.setState({encounter});
                }
            });
        }
        this.setState({descriptionOpened: true})
    }

    openAppointment = (patient) => {
        this.props.keepSelectedUserAndOpenAppointment(patient, this.props.appointment)
    }

    updateEncounter = () => {
        usersService.fetchAppointmentEncounter(this.props.appointment.encounter_id, null).then((encounter) => {
            if (encounter) {
                this.setState({encounter});
            }
        });
    }

    render() {
        const activeClass = classNames('appointment-containter apptm-list-item', {
            'appointment-active': (this.props.selectedAppointmentId === this.props.appointment.id)
        });

        this.participant = this.props.appointment.participants.find(p => p.participant_role === 'PATIENT');

        let lang = _.contains(['en', 'sq'], this.props.i18n) ? 'en' : this.props.i18n;
        moment.locale(lang.lang);

        return (
                <div className={activeClass} onClick={this.onAppointmentClick} ref={(ref)=>{this.ref = ref}}>
                    <div className="d-flex">
                        <div className="d-table flex-fill cell-wrap">
                            <div className="d-table w-100 app-list-item-table">   {this.getTimeCell()}
                                    {this.appointmentNameCell()}
                                    {this.getStatusCell()}
                            </div>
                            {this.props.appointment.health_issues && this.props.appointment.health_issues.length > 0 &&
                            <Collapse isOpened={this.state.descriptionOpened}> <div className="text-center">
                                <div className="medrec-grey-2 text-center mt-1 mb-1">{$$("condition_label")}</div>
                                {this.getHealthIssueListCell()}</div>
                            </Collapse>}
                            {this.state.encounter != null &&
                            <Collapse isOpened={this.state.descriptionOpened}>
                                <EncounterDetails settings={this.props.settings} loggedInUser={this.props.loggedInUser} encounter={this.state.encounter} i18n={this.props.i18n} selectedUser={this.props.selectedUser} appointment={this.props.appointment}
                                                  updateEncounter={() => {
                                                      this.updateEncounter();
                                                  }}
                                />
                            </Collapse>}
                            {this.state.encounter == null &&
                            <Collapse isOpened={this.state.descriptionOpened}>
                                <div className="card-body">
                                <div className='medrec-grey-2 centered-text'>
                                    {$$("no_encounter_found_label")}</div>
                                <br/>
                                </div>
                            </Collapse>}
                        </div>
                        <div className="flex-0-0-auto pt-3" style={{flexBasis: "50px"}}>
                            {! appointmentUtils.isAppointmentCanceled(this.props.appointment.status)
                                && !appointmentUtils.isOverdueTextConsultation(this.props.appointment)
                                && <OpenAppointmentButton
                                        title={$$('open_appointment_label')}
                                        onClick={() => this.openAppointment(this.participant)}/>}
                        </div>
                    </div>

                    <hr className="zero-margin-row"/>
                </div>
        )
    }
}

AppointmentsTabListItem.propTypes = {
    appointment: PropTypes.object,
    i18n: PropTypes.object,
    selectedUser: PropTypes.any,
    keepSelectedUserAndOpenAppointment: PropTypes.func,
    onAppointmentChange: PropTypes.func,
    settings: PropTypes.object,
    selectedAppointmentId: PropTypes.string
}

export default AppointmentsTabListItem;
