import React, {Component} from 'react'
import {$$} from '../../helpers/localization'

class NotificationsHeader extends Component {
    render() {
        return (
            <div className='patient-card-header'>
                <div className='row space-between-justify'>
                    <h2 className='card-title card-header-title'>{$$('notifications_label')}</h2>
                </div>
            </div>
        )
    }
}

export default NotificationsHeader
