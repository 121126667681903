import React, {useEffect, useState} from "react";
import { Modal, ModalBody, ModalFooter, Button } from "react-bootstrap"
import { $$ } from "../../../../helpers/localization";
import {
    nhisCreateEReferral,
    nhisLogin,
    nhisSignEReferral
} from "../../../../service/nhis_service";
import { dateAsStr } from "./NhisPrescription";
import {dateTimeUtils} from "../../../../utils/dateTimeUtils";
import {getClinicianNameWithTitle} from "../../../../utils/getClinicianNameWithTitle";
import {EreferralBody} from "./NhisEreferralListItemView";

export default function NhisEReferralSendToNhis({ examNrn, eRef, provider, onCancel, onSuccess, lang }) {
    const [error, setError] = useState();
    const [sendingInProgress, setSendingInProgress] = useState(false);
    const [success, setSuccess] = useState(false);
    const [nrn, setNrn] = useState();
    const [eRefContents, setErefContents] = useState();

    useEffect(() => {
        setErefContents(JSON.parse(eRef.content_json));
    }, [eRef])

    const onUpdate = () => {
        setSendingInProgress(true);
        setError(undefined);        
        let copy = _.cloneDeep(eRefContents);
        let content = copy.contents;
        content.basedOn = examNrn;
        if (content.laboratory) {
            content.laboratory = content.laboratory.map(l => l.value);
        }
        content.authoredOn = dateAsStr(new Date());
        content.diagnosis = content.mainDiagnoses.map(diagnosis => ({
            code: diagnosis.code,
            additionalCode: diagnosis.additionalCode && diagnosis.additionalCode.length > 0 ? diagnosis.additionalCode : null,
            use: diagnosis.use,
            rank: diagnosis.rank,
            clinicalStatus: diagnosis.clinicalStatus,
            verificationStatus: diagnosis.verificationStatus,
            onsetDateTime: diagnosis.onsetDateTime ? dateTimeUtils.toOffsetDateTime(diagnosis.onsetDateTime) : null,
            note: diagnosis.note ? diagnosis.note : null
        }));
        if (content.additionalDiagnoses && content.additionalDiagnoses.length > 0) {
            content.comorbidity = content.additionalDiagnoses.map(diagnosis => ({
                code: diagnosis.code,
                additionalCode: diagnosis.additionalCode && diagnosis.additionalCode.length > 0 ? diagnosis.additionalCode : null,
                use: diagnosis.use,
                rank: diagnosis.rank,
                clinicalStatus: diagnosis.clinicalStatus,
                verificationStatus: diagnosis.verificationStatus,
                onsetDateTime: diagnosis.onsetDateTime ? dateTimeUtils.toOffsetDateTime(diagnosis.onsetDateTime) : null,
                note: diagnosis.note ? diagnosis.note : null
            }));
        }
        let requester = copy.requester;
        requester.role = "1";
        if (content.financingSource !== '2') {
            requester.qualification_nhifCode = null;
        }

        if (content.consultation) {
            content.consultation = fromOptionToConsultation(content.consultation);
        }

        if (content.specializedActivities) {
            content.specializedActivities  = {
                code: content.specializedActivities.code.value,
                qualification:fromOptionToQualification(content.specializedActivities.qualification)
            }
        }

        if (content.hospitalization) {
            let h = {
                admissionType: content.hospitalization.admissionType,
                directedBy: content.hospitalization.directedBy
            }

            if (content.hospitalization.clinicalPathway) {
                h.clinicalPathway = content.hospitalization.clinicalPathway.value;
            }

            if (content.hospitalization.outpatientProcedure && content.hospitalization.outpatientProcedure.length > 0) {
                h.outpatientProcedure = content.hospitalization.outpatientProcedure.map(op => op.value);
            }
            content.hospitalization = h;
        }

        if (content.medicalExpertise) {
            let me = {};
            if (content.medicalExpertise.examType) {
                me.examType = content.medicalExpertise.examType;
            }
            me.qualification = content.medicalExpertise.qualification.map(q => fromOptionToQualification(q));
            content.medicalExpertise = me;
        }

        if (content.workIncapacity) {
            content.workIncapacity = convertToWorkIncapacity(content.workIncapacity);
        }

        let subject = copy.subject;
        let message  = {referral: content, requester: requester, subject:subject, senderId: requester.pmi};
        nhisLogin((loginSuccess) => {
            try {
                nhisSignEReferral(message, (signedERef) => {
                    nhisCreateEReferral(loginSuccess.accessToken, signedERef)
                        .then(async res => {
                            let contents = res["nhis:message"]["nhis:contents"];
                            let error = contents["nhis:error"];
                            if (error) {
                                if (Array.isArray(error)) {
                                    setError(error.map(e => e["nhis:reason"]["value"]).join("\n"));
                                } else {
                                    setError(error["nhis:reason"]["value"]);
                                }
                            } else {
                                let status = contents["nhis:status"]["value"];
                                //1 - active
                                if (status == 1) {
                                    setSuccess(true);
                                    let nrn = contents["nhis:nrnReferral"]["value"];
                                    setNrn(nrn);
                                    onSuccess(nrn);
                                } else {
                                    setError(`Неуспешно изпращане. Направлението е със статус ${status} в системата на НЗИС!`)
                                }
                            }
                            setSendingInProgress(false)
                        })
                }, (e) => {
                    setError(e);
                    setSendingInProgress(false);
                })
            } catch (e) {
                setSendingInProgress(false);
                setError(e)
            }
        }, (e) => {
            setSendingInProgress(false);
            setError(e);
        }).catch(error => {
            console.log(error);
            setSendingInProgress(false);
            setError($$("nhis.service_error"));
        })
    }

    return <Modal backdrop="static" show={true} size={"lg"}>
        <ModalBody className="nhis-prescription-modal-body">            
            {error && <div className="text-danger text-center p-2 whitespace-pre-line">{error}</div>}
            {success && <div className="text-left">
                <p className="text-center">{$$("nhis.ereferral.successfully_sent_to_nhis")}</p>
                <h5 className="text-center">{$$("nhis.ereferral.nrn_number")}: {nrn}</h5>
            </div>}            
            {eRefContents && !success &&
                <div className={`${sendingInProgress ? "d-none" : ''}`}>
                    <div><span className="medrec-grey-2">{$$("nhis.patient.title")}: </span>{`${eRefContents.subject.givenName} ${eRefContents.subject.familyName}`}</div>
                    <div><span className="medrec-grey-2">{$$("clinician_label")}: </span>{getClinicianNameWithTitle(provider)}</div>
                    <hr/>
                    <div>
                        <EreferralBody eReferral={eRefContents} nrn={nrn} lang={lang}/>
                    </div>
                </div>
            }            
            {sendingInProgress &&
                <div><p className="text-center">{$$("nhis.processing_body")}</p>
                </div>
            }
        </ModalBody>
        {!success && <ModalFooter>
            {!sendingInProgress &&                 /*<Button variant={"primary"} form={"patient_provider_form"} type={"submit"} disabled={hasEmptyDiagnosis}>
                    {$$("nhis.btn.complete_exam")}
                </Button>*/
                <Button variant={"primary"} type={"button"} onClick={onUpdate}>
                    {$$("nhis.btn.complete_ereferral")}
                </Button>
            }
            {!sendingInProgress && 
                <Button variant={"outline-primary"} type={"button"} onClick={onCancel}>{$$("close_btn_label")}</Button>}
        </ModalFooter>
        }
        {success && <ModalFooter>
            <Button variant={"primary"} form={"patient_provider_form"} type={"button"} 
                onClick={onCancel}>{$$("close_btn_label")}</Button>
        </ModalFooter>}
    </Modal>
}

function fromOptionToConsultation(option) {
    let consultation = {};
    consultation.qualification = option.value;
    if (option.item.meta && option.item.meta["nhif code"]) {
        consultation.qualification_nhifCode = option.item.meta["nhif code"];
    }
    return consultation;
}

function fromOptionToQualification(option) {
    let qualification = {};
    qualification.code = option.value;
    if (option.item.meta && option.item.meta["nhif code"]) {
        qualification.nhifCode = option.item.meta["nhif code"];
    }
    return qualification;
}

function convertToWorkIncapacity(wI) {
    let workIncapacity = {
        reason : wI.reason,
        addressType: wI.addressType,
        isHomeVisitNecessary: !!wI.isHomeVisitNecessary
    };

    if (wI.employer) {
        workIncapacity.employerName = wI.employer.name;
        workIncapacity.employerIdentificationCode = wI.employer.code;
        workIncapacity.employerPhone = wI.employer.phone || null;
        workIncapacity.employerEmail = wI.employer.email || null;
        workIncapacity.employerPosition = wI.employer.position;
        workIncapacity.employerAddress = {
            country: wI.employer.address.country.value,
            county: wI.employer.address.county ? wI.employer.address.county.value : null,
            ekatte: wI.employer.address.ekatte ? wI.employer.address.ekatte.value : null,
            city: wI.employer.address.city,
            line:  wI.employer.address.line,
            postalCode: wI.employer.address.postalCode,
        }
    }

    return workIncapacity;
}