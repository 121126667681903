import {
    CHANGE_LAB_RESULTS_FILTERS,
    CLEAR_PATIENTS,
    FETCH_LABORATORIES_ERROR,
    FETCH_LABORATORIES_REQUEST_SENT,
    FETCH_LABORATORIES_SUCCESS,
    FETCH_SELECTED_USER_LAB_RESULTS_ERROR,
    FETCH_SELECTED_USER_LAB_RESULTS_REQUEST_SENT,
    FETCH_SELECTED_USER_LAB_RESULTS_SUCCESS,
    LOGOUT,
    NO_MORE_SELECTED_USER_LAB_RESULTS,
    REQUEST_ERROR,
    REQUEST_SENT,
    REQUEST_SUCCESS,
    RESET_SELECTED_USER_LAB_RESULTS_SUCCESS,
    SELECT_USER
} from "../actions/actions";
import {requestStatus} from './requests_reducers';

/**
 * Laboratories reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the laboratories
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const laboratoriesInitialState = {
    labs: [],
    request: requestStatus(undefined, {}),
}

export function laboratories(state = laboratoriesInitialState, action) {
    switch (action.type) {
        case FETCH_LABORATORIES_REQUEST_SENT: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_SENT})}
        }
        case FETCH_LABORATORIES_SUCCESS: {
            return {...state, labs: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case FETCH_LABORATORIES_ERROR: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case LOGOUT: {
            return {...state, ...laboratoriesInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * Lab results entries reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the lab results
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const labResultsInitialState = {
    filters: {
        laboratory: '',
        before_date_time: null,
        after_date_time: null,
    },
    selectedUser: {
        entries: [],
        isLastPage: false,
        request: requestStatus(undefined, {}),
    }
}

export function labResults(state = labResultsInitialState, action) {
    switch (action.type) {
        case FETCH_SELECTED_USER_LAB_RESULTS_REQUEST_SENT: {
            return {
                ...state,
                selectedUser: {
                    ...state.selectedUser,
                    isLastPage: false,
                    request: requestStatus(state.request, {type: REQUEST_SENT})
                }
            }
        }
        case FETCH_SELECTED_USER_LAB_RESULTS_SUCCESS: {
            return {
                ...state,
                selectedUser: {
                    entries: [...state.selectedUser.entries, ...action.result],
                    request: requestStatus(state.request, {type: REQUEST_SUCCESS})
                }
            }
        }
        case FETCH_SELECTED_USER_LAB_RESULTS_ERROR: {
            return {
                ...state,
                selectedUser: {
                    entries: labResultsInitialState.selectedUser.entries,
                    isLastPage: labResultsInitialState.selectedUser.isLastPage,
                    request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
                }
            }
        }
        case RESET_SELECTED_USER_LAB_RESULTS_SUCCESS: {
            return {
                ...state,
                selectedUser: {entries: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
            }
        }
        case CHANGE_LAB_RESULTS_FILTERS: {
            return {
                ...state,
                filters: {
                    laboratory: action.laboratory,
                    before_date_time: action.beforeDateTime,
                    after_date_time: action.afterDateTime
                }
            }
        }
        case NO_MORE_SELECTED_USER_LAB_RESULTS: {
            return {...state, selectedUser: {...state.selectedUser, isLastPage: true}}
        }
        case SELECT_USER:
        case CLEAR_PATIENTS: {
            return {
                ...state,
                selectedUser: labResultsInitialState.selectedUser,
                loggedUser: labResultsInitialState.loggedUser
            };
        }
        case LOGOUT: {
            return {...state, ...labResultsInitialState};
        }
        default: {
            return state;
        }
    }
}