import {fetchHelper} from '../helpers/request_helper';
import {
    DELETE_LOGGED_USER_DOCUMENT_URL,
    DOWNLOAD_DOCUMENT_URL,
    DOWNLOAD_LOGGED_USER_DOCUMENT_URL,
    GET_LOGGED_USER_DOCUMENTS_URL,
    GET_USER_DOCUMENTS_URL,
    UPLOAD_LOGGED_USER_DOCUMENT_URL,
    FETCH_ENCOUNTER_DOCUMENTS_URL,
    DOWNLOAD_ENCOUNTER_DOCUMENT_URL,
    DOWNLOAD_EXAMINATION_RESULTS_URL,
    MARK_PATIENT_ENCOUNTER_DOCUMENT_AS_SEEN,
    REMOVE_MISSING_UNSEEN_DOCUMENTS,
    ENCOUNTER_DOCUMENTS_URL,
    GET_PROVIDER_ENCOUNTER_DOCUMENTS_URL,
    DELETE_PROVIDER_ENCOUNTER_DOCUMENTS_URL
} from '../constants/api_paths';

export const documentsService = {
    fetchSelectedUserDocuments,
    downloadFile,
    fetchLoggedUserDocuments,
    downloadLoggedUserFile,
    uploadLoggedUserDocument,
    deleteLoggedUserDocument,
    downloadExamResult,
    fetchEncounterDocuments,
    downloadEncounterFile,
    markEncounterDocumentAsRead,
    createProviderEncounterDocument,
    updateProviderEncounterDocument,
    deleteProviderEncounterDocument,
    fetchProviderEncounterDocuments,
    downloadReportResult,
    removeMissingUnseenDocuments
};

/**
 * Fetch the selected user uploaded documents
 *
 * @param {string} userId id of the user to fetch documents for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserDocuments(userId, params) {
    return fetchHelper.callGet(GET_USER_DOCUMENTS_URL.replace('{targetUserId}', userId), params);
}

/**
 * Download file with the given id.
 *
 * @param {number} documentId id of the document to download
 * @param {array} healthIssuesIds containing the health issue ids
 * @returns {object} promise object
 */
export function downloadFile(documentId, healthIssuesIds) {
    return fetchHelper.getBlob(DOWNLOAD_DOCUMENT_URL.replace('{documentId}', documentId).replace('{healthIssuesIds}', healthIssuesIds.join(",")));
}

/**
 * Download file with the given id.
 *
 * @param {number} documentId id of the document to download
 * @param {array} encounterId encounterId
 * @returns {object} promise object
 */
export function downloadEncounterFile(documentId, encounterId) {
    return fetchHelper.getBlob(DOWNLOAD_ENCOUNTER_DOCUMENT_URL.replace('{documentId}', documentId).replace("{encounterId}",encounterId));
}

/**
 * Fetch the logged in user uploaded documents
 *
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchLoggedUserDocuments(params) {
    return fetchHelper.callGet(GET_LOGGED_USER_DOCUMENTS_URL, params);
}


/**
 * Creates entry
 * @param {FormData} entry The entry to be created
 * @param {string} encounterId encounterId
 */
export function createProviderEncounterDocument(entry, encounterId) {
    return fetchHelper.callPost(ENCOUNTER_DOCUMENTS_URL.replace("{encounterId}",encounterId), entry,null ,true);
}


/**
 * Updates entry
 * @param {formData} entry The entry to be created
 * @param {string} encounterId encounterId
 */
export function updateProviderEncounterDocument(entry, encounterId) {
    return fetchHelper.callPut(ENCOUNTER_DOCUMENTS_URL.replace("{encounterId}",encounterId), entry,null ,true);
}


/**
 * Updates entry
 * @param {string} documentId The entry to be deleted
 * @param {string} encounterId encounterId
 */
export function deleteProviderEncounterDocument( encounterId, documentId) {
    return fetchHelper.callDelete(DELETE_PROVIDER_ENCOUNTER_DOCUMENTS_URL.replace("{encounterId}",encounterId).replace("{documentId}",documentId),null );
}

/**
 * Download file with the given filename.
 *
 * @param {string} filename the filename of the document to download
 * @returns {object} promise object
 */
export function downloadLoggedUserFile(filename) {
    return fetchHelper.getBlob(DOWNLOAD_LOGGED_USER_DOCUMENT_URL.replace('{filename}', filename));
}

/**
 * Upload file to the server.
 *
 * @param {object} file -  the file to be uploaded
 * @returns {object} promise object
 */
export function uploadLoggedUserDocument(file) {
    return fetchHelper.uploadFile(UPLOAD_LOGGED_USER_DOCUMENT_URL, file);
}

/**
 * Delete file with the given filename.
 *
 * @param {object} body - the body of the request
 * @returns {object} promise object
 */
export function deleteLoggedUserDocument(body) {
    return fetchHelper.callDelete(DELETE_LOGGED_USER_DOCUMENT_URL, null, body);
}

export function fetchEncounterDocuments(encounterId) {
    return fetchHelper.callGet(FETCH_ENCOUNTER_DOCUMENTS_URL.replace("{encounterId}", encounterId))
}

export function fetchProviderEncounterDocuments(encounterId) {
    return fetchHelper.callGet(GET_PROVIDER_ENCOUNTER_DOCUMENTS_URL.replace("{encounterId}", encounterId))
}

export function downloadExamResult(lang, examDetails) {
    return fetchHelper.getBlobWithPost(DOWNLOAD_EXAMINATION_RESULTS_URL.replace("{reportType}", "EncounterOutcome").replace("{lang}", lang).replace("{providerId}",""), examDetails)
}

export function downloadReportResult(lang, details, reportType, id) {
    return fetchHelper.getBlobWithPost(DOWNLOAD_EXAMINATION_RESULTS_URL.replace("{reportType}", reportType).replace("{lang}", lang).replace("{providerId}",id), details)
}

export function markEncounterDocumentAsRead(documentId, encounterId) {
    return fetchHelper.callPut(MARK_PATIENT_ENCOUNTER_DOCUMENT_AS_SEEN.replace('{encounterId}', encounterId).replace('{documentId}', documentId))
}

export function removeMissingUnseenDocuments(encounterID, params) {
    return fetchHelper.callDelete(REMOVE_MISSING_UNSEEN_DOCUMENTS.replace('{encounterId}', encounterID), params);
}