import React, {useCallback, useEffect, useMemo, useState} from "react"
import EditFormHeader from "../../EditFormHeader";
import {$$} from "../../../../helpers/localization";
import {v4} from "uuid"
import {NhisExaminationProvider, useNhisExaminationContext} from "./NhisExaminationProvider";
import Button from "react-bootstrap/Button";
import {Select} from "../../../shared/Select";
import {BoolSwitch} from "./BoolSwitch";
import {SearchableSelectAsync} from "./SearchableSelect";
import DatePicker from "react-datepicker";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {dateTimeUtils} from "../../../../utils/dateTimeUtils";
import moment from "moment"
import PatientProviderForm from "./PatientProviderForm";
import {clinicService} from "../../../../service/clinic_service";
import {
    FG,
    FG50SmallL,
    FG50SmallR,
    FormRow, Input,
    InputTextArea,
    RequiredIndicator,
    SectionHeader
} from "./HelperFormComponents";
import enGB from "date-fns/locale/en-GB";
import bg from "date-fns/locale/bg";
import {DateTimeComponentEditable} from "../../../appointment/AppointmentsManagement";
import CenteredModal from "../../../shared/CenteredModal";
import {summaryService} from "../../../../service/summary_service";

/*
const model = {
    nrnExamination;

//open examination fields
public String lrn;
public OffsetDateTime openDate;
@JsonProperty("class")
public String examinationClass;
public String financingSource;
public String rhifAreaNumber;

//close examination fields
public OffsetDateTime closeDate;
public String basedOn;
public String directedBy;
public String purpose;
public Boolean incidentalVisit;
public Boolean isSecondary;
public Boolean adverseConditions;

//motherHealthcare
public Boolean motherHealthcare_isPregnant;
public Boolean motherHealthcare_isBreastFeeding;
public Integer motherHealthcare_gestationalWeek;

//childHealthcare
public Float childHealthcare_age;

public List<Consultation> consultation;

public DocumentsRef documents;

public Diagnosis diagnosis;

public List<Diagnosis> comorbidity;

public List<Assessment> assessment;

public List<DiagnosticReport> diagnosticReport;

public Therapy therapy;
}
*/


export function NhisExam({encounter, i18n, doctor, orgId, patient, appointmentPrice, onSave, onCancel}) {
    const [previousCompletedAppointment, setPreviousCompletedAppointment] = useState(null);

    useEffect(()=>{
            let params = encounter ? {excludeEncounterId: encounter.id} : {};
            params.isNhis = true;
            summaryService.getLastCompletedAppointment(patient.id, params).then(res => {
                if (res) {
                    let exam = JSON.parse(res.nhis_examination_json);
                    let examination = exam.examination;
                    examination.nrn = res.nhis_examination_nrn;
                    setPreviousCompletedAppointment(examination);
                }
            })
        },
    []);

    return <NhisExaminationProvider>
        <div className={"minus-twenty-margin-top"}>
            <div>
                <div className='row' style={{'marginLeft': '0px', 'marginRight': '0px'}}>
                    <div className='col-xs-12 col-md-12'>
                        <div className='row'>
                            <div className="row"
                                 style={{"width": "100%", "marginLeft": "20px", "marginRight": "20px"}}>
                                <div style={{"width": "100%"}}>
                                    <NhisExamBody i18n={i18n} encounter={encounter}
                                                  appointmentPrice={appointmentPrice}
                                                  onSave={onSave}
                                                  onCancel={onCancel}
                                                  gender={patient.gender}
                                                  patient={patient}
                                                  doctor={doctor}
                                                  orgId={orgId}
                                                  previousCompletedAppointment={previousCompletedAppointment}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </NhisExaminationProvider>
}

function NhisExamBody({encounter, i18n, previousCompletedAppointment, appointmentPrice, onSave, onCancel, gender, patient, doctor, orgId}) {
    const [localModel, setLocalModel] = useState({examination:{}})
    const {isLoading, isError, reload, countyOptions, specialtyOptions, icdCodeOptions, conclusionOptions, dischargeDispositionOptions} = useNhisExaminationContext();
    const [compKey, setCompKey] = useState(v4())
    const [step, setStep] = useState(1)
    const [clearForm, setClearForm] = useState(false)

    useEffect(() => {
        let model;
        if (encounter?.nhis_examination_json) {
            model = JSON.parse(encounter.nhis_examination_json);
        } else {
            model = {examination:{}}
        }
        clinicService.fetchEncPatientInfo(patient.id, orgId).then(patient => {
            if (patient && patient.rhifAreaNumber) {
                model.examination.rhifAreaNumber = patient.rhifAreaNumber;
            }
        })

        setLocalModel(model)
        setCompKey(v4());
    }, [encounter?.nhis_examination_json])

    const onClearClicked = () => {
        setClearForm(true)
    }

    const createOrUpdateEncounterObject = (nhisModel) => {
        let enc = encounter ? {...encounter} : {};
        enc.nhis_examination_json = JSON.stringify(nhisModel);
        enc.subject = patient.id;
        enc.objective_data = nhisModel.examination.objectiveCondition || "";
        enc.subjective_data = nhisModel.examination.medicalHistory || "";

        if (nhisModel.examination.diagnosis.code) {
            enc.main_diagnosis_code = nhisModel.examination.diagnosis.code;
            enc.main_diagnosis = icdCodeOptions.find(o => o.value == enc.main_diagnosis_code).text;
        } else {
            enc.main_diagnosis_code = "";
            enc.main_diagnosis = ""
        }

        if (['10','11','12'].includes(nhisModel.examination.diagnosis.verificationStatus)) {
            enc.preliminary_diagnosis = true;
        }

        let add_notes = "";
        if (nhisModel.examination.diagnosis.note) {
            add_notes = nhisModel.examination.diagnosis.note;
        }

        if (nhisModel.examination.diagnosis.additionalCode) {
            enc.concomitant_diseases = nhisModel.examination.diagnosis.additionalCode.map(c => icdCodeOptions.find(o => o.value == c).text);
            enc.concomitant_diseases_codes = [...nhisModel.examination.diagnosis.additionalCode]
        } else {
            enc.concomitant_diseases = []
            enc.concomitant_diseases_codes = []
        }

        nhisModel.examination.additionalDiagnoses.forEach(d => {
            if (d.code) {
                if (!enc.concomitant_diseases_codes.includes(d.code)) {
                    enc.concomitant_diseases.push(icdCodeOptions.find(o => o.value == d.code).text);
                    enc.concomitant_diseases_codes.push(d.code);
                }
            }

            if (d.additionalCode) {
                let concomitantDiseases = d.additionalCode.map(c => icdCodeOptions.find(o => o.value == c).text);
                enc.concomitant_diseases = [...enc.concomitant_diseases, ...concomitantDiseases];
                enc.concomitant_diseases_codes = [...enc.concomitant_diseases_codes, ...d.additionalCode];
            }

            if (d.note) {
                add_notes += add_notes ? "\n" + d.note : d.note;
            }
        })

        if (nhisModel.examination.tests) {
            enc.tests = nhisModel.examination.tests.replace(/\s*\n\s*/g, "\n").replace(/\n\s*$/, "").split("\n");
        } else {
            enc.tests = [];
        }

        if (nhisModel.examination.medicationCodeOption) {
            enc.therapy = nhisModel.examination.medicationCodeOption.map(a => a.text).join("; ");
        } else {
            enc.therapy = "";
        }

        if (nhisModel.examination.note) {
            enc.therapy = enc.therapy ? enc.therapy + "\n" + nhisModel.examination.note : nhisModel.examination.note;
        }

        if (nhisModel.examination.conclusion) {
            enc.conclusion = conclusionOptions.find(o => o.value === nhisModel.examination.conclusion).text;
        }

        if (nhisModel.examination.dischargeDisposition) {
            let text = dischargeDispositionOptions.find(o => o.value === nhisModel.examination.dischargeDisposition).text;
            enc.conclusion = enc.conclusion ? enc.conclusion + "\n" + text : text;
        }

        enc.additional_info = add_notes;

        /*encounter = {
            tests: [],
            concomitant_diseases: [],
            concomitant_diseases_codes: [],
            therapy: "",
            main_diagnosis: "",
            main_diagnosis_code: "",
            additional_info: "",
            objective_data: "",
            subjective_data: ""
        };*/

        return enc;
    }

    if (isError && !isLoading) {
        return <div className="mt-4">
            <div className={"text-center"}>{$$("nhis.error_loading_nomenclatures")}</div>
            <br/>
            <div className={"text-center"}>
                <Button type="button" variant="primary" onClick={reload}>{$$("nhis.try_again")}</Button>
            </div>
        </div>;
    }

    if (isLoading) {
        return <div className={"text-center mt-4"}>{$$("nhis.loading_data_message")}</div>;
    }

    if (step === 1) {
        return <div>
            <PatientProviderForm countyOptions={countyOptions}
                                 subject={patient}
                                 provider={doctor}
                                 specialtyOptions={specialtyOptions}
                                 orgId={orgId}
                                 exam={localModel}
                                 lang={i18n.lang}
                                 onUpdate={(patient, provider) => {
                                     let enc = encounter ? {...encounter} : {
                                         tests: [],
                                         concomitant_diseases: [],
                                         concomitant_diseases_codes: [],
                                         therapy: "",
                                         main_diagnosis: "",
                                         main_diagnosis_code: "",
                                         additional_info: "",
                                         objective_data: "",
                                         subjective_data: "",
                                         subject: patient.id
                                     };
                                     let nhis_exam = {...localModel, subject: patient, performer: provider};
                                     enc.nhis_examination_json = JSON.stringify(nhis_exam);
                                     onSave(nhis_exam, true, enc);
                                     setStep(2);
                                 }}/>
            <div className="mt-3 text-right">
                <Button variant={"primary"} form={"patient_provider_form"}
                        type={"submit"}>{$$("nhis.btn.save")}</Button>
                <Button variant={"outline-primary"} type={"button"} className="ml-2"
                        onClick={onCancel}>{$$("cancel_btn")}</Button>
            </div>
        </div>
    }

    return (
        <div>
            <NhisForm key={compKey}
                      encounter={encounter}
                      formData={localModel.examination}
                      i18n={i18n}
                      appointmentPrice={appointmentPrice}
                      previousCompletedAppointment={previousCompletedAppointment}
                      onUpdate={(model) => {
                          let updatedModel = {...localModel, examination:model};
                          let enc = createOrUpdateEncounterObject(updatedModel);
                          onSave(updatedModel, false, enc)
                      }}
                      onClear={onClearClicked}
                      gender={gender}
                      onCancel={onCancel}
            />
            {clearForm && <CenteredModal title={$$('clear_form')}
                                         show={true}
                                         onHide={() => {
                                             setClearForm(false)
                                         }}
                                         onConfirm={() => {
                                             setLocalModel({...localModel, examination:{ lrn:localModel.examination?.lrn, rhifAreaNumber:localModel.examination?.rhifAreaNumber }});
                                             setCompKey(v4());
                                             setClearForm(false)
                                         }}
                                         confirmBtnLabel={$$("clear_form")}
                                         confirmBtnClass="danger"
                                         backdropClassName={"confirm-over-other-modal"}
            >
                {$$('confirm_clear_warning')}
            </CenteredModal>}
        </div>
    )
}

function NhisForm({formData, encounter, i18n, previousCompletedAppointment, onUpdate, appointmentPrice, onCancel, onClear, gender}) {


    const [formClass, setFormClass] = useState("");
    const [activeInput, setActiveInput] = useState("");
    const [activeInputText, setActiveInputText] = useState("");

    const [lrn, setLrn] = useState(formData?.lrn || v4())
    const [basedOn, setBasedOn] = useState(formData?.basedOn || "")
    const [directedBy, setDirectedBy] = useState(formData?.directedBy || "")
    const [classification, setClassification] = useState(formData?.classification || "") //to be set as exam.class
    const [purpose, setPurpose] = useState(formData?.purpose || "")
    const [incidentalVisit, setIncidentalVisit] = useState(formData?.incidentalVisit || false)
    const [isSecondary, setIsSecondary] = useState(formData?.isSecondary || false)
    const [financingSource, setFinancingSource] = useState(formData?.financingSource || "")
    const [rhifAreaNumber, setRhifAreaNumber] = useState(formData?.rhifAreaNumber || "")
    const [adverseConditions, setAdverseConditions] = useState(formData?.adverseConditions || false)

    //mother healthcare
    const [isPregnant, setIsPregnant] = useState(formData?.isPregnant || false)
    const [isBreastFeeding, setIsBreastFeeding] = useState(formData?.isBreastFeeding || false)
    const [gestationalWeek, setGestationalWeek] = useState(formData?.gestationalWeek || "")

    //child healthcare?
    //const [age, setAge] = useState(formData?.age || "")

    const [medicalHistory, setMedicalHistory] = useState(formData?.medicalHistory || "")
    const [objectiveCondition, setObjectiveCondition] = useState(formData?.objectiveCondition || "")
    const [conclusion, setConclusion] = useState(formData?.conclusion || "")
    const [dischargeDisposition, setDischargeDisposition] = useState(formData?.dischargeDisposition || "")

    //therapy
    const [medicationCodeOption, setMedicationCodeOption] = useState(formData?.medicationCodeOption || "")
    const [note, setNote] = useState(formData?.note || "")

    const [diagnosis, setDiagnosis] = useState(formData?.diagnosis || {rank: 1})
    const [additionalDiagnoses, setAdditionalDiagnoses] = useState(formData?.additionalDiagnoses || [])
    const [tests, setTests] = useState(formData?.tests || "");

    let durationMinutes = appointmentPrice.duration_mins ? appointmentPrice.duration_mins : 30;
    const [openDate, setOpenDate] = useState(formData?.openDate ? formData.openDate : dateTimeUtils.toOffsetDateTime(moment().subtract(durationMinutes, "minutes")))
    const [closeDate, setCloseDate] = useState(formData?.closeDate ? formData.closeDate : dateTimeUtils.toOffsetDateTime(new Date()))

    const {
        directedByOptions,
        examClassOptions,
        purposeOptions,
        financingSourceOptions,
        rhifAreaNumberOptions,
        diagnosisRoleOptions,
        icdCodeOptions,
        clinicalStatusOptions,
        verificationStatusOptions,
        conclusionOptions,
        dischargeDispositionOptions,
        medicationCodeOptions,
        specialtyOptions
    } = useNhisExaminationContext()

    const getMedicationCodeOptions = useCallback((query, callback) => {
        callback(medicationCodeOptions.filter(opt => opt.label.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1));
    }, [medicationCodeOptions])

    const endBeforeStart = useMemo(() => {return !moment(openDate).isBefore(moment(closeDate))}, [openDate, closeDate]);

    const getLocalModel = () => {
        return {
            lrn,
            closeDate: closeDate,
            openDate: openDate,
            classification,
            financingSource,
            rhifAreaNumber,
            basedOn,
            directedBy,
            purpose,
            incidentalVisit,
            isSecondary,
            adverseConditions,
            isPregnant,
            isBreastFeeding,
            gestationalWeek,
            diagnosis,
            additionalDiagnoses,
            medicationCodeOption,
            note,
            medicalHistory,
            objectiveCondition,
            conclusion,
            dischargeDisposition,
            tests
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (!e.target.checkValidity() || endBeforeStart) {
            setFormClass("was-validated");

            if (!e.target.checkValidity()) {
                document.getElementById("required-exam-information").scrollIntoView({
                    block: "start",
                    behavior: "smooth"
                })
            }

            return;
        }

        let localModel = getLocalModel();

        onUpdate(localModel);
    }

    const onCopyFromLastClick = () => {
        let p = previousCompletedAppointment;
        updateNonEmpty(classification, setClassification, p.classification);
        updateNonEmpty(financingSource, setFinancingSource, p.financingSource);
        updateNonEmpty(directedBy, setDirectedBy, p.directedBy);
        updateNonEmpty(purpose, setPurpose, p.purpose);
        //updateNonEmpty(incidentalVisit, setIncidentalVisit, p.incidentalVisit);
        //updateNonEmpty(isSecondary, setIsSecondary, p.isSecondary);
        updateNonEmpty(adverseConditions, setAdverseConditions, p.adverseConditions);
        updateNonEmpty(isPregnant, setIsPregnant, p.isPregnant);
        updateNonEmpty(isBreastFeeding, setIsBreastFeeding, p.isBreastFeeding);
        //updateNonEmpty(gestationalWeek, setGestationalWeek, p.gestationalWeek);
        updateNonEmpty(medicationCodeOption, setMedicationCodeOption, p.medicationCodeOption);
        updateNonEmpty(note, setNote, p.note);
        updateNonEmpty(medicalHistory, setMedicalHistory, p.medicalHistory);
        updateNonEmpty(objectiveCondition, setObjectiveCondition, p.objectiveCondition);
        updateNonEmpty(conclusion, setConclusion, p.conclusion);
        updateNonEmpty(dischargeDisposition, setDischargeDisposition, p.dischargeDisposition);
        updateNonEmpty(tests, setTests, p.tests);
        if (isSecondary) {
            updateNonEmpty(basedOn, setBasedOn, p.nrn);
        }

        if (additionalDiagnoses.length === 0 && p.additionalDiagnoses?.length > 0) {
            let d = _.cloneDeep(p.additionalDiagnoses)
            d.forEach(d => {
                if (d.onsetDateTime) {
                    d.onsetDateTime = new Date(d.onsetDateTime)
                }
            })
            setAdditionalDiagnoses(d)
        }

        let mainDiag = _.cloneDeep(diagnosis);


        updateNonEmpty(mainDiag.clinicalStatus, (v) => {mainDiag.clinicalStatus = v}, p.diagnosis.clinicalStatus);
        updateNonEmpty(mainDiag.code, (v) => {mainDiag.code = v}, p.diagnosis.code);
        updateNonEmpty(mainDiag.note, (v) => {mainDiag.note = v}, p.diagnosis.note);
        updateNonEmpty(mainDiag.rank, (v) => {mainDiag.rank = v}, p.diagnosis.rank);
        updateNonEmpty(mainDiag.use, (v) => {mainDiag.use = v}, p.diagnosis.use);
        updateNonEmpty(mainDiag.verificationStatus, (v) => {mainDiag.verificationStatus = v}, p.diagnosis.verificationStatus);
        updateNonEmpty(mainDiag.onsetDateTime, (v) => {mainDiag.onsetDateTime = new Date(v)}, p.diagnosis.onsetDateTime);
        if (!mainDiag.additionalCode || mainDiag.additionalCode.length === 0) {
            if (p.diagnosis.additionalCode && p.diagnosis.additionalCode.length > 0) {
                mainDiag.additionalCode = [...p.diagnosis.additionalCode];
            }
        }
        setDiagnosis(mainDiag);
    }

    const basedOnRequired = isSecondary || financingSource == 2;
    const directedByRequired = !basedOnRequired && !basedOn;


    const locale = useMemo(() => {
        switch (i18n.lang) {
            case "en":
                return enGB;
            case "bg":
                return bg;
            default:
                return enGB;
        }
    }, [])

    return (
        <form onSubmit={onSubmit} className={`has-custom-validation ${formClass}`}
              noValidate={true}>
            <div className="pt-3 nhis-examination">
                <div className="d-flex justify-content-end">
                    <div className={"mt-1"}>
                        <button type="button" onClick={onClear}
                                className="btn btn-sm btn-primary mr-1 mb-2">{$$('clear_form')}</button>
                        {previousCompletedAppointment != null &&
                        <button type="button" onClick={onCopyFromLastClick}
                                className="btn btn-sm btn-primary mr-1 mb-2">{$$('copy_from_last')}</button>}
                    </div>
                </div>
                <fieldset>
                    <div id = "required-exam-information" className="low-shadow-container mt-2 mb-3">
                        <div className="d-flex w-100 justify-space-between">
                            <div className="w-50">
                                <DateTimeComponentEditable labelKey={"start"} locale={locale} time={openDate} setTime={(d) => setOpenDate(dateTimeUtils.toOffsetDateTime(d))} />
                            </div>
                            <div className="w-50">
                                <DateTimeComponentEditable labelKey={"end"} locale={locale} time={closeDate} setTime={(d) => setCloseDate(dateTimeUtils.toOffsetDateTime(d))} />
                            </div>
                        </div>
                        {endBeforeStart && <div className="high-shadow-container mt-3 text-danger background-red">{$$("end_before_start_warning")}</div>}
                    </div>
                    <FormRow>
                        <FG50SmallL>
                            <label>{$$("nhis.examination.classification")}<RequiredIndicator/></label>
                            <Select placeHolder={$$("nhis.select")}
                                    emptyOption={true}
                                    options={examClassOptions}
                                    class="custom-select-sm"
                                    value={classification || ""}
                                    required={true}
                                    onChange={({value}) => {
                                        setClassification(value);
                                    }}
                                    autoFocus
                            />
                        </FG50SmallL>
                        <FG50SmallR>
                            <label>{$$("nhis.examination.purpose")}<RequiredIndicator/></label>
                            <Select placeHolder={$$("nhis.select")}
                                    emptyOption={true}
                                    options={purposeOptions}
                                    class="custom-select-sm"
                                    value={purpose || ""}
                                    required={true}
                                    onChange={({value}) => {
                                        setPurpose(value);
                                    }}
                            />
                        </FG50SmallR>
                    </FormRow>
                    <FormRow>
                        <FG50SmallL>
                            <label>{$$("nhis.examination.incidental_visit_label")}</label>
                            <BoolSwitch checked={incidentalVisit} onChange={() => {
                                setIncidentalVisit(!incidentalVisit);
                            }} labelOff={$$("nhis.no_label")} labelOn={$$("nhis.yes_label")} offFirst={false}/>
                        </FG50SmallL>
                        <FG50SmallR>
                            <label>{$$("nhis.examination.is_secondary_label")}</label>
                            <BoolSwitch checked={isSecondary} onChange={() => {
                                if (previousCompletedAppointment && !isSecondary) {
                                    updateNonEmpty(basedOn, setBasedOn, previousCompletedAppointment.nrn)
                                }
                                setIsSecondary(!isSecondary);
                            }} labelOff={$$("nhis.no_label")} labelOn={$$("nhis.yes_label")} offFirst={false}/>
                        </FG50SmallR>
                    </FormRow>
                    <FormRow>
                        <FG50SmallL>
                            <label>{$$("nhis.examination.directedBy")}<RequiredIndicator
                                hide={!directedByRequired}/></label>
                            <Select placeHolder={$$("nhis.select")}
                                    emptyOption={true}
                                    enableClear={true}
                                    options={directedByOptions}
                                    class="custom-select-sm"
                                    value={directedBy || ""}
                                    required={directedByRequired}
                                    onChange={({value}) => {
                                        setDirectedBy(value);
                                    }}
                            /></FG50SmallL>
                        <FG50SmallR>
                            <Input type="text" value={basedOn} label={"nhis.examination.basedOn"}
                                   required={basedOnRequired}
                                   maxLength={36}
                                   setValue={setBasedOn}/>
                        </FG50SmallR>
                    </FormRow>
                    <FG>
                        <label>{$$("nhis.examination.financingSource")}<RequiredIndicator/></label>
                        <Select placeHolder={$$("nhis.select")}
                                emptyOption={true}
                                options={financingSourceOptions}
                                class="custom-select-sm"
                                value={financingSource || ""}
                                required={true}
                                onChange={({value}) => {
                                    setFinancingSource(value);
                                }}
                        /></FG>
                    <FormRow>
                        <FG50SmallL>
                            <label>{$$("nhis.examination.rhifAreaNumber")}<RequiredIndicator/></label>
                            <Select placeHolder={$$("nhis.select")}
                                    emptyOption={true}
                                    options={rhifAreaNumberOptions}
                                    class="custom-select-sm"
                                    value={rhifAreaNumber || ""}
                                    required={true}
                                    onChange={({value}) => {
                                        setRhifAreaNumber(value);
                                    }}
                            /></FG50SmallL>
                        <FG50SmallR>
                            <label>{$$("nhis.examination.adverse_conditions_label")}</label>
                            <BoolSwitch checked={adverseConditions} onChange={() => {
                                setAdverseConditions(!adverseConditions);
                            }} labelOff={$$("nhis.no_label")} labelOn={$$("nhis.yes_label")} offFirst={false}/>
                        </FG50SmallR>
                    </FormRow>
                    {gender !== 'MALE' && purpose === "4" && <FormRow>
                        <FG50SmallL>
                            <label>{$$("nhis.examination.is_pregnant_label")}</label>
                            <BoolSwitch checked={isPregnant} onChange={() => {
                                setIsPregnant(!isPregnant);
                            }} labelOff={$$("nhis.no_label")} labelOn={$$("nhis.yes_label")} offFirst={false}/>
                        </FG50SmallL>
                        <FG50SmallR>
                            <label>{$$("nhis.examination.is_breastfeeding_label")}</label>
                            <BoolSwitch checked={isBreastFeeding} onChange={() => {
                                setIsBreastFeeding(!isBreastFeeding);
                            }} labelOff={$$("nhis.no_label")} labelOn={$$("nhis.yes_label")} offFirst={false}/>
                        </FG50SmallR>
                    </FormRow>}
                    {gender !== 'MALE' && purpose === "4" && isPregnant && <FG>
                        <Input type="number" setValue={setGestationalWeek}
                               label={"nhis.examination.gestational_week_label"}
                               value={gestationalWeek}
                               required={isPregnant}
                               min={1} max={99}/>
                    </FG>}

                    <div className={"low-shadow-container mt-3 p-3"}>
                        <SectionHeader>{$$("nhis.examination.main_diagnosis")}</SectionHeader>

                        <Diagnosis diagnosis={diagnosis} onUpdate={(d, i) => setDiagnosis(d)} i18n={i18n}/>
                    </div>

                    <div className={"low-shadow-container p-3 mt-4 mb-4"}>
                        <AdditionalDiagnosis diagnoses={additionalDiagnoses}
                                             onAddNew={() => {
                                                 setAdditionalDiagnoses([...additionalDiagnoses, {rank: additionalDiagnoses.length + 2}])
                                             }}
                                             onRemove={(i) => {
                                                 let updated = [...additionalDiagnoses];
                                                 updated.splice(i, 1);
                                                 updated.forEach((d, i) => d.rank = i + 2)
                                                 setAdditionalDiagnoses([...updated])
                                             }}
                                             onUpdate={(d, i) => {
                                                 let updated = [...additionalDiagnoses];
                                                 updated[i] = d
                                                 setAdditionalDiagnoses(updated)
                                             }}
                                             i18n={i18n}
                        />
                    </div>

                    <FG>
                        <InputTextArea setValue={setMedicalHistory} label={"nhis.examination.medical_history_label"}
                                       value={medicalHistory} maxLength={4000}/>
                    </FG>
                    <FG>
                        <InputTextArea setValue={setObjectiveCondition}
                                       label={"nhis.examination.objective_condition_label"}
                                       value={objectiveCondition} maxLength={4000}/>
                    </FG>
                    <FG>
                        <InputTextArea setValue={setTests}
                                       label={"nhis.examination.tests_label"}
                                       value={tests} />
                        <small className="form-text text-muted text-left"><span
                            className="fa fa-info-circle"/> {$$("hint_examination_tests")}
                        </small>
                    </FG>
                    <FormRow>
                        <FG50SmallL>
                            <label>{$$("nhis.examination.conclusion")}</label>
                            <Select placeHolder={$$("nhis.select")}
                                    emptyOption={true}
                                    enableClear={true}
                                    options={conclusionOptions}
                                    class="custom-select-sm"
                                    value={conclusion || ""}
                                    onChange={({value}) => {
                                        setConclusion(value);
                                    }}
                            />
                        </FG50SmallL>
                        <FG50SmallR>
                            <label>{$$("nhis.examination.dischargeDisposition")}</label>
                            <Select placeHolder={$$("nhis.select")}
                                    emptyOption={true}
                                    enableClear={true}
                                    options={dischargeDispositionOptions}
                                    class="custom-select-sm"
                                    value={dischargeDisposition || ""}
                                    onChange={({value}) => {
                                        setDischargeDisposition(value);
                                    }}
                            />
                        </FG50SmallR>
                    </FormRow>
                    <div className={"low-shadow-container p-3 mt-3 mb-3"}>
                        <SectionHeader>{$$("nhis.examination.therapy_section")}</SectionHeader>
                        <FG>
                            <label>{$$("nhis.examination.medications")}</label>
                            <SearchableSelectAsync
                                value={medicationCodeOption}
                                onSelect={setMedicationCodeOption}
                                options={medicationCodeOptions}
                                isMulti={true}
                                loadOptions={getMedicationCodeOptions}
                                placeholder={$$("nhis.search_placeholder")}
                            />
                        </FG>

                        <FG>
                            <InputTextArea setValue={setNote} label={"nhis.examination.therapy_notes_label"}
                                           value={note}
                                           maxLength={4000}/>
                        </FG>
                    </div>
                </fieldset>
                <Buttons
                    onCancel={onCancel}
                    encounter={encounter}/>
            </div>
        </form>
    )
}

export function Buttons({encounter, onCancel}) {
    return <div className="row" style={{"width": "100%"}}>
        <div style={{"width": "100%"}}>
            <div className="form-group"
                 style={{"float": "right", "marginRight": "10px"}}>
                <div style={{
                    "marginLeft": "7px", "display": "inline-block"
                }}>
                    <button type="submit"
                            className="btn btn-primary btn-block"
                    >
                        {$$("save_btn_label")}
                    </button>
                </div>

                <div style={{
                    "marginLeft": "7px", "display": "inline-block"
                }}>
                    <button type="button"
                            className="btn btn-secondary btn-block"
                            onClick={onCancel}>
                        {$$("cancel_btn")}
                    </button>
                </div>
            </div>
        </div>
    </div>
}

export function AdditionalDiagnosis({diagnoses, onAddNew, onRemove, onUpdate, i18n, 
        title="nhis.examination.additional_diagnoses", 
        lock=true, 
        additional=true,
        min=0,
        required=false,
        validated=false,
    }) {
    const [selectedIdx, setSelectedIdx] = useState(0);
    const [showRemoveDialog, setShowRemoveDialog] = useState(false)

    useEffect(() => {
        console.log("validated")
        if (diagnoses && diagnoses.length > 0 && required && validated) {
            if (!diagnoses[selectedIdx].code) {
                for (let i = 0; i < diagnoses; i++) {
                    const d = diagnoses[i];
                    if (!d.code) {
                        setSelectedIdx(i);
                        return;
                    }
                }
            }
        }
    }, [validated, diagnoses])


    if (!diagnoses || diagnoses.length === 0) {
        return <div className="mb-4"><SectionHeader>{$$(title)} <span
            className="float-right">
                    <button type="button" className="btn btn-sm btn-primary btn-sm" onClick={() => {
                        onAddNew()
                    }}>{$$("add")}</button>
        </span></SectionHeader>
        </div>
    }

    return <React.Fragment>
        <SectionHeader>{$$(title)} <span className="float-right">
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => {
                                onAddNew();
                                setSelectedIdx(selectedIdx + 1);
                            }}>{$$("add")}</button>
                            <button type="button" disabled={diagnoses.length == min} className="ml-2 btn btn-danger btn-sm" onClick={() => {
                                //setSelectedIdx(selectedIdx > 0 ? selectedIdx - 1 : 0);
                                //onRemove(selectedIdx)
                                setShowRemoveDialog(true);
                            }}>{$$("remove_label")}</button>
                </span></SectionHeader>
        <ButtonGroup>{diagnoses.map((d, i) => {
            return <Button key={i} type="button" size={"sm"} active={i === selectedIdx}
                           variant={
                                required && validated && !d.code ? "outline-danger is-invalid" :"outline-primary"
                           }
                           onClick={() => {
                               setSelectedIdx(i)
                           }}>{i + 1}</Button>
        })}
        </ButtonGroup>
        {diagnoses.map((d, i) => {
            return <div key={i} className={selectedIdx === i ? "" : "d-none"}>
                <Diagnosis diagnosis={d}
                           additional={additional}
                           lock={lock}
                           onUpdate={(d) => {
                               onUpdate(d, i)
                           }}
                           required={required}
                           i18n={i18n}
                />
            </div>
        })}
        {showRemoveDialog && <CenteredModal title={$$('delete_note_modal_header')}
                       show={true}
                       onHide={() => {
                           setShowRemoveDialog(false)
                       }}
                       onConfirm={() => {
                           setShowRemoveDialog(false)
                           setSelectedIdx(selectedIdx > 0 ? selectedIdx - 1 : 0);
                           onRemove(selectedIdx)
                       }}
                       backdropClassName={"confirm-over-other-modal"}
        >
            {$$('nhis.examination.remove_diagnosis_modal_message')}
        </CenteredModal>}
    </React.Fragment>
}

export function Diagnosis({diagnosis, onUpdate, additional, i18n, lock=false, required=false}) {
    const [rank, setRank] = useState(diagnosis.rank || "")
    const [onsetDateTime, setOnsetDateTime] = useState(diagnosis.onsetDateTime ? new Date(diagnosis.onsetDateTime) : null);

    const [primaryIcdCodeOption, setPrimaryIcdCodeOption] = useState("")
    const [additionalIcdCodeOptions, setAdditionalIcdCodeOptions] = useState([])

    const {
        icdCodeOptions, diagnosisRoleOptions, clinicalStatusOptions,
        verificationStatusOptions
    } = useNhisExaminationContext()

    const getIcdCodeOptions = useCallback((query, callback) => {
        callback(icdCodeOptions.filter(opt => opt.label.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1));
    }, [icdCodeOptions])

    useEffect(() => {
        if (diagnosis.code && primaryIcdCodeOption?.value !== diagnosis.code) {
            let opt = icdCodeOptions.find(c => c.value == diagnosis.code)
            setPrimaryIcdCodeOption(opt ? opt : null)
        } else {
            setPrimaryIcdCodeOption(null)
        }
    }, [diagnosis.code])

    useEffect(() => {
        if (diagnosis.additionalCode && diagnosis.additionalCode.toString() !== additionalIcdCodeOptions.map(p => p.value).toString()) {
            let opt = diagnosis.additionalCode.map(code => icdCodeOptions.find(c => c.value == code))
            setAdditionalIcdCodeOptions(opt)
        }
    }, [diagnosis.additionalCode])

    useEffect(() => {
        if (!diagnosis.use) {
            diagnosis.use = additional ? "4" : "3"
        }
    }, [diagnosis.use])

    return <div className="p-2">
        <FG>
            <label>{$$("nhis.examination.icdCode")}{required && <RequiredIndicator />}</label>
            <SearchableSelectAsync
                required={required}
                isClearable={true}
                options={icdCodeOptions}
                loadOptions={getIcdCodeOptions}
                class="custom-select-sm"
                value={primaryIcdCodeOption || ""}
                onSelect={(opt) => {
                    let d = _.cloneDeep(diagnosis)
                    d.code = opt ? opt.value : null
                    onUpdate(d)
                }}
                placeholder={$$("nhis.search_placeholder")}
            /></FG>

        <FG>
            <label>{$$("nhis.examination.additionalIcdCodes")}</label>
            <SearchableSelectAsync
                options={icdCodeOptions}
                loadOptions={getIcdCodeOptions}
                class="custom-select-sm"
                isMulti={true}
                value={additionalIcdCodeOptions}
                placeholder={$$("nhis.search_placeholder")}
                onSelect={(opts) => {
                    let d = _.cloneDeep(diagnosis)
                    d.additionalCode = opts.map(o => o.value)
                    onUpdate(d)
                }}
            />
        </FG>
        <FormRow>
            <FG50SmallL>
                <label>{$$("nhis.examination.role_label")}</label>
                <Select placeHolder={$$("nhis.select")}
                        emptyOption={true}
                        required={true}
                        options={diagnosisRoleOptions}
                        class="custom-select-sm"
                        disabled={lock}
                        value={diagnosis.use || ""}
                        onChange={({value}) => {
                            let d = _.cloneDeep(diagnosis)
                            d.use = value;
                            onUpdate(d);
                        }}
                />
            </FG50SmallL>
            <FG50SmallR>
                <label>{$$("nhis.examination.onsetDateTime")}</label>
                <div><DatePicker
                    locale={i18n.lang}
                    selected={diagnosis.onsetDateTime ? new Date(diagnosis.onsetDateTime) : null}
                    onChange={date => {
                        let d = _.cloneDeep(diagnosis)
                        d.onsetDateTime = date;
                        onUpdate(d);
                    }}
                    selectsStart
                    startDate={diagnosis.onsetDateTime ? new Date(diagnosis.onsetDateTime) : new Date()}
                    isClearable
                    dateFormat="dd/MM/yyyy"
                    placeholderText={$$('nhis.medication.onsetDateTime')}
                    className="form-control form-control-sm w-100"/>
                </div>
            </FG50SmallR>
        </FormRow>
        <FormRow>
            <FG50SmallL>
                <label>{$$("nhis.examination.clinicalStatus")}</label>
                <Select placeHolder={$$("nhis.select")}
                        emptyOption={true}
                        enableClear={true}
                        options={clinicalStatusOptions}
                        class="custom-select-sm"
                        value={diagnosis.clinicalStatus || ""}
                        onChange={({value}) => {
                            let d = _.cloneDeep(diagnosis)
                            d.clinicalStatus = value;
                            onUpdate(d);
                        }}
                />
            </FG50SmallL>
            <FG50SmallR>
                <label>{$$("nhis.examination.verificationStatus")}</label>
                <Select placeHolder={$$("nhis.select")}
                        emptyOption={true}
                        enableClear={true}
                        options={verificationStatusOptions}
                        class="custom-select-sm"
                        value={diagnosis.verificationStatus || ""}
                        onChange={({value}) => {
                            let d = _.cloneDeep(diagnosis)
                            d.verificationStatus = value;
                            onUpdate(d);
                        }}
                />
            </FG50SmallR>
        </FormRow>
        <FG>
            <InputTextArea setValue={(value) => {
                let d = _.cloneDeep(diagnosis)
                d.note = value;
                onUpdate(d);
            }} label={"nhis.examination.diagnosis_notes_label"} value={diagnosis.note || ""}
                           maxLength={4000}/>
        </FG>
    </div>
}

function updateNonEmpty(value, setter, newValue) {
    if (value || !newValue) {
        return;
    }
    setter(newValue);
}

function updateNonemptyDiagnosis(value, setter, newProp) {
    if (value) {
        return;
    }
}