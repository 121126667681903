import UserImage from './UserImage';

export default class EditableUserImage extends UserImage {

    componentDidMount() {
        this.fetchUserImage();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
           this.fetchUserImage();
        }
        if (this.imgRef.current && this.imgRef.current.data && prevProps.userID !== this.props.userID) {
            this.imgRef.current.src = "";
        }
        if (prevProps.userID !== this.props.userID) {
            this.fetchUserImage();
        }
    }
}