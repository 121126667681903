import React from "react";
import {getEmail} from "../../utils/userUtils";

export default function Email({object}) {
    const email = getEmail(object, null)
    if (email) {
        return <div style={{lineBreak: "anywhere"}}><a href={"mailto:" + email} target="_blank"
                                                       rel="noreferrer"
                                                       onClick={(e) => e.stopPropagation()}><i
            className="fa fa-envelope"/>
            <span className="ml-1">{email}</span></a></div>
    }
    return null;
}

