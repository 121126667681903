import React, {useEffect, useMemo, useState} from "react";
import {deleteNhisTemplate, listNhisTemplates} from "../../../../service/nhis_service";
import Modal from "react-bootstrap/Modal";
import no_appointments from "../../../../resources/images/no_appointments.png";
import {$$} from "../../../../helpers/localization";
import {infoUtils} from "../../../../utils/infoUtils";
import Search from "../../../shared/Search";
import Button from "react-bootstrap/Button";
import CenteredModal from "../../../shared/CenteredModal";
import ExamLinkIcon from "../../ExamLinkIcon";

export function NhisPrescriptionTemplates({onSelect, reload, onReloaded}) {
    const [showModal, setShowModal] = useState(false);
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        if (reload) {
            listNhisTemplates().then((res) => {
                setTemplates(res);
            })
            onReloaded();
        }
    }, [reload])

    if (templates.length === 0) {
        return null;
    }

    return <div>
        <ExamLinkIcon onClick={() => {
            setShowModal(true);
        }} labelKey="nhis.from_prescription_template"/>

        {showModal && templates.length > 0 && <TemplatesModal
            onClose={() => {
                setShowModal(false)
            }}
            onDelete={(t) => {
                deleteNhisTemplate(t.id).then(() => {
                    listNhisTemplates().then((res) => {
                        setTemplates(res);
                    })
                })
            }}
            onSelect={(t) => {
                setShowModal(false)
                onSelect(t)
            }}
            show={showModal}
            templates={templates}
        />}
    </div>
}

function TemplatesModal({templates, show, onSelect, onDelete, onClose}) {
    const [filteredTemplates, setFilteredTemplates] = useState()
    const [filter, setFilter] = useState("")
    const [forDelete, setForDelete] = useState();

    const filterTemplates = () => {
        setFilteredTemplates(templates.filter(template => template.template_name.toLowerCase().includes(filter.toLowerCase())));
    }

    useEffect(() => {
        filterTemplates();
    }, [filter])

    useEffect(() => {
        filterTemplates();
    }, [templates])


    if (forDelete) {
        return <div>
            <CenteredModal title={$$('delete_note_modal_header')}
                           show={true}
                           onHide={() => {
                               setForDelete(null)
                           }}
                           onConfirm={() => {
                               onDelete(forDelete);
                               setForDelete(null)
                           }
                           }
            >
                {$$('delete_template_modal_message')}
            </CenteredModal>
        </div>
    }

    return <div>
        <Modal backdrop={"static"} show={show}>
            <Modal.Body>
                <h4>{$$("nhis.pick_prescription_template")}</h4>
                <p className={"text-left"}>{$$("nhis.pick_prescription_template_message")}</p>
                <Search placeholder={$$("search_templates")} filterUsers={(e) => {
                    setFilter(e ? e : "");
                }}/>

                <div className={"scrollable-prescriptions"}>
                    <div className="list-group text-left shadow-boxes-list">
                        {filteredTemplates && filteredTemplates.length === 0 &&
                        <div><NoResults/></div>}
                        {filteredTemplates && filteredTemplates.map((t, i) => {
                            return <a key={i} className="list-group-item" href="#" onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onSelect(t);
                            }}>
                                <div className={"prescription-title"}>
                                    <h6>{t.template_name}</h6>
                                    <span style={{paddingTop: "5px"}} className={"la la-close"} onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setForDelete(t);
                                    }}/>
                                </div>

                                <div
                                    className="smaller-text base-font-color">{t.template_description}</div>
                            </a>
                        })}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" variant="primary" onClick={onClose}>{$$('cancel_btn')}</Button>
            </Modal.Footer>
        </Modal>
    </div>
}


function NoResults() {
    let noResults = {
        imgClass: 'sm-info-util',
        primaryLabelClass: 'no-lab-results-primary-label',
        secondaryLabelClass: 'no-lab-results-secondary-label',
        src: no_appointments,
        primaryLabel: $$('no_results_found'),
        secondaryLabel: ''
    }
    return infoUtils.noData(noResults);
}


