import React, {Component} from "react";
import {$$} from "../../helpers/localization";
import PropTypes from "prop-types";
import {
    downloadPeakFlowDiaryReport,
    getPeakFlowNormStats,
    getPeakFlowStats,
    preparePeakFlowDiaryReport
} from "../../service/summary_service";
import {connect} from "react-redux";
import {getSymptomName} from "../../utils/symptomUtils";
import no_data from "../../resources/images/no_data.png";
import {infoUtils} from "../../utils/infoUtils";
import {downloadUtils} from "../../utils/downloadUtils";
import DownloadEditDeleteButtons from "../shared/DownloadEditDeleteButtons";
import classNames from 'classnames'
import {displayPluralMedUnit} from "../../utils/converter";
import differenceInYears from "date-fns/differenceInYears"
import {dateTimeUtils} from "../../utils/dateTimeUtils";

const incrementValue = 20;

export class PeakFlowTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentEntry: null,
            allEntries: null,
            currentIndex: 0,
            downloadReportData: null,
            loading: true
        };
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.i18n !== prevProps.i18n) {
            this.peakFlowStats(this.props.selectedStart, this.props.selectedEnd);
        } else {
            this.onRangeUpdate(prevProps)
        }
    }

    componentDidMount() {
        this.onRangeUpdate({})
    }

    peakFlowStats = (fromDate, toDate) => {
        let healthIssueIds = []

        for (let i in this.props.selectedUser.health_issues) {
            healthIssueIds.push(this.props.selectedUser.health_issues[i].id)
        }

        let params = {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            fromDate: fromDate ? fromDate : 0,
            toDate: toDate ? toDate : 0,
            healthIssueIds: healthIssueIds
        }

        let paramsForReport = {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            fromDate: fromDate ? fromDate : 0,
            toDate: toDate ? toDate : 0,
            healthIssueIds: healthIssueIds,
        }

        preparePeakFlowDiaryReport(this.props.selectedUser.id, paramsForReport).then((res) => {
            let ReportData = res;
            ReportData.reportTitle = $$("peak_flow_report");
            ReportData.patientName = this.props.selectedUser.fullname;
            ReportData.fromDate = dateTimeUtils.getFormattedDateWithTime(fromDate);
            ReportData.toDate = dateTimeUtils.getFormattedDateWithTime(toDate);
            ReportData.datetime = dateTimeUtils.getFormattedDateWithTime(new Date());

            let standardSymptoms = ["inhaler_is_used", "had_asthma_symptoms", "had_night_asthma_symptoms", "cannot_keep_daily_activities"]

            for (let i in ReportData.symptomsLabels) {
                for (let j in ReportData.symptomsLabels[i]) {
                    if (standardSymptoms.includes(ReportData.symptomsLabels[i][j].toLowerCase())) {
                        ReportData.symptomsLabels[i][j] = $$(ReportData.symptomsLabels[i][j].toLowerCase())
                    } else {
                        ReportData.symptomsLabels[i][j] = getSymptomName(ReportData.symptomsLabels[i][j], this.props.nomenclature, this.props.i18n.lang)
                    }
                }
            }

            for (let i in ReportData.medicationsLabels) {
                for (let j in ReportData.medicationsLabels[i]) {
                    let strings = ReportData.medicationsLabels[i][j].split("|");
                    ReportData.medicationsLabels[i][j] = strings[0] + ", " + displayPluralMedUnit(strings[1]);
                }
            }

            this.setState({
                downloadReportData: ReportData
            })
        })

        getPeakFlowStats(this.props.selectedUser.id, params).then((res) => {
            this.setState({allEntries: res, currentEntry: res[res.length - 1], currentIndex: res.length - 1})
        }).catch(err => {
            this.setState({loading: false, allEntries: [], currentEntry: undefined, currentIndex: 0});
        })

        let pfnParams = {...params}
        pfnParams.heightCm = this.props.medicalProfile.height;
        pfnParams.gender = this.props.selectedUser.gender;
        pfnParams.age = calculateAgeInYears(this.props.selectedUser.birthday)
        this.setState({loading: true})
        getPeakFlowNormStats(this.props.selectedUser.id, pfnParams).then((res) => {
            this.setState({norm: res, loading: false})
        }).catch(err => {
            this.setState({loading: false, allEntries: [], currentEntry: undefined, currentIndex: 0});
        })
    }

    downloadPeakFlowDiary = () => {
        downloadPeakFlowDiaryReport(this.props.i18n.lang, this.state.downloadReportData).then((file) => {
            downloadUtils.download(file, "Peak Flow Diary  - " +
                this.props.selectedUser.fullname +
                " - " +
                this.state.downloadReportData.fromDate +
                " to " +
                this.state.downloadReportData.toDate +
                ".pdf");
        });
    }

    getAmPmLabel = () => {
        let cells = [];
        let am = $$("am");
        let pm = $$("pm");
        for (let a = 0; a < 14; a++) {
            cells.push(<td key={a}
                           className={classNames("am-pm-labels table-info-label week-table-header date-table-header", {"base-grey-1-bg": a % 2 !== 0})}>{a % 2 === 0 ? am : pm}</td>)
        }

        return <tr>
            <td></td>
            {cells}
        </tr>
    }

    getTableDates = () => {
        return <tr>
            <th className={"w-100"}>
                <div className={"next-previous-lbl"}>
                    <button disabled={this.state.currentIndex === 0} onClick={this.backButtonClicked} type="button"
                            className={"btn btn-secondary btn-sm"}>
                        {$$("previous")}
                    </button>
                    &nbsp;
                    &nbsp;
                    <button
                        disabled={this.state.allEntries && this.state.currentIndex === this.state.allEntries.length - 1}
                        onClick={this.forwardButtonClicked} type="button"
                        className={"btn btn-secondary btn-sm"}>
                        {$$("next")}
                    </button>
                </div>
            </th>
            <th colSpan={2} className={"table-info-label week-table-header date-table-header"}>
                {this.state.currentEntry ? dateTimeUtils.getFormattedDateWithTimeWithOffset(this.state.currentEntry.weekStartDate, 0) : ""}</th>
            <th colSpan={2} className={"table-info-label week-table-header date-table-header"}>
                {this.state.currentEntry ? dateTimeUtils.getFormattedDateWithTimeWithOffset(this.state.currentEntry.weekStartDate, 1) : ""}</th>
            <th colSpan={2} className={"table-info-label week-table-header date-table-header"}>
                {this.state.currentEntry ? dateTimeUtils.getFormattedDateWithTimeWithOffset(this.state.currentEntry.weekStartDate, 2) : ""}</th>
            <th colSpan={2} className={"table-info-label week-table-header date-table-header"}>
                {this.state.currentEntry ? dateTimeUtils.getFormattedDateWithTimeWithOffset(this.state.currentEntry.weekStartDate, 3) : ""}</th>
            <th colSpan={2} className={"table-info-label week-table-header date-table-header"}>
                {this.state.currentEntry ? dateTimeUtils.getFormattedDateWithTimeWithOffset(this.state.currentEntry.weekStartDate, 4) : ""}</th>
            <th colSpan={2} className={"table-info-label week-table-header date-table-header"}>
                {this.state.currentEntry ? dateTimeUtils.getFormattedDateWithTimeWithOffset(this.state.currentEntry.weekStartDate, 5) : ""}</th>
            <th colSpan={2} className={"table-info-label week-table-header date-table-header"}>
                {this.state.currentEntry ? dateTimeUtils.getFormattedDateWithTimeWithOffset(this.state.currentEntry.weekStartDate, 6) : ""}</th>
        </tr>
    }


    getTableHeader = () => {
        return <tr>
            <th></th>
            <th className={"table-info-label week-table-header"} colSpan={2}> {$$("mon")}</th>
            <th className={"table-info-label week-table-header"} colSpan={2}>{$$("tue")}</th>
            <th className={"table-info-label week-table-header"} colSpan={2}>{$$("wed")}</th>
            <th className={"table-info-label week-table-header"} colSpan={2}>{$$("thu")}</th>
            <th className={"table-info-label week-table-header"} colSpan={2}>{$$("fri")}</th>
            <th className={"table-info-label week-table-header"} colSpan={2}>{$$("sat")}</th>
            <th className={"table-info-label week-table-header"} colSpan={2}>{$$("sun")}</th>
        </tr>
    }

    getMarkedValue = (index) => {
        return this.state.currentEntry.weekDayNotesIndexes[index] > 0 ?
            <div
                title={parseFloat(this.state.currentEntry.weekDayValues[index]).toFixed(2).toString() + " " + $$("liters_per_minute")}
                className={"marked-cell-with-content"}>{this.state.currentEntry.weekDayNotesIndexes[index]}</div> :
            <div
                title={parseFloat(this.state.currentEntry.weekDayValues[index]).toFixed(2).toString() + " " + $$("liters_per_minute")}
                className={"marked-cell-with-content"}/>
    }

    getMeasurementRow = (item, index) => {
        return <tr key={index}>
            <td className={"table-info-label week-table-header range-cell"}>{item}</td>
            <td className={"week-table-header"}>
                {Math.floor(this.state.currentEntry.weekDayValues[0] / incrementValue) * incrementValue === item ?
                    this.getMarkedValue(0) : ""}
            </td>
            <td className={"week-table-header base-grey-1-bg"}>
                {Math.floor(this.state.currentEntry.weekDayValues[1] / incrementValue) * incrementValue === item ?
                    this.getMarkedValue(1) : ""}
            </td>
            <td className={"week-table-header"}>
                {Math.floor(this.state.currentEntry.weekDayValues[2] / incrementValue) * incrementValue === item ?
                    this.getMarkedValue(2) : ""}
            </td>
            <td className={"week-table-header base-grey-1-bg"}>
                {Math.floor(this.state.currentEntry.weekDayValues[3] / incrementValue) * incrementValue === item ?
                    this.getMarkedValue(3) : ""}
            </td>
            <td className={"week-table-header"}>
                {Math.floor(this.state.currentEntry.weekDayValues[4] / incrementValue) * incrementValue === item ?
                    this.getMarkedValue(4) : ""}
            </td>
            <td className={"week-table-header base-grey-1-bg"}>
                {Math.floor(this.state.currentEntry.weekDayValues[5] / incrementValue) * incrementValue === item ?
                    this.getMarkedValue(5) : ""}
            </td>
            <td className={"week-table-header"}>
                {Math.floor(this.state.currentEntry.weekDayValues[6] / incrementValue) * incrementValue === item ?
                    this.getMarkedValue(6) : ""}
            </td>
            <td className={"week-table-header base-grey-1-bg"}>
                {Math.floor(this.state.currentEntry.weekDayValues[7] / incrementValue) * incrementValue === item ?
                    this.getMarkedValue(7) : ""}
            </td>
            <td className={"week-table-header"}>
                {Math.floor(this.state.currentEntry.weekDayValues[8] / incrementValue) * incrementValue === item ?
                    this.getMarkedValue(8) : ""}
            </td>
            <td className={"week-table-header base-grey-1-bg"}>
                {Math.floor(this.state.currentEntry.weekDayValues[9] / incrementValue) * incrementValue === item ?
                    this.getMarkedValue(9) : ""}
            </td>
            <td className={"week-table-header"}>
                {Math.floor(this.state.currentEntry.weekDayValues[10] / incrementValue) * incrementValue === item ?
                    this.getMarkedValue(10) : ""}
            </td>
            <td className={"week-table-header base-grey-1-bg"}>
                {Math.floor(this.state.currentEntry.weekDayValues[11] / incrementValue) * incrementValue === item ?
                    this.getMarkedValue(11) : ""}
            </td>
            <td className={"week-table-header"}>
                {Math.floor(this.state.currentEntry.weekDayValues[12] / incrementValue) * incrementValue === item ?
                    this.getMarkedValue(12) : ""}
            </td>
            <td className={"week-table-header base-grey-1-bg"}>
                {Math.floor(this.state.currentEntry.weekDayValues[13] / incrementValue) * incrementValue === item ?
                    this.getMarkedValue(13) : ""}
            </td>
        </tr>
    }

    getFlowScale = () => {
        let maxValue = this.state.currentEntry ? Math.floor(this.state.currentEntry.maxPeakFlow / 20) * 20 : 1000;
        let minValue = this.state.currentEntry ? Math.floor(this.state.currentEntry.minPeakFlow / 20) * 20 : 100;

        maxValue = maxValue + incrementValue < 1000 ? maxValue + (incrementValue * 1) : 1000
        minValue = minValue - incrementValue <= 100 ? 100 : minValue - (incrementValue * 1)

        let scaleValues = []

        for (let i = maxValue; i >= minValue; i = i - incrementValue) {
            scaleValues.push(i)
        }

        return scaleValues
    }

    getFormattedNote = (item, index) => {
        return <li key={index}>{item}</li>
    }

    getSummary = () => {
        return <tr>
            <td className={"table-info-label date-table-header"}>{$$("summary")}</td>
            <td className={"date-table-header font-weight-normal"} colSpan={14}>
                {$$("min")}: {parseFloat(this.state.currentEntry.minPeakFlow).toFixed(2)} {$$("liters_per_minute")} &emsp; &emsp;
                {$$("max")}: {parseFloat(this.state.currentEntry.maxPeakFlow).toFixed(2)} {$$("liters_per_minute")} &emsp; &emsp;
                {$$("average")}: {parseFloat(this.state.currentEntry.avgPeakFlow).toFixed(2)} {$$("liters_per_minute")}
            </td>
        </tr>
    }

    getNotes = () => {
        let Notes = this.state.currentEntry ? this.state.currentEntry.notes.map((item, index) => {
            return this.getFormattedNote(item, index)
        }) : ""
        return <tr>
            <td className={"table-info-label date-table-header"}>{$$("notes")}</td>
            <td className={"date-table-header"} colSpan={14}>
                <ol style={{paddingInlineStart: "1rem"}}>{Notes}</ol>
            </td>
        </tr>
    }

    getSymptoms = () => {
        let standardSymptoms = ["inhaler_is_used", "had_asthma_symptoms", "had_night_asthma_symptoms", "cannot_keep_daily_activities"]

        return this.state.currentEntry ? this.state.currentEntry.symptomsLabels.map((item, index) => {
            if (standardSymptoms.includes(item.toLowerCase())) {
                return <tr key={index} className={index % 2 !== 0 ? "base-grey-1-bg" : ""}>
                    <td className={"table-info-label date-table-header symptom-name-cell"}>{$$(item.toLowerCase())}</td>
                    <td className={"week-table-header"} colSpan={2}>
                        {this.state.currentEntry && this.state.currentEntry.weekDaySymptoms[0][index] !== 0 ?
                            <i style={{fontSize: "11px"}} className='fas fa-check'/> : ""}
                    </td>
                    <td className={"week-table-header"} colSpan={2}>
                        {this.state.currentEntry && this.state.currentEntry.weekDaySymptoms[1][index] !== 0 ?
                            <i style={{fontSize: "11px"}} className='fas fa-check'/> : ""}

                    </td>
                    <td className={"week-table-header"} colSpan={2}>
                        {this.state.currentEntry && this.state.currentEntry.weekDaySymptoms[2][index] !== 0 ?
                            <i style={{fontSize: "11px"}} className='fas fa-check'/> : ""}
                    </td>
                    <td className={"week-table-header"} colSpan={2}>
                        {this.state.currentEntry && this.state.currentEntry.weekDaySymptoms[3][index] !== 0 ?
                            <i style={{fontSize: "11px"}} className='fas fa-check'/> : ""}

                    </td>
                    <td className={"week-table-header"} colSpan={2}>
                        {this.state.currentEntry && this.state.currentEntry.weekDaySymptoms[4][index] !== 0 ?
                            <i style={{fontSize: "11px"}} className='fas fa-check'/> : ""}

                    </td>
                    <td className={"week-table-header"} colSpan={2}>
                        {this.state.currentEntry && this.state.currentEntry.weekDaySymptoms[5][index] !== 0 ?
                            <i style={{fontSize: "11px"}} className='fas fa-check'/> : ""}

                    </td>
                    <td className={"week-table-header"} colSpan={2}>
                        {this.state.currentEntry && this.state.currentEntry.weekDaySymptoms[6][index] !== 0 ?
                            <i style={{fontSize: "11px"}} className='fas fa-check'/> : ""}
                    </td>
                </tr>
            } else {
                return <tr key={index} className={index % 2 !== 0 ? "base-grey-1-bg m-row" : "m-row"}>
                    <td className={"table-info-label date-table-header symptom-name-cell"}>{getSymptomName(item, this.props.nomenclature, this.props.i18n.lang)}</td>
                    <td className={"week-table-header"} colSpan={2}>
                        {this.state.currentEntry && this.state.currentEntry.weekDaySymptoms[0][index] !== 0 ?
                            this.getStrengthIndicator(this.state.currentEntry.weekDaySymptoms[0][index]) : ""}
                    </td>
                    <td className={"week-table-header"} colSpan={2}>
                        {this.state.currentEntry && this.state.currentEntry.weekDaySymptoms[1][index] !== 0 ?
                            this.getStrengthIndicator(this.state.currentEntry.weekDaySymptoms[1][index]) : ""}
                    </td>
                    <td className={"week-table-header"} colSpan={2}>
                        {this.state.currentEntry && this.state.currentEntry.weekDaySymptoms[2][index] !== 0 ?
                            this.getStrengthIndicator(this.state.currentEntry.weekDaySymptoms[2][index]) : ""}
                    </td>
                    <td className={"week-table-header"} colSpan={2}>
                        {this.state.currentEntry && this.state.currentEntry.weekDaySymptoms[3][index] !== 0 ?
                            this.getStrengthIndicator(this.state.currentEntry.weekDaySymptoms[3][index]) : ""}
                    </td>
                    <td className={"week-table-header"} colSpan={2}>
                        {this.state.currentEntry && this.state.currentEntry.weekDaySymptoms[4][index] !== 0 ?
                            this.getStrengthIndicator(this.state.currentEntry.weekDaySymptoms[4][index]) : ""}
                    </td>
                    <td className={"week-table-header"} colSpan={2}>
                        {this.state.currentEntry && this.state.currentEntry.weekDaySymptoms[5][index] !== 0 ?
                            this.getStrengthIndicator(this.state.currentEntry.weekDaySymptoms[5][index]) : ""}
                    </td>
                    <td className={"week-table-header"} colSpan={2}>
                        {this.state.currentEntry && this.state.currentEntry.weekDaySymptoms[6][index] !== 0 ?
                            this.getStrengthIndicator(this.state.currentEntry.weekDaySymptoms[6][index]) : ""}
                    </td>
                </tr>
            }
        }) : null
    }

    getStrengthIndicator = (strength) => {
        switch (strength) {
            case 1:
                return <>
                    <i style={{fontSize: "11px", color: "#ffd556"}} className='fas fa-circle'/>
                </>;
            case 2:
                return <>
                    <i style={{fontSize: "11px", color: "#ff9a40"}} className='fas fa-circle'/>
                    <i style={{fontSize: "11px", color: "#ff9a40"}} className='fas fa-circle'/>

                </>;
            case 3:
                return <>
                    <i style={{fontSize: "11px", color: "#ea352c"}} className='fas fa-circle'/>
                    <i style={{fontSize: "11px", color: "#ea352c"}} className='fas fa-circle'/>
                    <i style={{fontSize: "11px", color: "#ea352c"}} className='fas fa-circle'/>
                </>;
            default:
                return <></>;
        }
    }

    getMedicationsHeader = () => {
        if (this.state.currentEntry?.medicationsLabels?.length > 0) {
            return <tr className={this.state.currentEntry.symptomsLabels.length % 2 === 0 ? "" : "base-grey-1-bg"}>
                <td className={"table-info-label week-table-header"} colSpan={15}>{$$("Medications")}</td>
            </tr>
        }
        return null;
    }

    getMedications = () => {
        if (this.state.currentEntry && this.state.currentEntry.medicationsLabels?.length > 0) {
            let startGrey = this.state.currentEntry.symptomsLabels.length % 2 === 0;
            return this.state.currentEntry.medicationsLabels.map((item, idx) => {
                let items = item.split("|");
                return <tr key={idx}
                           className={classNames({"base-grey-1-bg": startGrey ? idx % 2 === 0 : idx % 2 !== 0})}>
                    <td className={"p-2 table-info-label"}>{items[0]}, {displayPluralMedUnit(items[1])}</td>
                    {
                        [...Array(7).keys()].map((day) => {
                            let weekDayMedicationsDoseElement = this.state.currentEntry.weekDayMedicationsDoses[day][idx];
                            weekDayMedicationsDoseElement = weekDayMedicationsDoseElement > 0 ? weekDayMedicationsDoseElement : "";
                            return <td colSpan={2} className={"text-center p-2"}
                                       key={day}>{weekDayMedicationsDoseElement}</td>
                        })
                    }
                </tr>
            })
        }
        return null;
    }

    getPeakFlowTable = () => {
        let itemList = this.getFlowScale().map((item, index) => {
            return this.getMeasurementRow(item, index)
        })

        return <>
            <table className={"peak-flow-table table-bordered w-100"}>
                <thead>
                {this.getTableDates()}
                {this.getTableHeader()}
                </thead>
                <tbody>
                {this.getSymptoms()}
                {this.getMedicationsHeader()}
                {this.getMedications()}
                {this.getAmPmLabel()}
                {itemList}
                {this.getSummary()}
                {this.getNotes()}
                </tbody>
            </table>
            <br/>
            <DownloadEditDeleteButtons
                handleDownloadClick={this.downloadPeakFlowDiary}
            />
            <span className={"user-profile-link"}
                  onClick={this.downloadPeakFlowDiary}>{$$("download_peak_flow")} </span>
        </>
    }

    onRangeUpdate = (prevProps) => {
        if (this.props.selectedStart && this.props.selectedEnd && (this.props.selectedStart !== prevProps.selectedStart || this.props.selectedEnd !== prevProps.selectedEnd)) {
            this.peakFlowStats(this.props.selectedStart, this.props.selectedEnd)
        }
    }

    backButtonClicked = () => {
        this.setState({
            currentEntry: this.state.allEntries[this.state.currentIndex - 1],
            currentIndex: this.state.currentIndex - 1
        })
    }

    forwardButtonClicked = () => {
        this.setState({
            currentEntry: this.state.allEntries[this.state.currentIndex + 1],
            currentIndex: this.state.currentIndex + 1
        })
    }

    noResults = () => {
        let emptyStatsResultObj = {
            imgClass: 'no-lab-results-img',
            primaryLabelClass: 'no-lab-results-primary-label',
            secondaryLabelClass: 'no-lab-results-secondary-label',
            src: no_data,
            primaryLabel: $$('no_records_found'),
            secondaryLabel: ''
        }
        return infoUtils.noData(emptyStatsResultObj);
    }

    render() {
        return (
            <div>
                {!this.state.loading && this.state.norm && <h5>{$$("pf_norm_title")}</h5>}
                {!this.state.loading && this.state.norm && <table className="peak-flow-table w-100 table-bordered mb-3">
                    <thead>
                    <tr>
                        <th className="table-info-label text-center">{$$("pf_norm")}</th>
                        <th className="table-info-label text-center">{$$("pf_norm_stddev")}</th>
                        <th className="table-info-label text-center">{$$("pf_norm_count")}</th>
                        <th className="table-info-label text-center">{$$("pf_norm_max")}</th>
                        <th className="table-info-label text-center">{$$("pf_norm_standard")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className={"font-weight-normal"}>
                        <td className="text-center">{this.state.norm.norm ? Math.round(this.state.norm.norm) : "-"}</td>
                        <td className="text-center">{this.state.norm.normStdDev ? Math.round(this.state.norm.normStdDev) : "-"}</td>
                        <td className="text-center">{this.state.norm.normCount ? this.state.norm.normCount : "-"}</td>
                        <td className="text-center">{this.state.norm.max ? this.state.norm.max : "-"}</td>
                        <td className="text-center">{this.state.norm.normStandard ? this.state.norm.normStandard : "-"}</td>
                    </tr>
                    </tbody>
                </table>}
                {this.state.loading && <div className={"report-loader"}/>}
                {this.state.allEntries && this.state.allEntries.length > 0 && !this.state.loading &&
                    <h5>{$$("peak_flow_weekly_diary")}</h5>}
                {this.state.allEntries && this.state.allEntries.length > 0 && !this.state.loading && this.getPeakFlowTable(this.state.currentEntry)}
                {this.state.allEntries && this.state.allEntries.length === 0 && !this.state.loading && this.noResults()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        nomenclature: state.nomenclature,
        settings: state.settings.data,
        i18n: state.language.selected,
        medicalProfile: state.medicalProfile.data
    }
}

PeakFlowTab.propTypes = {
    i18n: PropTypes.any,
    nomenclature: PropTypes.any,
    settings: PropTypes.any,
    selectedStart: PropTypes.any,
    selectedEnd: PropTypes.any,
    medicalProfile: PropTypes.any,
    selectedUser: PropTypes.any
}

export default connect(mapStateToProps, {})(PeakFlowTab)


function calculateAgeInYears(birthdate) {
    if (birthdate === 0) {
        return 0;
    }

    return differenceInYears(new Date(), birthdate)
}
