import React, {Component} from "react";
import {connect} from "react-redux";
import {clearSelectedAppointmentInProgress} from "../../actions/users_actions";
import PatientVerticalCard from "../patient-vertical-card/PatientVerticalCard";
import TabsContainer from "../main/TabsContainer";
import VideoCall from "../videocall/VideoCall";
import {updateVideoData} from "../../actions/video_actions";
import {$$} from "../../helpers/localization";
import PropTypes from "prop-types";
import {VideoApp} from "./VideoApp";
import {appointmentUtils} from "../../utils/appointmentUtils";
import {Modal} from "react-bootstrap";
import {Routes} from "../../constants/routes";
import history from "../../helpers/history";
import _ from "underscore";
import {medicalHistoryHelper} from "../../helpers/medical_history_helper";
import {RETURN_TO_PATIENT_MED_HISTORY} from "../../constants/pages";
import BackButton from "../shared/BackButton";
import {restorePreviousFilters} from "../../actions/filter_actions";


class AppointmentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: "2-col-no-split"
        }
        this._isMounted = false;
    }

    /**
     * If there was an appointment in progress clear the selected appontment in progress
     */
    componentWillUnmount() {
        this.props.clearSelectedAppointmentInProgress();
        this._isMounted = false;
        if (this.repeat) {
            clearInterval(this.repeat);
        }
    }

    componentDidMount() {
        if (_.isEmpty(this.props.appointment)) {
            history.push(Routes.LOGIN);
            return;
        }

        this._isMounted = true;
        if (this.props.appointment.text_only) {
            const app = this.props.appointment;
            this.repeat = setInterval(() => {
                if (appointmentUtils.isOverdueTextConsultation(app)) {
                    if (this._isMounted) {
                        this.setState({showOverdueAlert: true})
                    }
                    clearInterval(this.repeat);
                    this.repeat = null;
                }
            }, 1000)
        }
    }

    historyCol() {
        return <div key="content-tabs col-margin-left col-margin-right">
            <TabsContainer i18n={this.props.i18n} selectedUser={this.props.medicalHistory} clearOnCancel={true}/>
        </div>
    }

    twoColNoSplit() {
        return <div className="history d-flex flex-container flex-row history-two-col-no-split">
            <div className="main-col">
                <h2 className={"col-margin-left"}>
                    {this.props.location.state?.fromPage === "/history"
                        && <> <BackButton onClick={() => {
                            this.props.restorePreviousFilters(true)
                            medicalHistoryHelper.prepareMedicalHistoryData(RETURN_TO_PATIENT_MED_HISTORY, this.props.selectedUser.data, null, Routes.HISTORY);
                            return true;
                        }} title={$$("medical_history")}/>  &nbsp;</>}

                    {appointmentUtils.getExaminationName(this.props.appointment.appointment_price)}</h2>

                {this.historyCol()}
            </div>
            <div>
                <div className="video-wrap">
                    {this.props.appointment?.appointment_price?.encounter_price_type !== "TEXT_ONLY" &&
                        (this.props.appointment?.appointment_price?.location_type !== 'ON_SITE' || this.props.appointment?.override_location_to_online)
                    && this.video()}
                </div>
                <PatientVerticalCard hide={false}
                                     selectedUser={this.props.selectedUser.data}
                                     i18n={this.props.i18n}/>
            </div>
        </div>
    }

    twoColSplit() {
        return <div className="history d-flex flex-container flex-row history-two-col-split">
            <div className="main-col">
                <div className="video-wrap">
                    {this.video()}
                </div>
                {this.historyCol()}
            </div>
            <div>
                <PatientVerticalCard hide={false}
                                     selectedUser={this.props.selectedUser.data}
                                     i18n={this.props.i18n}/>
            </div>
        </div>
    }

    videoFull() {
        return <div className="history d-flex flex-container flex-row history-video-only">
            <div className="main-col d-none">
                {this.historyCol()}
            </div>
            <div className="w-100 video-wrap">
                {this.video()}
            </div>
        </div>
    }

    shouldDisableVideoCall(a) {
        const isFree = a.appointment_price.price_cents === 0;
        const isPaid = a.payment_status === 'COMPLETED';
        return !(isFree || isPaid);
    }

    video() {

        if (this.shouldDisableVideoCall(this.props.appointment)) {
            return <div className="rounded-borders background-color-blue-2 p-4 mt-4" key="key-video-container"
                        style={{width: "330px"}}>
                {$$("unpaid_video_explanation")}
            </div>

        }

        if (!this.props.videoData.initiate_call) {
            return <div className="video-container" key="key-video-container">
                <VideoCall startVideoCall={this.props.videoData.initiate_call}
                           incomingVideoCall={false}
                           onStopCall={() => {
                               this.props.updateVideoData({initiate_call: false})
                           }}
                           incomingVideoCallStart={() => {
                           }}
                           userToChatFullName={this.props.selectedUser.data.fullname}
                           userToChatId={this.props.selectedUser.data.id}
                           onStartCall={() => {
                               this.props.updateVideoData({initiate_call: true});
                           }}
                           videoMessages={[]}
                           currentView={this.state.view}
                           toFull={this.toFull}
                           toSplit={this.toSplit}
                           toRight={this.toRight}/>
            </div>
        }

        return <VideoApp name={this.props.userInfo.id + ":" + this.props.userInfo.fullname}
                         roomName={this.props.appointment.id}
                         groupEvent={false}
                         onHangup={() => this.props.updateVideoData({initiate_call: false})}
                         currentView={this.state.view}
                         toFull={this.toFull}
                         toSplit={this.toSplit}
                         toRight={this.toRight}
        />
    }

    toFull = () => {
        this.setState({view: "video-only"});
    }

    toSplit = () => {
        this.setState({view: "2-col-split"})
    }

    toRight = () => {
        this.setState({view: "2-col-no-split"})
    }

    closeOverduePopup = () => {
        history.push(Routes.DASHBOARD);
    }

    render() {
        if (_.isEmpty(this.props.appointment)) {
            return <></>
        }
        let body;
        switch (this.state.view) {
            case "2-col-no-split":
                body = this.twoColNoSplit();
                break;
            case "video-only":
                body = this.videoFull();
                break;
            case "2-col-split":
                body = this.twoColSplit();
                break;
        }
        return (
            <div className="med-history-page appointment-page">
                {body}

                {this.state.showOverdueAlert &&
                    <Modal show={this.state.showOverdueAlert} onHide={this.closeOverduePopup}
                           dialogClassName="w-100 day-picker-popup"
                           backdrop="static"
                    >
                        <Modal.Header>
                            <h5 className={"text-danger"}>{$$("warning_title")}</h5>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{$$("overdue_modal_body")}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="text-center w-100">
                                <button className="btn btn-danger"
                                        onClick={this.closeOverduePopup}>{$$("close_btn_label")}</button>
                            </div>
                        </Modal.Footer>
                    </Modal>}
            </div>
        );
    }
}

AppointmentPage.propTypes = {
    appointment: PropTypes.object,
    clearSelectedAppointmentInProgress: PropTypes.func,
    history: PropTypes.object,
    medicalHistory: PropTypes.object,
    i18n: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    selectedUser: PropTypes.object,
    updateVideoData: PropTypes.func,
    restorePreviousFilters: PropTypes.func,
    videoData: PropTypes.object,
    userInfo: PropTypes.object
}

const mapStateToProps = (state) => {
    return {
        i18n: state.language,
        selectedUser: state.selectedUser,
        appointment: state.selectedAppointmentInProgress,
        medicalHistory: state.medicalHistory,
        videoData: state.video.data,
        userInfo: state.userInfo.data
    }
};

export default connect(mapStateToProps, {updateVideoData, clearSelectedAppointmentInProgress,restorePreviousFilters})(AppointmentPage)