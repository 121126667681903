import React, {useEffect, useRef, useState} from "react";
import {clinicService} from "../../../../service/clinic_service";
import {CONVERTER} from "../../../../utils/converter";
import {dateAsStr, NHIS_GENDER_OPTIONS, NHIS_ID_TYPE_OPTIONS} from "./NhisPrescription";
import {$$} from "../../../../helpers/localization";
import FormGroup from "react-bootstrap/FormGroup";
import DatePicker from "react-datepicker";
import Select from "../../../shared/Select";
import {getResolvedOptions} from "../../../../constants/select_options";
import {countryOptions} from "../../../../constants/countries";
import SearchableSelect from "./SearchableSelect";
import {components} from "react-select";

export default function PatientProviderForm({exam, subject, provider, specialtyOptions, countyOptions, orgId, onUpdate, encounter, lang, ekatteOptions, providerRhifAreaNumberOptions}) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [idType, setIdType] = useState("1");
    const [personalId, setPersonalId] = useState("");
    const [birthDate, setBirthDate] = useState();
    const [gender, setGender] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");

    const [pmi, setPmi] = useState("");
    const [qualification, setQualification] = useState("");
    const [qualification_nhifCode, setQualification_nhifCode] = useState("");
    const [practiceNumber, setPracticeNumber] = useState("");
    const [phone, setPhone] = useState("");
    const [qualificationOptions, setQualificationOptions] = useState(specialtyOptions)
    const [county, setCounty] = useState("")

    const [personalInfo, setPersonalInfo] = useState();
    const [formClass, setFormClass] = useState("has-custom-validation");

    const [ekatte, setEkatte] = useState("");
    const [providerRhifAreaNumber, setProviderRhifAreaNumber] = useState("");
    const isMounted = useRef(true)

    useEffect(() => {
        return () => {
            isMounted.current = false;
    }}, [])

    const populateMissingFromSelectedUser = (patient) => {
        if (subject.city && !patient.patientCity) {
            setCity(subject.city);
        }
        if (subject.country && !patient.patientCountry) {
            setCountry(subject.country.toUpperCase());
        }
        if (subject.gender && !patient.patientGender) {
            switch (subject.gender) {
                case 'MALE':
                    setGender("1");
                    break;
                case 'FEMALE':
                    setGender("2");
                    break;
            }
        }
        if (subject.birthday && !patient.patientBirthDate) {
            setBirthDate(new Date(subject.birthday))
        }
        if (subject.fullname && !patient.patientName) {
            let names = subject.fullname.split(" ").map(f => f.trim());
            if (names.length > 0) {
                setFirstName(names[0]);
            }
            if (names.length > 1) {
                setLastName(names[names.length - 1]);
            }
        }
    }

    const toPatientIdType = (patient) => {
        switch (patient.patientIDType) {
            case "EGN":
                return "1";
            case "LNZ":
                return "2";
            case "SSN":
                return "3";
            case "PASS":
                return "4";
            case "OTHER":
                return "5";
            case "NEWBORN":
                return "6";
        }
    }

    const fromPatientIdType = (idType) => {
        switch (idType) {
            case "1":
                return "EGN";
            case "2":
                return "LNZ";
            case "3":
                return "SSN";
            case "4":
                return "PASS";
            case "5":
                return "OTHER";
            case "6":
                return "NEWBORN";
        }
    }

    useEffect(() => {
        if (exam.performer) {
            setPmi(exam.performer.pmi)
            if (provider.nhis_specialties && provider.nhis_specialties.length > 0) {
                let filteredOptions = qualificationOptions.filter(opt => provider.nhis_specialties.includes(opt.value));
                setQualificationOptions(filteredOptions)
            }
            setQualification(qualificationOptions.find(o => o.value === exam.performer.qualification));
            setQualification_nhifCode(exam.performer.qualification_nhifCode)
            setPracticeNumber(exam.performer.practiceNumber)
            setPhone(exam.performer.phone)

        } else {
            setPmi(provider.uin || "");
            let phoneNumber = provider.phone_numbers && provider.phone_numbers.length > 0 ? provider.phone_numbers[0] : "";
            phoneNumber = phoneNumber.replaceAll(/[-\s/]/gi, "");
            setPhone(phoneNumber)
            const org = provider.organizations.find(org => org.id === orgId)
            setPracticeNumber(org.uin || "")
            if (provider.nhis_specialties && provider.nhis_specialties.length > 0) {
                let filteredOptions = qualificationOptions.filter(opt => provider.nhis_specialties.includes(opt.value));
                setQualificationOptions(filteredOptions)
                let option = filteredOptions.length === 1 ? filteredOptions[0] : null;
                setQualification(option);
                if (option && option.item?.meta["nhif code"]) {
                    setQualification_nhifCode(option.item.meta["nhif code"])
                } else {
                    setQualification_nhifCode(null);
                }
            }
        }

        if (exam.subject) {
            setFirstName(exam.subject.givenName);
            setLastName(exam.subject.familyName);
            setPersonalId(exam.subject.identifier);
            setIdType(exam.subject.identifierType);
            setBirthDate(new Date(exam.subject.birthDate));
            setGender(exam.subject.gender);
            setCity(exam.subject.address.city);
            setCountry(exam.subject.address.country);
            setCounty(exam.subject.address.county)
            clinicService.fetchEncPatientInfo(subject.id, orgId).then(patient => {
                if (patient && isMounted.current) {
                    setPersonalInfo(patient);
                }
            });
        } else {
            clinicService.fetchEncPatientInfo(subject.id, orgId).then(patient => {
                    if (patient) {
                        setPersonalInfo(patient);
                        if (patient.patientName) {
                            let names = patient.patientName.split(" ").map(f => f.trim());
                            if (names.length > 0) {
                                setFirstName(names[0]);
                            }
                            if (names.length > 1) {
                                setLastName(names[names.length - 1]);
                            }
                        }
                        if (patient.patientIDType) {
                            setIdType(toPatientIdType(patient));
                        }
                        if (patient.patientID) {
                            setPersonalId(patient.patientID);
                        }
                        if (patient.patientBirthDate) {
                            setBirthDate(new Date(patient.patientBirthDate));
                        }
                        if (patient.patientGender) {
                            setGender(patient.patientGender);
                        }
                        if (patient.patientCity) {
                            setCity(patient.patientCity);
                        }
                        if (patient.patientCountry) {
                            setCountry(patient.patientCountry);
                        }
                        if (patient.patientCounty) {
                            setCounty(patient.patientCounty);
                        }
                        if (patient.patientEkatte && ekatteOptions) {
                            setEkatte(patient.patientEkatte);
                        }
                    }
                    populateMissingFromSelectedUser(patient ? patient : {});
                }
            )
        }
    }, [exam])

    return <form className={formClass} noValidate={true} id="patient_provider_form" onSubmit={(e) => {
        e.preventDefault();
        if (!e.target.checkValidity()) {
            setFormClass("has-custom-validation was-validated");
            return;
        }

        let age = CONVERTER.millisecondsToAge(new Date(birthDate).getTime());

        const userInfo = {
            patientName: firstName + " " + lastName,
            patientID: personalId,
            patientIDType: fromPatientIdType(idType),
            patientBirthDate: birthDate,
            patientGender: gender,
            patientCity: city,
            patientCountry: country.toUpperCase(),
            patientCounty: county
        }

        if (ekatteOptions) {
            userInfo.patientEkatte = ekatte;
        }

        const pi = personalInfo ? {...personalInfo, ...userInfo} : userInfo;
        clinicService.updateEncPatientInfo(subject.id, orgId, pi).then(patient => {
            const prescriptionSubject = {
                "givenName": firstName.trim(),
                "familyName": lastName.trim(),
                "identifierType": idType,
                "identifier": personalId,
                "birthDate": dateAsStr(birthDate),
                "gender": gender,
                "age": age,
                "address": {
                    "city": city.trim(),
                    "country": country.toUpperCase(),
                    "county": county
                }
            }

            if (ekatteOptions) {
                prescriptionSubject.address.ekatte = ekatte;
            }

            const performer = {
                pmi: pmi,
                qualification: qualification.value,
                qualification_nhifCode: qualification_nhifCode,
                practiceNumber: practiceNumber,
                phone: phone
            }

            if (providerRhifAreaNumberOptions) {
                performer.rhifAreaNumber = providerRhifAreaNumber;
            }

            setPersonalInfo(patient);
            onUpdate(prescriptionSubject, performer);
        });

    }}>
        <div className="low-shadow-container p-3">
            <h5>{$$("nhis.patient.title")}</h5>
            <div className="d-flex">
                <FormGroup className="form-group-sm w-50 mr-1">
                    <label>{$$("nhis.patient.first_name_label")}</label>
                    <input className="form-control form-control-sm" type="text" value={firstName}
                           required={true}
                           onChange={(e) => {
                               setFirstName(e.target.value)
                           }}/>
                </FormGroup>
                <FormGroup className="form-group-sm w-50 ml-1">
                    <label>{$$("nhis.patient.last_name_label")}</label>
                    <input className="form-control form-control-sm" type="text" value={lastName}
                           required={true}
                           onChange={(e) => {
                               setLastName(e.target.value)
                           }}/>
                </FormGroup>
            </div>
            <div className="d-flex">
                <FormGroup className="form-group-sm w-50 mr-1">
                    <label>{$$("birthday_label")}</label>
                    <div>
                        <DatePicker
                            locale={lang || "en"}
                            required={true}
                            selected={birthDate}
                            onChange={date => setBirthDate(date)}
                            selectsStart
                            startDate={birthDate}
                            isClearable
                            dateFormat="dd/MM/yyyy"
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            placeholderText={$$('birthday_label')}
                            className="form-control form-control-sm"
                        />
                    </div>
                </FormGroup>
                <FormGroup className="form-group-sm w-50 ml-1">
                    <label>{$$("gender_label")}</label>
                    <Select name="gender"
                            class="custom-select-sm"
                            options={getResolvedOptions(NHIS_GENDER_OPTIONS)}
                            value={gender}
                            onChange={({value}) => setGender(value)}
                            emptyOption={true}
                            required={true}
                            placeHolder={$$('gender_label')}>
                    </Select>
                </FormGroup>
            </div>
            <div className="d-flex">
                <FormGroup className="form-group-sm w-50 mr-1">
                    <label>{$$("nhis.patient.idType")}</label>
                    <Select name="patientIDType"
                            options={getResolvedOptions(NHIS_ID_TYPE_OPTIONS)}
                            value={idType}
                            class="custom-select-sm"
                            required={true}
                            onChange={({value}) => setIdType(value)}
                            placeHolder={$$('nhis.patient.idType')}>
                    </Select>
                </FormGroup>
                <FormGroup className="form-group-sm w-50 ml-1">
                    <label>{$$("nhis.patient.personal_id")}</label>
                    <input className="form-control form-control-sm" type="text" value={personalId}
                           required={true}
                           onChange={(e) => {
                               setPersonalId(e.target.value)
                           }}/>
                </FormGroup>
            </div>
            <div className="d-flex">
                <FormGroup className="form-group-sm w-50 mr-1">
                    <Select
                        emptyOption={true}
                        class="custom-select-sm"
                        label={$$('country_label')}
                        name="country"
                        options={countryOptions("bg")}
                        required={true}
                        value={country.toLowerCase()}
                        placeHolder={$$('nhis.select')}
                        onChange={({value}) => setCountry(value.toUpperCase())}/>
                </FormGroup>
                <FormGroup className="form-group-sm w-50 ml-1">
                    <label>{$$("city_label")}</label>
                    <input className="form-control form-control-sm" type="text" value={city}
                           required={true}
                           onChange={(e) => {
                               setCity(e.target.value)
                           }}/>
                </FormGroup>
            </div>
            <div className="d-flex">
                <FormGroup className="form-group-sm w-50 mr-1">
                    <label>{$$("nhis.patient.county")}{country === 'BG' ? "" : ""}</label>
                    <Select name="patientCounty"
                            options={countyOptions}
                            emptyOption={true}
                            enableClear={country !== 'BG'}
                            value={county}
                            class="custom-select-sm"
                            required={country === 'BG'}
                            onChange={({value}) => setCounty(value)}
                            placeHolder={$$('nhis.select')}>
                    </Select>
                </FormGroup>
                {ekatteOptions && country == 'BG' && <FormGroup className="form-group-sm w-50 ml-1">
                    <label>{$$("nhis.patient.ekatte")}</label>
                    <SearchableSelect name="patientEkatte"
                            options={ekatteOptions}
                            value={ekatteOptions.find(o => o.value == ekatte)}
                            required={country === 'BG'}
                            onSelect={({value}) => setEkatte(value)}
                            placeHolder={$$('nhis.select')}>
                    </SearchableSelect>
                </FormGroup>
                }
            </div>
        </div>
        <div className="low-shadow-container p-3 mt-3">
            <h5 className="mt-2">{$$("nhis.provider.medical_practitioner_practice")}</h5>

            <div className="d-flex">
                <FormGroup className="form-group-sm w-50 mr-1">
                    <label>{$$("nhis.provider.pmi")}</label>
                    <input className="form-control form-control-sm" type="text" value={pmi} pattern=".{10,10}"
                           required={true}
                           onChange={(e) => {
                               setPmi(e.target.value)
                           }}/>
                </FormGroup>
                <FormGroup className="form-group-sm w-50 ml-1">
                    <label>{$$("nhis.provider.practiceNumber")}</label>
                    <input className="form-control form-control-sm" type="text" value={practiceNumber}
                           pattern=".{10,10}"
                           required={true}
                           onChange={(e) => {
                               setPracticeNumber(e.target.value)
                           }}/>
                </FormGroup>
            </div>
            <div className="d-flex">
                <FormGroup className="form-group-sm w-50 mr-1">
                    <label>{$$("nhis.provider.qualification")}</label>
                    <SearchableSelect optionComponent={SpecialtyOption}
                                      options={qualificationOptions}
                                      value={qualification}
                                      placeholder={$$("nhis.search_placeholder")}
                                      onSelect={(qualification) => {
                                          setQualification(qualification);
                                          if (qualification.item.meta["nhif code"]) {
                                              setQualification_nhifCode(qualification.item.meta["nhif code"])
                                          } else {
                                              setQualification_nhifCode(null);
                                          }
                                      }}
                                      required={true}
                                      defaultMenuIsOpen={false}
                    />
                </FormGroup>
                <FormGroup className="form-group-sm w-50 ml-1">
                    <label>{$$("nhis.provider.phone")}</label>
                    <input className="form-control form-control-sm" type="text" value={phone}
                           required={true}
                           onChange={(e) => {
                               setPhone(e.target.value)
                           }}/>
                </FormGroup>
            </div>
            {providerRhifAreaNumberOptions && <div className="d-flex">
                <FormGroup className="form-group-sm w-50 mr-1">
                    <label>{$$("nhis.provider.rhifAreaNumber")}</label>
                    <SearchableSelect options={providerRhifAreaNumberOptions}
                                      value={providerRhifAreaNumberOptions.find(o => o.value == providerRhifAreaNumber)}
                                      placeholder={$$("nhis.search_placeholder")}
                                      onSelect={({value}) => {
                                          setProviderRhifAreaNumber(value)
                                      }}
                                      required={true}
                    />
                </FormGroup>
            </div>}
        </div>
    </form>
}

const SpecialtyOption = (props) => {
    return (
        <components.Option {...props}>{props.children}
            <div className="small medrec-grey-2">{props.data.item.meta?.role}</div>
        </components.Option>
    );
};