import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import PropTypes from "prop-types";
import PriceListButton from "../shared/PriceListButton";
import AppointmentsButton from "../shared/AppointmentsButton";
import no_appointments from "../../resources/images/no_appointments.png";
import {infoUtils} from "../../utils/infoUtils";
import PatientInfoButton from "../patient-vertical-card/PatientInfoButton";
import {getClinicianNameWithTitle} from "../../utils/getClinicianNameWithTitle";
import UserImageWrap from "../shared/UserImageWrap";
import Email from "../common/Email";
import Phone from "../common/Phone";
import RoundButton from "../shared/RoundButton";

class staffList extends Component {
    constructor(props) {
        super(props);
        this.index = 0;
    }

    getSpecialities = (staffMember) => {
        if (staffMember.internal) {
            return this.getOrgRole(staffMember);
        }
        let specialtiesList = staffMember.specialties.map((h, idx) => {
            return <div key={idx} className='patient-health-issue-list'>{$$(h.toLowerCase())}</div>
        });

        return <div style={{ marginBottom: "1rem"}}
                    className='row left-justify'>{specialtiesList}</div>;
    }

    getOrgRole(u) {
        let org = u.organizations.find(o => o.id = this.props.orgId);
        let localRole = $$("ORG_ROLE_"+ org.role);
        if (localRole.startsWith("$_:")) {
            return org.role;
        }
        return localRole;
    }



    getFilteredStaff = () => {
        return this.props.users ? this.props.users.filter(u => {
            if (!this.props.searchValue) {
                return true;
            }
            return u.fullname.toLowerCase().indexOf(this.props.searchValue.toLowerCase()) !== -1;
        }) : [];
    }

    noStaff = () => {
        let noStaff = {
            imgClass: 'no-vitals-logbook-img',
            primaryLabelClass: 'no-lab-results-primary-label',
            secondaryLabelClass: 'no-lab-results-secondary-label',
            src: no_appointments,
            primaryLabel: $$('no_results_found'),
            secondaryLabel: ''
        }
        return infoUtils.noData(noStaff);
    }

    render() {
        return (
            <div>
                {this.getFilteredStaff().length > 0 ?
                    <div className='card-body patient-list-view'>
                        <div className='row zero-margin-row'>
                            <div className='col-xs-12 col-md-12'>
                                <div className='row'>
                                    <div className="patient-table-container mt-3">
                                        <div className="w-100 d-table management-patients-table">
                                            {this.getFilteredStaff().map(u => {

                                                let isLoggedInUser = this.props.loggedInUserId === u.id;
                                                let canSeeAppointments = isLoggedInUser ? this.props.permissions.canSeeOwnAppointments() : this.props.permissions.canSeeOthersAppointments();
                                                let canSeePrices = isLoggedInUser ? this.props.permissions.canSeeOwnPrice() : this.props.permissions.canSeeStaffPrices();
                                                let canSeeSchedule = isLoggedInUser ? this.props.permissions.canSeeOwnWorktime() : this.props.permissions.canSeeOthersWorktime();
                                                let canManageStaff = this.props.permissions.canUpdateStaff();

                                                return <div key={u.id} className="d-table-row">
                                                    <div className="d-table-cell p-2">
                                                        <UserImageWrap userID={u.id}
                                                                       classnames="patient-img"/>
                                                    </div>
                                                    <div className="d-table-cell p-2">
                                                        <h5>{getClinicianNameWithTitle(u)}</h5>
                                                        <div className="d-flex">
                                                            <div><Email object={u}/>
                                                                <Phone object={u}/>
                                                                {u.disabled && <div
                                                                    className="text-danger">{$$("account_closed")}</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"d-table-cell p-2"}>
                                                        {this.getSpecialities(u)}
                                                    </div>
                                                    <div className={"d-table-cell p-2 text-right"}>
                                                        {!u.internal && <>
                                                            {canSeeAppointments && <PatientInfoButton title={$$('appointments_label')}
                                                                           style={{'marginLeft': '0.625rem', 'marginBottom': '0.625rem'}}
                                                                           onClick={() => {
                                                                               this.props.onAppointmentsClick(u)
                                                                           }}/>}
                                                            {canSeePrices && <PriceListButton title={$$('price_settings_label')}
                                                                         style={{'marginLeft': '0.625rem', 'marginBottom': '0.625rem'}}
                                                                         onClick={() => {
                                                                             this.props.onPriceListClick(u)
                                                                         }}/>}
                                                            {canSeeSchedule && <AppointmentsButton title={$$('schedule_label')}
                                                                            style={{'marginLeft': '0.625rem', 'marginBottom': '0.625rem'}}
                                                                            onClick={() => {
                                                                                this.props.onScheduleClick(u)
                                                                            }}/>}
                                                            </>}
                                                        {canManageStaff && <RoundButton title={$$('staff_management.buttons.manage')}
                                                                                        style={{'marginLeft': '0.625rem', 'marginBottom': '0.625rem'}}
                                                                                        icon_classes={'fas fa-cogs centered-text'}
                                                                                        btn_classes={'btn btn-outline-primary btn-icon btn-icon-sm btn-circle btn-sm'}
                                                                                        onClick={()=>{
                                                                                            this.props.onManageClick(u);
                                                                                        }}
                                                        /> }
                                                        {canManageStaff && <RoundButton title={$$('staff_management.buttons.edit')}
                                                                                        style={{'marginLeft': '0.625rem', 'marginBottom': '0.625rem'}}
                                                                                        icon_classes={'fas fa-edit centered-text'}
                                                                                        btn_classes={'btn btn-outline-primary btn-icon btn-icon-sm btn-circle btn-sm'}
                                                                                        onClick={()=>{
                                                                                            this.props.onEditStaffClick(u);
                                                                                        }}
                                                        /> }
                                                    </div>
                                                </div>
                                            })}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : this.noStaff()
                }
            </div>
        )
    }
}

staffList.propTypes = {
    onMedicalHistoryClick: PropTypes.func,
    onAppointmentsClick: PropTypes.func,
    onPriceListClick: PropTypes.func,
    onScheduleClick: PropTypes.func,
    users: PropTypes.any,
    searchValue: PropTypes.any
}

export default staffList;
