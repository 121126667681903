import React, {Component} from 'react'
import {connect} from 'react-redux'
import {$$} from '../../helpers/localization';
import {
    deleteTimetable, fetchPriceList,
    fetchTimeTable,
    postNewTimetable,
    updateTimetable
} from "../../actions/users_actions";
import Calendar from "./Calendar";
import ProviderCalendars from "./ProviderCalendars";
import HolidayBooking from "./HolidayBooking";
import SchedulePopup from "./SchedulePopup";
import EditPopup from "./EditPopup";
import {Modal} from "react-bootstrap";
import startOfDay from 'date-fns/startOfDay'
import {setUserSelections} from "../../actions/user_selections_actions";
import PropTypes from "prop-types";
import NewSchedule from "./NewSchedule";

export class WorkTimePage extends Component {
    state = {
        events: [],
        isOpen: false,
        editOpen: false,
        selectedDate: "",
        selectedEvent: null,
        selectedCalendars: [],
        refreshList: false,
        timeOff:false,
        selectedOrganisationName: ''
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchTimeTable();
        this.props.fetchPriceList()
    }

    handleCalendarSelect = (calendars) => {
        this.setState({selectedCalendars: calendars});
    }

    editHoliday = (holiday) => {
        this.setState({
            timeTable: holiday,
            editOpen: true
        })
    }

    // eslint-disable-next-line no-unused-vars
    dayClick = (calEvent, jsEvent, view) => {
        this.setState({
            isOpen: true,
            editOpen: false,
            selectedDate: startOfDay(new Date(calEvent.format()))
        });
    }


    newScheduleClick = (timeOff) => {
        this.setState({
            isOpen: true,
            timeTable: {not_available : false},
            timeOff: timeOff,
            selectedDate: startOfDay(new Date())
        });
    }

    eventClick = (e) => {
        let organizationName;
        this.state.selectedCalendars.forEach(element => {
            if (element.id === e.timeTable.organization_id) {
                organizationName = element.name;
            }
        });
        this.setState({
            editOpen: true,
            isOpen: false,
            timeTable: e.timeTable,
            selectedOrganisationName: organizationName
        });
    }

    setScheduleClick = (form) => {
        if (form) {
            this.props.postNewTimetable(form).then(this.props.fetchTimeTable);
        }
        this.setState({isOpen: false});
    }

    updateScheduleClick = (form) => {
        if (form) {
            this.props.updateTimetable(form).then(this.props.fetchTimeTable);
        }
        this.closeEditPopup();
    }

    deleteDateRange = (id) => {
        this.props.deleteTimetable(id).then(this.props.fetchTimeTable);
        this.setState({editOpen: false});
    }

    closeEditPopup = () => {
        this.setState({editOpen: false});
    }

    closePopup = () => {
        this.setState({isOpen: false, timeOff: false});
    }

    getWorkTime() {
        return (
            <div className={"patient-card"}>
                <WorkTimeTitle
                    i18n={this.props.i18n}
                />
                <Calendar
                    i18n={this.props.i18n}
                    dayClick={this.dayClick}
                    eventClick={this.eventClick}
                    fetchTimeTable={this.props.fetchTimeTable}
                    timeTables={this.props.timeTables}
                    selectedCalendars={this.state.selectedCalendars}
                    userSelections={this.props.userSelections}
                    organizations={this.props.organizations}
                    prices={this.props.price_list.result || []}
                    setUserSelections={this.props.setUserSelections}
                />
                <Modal show={this.state.isOpen} onHide={this.closePopup} dialogClassName="w-100 day-picker-popup"
                       backdrop="static"
                >
                    <Modal.Body>
                        {this.state.isOpen && (
                            <SchedulePopup
                                i18n={this.props.i18n}
                                timeOff = {this.state.timeOff}
                                setScheduleClick={this.setScheduleClick}
                                closePopup={this.closePopup}
                                settings={this.props.settings}
                                selectedDate={this.state.selectedDate}
                                organizations={this.props.organizations}
                                providerId={this.props.providerId}
                                prices={this.props.price_list.result || []}
                            />
                        )}
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.editOpen} onHide={this.closeEditPopup} dialogClassName="w-100 day-picker-popup"
                       backdrop="static"
                >
                    <Modal.Body>
                        {this.state.editOpen && (
                            <EditPopup
                                timeTable={this.state.timeTable}
                                deleteEvent={this.deleteDateRange}
                                settings={this.props.settings}
                                closeEditPopup={this.closeEditPopup}
                                updateTimetable={this.updateScheduleClick}
                                selectedDate={this.state.selectedDate}
                                organizations={this.props.organizations}
                                providerId={this.props.providerId}
                                i18n={this.props.i18n}
                                selectedOrganisationName={this.state.selectedOrganisationName}
                                prices={this.props.price_list.result || []}
                            />
                        )}
                    </Modal.Body>
                </Modal>
            </div>
        );
    }

    render() {
        return (
            <div className="work-time-page">
                <div className="work-time d-flex flex-container flex-row">
                    <div className="main-col main-col-collapse-on-small">
                        {this.getWorkTime()}
                    </div>
                    <div className="work-time-small">
                        <ProviderCalendars
                            onSelectCalendar={this.handleCalendarSelect}
                            organizations={this.props.organizations}
                            providerId={this.props.providerId}
                            userSelections={this.props.userSelections}
                            setUserSelections={this.props.setUserSelections}
                        />
                        <NewSchedule
                            i18n={this.props.i18n}
                            newScheduleClick = {this.newScheduleClick}
                        />
                        <HolidayBooking
                            i18n={this.props.i18n}
                            organizations={this.props.organizations}
                            editHoliday={this.editHoliday}
                            providerId={this.props.providerId}
                            bookTimeOff={this.setScheduleClick}
                            timeTables={this.props.timeTables}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        providerId: state.userInfo.data.id,
        settings: state.settings,
        organizations: state.userInfo.data.organizations,
        i18n: state.language,
        providerAppointments: state.providerAppointments,
        timeTables: state.timeTables,
        userSelections: state.userSelections,
        price_list: state.priceList,
    }
}

const mapDispatchToProps = {
    fetchTimeTable,
    postNewTimetable,
    updateTimetable,
    deleteTimetable,
    setUserSelections,
    fetchPriceList
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkTimePage);

WorkTimePage.propTypes = {
    deleteTimetable: PropTypes.func,
    fetchTimeTable: PropTypes.func,
    fetchPriceList: PropTypes.func,
    history: PropTypes.object,
    price_list: PropTypes.any,
    settings: PropTypes.any,
    i18n: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    organizations: PropTypes.array,
    postNewTimetable: PropTypes.func,
    providerAppointments: PropTypes.object,
    providerId: PropTypes.string,
    setUserSelections: PropTypes.func,
    timeTables: PropTypes.object,
    updateTimetable: PropTypes.func,
    userSelections: PropTypes.object
}

class WorkTimeTitle extends React.PureComponent {
    render() {
        return (
            <div className='patient-card-header work-time-title'>
                <div className='d-flex space-between-justify'>
                    <h2 className='card-title card-header-title'>{$$('schedule_label')}</h2>
                </div>
            </div>
        );
    }
}