import {
    CLEAR_APPOINTMENT_FILTERS,
    LOGOUT,
    RESTORE_APPOINTMENT_FILTERS,
    SAVE_APPOINTMENT_FILTERS
} from "../actions/actions";

const initialState = {
    range: 'ALL',
    selectedType: '',
    restoreFiltersOnLoad: false,
}

export function appointmentFilters(state = initialState, action) {
    switch (action.type) {
        case SAVE_APPOINTMENT_FILTERS: {
            return {...state, range: action.filters.range, selectedType: action.filters.selectedType
            }
        }
        case RESTORE_APPOINTMENT_FILTERS: {
            return {...state, restoreFiltersOnLoad: action.restore}
        }
        case CLEAR_APPOINTMENT_FILTERS: {
            return initialState
        }
        case LOGOUT: {
            return initialState
        }
        default: {
            return state
        }
    }
}
