/* eslint-disable no-undef */
let countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/bg.json"));
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/sq.json"));
/* eslint-enable no-undef */

export function getLocalizedCountryName(countryCode, locale) {
    if (locale.toUpperCase() !== 'BG' && locale.toUpperCase() !== 'EN') {
        locale = 'EN';
    }
    return countries.getName(countryCode, locale);
}

export function countryOptions(language) {
    if (!language) {
        language = 'EN';
    }
    return Object.entries(countries.getNames(language)).map(([key, value]) => {
        return {value: key.toLowerCase(), text: value}
    });
}