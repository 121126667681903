import React, {Component} from 'react'
import moment from "moment";
import useVideoContext from "./hooks/useVideoContext/useVideoContext";
import PropTypes from "prop-types";

class Stopwatch extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {mins: 0, secs: 0}
    }

    componentDidMount() {
        this.setState({d : this.elapsedTime()});
        this.interval = this.getInterval();
    }

    getInterval() {
        return setInterval(this.getHandler(), 1000);
    }

    getHandler = () => {
        return () => {
            let elapsedTime = this.elapsedTime();
            this.setState({d: elapsedTime})
        };
    }

    elapsedTime = () => {
        let a = moment(new Date());
        let b = moment(this.props.start);
        let inp = a.diff(b);
        let diff = moment.utc(inp);
        let elapsedTime = diff > 1000 * 60 * 60 ? diff.format("H:mm:ss") : diff.format("mm:ss");
        return elapsedTime;
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    render() {
        return <div className="stopwatch">{this.state.d}</div>
    }

}

export default function StopwatchWrap() {
    const { room, isConnecting } = useVideoContext();
    return <Stopwatch start={isConnecting ? new Date() : room.started} />
}

Stopwatch.propTypes = {
    start: PropTypes.any
};