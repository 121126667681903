export const forms = {
    "3": {
    "single": "капсула",
        "plural": "капсули"
},
    "4": {
    "single": "капсула",
        "plural": "капсули"
},
    "15": {
    "single": "пръчица",
        "plural": "пръчици"
},
    "74": {
    "single": "пръчица",
        "plural": "пръчици"
},
    "19": {
    "single": "капка",
        "plural": "капки"
},
    "27": {
    "single": "дъвка",
        "plural": "дъвки"
},
    "44": {
    "single": "дъвка",
        "plural": "дъвки"
},
    "35": {
    "single": "международна единица",
        "plural": "международни единици"
},
    "36": {
    "single": "промивка",
        "plural": "промивки"
},
    "41": {
    "single": "таблетка за смучене",
        "plural": "таблетки за смучене"
},
    "70": {
    "single": "разтворима таблетка",
        "plural": "разтворими таблетки"
},
    "78": {
    "single": "таблетка",
        "plural": "таблетки"
},
    "79": {
    "single": "тампон",
        "plural": "тампона"
},
    "81": {
    "single": "промивка",
        "plural": "промивки"
},
    "82": {
    "single": "тест лента",
        "plural": "тест ленти"
},
    "default": {
    "single": "приложение",
        "plural": "приложения"
}
}