/* eslint-disable */
import React from 'react'
import {$$} from '../../../helpers/localization'
import {CONVERTER} from '../../../utils/converter'
import {
    getColorForOption,
    getResolvedText,
    MENSTRUATION
} from '../../../constants/select_options'

export default function MenstruationEntity(props) {
    return (
        <div className="card logbook-entry-card">
            <div className='row zero-margin-row'>
                <div className='medrec-grey-2 vitals-logbook-time logbook-entry-time-icon'>
                    <i className='kt-menu__link-icon far fa-clock'/> &nbsp;
                    {CONVERTER.getEntryDateTimeFormat(props.entry.date_time)}
                </div>
                &nbsp;&nbsp;
                <div>
                    <div>{$$('Flow')}: {getResolvedText(MENSTRUATION.FLOW, props.entry.menstruationFlow)}</div>
                    <div
                        style={{"color": getColorForOption(MENSTRUATION.COLOR, props.entry.menstruationColor)}}>{$$('Color')}: <b>{getResolvedText(MENSTRUATION.COLOR, props.entry.menstruationColor)}</b>
                    </div>
                    <div>{$$('Consistency')}: {getResolvedText(MENSTRUATION.CONSISTENCY, props.entry.menstruationConsistency)}</div>
                </div>
            </div>
        </div>
    )
}
