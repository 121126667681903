import React, {useEffect, useState} from "react"
import Modal from "react-bootstrap/Modal";
import {$$} from "../../../../helpers/localization";
import FormGroup from "react-bootstrap/FormGroup";
import {
    nhisAnnulExamination,
    nhisLogin,
    nhisSignAnnulRequest,
} from "../../../../service/nhis_service";

export default function NhisCancelExamDialog({encounter, senderId, onClose, onSuccess}) {
    const [nrn, setNrn] = useState(encounter.nhis_examination_nrn);
    const [reason, setReason] = useState("");
    const [formClass, setFormClass] = useState("");
    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");

    const onSubmit = (e) => {
        e.preventDefault();
        if (!e.target.checkValidity()) {
            setFormClass("was-validated")
            return;
        }

        setSending(true);

        let message = {
            senderId : senderId,
            nrnExamination: nrn,
            cancelReason: reason.trim()
        }

        nhisLogin((loginSuccess) => {
            nhisSignAnnulRequest(message, (signedRequest) => {
                nhisAnnulExamination(loginSuccess.accessToken, signedRequest)
                    .then(async res => {
                        let contents = res["nhis:message"]["nhis:contents"];
                        let error = contents["nhis:error"];
                        if (error) {
                            if (Array.isArray(error)) {
                                setError(error[0]["nhis:reason"]["value"]);
                            } else {
                                setError(error["nhis:reason"]["value"]);
                            }
                        } else {
                            let status = contents["nhis:status"]["value"];
                            //1 - open, 2-closed (success), 3-canceled
                            if (status == 3) {
                                setSuccess(true);
                                onSuccess()
                            } else if (status == 2) {
                                setError("Неуспешно изпращане. Прегледът е със статус ЗАТВОРЕН в системата на НЗИС!")
                            } else {
                                setError("Неуспешно изпращане. Прегледът е със статус ОТВОРЕН в системата на НЗИС!")
                            }
                        }
                        setSending(false)
                    })
            }, (e) => {
                setError(e);
                setSending(false);
            })
        }, (e) => {
            setSending(false);
            setError(e);
        }).catch(error => {
            console.log(error);
            setSending(false);
            setError($$("nhis.service_error"));
        })
    }

    return <Modal backdrop={"static"} show={true} >
        <Modal.Header className="h5">
            {$$("nhis.examination.cancel_modal_header")}
        </Modal.Header>
        <Modal.Body>
            {success && <p className="p-2">{$$("nhis.examination.annul_success")}</p> }
            {!success && <form noValidate={true} className={formClass} onSubmit={onSubmit} id="form_annul_exam">
                {error && <p className="text-danger">{error}</p> }
                <p>{$$("nhis.examination.cancel_modal_body")}</p>
                <FormGroup>
                    <label>{$$("nhis.examination.cancellation_reason")}*</label>
                    <textarea className="form-control"
                              rows={4}
                              value={reason}
                              onChange={(e)=>{setReason(e.target.value)}}
                              maxLength={2000}
                              required={true}/>
                </FormGroup>
            </form>}

        </Modal.Body>
        {!success && <Modal.Footer>
            <button type="submit" className="btn btn-danger" form={"form_annul_exam"} disabled={sending}
                    >{$$("nhis.annul_nhis")}</button>
            <button type="button" className="btn btn-secondary" disabled={sending}
                    onClick={onClose}>{$$("close_btn_label")}</button>
        </Modal.Footer>
        }
        {success && <Modal.Footer>
            <button type="button" className="btn btn-primary" disabled={sending}
                    onClick={onClose}>{$$("close_btn_label")}</button>
        </Modal.Footer>}
    </Modal>

}