import React, {Component} from "react";
import {$$, _$$} from "../../helpers/localization";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {downloadMedicationsReport, getMedicationSummaryStats} from "../../service/summary_service";
import DownloadEditDeleteButtons from "../shared/DownloadEditDeleteButtons";
import moment from "moment";
import {downloadUtils} from "../../utils/downloadUtils";
import no_data from "../../resources/images/no_data.png";
import {infoUtils} from "../../utils/infoUtils";
import {getUnitValuefromKey} from "../../utils/converter";
import {TAKE_EVERY_X_HOURS, TAKE_X_TIMES, WHEN_NEEDED} from "../../constants/medications_constants";
import {dateTimeUtils} from "../../utils/dateTimeUtils";


export class MedicationsTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            downloadReportData: null,
            entries: null,
            loading: true
        };

    }

    componentDidMount() {
        this.onRangeUpdate({})
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onRangeUpdate(prevProps)
    }

    getMedicationSummaryStats = (fromDate, toDate) => {

        let params = {
            fromDate: fromDate,
            toDate: toDate
        }

        this.setState({
            loading: true
        })

        getMedicationSummaryStats(this.props.selectedUser.id, params).then((res) => {
            let medications = []

            for (let i in res.entries) {
                medications.push([
                        moment(res.entries[i].plan.starts).format(this.props.settings.dateFormat.toUpperCase()),
                        res.entries[i].plan.ends ? dateTimeUtils.getFormattedDateWithTime(res.entries[i].plan.ends) : $$("ongoing"),
                        res.entries[i].plan.name,
                        this.getMedicationFrequencyString(res.entries[i].plan),
                        res.entries[i].total_doses_intake + " " + getUnitValuefromKey(res.entries[i].plan.unit, res.entries[i].plan.dose)
                    ]
                )
            }

            let ReportData = {}
            ReportData.reportTitle = $$("medications_report");
            ReportData.patientName = this.props.selectedUser.fullname;
            ReportData.fromDate = dateTimeUtils.getFormattedDateWithTime(fromDate);
            ReportData.toDate = dateTimeUtils.getFormattedDateWithTime(toDate);
            ReportData.datetime = dateTimeUtils.getFormattedDateWithTime(new Date());
            ReportData.medications = medications

            this.setState({
                downloadReportData: ReportData,
                entries: res.entries,
                loading: false
            })
        })
    }

    onRangeUpdate = (prevProps) => {
        if (this.props.selectedStart && this.props.selectedEnd && (this.props.selectedStart !== prevProps.selectedStart || this.props.selectedEnd !== prevProps.selectedEnd)) {
            this.getMedicationSummaryStats(this.props.selectedStart, this.props.selectedEnd)
        }
    }

    downloadMedicationSummary = () => {
        downloadMedicationsReport(this.props.i18n.lang, this.state.downloadReportData).then((file) => {
            downloadUtils.download(file, "Medications Intake  - " +
                this.props.selectedUser.fullname +
                " - " +
                this.state.downloadReportData.fromDate +
                " to " +
                this.state.downloadReportData.toDate +
                ".pdf");
        });
    }

    /**
     * Determines how often to take the medication
     * @param {object} medication
     */
    getMedicationFrequencyString = (medication) => {
        if (medication.scheduleType === WHEN_NEEDED) return '';
        switch (medication.howToTake) {
            case TAKE_X_TIMES:
                return medication.scheduleTimesPerDay === 0 || medication.scheduleTimesPerDay > 1 ?
                    _$$('times_per_day_plural', medication.dose, getUnitValuefromKey(medication.unit, medication.dose), medication.scheduleTimesPerDay) :
                    _$$('times_per_day', medication.dose, getUnitValuefromKey(medication.unit, medication.dose), medication.scheduleTimesPerDay);
            case TAKE_EVERY_X_HOURS:
                return medication.scheduleHours === 0 || medication.scheduleTimesPerDay > 1 ?
                    _$$('every_x_hours_plural', medication.dose, getUnitValuefromKey(medication.unit, medication.dose), medication.scheduleHours) :
                    _$$('every_x_hours', medication.dose, getUnitValuefromKey(medication.unit, medication.dose), medication.scheduleHours);
            default:
                return '';
        }
    }

    /**
     * Determines the specifics of taking the medication
     * @param {object} medication
     */
    getMedicationData = (medication) => {
        return <div key={++this.index} className="medication-row ml-2">
            <div className="medications-plans-name d-flex flex-wrap">
                <div className="d-flex">{medication.plan.name}</div>
            </div>
            <div className="medication-notes mt-1">{this.getMedicationFrequencyString(medication.plan)}</div>
            <div
                className="medication-notes mt-1">{$$("total_dose_intake") +
                ": " + medication.total_doses_intake +
                " " +
                getUnitValuefromKey(medication.plan.unit, medication.plan.dose)}</div>
        </div>;
    }

    getDate = (medication) => {
        return <div key={++this.index} className="text-right med-btn">
            <div className='small font-italic'>
                <span>{$$('start_date')}: </span>
                {dateTimeUtils.getFormattedDate(medication.starts)}
            </div>
        </div>
    }

    getRow = (medication, idx) => {
        return <React.Fragment key={idx}>
            <div className="d-flex flex-space-between flex-wrap flex-wrap med-plan-row">
                <div className="d-flex flex-wrap">
                    {this.getMedicationData(medication)}
                </div>
                {this.getDate(medication.plan)}
            </div>
            <hr className="ruler-plans"/>
        </React.Fragment>;
    }

    getMedicationPlans = () => {
        let allRows = [];

        for (let i = 0; i < this.state.entries.length; i++) {
            allRows.push(this.getRow(this.state.entries[i], i));
        }

        return [].concat.apply([], allRows);

    }

    getMedicationsList = () => {
        return <>
            <div className="medication-container">
                {this.getMedicationPlans()}
            </div>

            <DownloadEditDeleteButtons
                handleDownloadClick={this.downloadMedicationSummary}
            />
            <span className={"user-profile-link"}
                  onClick={this.downloadMedicationSummary}>{$$("download_medications")} </span>
        </>
    }

    noResults = () => {
        let emptyStatsResultObj = {
            imgClass: 'no-lab-results-img',
            primaryLabelClass: 'no-lab-results-primary-label',
            secondaryLabelClass: 'no-lab-results-secondary-label',
            src: no_data,
            primaryLabel: $$('no_records_found'),
            secondaryLabel: ''
        }
        return infoUtils.noData(emptyStatsResultObj);
    }


    render() {
        return (
            <div>
                {this.state.loading && <div className={"report-loader"}/>}
                {!this.state.loading && this.state.entries && this.state.entries.length > 0 && this.getMedicationsList()}
                {!this.state.loading && this.state.entries && this.state.entries.length === 0 && this.noResults()}
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        nomenclature: state.nomenclature,
        settings: state.settings.data,
        i18n: state.language.selected
    }
}

MedicationsTab.propTypes = {
    i18n: PropTypes.any,
    selectedStart: PropTypes.any,
    selectedEnd: PropTypes.any,
    nomenclature: PropTypes.any,
    settings: PropTypes.any,
    selectedUser: PropTypes.any
}

export default connect(mapStateToProps, {})(MedicationsTab)