import {
    FETCH_REVENUE_DETAILS_REQUEST_SUCCESS,
    FETCH_REVENUE_ERROR,
    FETCH_REVENUE_REQUEST_SENT,
    FETCH_REVENUE_SUCCESS,
    LOGOUT, REQUEST_ERROR,
    REQUEST_SENT, REQUEST_SUCCESS
} from "../actions/actions";
import {requestStatus} from "./requests_reducers";


const initialState = {
    request: requestStatus(undefined, {})
}

export function revenue(state = initialState, action) {
    switch (action.type) {
        case FETCH_REVENUE_REQUEST_SENT:
            return {...state, request: requestStatus(state.request, {type: REQUEST_SENT})};
        case FETCH_REVENUE_SUCCESS:
            return {...state, revenue:action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})};
        case FETCH_REVENUE_ERROR:
            return {...state, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})};
        case FETCH_REVENUE_DETAILS_REQUEST_SUCCESS:
            let id = action.result.id;
            let provider = state.revenue.providers.find(r => r.id === id);
            provider.details = action.result.amounts;
            let revenue = { ... state.revenue, providers: [...state.revenue.providers]}
            return {...state, revenue: revenue }
        case LOGOUT : {
            return {...initialState};
        }
        default: {
            return state
        }
    }
}