import React, {Component} from 'react';
import {$$} from '../../helpers/localization';
import Search from "../shared/Search";
import PropTypes from "prop-types";
import {getClinicianNameWithTitle} from "../../utils/getClinicianNameWithTitle";
import BackButton from "../shared/BackButton";

class StaffHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let canCreatePrice = this.props.loggedInUserId === this.props.SelectedStaffMember.id ? this.props.permissions.canCreateOwnPrices() : this.props.permissions.canCreateStaffPrices();
        return (
            <div className='patient-card-header'>
                <div className='row space-between-justify'>
                    <h2 className='card-title card-header-title'>
                        {this.props.PriceList && <BackButton onClick={this.props.clearPriceList} title={$$("back")}/>}
                        {this.props.Schedule && <BackButton onClick={this.props.clearSchedule} title={$$("back")}/>}
                        {this.props.Appointments &&
                            <BackButton onClick={this.props.clearAppointments} title={$$("back")}/>}

                        {(this.props.Schedule || this.props.Appointments || this.props.PriceList) && <> &nbsp;</>}

                        {$$('staff')}
                    </h2>

                    <div className='row patient-view-form-container form-margin'>
                    </div>
                    {this.props.PriceList && canCreatePrice && <div className='row patient-view-form-container form-margin'>
                        <input
                            type="button"
                            className="btn btn-primary date-picker-button"
                            value={$$("create_new_consultation")}
                            onClick={() => {
                                this.props.openCreatePricePopup(true)
                            }}
                        />
                        <input
                            type="button"
                            className="btn btn-primary date-picker-button"
                            value={$$("create_new_appointment")}
                            onClick={() => {
                                this.props.openCreatePricePopup(false)
                            }}
                        />
                    </div>}
                </div>


                <div className='row patient-view-form-container form-margin'>
                    <h4 className='card-title card-header-title'>
                        {this.props.PriceList &&
                            $$('price_list_for') + getClinicianNameWithTitle(this.props.SelectedStaffMember)}
                        {this.props.Schedule &&
                            $$('schedule_for') + getClinicianNameWithTitle(this.props.SelectedStaffMember)}
                        {this.props.Appointments &&
                            $$('appointments_for') + getClinicianNameWithTitle(this.props.SelectedStaffMember)}
                    </h4>
                </div>
                <div className='search-box'>
                    {!(this.props.PriceList || this.props.Schedule || this.props.Appointments) &&
                        <Search filterUsers={this.props.filterUsers}
                                placeholder={$$('search_for_staff')}/>}
                </div>
            </div>
        )
    }
}

StaffHeader.propTypes = {
    filterUsers: PropTypes.func,
    openCreatePricePopup: PropTypes.func,
    PriceList: PropTypes.bool,
    Appointments: PropTypes.bool,
    Schedule: PropTypes.bool,
    SelectedStaffMember: PropTypes.any,
    clearPriceList: PropTypes.func,
    clearSchedule: PropTypes.func,
    clearAppointments: PropTypes.func

}

export default StaffHeader;