import {fetchHelper} from '../helpers/request_helper';
import {
    GET_CHAT_WITH_USER,
    GET_LATEST_MESSAGES_PER_CHAT,
    GET_UNREAD_MESSAGES,
    MARK_MESSAGE_AS_READ_URL,
    SEND_MESSAGE_TO_USER,
    START_VIDEO_CALL_SESSION_UTR,
    STOP_VIDEO_CALL_SESSION_URL,
    UPDATE_VIDEO_DURATION_AND_STATUS_URL,
    CREATE_VIDEO_ROOM_URL,
    DELETE_VIDEO_ROOM_URL, CHAT_CAN_RECEIVE_MESSAGE_FROM, BLOCK_CHAT_FROM, EXPLICIT_UNBLOCK_CHAT_FROM, RESET_BLOCKING
} from '../constants/api_paths';

export const chatService = {
    getChatWithUser,
    sendMessage,
    updateVideoCallDurationAndStatus,
    markAsRead,
    getLatestMessages,
    getUnreadMessages,
    startVideoCallSession,
    getVideoCallSession,
    stopVideoCallSession,
    createAppointmentVideoRoom,
    closeVideoRoom,
    createGroupEventVideoRoom,
    canReceiveMessageFrom,
    blockUserFromChat,
    ExplicitUnblockUserFromChat,
    resetChatBlockingStatus
};

/**
 * Send chat message message
 *
 * @param {object} message - the message to send
 * @returns {object} promise object
 */
export function sendMessage(message) {
    return fetchHelper.callPost(SEND_MESSAGE_TO_USER, message);
}

/**
 * Mark message as read
 *
 * @param {object} message - the id of the message to mark as read
 * @returns {object} promise object
 */
export function markAsRead(messageId) {
    return fetchHelper.callPut(MARK_MESSAGE_AS_READ_URL.replace('{messageId}', messageId));
}

/**
 * Get the chat messages with the user with the given id
 *
 * @param {string} userId id of the user to get the messages for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function getChatWithUser(userId, params) {
    return fetchHelper.callGet(GET_CHAT_WITH_USER.replace('{userId}', userId), params);
}

/**
 * Check if you can receive messages from a user
 *
 * @param {string} userId id of the user that you want to see if you can receive message from.
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function canReceiveMessageFrom(userId, params) {
    return fetchHelper.callGet(CHAT_CAN_RECEIVE_MESSAGE_FROM.replace('{userId}', userId), params);
}

/**
 * Block a user from chatting with you
 *
 * @param {string} userId id of the user that you want to block.
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function blockUserFromChat(userId, params) {
    return fetchHelper.callPut(BLOCK_CHAT_FROM.replace('{userId}', userId), params);
}

/**
 * unBlock a user from chatting with you
 *
 * @param {string} userId id of the user that you want to unblock.
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function ExplicitUnblockUserFromChat(userId, params) {
    return fetchHelper.callPut(EXPLICIT_UNBLOCK_CHAT_FROM.replace('{userId}', userId), params);
}

/**
 * resets a user to the default blocking status
 *
 * @param {string} userId id of the user that you want to reset.
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function resetChatBlockingStatus(userId, params) {
    return fetchHelper.callPut(RESET_BLOCKING.replace('{userId}', userId), params);
}

/**
 * Fetch list with latest messages with each user
 *
 * @returns {object} promise object
 */
export function getLatestMessages() {
    return fetchHelper.callGet(GET_LATEST_MESSAGES_PER_CHAT);
}

/**
 * Fetch all undread messages
 *
 * @returns {object} promise object
 */
export function getUnreadMessages() {
    return fetchHelper.callGet(GET_UNREAD_MESSAGES);
}

/**
 * Update duration and status of the given video call message
 *
 * @param {number} messageId - Id of the video message to update
 * @param {number} duration - the duration of the video call
 * @param {string} status - the status of the video call
 * @returns {object} promise object
 */
export function updateVideoCallDurationAndStatus(messageId, duration, status) {
    const params = {
        video_duration: duration,
        video_status: status
    }
    return fetchHelper.callPut(UPDATE_VIDEO_DURATION_AND_STATUS_URL.replace('{messageId}', messageId), null, params);
}

/**
 * Start a videocall sesssion with the user with the given Id
 *
 * @param {number} userId - Id of the user to call
 * @returns {object} promise object
 */
export function startVideoCallSession(userId) {
    return fetchHelper.callPost(START_VIDEO_CALL_SESSION_UTR.replace('{userId}', userId));
}

/**
 * Get a videocall sesssion with the user with the given Id
 *
 * @param {number} userId - Id of the user which created the call session
 * @returns {object} promise object
 */
export function getVideoCallSession(userId) {
    return fetchHelper.callGet(START_VIDEO_CALL_SESSION_UTR.replace('{userId}', userId));
}

/**
 * Stop video call session
 *
 * @param {number} userId - Id of the user to call
 * @returns {object} promise object
 */
export function stopVideoCallSession(params) {
    return fetchHelper.callDelete(STOP_VIDEO_CALL_SESSION_URL, params);
}

/**
 * Create video room for given Appointment id
 * @param appointmentId
 * @return {Promise<*>}
 */
export function createAppointmentVideoRoom(appointmentId) {
    let url = CREATE_VIDEO_ROOM_URL + "?appointmentId=" + appointmentId;
    return fetchHelper.callPost(url, {})
}

/**
 * Create video room for given Event id
 * @param eventId - the group appointment id
 * @return {Promise<*>}
 */
export function createGroupEventVideoRoom(eventId) {
    let url = CREATE_VIDEO_ROOM_URL + "?eventId=" + eventId;
    return fetchHelper.callPost(url, {})
}

export function closeVideoRoom(roomId) {
    let url = DELETE_VIDEO_ROOM_URL.replace('{roomId}', roomId);
    return fetchHelper.callDelete(url, {}, {});
}