import {clinicService} from "../service/clinic_service";
import {
    ALL_USERS_ERROR,
    ALL_USERS_SUCCESS,
    CHANGE_APPOINTMENT_IN_USER_APPOINTMENTS_LIST,
    CLEAR_PATIENTS,
    DUMMY,
    FETCH_FIRST_AVAILABLE_ERROR,
    FETCH_FIRST_AVAILABLE_REQUEST_SENT,
    FETCH_FIRST_AVAILABLE_SUCCESS,
    FETCH_PROVIDER_TIMETABLE_ERROR,
    FETCH_PROVIDER_TIMETABLE_REQUEST_SENT,
    FETCH_PROVIDER_TIMETABLE_SUCCESS,
    FETCH_REVENUE_DETAILS_REQUEST_ERROR,
    FETCH_REVENUE_DETAILS_REQUEST_SENT,
    FETCH_REVENUE_DETAILS_REQUEST_SUCCESS,
    FETCH_REVENUE_ERROR,
    FETCH_REVENUE_REQUEST_SENT,
    FETCH_REVENUE_SUCCESS,
    FETCH_SELECTED_USER_MEDICATIONS_ERROR,
    FETCH_USER_APPOINTMENTS_ERROR,
    FETCH_USER_APPOINTMENTS_REQUEST_SENT,
    FETCH_USER_APPOINTMENTS_SUCCESS,
    PATIENT_REGISTER_CLEAR,
    PATIENT_REGISTER_ERROR,
    PATIENT_REGISTER_SUCCESS,
    SELECT_USER,
    SELECT_USER_AFTER_UPDATE,
    UPDATE_APPOINTMENT_STATUS_REQUEST_ERROR,
    UPDATE_APPOINTMENT_STATUS_REQUEST_SENT, UPDATE_PATIENT_MED_PROFILE_CLEAR_REQUEST_STATUS,
    UPDATE_PATIENT_MED_PROFILE_REQUEST_ERROR,
    UPDATE_PATIENT_MED_PROFILE_REQUEST_SENT,
    UPDATE_PATIENT_MED_PROFILE_REQUEST_SUCCESS,
    UPDATE_PATIENT_PERSONAL_INFO_CLEAR_REQUEST_STATUS,
    UPDATE_PATIENT_PERSONAL_INFO_REQUEST_ERROR,
    UPDATE_PATIENT_PERSONAL_INFO_REQUEST_SENT,
    UPDATE_PATIENT_PERSONAL_INFO_REQUEST_SUCCESS
} from "./actions";
import moment from 'moment';
import {appointmentUtils} from "../utils/appointmentUtils";
import store from '../store'

/**
 * Load all patients for given organization
 * @param orgId
 * @param params - pagination params
 * @return {function} dispatch function
 */
export function loadPatients(orgId, params) {
    return (dispatch) => {
        clinicService.fetchOrgPatients(orgId, params).then(res => {
            dispatch({type: ALL_USERS_SUCCESS, result: res, reset: true});
        }).catch((err) => {
            dispatch({type: ALL_USERS_ERROR, result: err});
        })
    }
}

/**
 * Clear the user list action.
 *
 * @returns {function} dispatch function
 */
export function clearPatients() {
    return (dispatch) => {
        dispatch({type: CLEAR_PATIENTS});
    }
}

/**
 * Create new Medrec-M user and register within the organization. The data object should contain a property organization_id!
 * @param data
 * @return {function} dispatch function
 */
export function createNewPatient(data) {
    return (dispatch, getState) => {
        clinicService.registerPatient(data).then(res => {
            if (res.id) {
                dispatch({type: PATIENT_REGISTER_SUCCESS, response: res});
            }
        }).catch((err) => {
            dispatch({type: PATIENT_REGISTER_ERROR, response: err});
        })
    }
}

/**
 * Clear registration data action
 *
 * @returns {function} dispatch function
 */
export function clearPatientRegistrationData() {
    return (dispatch) => {
        dispatch({type: PATIENT_REGISTER_CLEAR});
    }
}

/**
 * Fetch appointments for given organization. Optional user and provider. Fetches all up to 6 months in the future.
 * @param {string} userId - optional user id
 * @param {string} orgId - organization id
 * @param {string} providerId - optional providerId
 * @return {function} dispatch function
 */
export function fetchAppointments(userId, orgId, providerId, params) {
    return (dispatch, getState) => {
        dispatch({type: FETCH_USER_APPOINTMENTS_REQUEST_SENT});
        return clinicService.fetchAppointments(userId, orgId, providerId, params).then(res => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: FETCH_USER_APPOINTMENTS_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: FETCH_USER_APPOINTMENTS_ERROR, result: err});
        });
    }
}

/**
 * Update status for appointment, dispatch the appropriate action.
 *
 * @param {object} appointmentId - id of the selected appointment
 * @param {object} status - new status for selected appointment
 * @param {object} note - note to be sent with the appointment
 * @returns {function} dispatch function
 */
export function updateAppointmentStatus(appointmentId, status, note) {
    return (dispatch) => {
        dispatch({type: UPDATE_APPOINTMENT_STATUS_REQUEST_SENT});
        return clinicService.updateAppointmentStatus(appointmentId, status, note).then((res) => {
            if (res) {
                dispatch({type: CHANGE_APPOINTMENT_IN_USER_APPOINTMENTS_LIST, result: res});
            }
        }).catch((err) => {
            dispatch({type: UPDATE_APPOINTMENT_STATUS_REQUEST_ERROR, result: err});
        });
    }
}

/**
 * Mark appointment as paid, dispatch the appropriate action.
 *
 * @param {string} appointmentId - id of the selected appointment
 * @returns {function} dispatch function
 */
export function markAppointmentAsPaid(appointmentId) {
    return (dispatch) => {
        dispatch({type: UPDATE_APPOINTMENT_STATUS_REQUEST_SENT});
        return clinicService.markAppointmentAsPaid(appointmentId).then((res) => {
            if (res) {
                dispatch({type: CHANGE_APPOINTMENT_IN_USER_APPOINTMENTS_LIST, result: res});
            }
        }).catch((err) => {
            dispatch({type: UPDATE_APPOINTMENT_STATUS_REQUEST_ERROR, result: err});
        });
    }
}

/**
 * Update appointment
 *
 */
export function updateAppointment(appointment, forceUpdateAppointment) {
    return (dispatch) => {
        dispatch({type: UPDATE_APPOINTMENT_STATUS_REQUEST_SENT});
        let future;
        if (appointmentUtils.isGroupEventParent(appointment)) {
            future = clinicService.updateGroupAppointment(appointment, forceUpdateAppointment);
        } else {
            future = clinicService.updateAppointment(appointment, forceUpdateAppointment);
        }
        return future.then((res) => {
            if (res) {
                dispatch({type: CHANGE_APPOINTMENT_IN_USER_APPOINTMENTS_LIST, result: res});
            }
        }).catch((err) => {
            dispatch({type: UPDATE_APPOINTMENT_STATUS_REQUEST_ERROR, result: err});
            throw err;
        });
    }
}


/**
 * Fetch first available hour
 */
export function fetchFirstHour(providerId, location, organizationId, priceId) {
    let startTimestamp = moment().add(15, 'minutes').valueOf();
    let endTimestamp = appointmentUtils.calculateMaxFetchAppointmentsTimestamp();
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return (dispatch) => {
        dispatch({type: FETCH_FIRST_AVAILABLE_REQUEST_SENT});
        return clinicService.fetchFirstHour(providerId, startTimestamp, endTimestamp, timezone, location, organizationId, priceId).then((res) => {
            if (res) {
                dispatch({type: FETCH_FIRST_AVAILABLE_SUCCESS, result: res})
            }
        }).catch((err) => {
            dispatch({type: FETCH_FIRST_AVAILABLE_ERROR, result: err});
        })
    }
}

/**
 * Fetch timetable
 */
export function fetchTimetable(providerId, location, organizationId, priceId) {
    let startTimestamp = moment().add(15, 'minutes').valueOf();
    let endTimestamp = appointmentUtils.calculateMaxFetchAppointmentsTimestamp();
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return (dispatch) => {
        dispatch({type: FETCH_PROVIDER_TIMETABLE_REQUEST_SENT});
        return clinicService.fetchTimetable(providerId, startTimestamp, endTimestamp, timezone, location, organizationId, priceId).then((res) => {
            if (res) {
                dispatch({type: FETCH_PROVIDER_TIMETABLE_SUCCESS, result: res})
            }
        }).catch((err) => {
            dispatch({type: FETCH_PROVIDER_TIMETABLE_ERROR, result: err});
        })
    }
}

export function createAppointment(clinician, patient, price, timeSlot, note, orgId, pay_before_timestamp, forceCreateAppointment) {
    let appointment = composeAppointment(clinician, patient, price, timeSlot, note, orgId, pay_before_timestamp)
    return (dispatch, getState) => {
        return clinicService.createAppointment(appointment, patient.id, forceCreateAppointment)
    }
}

export function createAppointmentWithExactEnd(clinician, patient, price, start, end, note, orgId, pay_before_timestamp, max_patients, forceCreateAppointment) {
    let appointment = composeAppointment(clinician, patient, price, {start:start}, note, orgId, pay_before_timestamp);
    appointment.ends_at = end;
    return (dispatch, getState) => {
        if (price.encounter_price_type == 'GROUP_EVENT') {
            appointment.max_patients = max_patients;
            return clinicService.createGroupParentAppointment(appointment, forceCreateAppointment);
        }
        return clinicService.createAppointment(appointment, patient.id, forceCreateAppointment)
    }
}

function composeAppointment(clinician, patient, price, timeSlot, note, orgId, pay_before_timestamp) {
    let a = {
        appointment_price: price,
        status: "ACCEPTED",
        starts_at: timeSlot.start,
        ends_at: timeSlot.start + 60000 * price.duration_mins,
        encounter_type: price.encounter_type,
        location_type: price.location_type,
        organization_id: orgId,
        participants: [{user_id: clinician.id, participant_role: "PROVIDER"}],
        pay_before_timestamp: pay_before_timestamp
    };

    if (note && note.length > 0 && note.trim()) {
        let n = {value: note, visibility: "ALL", owner_id: clinician.id, subject_id: patient.id}
        a.notes = [n];
    }
    return a;
}

export function fetchRevenue(providerId, orgId, before, after, bookedByDate, markedAsPaid) {
    return (dispatch, getState) => {
        dispatch({type: FETCH_REVENUE_REQUEST_SENT});
        return clinicService.fetchRevenue(providerId, orgId, before, after, bookedByDate, markedAsPaid).then(res => {
            dispatch({type:FETCH_REVENUE_SUCCESS, result: res})
        }).catch((err) => {
            dispatch({type: FETCH_REVENUE_ERROR, result: err});
        })
    }
}

export function fetchRevenueDetails(providerId, orgId, before, after, bookedByDate, markedAsPaid) {
    return (dispatch, getState) => {
        dispatch({type: FETCH_REVENUE_DETAILS_REQUEST_SENT});
        return clinicService.fetchRevenueDetails(providerId, orgId, before, after, bookedByDate, markedAsPaid).then(res => {
            dispatch({type:FETCH_REVENUE_DETAILS_REQUEST_SUCCESS, result: res})
        }).catch((err) => {
            dispatch({type: FETCH_REVENUE_DETAILS_REQUEST_ERROR, result: err});
        })
    }
}

export function dummyAction() {
    return (dispatch) => {
        dispatch({type:DUMMY})
    }
}

export function dummyActionDirectDispatch() {
    store.dispatch({type:DUMMY})
}

export function updatePatientUserInfo(userInfo, orgId) {
    return (dispatch, getState) => {
        dispatch({type: UPDATE_PATIENT_PERSONAL_INFO_REQUEST_SENT});
        return clinicService.updatePatientUserInfo(userInfo, orgId).then(res => {
            dispatch({type: UPDATE_PATIENT_PERSONAL_INFO_REQUEST_SUCCESS, user: res});
        }).catch((err) => {
            dispatch({type: UPDATE_PATIENT_PERSONAL_INFO_REQUEST_ERROR, result: err});
        })
    }
}

export function updatePatientMedicalProfile(medicalProfile) {
    return (dispatch, getState) => {
        dispatch({type: UPDATE_PATIENT_MED_PROFILE_REQUEST_SENT});
        return clinicService.updatePatientMedicalProfile(medicalProfile).then(res => {
            dispatch({type: UPDATE_PATIENT_MED_PROFILE_REQUEST_SUCCESS, result: res});
        }).catch((err) => {
            dispatch({type: UPDATE_PATIENT_MED_PROFILE_REQUEST_ERROR, result: err});
        })
    }
}

export function clearPersonalInfoRequestState() {
    return (dispatch) => {
        dispatch({type: UPDATE_PATIENT_PERSONAL_INFO_CLEAR_REQUEST_STATUS})
    }
}

export function clearMedProfileRequestState() {
    return (dispatch) => {
        dispatch({type: UPDATE_PATIENT_MED_PROFILE_CLEAR_REQUEST_STATUS})
    }
}