import React, {Component} from "react";
import PropTypes from "prop-types";


export default class FormLoading extends Component {

    componentDidMount() {
        this.interval = setInterval(() => this.setState({time: Date.now()}), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    isFormLoading = () => {
        if (new Date() < this.props.formDisabled.expiry) {
            return this.props.formDisabled.formsDisabled === this.props.currentForm;
        }
        return false;
    }

    render() {
        return (
            <>
                {this.isFormLoading() && <div className="loader-container">
                    <div className="loader"
                         style={{marginTop: this.props.marginTop, marginLeft: this.props.marginLeft}}/>
                </div>}
            </>
        )
    }
}

FormLoading.propTypes = {
    currentForm: PropTypes.string,
    formDisabled: PropTypes.any,
    marginLeft: PropTypes.string,
    marginTop: PropTypes.string
};
