import React, {Component} from 'react'
import {$$} from '../../../helpers/localization'
import moment from 'moment'
import {CONVERTER} from '../../../utils/converter';
import {isSameDay} from 'date-fns'
import PropTypes from "prop-types";


export class DateLine extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        let time = moment(this.props.message.date_time).format(CONVERTER.getSelectedDateTimeFormat())
        let res = time.split(",");
        time = res[0];

        if(isSameDay(this.props.message.date_time, new Date())){
            time = $$("today")
        }

        const oneDay = (24*60*60*1000)

        if(isSameDay(this.props.message.date_time, new Date() - oneDay)){
            time = $$("yesterday")
        }

        return (
            <div>
                <>
                    <span className="date-line">
                        <p className="date-line-time" style={{fontWeight: "bold", marginBottom: 0, marginTop: "1rem",}}>{time}</p>
                        <hr className="hr-text" style={{marginTop: "-10px", marginBottom: "-10px"}}/>
                    </span>
                </>
            </div>
        )
    }
}

DateLine.propTypes = {
    isSent: PropTypes.bool,
    message: PropTypes.object
}

export default DateLine
