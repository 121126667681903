import React from "react";
import PropTypes from "prop-types";

export default class QuestionnaireAnswers extends React.Component {
    render() {
        return <div className="card-body main-card questionnaire">
            <div className={'d-flex flex-column p-2'}>
                <h4>{this.props.answers.title}</h4>
                <div className={'d-flex flex-column'}>
                    {
                        this.props.answers.results.map(question => {
                            return (
                                <div key={question.question_id}>
                                    <span className="question-text">{`${question.question_text}`}</span>
                                    <ul>
                                        {question.answers_results.map(answer => {
                                            let answerListItem = <span>{answer.answer_text}</span>;
                                            let hasResultText = answer.answer_text !== answer.result_text;
                                            return (
                                                <React.Fragment key={answer.answer_id}>
                                                    {answer.topic && <div className={"summary-answer-topic"}>{answer.topic}</div>}
                                                    <li>{answerListItem}{hasResultText && ` -> `}{hasResultText && <span className="answer-display">{answer.display ? answer.display.replace("{}", answer.result_text) : answer.result_text}</span>}</li>
                                                </React.Fragment>
                                            )
                                        })}
                                    </ul>
                                </div>
                            )
                        })}
                </div>
            </div>
        </div>
    }
}

QuestionnaireAnswers.propTypes = {
    answers: PropTypes.any
}