import React from 'react'
import {$$} from '../../helpers/localization'
import classNames from 'classnames'
import Select from '../shared/Select';
import {countryOptions} from '../../constants/countries';
import CustomTagsInput from '../shared/CustomTagsInput'
import {URL_UTILS} from '../../utils/urlUtils'
import PropTypes from "prop-types";

export class NewOrganisation extends React.Component {
    state = {
        practiceDetailsFields: {
            name: '',
            address: '',
            city: '',
            country: countryOptions(this.props.i18n.selected.lang)[0].value || '',
            website: '',
            phone_numbers: [],
            details: '',
            uin: '',
            type: 'SELF_REGISTERED',
        },

        validPhoneNumbersField: true,
        formclass: '',
        errors: {},
    }


    constructor(props) {
        super(props)
    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields['practiceDetailsFields'][evt.target.name] = evt.target.value;
        this.setState(fields);

    };

    /**
     * Set the state to the latest selected option.
     *
     * @param {object} evt - The event handler argument
     */
    onSelectChange = ({name, value}) => {
        const fields = Object.assign({}, this.state);
        fields['practiceDetailsFields'][name] = value;
        this.setState(fields);

        if (this.props.profilePage) {
            let evt = {target: {name: name, value: fields['practiceDetailsFields'][name]}};
            let valid = {name: "practiceValid", value: true};
            this.props.dataChanged(evt, valid);
        }
    };

    /**
     * Adds the item in the phone numbers array and updates the state
     *
     * @param {string} tag - the tag entered
     */
    handleAddition = (tag) => {
        const fields = Object.assign({}, this.state);
        fields['practiceDetailsFields']['phone_numbers'].push(tag.text);
        fields['validPhoneNumbersField'] = true;
        this.setState(fields);

        if (this.props.profilePage) {
            let evt = {target: {name: "phone_numbers", value: fields['practiceDetailsFields']['phone_numbers']}};
            let valid = {name: "practiceValid", value: fields['practiceDetailsFields']['phone_numbers'].length > 0};
            this.props.dataChanged(evt, valid);
        }

    };

    /**
     * Removes the item from the phone numbers array and updates the state
     *
     * @param {string} i - the index of the removed tag
     */
    handleDelete = (i) => {
        const fields = Object.assign({}, this.state);
        fields['practiceDetailsFields']['phone_numbers'] = fields['practiceDetailsFields']['phone_numbers'].filter((tag, index) => index !== i);
        let form = document.getElementsByTagName('form')[0];
        this.setState(fields, function () {
            if (form['phone_numbers'].checkValidity() === false && this.state.formclass !== '') {
                this.setState({validPhoneNumbersField: false});
            }
        });

        if (this.props.profilePage) {
            let evt = {target: {name: "phone_numbers", value: fields['practiceDetailsFields']['phone_numbers']}};
            let valid = {name: "practiceValid", value: fields['practiceDetailsFields']['phone_numbers'].length > 0};
            this.props.dataChanged(evt, valid);
        }
    };


    /**
     * Validate form data.
     *
     * @returns {object} errors - Form errors after validate
     */
    validate = () => {
        const errors = {};
        if (this.state.practiceDetailsFields.website && !URL_UTILS.isUrl(this.state.practiceDetailsFields.website)) {
            errors.website = 'register_form_website_not_correct_message';
        }
        return errors;
    }

    nameIsUsed = () => {
        for (let i in this.props.organisations) {
            if (this.props.organisations[i].name === this.state.practiceDetailsFields.name) {
                return true;
            }
        }
        return false;
    }

    uinIsUsed = () => {
        for (let i in this.props.organisations) {
            if (this.props.organisations[i].uin === this.state.practiceDetailsFields.uin) {
                return true;
            }
        }
        return false;
    }

    handleSubmit = (evt) => {
        const formErrors = this.validate();
        this.setState({errors: formErrors});

        evt.preventDefault();

        if (this.state.formclass !== 'was-validated') {
            this.setState({formclass: 'was-validated'});
        }

        if (Object.keys(formErrors).length) {
            return;
        }


        let form = document.getElementsByTagName('form')[0];
        if (form.checkValidity() === true) {
            if (!this.nameIsUsed() && !this.uinIsUsed()) {
                this.props.handleAddOrganisation(this.state.practiceDetailsFields)
                this.props.close();
            }
        } else {
            if (!form['org_phone_numbers'] || form['org_phone_numbers'].checkValidity() === false) {
                this.setState({validPhoneNumbersField: false});
            }
        }
    }

    render() {
        let cityClassNames = classNames('col-sm-6', {
            'register-inline-control-filled': this.state.practiceDetailsFields.city,
            'register-inline-control': !this.state.practiceDetailsFields.city
        });

        return (
            <div>
                <form className={this.state.formclass} noValidate={true}>
                    <div style={{'minHeight': '43.75rem'}}>
                        <div className='form-group'>
                            {this.state.practiceDetailsFields.name &&
                            <label>{$$('wizard_practice_details_org_name_label')}</label>}
                            <input type='text' className='form-control' value={this.state.practiceDetailsFields.name}
                                   placeholder={$$('wizard_practice_details_org_name_label')} name='name'
                                   onChange={this.onInputChange} required/>
                            <div className='invalid-feedback'>
                                {$$('wizard_practice_details_org_name_required_label')}
                            </div>
                            {this.nameIsUsed() &&
                            <p className="organisation-registered">{$$('organisation_already_registered')}</p>}
                        </div>
                        <div className='form-group'>
                            {this.state.practiceDetailsFields.uin &&
                            <label>{$$('wizard_practice_details_uin_label')}</label>}
                            <input type='text' className='form-control' value={this.state.practiceDetailsFields.uin}
                                   placeholder={$$('wizard_practice_details_uin_label')} name='uin'
                                   onChange={this.onInputChange} required/>
                            <div className='invalid-feedback'>
                                {$$('wizard_practice_details_uin_required_label')}
                            </div>
                            {this.uinIsUsed() &&
                            <p className="organisation-registered">{$$('organisation_already_registered')}</p>}
                        </div>
                        <div className='form-group'>
                            {this.state.practiceDetailsFields.address &&
                            <label>{$$('wizard_practice_details_address_label')}</label>}
                            <input type='text' className='form-control' value={this.state.practiceDetailsFields.address}
                                   placeholder={$$('wizard_practice_details_address_label')} name='address'
                                   onChange={this.onInputChange} required/>
                            <div className='invalid-feedback'>
                                {$$('wizard_practice_details_address_required_label')}
                            </div>
                        </div>
                        <div className='form-group'>
                            {this.state.practiceDetailsFields.website &&
                            <label>{$$('wizard_practice_details_website_label')}</label>}
                            <input type='text'
                                   className={this.state.errors.website ? 'custom-error form-control' : 'form-control'}
                                   value={this.state.practiceDetailsFields.website}
                                   placeholder={$$('wizard_practice_details_website_label')} name='website'
                                   onChange={this.onInputChange}/>
                            <div className={this.state.errors.website ? 'custom-invalid-feedback' : ''}>
                                {this.state.errors.website ? $$(this.state.errors.website) : ''}
                            </div>
                        </div>
                        <div className='form-group'>
                            <input type='text' className='form-control'
                                   value={this.state.practiceDetailsFields.phone_numbers}
                                   placeholder={$$('wizard_phone_numbers_label')} name='phone_numbers'
                                   onChange={this.onInputChange} required hidden/>
                            <CustomTagsInput tags={this.state.practiceDetailsFields.phone_numbers}
                                             handleAddition={this.handleAddition}
                                             handleDelete={this.handleDelete}
                                             placeholder={$$('wizard_phone_numbers_label')}
                                             autofocus={false}
                                             isFieldValid={this.state.validPhoneNumbersField}
                                             formClass={this.state.formclass}/>
                            <div className='invalid-feedback'>
                                {$$('wizard_phone_numbers_required_label')}
                            </div>
                        </div>
                        <div className='form-group'>
                            {this.state.practiceDetailsFields.details &&
                            <label>{$$('wizard_practice_details_label')}</label>}
                            <textarea className='form-control' value={this.state.practiceDetailsFields.details}
                                      placeholder={$$('wizard_practice_details_label')} name="details" rows="4"
                                      cols="50" onChange={this.onInputChange} required />
                            <div className='invalid-feedback'>
                                {$$('wizard_practice_details_required_label')}
                            </div>
                        </div>
                        <div className='row' style={{'marginTop': '0.3125rem'}}>
                            <div className='col-sm-6'>
                                <Select
                                    label={$$('country_label')}
                                    name='country'
                                    options={countryOptions(this.props.i18n.selected.lang)}
                                    value={this.state.practiceDetailsFields.country}
                                    onChange={this.onSelectChange}/>
                            </div>
                            <div className={cityClassNames}>
                                {this.state.practiceDetailsFields.city && <label>{$$('city_label')}</label>}
                                <input type='text' className='form-control'
                                       value={this.state.practiceDetailsFields.city} placeholder={$$('city_label')}
                                       name='city' onChange={this.onInputChange} required/>
                                <div className='invalid-feedback'>
                                    {$$('wizard_city_required_label')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='form-group' style={{'marginTop': '5.25rem'}}>
                        <div className='row flex-end-justify' style={{'float': 'right', 'margin': '0rem'}}>
                            <button type='button' onClick={this.props.close} className='btn btn-secondary'
                                    style={{'marginRight': '0.3125rem'}}>{$$('wizard_back_label')}</button>
                            <button type='button' onClick={this.handleSubmit}
                                    className='btn btn-success'>{$$('add')}</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

NewOrganisation.propTypes = {
    close: PropTypes.func,
    handleAddOrganisation: PropTypes.func,
    dataChanged: PropTypes.func,
    i18n:PropTypes.object,
    profilePage: PropTypes.any,
    organisations: PropTypes.array
}

export default NewOrganisation
