import React, {Component} from 'react'
import Tabs from './Tabs';
import Tab from './Tab';
import VitalsLogbook from '../vitals-logbook/VitalsLogbook';
import {connect} from 'react-redux'
import {
    clearSelectedAppointmentInProgress,
    clearSelectedEncounter,
    clearSelectedUserData,
    createEncounter,
    createNote,
    deleteNote,
    fetchSelectedUserLogbookEntries,
    fetchUserAppointments,
    fetchUserNotes,
    fetchUserNotesForAppointment,
    resetSelectedUserLogbookEntries,
    resetSelectedUserSymptoms,
    selectAppointmentInProgress,
    updateAppointmentStatus,
    updateEncounter,
    updateNote
} from '../../actions/users_actions';
import {fetchSelectedUserCardiacStats} from '../../actions/cardiac_stats_actions';
import {fetchSelectedUserSymptomsLog} from '../../actions/symptoms_actions';
import {
    changeDocumentsFilters,
    fetchSelectedUserDocuments
} from '../../actions/documents_actions';
import {$$} from '../../helpers/localization'
import {SubHeader} from './SubHeader';
import Documents from '../documents/Documents';
import DocumentsFilters from '../documents/DocumentsFilters';
import {
    changeLabResultsFilters,
    fetchLaboratories,
    fetchSelectedUserLabResults
} from '../../actions/lab-results_actions';
import LabResults from '../lab-results/LabResults';
import LabResultsFilters from '../lab-results/LabResultsFilters';
import {
    DOCUMENT,
    LABORATORY_RESULT
} from '../../constants/information-retrieval-types';
import moment from 'moment';
import AppointmentsTab from '../appointments/AppointmentsTab';
import {NotesTab} from '../notes/NotesTab';
import {ExaminationTab} from '../examination/ExaminationTab';
import {
    clearIcd,
    searchIcd
} from "../../actions/icd_actions";
import PropTypes from "prop-types";
import QuestionnaireAnswers from "../questionnaire/QuestionnaireAnswers";
import Other from "../other_tab/Other";


class TabsContainer extends Component {


    constructor(props) {
        super(props);
        this.state = {
            selectedUserId: props.selectedUser ? props.selectedUser.id : null,
            isActive: false,
            selectedFilters : {
                appointmentsTab: {
                    range: 'ALL',
                    selectedType: "0"
                },
                vitalsLogbookTab: {
                    logbook_entry_type: 'ALL'
                },
                otherTab:{
                    selectedEnd: null,
                    selectedEndYrs: null,
                    selectedStart: null,
                    selectedStartYrs: null,
                    timeFrameSelection: "30_DAYS",
                    timeFrameSelectionYrs: "1_YEAR",
                }
            }
        }
    }

    componentDidMount() {
        this.props.fetchLaboratories();
        this.props.fetchUserAppointments(this.props.selectedUser.id);
        this.fetchNotes();
    }

    updateFilterSelections = (filters) => {
        this.setState({
            selectedFilters : filters
        })
    }

    fetchNotes() {
        if (this.props.appointment.id) {
            this.props.fetchUserNotesForAppointment(this.props.selectedUser.id, this.props.appointment.id);
        } else {
            this.props.fetchUserNotes(this.props.selectedUser.id);
        }
    }

    getDefaultTab = () => {
        let tabNo = !this.props.appointment.id ? 0 : 1

        if( this.props.appointment?.appointment_price?.encounter_price_type === "TEXT_ONLY"){
            tabNo = 3
        }

        if (window.location.pathname.includes("history")) {
            tabNo = 0
        }
        
        return tabNo
    }

    /**
     * Fetch the doc or lab data if the filters or language has changed
     */
    componentDidUpdate(prevProps) {
        if (prevProps.selectedUser.id && this.props.selectedUser.id) {
            if (prevProps.documents.filters.before_date_time !== this.props.documents.filters.before_date_time) {
                let healthIssueIds = this.props.selectedUser.health_issues.map(h => h.id);
                    let params = {
                        before_date_time: this.props.documents.filters.before_date_time ? this.props.documents.filters.before_date_time : moment().valueOf(),
                        healthIssueIds: healthIssueIds,
                        size: 500,
                        types: [DOCUMENT]
                    };
                    this.props.fetchSelectedUserDocuments(this.props.selectedUser.id, params, true);
            }
            if (prevProps.labResults.filters.before_date_time !== this.props.labResults.filters.before_date_time) {
                let healthIssueIds = this.props.selectedUser.health_issues.map(h => h.id);
                    let params = {
                        before_date_time: this.props.labResults.filters.before_date_time ? this.props.labResults.filters.before_date_time : moment().valueOf(),
                        healthIssueIds: healthIssueIds,
                        size: 500,
                        types: [LABORATORY_RESULT]
                    };

                    this.props.fetchSelectedUserLabResults(this.props.selectedUser.id, params, true);
            }
        }

        if (this.props.appointment.id !== prevProps.appointment.id) {
            this.fetchNotes();
        }
    }

    /**
     * If there was an appointment in progress clear the selected appontment in progress
     */
    componentWillUnmount = () => {

        if (! (this.props.videoBackButton)) {
            if (this.props.appointment.id) {
                this.props.clearSelectedAppointmentInProgress();

            }
        }
    }

    /**
     * Get the options for the documents category filter
     *
     * @returns {array} options for the documents category filter
     */
    getDocumentsCategoryOptions = () => {
        const categories = [{text: $$('select_category'), value: ''}];
        if (this.props.documents &&
            this.props.documents.selectedUser &&
            this.props.documents.selectedUser.entries &&
            this.props.documents.selectedUser.entries.length > 0) {
            for (let doc of this.props.documents.selectedUser.entries) {
                if (categories.length === 11) {
                    break;
                }
                if (doc.category && !categories.some(c => c.value === doc.category)) {
                    categories.push({text: doc.category, value: doc.category});
                }
            }
        }
        return categories;
    }

    /**
     * Get the options for the lab results laboratory filter
     *
     * @returns {array} options for the lab results laboratory filter
     */
    getLabResultsLaboratoryOptions = () => {
        const laboratories = [{text: $$('select_laboratory_label'), value: ''}];
        if (this.props.labs && this.props.labs.length > 0) {
            for (let lab of this.props.labs) {
                if (laboratories.length === 11) {
                    break;
                }
                if (lab.name && !laboratories.some(l => l.value === lab.name)) {
                    laboratories.push({text: lab.name, value: lab.lab});
                }
            }
        }
        return laboratories;
    }

    handleButtonClicked = () => {
        let active = !this.state.isActive;
        this.setState({isActive: active});
    };

    render() {
        return (
            <Tabs lang={this.props.i18n.selected.lang} defaultActiveTabIndex={this.getDefaultTab()} acceptAppointment={this.props.acceptAppointment} clearOnCancel={this.props.clearOnCancel}
                  appointment={this.props.appointment}>
                {!this.props.appointment.id &&
                <Tab tabName={$$('appointments_label')}>
                    <AppointmentsTab userId={this.props.selectedUser.id}
                                     userAppointments={this.props.userAppointments}
                                     selectedUser={this.props.selectedUser}
                                     isLastPage={this.props.logBook.isLastPage}
                                     isFinished={this.props.logBook.request.finished}
                                     fetchUserAppointments={this.props.fetchUserAppointments}
                                     settings={this.props.settings}
                                     i18n={this.props.i18n.selected}
                                     loggedInUser={this.props.loggedInUser}
                                     selectedFilters ={this.state.selectedFilters}
                                     updateFilters = {this.updateFilterSelections}
                                     keepSelectedUserAndOpenAppointment={this.props.keepSelectedUserAndOpenAppointment}
                    />
                </Tab>}
                {this.props.appointment.id &&
                <Tab tabName={this.props.appointment.text_only ? $$("consultation_examination_result_label") : $$('examination_result_label')}>
                    <ExaminationTab userId={this.props.userId}
                                    selectedUser={this.props.selectedUser}
                                    createEncounter={this.props.createEncounter}
                                    updateEncounter={this.props.updateEncounter}
                                    selectAppointmentInProgress={this.props.selectAppointmentInProgress}
                                    encounter={this.props.encounter}
                                    appointment={this.props.appointment}
                                    encounterId={this.props.appointment.encounter_id ? this.props.appointment.encounter_id : null}
                                    settings={this.props.settings}
                                    clearSelectedEncounter={this.props.clearSelectedEncounter}
                                    updateAppointmentStatus={this.props.updateAppointmentStatus}
                                    i18n={this.props.i18n.selected}
                                    searchIcd={this.props.searchIcd}
                                    clearIcd={this.props.clearIcd}
                                    icdEntries={this.props.icdEntries}
                                    loggedInUser={this.props.loggedInUser}
                                    fetchSearchedICDList={this.props.fetchSearchedICDList}
                                    isNhis={this.props.isNhis}
                    />
                </Tab>}
                {this.props.appointment.id && this.props.appointment.questionnaire_answers_id &&
                <Tab tabName={$$('questionnaire')}>
                    <QuestionnaireAnswers answers={this.props.appointment.questionnaire_answer.questionnaire_answers} i18n={this.props.i18n.selected}/>
                </Tab>
                }
                <Tab tabName={this.props.appointment.id ? $$("messages") : $$('notes')}>
                    <NotesTab userId={this.props.userId}
                              selectedAppointment={this.props.appointment}
                              userAppointments={this.props.userAppointments}
                              selectedUserId={this.props.selectedUser.id}
                              notes={this.props.selectedUserNotes}
                              formDisabled={this.props.formDisabled}
                              createNote={this.props.createNote}
                              updateNote={this.props.updateNote}
                              deleteNote={this.props.deleteNote}
                              settings={this.props.settings}
                              i18n={this.props.i18n.selected}/>
                </Tab>
                <Tab tabName={$$('Vitals_Logbook')}>
                    <VitalsLogbook selectedUser={this.props.selectedUser}
                                   logbook={this.props.logBook}
                                   isLastPage={this.props.logBook.isLastPage}
                                   isFinished={this.props.logBook.request.finished}
                                   fetchLogbookEntries={this.props.fetchSelectedUserLogbookEntries}
                                   resetLogbookEntries={this.props.resetSelectedUserLogbookEntries}
                                   symptoms={this.props.symptoms}
                                   nomenclature={this.props.nomenclature}
                                   fetchSymptomsEntries={this.props.fetchSelectedUserSymptomsLog}
                                   resetSymptomsEntries={this.props.resetSelectedUserSymptoms}
                                   selectedFilters ={this.state.selectedFilters}
                                   updateFilters = {this.updateFilterSelections}
                                   settings={this.props.settings}
                                   i18n={this.props.i18n.selected}/>
                </Tab>
                <Tab tabName={$$('Documents')}>
                    <SubHeader>
                                <DocumentsFilters
                                    i18n={this.props.i18n}
                                    filters={this.props.documents.filters}
                                    settings ={this.props.settings}
                                    categoryOptions={this.getDocumentsCategoryOptions()}
                                    changeDocumentsFilters={this.props.changeDocumentsFilters}
                                />
                    </SubHeader>
                    <Documents selectedUser={this.props.selectedUser}
                               filters={this.props.documents.filters}
                               documents={this.props.documents.selectedUser}
                               fetchEntries={this.props.fetchSelectedUserDocuments}
                               i18n={this.props.i18n.selected}/>
                </Tab>
                <Tab tabName={$$('Lab_Results')}>
                    <SubHeader>
                        <LabResultsFilters
                            i18n={this.props.i18n}
                            settings ={this.props.settings}
                            filters={this.props.labResults.filters}
                            laboratoryOptions={this.getLabResultsLaboratoryOptions()}
                            changeLabResultsFilters={this.props.changeLabResultsFilters}
                        />
                    </SubHeader>
                    <LabResults selectedUser={this.props.selectedUser}
                                filters={this.props.labResults.filters}
                                labResults={this.props.labResults.selectedUser}
                                labs={this.props.labs}
                                fetchEntries={this.props.fetchSelectedUserLabResults}
                                i18n={this.props.i18n.selected}/>
                </Tab>
                <Tab tabName={$$("other")}>
                    <Other
                        selectedUser={this.props.selectedUser}
                        fetchSelectedUserCardiacStats={this.props.fetchSelectedUserCardiacStats}
                        i18n={this.props.i18n.selected}
                        cardiacStats={this.props.cardiacStats}
                        selectedFilters ={this.state.selectedFilters}
                        updateFilters = {this.updateFilterSelections}
                        settings={this.props.settings}
                    />
                </Tab>
            </Tabs>
        )
    }
}

TabsContainer.propTypes = {
    i18n: PropTypes.object,
    cardiacStats: PropTypes.object,
    labs: PropTypes.any,
    documents: PropTypes.object,
    labResults: PropTypes.object,
    selectedAppointmentInProgress: PropTypes.object,
    userId: PropTypes.string,
    fetchSelectedUserCardiacStats: PropTypes.func,
    resetSelectedUserSymptoms: PropTypes.func,
    resetSelectedUserLogbookEntries: PropTypes.func,
    clearSelectedAppointmentInProgress: PropTypes.func,
    fetchUserAppointments: PropTypes.func,
    fetchUserNotesForAppointment: PropTypes.func,
    logBook: PropTypes.any,
    settings: PropTypes.any,
    nomenclature: PropTypes.any,
    icdEntries: PropTypes.any,
    selectedUserNotes: PropTypes.any,
    symptoms: PropTypes.any,
    userAppointments: PropTypes.any,
    fetchLaboratories: PropTypes.func,
    clearOnCancel: PropTypes.bool,
    selectedEncounter: PropTypes.any,
    acceptAppointment: PropTypes.any,
    clearIcd: PropTypes.func,
    searchIcd: PropTypes.func,
    updateAppointmentStatus: PropTypes.func,
    changeDocumentsFilters: PropTypes.func,
    fetchUserNotes: PropTypes.func,
    fetchSelectedUserDocuments: PropTypes.func,
    deleteNote: PropTypes.func,
    updateNote: PropTypes.func,
    videoBackButton: PropTypes.bool,
    keepSelectedUserAndOpenAppointment: PropTypes.func,
    createNote: PropTypes.func,
    updateEncounter: PropTypes.func,
    createEncounter: PropTypes.func,
    formDisabled: PropTypes.any,
    fetchSelectedUserSymptomsLog: PropTypes.func,
    clearSelectedEncounter: PropTypes.func,
    fetchSearchedICDList: PropTypes.func,
    fetchSelectedUserLogbookEntries: PropTypes.func,
    selectedUser: PropTypes.object,
    fetchSelectedUserLabResults: PropTypes.func,
    selectAppointmentInProgress: PropTypes.func,
    changeLabResultsFilters: PropTypes.func
}

function mapStateToProps(state) {
    return {
        //state:state,
        selectedUser: state.selectedUser.data,
        logBook: state.logBook,
        symptoms: state.symptoms,
        nomenclature: state.nomenclature,
        settings: state.settings,
        documents: state.documents,
        labs: state.laboratories.labs,
        labResults: state.labResults,
        userAppointments: state.userAppointments.data,
        userId: state.userInfo.data.id,
        loggedInUser:state.userInfo.data,
        selectedUserNotes: state.selectedUserNotes.data,
        appointment: state.selectedAppointmentInProgress,
        encounter: state.selectedEncounter.data,
        cardiacStats: state.cardiacStats,
        icdEntries: state.icd.entries,
        formDisabled: state.formInteractions,
        isNhis: state.provider_configuration.data.nhis
    }
}

const mapDispatchToProps = {
    fetchUserAppointments,
    fetchSelectedUserLogbookEntries,
    clearSelectedUserData,
    fetchSelectedUserSymptomsLog,
    fetchSelectedUserDocuments,
    changeDocumentsFilters,
    fetchLaboratories,
    fetchSelectedUserLabResults,
    changeLabResultsFilters,
    fetchUserNotes,
    fetchUserNotesForAppointment,
    createNote,
    updateNote,
    deleteNote,
    clearSelectedAppointmentInProgress,
    clearSelectedEncounter,
    createEncounter,
    updateEncounter,
    selectAppointmentInProgress,
    updateAppointmentStatus,
    fetchSelectedUserCardiacStats,
    resetSelectedUserSymptoms,
    resetSelectedUserLogbookEntries,
    searchIcd,
    clearIcd

}

export default connect(mapStateToProps, mapDispatchToProps)(TabsContainer);
