import {fetchHelper} from '../helpers/request_helper';
import {
    GET_NOTIFICATIONS_URL,
    UPDATE_NOTIFICATION_STATUS_URL
} from '../constants/api_paths';

export const notificationsService = {
    getAllNotifications,
    markNotificationAsSeen
};

/**
 * Get all notifications
 *
 * @param {number} limit query param sent together with the request
 * @param {number} offset query param sent together with the request
 * @param {boolean} onlyNotSeen query param sent together with the request
 * @returns {object} promise object
 */
export function getAllNotifications(limit, offset, onlyNotSeen) {
    return fetchHelper.callGet(GET_NOTIFICATIONS_URL + "?limit=" + limit + "&offset=" + offset + "&onlyNotSeen=" + onlyNotSeen);
}

/**
 * Get all notifications
 *
 * @param {string} notificationId id of the notification to be marked as seen
 * @returns {object} promise object
 */
export function markNotificationAsSeen(notificationId) {
    return fetchHelper.callPut(UPDATE_NOTIFICATION_STATUS_URL.replace('{notificationId}', notificationId));
}