import React from 'react'
import PersonalInformation from '../register/wizard/PersonalInformation'
import Biography from '../register/wizard/Biography'
import PracticeDetails from './PracticeDetails'
import UploadDocuments from '../register/wizard/UploadDocuments'
import FormLoading from "../shared/FormLoading";
import {PROFILE} from "../../actions/actions";
import PropTypes from "prop-types";


export class UserInfoTabs extends React.Component {
    state = {
        formclass: '',
        errors: {}
    }

    constructor(props) {
        super(props)
    }

    render() {
        return (

            <div>
                {this.props.wizardStep === 0 && <PersonalInformation registrationFields={this.props.registrationFields}
                                                                     specialties={this.props.specialties}
                                                                     dataChanged={this.props.dataChanged}
                                                                     profilePage={true}
                                                                     submitForm={this.props.submitForm}
                                                                     updateUserInfo={this.props.updateUserInfo}
                                                                     i18n={this.props.i18n}/>}
                {this.props.wizardStep === 1 && <Biography onWizardStepChange={this.props.onWizardStepChange}
                                                           wizardStep={this.props.wizardStep}
                                                           dataChanged={this.props.dataChanged}
                                                           profilePage={true}
                                                           submitForm={this.props.submitForm}
                                                           registrationFields={this.props.registrationFields}
                                                           i18n={this.props.i18n}/>}
                {this.props.wizardStep === 2 && <PracticeDetails onWizardStepChange={this.props.onWizardStepChange}
                                                                 wizardStep={this.props.wizardStep}
                                                                 profilePage={true}
                                                                 submitForm={this.props.submitForm}
                                                                 dataChanged={this.props.dataChanged}
                                                                 registrationFields={this.props.registrationFields}
                                                                 i18n={this.props.i18n}/>}
                {this.props.wizardStep === 3 && <UploadDocuments onWizardStepChange={this.props.onWizardStepChange}
                                                                 profilePage={true}
                                                                 wizardStep={this.props.wizardStep}
                                                                 submitForm={this.props.submitForm}
                                                                 registrationFields={this.props.registrationFields}
                                                                 changeDocuments={this.props.changeDocuments}
                                                                 i18n={this.props.i18n}/>}

                <FormLoading
                    formDisabled={this.props.formDisabled}
                    currentForm={PROFILE}
                    marginTop="40%"
                    marginLeft="40%"
                />
            </div>
        )
    }
}

UserInfoTabs.propTypes = {
    changeDocuments: PropTypes.func,
    completedSteps: PropTypes.object,
    dataChanged: PropTypes.func,
    documents: PropTypes.array,
    formDisabled:  PropTypes.object,
    i18n: PropTypes.object,
    onWizardStepChange:  PropTypes.func,
    registrationFields: PropTypes.object,
    showPhoneNumError: PropTypes.bool,
    specialties: PropTypes.array,
    submitForm: PropTypes.func,
    updateUserInfo:  PropTypes.func,
    wizardStep:  PropTypes.number
}

export default UserInfoTabs
