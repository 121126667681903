import {fetchHelper} from "../helpers/request_helper";

import {
    DELETE_PRESCRIPTION_URL,
    UPDATE_PRESCRIPTION_URL,
    CREATE_PRESCRIPTION_URL,
    GET_PRESCRIPTION_TEMPLATES_URL,
    DELETE_PRESCRIPTION_TEMPLATES_URL
} from "../../src/constants/api_paths"

export const prescriptionService = {
    deletePrescription,
    updatePrescription,
    createPrescription,
    fetchPrescriptionTemplates,
    deletePrescriptionTemplate
}

export function deletePrescription(prescriptionId) {
    return fetchHelper.callDelete(DELETE_PRESCRIPTION_URL.replace('{prescriptionId}', prescriptionId));
}

export function createPrescription(prescription, createTemplate) {
    return fetchHelper.callPost(CREATE_PRESCRIPTION_URL, prescription, {createTemplate});
}

export function updatePrescription(prescription, createTemplate) {
    return fetchHelper.callPut(UPDATE_PRESCRIPTION_URL, prescription, {createTemplate});
}

export function fetchPrescriptionTemplates() {
    return fetchHelper.callGet(GET_PRESCRIPTION_TEMPLATES_URL);
}

export function deletePrescriptionTemplate(prescriptionTemplateId) {
    return fetchHelper.callDelete(DELETE_PRESCRIPTION_TEMPLATES_URL.replace('{prescriptionTemplateId}', prescriptionTemplateId));
}