import React, {Component} from 'react'
import {connect} from "react-redux";
import {clearPatients, getAllPatients} from "../../actions/users_actions";
import {chatService} from "../../service/chat_service";
import MessagesHeader from "./MessagesHeader";
import ChatBox from "./chatBoxWithSelectedUser/ChatBox";
import {UsersComponent} from "./users/UsersComponent";
import PropTypes from "prop-types";

class ChatPage extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            latestMessages: [], selectedChat: undefined
        }
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize)
        this.props.getAllPatients({page: 0, size: 1000});
        this.getLastMessages();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
        this.props.clearPatients();
    }

    getLastMessages = () => {
        chatService.getLatestMessages().then((res) => {
            if (res) {
                this.setState({
                    latestMessages: res
                });
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.users !== prevProps.users || this.props.chat !== prevProps.chat) {
            this.getLastMessages();
        }
    }

    handleResize = () => {
        this.setState({width: window.innerWidth})
    }

    isSmall = () => {
        return this.state.width <= 737
    }

    isLarge = () => {
        return !this.isSmall();
    }

    render() {
        let patient = this.state.selectedChat;
        let childName = null;
        let childId = null;
        if (this.state.selectedChat) {
            let parent = patient.parentContactInfos && patient.parentContactInfos[0];
            if (parent) {
                patient = parent;
                childName = this.state.selectedChat.fullname;
                childId = this.state.selectedChat.id
            }
        }
        let showUsers = this.isLarge() || !this.state.selectedChat;
        return <>
            <MessagesHeader/>
            <div className={"chat-card-box"}>
                <div className="chat-card d-flex justify-content-between pr-0">
                    {showUsers && <div className="searchbox-chat chat-card-left">
                        <UsersComponent users={this.props.users} latestMessages={this.state.latestMessages}
                                        lang={this.props.lang}
                                        unreadMessages={this.props.chat.unreadMessages}
                                        selectedUserId={this.state.selectedChat?.id}
                                        small={this.isSmall()}
                                        selectChat={(u)=>{this.setState({selectedChat:u})}}/>
                    </div>}
                    {this.state.selectedChat && <ChatBox
                        patient={patient}
                        childName={childName}
                        childId={childId}
                        deSelectUser={() => {
                            this.setState({selectedChat: undefined})
                        }}
                        smallMessages={this.isSmall()}
                    />}
                </div>
            </div>
        </>
    }
}

const mapDispatchToProps = {
    getAllPatients,
    clearPatients
}

const mapStateToProps = (state) => ({
    lang: state.language.selected.lang,
    users: state.users.list,
    loggedUser: state.userInfo.data,
    chat: state.chat
})

ChatPage.propTypes = {
    lang: PropTypes.object,
    getAllPatients: PropTypes.func,
    clearPatients: PropTypes.func,
    chat: PropTypes.object,
    loggedUser: PropTypes.object,
    users: PropTypes.object
}
export default connect(mapStateToProps, mapDispatchToProps)(ChatPage)


