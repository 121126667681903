import React from 'react'
import {$$} from '../../../helpers/localization'
import classNames from 'classnames'
import Select from '../../shared/Select';
import {countryOptions} from '../../../constants/countries';
import CustomTagsInput from '../../shared/CustomTagsInput'
import {URL_UTILS} from '../../../utils/urlUtils'
import PropTypes from "prop-types";


export class PracticeDetails extends React.Component {
    state = {
        practiceDetailsFields: {
            org_name: this.props.registrationFields.org_name || '',
            address: this.props.registrationFields.address || '',
            org_city: this.props.registrationFields.org_city || '',
            org_country: this.props.registrationFields.org_country || countryOptions()[0].value || '',
            website: this.props.registrationFields.website || '',
            org_phone_numbers: this.props.registrationFields.org_phone_numbers || [],
            org_other_info: this.props.registrationFields.org_other_info || '',
            org_uin: this.props.registrationFields.org_uin || ''
        },

        validPhoneNumbersField: true,

        formclass: '',
        errors: {}
    }

    constructor(props) {
        super(props)
    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields['practiceDetailsFields'][evt.target.name] = evt.target.value;
        this.setState(fields);
    };

    /**
     * Set the state to the latest selected option.
     *
     * @param {object} evt - The event handler argument
     */
    onSelectChange = ({name, value}) => {
        const fields = Object.assign({}, this.state);
        fields['practiceDetailsFields'][name] = value;
        this.setState(fields);
    };

    /**
     * Adds the item in the phone numbers array and updates the state
     *
     * @param {string} tag - the tag entered
     */
    handleAddition = (tag) => {
        const fields = Object.assign({}, this.state);
        fields['practiceDetailsFields']['org_phone_numbers'].push(tag);
        fields['validPhoneNumbersField'] = true;
        this.setState(fields);
    };

    /**
     * Removes the item from the phone numbers array and updates the state
     *
     * @param {string} i - the index of the removed tag
     */
    handleDelete = (i) => {
        const fields = Object.assign({}, this.state);
        fields['practiceDetailsFields']['org_phone_numbers'] = fields['practiceDetailsFields']['org_phone_numbers'].filter((tag, index) => index !== i);
        let form = document.getElementsByTagName('form')[0];
        this.setState(fields, function () {
            if (form['org_phone_numbers'].checkValidity() === false && this.state.formclass !== '') {
                this.setState({validPhoneNumbersField: false});
            }
        });
    };

    /**
     * Handler to be executed when the Continue button is clicked, validates data and sets error in state if any
     *
     * @param {object} evt - The event handler argument
     */
    onContinueClick = (evt) => {
        const formErrors = this.validate();
        this.setState({errors: formErrors});

        evt.preventDefault();

        if (this.state.formclass !== 'was-validated') {
            this.setState({formclass: 'was-validated'});
        }

        if (Object.keys(formErrors).length) {
            return;
        }

        let form = document.getElementsByTagName('form')[0];
        if (form.checkValidity() === true) {
            let wizardStep = this.props.wizardStep;
            this.props.onWizardStepChange(this.state.practiceDetailsFields, this.props.isInternal ? wizardStep : ++wizardStep, true, this.props.isInternal);
        } else {
            if (form['org_phone_numbers'].checkValidity() === false) {
                this.setState({validPhoneNumbersField: false});
            }
        }
    }

    /**
     * Handler to be executed when the Back button is clicked
     *
     */
    onBackClick = () => {
        let wizardStep = this.props.wizardStep;
        this.props.onWizardStepChange(this.state.practiceDetailsFields, --wizardStep, this.props.isStepCompleted);
    }

    /**
     * Validate form data.
     *
     * @returns {object} errors - Form errors after validate
     */
    validate = () => {
        const errors = {};
        if (this.state.practiceDetailsFields.website && !URL_UTILS.isUrl(this.state.practiceDetailsFields.website)) {
            errors.website = 'register_form_website_not_correct_message';
        }
        return errors;
    }

    render() {
        let cityClassNames = classNames('col-sm-6', {
            'register-inline-control-filled': this.state.practiceDetailsFields.org_city,
            'register-inline-control': !this.state.practiceDetailsFields.org_city
        });

        return (
            <div>
                <form className={this.state.formclass} noValidate={true}>
                    <div style={{'minHeight': '43.75rem'}}>
                        <div className='form-group'>
                            {this.state.practiceDetailsFields.org_name &&
                            <label>{$$('wizard_practice_details_org_name_label')}</label>}
                            <input type='text' className='form-control'
                                   value={this.state.practiceDetailsFields.org_name}
                                   placeholder={$$('wizard_practice_details_org_name_label')} name='org_name'
                                   onChange={this.onInputChange} required/>
                            <div className='invalid-feedback'>
                                {$$('wizard_practice_details_org_name_required_label')}
                            </div>
                        </div>
                        {this.props.registrationFields.country.toLowerCase() === 'bg' && <div className='form-group'>
                            {this.state.practiceDetailsFields.org_uin &&
                            <label>{$$('wizard_practice_details_uin_label')}</label>}
                            <input type='text' className='form-control'
                                   value={this.state.practiceDetailsFields.org_uin}
                                   placeholder={$$('wizard_practice_details_uin_label')} name='org_uin'
                                   onChange={this.onInputChange} required/>
                            <div className='invalid-feedback'>
                                {$$('wizard_practice_details_uin_required_label')}
                            </div>
                        </div>}
                        <div className='form-group'>
                            {this.state.practiceDetailsFields.address &&
                            <label>{$$('wizard_practice_details_address_label')}</label>}
                            <input type='text' className='form-control' value={this.state.practiceDetailsFields.address}
                                   placeholder={$$('wizard_practice_details_address_label')} name='address'
                                   onChange={this.onInputChange} required/>
                            <div className='invalid-feedback'>
                                {$$('wizard_practice_details_address_required_label')}
                            </div>
                        </div>
                        <div className='form-group'>
                            {this.state.practiceDetailsFields.website &&
                            <label>{$$('wizard_practice_details_website_label')}</label>}
                            <input type='text'
                                   className={this.state.errors.website ? 'custom-error form-control' : 'form-control'}
                                   value={this.state.practiceDetailsFields.website}
                                   placeholder={$$('wizard_practice_details_website_label')} name='website'
                                   onChange={this.onInputChange}/>
                            <div className={this.state.errors.website ? 'custom-invalid-feedback' : ''}>
                                {this.state.errors.website ? $$(this.state.errors.website) : ''}
                            </div>
                        </div>
                        <div className='form-group'>
                            <input type='text' className='form-control'
                                   value={this.state.practiceDetailsFields.org_phone_numbers}
                                   placeholder={$$('wizard_phone_numbers_label')} name='org_phone_numbers'
                                   onChange={this.onInputChange} required hidden/>
                            <CustomTagsInput tags={this.state.practiceDetailsFields.org_phone_numbers}
                                             handleAddition={this.handleAddition}
                                             handleDelete={this.handleDelete}
                                             placeholder={$$('wizard_phone_numbers_label')}
                                             autofocus={false}
                                             isFieldValid={this.state.validPhoneNumbersField}
                                             formClass={this.state.formclass}/>
                            <div className='invalid-feedback'>
                                {$$('wizard_phone_numbers_required_label')}
                            </div>
                        </div>
                        <div className='form-group'>
                            {this.state.practiceDetailsFields.org_other_info &&
                            <label>{$$('wizard_practice_details_label')}</label>}
                            <textarea className='form-control' value={this.state.practiceDetailsFields.org_other_info}
                                      placeholder={$$('wizard_practice_details_label')} name="org_other_info" rows="4"
                                      cols="50" onChange={this.onInputChange} required />
                            <div className='invalid-feedback'>
                                {$$('wizard_practice_details_required_label')}
                            </div>
                        </div>
                        <div className='row' style={{'marginTop': '0.3125rem'}}>
                            <div className='col-sm-6'>
                                <Select
                                    label={$$('country_label')}
                                    name='org_country'
                                    options={countryOptions(this.props.i18n.selected.lang)}
                                    value={this.state.practiceDetailsFields.org_country}
                                    onChange={this.onSelectChange}/>
                            </div>
                            <div className={cityClassNames}>
                                {this.state.practiceDetailsFields.org_city && <label>{`${$$('city_label')}*`}</label>}
                                <input type='text' className='form-control'
                                       value={this.state.practiceDetailsFields.org_city} placeholder={`${$$('city_label')}*`}
                                       name='org_city' onChange={this.onInputChange} required/>
                                <div className='invalid-feedback'>
                                    {$$('wizard_city_required_label')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='form-group' style={{'marginTop': '5.25rem'}}>
                        <div className='row flex-end-justify' style={{'float': 'right', 'margin': '0rem'}}>
                            <button type='button' onClick={this.onBackClick} className='btn btn-secondary'
                                    style={{'marginRight': '0.3125rem'}}>{$$('wizard_back_label')}</button>
                            <button type='button' onClick={this.onContinueClick}
                                    className='btn btn-success'>{$$('wizard_continue_label')}</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

PracticeDetails.propTypes = {
    i18n: PropTypes.object,
    isStepCompleted: PropTypes.bool,
    onWizardStepChange: PropTypes.func,
    registrationFields: PropTypes.object,
    wizardStep: PropTypes.number
}

export default PracticeDetails
