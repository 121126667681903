import FormGroup from "react-bootstrap/FormGroup";
import React from "react";
import {$$} from "../../../../helpers/localization";

export function FG({children, className}) {
    return <FormGroup className={`form-group-sm ${className ? className : ""}`}>
        {children}
    </FormGroup>
}

export function FGL({children, className}) {
    return <FormGroup className={`${className ? className : ""}`}>
        {children}
    </FormGroup>
}

export function FG50SmallL({children}) {
    return <FormGroup className="form-group-sm w-50 mr-1">
        {children}
    </FormGroup>
}

export function FG50SmallR({children}) {
    return <FormGroup className="form-group-sm w-50 ml-1">
        {children}
    </FormGroup>
}

export function FormRow({children}) {
    return <div className="d-flex align-items-end">
        {children}
    </div>
}

export function Input({value, setValue, label, type, required, ...rest}) {
    return <React.Fragment>
        <FieldLabel labelKey={label} prop={value} required={!!required}/>
        <input type={type} name={rest?.name ? rest.name : label} className="form-control form-control-sm" onWheel={ event => event.currentTarget.blur() }
               value={value}
               placeholder={`${$$(label)}${required ? '*' : ''}`}
               {...rest}
               required={!!required}
               onChange={(e) => {
                   setValue(e.target.value, e)
               }}
        />
    </React.Fragment>
}

export function InputTextArea({value, setValue, label, type, required, rows=4, ...rest}) {
    return <React.Fragment>
        <FieldLabel labelKey={label} prop={value} required={!!required} />
        <textarea rows={rows} name={label} placeholder={`${$$(label)}${required ? '*':''}`} className="form-control form-control-sm"
                  value={value}
                  {...rest}
                  required={!!required}
                  onChange={(e) => {
                      setValue(e.target.value)
                  }}
        />
    </React.Fragment>
}

export function SectionHeader({children}) {
    return <h5 className="mt-2">{children}</h5>
}

export function RequiredIndicator({hide}) {
    if (hide) return null;
    return <span className="text-danger">*</span>
}

export function FieldLabel({prop, labelKey, required}) {
    const showLabel = prop && prop.length > 0;
    if (!showLabel) {
        return null;
    }
    return <label>{$$(labelKey)}<RequiredIndicator hide={!required}/></label>
}