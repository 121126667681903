import {$$} from "../../../../helpers/localization";
import {useNhisEReferralFetchContext} from "./NhisEReferralFetchNomenclatureProvider";
import React from "react";
import {getLabel} from "./utils";
import {dateTimeUtils} from "../../../../utils/dateTimeUtils";

export function ExecutionsView({executions}) {
    return (<>
        {executions.map(k => <ExecutionView key={k.nrnExecution} execution={k} />)}
    </>)
}

function ExecutionView({execution}) {
    return (
        <>
            <div>{$$("nhis.ereferral.lab_res.execution_date")}: {dateTimeUtils.getFormattedDate(execution.executeDate)}</div>
            <table className="table table-sm medrec-default-text-color">
                <thead>
                <tr>
                    <th>{$$("nhis.ereferral.lab_res.name")}</th>
                    <th>{$$("nhis.ereferral.lab_res.value")}</th>
                    <th>{$$("nhis.ereferral.lab_res.unit")}</th>
                    <th>{$$("nhis.ereferral.lab_res.reference")}</th>
                </tr>
                </thead>
                <tbody className={"text-thin"}>
                {execution.procedure.map(p => <Procedure key={p.code} procedure={p}/>)}
                </tbody>
            </table>
        </>
    )
}

function Procedure({procedure}) {
    const {
        activityCodeOptions,
        isLoading
    } = useNhisEReferralFetchContext();

    if (isLoading) {
        return false;
    }

    return (<>
        {procedure.cannotPerform && <tr>
            <td>{getLabel(activityCodeOptions, procedure.code)}</td>
            <td colSpan={3}>{$$("nhis.ereferral.cannot_perform_procedure")}</td>
        </tr>}
        {!procedure.cannotPerform && <>
            {procedure.observation.map(o => <Observation key={o.code} observation={o} procedureCode={procedure.code}/>)}
        </>}
        {!procedure.cannotPerform && procedure.conclusion && <tr>
            <td className="text-right">{$$("conclusion")}:</td>
            <td colSpan={3}>{procedure.conclusion}</td>
        </tr>}
    </>)
}

function Observation({observation, procedureCode}) {

    const {
        observationValueCodeOptions,
        observationCodeOptions
    } = useNhisEReferralFetchContext();

    if (!observation) {
        return false;
    }

    if (observation.valueScale == '1') {
        const isOutsideRange =  observation.valueQuantity >= observation.referenceRange_low && observation.valueQuantity <= observation.referenceRange_high;
        return (<><tr>
            <td>{getLabel(observationCodeOptions, observation.code)}</td>
            <td style={{whiteSpace:"nowrap"}} className={isOutsideRange ? "danger" : ""}>{observation.valueQuantity}</td>
            <td style={{whiteSpace:"nowrap"}}>{observation.valueUnit}</td>
            <Reference observation={observation} />
        </tr>
            {observation.note && <tr><td className="text-right">{$$("notes")}:</td><td colSpan={3}>{observation.note}</td></tr>}
        </>)

    }

    if (observation.valueScale == '2') {
        let res = observationValueCodeOptions.find(o => o.value == observation.valueCode);
        return (<><tr>
            <td>{getLabel(observationCodeOptions, observation.code)}</td>
            <td>{res.code} {res.label}</td>
            <td style={{whiteSpace:"nowrap"}}>{observation.valueUnit ?? ""}</td>
            <Reference observation={observation} />
        </tr>
            {observation.note && <tr><td className="text-right">{$$("notes")}:</td><td colSpan={3}>{observation.note}</td></tr>}
        </>);
    }

    if (observation.valueScale == '3') {
        return (<><tr>
            <td>{getLabel(observationCodeOptions, observation.code)}</td>
            <td >{observation.valueString}</td>
            <td style={{whiteSpace:"nowrap"}}>{observation.valueUnit ?? ""}</td>
            <Reference observation={observation} />
        </tr>
            {observation.note && <tr><td className="text-right">{$$("notes")}:</td><td colSpan={3}>{observation.note}</td></tr>}
        </>);
    }

    return false;
}

function Reference({observation}) {
    if (observation.referenceRange_low !== undefined || observation.referenceRange_high !== undefined || observation.referenceRange_text) {

        if (observation.referenceRange_low !== undefined && observation.referenceRange_high !== undefined) {
            return <td><span style={{whiteSpace:"nowrap"}}>{`${observation.referenceRange_low} - ${observation.referenceRange_high}`}</span>
                {observation.referenceRange_text ? <><br/>{observation.referenceRange_text}</> : ""}
            </td>
        }

        if (observation.referenceRange_low !== undefined) {
            return <td>
                <span style={{whiteSpace:"nowrap"}}>{$$("nhis.ereferral.lab_res.over")} {observation.referenceRange_low}</span>
                {observation.referenceRange_text ? <><br/>{observation.referenceRange_text}</> : ""}
            </td>
        }

        if (observation.referenceRange_high !== undefined) {
            return <td><span style={{whiteSpace:"nowrap"}}>{$$("nhis.ereferral.lab_res.under")} {observation.referenceRange_high}</span>
                {observation.referenceRange_text ? <><br/>{observation.referenceRange_text}</> : ""}
            </td>
        }

        return <td>
            {observation.referenceRange_text}
        </td>
    }

    return <td></td>;
}
