import {useEffect, useMemo, useState} from "react";
import {Button, FormControl, FormGroup, ListGroup, ListGroupItem, Modal} from "react-bootstrap";
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {$$} from "../../helpers/localization";
import {
    clearTotpVerifyResp,
    clear2FAResult,
    generateTotp, load2FAMethod,
    update2FA,
    verifyTotp
} from "../../actions/twofactor_auth_actions";
import PropTypes from "prop-types";

class Select2FAComponent extends React.Component {
    render() {
        return <Select2FA userId={this.props.userId} two_fa_settings={this.props.two_fa_settings}
                          method={this.props.two_fa_settings.settings.method}
                          load2FAMethod={this.props.load2FAMethod}
        />
    }
}

function mapStateToProps(state) {
    return {
        userId: state.userInfo.data.id,
        two_fa_settings: state.two_fa_settings
    }
}

export default connect(mapStateToProps, {load2FAMethod})(Select2FAComponent)

// eslint-disable-next-line react/prop-types
function Select2FA({userId, two_fa_settings, method, load2FAMethod}) {
    let ls = useMemo(() => {
        return sessionStorage.getItem("sstfa") && true
    }, []);
    const [closed, setClose] = useState(ls);

    useEffect(() => {
        if (userId) {
            load2FAMethod();
        } else {
            setClose(false);
        }
    }, [userId])

    if (userId && method === 'NOT_SET' && !closed) {
        return <TFA setClose={setClose} two_fa_settings={two_fa_settings} method={method}/>
    }



    return null;
}

Select2FAComponent.propTypes = {
    userId: PropTypes.any,
    two_fa_settings: PropTypes.any,
    load2FAMethod: PropTypes.func,
}

class TwoFactorAuthenticationWizardWrap extends Component {
    render() {
        return <TwoFactorAuthenticationWizard show={true}
                                              method={this.props.method}
                                              onHide={() => {
                                                  this.props.setClose(true);
                                                  this.props.clear2FAResult();
                                                  sessionStorage.setItem("sstfa", "1")
                                              }}
                                              totp={this.props.two_fa_settings.totp}
                                              totpVerifyResult={this.props.two_fa_settings.totp_verify_result}
                                              onLoadTOTP={() => {
                                                  this.props.generateTotp();
                                              }}
                                              onCancel={() => {
                                                  this.props.clear2FAResult();
                                              }}
                                              onChange={(e) => {
                                                  this.props.update2FA(e);
                                              }}
                                              onTOTPVerify={(r) => {
                                                  this.props.verifyTotp(r)
                                              }}
                                              clearTOTPVerifyResponse={() => {
                                                  this.props.clearTotpVerifyResp();
                                              }}
                                              result={this.props.two_fa_settings.result}
        />
    }
}


TwoFactorAuthenticationWizardWrap.propTypes = {
    userId: PropTypes.any,
    two_fa_settings: PropTypes.any,
    method: PropTypes.any,
    load2FAMethod: PropTypes.func,
    setClose: PropTypes.func,
    clearTotpVerifyResp: PropTypes.func,
    generateTotp: PropTypes.func,
    clear2FAResult: PropTypes.func,
    update2FA: PropTypes.func,
    verifyTotp: PropTypes.func,
}


const TFA = connect(null, {clear2FAResult, generateTotp, clearTotpVerifyResp, verifyTotp, update2FA})(TwoFactorAuthenticationWizardWrap);

export class TwoFactorAuthenticationWizard extends Component {
    constructor(props) {
        super(props);
        this.onBack = this.onBack.bind(this);
        this.onActivate = this.onActivate.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onNext = this.onNext.bind(this);
        this.state = {step: 0, method: this.props.method ? this.props.method : 'NOT_SET'}
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(oldProps, oldState) {
        if (oldProps.result != this.props.result) {
            if (this.props.result === 'changed') {
                this.setState({step: this.state.step + 1})
            }
        }

        if (this.isTOTPValidationStep() && this.isTOTPValidated()) {
            this.props.clearTOTPVerifyResponse()
            this.setState({input: '', step: this.state.step + 1});
        }
    }

    onBack() {
        if (this.state.step > 0) {
            this.setState({input: '', step: this.state.step - 1});
        }
    }

    onActivate() {
        var payload = {"method": this.state.method};
        if (this.state.method === 'TOTP') {
            payload.recoveryCodes = this.props.totp.recoveryCodes;
            payload.sharedSecret = this.props.totp.sharedSecret;
        }
        this.props.onChange(payload);
    }

    onNext() {
        if (!this.props.totp.sharedSecret && this.state.step == 0) {
            this.props.onLoadTOTP();
        }
        this.setState({input: '', step: this.state.step + 1});
    }

    onCancel() {
        this.props.onHide();
        this.setState({step: 0, input: '', method: this.props.method ? this.props.method : 'NOT_SET'});
    }

    isFinalStep() {
        return this.state.method == 'OFF' && this.state.step == 2 || this.state.method == 'EMAIL' && this.state.step == 2 ||
            this.state.method == 'TOTP' && this.state.step == 4;
    }

    isProcessingStep() {
        return this.state.method == 'OFF' && this.state.step == 1 || this.state.method == 'EMAIL' && this.state.step == 1 ||
            this.state.method == 'TOTP' && this.state.step == 3;
    }

    renderResult() {

        if (this.props.result === 'changed') {
            let msg = '';
            if (this.state.method === 'OFF') {
                msg = $$("two_fa_method_successfully_deactivated");
            } else if (this.state.method === 'TOTP') {
                msg = $$("two_fa_method_totp_successfully_activated");
            } else if (this.state.method === 'EMAIL') {
                msg = $$("two_fa_method_email_successfully_activated");
            }
            return <div>{msg}</div>
        }
        return <div>{$$("processing")}</div>;
    }

    splitTOTPCode() {
        return this.props.totp.sharedSecret.match(/.{1,4}/g);
    }

    renderStep() {
        if (this.state.step == 0) {
            return <div>
                <h3 className={"mb-3"}>{$$("select_2fa_method_step_1")}</h3>
                <ListGroup className="two_fa_list">
                    <ListGroupItem className={this.state.method === 'EMAIL' ? "list-group-item-success" : undefined}
                                   onClick={() => this.setState({method: 'EMAIL'})}
                                   action={true}
                                   autoFocus={this.state.method === 'EMAIL'}
                    >
                        <h4>{$$("email_label")}</h4>
                        <p>{$$("email_two_fa_selector")}</p>
                    </ListGroupItem>
                    <ListGroupItem action={true}
                                   className={this.state.method === 'TOTP' ? "list-group-item-success" : undefined}
                                   onClick={() => this.setState({method: 'TOTP'})}
                                   autoFocus={this.state.method === 'TOTP'}
                    >
                        <h4>{$$("authenticator_label")}</h4>
                        <p>{$$("totp_two_fa_selector")}</p>
                    </ListGroupItem>
                    <ListGroupItem action={true}
                                   className={this.state.method === 'OFF' ? "list-group-item-danger" : undefined}
                                   onClick={() => this.setState({method: 'OFF'})}
                                   autoFocus={this.state.method === 'OFF'}
                    >
                        <h4>{$$("two_fa_off")}</h4>
                        <p>{$$("turn_off_two_fa_selector")}</p>
                    </ListGroupItem>
                </ListGroup>
            </div>
        }
        if (this.state.step == 1) {
            switch (this.state.method) {
                case 'OFF':
                    return <div>
                        <div className="text-danger-with-bg">{$$("two_fa_warning_off")}</div>
                    </div>;
                case 'TOTP':
                    return <div>
                        <div>
                            <p>{$$("two_fa_totp_qr_description")}</p>
                        </div>
                        {
                            this.props.totp.sharedSecret && <div>
                                <div className="text-center"><img src={this.props.totp.qrUrl}/></div>
                                <div className="text-center mt-4">
                                    {this.splitTOTPCode().map((o, i) => {
                                        return <span key={i} className="p-1 bold">{o}</span>
                                    })}
                                </div>
                            </div>

                        }
                    </div>
                case 'EMAIL':
                    return <div>
                        <p>{$$("email_two_fa_step_1")}</p>
                    </div>
            }
        }
        if (this.state.step == 2) {
            switch (this.state.method) {
                case 'TOTP':
                    return <div>
                        <FormGroup>
                            <span>{$$("verify_totp_input")}</span>
                            <FormControl autoFocus type="text" name="totpVerification" value={this.state.input || ''}
                                         onChange={(e) => {
                                             this.setState({input: e.target.value})
                                         }}/>
                        </FormGroup>
                        <FormGroup>
                            <div className="text-danger">
                                {this.props.totpVerifyResult && this.props.totpVerifyResult.status == 'false' ? $$("code_2fa_mismatch") : ""}
                            </div>
                        </FormGroup>
                    </div>
                default:
                    return <div>
                        <div>
                            {this.renderResult()}
                        </div>
                    </div>
            }

        }
        if (this.state.step == 3) {
            switch (this.state.method) {
                case 'TOTP':
                    return <div>
                        <p>{$$("recovery_codes_description")}</p>
                        <div>
                            <div className="p-1 d-inline">
                                <Button onClick={() => {
                                    var openWindow = window.open("", "Medrec:M Clinic Recovery Codes");
                                    openWindow.document.write("<div style='font-size:20px; text-align: center; padding-top: 40px'>" + document.getElementById("rec-codes").innerHTML + "</div>");
                                    openWindow.document.close();
                                    openWindow.focus();
                                    openWindow.print();
                                    openWindow.close();
                                }}>{$$("print_btn")}</Button>
                            </div>
                            <div className="p-1 d-inline">
                                <Button onClick={() => {
                                    var element = document.createElement('a');
                                    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.props.totp.recoveryCodes.map((c) => {
                                        return c.match(/.{1,4}/g).join(" ")
                                    }).join("\r\n")));
                                    element.setAttribute('download', "medrec_m_clinic_recovery_codes.txt");
                                    element.style.display = 'none';
                                    document.body.appendChild(element);
                                    element.click();
                                    document.body.removeChild(element);
                                }}>{$$("download_btn")}</Button>
                            </div>
                        </div>
                        <ListGroup id="rec-codes">
                            {this.props.totp.recoveryCodes.map((c, i) => {
                                let codePadded = c.match(/.{1,4}/g).join(" ");
                                return <div key={i} className="text-center"
                                            style={{fontFamily: "monospace", fontWeight: "700", fontSize: "larger"}}>
                                    {codePadded}
                                </div>
                            })}
                        </ListGroup>
                    </div>
                default:
                    return <div>
                        <div>
                            {this.renderResult()}
                        </div>
                    </div>
            }
        }
        if (this.state.step == 4) {
            return <div>
                <div>
                    {this.renderResult()}
                </div>
            </div>
        }
        return null;
    }

    isTOTPValidated() {
        return this.props.totpVerifyResult && this.props.totpVerifyResult.status === 'true';
    }

    isTOTPValidationStep() {
        return this.state.step === 2 && this.state.method === 'TOTP';
    }

    verifyButton() {
        if (this.isTOTPValidationStep() && !this.isTOTPValidated()) {
            return <Button onClick={() => {
                if (this.state.input != '') {
                    this.props.onTOTPVerify({sharedSecret: this.props.totp.sharedSecret, code: this.state.input})
                }
            }}>{$$("verify_btn")}</Button>
        }
        return null;
    }

    nextButton() {
        if (!this.isFinalStep() && !this.isProcessingStep() && !this.isTOTPValidationStep()) {
            return <Button onClick={this.onNext} disabled={this.state.method === 'NOT_SET'}>{$$("continue_btn")}</Button>
        }
        return null;
    }

    render() {
        return <Modal backdrop="static" show={this.props.show} onHide={this.onCancel} size={"lg"}
                      dialogClassName={"auth-modal"}>
            <Modal.Header>
                {$$("clinician_change_2fa_modal_header")}
            </Modal.Header>
            <Modal.Body>
                {this.renderStep()}
            </Modal.Body>
            <Modal.Footer>
                {!this.isFinalStep() && <div className="flex-grow-1 flex-shrink-1"><Button style={{float: "left"}}
                                                                                           onClick={this.onCancel}>{$$("cancel_btn")}</Button>
                </div>}
                {this.state.step > 0 && !this.isFinalStep() && <Button onClick={this.onBack}>{$$("back")}</Button>}
                {this.isProcessingStep() && <Button
                    onClick={this.onActivate}>{$$(this.state.method !== 'OFF' ? "activate_btn" : "two_fa_off")}</Button>}
                {this.isFinalStep() && this.props.result == 'changed' &&
                <Button onClick={this.onCancel} style={{minWidth: "60px"}}>{$$("OK")}</Button>}
                {this.nextButton()}
                {this.verifyButton()}
            </Modal.Footer>
        </Modal>
    }
}


TwoFactorAuthenticationWizard.propTypes = {
    result: PropTypes.any,
    method: PropTypes.any,
    totpVerifyResult: PropTypes.any,
    totp: PropTypes.any,
    onTOTPVerify: PropTypes.func,
    onChange: PropTypes.func,
    clearTOTPVerifyResponse: PropTypes.func,
    onLoadTOTP: PropTypes.func,
    onHide: PropTypes.func,
    show: PropTypes.bool,
}