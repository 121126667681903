/* eslint-disable */
import React from 'react'
import {CONVERTER} from '../../../utils/converter'
import {WEIGHT} from '../../../constants/entry_types'

export default function WeightEntry(props) {
    return (
        <div className="card logbook-entry-card">
            <div className='row zero-margin-row'>
                <div className='medrec-grey-2 vitals-logbook-time logbook-entry-time-icon'>
                    <i className='kt-menu__link-icon far fa-clock'/> &nbsp;
                    {CONVERTER.getEntryDateTimeFormat(props.entry.date_time)}
                </div>
                &nbsp;&nbsp;
                {CONVERTER.convertByUnit(WEIGHT, props.entry.weight).toFixed(1)} {CONVERTER.getUnitByType(WEIGHT)}
            </div>
        </div>
    )
}