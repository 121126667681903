import React, {Component} from 'react'
import EncounterDocuments from "../../appointments/EncounterDocuments";
import PropTypes from "prop-types";
import {connect} from "react-redux";

class DocumentsList extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return <>
            {this.props.show && <EncounterDocuments documents={this.props.documents}
                                                    loggedInUserId={this.props.loggedInUserId}
                                                       encounterDocuments={this.props.encounterDocuments}
                                                       showEncounterDocumentsDeleteConfirmationModal={this.props.showEncounterDocumentsDeleteConfirmationModal}
                                                       showEncounterDocumentsAddUpdateModal={this.props.showEncounterDocumentsAddUpdateModal}
                                                       encounter={this.props.encounter}
                                                       appointment={this.props.appointment}
                                                       selectedUser={this.props.selectedUser}
                                                       i18n={this.props.i18n}/>
            }
        </>
    }
}

DocumentsList.propTypes = {
    documents: PropTypes.any,
    encounterDocuments: PropTypes.any,
    showEncounterDocumentsDeleteConfirmationModal: PropTypes.any,
    showEncounterDocumentsAddUpdateModal: PropTypes.any,
    encounter: PropTypes.any,
    selectedUser: PropTypes.any,
    i18n: PropTypes.any,
    show: PropTypes.bool,
    appointment: PropTypes.any
}

function mapStateToProps(state) {
    return {
        i18n: state.language.selected,
        settings: state.settings,
        formDisabled: state.formInteractions,
        userInfo: state.userInfo.data
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsList);
