import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import PropTypes from "prop-types";
import {InformationTooltip} from "../common/tooltips";

class NewSchedule extends Component {

    constructor(props) {
        super(props);
    }

    getHeader = () => {
        return (
            <div className={"provider-cal"}>
                <div className="d-flex provider-cal-header">
                    <div className="bold">{$$("create_new_schedule")}
                        &nbsp;
                        <InformationTooltip>
                            {$$('create_new_schedule_info_text')}
                        </InformationTooltip>
                    </div>
                </div>
                <hr className="hr"/>
            </div>
        )
    }

    getButtons = () => {
        return (
            <div>
                <div className="d-flex new-schedules-btns">
                    <button className="btn btn-primary w-50 ml-3 mb-3" onClick={() => {
                        this.props.newScheduleClick(false)
                    }}>{$$("time_on_button")}</button>
                    <button className="btn btn-primary w-50 mr-3 mb-3" onClick={() => {
                        this.props.newScheduleClick(true)
                    }}>{$$("time_off_button")}</button>
                </div>
            </div>
        )
    }


    render() {
        return (
            <div className=' new-schedules-container dashboard-card new-schedules-container'
                 style={{minHeight: "16vh !important"}}>
                {this.getHeader()}
                {this.getButtons()}
            </div>
        )
    }
}

NewSchedule.propTypes = {
    newScheduleClick: PropTypes.func,
    i18n: PropTypes.object
}

export default NewSchedule
