import React, {Component} from 'react'
import $ from 'jquery';
import 'fullcalendar';
import {$$} from '../../helpers/localization';
import 'fullcalendar/dist/fullcalendar.css';
import AppointmentsListForCalendar from '../appointments/AppointmentsListForCalendar'
import moment from 'moment'
import classNames from 'classnames'
import PatientVerticalCard from '../patient-vertical-card/PatientVerticalCard'
import no_appointments from '../../resources/images/no_appointments.png'
import {medicalHistoryHelper} from '../../helpers/medical_history_helper'
import {APPOINTMENTS_APPOINTMENT_EVENT_PRELOAD} from '../../constants/pages'
import PropTypes from "prop-types";
import {appointmentUtils} from "../../utils/appointmentUtils";
import AppointmentsListItemForCalendar from "../appointments/AppointmentsListItemForCalendar";


export class Calendar extends Component {
    state = {
        events: [],
        selectedDate: new Date(),
        filteredAppointmentsByDate: [],
        viewType: this.props.userSelections.appointments.calendarView,
        currentMonth: new Date().getMonth(),
        selectedUser: null,
        firstRequest: true,
        intervalStart: null,
        intervalEnd: null,
        datesWithAppointmentsNumbers: {},
        keysOfDatesWithAppointmentsNumbers: [],
        selectedWeekEventElement: null,
        canceledEvent: null
    }

    constructor(props) {
        super(props);
        this.calendarRef = React.createRef();
    }

    componentDidMount = () => {
        let self = this;
        let $calendar = $(this.calendarRef.current);
        let calendar = $calendar.fullCalendar({
            themeSystem: 'bootstrap4',
            locale: this.props.i18n.selected.lang,
            events: this.state.events,
            editable: false,
            draggable: false,
            selectable: false,
            selectHelper: false,
            unselectAuto: false,
            disableResizing: false,
            droppable: false,
            timeFormat: 'hh:mm a',
            defaultTimedEventDuration: '00:30:00',
            slotDuration: '00:30:00',
            header: {
                left: 'prev,next today',
                center: 'title',
                right: 'month,agendaWeek,agendaDay,listMonth'
            },
            buttonText: {
                today: $$('today'),
                month: $$('month'),
                week: $$('week'),
                day: $$('day'),
                list: $$('list')
            },

            select: function (start, end, allDay) {
                var title = prompt('Event Title:');
                if (title) {
                    calendar.fullCalendar('renderEvent', {
                            title: title,
                            start: start,
                            end: end,
                            allDay: allDay
                        }, true // make the event "stick"
                    );
                }
                calendar.fullCalendar('unselect');
            },
            id: 1,
            eventRender: function (event, element, view) {
                if (view.type !== 'agendaWeek') return;
                if (appointmentUtils.isAppointmentCanceled(event.appointment.status)) {
                    element.addClass("fc-event-red-2")
                } else if (event.appointment.status === 'COMPLETED') {
                    element.addClass("fc-event-green-1")
                }
            },
            viewRender: function (view) {
                self.props.fetchProviderAppointments(view.intervalStart._i, view.intervalEnd._i);
                self.setState({
                    currentMonth: new Date(view.intervalStart._i).getMonth(),
                    intervalStart: view.intervalStart._i,
                    intervalEnd: view.intervalEnd._i
                });
            },
            theme: true,
            eventLimit: 1,
            eventClick: function (info) {
                if (self.state.viewType === "Week") {
                    $(".selected-event-calendar-week-view").removeClass("selected-event-calendar-week-view");
                    $(this).addClass("selected-event-calendar-week-view");
                    if (!appointmentUtils.isGroupEventParent(info.appointment)) {
                        medicalHistoryHelper.prepareMedicalHistoryData(APPOINTMENTS_APPOINTMENT_EVENT_PRELOAD, info.user, info.appointment);
                    }
                    self.setState({
                        selectedUser: info.user,
                        appointmentState: info.appointment.status,
                        selectedWeekEventElement: this,
                        appointment: info.appointment
                    });
                }
            },
            dayClick: function (date) {
                self.setState({
                    selectedDate: date.format()
                });
                $(".fc-day").removeAttr("style");
                $(".fc-today").css('background-color', 'transparent');
                if (self.state.viewType === "Month") {
                    $(this).css({
                        'border-left': '5px solid #16DBCC',
                        'box-shadow': '0px 1px 20px rgba(20, 46, 110, 0.17)',
                        'border-radius': '8px',
                        'cursor': 'pointer',
                        'background-color': 'white'
                    });
                }
            },
            timezone: 'local',
            windowResize: function () {
                self.renderView();
            }
        });
        $(".fc-today").removeClass('alert');
        $(".fc-today").css('background-color', 'transparent');
        $(".fc-today").css({
            'border-left': '5px solid #16DBCC',
            'box-shadow': '0px 1px 20px rgba(20, 46, 110, 0.17)',
            'border-radius': '8px',
            'cursor': 'pointer',
            'background-color': 'white'
        });
        $("td.fc-day-top.fc-wed.fc-today.alert-info").removeAttr("style");
        this.setState({
            filteredAppointmentsByDate: this.getFilteredAppointments()
        });

        if (self.state.viewType === "Week") {
            $("button.fc-agendaWeek-button.btn.btn-primary").click();
        }
    }

    /**
     * Set canceled appointment in the state
     * @param {*} appointment the appointment for the medical history
     */
    cancelAppointment = (appointment) => {
        this.setState({
            canceledEvent: appointment
        });
    }

    /**
     * Render view on change
     */
    renderView = () => {
        if (this.state.viewType === "Month") {
            let self = this;
            let index = this.state.keysOfDatesWithAppointmentsNumbers.length - 1;
            if (this.state.keysOfDatesWithAppointmentsNumbers.length > 0) {
                $(".fc-more.completed-appointment").remove();
                $(".fc-more.canceled-appointment").remove();
                $(".fc-limited").css("display", "none");
                $(".fc-limited div a.fc-more").removeClass("fc-more");
                $(".fc-limited").removeClass("fc-limited");
                $(".fc-more").each(function (i, obj) {
                    if (index >= 0) {
                        let key = self.state.keysOfDatesWithAppointmentsNumbers[index];
                        $(obj).text(self.state.datesWithAppointmentsNumbers["" + key]["other"]);
                        if (self.state.datesWithAppointmentsNumbers["" + key]["completed"] > 0) {
                            $("<a class='fc-more completed-appointment'>" + self.state.datesWithAppointmentsNumbers["" + key]["completed"] + "</a>").insertAfter(obj);
                        }

                        if (self.state.datesWithAppointmentsNumbers["" + key]["canceled"] > 0) {
                            $("<a class='fc-more canceled-appointment'>" + self.state.datesWithAppointmentsNumbers["" + key]["canceled"] + "</a>").insertAfter(obj);
                        }
                        index--;
                    }
                });
            }
        }
    }

    // eslint-disable-next-line no-unused-vars
    createEvent = (i) => {

    }

    componentDidUpdate(prevProps, prevState) {
        $(".div-container-week").remove();
        if ($(".fc-day-header").length) {
            if (this.state.viewType === "Week") {
                $(this.getWeekDates()).insertAfter("div.fc-toolbar.fc-header-toolbar");
            }
        }
        if (this.state.firstRequest) {
            $('.fc-today-button-custom').click();
            this.setState({
                firstRequest: false
            });
        }
        $(".fc-today").removeClass('alert');
        $(".fc-today").css('background-color', 'transparent');
        if (this.state.viewType === "Month") {
            $(".fc-today").css({
                'border-left': '5px solid #16DBCC',
                'box-shadow': '0px 1px 20px rgba(20, 46, 110, 0.17)',
                'border-radius': '8px',
                'cursor': 'pointer',
                'background-color': 'white'
            });
        }
        $("td.fc-day-top.fc-today.alert-info").removeAttr("style");
        if ((!this.state.events.length &&
                this.props.providerAppointments &&
                this.props.providerAppointments.data &&
                this.props.providerAppointments.data.length) ||
            this.state.events.length &&
            this.props.providerAppointments &&
            this.props.providerAppointments.data &&
            this.props.providerAppointments.data.length &&
            this.state.events[0].start !== this.props.providerAppointments.data[0].starts_at
            && this.state.events[this.state.events.length - 1] !== this.props.providerAppointments.data[this.props.providerAppointments.data.length - 1].ends_at) {
            let events = [];
            let datesWithAppointmentsNumbers = {};
            let keysOfDatesWithAppointmentsNumbers = [];
            for (let i = 0; i < this.props.providerAppointments.data.length; i++) {
                events.push(this.getItems(i));
                let date = new Date(this.props.providerAppointments.data[i].starts_at);
                if (datesWithAppointmentsNumbers["" + date.getDate()]) {
                    if (this.props.providerAppointments.data[i].status === "COMPLETED") {
                        datesWithAppointmentsNumbers["" + date.getDate()]["completed"] = datesWithAppointmentsNumbers["" + date.getDate()]["completed"] + 1;
                    } else if (appointmentUtils.isAppointmentCanceled(this.props.providerAppointments.data[i].status)) {
                        datesWithAppointmentsNumbers["" + date.getDate()].canceled = datesWithAppointmentsNumbers["" + date.getDate()].canceled + 1;
                    } else {
                        datesWithAppointmentsNumbers["" + date.getDate()].other = datesWithAppointmentsNumbers["" + date.getDate()].other + 1;
                    }
                } else {
                    keysOfDatesWithAppointmentsNumbers.push(date.getDate());
                    datesWithAppointmentsNumbers["" + date.getDate()] = {};
                    if (this.props.providerAppointments.data[i].status === "COMPLETED") {
                        datesWithAppointmentsNumbers["" + date.getDate()]["completed"] = 1;
                        datesWithAppointmentsNumbers["" + date.getDate()]["other"] = 0;
                        datesWithAppointmentsNumbers["" + date.getDate()]["canceled"] = 0;
                    } else if (appointmentUtils.isAppointmentCanceled(this.props.providerAppointments.data[i].status)) {
                        datesWithAppointmentsNumbers["" + date.getDate()]["completed"] = 0;
                        datesWithAppointmentsNumbers["" + date.getDate()]["other"] = 0;
                        datesWithAppointmentsNumbers["" + date.getDate()]["canceled"] = 1;
                    } else {
                        datesWithAppointmentsNumbers["" + date.getDate()]["completed"] = 0;
                        datesWithAppointmentsNumbers["" + date.getDate()]["other"] = 1;
                        datesWithAppointmentsNumbers["" + date.getDate()]["canceled"] = 0;
                    }
                }
            }
            $('#calendar').fullCalendar('renderEvents', events, true);
            this.setState({
                events: events,
                datesWithAppointmentsNumbers: datesWithAppointmentsNumbers,
                keysOfDatesWithAppointmentsNumbers: keysOfDatesWithAppointmentsNumbers
            });
        }
        if (this.state.events.length &&
            prevProps &&
            prevProps.providerAppointments &&
            this.props.providerAppointments &&
            JSON.stringify(prevProps.providerAppointments.data) !== JSON.stringify(this.props.providerAppointments.data)) {
            let events = [];
            let datesWithAppointmentsNumbers = {};
            let keysOfDatesWithAppointmentsNumbers = [];
            for (let i = 0; i < this.props.providerAppointments.data.length; i++) {
                events.push(this.getItems(i));
                let date = new Date(this.props.providerAppointments.data[i].starts_at);
                if (datesWithAppointmentsNumbers["" + date.getDate()]) {
                    if (this.props.providerAppointments.data[i].status === "COMPLETED") {
                        datesWithAppointmentsNumbers["" + date.getDate()]["completed"] = datesWithAppointmentsNumbers["" + date.getDate()]["completed"] + 1;
                    } else if (appointmentUtils.isAppointmentCanceled(this.props.providerAppointments.data[i].status)) {
                        datesWithAppointmentsNumbers["" + date.getDate()]["canceled"] = datesWithAppointmentsNumbers["" + date.getDate()]["canceled"] + 1;
                    } else {
                        datesWithAppointmentsNumbers["" + date.getDate()]["other"] = datesWithAppointmentsNumbers["" + date.getDate()]["other"] + 1;
                    }
                } else {
                    keysOfDatesWithAppointmentsNumbers.push(date.getDate());
                    datesWithAppointmentsNumbers["" + date.getDate()] = {};
                    if (this.props.providerAppointments.data[i].status === "COMPLETED") {
                        datesWithAppointmentsNumbers["" + date.getDate()]["completed"] = 1;
                        datesWithAppointmentsNumbers["" + date.getDate()]["other"] = 0;
                        datesWithAppointmentsNumbers["" + date.getDate()]["canceled"] = 0;
                    } else if (appointmentUtils.isAppointmentCanceled(this.props.providerAppointments.data[i].status)) {
                        datesWithAppointmentsNumbers["" + date.getDate()]["completed"] = 0;
                        datesWithAppointmentsNumbers["" + date.getDate()]["other"] = 0;
                        datesWithAppointmentsNumbers["" + date.getDate()]["canceled"] = 1;
                    } else {
                        datesWithAppointmentsNumbers["" + date.getDate()]["completed"] = 0;
                        datesWithAppointmentsNumbers["" + date.getDate()]["other"] = 1;
                        datesWithAppointmentsNumbers["" + date.getDate()]["canceled"] = 0;
                    }
                }
            }
            this.setState({
                datesWithAppointmentsNumbers: datesWithAppointmentsNumbers,
                keysOfDatesWithAppointmentsNumbers: keysOfDatesWithAppointmentsNumbers
            });
            $('#calendar').fullCalendar('removeEvents');
            $('#calendar').fullCalendar('renderEvents', events, true);
        }
        if (prevProps && prevProps.i18n.selected !== this.props.i18n.selected) {
            $(this.calendarRef.current).fullCalendar('option', 'locale', this.props.i18n.selected.lang);
            $(this.calendarRef.current).fullCalendar('option', 'buttonText', {
                today: $$('today'),
                month: $$('month'),
                week: $$('week'),
                day: $$('day'),
                list: $$('list')
            });
            $('.fc-today-button').text($$('today'));
        }
        if (prevState && prevState.selectedDate !== this.state.selectedDate) {
            this.setState({
                filteredAppointmentsByDate: this.getFilteredAppointments()
            });
        }
        if (prevProps.providerAppointments !== this.props.providerAppointments) {
            this.setState({
                filteredAppointmentsByDate: this.getFilteredAppointments()
            });
        }
        $("td.fc-event-container").each(function (i, obj) {
            $(obj).html("<div><a class='fc-more'>1</a></div>");
            $(obj).addClass("fc-more-cell");
            $(obj).removeClass("fc-event-container");
        });
        if (this.state.viewType == "Month") {
            $("a.fc-more").prop("onclick", null).off("click");
        }
        $(".fc-day-number").css("font-size", "1.25rem");
        var self = this;
        $(".fc-day-number").filter(function () {
            return $(this).css('color') === "rgb(48, 53, 124)" &&
                $(this).text() === "" + new Date(self.state.selectedDate).getDate() &&
                new Date(self.state.selectedDate).getMonth() === self.state.currentMonth;
        }).css("font-size", "2.2rem");
        $("td.fc-other-month span.fc-day-number").css("font-size", "1.25rem");
        $(".right-space-in-day").remove();
        $("thead").append("<span class='right-space-in-day' style='font-size: 1.5rem; color: white'>.</span>");
        $("thead.fc-head").css("display", "none");
        if (new Date(this.state.selectedDate).getMonth() === this.state.currentMonth) {
            $("[data-date='" + moment(this.state.selectedDate).format('YYYY-MM-DD') + "']").click();
            $(".fc-day").removeAttr("style");
            $(".fc-today").css('background-color', 'transparent');
            if (this.state.viewType === "Month") {
                $(".fc-day[data-date='" + moment(this.state.selectedDate).format('YYYY-MM-DD') + "']").css({
                    'border-left': '5px solid #16DBCC',
                    'box-shadow': '0px 1px 20px rgba(20, 46, 110, 0.17)',
                    'border-radius': '8px',
                    'cursor': 'pointer',
                    'background-color': 'white'
                });
            }
        } else {
            $(".fc-day").removeAttr("style");
            $(".fc-today").css('background-color', 'transparent');
        }
        if (prevState.datesWithAppointmentsNumbers !== this.state.datesWithAppointmentsNumbers) {
            this.renderView();
        }
        if (this.state.viewType === 'Month' && prevState.viewType === 'Week') {
            this.renderView();
        }
        if (prevProps.i18n.selected.lang !== this.props.i18n.selected.lang) {
            this.renderView();
        }
        if (prevProps.providerAppointments !== this.props.providerAppointments && this.state.canceledEvent) {
            let events = [];
            let datesWithAppointmentsNumbers = {};
            let keysOfDatesWithAppointmentsNumbers = [];
            for (let i = 0; i < this.props.providerAppointments.data.length; i++) {
                events.push(this.getItems(i));
                let date = new Date(this.props.providerAppointments.data[i].starts_at);
                if (datesWithAppointmentsNumbers["" + date.getDate()]) {
                    if (this.props.providerAppointments.data[i].status === "COMPLETED") {
                        datesWithAppointmentsNumbers["" + date.getDate()]["completed"] = datesWithAppointmentsNumbers["" + date.getDate()]["completed"] + 1;
                    } else if (appointmentUtils.isAppointmentCanceled(this.props.providerAppointments.data[i].status)) {
                        datesWithAppointmentsNumbers["" + date.getDate()].canceled = datesWithAppointmentsNumbers["" + date.getDate()].canceled + 1;
                    } else {
                        datesWithAppointmentsNumbers["" + date.getDate()].other = datesWithAppointmentsNumbers["" + date.getDate()].other + 1;
                    }
                } else {
                    keysOfDatesWithAppointmentsNumbers.push(date.getDate());
                    datesWithAppointmentsNumbers["" + date.getDate()] = {};
                    if (this.props.providerAppointments.data[i].status === "COMPLETED") {
                        datesWithAppointmentsNumbers["" + date.getDate()]["completed"] = 1;
                        datesWithAppointmentsNumbers["" + date.getDate()]["other"] = 0;
                        datesWithAppointmentsNumbers["" + date.getDate()]["canceled"] = 0;
                    } else if (appointmentUtils.isAppointmentCanceled(this.props.providerAppointments.data[i].status)) {
                        datesWithAppointmentsNumbers["" + date.getDate()]["completed"] = 0;
                        datesWithAppointmentsNumbers["" + date.getDate()]["other"] = 0;
                        datesWithAppointmentsNumbers["" + date.getDate()]["canceled"] = 1;
                    } else {
                        datesWithAppointmentsNumbers["" + date.getDate()]["completed"] = 0;
                        datesWithAppointmentsNumbers["" + date.getDate()]["other"] = 1;
                        datesWithAppointmentsNumbers["" + date.getDate()]["canceled"] = 0;
                    }
                }
            }
            this.setState({
                datesWithAppointmentsNumbers: datesWithAppointmentsNumbers,
                keysOfDatesWithAppointmentsNumbers: keysOfDatesWithAppointmentsNumbers,
                canceledEvent: null
            });
            $('#calendar').fullCalendar('removeEvents');
            $('#calendar').fullCalendar('renderEvents', events, true);
        }
    }

    getItems = (i) => {
        let appointment = this.props.providerAppointments.data[i];
        let participant = appointmentUtils.isGroupEventParent(appointment) ? null : appointment.participants.find(p=>p.participant_role === 'PATIENT');
        let title = appointmentUtils.isGroupEventParent(appointment) ? appointmentUtils.getExaminationName(appointment.appointment_price) : appointment.participants.find(p=>p.participant_role === 'PATIENT').fullname;
        return {
            appointment: appointment,
            user: participant,
            title: title,
            start: appointment.starts_at,
            end: appointment.ends_at
        };
    }

    getWeekDays = () => {
        if ($(".fc-day-header").length) {
            const today = new Date().getDay();
            const activeSunday = classNames('', {
                'current-day-of-week': (today === 0)
            });
            const activeMonday = classNames('', {
                'current-day-of-week': (today === 1)
            });
            const activeTuesday = classNames('', {
                'current-day-of-week': (today === 2)
            });
            const activeWednesday = classNames('', {
                'current-day-of-week': (today === 3)
            });
            const activeThursday = classNames('', {
                'current-day-of-week': (today === 4)
            });
            const activeFriday = classNames('', {
                'current-day-of-week': (today === 5)
            });
            const activeSaturday = classNames('', {
                'current-day-of-week': (today === 6)
            });
            let weekDays = {
                "mon": {
                    "className": activeMonday,
                    "label": "short_monday_label"
                },
                "tue": {
                    "className": activeTuesday,
                    "label": "short_tuesday_label"
                },
                "wed": {
                    "className": activeWednesday,
                    "label": "short_wednesday_label"
                },
                "thu": {
                    "className": activeThursday,
                    "label": "short_thursday_label"
                },
                "fri": {
                    "className": activeFriday,
                    "label": "short_friday_label"
                },
                "sat": {
                    "className": activeSaturday,
                    "label": "short_saturday_label"
                },
                "sun": {
                    "className": activeSunday,
                    "label": "short_sunday_label"
                }
            };
            var orderedWeekDays = [];
            $(".fc-day-header").each(function (i, obj) {
                orderedWeekDays.push($(obj).attr("class").replace(/  +/g, ' ').split(" ")[1].split("-")[1]);
            });
            return (
                <div className="grid-container">
                    <div className="grid-item"><span
                        className={weekDays[orderedWeekDays[0]].className}>{$$(weekDays[orderedWeekDays[0]].label)}</span>
                    </div>
                    <div className="grid-item"><span
                        className={weekDays[orderedWeekDays[1]].className}>{$$(weekDays[orderedWeekDays[1]].label)}</span>
                    </div>
                    <div className="grid-item"><span
                        className={weekDays[orderedWeekDays[2]].className}>{$$(weekDays[orderedWeekDays[2]].label)}</span>
                    </div>
                    <div className="grid-item"><span
                        className={weekDays[orderedWeekDays[3]].className}>{$$(weekDays[orderedWeekDays[3]].label)}</span>
                    </div>
                    <div className="grid-item"><span
                        className={weekDays[orderedWeekDays[4]].className}>{$$(weekDays[orderedWeekDays[4]].label)}</span>
                    </div>
                    <div className="grid-item"><span
                        className={weekDays[orderedWeekDays[5]].className}>{$$(weekDays[orderedWeekDays[5]].label)}</span>
                    </div>
                    <div className="grid-item"><span
                        className={weekDays[orderedWeekDays[6]].className}>{$$(weekDays[orderedWeekDays[6]].label)}</span>
                    </div>
                </div>
            );
        }
    }

    getWeekDates = () => {
        return `
            <div class="div-calendar-week-days">
                <div class="row div-container-week">
                    <div class="grid-container-week">
                        <div class="grid-item-week">
                            <span>
                                ` + moment(new Date(this.state.intervalStart)).format('DD MMM') + `
                            </span>
                        </div>
                        <div class="grid-item-week">
                            <span>
                                ` + moment(new Date(this.state.intervalStart + 86400000)).format('DD MMM') + `
                            </span>
                        </div>
                        <div class="grid-item-week">
                            <span>
                                ` + moment(new Date(this.state.intervalStart + (2 * 86400000))).format('DD MMM') + `
                            </span>
                        </div>
                        <div class="grid-item-week">
                            <span>
                                ` + moment(new Date(this.state.intervalStart + (3 * 86400000))).format('DD MMM') + `
                            </span>
                        </div>
                        <div class="grid-item-week">
                            <span>
                                ` + moment(new Date(this.state.intervalStart + (4 * 86400000))).format('DD MMM') + `
                            </span>
                        </div>
                        <div class="grid-item-week">
                            <span>
                                ` + moment(new Date(this.state.intervalStart + (5 * 86400000))).format('DD MMM') + `
                            </span>
                        </div>
                        <div class="grid-item-week">
                            <span>
                                ` + moment(new Date(this.state.intervalStart + (6 * 86400000))).format('DD MMM') + `
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    /**
     * Filters the appointments based on the selected date
     *
     * @returns {Array} the list of filtered appointments
     */
    getFilteredAppointments = () => {
        return this.props.providerAppointments &&
        this.props.providerAppointments.data &&
        this.props.providerAppointments.data.length > 0
            ? this.props.providerAppointments.data.filter(a => moment(new Date(a.starts_at)).format('YYYY-MM-DD') === moment(this.state.selectedDate).format('YYYY-MM-DD'))
                .sort(function (a, b) {
                    return a.starts_at - b.starts_at;
                })
            : [];
    }

    /**
     * An event handler triggered when a new appointment is choosen
     * from the list of appointments
     *
     * @param {object} appointment - the appointment object
     */
    onAppointmentChange = (appointment) => {
        this.props.selectAppointment(appointment);
    }

    render() {
        const month = classNames('fc-month-button btn btn-primary', {
            'active-calendar-view': (this.state.viewType === 'Month')
        });
        const week = classNames('fc-agendaWeek-button btn btn-primary', {
            'active-calendar-view': (this.state.viewType === 'Week')
        });

        return (
            <div className="history d-flex flex-container flex-row">
                <div className="appointments-page-left-col">
                    <div className="row change-month-week-day">
                        <div className="btn-group">
                            <button type="button" className={month} onClick={() => {
                                $("button.fc-month-button.btn.btn-primary").click();
                                if (this.state.viewType !== 'Month') {
                                    this.setState({
                                        viewType: 'Month'
                                    });
                                    this.props.userSelections.appointments.calendarView = "Month";
                                    this.props.setUserSelections(this.props.userSelections, null);
                                }
                            }}>{$$('month')}</button>
                            <button type="button" className={week} onClick={() => {
                                $("button.fc-agendaWeek-button.btn.btn-primary").click();
                                if (this.state.viewType !== 'Week') {
                                    this.setState({
                                        viewType: 'Week'
                                    });
                                    this.props.userSelections.appointments.calendarView = "Week";
                                    this.props.setUserSelections(this.props.userSelections, null);
                                }
                            }}>{$$('week')}</button>
                        </div>
                        <div className="btn-group choose-day-button">
                            <button type="button" className="fc-prev-button-custom btn btn-primary" aria-label="prev"
                                    onClick={() => {
                                        $("button.fc-prev-button.btn.btn-primary").click()
                                    }}>
                                <span className="fa fa-chevron-left"/>
                            </button>
                            <button type="button" className="fc-today-button-custom btn btn-primary" onClick={() => {
                                $("button.fc-today-button.btn.btn-primary").click();
                                let self = this;
                                setTimeout(function () {
                                    self.setState({
                                        selectedDate: new Date()
                                    });
                                    $(".fc-day").removeAttr("style");
                                    $(".fc-today").css('background-color', 'transparent');
                                    if (self.state.viewType === "Month") {
                                        $(".fc-today").css({
                                            'border-left': '5px solid #16DBCC',
                                            'box-shadow': '0px 1px 20px rgba(20, 46, 110, 0.17)',
                                            'border-radius': '8px',
                                            'cursor': 'pointer',
                                            'background-color': 'white'
                                        });
                                    }
                                    $("td.fc-day-top.fc-today.alert-info").removeAttr("style");
                                }, 500);
                            }}>{$$('today')}</button>
                            <button type="button" className="fc-next-button-custom btn btn-primary" aria-label="next"
                                    onClick={() => {
                                        $("button.fc-next-button.btn.btn-primary").click()
                                    }}>
                                <span className="fa fa-chevron-right"/>
                            </button>
                        </div>
                    </div>
                    {this.getWeekDays()}
                    <div className="calendar-container">
                        <div id="calendar" ref={this.calendarRef}/>
                    </div>
                </div>
                {this.state.viewType === "Week" && this.state.selectedUser && !appointmentUtils.isGroupEventParent(this.state.appointment) &&
                <div className="calendar-appointments-card-week-view flex-shrink-0 flex-grow-0">
                    <PatientVerticalCard
                        hide={false}
                        appointmentsView={! appointmentUtils.isAppointmentCanceled(this.state.appointmentState )}
                        cancelAppointment={this.cancelAppointment}
                        appointment={this.state.appointment}
                        i18n={this.props.i18n}/>
                </div>}
                {this.state.viewType === "Week" && this.state.appointment && appointmentUtils.isGroupEventParent(this.state.appointment) &&
                    <div className="calendar-appointments-card-week-view flex-shrink-0 flex-grow-0">
                        <AppointmentsListItemForCalendar appointment={this.state.appointment}
                                                         selectedAppointmentId={this.state.appointment.id}
                                                         i18n={this.props.i18n}
                                                         cancelAppointment={this.cancelAppointment}/>
                    </div>
                }
                {this.state.viewType === "Week" && !this.state.selectedUser && (!this.state.appointment || !appointmentUtils.isGroupEventParent(this.state.appointment)) &&
                <div className="calendar-appointments-card">
                    <div className={"centered-text"}>
                        <img src={no_appointments} className={"no-appointments-img"}/>
                        <h4 className="medrec-blue-1">{$$('no_selected_user')}</h4>
                        <p className={"selected-user-text"}>{$$('selected_user_will_appear_here')}</p>
                    </div>
                </div>}
                {this.state.viewType === "Month" &&
                <div className="calendar-appointments-card">
                    <AppointmentsListForCalendar i18n={this.props.i18n.selected}
                                                 chosenDate={moment(this.state.selectedDate).format('YYYY-MM-DD')}
                                                 appointments={this.state.filteredAppointmentsByDate}
                                                 onAppointmentChange={() => {
                                                     this.onAppointmentChange
                                                 }}
                                                 selectAppointment={this.props.selectAppointment}
                                                 selectAppointmentInProgress={this.props.selectAppointmentInProgress}
                                                 onMessagesClick={this.props.onMessagesClick}
                                                 cancelAppointment={this.cancelAppointment}/>
                </div>}
            </div>
        )
    }
}

Calendar.propTypes = {
    clearProviderAppointments:  PropTypes.func,
    clearSelectedAppointmentInProgress: PropTypes.func,
    clearSelectedUserData:  PropTypes.func,
    fetchProviderAppointments:  PropTypes.func,
    i18n: PropTypes.object,
    onMessagesClick:  PropTypes.func,
    providerAppointments:  PropTypes.object,
    selectAppointment: PropTypes.func,
    selectAppointmentInProgress: PropTypes.func,
    selectUser:  PropTypes.func,
    selectedAppointment:  PropTypes.object,
    setUserSelections:  PropTypes.func,
    userSelections: PropTypes.object
}

export default Calendar
