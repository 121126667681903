import React, {useEffect, useState} from "react"
import {ListGroup} from "react-bootstrap";
import {ItemBody} from "./MedicalNoticeList";

export default function EncounterDetailsMedicalNoticeList({encounter, lang}) {
    const [medicalNotices, setMedicalNotices] = useState();

    useEffect(() => {
        setMedicalNotices(encounter.nhis_medical_notice_jsons.map(n => {
            return JSON.parse(n).viewModel;
        }))
    }, [encounter.nhis_medical_notice_jsons]);


    if (!medicalNotices) {
        return null;
    }

    return <ListGroup className={"hover-shadow-boxes-list"}>
        {medicalNotices.map(n => {
            return <MedicalNoticeListItem key={n.nrnMedicalNotice} notice={n} lang={lang}/>
        })}
    </ListGroup>
}

function MedicalNoticeListItem({notice, lang}) {
    return <div className="list-group-item">
        <ItemBody notice={notice} lang={lang}/>
        <hr className="kt-margin-b-0"/>
    </div>
}