import {
    CLEAR_NOTIFICATIONS,
    GET_MORE_NOTIFICATIONS_REQUEST_SUCCESS,
    GET_NOTIFICATIONS_REQUEST_ERROR,
    GET_NOTIFICATIONS_REQUEST_SENT,
    GET_NOTIFICATIONS_REQUEST_SUCCESS,
    LOGOUT,
    REQUEST_ERROR,
    REQUEST_SENT,
    REQUEST_SUCCESS,
    UPDATE_NOTIFICATION_STATUS_REQUEST_ERROR,
    UPDATE_NOTIFICATION_STATUS_REQUEST_SENT,
    UPDATE_NOTIFICATION_STATUS_REQUEST_SUCCESS
} from "../actions/actions";
import {requestStatus} from './requests_reducers';


/**
 * Notifications reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const notificationsInitialState = {data: {}, request: requestStatus(undefined, {})}

export function notifications(state = notificationsInitialState, action) {
    switch (action.type) {
        case GET_NOTIFICATIONS_REQUEST_SENT: {
            return {...state, createRequest: requestStatus(state.request, {type: REQUEST_SENT})}
        }
        case GET_NOTIFICATIONS_REQUEST_SUCCESS: {
            return {...state, data: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case GET_MORE_NOTIFICATIONS_REQUEST_SUCCESS: {
            return {
                ...state,
                data: [...state.data, ...action.result],
                request: requestStatus(state.request, {type: REQUEST_SUCCESS})
            }
        }
        case GET_NOTIFICATIONS_REQUEST_ERROR: {
            return {
                ...state,
                createRequest: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
            }
        }
        case UPDATE_NOTIFICATION_STATUS_REQUEST_SENT: {
            return {...state, updateRequest: requestStatus(state.request, {type: REQUEST_SENT})}
        }
        case UPDATE_NOTIFICATION_STATUS_REQUEST_SUCCESS: {
            return {...state, data: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case UPDATE_NOTIFICATION_STATUS_REQUEST_ERROR: {
            return {
                ...state,
                updateRequest: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
            }
        }
        case CLEAR_NOTIFICATIONS:
        case LOGOUT: {
            return {...state, ...notificationsInitialState};
        }
        default: {
            return state;
        }
    }
}
