import {COMPLETED, IN_PROGRESS, INITIAL} from "../constants/appointment_payment_status";
import {$$} from "../helpers/localization";
import {REJECTED} from "../constants/video_message_status";

export const paymentStatusUtils = {
    paymentStatus
}

function paymentStatus(appointment) {

    let paymentStatus = {
        colour : "",
        label : ""
    }

    const consultationSuffix = appointment.text_only ? "_consultation" : "";

    if (appointment.payment_status === COMPLETED) {
        paymentStatus.label = $$('payment_completed' + consultationSuffix);
        paymentStatus.colour = "medrec-green-1"
    } else if (appointment.payment_status === INITIAL) {
        paymentStatus.label = $$('payment_initial' + consultationSuffix);
        paymentStatus.colour = "medrec-grey-2"
    } else if (appointment.payment_status === IN_PROGRESS) {
        paymentStatus.label = $$('payment_in_progress' + consultationSuffix);
        paymentStatus.colour = "medrec-orange-1";
    } else if (appointment.payment_status === REJECTED) {
        paymentStatus.label = $$('payment_rejected' + consultationSuffix);
        paymentStatus.colour = "medrec-red-3"
    }

    return paymentStatus
}