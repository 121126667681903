import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import {Routes} from '../../constants/routes';
import OpenAppointmentButton from '../appointments/OpenAppointmentButton';
import MessagesButton from '../shared/MessagesButton';
import PatientInfoButton from './PatientInfoButton';
import CancelAppointmentButton from '../appointments/CancelAppointmentButton';
import CenteredModal from '../shared/CenteredModal';
import moment from 'moment'
import {
    APPOINTMENTS_APPOINTMENT_EVENT,
    RETURN_TO_PATIENT_MED_HISTORY
} from "../../constants/pages";
import {medicalHistoryHelper} from "../../helpers/medical_history_helper";
import PropTypes from "prop-types";
import {appointmentUtils} from "../../utils/appointmentUtils";
import MedicalHistoryButton from "../History/MedicalHistoryButton";
import CancelAppointmentModal from "../appointment/CancelAppointmentModal";


export class PatientHeader extends Component {
    state = {
        cancelAppointmentModalOpened: false
    }

    constructor(props) {
        super(props);
        this.state = {
            infoIsActive: this.props.patientInfo
        }
    }

    handleMessagesClicked = () => {
        let infoIsActive = false;
        this.setState({infoIsActive: infoIsActive});
        this.props.onSelect(infoIsActive);
    };

    handleInfoClicked = () => {
        let infoIsActive = true;
        this.setState({infoIsActive: infoIsActive});
        this.props.onSelect(infoIsActive);
    };

    /**
     * Cancel appointment on confirmation
     */
    cancelAppointment = (app, note) => {
        this.props.updateAppointmentStatus(this.props.appointment.id, "CANCELED_BY_PROVIDER", note);
        this.props.cancelAppointment(this.props.appointment);
        this.setState({cancelAppointmentModalOpened: false});
    }

    render() {
        return (
            <div className={"patient-header-container"}>
                <div className='row space-between-justify' style={{'marginLeft': '0rem'}}>
                    <div className="patient-header">{$$('next_patient_label')}</div>
                    <div className='row patient-actions'>
                        {this.props.appointmentsView && appointmentUtils.canCancel(this.props.appointment) &&
                           <CancelAppointmentButton title={$$('cancel_appointment_label')}
                                                     style={{'marginLeft': '0.625rem'}} onClick={() => {
                                this.setState({cancelAppointmentModalOpened: true});
                            }}/>}

                        {!window.location.pathname.includes("history") &&
                            !window.location.pathname.includes("m-patient")&&
                        <MedicalHistoryButton title={$$('medical_history')} style={{'marginLeft': '0.625rem'}}
                                              onClick={() => {
                                                  medicalHistoryHelper.prepareMedicalHistoryData(RETURN_TO_PATIENT_MED_HISTORY, this.props.selectedUser, null, Routes.HISTORY);
                                                  return true;
                                              }}/>
                        }

                        {this.props.appointmentsView &&
                            ! appointmentUtils.isAppointmentCanceled(this.props.appointment.status) &&
                        <OpenAppointmentButton title={$$('open_appointment_label')} style={{'marginLeft': '0.625rem'}}
                                               onClick={() => {
                                                   medicalHistoryHelper.prepareMedicalHistoryData(APPOINTMENTS_APPOINTMENT_EVENT, this.props.selectedUser, this.props.appointment, Routes.APPOINTMENT);
                                               }}/>}
                        {this.props.appointment?.appointment_price?.encounter_price_type !== "TEXT_ONLY" && <MessagesButton title={$$('messages_label')} style={{'marginLeft': '0.625rem'}}
                                        messagesIsActive={!this.state.infoIsActive}
                                        onClick={this.handleMessagesClicked}/>}
                        <PatientInfoButton title={$$('patient_information_label')} style={{'marginLeft': '0.625rem'}}
                                           infoIsActive={this.state.infoIsActive} onClick={this.handleInfoClicked}/>
                    </div>
                </div>
                <hr className={"hr"}/>
                {this.props.appointment.participants &&
                 <CancelAppointmentModal
                    appointment={this.props.appointment}
                    show={this.state.cancelAppointmentModalOpened}
                    onHide={() => {
                        this.setState({cancelAppointmentModalOpened: false})
                    }}
                    onConfirm={this.cancelAppointment}
                 />}
            </div>
        )
    }
}

PatientHeader.propTypes = {
    appointment: PropTypes.object,
    i18n: PropTypes.object,
    appointmentsView: PropTypes.any,
    onSelect: PropTypes.func,
    cancelAppointment: PropTypes.func,
    patientInfo: PropTypes.bool,
    selectedUser:PropTypes.object,
    updateAppointmentStatus: PropTypes.func
}

export default PatientHeader;
