import {
    CLEAR_PATIENTS,
    FETCH_SELECTED_USER_SYMPTOMS_ERROR,
    FETCH_SELECTED_USER_SYMPTOMS_REQUEST_SENT,
    FETCH_SELECTED_USER_SYMPTOMS_SUCCESS,
    GET_NOMENCLATURE_ERROR,
    GET_NOMENCLATURE_REQUEST_SENT,
    GET_NOMENCLATURE_SUCCESS,
    LOGOUT,
    NO_MORE_SYMPTOMS,
    REQUEST_ERROR,
    REQUEST_SENT,
    REQUEST_SUCCESS,
    RESET_SELECTED_USER_SYMPTOMS,
    RESET_SELECTED_USER_SYMPTOMS_SUCCESS,
    SELECT_USER
} from "../actions/actions";
import {requestStatus} from './requests_reducers';

const initialState = {data: {}, request: requestStatus(undefined, {})}

/**
 * Nomenclature reducer. Reduce state based on action type.
 *
 * @param {object} state the state of nomenclature
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
export function nomenclature(state = initialState, action) {
    switch (action.type) {
        case GET_NOMENCLATURE_REQUEST_SENT: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_SENT})};
        }
        case GET_NOMENCLATURE_SUCCESS: {
            return {...state, data: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})};
        }
        case GET_NOMENCLATURE_ERROR:
            return {
                ...state, ...initialState,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
            };
        case LOGOUT: {
            return {...state, ...initialState};
        }
        default: {
            return state
        }
    }
}

/**
 * Symptoms log reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the symptoms logs
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const symptomsInitialState = {entries: [], isLastPage: false, request: requestStatus(undefined, {})}

export function symptoms(state = symptomsInitialState, action) {
    switch (action.type) {
        case FETCH_SELECTED_USER_SYMPTOMS_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, isLastPage, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT})}
        }
        case FETCH_SELECTED_USER_SYMPTOMS_SUCCESS: {
            return {
                ...state,
                entries: [...state.entries, ...action.result],
                request: requestStatus(state.request, {type: REQUEST_SUCCESS})
            }
        }
        case FETCH_SELECTED_USER_SYMPTOMS_ERROR: {
            const {entries, isLastPage, ...rest} = state;
            return {...rest, entries: [], request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case RESET_SELECTED_USER_SYMPTOMS_SUCCESS: {
            return {...state, entries: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case NO_MORE_SYMPTOMS: {
            return {...state, isLastPage: true}
        }
        case RESET_SELECTED_USER_SYMPTOMS:
        case SELECT_USER:
        case CLEAR_PATIENTS:
        case LOGOUT: {
            return {...state, ...symptomsInitialState};
        }
        default: {
            return state;
        }
    }
}