import React from 'react'
import {$$} from '../../../helpers/localization'
import {connect} from 'react-redux'
import WizardHeader from './WizardHeader'
import WizardSteps from './WizardSteps'
import PropTypes from "prop-types";


export class Wizard extends React.Component {
    state = {
        registrationFields: {
            id: '',
            fullname: '',
            title: '',
            email: '',
            password: '',
            confirm_password: '',
            phone_numbers: [],
            specialties: [],
            nhis_specialties: [],
            uin: '',
            city: '',
            country: '',

            languages: [],
            other_info: '',

            org_name: '',
            address: '',
            org_city: '',
            org_country: '',
            website: '',
            org_phone_numbers: [],
            org_other_info: '',
            org_uin: '',
            internal: this.props.isInternal,
            documents: []
        },

        wizardStep: 0,
        completedSteps: this.props.isInternal ? {
            firstStep: false,
            secondStep: false,
        } : {
            firstStep: false,
            secondStep: false,
            thirdStep: false,
            fourthStep: false,
        },
        shouldResend: false,
    }

    constructor(props) {
        super(props)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.registration.response && props.registration.response.status === 403) {
            return {
                wizardStep: 0,
                shouldResend: false
            }
        }
        return state;
    }

    /**
     * Updates the active wizard step
     *
     * @param {object} fields - the fields to be set to the state
     * @param {number} wizardStep - the updated/new wizard step
     * @param {boolean} isCompleted - a boolean to indicate whether a step is completed or not
     * @param {boolean} shouldResend - the boolean value which is primary used for server errors
     */
    onWizardStepChange = (fields, wizardStep, isCompleted, shouldResend) => {
        if (this.state.wizardStep === 0) {
            this.prepareStateData(fields, 'firstStep', isCompleted, wizardStep);
        } else if (this.state.wizardStep === 1) {
            this.prepareStateData(fields, 'secondStep', isCompleted, wizardStep, shouldResend && this.props.isInternal);
        } else if (this.state.wizardStep === 2) {
            this.prepareStateData(fields, 'thirdStep', isCompleted, wizardStep);
        } else {
            this.prepareStateData(fields, 'fourthStep', isCompleted, wizardStep, shouldResend);
        }
    }

    /**
     * Prepares the state data based on the step we are
     *
     * @param {object} fields - the fields to be set to the state
     * @param {string} step - the current step we are at
     * @param {boolean} isCompleted - a boolean to indicate whether a step is completed or not
     * @param {number} wizardStep - the updated/new wizard step
     * @param {boolean} shouldResend - the boolean value which is primary used for server errors
     */
    prepareStateData = (fields, step, isCompleted, wizardStep, shouldResend) => {
        let completedSteps = {...this.state.completedSteps};
        completedSteps[step] = isCompleted;
        let registrationFields = Object.assign(this.state.registrationFields, fields);
        this.setState({registrationFields, wizardStep, completedSteps, shouldResend}, function () {
            if (this.props.isInternal) {
                if (this.state.wizardStep === 1 && this.state.completedSteps.secondStep === true && shouldResend) {
                    this.handleRegistering();
                }
            } else {
                if (this.state.wizardStep === 3 && this.state.completedSteps.fourthStep === true && shouldResend) {
                    this.handleRegistering();
                }
            }
        });
    }

    /**
     * Handles the register phase
     */
    handleRegistering = () => {
        let registrationFields = Object.assign(this.state.registrationFields, this.getFormattedFields());
        this.setState({registrationFields}, function () {
            let fields = {...this.state.registrationFields, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone};
            // eslint-disable-next-line no-unused-vars
            let {id, ...rest} = fields;
            // eslint-disable-next-line no-unused-vars
            let {documents, ...other} = rest;
            this.props.registerProvider(other, this.state.registrationFields.documents);
        });
    }

    /**
     * Removes unnecessary object properties from some of the registration fields objects
     * by leaving them just string arrays and not object arrays
     *
     * @returns {object} - an object with the formatted fields
     */
    getFormattedFields = () => {
        let fields = {};
        fields.specialties = this.state.registrationFields.specialties;
        fields.languages = this.state.registrationFields.languages;
        fields.phone_numbers = typeof this.state.registrationFields.phone_numbers[0] === 'object' ? this.state.registrationFields.phone_numbers.map(p => p.text) : this.state.registrationFields.phone_numbers;
        fields.org_phone_numbers = typeof this.state.registrationFields.org_phone_numbers[0] === 'object' ? this.state.registrationFields.org_phone_numbers.map(o => o.text) : this.state.registrationFields.org_phone_numbers;
        return fields;
    }

    render() {
        let wizardSteps = this.props.isInternal ? [
            {
                step: 0,
                name: $$('wizard_personal_information'),
                completed: this.state.completedSteps.firstStep
            },
            {
                step: 1,
                name: $$('wizard_practice_details'),
                completed: this.state.completedSteps.thirdStep
            }
        ] : [
            {
                step: 0,
                name: $$('wizard_personal_information'),
                completed: this.state.completedSteps.firstStep
            },
            {
                step: 1,
                name: $$('wizard_biography'),
                completed: this.state.completedSteps.secondStep
            },
            {
                step: 2,
                name: $$('wizard_practice_details'),
                completed: this.state.completedSteps.thirdStep
            },
            {
                step: 3,
                name: $$('wizard_upload_documents'),
                completed: this.state.completedSteps.fourthStep
            }];
        return (
            <>
                {this.props.showWizard &&
                <div className='wizard-container'>
                    <div>
                        <WizardHeader wizardStep={this.state.wizardStep}
                                      wizardSteps={wizardSteps}
                                      i18n={this.props.i18n}
                        />
                    </div>
                    <div className={"wizard-steps-container"}>
                        <WizardSteps wizardStep={this.state.wizardStep}
                                     onWizardStepChange={this.onWizardStepChange}
                                     completedSteps={this.state.completedSteps}
                                     registrationFields={this.state.registrationFields}
                                     specialties={this.props.specialties}
                                     isInternal={this.props.isInternal}
                                     i18n={this.props.i18n}/>
                    </div>
                </div>
                }
            </>
        )
    }
}

Wizard.propTypes = {
    i18n: PropTypes.object,
    registerProvider:PropTypes.func,
    registration: PropTypes.object,
    showWizard: PropTypes.bool,
    specialties: PropTypes.array
}


const mapStateToProps = (state) => ({
    registration: state.register
})

export default connect(mapStateToProps, null)(Wizard)
