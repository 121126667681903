import React, {Component, useCallback, useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {$$, _$$} from "../../helpers/localization";
import {fetchRevenue, fetchRevenueDetails} from "../../actions/clinic_actions";
import {formatUtils} from "../../utils/formatUtils";
import DateFnsUtils from "@date-io/date-fns";
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import endOfMonth from 'date-fns/endOfMonth'
import startOfMonth from 'date-fns/startOfMonth'

import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import _ from "underscore";
import moment from "moment";
import {getSelectedDateTimeFormat} from "../../utils/converter";
import enGB from "date-fns/locale/en-GB";
import bg from "date-fns/locale/bg";
import sq from "date-fns/locale/sq";
import {appointmentUtils} from "../../utils/appointmentUtils";
import PropTypes from "prop-types";
import {getClinicianNameWithTitle} from "../../utils/getClinicianNameWithTitle";
import {GetStaffList} from "../../actions/management_actions";
import {Form} from 'react-bootstrap'
import no_data from "../../resources/images/no_data.png";
import {infoUtils} from "../../utils/infoUtils";
import classNames from 'classnames';

class AppointmentPage extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            month: new Date(),
            before: endOfDay(endOfMonth(new Date())).getTime(),
            after: startOfDay(startOfMonth(new Date())).getTime(),
            bookedByDate: false,
            loading: true,
            paidInMedrecOnly: false
        }
    }

    setLocale = () => {
        let lang = _.contains(['en', 'sq'], this.props.i18n.selected) ? 'en' : this.props.i18n.selected;
        moment.locale(lang.lang);
    }

    componentDidMount() {
        this.setLocale();
        this.props.GetStaffList(this.props.orgId)
        this.props.fetchRevenue(undefined, this.props.orgId, this.state.before, this.state.after, this.state.bookedByDate, !this.state.paidInMedrecOnly).then(() => {
            this.setState({loading: false});
        });
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.i18n.selected !== this.props.i18n.selected) {
            this.setLocale();
        }
    }

    onMonthChange = (date) => {
        let start = startOfDay(startOfMonth(date)).getTime();
        let end = endOfDay(endOfMonth(date)).getTime();
        this.setState({month: date, after: start, before: end, loading: true});
        this.props.fetchRevenue(undefined, this.props.orgId, end, start, this.state.bookedByDate, !this.state.paidInMedrecOnly).then(() => {
            this.setState({loading: false});
            if (this.state.selectedProviderId) {
                this.props.fetchRevenueDetails(this.state.selectedProviderId, this.props.orgId, this.state.before, this.state.after, this.state.bookedByDate, !this.state.paidInMedrecOnly);
            }
        });
    }

    onProviderClicked = (providerId) => {
        if (this.state.selectedProviderId === providerId) {
            //unselect
            this.setState({selectedProviderId: undefined});
        } else {
            this.setState({selectedProviderId: providerId});
            this.props.fetchRevenueDetails(providerId, this.props.orgId, this.state.before, this.state.after, this.state.bookedByDate, !this.state.paidInMedrecOnly);
        }
    }

    onChangeBookedByDate = () => {
        this.setState({bookedByDate: !this.state.bookedByDate}, () => {
            this.props.fetchRevenue(undefined, this.props.orgId, this.state.before, this.state.after, this.state.bookedByDate, !this.state.paidInMedrecOnly).then(() => {
                if (this.state.selectedProviderId) {
                    this.props.fetchRevenueDetails(this.state.selectedProviderId, this.props.orgId, this.state.before, this.state.after, this.state.bookedByDate, !this.state.paidInMedrecOnly);
                }
            });
        })
    }

    onChangePayedInMedrecOnly = () => {
        this.setState({paidInMedrecOnly:!this.state.paidInMedrecOnly},() => {
            this.props.fetchRevenue(undefined, this.props.orgId, this.state.before, this.state.after, this.state.bookedByDate, !this.state.paidInMedrecOnly).then(() => {
                if (this.state.selectedProviderId) {
                    this.props.fetchRevenueDetails(this.state.selectedProviderId, this.props.orgId, this.state.before, this.state.after, this.state.bookedByDate, !this.state.paidInMedrecOnly);
                }
            });
        })
    }

    render() {
        return <div className="patient-card">
            <div className="patient-card-header">
                <h2>{$$("accounting_page_title")}</h2>
                <div className="mt-3">
                    <AccountingFilter month={this.state.month} onMonthChange={this.onMonthChange}
                                      i18n={this.props.i18n.selected} onChangeBookedByDate={this.onChangeBookedByDate}
                                      onChangePayedInMedrecOnly={this.onChangePayedInMedrecOnly}
                                      paidInMedrecOnly={this.state.paidInMedrecOnly}
                                      bookedByDate={this.state.bookedByDate}/>
                    {this.props.revenue.revenue && this.props.revenue.revenue.providers &&
                        <AccountingTable revenue={this.props.revenue.revenue}
                                         onProviderClicked={this.onProviderClicked}
                                         loading={this.state.loading}
                                         staffList={this.props.staffList}
                                         lang={this.props.i18n.selected}
                                         selectedProviderId={this.state.selectedProviderId}/>
                    }
                </div>
            </div>

        </div>
    }
}

AppointmentPage.propTypes = {
    i18n: PropTypes.any,
    fetchRevenueDetails: PropTypes.func,
    GetStaffList: PropTypes.func,
    orgId: PropTypes.string,
    staffList: PropTypes.array,
    fetchRevenue: PropTypes.func,
    revenue: PropTypes.object
}

function mapStateToProps(state) {
    return {
        orgId: state.management.selectedOrganisation.id,
        staffList: state.management.staff,
        i18n: state.language,
        loggedUser: state.userInfo.data,
        revenue: state.revenue,
    }
}

export default connect(mapStateToProps, {fetchRevenue, fetchRevenueDetails, GetStaffList})(AppointmentPage)

class AccountingTable extends Component {

    constructor(props, context) {
        super(props, context);
        this.dateTimeFormat = getSelectedDateTimeFormat(false);
    }

    getNameAndTitle = (p) => {
        for (let i in this.props.staffList) {
            if (p.id === this.props.staffList[i].id) {
                return this.props.staffList[i]
            }
        }
    }

    render() {
        let emptyStatsResultObj = {
            imgClass: 'no-lab-results-img',
            primaryLabelClass: 'no-lab-results-primary-label',
            secondaryLabelClass: 'no-lab-results-secondary-label',
            src: no_data,
            primaryLabel: $$('no_results_found'),
            secondaryLabel: ''
        }

        return <>
            {this.props.loading === true && <div className="report-loader"/>}
            {this.props.loading === false && this.props.revenue.providers.length === 0 && infoUtils.noData(emptyStatsResultObj)}
            {this.props.loading === false && this.props.revenue.providers.length > 0 &&
                <table className="table table-striped medrec-default-text-color">
                    <thead>
                    <tr>
                        <th scope="col"><span className="bold">{$$("clinicians_label")}</span></th>
                        <th scope="col" className="text-right"><span className={"bold"}>{$$("amount")}</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.revenue.providers.map(p => {
                        return <React.Fragment key={p.id}>
                            <tr>
                                <td className="d-table-cell p-2">
                                    <span className="pointer" onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.props.onProviderClicked(p.id)
                                    }} >
                                        <span className="p-2">{p.id === this.props.selectedProviderId ? "\u2013" : "+"}</span>
                                        <span> {getClinicianNameWithTitle(this.getNameAndTitle(p))}</span>
                                    </span>
                                </td>
                                <td className="d-table-cell p-2 text-right">{p.amounts.map(a => {
                                    return <div
                                        key={a.currency}>{formatUtils.currencyFormatTotals(a.amount, a.currency)}</div>
                                })}</td>
                            </tr>
                            {p.id === this.props.selectedProviderId && p.details && p.details.map(item => {
                                return <tr key={item.invoice_id}>
                                    <td className="d-table-cell p-2">
                                        <LineItem item={item} dateTimeFormat={this.dateTimeFormat} lang={this.props.lang}/>
                                    </td>
                                    <td className="d-table-cell p-2 text-right">
                                        <span className={classNames("", {
                                                "text-danger": !item.date_paid || appointmentUtils.isAppointmentCanceled(item.appointment_status),
                                                "strike-through": appointmentUtils.isAppointmentCanceled(item.appointment_status)
                                        })}>
                                            {formatUtils.currencyFormat(item.amount, item.currency)}
                                        </span>
                                    </td>
                                </tr>
                            })}
                        </React.Fragment>
                    })}
                    <tr>
                        <td className="d-table-cell p-2 text-right">{$$("total_amount")}:</td>
                        <td className="d-table-cell p-2 text-right">{this.props.revenue.totals.map(a => {
                            return <div
                                key={a.currency}>{formatUtils.currencyFormatTotals(a.amount, a.currency)}</div>
                        })}</td>
                    </tr>
                    </tbody>
                </table>}
        </>
    }
}

AccountingTable.propTypes = {
    i18n: PropTypes.any,
    onProviderClicked: PropTypes.func,
    staffList: PropTypes.array,
    selectedProviderId: PropTypes.string,
    loading: PropTypes.bool,
    revenue: PropTypes.object
}

function LineItem({item, dateTimeFormat, lang}) {
    const isCanceled = appointmentUtils.isAppointmentCanceled(item.appointment_status);
    const classes = item.date_paid && !isCanceled ? "" : "text-danger";

    let dateText;
    if (item.date_paid) {
        const dt = moment(item.date_paid).format(dateTimeFormat);
        const canceledText = isCanceled ? ` (${$$("accounting_appointment_status_canceled")})` : "";
        dateText = dt + canceledText;
    } else {
        const dt = moment(item.date_invoice_created).format(dateTimeFormat);
        const canceledText = isCanceled ? `, ${$$("accounting_appointment_status_canceled")}` : "";
        dateText = dt + ' (' + $$("paid_outside_medrec") + canceledText + ')';
    }

    const appName = appointmentUtils.appointmentType({
        appointment_price: {
            name: item.appointment_name,
            encounter_type: item.appointment_type
        }
    });

    const text = `#${item.invoice_id} - ${dateText} - ${item.patient_name} - ${_$$("appointment_on", [appName])} ${moment(item.appointment_timestamp).format(dateTimeFormat)}`;

    return <span className={classes}>
        <span className="pl-5">{text}</span>
    </span>

}

class AccountingFilter extends Component {

    onDateChange = (e) => {
        this.props.onMonthChange(e);
    }

    onChangeBookedByDate = () => {
        this.props.onChangeBookedByDate()
    }

    getLocale = () => {
        switch (this.props.i18n.lang) {
            case "en":
                return enGB
            case "bg":
                return bg
            case "sq":
                return sq
            default:
                return enGB
        }
    }

    render() {
        return <div>
            <div className="d-flex align-items-baseline">
                <MuiPickersUtilsProvider locale={this.getLocale()} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        variant="inline"
                        format="yyyy/MM"
                        margin="normal"
                        label={$$("month")}
                        value={this.props.month}
                        KeyboardButtonProps={{'aria-label': 'change date'}}
                        onChange={this.onDateChange}
                        views={["year", "month"]}
                        openTo={"month"}
                        className="w-auto"
                        strictCompareDates={false}
                    />
                </MuiPickersUtilsProvider>
                <div className="d-inline-flex ml-3">
                    <Form>
                        <div className="ml-3">
                            <Form.Check inline label={$$("date_of_payment")} custom name="bookedByDate"
                                        checked={this.props.bookedByDate === false} onChange={this.onChangeBookedByDate}
                                        value="false" type="radio" id={`inline-radio-1`}/>
                            <Form.Check inline className={"ml-2"} label={$$("date_of_appointment")} custom
                                        name="bookedByDate" checked={this.props.bookedByDate === true}
                                        onChange={this.onChangeBookedByDate} value="true" type="radio"
                                        id={`inline-radio-2`}/>
                            <Form.Check inline className={"ml-2"} label={$$("paid_online_only")} custom
                                        name="paidInMedrecOnly" checked={this.props.paidInMedrecOnly === true}
                                        onChange={this.props.onChangePayedInMedrecOnly} value={this.props.paidInMedrecOnly} type="checkbox"
                                        id={`inline-check-2`}/>
                        </div>
                    </Form>
                </div>

            </div>
        </div>
    }
}

AccountingFilter.propTypes = {
    i18n: PropTypes.any,
    month: PropTypes.any,
    bookedByDate: PropTypes.any,
    onChangeBookedByDate: PropTypes.func,
    onMonthChange: PropTypes.func
}