import React, {Component, useCallback, useEffect, useMemo, useState} from 'react';
import {connect} from "react-redux";
import {Calendar, Views, momentLocalizer, dateFnsLocalizer} from 'react-big-calendar'
import moment from 'moment'

import DateFnsUtils from "@date-io/date-fns";
import endOfDay from 'date-fns/endOfDay'
import startOfDay from 'date-fns/startOfDay'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import isBefore from 'date-fns/isBefore'
import isAfter from 'date-fns/isAfter'
import isEqualDate from 'date-fns/isEqual'
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping'
import {Calendar as MUICalendar, DatePicker, DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {$$, getLanguage} from "../../helpers/localization";
import enGB from "date-fns/locale/en-GB";
import bg from "date-fns/locale/bg";
import sq from "date-fns/locale/sq";
import {GetStaffList, managementPostNewTimetable} from "../../actions/management_actions";
import {clinicService} from "../../service/clinic_service";
import {getClinicianNameWithTitle} from "../../utils/getClinicianNameWithTitle";
import Button from "react-bootstrap/Button";
import RoundButton from "../shared/RoundButton";
import * as classnames from "classnames";
import Modal from "react-bootstrap/Modal";
import {ReactComponent as CalendarIcon} from "../../../public/media/icons/calendar-1.svg";
import {formatUtils} from "../../utils/formatUtils";
import {OTHER} from "../../constants/appointment_types";
import UserImage from "../shared/UserImage";
import {paymentStatusUtils} from "../../utils/paymentStatusUtils";
import {
    clearPatients,
    createAppointmentWithExactEnd,
    loadPatients,
    updateAppointment,
    updateAppointmentStatus,
} from "../../actions/clinic_actions";
import no_appointments from "../../resources/images/no_appointments.png";
import Search from "../shared/Search";
import {infoUtils} from "../../utils/infoUtils";
import {appointmentUtils} from "../../utils/appointmentUtils";
import {managementService} from "../../service/management_service";
import PropTypes from "prop-types";
import PaginationComponent from "../common/Pagination";
import ReactHorizontalDatePicker from "../shared/ReactHorizontalDatePicker";
import {Select} from "../shared/Select";
import FreeSlotsList from "../staff/FreeSlotsList";
import {isSameDay} from "date-fns";
import Email from "../common/Email";
import Phone from "../common/Phone";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import {Form} from "react-bootstrap";

import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import makeStyles from "@material-ui/styles/makeStyles";
import {MuiThemeProvider, createMuiTheme} from "@material-ui/core";
import {managementColor, managementColorLight} from '../../_metronic/_assets/sass/global/layout/_config.scss'
import {Permissions} from "../../utils/permissions/types";

const locales = {
    "en": enGB,
    "bg": bg
}

//const localizer = momentLocalizer(moment);
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

function toBackgroundEvents(tt, id, startOfCurrentDay, endOfCurrentDay) {
    let end = tt.end;
    if (!tt.end ||
        (isBefore(tt.start, startOfCurrentDay) || isEqualDate(tt.start, startOfCurrentDay))
        && (isAfter(tt.end, endOfCurrentDay) || isEqualDate(tt.end, endOfCurrentDay))) {
        return {
            timetable: tt,
            resourceId: id,
            start: timeFromDateAndTime(startOfCurrentDay, tt.start_time),
            end: timeFromDateAndTime(startOfCurrentDay, tt.end_time),
            is_background: true
        };
    }
    return {
        timetable: tt,
        resourceId: id,
        start: timeFromDateAndTime(tt.start, tt.start_time),
        end: timeFromDateAndTime(end, tt.end_time),
        is_background: true
    };
}

class AppointmentsManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            events: [],
            backgroundEvents: [],
            selectedStaff: [],
            filteredClinicians: [],
        }
        moment.locale(this.props.i18n.lang);
    }

    setDate = (date) => {
        this.setState({
            date: date,
            events: [],
            backgroundEvents: []
        }, () => this.fetchAppointments(this.state.openMonthlySchedule ? startOfMonth(date) : startOfDay(date), this.state.openMonthlySchedule ? endOfMonth(date) : endOfDay(date)))
    }

    componentDidMount() {
        this.props.GetStaffList(this.props.organisationId).then(() => {
            let staff = [...this.props.staff].filter(u => {
                if (u.id === this.props.loggedInUserId) {
                    return true;
                }
                return this.props.permissions.canSeeOthersAppointments() || this.props.permissions.canSeeOthersWorktime()
            });
            let selectedStaff = staff.map(s => s.id);
            this.setState({selectedStaff:selectedStaff, filteredClinicians: staff});
            this.fetchAppointments(startOfDay(this.state.date), endOfDay(this.state.date))
        });
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.i18n !== prevProps.i18n) {
            moment.locale(this.props.i18n.lang);
        }
    }

    fetchAppointments = (start, end) => {
        let params = {
            org_id: this.props.organisationId,
            after: Date.parse(start),
            before: Date.parse(end)
        }
        let events = [];
        let backgroundEvents =  [];
        let promises = [];
        let date = this.state.date;
        for (const staff of this.props.staff) {
            if (this.state.openMonthlySchedule && this.state.openMonthlySchedule !== staff.id) {
                continue;
            }
            let isLoggedInUser = staff.id === this.props.loggedInUserId;
            let canSeeAppointments = isLoggedInUser ? this.props.permissions.canSeeOwnAppointments() : this.props.permissions.canSeeOthersAppointments();
            let canSeeWorkTime = isLoggedInUser ? this.props.permissions.canSeeOwnWorktime() : this.props.permissions.canSeeOthersWorktime();
            let res = clinicService.fetchAppointments(null, null, staff.id, params).then(res => {
                events = [...events, ...(res.filter(a => !a.status.startsWith('CANCELED') && !a.status.startsWith('REJECTED') && canSeeAppointments).map(a => {
                    return {
                        appointment_id: a.id,
                        title: !appointmentUtils.isGroupEventParent(a) ? a.participants.find(p => p.participant_role === 'PATIENT').fullname : getExaminationName(a.appointment_price),
                        start: new Date(a.starts_at),
                        end: new Date(a.ends_at),
                        resourceId: staff.id,
                        appointment: a
                    }
                }))];
                return {
                    then: function (f) {
                        return f()
                    }
                }
            }).then(() => {
                if (this.state.openMonthlySchedule) {
                    return;
                }
                return managementService.managementGetDateRanges(this.props.organisationId, staff.id).then(res => {
                    let start = startOfDay(this.state.date).getTime();
                    let end = endOfDay(this.state.date).getTime();
                    events = [...events, ...res.filter(t => t.not_available && t.start === start && t.end === end && canSeeWorkTime).map((tt) => {
                        return {
                            timetable: tt,
                            resourceId: staff.id,
                            start: timeFromDateAndTime(start, tt.start_time),
                            end: timeFromDateAndTime(end, tt.end_time)
                        }
                    })];
                    backgroundEvents = [...backgroundEvents,
                        ...res.filter(t => t.not_available && (t.start !== start || t.end !== end) && canSeeWorkTime).flatMap((tt) => {
                            return toBackgroundEvents(tt, staff.id, start, end);
                        })
                    ]
                })
            });
            promises.push(res);
        }

        Promise.all(promises).then(()=>{
            if (date !== this.state.date) {
                return;
            }
            this.setState({events:events, backgroundEvents:backgroundEvents});
        });
    }

    fetchTimeOff = (id) => {
        return managementService.managementGetDateRanges(this.props.organisationId, id).then(res => {
            let isLoggedInUser = id === this.props.loggedInUserId;
            let canSeeWorkTime = isLoggedInUser ? this.props.permissions.canSeeOwnWorktime() : this.props.permissions.canSeeOthersWorktime();
            let start = this.state.openMonthlySchedule ? startOfMonth(this.state.date).getTime() : startOfDay(this.state.date).getTime();
            let end = this.state.openMonthlySchedule ? endOfMonth(this.state.date).getTime() : endOfDay(this.state.date).getTime();
            this.setState({
                events: [...this.state.events.filter((e) => e.resourceId !== id || e.appointment),
                    ...res.filter(t => t.not_available && t.start === start && t.end === end && canSeeWorkTime).map((tt) => {
                        return {
                            timetable: tt,
                            resourceId: id,
                            start: timeFromDateAndTime(tt.start, tt.start_time),
                            end: timeFromDateAndTime(tt.start, tt.end_time)
                        }
                    })],
                backgroundEvents: [...this.state.backgroundEvents.filter((e) => e.resourceId !== id),
                    ...res.filter(t => t.not_available && t.start !== start && t.end !== end && canSeeWorkTime).flatMap((tt) => {
                        return toBackgroundEvents(tt, id, start, end)
                    })
                ]

            });
        })
    }

    getLocale = () => {
        switch (this.props.i18n.lang) {
            case "en":
                return enGB;
            case "bg":
                return bg;
            case "sq":
                return sq;
            default:
                return enGB;
        }
    }

    openMonthlySchedule = (id) => {
        let state = {openMonthlySchedule:id, events:[], backgroundEvents:[]};
        if (!id) {
            state.date = new Date();
        } else if (id !== state.openMonthlySchedule) {
            state.date = startOfMonth(new Date());
        }
        this.setState(state, () => {
            this.fetchAppointments(startOfMonth(this.state.date), endOfMonth(this.state.date))
        });
    }

    render() {
        return (<div className="appointment-page">
            <div className="flex-container flex-row schedule-page-content">
                <div className="flex-grow-1 flex-shrink-1 min-width-0">
                    <Schedule localizer={localizer} date={this.state.date} staff={this.state.filteredClinicians}
                              events={this.state.events}
                              backgroundEvents={this.state.backgroundEvents}
                              culture={this.props.i18n.lang}
                              organisationName={this.props.organisationName}
                              organisationId={this.props.organisationId}
                              locale={this.getLocale()}
                              openMonthlySchedule={this.state.openMonthlySchedule}
                              permissions={this.props.permissions}
                              loggedInUserId={this.props.loggedInUserId}
                              updateAppointmentStatus={(id, status, note) => {
                                  return this.props.updateAppointmentStatus(id, status, note).then(() => {
                                          this.setState({
                                              events: [],
                                              backgroundEvents: []
                                          }, () => this.fetchAppointments(this.state.openMonthlySchedule ? startOfMonth(this.state.date) : startOfDay(this.state.date),
                                              this.state.openMonthlySchedule ? endOfMonth(this.state.date) : endOfDay(this.state.date)));
                                      }
                                  ).catch((e) => {
                                      alert("Server Error!")
                                      throw e;
                                  })
                              }}

                              onUpdate={(app, forceUpdateAppointment) => {
                                  return this.props.updateAppointment(app, forceUpdateAppointment).then(() => {
                                          this.setState({
                                              events: [],
                                              backgroundEvents: []
                                          }, () => this.fetchAppointments(this.state.openMonthlySchedule ? startOfMonth(this.state.date) : startOfDay(this.state.date),
                                              this.state.openMonthlySchedule ? endOfMonth(this.state.date) : endOfDay(this.state.date)));
                                      }
                                  )
                              }}

                              onCreateAppointment={(start, end, clinician, pat, exam, organisationId, max_patients, forceCreateAppointment) => {
                                  start = moment(start).valueOf();
                                  end = moment(end).valueOf();
                                  let pay_before_timestamp = appointmentUtils.calculatePayBeforeTimestamp(start, exam.price_cents);
                                  return this.props.createAppointmentWithExactEnd(clinician, pat, exam, start, end, null, organisationId, pay_before_timestamp, max_patients, forceCreateAppointment).then(() => {
                                          this.setState({
                                              events: [],
                                              backgroundEvents: []
                                          }, () => this.fetchAppointments(this.state.openMonthlySchedule ? startOfMonth(this.state.date) : startOfDay(this.state.date),
                                                this.state.openMonthlySchedule ? endOfMonth(this.state.date) : endOfDay(this.state.date)));
                                      }
                                  )
                              }}


                              saveTimeOff={(startTime, endTime, resourceId) => {
                                  let start = startOfDay(startTime).getTime();
                                  let end = endOfDay(endTime).getTime();

                                  if (this.state.openMonthlySchedule) {
                                      resourceId = this.state.openMonthlySchedule;
                                  }

                                  const {
                                      start_time,
                                      end_time
                                  } = getStartAndEndTime(startTime, endTime);

                                  let timetable = {
                                      "days_of_the_week": [],
                                      "end": end,
                                      "end_time": end_time,
                                      "not_available": true,
                                      "provider_id": resourceId,
                                      "organization_id": this.props.organisationId,
                                      "location_type": null,
                                      "start": start,
                                      "start_time": start_time
                                  };
                                  let id = resourceId;
                                  return this.props.managementPostNewTimetable(timetable).then(() => {
                                          this.setState({events: [...this.state.events]}, () => this.fetchTimeOff(id))
                                      }
                                  ).catch(() => {
                                      alert("Server Error!")
                                  })
                              }}

                              onDeleteTimetable={(timetable) => {
                                  return managementService.managementDeleteDateRange(timetable.id).then(() => {
                                          this.setState({events: [...this.state.events.filter(f => f.appointment || f.timetable.id !== timetable.id)]}, () => this.fetchTimeOff(timetable.provider_id))
                                      }
                                  ).catch(() => {
                                      alert("Server Error!")
                                  })
                              }}

                              setDateAndOpenView={(date, clinicianId)=>{
                                  let state = {
                                      openMonthlySchedule:undefined,
                                      events:[],
                                      backgroundEvents:[],
                                      date:date,
                                      selectedStaff: [clinicianId],
                                      filteredClinicians: this.props.staff.filter(c => clinicianId === c.id)
                                  };

                                  this.setState(state, () => {
                                      this.fetchAppointments(startOfDay(this.state.date), endOfDay(this.state.date))
                                  });
                              }}
                    />
                </div>
                <div className="d-flex flex-column">
                    {!this.state.openMonthlySchedule && <DatePickComponent
                        date={this.state.date}
                        setDate={this.setDate}
                        locale={this.getLocale()}
                    />}
                    {this.state.openMonthlySchedule && <MonthPickComponent
                        date={this.state.date}
                        setDate={this.setDate}
                        locale={this.getLocale()}
                    />}
                    <div className="mt-2"></div>
                    <h4 className="pl-4 ml-1">{$$("clinicians_label")}</h4>
                    <ClinicianList clinicians={this.props.staff}
                                   selected={this.state.selectedStaff}
                                   onChange={(id)=>{
                                                let s = [...this.state.selectedStaff];
                                                if (s.indexOf(id) !== -1) {
                                                    s.splice(s.indexOf(id), 1)
                                                } else {
                                                    s.push(id);
                                                }
                                                this.setState({selectedStaff: s, filteredClinicians: this.props.staff.filter(c => s.indexOf(c.id) !== -1)});
                                            }}
                                   selectedForMonthlyOpen={this.state.openMonthlySchedule}
                                   openMonthlySchedule={this.openMonthlySchedule}
                    />
                </div>
            </div>
        </div>)
    }
}

const mapStateToProps = (state) => {
    return {
        i18n: state.language.selected,
        organisationId: state.management.selectedOrganisation.id,
        organisationName: state.management.selectedOrganisation.name,
        staff: state.management.staff,
        permissions: Permissions.fromOrg(state.userInfo.data.organizations.find(o => o.id === state.management.selectedOrganisation.id)),
        loggedInUserId: state.userInfo.data.id
    }
}

export default connect(mapStateToProps, {
    GetStaffList,
    updateAppointmentStatus,
    updateAppointment,
    managementPostNewTimetable,
    createAppointmentWithExactEnd
})(AppointmentsManagement);

AppointmentsManagement.propTypes = {
    i18n: PropTypes.any,
    organisationName: PropTypes.any,
    staff: PropTypes.any,
    organisationId: PropTypes.any,
    managementPostNewTimetable: PropTypes.func,
    updateAppointmentStatus: PropTypes.func,
    GetStaffList: PropTypes.func,
    updateAppointment: PropTypes.func,
    createAppointmentWithExactEnd: PropTypes.func,
    loggedInUserId: PropTypes.string,
    permissions: PropTypes.object
}


function ClinicianList({clinicians, selected, onChange, openMonthlySchedule, selectedForMonthlyOpen}) {
    return <ListGroup variant={"flush"} className="ml-2 overflow-auto" >
        {clinicians.map(c => {
            return <ListGroupItem key={c.id} className={"schedule-clinician-list"}>
                <div className="d-flex justify-content-between align-items-center">
                    {!selectedForMonthlyOpen &&
                    <Form.Check className="test" label={getClinicianNameWithTitle(c)} type="checkbox"
                                id={`check-${c.id}`}
                                value={c.id}
                                onChange={() => onChange(c.id)}
                                checked={selected.indexOf(c.id) !== -1}/>
                    }
                    {selectedForMonthlyOpen && <span>{getClinicianNameWithTitle(c)}</span>}
                    <button className={classnames("btn btn-sm btn-outline-primary ml-3", {"active":selectedForMonthlyOpen && c.id===selectedForMonthlyOpen})}  onClick={()=> {openMonthlySchedule(selectedForMonthlyOpen !== c.id ? c.id: undefined)}}>
                        <span className="flaticon-calendar-3 bold" style={{fontSize:"1rem"}} />
                    </button>
                </div>
            </ListGroupItem>
        })}
    </ListGroup>
}

/* eslint-disable react/prop-types */
function DatePickComponent({date, setDate, locale}) {
    return <div className="overflow-hidden">
        <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
            <MUICalendar
                date={date}
                onChange={setDate}
            />
        </MuiPickersUtilsProvider>
    </div>
}

const useStyles = makeStyles({
    root: {
        "& .MuiPaper-root": {
            border: "1px solid red"
        }
    }
});

const theme = createMuiTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: managementColor,
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                height : "6rem"
            }
        },
        MuiPickersMonth: {
            root: {height: '40px'}
        },
        MuiPickersBasePicker: {
            pickerView: {maxHeight: '280px', minHeight:"auto", paddingTop:"0.5rem", paddingBottom:"0.5rem"}
        }
    }
})

/* eslint-disable react/prop-types */
function MonthPickComponent({date, setDate, locale}) {
    const classes = useStyles();
    return <div className="m-3">
        <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
                <DatePicker
                    variant="static"
                    inputVariant="outlined"
                    value={date}
                    onChange={setDate}
                    views={["year", "month"]}
                    openTo="month"
                />
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    </div>
}

/* eslint-enable react/prop-types */

/* eslint-disable react/prop-types */
export function DateTimePickComponent({date, setDate, locale, open, onClose}) {
    return <div className="overflow-hidden">
        <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
            <DateTimePicker
                value={date}
                onChange={setDate}
                ampm={false}
                okLabel={$$("OK")}
                cancelLabel={$$("cancel_btn")}
                open={open}
                onClose={onClose}
            />
        </MuiPickersUtilsProvider>
    </div>
}

/* eslint-enable react/prop-types */

const ZoomLevels = {
    1: {
        step: 10,
        timeslots: 6
    },
    2: {
        step: 30,
        timeslots: 1
    },
    3: {
        step: 5,
        timeslots: 3
    }
}

/* eslint-disable react/prop-types */
function ZoomToolbar({setStep, setTimeslots}) {
    const [zoomLevel, setZoomLevel] = useState(2)
    // eslint-disable-next-line no-unused-vars
    const handleZoomLevelChangeIncrease = (e) => {
        if (zoomLevel < 3) {
            setZoomLevel(zoomLevel + 1),
                setStep(ZoomLevels[zoomLevel + 1].step)
            setTimeslots(ZoomLevels[zoomLevel + 1].timeslots)
        }
    };
    // eslint-disable-next-line no-unused-vars
    const handleZoomLevelChangeDecrease = (e) => {
        if (zoomLevel > 1) {
            setZoomLevel(zoomLevel - 1)
            setStep(ZoomLevels[zoomLevel - 1].step)
            setTimeslots(ZoomLevels[zoomLevel - 1].timeslots)
        }
    };

    return (
        <div style={{transform: "scale(0.8)"}}>
            <RoundButton disabled={zoomLevel === 3} title={$$("zoom_in_schedule")} btn_classes="btn-outline-primary"
                         icon_classes={"fas fa-plus"} onClick={handleZoomLevelChangeIncrease}>+</RoundButton>
            <RoundButton disabled={zoomLevel === 1} title={$$("zoom_out_schedule")}
                         btn_classes="btn-outline-primary ml-2"
                         icon_classes={"fas fa-minus"} onClick={handleZoomLevelChangeDecrease}>-</RoundButton>
        </div>
    )
}

/* eslint-enable react/prop-types */

const MonthEvent = ({ event }) => {
    if (event.timetable) {
        return <div>{`${moment(event.start).format("HH:mm")} - ${moment(event.end).format("HH:mm")}`}</div>
    }
    return <div>{`${moment(event.start).format("HH:mm")}${event.title ? ' - ' + event.title.split(" ")[0] : ""}`}</div>

};

/* eslint-disable react/prop-types */
function Schedule({
                      localizer,
                      date,
                      staff,
                      events,
                      culture,
                      organisationName,
                      locale,
                      updateAppointmentStatus,
                      onUpdate,
                      patient,
                      saveTimeOff,
                      organisationId,
                      onCreateAppointment,
                      onDeleteTimetable,
                      backgroundEvents,
                      openMonthlySchedule,
                      setDateAndOpenView,
                      permissions,
                      loggedInUserId
                  }) {
    function format(date) {
        return moment(date).format(openMonthlySchedule ? "MMMM YYYY" : "LL");
    }

    const [formattedDate, setFormattedDate] = useState(format(date))
    const [showMorePopupOpen, setShowMorePopupOpen] = useState(false)
    const [step, setStep] = useState(ZoomLevels["2"].step)
    const [timeslots, setTimeslots] = useState(ZoomLevels["2"].timeslots)
    const [selectedEvent, setSelectedEvent] = useState();
    const [selectedSlots, setSelectedSlots] = useState();

    useEffect(() => {
        setFormattedDate(format(date));
    }, [date, openMonthlySchedule])

    const {views} = useMemo(
        () => ({
            views: ['day', 'month'],
        }),
        []
    )

    function staffName(userInfo) {
        return getClinicianNameWithTitle(userInfo)
    }

    const onCancelAppointment = (appointment, note) => {
        updateAppointmentStatus(appointment.id, 'CANCELED_BY_PROVIDER', note)
            .then(() => {
                setSelectedEvent(null)
            })
    }

    const onSave = (appointment, forceUpdateAppointment) => {
        return onUpdate(appointment, forceUpdateAppointment).then(() => {
            setSelectedEvent(null)
        })
    }

    const handleDeleteTimetable = (timetable) => {
        onDeleteTimetable(timetable).then(() => {
            setSelectedEvent(null)
        })
    }

    const onClose = () => {
        setSelectedEvent(null)
    }

    /* eslint-disable no-unused-vars */
    const eventPropGetter = useCallback(
        (event, start, end, isSelected) => ({
            ...(event.timetable && {
                className: 'schedule-timeoff-event'
            }), ...(event.appointment && event.appointment.status === 'COMPLETED' && { className: "schedule-event-completed"})
        }),
        []
    )
    /* eslint-enable no-unused-vars */

    const intersects = useCallback((start, end, start2, end2) => {
        return areIntervalsOverlapping({start: start, end: end}, {start: start2, end: end2})
    }, [])

    const isTimeslotAllowed = useCallback((timeSlot) => {
        for (const event of events) {
            if (event.timetable && event.resourceId === timeSlot.resourceId) {
                if (intersects(event.start, event.end, timeSlot.start, timeSlot.end)) {
                    return false;
                }
            }
        }
        return true;
    }, [events])

    const minTime = new Date();
    minTime.setHours(7, 0, 0);
    const maxTime = new Date();
    maxTime.setHours(22, 0, 0);

    if (staff.length === 0 && !openMonthlySchedule) {
        let noStaff = {
            imgClass: 'no-lab-results-img mw-100',
            primaryLabelClass: 'no-lab-results-primary-label',
            secondaryLabelClass: '',
            src: no_appointments,
            primaryLabel: $$('select_clinicians_to_see_schedule'),
            secondaryLabel: ''
        }
        return <div>{infoUtils.noData(noStaff)}</div>
    }

    /* eslint-disable no-unused-vars */
    return (
        <div className={classnames("schedule-calendar-wrapper", {"h-100": openMonthlySchedule })}>
            <div className="calendar-title-date d-flex space-between-justify">
                <span>{formattedDate}</span>
                {!openMonthlySchedule && <ZoomToolbar setStep={setStep} setTimeslots={setTimeslots}/>}
            </div>
            <Calendar
                date={date}
                onNavigate={(f) => {
                }}
                min={minTime}
                max={maxTime}
                defaultView={openMonthlySchedule ? Views.MONTH : Views.DAY}
                events={events}
                backgroundEvents={backgroundEvents}
                localizer={localizer}
                culture={culture}
                resourceIdAccessor="id"
                resources={staff}
                resourceTitleAccessor={staffName}
                step={step}
                timeslots={timeslots}
                views={views}
                toolbar={false}
                selectable
                components={{
                    month: { event: MonthEvent }
                }}
                eventPropGetter={eventPropGetter}
                onSelectEvent={(e) => {
                    if (!e.is_background) {

                        if (e.resourceId === loggedInUserId) {
                            if (e.timetable) {
                                if (!permissions.canUpdateOwnWorktime()) {
                                    return;
                                }
                            } else {
                                if (!permissions.canEditAppointmentOwn()) {
                                    return;
                                }
                            }
                        } else {
                            if (e.timetable) {
                                if (!permissions.canUpdateOthersWorktime()) {
                                    return;
                                }
                            } else {
                                if (!permissions.canEditAppointmentOthers()) {
                                    return;
                                }
                            }
                        }

                        setSelectedEvent(e);
                    }
                }}
                onShowMore={()=>{
                    setShowMorePopupOpen(!showMorePopupOpen)}
                }
                selected={selectedEvent}
                onSelectSlot={(e) => {

                    if (isTimeslotAllowed(e)) {
                        if (!isSameDay(e.start, e.end)) {
                            e.end = endOfDay(e.start);
                        }
                        if (openMonthlySchedule) {
                            if (!showMorePopupOpen) {
                                setDateAndOpenView(startOfDay(e.start), openMonthlySchedule);
                            } else {
                                setShowMorePopupOpen(false)
                            }
                            return;
                        }
                        setSelectedSlots(e)
                    }
                }}
                view = {openMonthlySchedule ? Views.MONTH : Views.DAY}
                onView={(view)=>{}}
                /*dayLayoutAlgorithm='no-overlap'*/
                showMultiDayTimes={true}
                popup={true}
                messages={{showMore:total => `+${total} ${$$('more')?.toLowerCase()}`}}
            />
            {selectedEvent && selectedEvent.appointment &&
                <EditAppointmentModal appointment={selectedEvent.appointment} onCancel={onCancelAppointment}
                                      onClose={onClose} onSave={onSave} organisationName={organisationName}
                                      staff={staff} locale={locale}
                                      permissions={permissions}
                                      loggedInUserId={loggedInUserId}
                />}

            {selectedEvent && selectedEvent.timetable &&
                <DeleteTimetableSlot timetable={selectedEvent.timetable} start={selectedEvent.start}
                                     end={selectedEvent.end} onDelete={handleDeleteTimetable} onCancel={onClose}/>
            }

            {selectedSlots &&
                <div>
                    <SlotsSelected providerId={selectedSlots.resourceId} start={selectedSlots.start}
                                   end={selectedSlots.end}
                                   patient={patient} onCancel={() => setSelectedSlots(null)}
                                   staff={staff}
                                   saveTimeOff={(start,end) => saveTimeOff(start,end, selectedSlots.resourceId)}
                                   organisationName={organisationName}
                                   organisationId={organisationId}
                                   onCreateAppointment={onCreateAppointment}
                                   locale={locale}
                                   permissions={permissions}
                                   loggedInUserId={loggedInUserId}
                    />
                </div>
            }
        </div>
    )
}

/* eslint-enable no-unused-vars */
/* eslint-enable react/prop-types */


/* eslint-disable react/prop-types */
function DeleteTimetableSlot({start, end, timetable, onDelete, onCancel}) {
    let isThisYear = moment(start).format("YYYY") === moment().format("YYYY");
    return (
        <Modal show={true} onHide={onCancel} dialogClassName="max-width-800px">
            <Modal.Body>
                <div className="p-3 high-shadow-container ">
                    <div className="d-flex w-100 justify-content-around">
                        <div className="d-flex">
                            <div className="early-appointment-icon">
                                <CalendarIcon width="23px" height="28px" className="svg-icon"/>
                            </div>
                            <div className="early-appoitment-time align-self-center">
                                <span className="medrec-grey-2">{$$("start")}</span>
                                <div
                                    className="first-time-available">{start ? (isThisYear ? moment(start).format("D MMM, LT") : moment(start).format("D MMM, YYYYY LT")) : ""}</div>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="early-appointment-icon">
                                <CalendarIcon width="23px" height="28px" className="svg-icon"/>
                            </div>
                            <div className="early-appoitment-time align-self-center">
                                <span className="medrec-grey-2">{$$("end")}</span>
                                <div
                                    className="first-time-available">{end ? (isThisYear ? moment(end).format("D MMM, LT") : moment(end).format("D MMM, YYYYY LT")) : ""}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <h5 className="mt-3 text-center">
                    {$$("delete_time_off_description")}
                </h5>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"secondary"} onClick={onCancel}>{$$("close_btn_label")}</Button>
                <Button variant={"danger"} onClick={() => onDelete(timetable)}>{$$("delete_label")}</Button>
            </Modal.Footer>
        </Modal>
    )
}

/* eslint-enable react/prop-types */

/* eslint-disable react/prop-types */
function SlotsSelected({
                           providerId,
                           start,
                           end,
                           onCancel,
                           saveTimeOff,
                           staff,
                           patient,
                           organisationId,
                           organisationName,
                           onCreateAppointment,
                           locale,
                           permissions,
                           loggedInUserId
                       }) {
    const [create, setCreate] = useState(false);
    const [timeOff, setTimeOff] = useState(false);
    const [pat, setPat] = useState(patient);
    const [exam, setExam] = useState();
    const [max_patients, setMaxPatients] = useState(5);
    const [startTime, setStartTime] = useState(start);
    const [endTime, setEndTime] = useState(end);
    const [forceCreateAppointment, setForceCreateAppointment] = useState(false);
    const [error, setError] = useState(false);

    let clinician;
    for (let i = 0; i < staff.length; i++) {
        let c = staff[i];
        if (providerId === c.id) {
            clinician = c;
            break;
        }
    }
    const onSaveTimeOff = () => {
        saveTimeOff(startTime, endTime).then(() => {
            onCancel();
        })
    }

    const handleCreateAppointment = () => {
        onCreateAppointment(startTime, endTime, clinician, pat, exam, organisationId, max_patients, forceCreateAppointment).then(() => {
            onCancel();
        }).catch(e => {
            setError(e);
        })
    }

    const setExamination = (exam) => {
        setExam(exam);
        setEndTime(moment(moment(startTime).valueOf() + exam.duration_mins * 60 * 1000))
    }

    const endBeforeStart = useMemo(() => {return !moment(startTime).isBefore(moment(endTime))}, [startTime, endTime]);
    const timeClasses = useMemo(() => {return classnames("d-flex w-100 justify-content-around", {"text-danger": endBeforeStart})},
        [startTime, endTime]);

    const isOwn = providerId === loggedInUserId;
    const canCreateAppointment = isOwn ? permissions.canCreateOwnAppointment() : permissions.canCreateOthersAppointment();
    const canCreateWorktime = isOwn ? permissions.canCreateOwnWorktime() : permissions.canCreateOthersWorktime();

    return <Modal show={true} onHide={onCancel} dialogClassName="max-width-800px">
        <Modal.Body>
            <div>
                <div className="p-3 high-shadow-container">
                    <div className={timeClasses}>
                        <DateTimeComponentEditable locale={locale} labelKey={"start"} time={startTime} setTime={(t) => {
                            let timeInMs = moment(t).valueOf();
                            setStartTime(timeInMs);
                            if (exam) {
                                setEndTime(moment(timeInMs + exam.duration_mins * 60 * 1000))
                            }}} />
                        <DateTimeComponentEditable locale={locale} labelKey={"end"} time={endTime} setTime={(t) => {
                                let timeInMs = moment(t).valueOf();
                                setEndTime(timeInMs);
                            }} />
                    </div>
                </div>
                {endBeforeStart && <div className="high-shadow-container mt-3 text-danger background-red">{$$("end_before_start_warning")}</div>}
                <div className="p-3">
                    {create && <div>
                        <div>
                            <h5>{$$("clinician_label")}</h5>
                            <UserInfoRow user={clinician}/>
                        </div>
                        {!pat && exam && appointmentUtils.isPriceForRegularAppointment(exam) &&
                            <div className="mt-2"><SelectPatientComponent onSelect={setPat}/></div>}
                        {pat && exam && <div>
                            <h5>{$$("patient_label")}</h5>
                            <UserInfoRow user={pat} isPatient={true}/>
                        </div>
                        }
                        {!exam &&
                            <Examinations clinician={clinician} onSelect={setExamination} organisationId={organisationId}
                                          organisationName={organisationName}/>}
                        {exam &&
                            <div className="mt-2"><ChosenExamination organisationName={organisationName} exam={exam}/>
                            </div>}
                        {exam && appointmentUtils.isPriceForGroupEvent(exam) && <div>
                            <label>{$$("max_number_participants_label")}</label>
                            <input type={"text"} className={"form-control"} value={max_patients}
                                   onChange={event => setMaxPatients(event.target.value.replace(/\D/, ''))}/>
                        </div>
                        }
                        {exam && (pat || appointmentUtils.isPriceForGroupEvent(exam)) && <div className="mt-3">
                            <Form.Check
                                className={'custom-control-inline flex-grow-1 flex-shrink-1'}
                                id={'force-create-appointment-check'}
                                checked={forceCreateAppointment}
                                value={forceCreateAppointment}
                                type={"checkbox"}
                                custom
                                label={$$("force_create_appointment")}
                                onChange={() => {
                                    setForceCreateAppointment(!forceCreateAppointment);
                                }}/>
                        </div>}
                        {error && <div className="high-shadow-container mt-3 text-danger background-red">
                                <p>
                                    <strong>{$$("appointment_creation_failed")} {error.message === 'Starting time is unavailable' ? $$("appointment_starting_time_unavailable") : ''}</strong>
                                </p>
                            </div>
                        }
                    </div>}
                    {timeOff && <h5 className="mt-3 text-center">{$$("create_new_busy_slot_description")}</h5>}
                    {!create && !timeOff && <div>
                        <h5 className="p-3">{$$("choose_action_timeslot_or_calendar")}</h5>
                        <div className="d-flex justify-content-around p-3">
                            <Button disabled={!canCreateWorktime} onClick={() => {
                                setTimeOff(true)
                            }}>{$$("mark_time_off")}</Button>
                            <Button disabled={!canCreateAppointment} onClick={() => {
                                setCreate(true)
                            }}>{$$("create_appointment")}</Button>
                        </div>
                    </div>}
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            {(create || timeOff) && <Button variant={"secondary"} onClick={() => {
                setCreate(false);
                setTimeOff(false);
                setPat(patient);
                setExam(null);
                setError(null);
            }}>{$$("back")}</Button>}
            <Button variant={"secondary"} onClick={onCancel}>{$$("close_btn_label")}</Button>
            {timeOff && <Button variant={"primary"} disabled={endBeforeStart} onClick={onSaveTimeOff}>{$$("save_btn_label")}</Button>}
            {(exam && create && (pat || appointmentUtils.isPriceForGroupEvent(exam))) &&
                <Button variant={"primary"} disabled={endBeforeStart} onClick={handleCreateAppointment}>{$$("save_btn_label")}</Button>}
        </Modal.Footer>
    </Modal>
}

/* eslint-enable react/prop-types */


/* eslint-disable react/prop-types */
function ChosenExamination({exam, organisationName}) {
    return <div className="d-flex high-shadow-container">
        <div className="d-flex w-100 pt-3 justify-content-between">
            <div className="">
                <div
                    className="">{getExaminationName(exam)}
                </div>
            </div>
            <div className="capitalized ml-2">
                {$$(exam.location_type.toLowerCase()) + ", " + organisationName}
            </div>
            <div className="ml-2 align-self-center text-right flex-shrink-0" style={{
                display: "flex",
                alignSelf: "center"
            }}>
                <div>{price(exam)}</div>
            </div>
        </div>
    </div>
}

/* eslint-enable react/prop-types */

/* eslint-disable react/prop-types */
export function EditAppointmentModal({appointment, onCancel, onSave, onClose, organisationName, staff, locale, permissions, loggedInUserId}) {
    const [start, setStart] = useState(appointment.starts_at)
    const [end, setEnd] = useState(appointment.ends_at)
    const [status, setStatus] = useState(appointment.status)
    const [max_patients, setMaxPatients] = useState(appointment.max_patients)
    const [showSaveConfirmModal, setShowSaveConfirmModal] = useState(false);
    const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);
    const [forceUpdateAppointment, setForceUpdateAppointment] = useState(false);
    const [error, setError] = useState(null);
    const [note, setNote] = useState("")

    const save = () => {
        // eslint-disable-next-line no-undef
        let app = _.cloneDeep(appointment);
        app.starts_at = moment(start).valueOf();
        app.ends_at = moment(end).valueOf();
        app.max_patients = max_patients;
        onSave(app, forceUpdateAppointment).catch(e => {
            setError(e);
        });
    }

    const showSave = () => {
        setShowSaveConfirmModal(true)
    }

    const updateStartEnd = useCallback((start, end) => {
        setStart(start);
        setEnd(end);
    }, [setStart, setEnd]);

    useEffect(()=>{setNote("")}, [showCancelConfirmModal]);

    let clinician = appointment.participants.find(p => p.participant_role === 'PROVIDER');
    let patient = appointment.participants.find(p => p.participant_role === 'PATIENT');
    if (staff) {
        for (let i = 0; i < staff.length; i++) {
            let c = staff[i];
            if (clinician.user_id === c.id) {
                clinician = c;
                break;
            }
        }
    }

    const endBeforeStart = useMemo(() => {return !moment(start).isBefore(moment(end))}, [start, end]);

    const isOwn = appointmentUtils.getProviderId(appointment) === loggedInUserId;
    const canCreateAppointmentNote = isOwn ? permissions.canCreateAppointmentNoteOwn() : permissions.canCreateAppointmentNoteOther();

    const confirmModal = showSaveConfirmModal || showCancelConfirmModal;
    return (
        <Modal show={true} onHide={onClose} dialogClassName="max-width-800px">
            <Modal.Body>
                <div>
                    <div><h5>{$$("clinician_label")}</h5>
                        <UserInfoRow user={clinician}/>
                    </div>

                    {patient && <div className="mt-3">
                        <h5>{$$("patient_label")}</h5>
                        <UserInfoRow user={patient} isPatient={true}/>
                    </div>}

                    <div className="mt-3">
                        <h5>{$$("appointment_label")}</h5>
                        {ChosenExaminationWithTime(start, end, appointment.payment_status, appointment.override_location_to_online, appointment.appointment_price, organisationName, status, setStart, setEnd, setStatus, locale, confirmModal)}
                    </div>

                    {!confirmModal && <div className="mt-3">
                        <div className="medrec-default-text-color high-shadow-container">
                            <div className="pt-3">
                                <PickerComponent onComplete={showSave} price={appointment.appointment_price}
                                                 updateStartEnd={updateStartEnd} selectedStart={start}
                                                 originalStart={appointment.starts_at}/>
                            </div>
                        </div>
                    </div>
                    }

                    {endBeforeStart && <div className="high-shadow-container mt-3 text-danger background-red">{$$("end_before_start_warning")}</div>}
                    {
                        appointmentUtils.isGroupEventParent(appointment) && <div>
                            <label>{$$("max_number_participants_label")} ({$$("occupied_seats")} : {appointment.used_slots})</label>
                            <input type={"text"} className={"form-control"} value={max_patients}
                                   onChange={event => setMaxPatients(event.target.value.replace(/\D/, ''))}/>
                        </div>
                    }
                </div>
                {showSaveConfirmModal && <div className="mt-3">
                    <Form.Check
                        className={'custom-control-inline flex-grow-1 flex-shrink-1'}
                        id={'force-create-appointment-check'}
                        checked={forceUpdateAppointment}
                        value={forceUpdateAppointment}
                        type={"checkbox"}
                        custom
                        label={$$("force_create_appointment")}
                        onChange={() => {
                            setForceUpdateAppointment(!forceUpdateAppointment);
                        }}/>
                </div>}
                {showSaveConfirmModal &&
                    <div className="high-shadow-container mt-3 text-danger background-red">
                        <p>{$$("confirm_update_appointment_body")}</p>
                        {error && <p><strong>{$$("appointment_update_failed")} {error.message === 'Starting time is unavailable' ? $$("appointment_starting_time_unavailable") : ''}</strong></p>}
                    </div>
                }
                {showCancelConfirmModal &&
                    <div className="high-shadow-container mt-3 text-danger background-red">
                        <p>{$$('cancel_appointment_confirmation_header')
                            .replace('{fullname}', patient.fullname)
                            .replace('{date}', moment(appointment.starts_at).format('D MMMM HH:mm'))}</p>
                        <p>{$$("cancel_appointment_confirmation_body")}</p>
                        {canCreateAppointmentNote && <div className={"pt-2"}>
                            <textarea className="form-control" rows="4"
                                      value={note}
                                      placeholder={$$('note_label')}
                                      name="note"
                                      onChange={(e)=>{setNote(e.target.value)}}
                            />
                        </div>}
                    </div>
                }
            </Modal.Body>
            {showSaveConfirmModal && <Modal.Footer>
                <Button variant={"secondary"} onClick={()=>{
                    setStart(appointment.starts_at);
                    setEnd(appointment.ends_at);
                    setShowSaveConfirmModal(false);
                    setError(false);
                }}>{$$("cancel_btn")}</Button>
                <Button disabled={!moment(start).isBefore(moment(end))} onClick={save}>{$$("save_btn_label")}</Button>
            </Modal.Footer>}
            {showCancelConfirmModal && <Modal.Footer>
                <Button variant={"secondary"} onClick={()=>{
                    setShowCancelConfirmModal(false);
                }}>{$$("cancel_btn")}</Button>
                <Button onClick={() => {
                    let noteObj = null;
                    if (note && note.trim() !== "") {
                        noteObj = {
                            value:note.trim(),
                            visibility:"ALL"
                        };
                    }
                    onCancel(appointment, noteObj)
                }}>{$$("cancel_appointment_label")}</Button>
            </Modal.Footer>}
            {!confirmModal && <Modal.Footer>
                {onCancel && appointmentUtils.canCancel(appointment) && <Button variant={"danger"}
                        onClick={() => setShowCancelConfirmModal(true)}>{$$("cancel_appointment_label")}</Button> }
                <Button variant={"secondary"} onClick={onClose}>{$$("close_btn_label")}</Button>
                <Button disabled={!moment(start).isBefore(moment(end))} onClick={showSave}>{$$("save_btn_label")}</Button>
            </Modal.Footer>}
        </Modal>
    );
}

function PickerComponent({price, updateStartEnd, selectedStart, originalStart, onComplete}) {
    const [chosenDate, setChosenDate] = useState(new Date())
    const [triggeredFromBtn, setTriggeredFromBtn] = useState(false)
    const [timetableData, setTimetableData] = useState()
    const [firstAvailableHour, setFirstAvailableHour] = useState()
    const [firstAvailableHourDisplay, setFirstAvailableHourDisplay] = useState("")
    const [todaySlots, setTodaySlots] = useState([])
    const [availableDaysOptions, setAvailableDaysOptions] = useState([])

    const createAvailableOptions = useCallback((timetableAvailableOnly) => {
        return [ {value:moment().format("YYYY-MM-DD"), text:$$("jump_to_date")},
            ...timetableAvailableOnly.map(day => {
                let momentDate = moment(day.date);
                let isSameYear = momentDate.format('YYYY') === moment().format("YYYY");
                let d = isSameYear ? momentDate.format("D MMM") : momentDate.format("D MMM YYYY");
                return {value:day.date, text: `${d} (${day.count})`}
            })];
    }, [])

    const filterSlots = useCallback(() => {
        let dateToFilter = chosenDate;
        let todaySlots = null;
        if (timetableData != null && timetableData.data != null) {
            for (let i = 0; i < timetableData.data.length; i++) {
                if (isSameDay(moment(timetableData.data[i].date).toDate(), dateToFilter)) {
                    todaySlots = timetableData.data[i].slots;
                    break;
                }
            }
        }
        return todaySlots;
    }, [timetableData, chosenDate]);

    useEffect(() => {
        let startTimestamp = moment().add(15, 'minutes').valueOf();
        let endTimestamp = appointmentUtils.calculateMaxFetchAppointmentsTimestamp();
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        clinicService.fetchFirstHour(price.provider_id,
            startTimestamp,
            endTimestamp,
            timezone,
            price.location_type,
            price.organization_id,
            price.id).then(res => {
                if (res) {
                    setFirstAvailableHour(res);
                    let isSameYear = moment(res.start).format('YYYY') === moment().format("YYYY");
                    setFirstAvailableHourDisplay(isSameYear ? moment(res.start).format("D MMM, (LT)") : moment(res.start).format("D MMM, YYYY (LT)"))
                    onSelectDate(new Date(res.start))
                }
        })
        clinicService.fetchTimetable(price.provider_id,
            startTimestamp,
            endTimestamp,
            timezone,
            price.location_type,
            price.organization_id,
            price.id).then(res => {
                if (res !== null) {
                    let f = res[price.organization_id];
                    if (f != null) {
                        setTimetableData(f);
                        let timetableAvailableOnly = f.data.filter(o => {
                            return o.slots.find(slot=> slot.status === 'AVAILABLE') !== undefined;
                        }).map(day => { return { date:day.date, count:day.slots.filter(slot => slot.status === 'AVAILABLE').length}});
                        let availableOptions = createAvailableOptions(timetableAvailableOnly);
                        setAvailableDaysOptions(availableOptions);
                    }
                }
        })

    }, []);

    useEffect(() => {
        setTodaySlots(filterSlots());
    }, [timetableData, chosenDate])

    const onSelectDate = useCallback((date, triggeredFromBtn) => {
        setChosenDate(date);
        setTriggeredFromBtn(triggeredFromBtn);
    }, [setChosenDate, setTriggeredFromBtn]);

    const selectToday = useCallback(() => {
        onSelectDate(new Date(), true);
    }, [onSelectDate]);

    const onSlotSelected = useCallback((slot) => {
        updateStartEnd(slot.start, slot.end)
    }, [updateStartEnd]);

    return <div>
                <div className="flex-space-between align-items-center mb-4 pb-2">
                    <div className="early-appointment">
                        <div className="early-appointment-icon">
                            <CalendarIcon width="23px" height="28px" className="svg-icon"/>
                        </div>
                        {firstAvailableHour &&
                        <div className="early-appoitment-time">
                            <div className="small-gray-text">{$$("first_available_hour_label")}</div>
                            <div
                                className="first-time-available">{firstAvailableHour ? firstAvailableHourDisplay : ""}</div>
                        </div>
                        }
                        {
                            !firstAvailableHour && <div className="early-appoitment-time">
                                {$$("no_hours_available_primary_label")}
                            </div>
                        }
                    </div>
                    {firstAvailableHour && <button className="btn btn-primary"
                                                              onClick={
                                                                  () => {
                                                                      onSlotSelected({start:firstAvailableHour.start, end:firstAvailableHour.end})
                                                                      onComplete()
                                                                  }
                                                              }>
                        {$$("book_label")}
                    </button>
                    }
                </div>
                {firstAvailableHour && <div>
                    <ReactHorizontalDatePicker
                        bookAppointmentPanel={true}
                        selectedDay={onSelectDate}
                        enableScroll={true}
                        enableDays={appointmentUtils.FUTURE_DAYS_TO_FETCH}
                        i18n={getLanguage()}
                        chosenDate={chosenDate}
                        triggeredFromBtn={triggeredFromBtn}
                        slots={timetableData}
                        selectToday={selectToday}>
                        <Select
                            options={availableDaysOptions}
                            onChange={({name, value}) => {
                                onSelectDate(new Date(value), true);
                            }}
                        />
                    </ReactHorizontalDatePicker>
                </div>}
                {firstAvailableHour &&
                <FreeSlotsList
                    slots={todaySlots}
                    onSelect={onSlotSelected}
                    currentStart={selectedStart}
                    originalStart={originalStart}
                />}
        </div>
}

/* eslint-enable react/prop-types */

function ChosenExaminationWithTime(start, end, payment_status, overriden_location, examination, organisationName, status, setStart, setEnd, setStatus, locale, disableEdit) {
    const [openStart, setOpenStart] = useState(false)
    const [openEnd, setOpenEnd] = useState(false)

    const handleStart = (d) => {
        setOpenStart(false)
        let st = moment(d).startOf('minute').valueOf();
        setStart(st);
        setEnd(st + examination.duration_mins*60*1000);
    }

    const handleEnd = (d) => {
        setOpenStart(false)
        let endMs = moment(d).startOf('minute').valueOf();
        setEnd(endMs);
    }

    const timeClasses = classnames("d-flex w-100 space-between-justify", {"text-danger": !moment(start).isBefore(moment(end))})
    let isThisYear = moment(start).format("YYYY") === moment().format("YYYY");
    return <div className="high-shadow-container">
        <div className={timeClasses}>
            <div className="d-flex">
                <div className="early-appointment-icon pointer" onClick={() => {
                    if (!disableEdit) {
                        setOpenStart(true)
                    }
                }}>
                    <CalendarIcon width="23px" height="28px" className="svg-icon"/>
                </div>
                <div className="early-appoitment-time align-self-center">
                    <span className="medrec-grey-2">{$$("start")}</span>
                    <div
                        className="first-time-available">{start ? (isThisYear ? moment(start).format("D MMM, LT") : moment(start).format("D MMM, YYYY LT")) : ""}</div>
                </div>
            </div>
            <div className="hidden">
                {!disableEdit && <DateTimePickComponent date={moment(start)} locale={locale} setDate={handleStart} open={openStart}
                                       onClose={() => setOpenStart(false)}/>}
                {!disableEdit && <DateTimePickComponent date={moment(end)} locale={locale} setDate={handleEnd} open={openEnd}
                                       onClose={() => setOpenEnd(false)}/>}
            </div>
            <div className="d-flex">
                <div className="early-appointment-icon pointer" onClick={() => {
                    if (!disableEdit) {
                        setOpenEnd(true)
                    }
                }}>
                    <CalendarIcon width="23px" height="28px" className="svg-icon"/>
                </div>
                <div className="early-appoitment-time align-self-center">
                    <span className="medrec-grey-2">{$$("end")}</span>
                    <div
                        className="first-time-available">{end ? (isThisYear ? moment(end).format("D MMM, LT") : moment(end).format("D MMM, YYYY LT")) : ""}</div>
                </div>
            </div>
        </div>
        <div className="d-flex w-100 pt-3 justify-content-between">
            <div className="">
                <div
                    className="">{getExaminationName(examination)}
                </div>
            </div>
            <div className="capitalized ml-2">
                {(overriden_location ? appointmentUtils.overridenLocation() : $$(examination.location_type.toLowerCase())) + ", " + organisationName}
            </div>
            <div>
                {$$(status.toLowerCase())}
            </div>
            <div className="ml-2 align-self-center text-right flex-shrink-0" style={{
                display: "flex",
                alignSelf: "center"
            }}>
                <div>{price(examination)}
                    {examination.price_cents > 0 ? <div
                        className={`small ml-2 text-uppercase ${paymentStatusUtils.paymentStatus({payment_status: payment_status}).colour}`}>
                        ({paymentStatusUtils.paymentStatus({payment_status: payment_status}).label})
                    </div> : null}</div>

            </div>
        </div>
    </div>;
}


export function DateTimeComponentEditable({locale, readOnly, labelKey, time, setTime}) {
    const [openPicker, setOpenPicker] = useState(false)
    let isThisYear = moment(time).format("YYYY") === moment().format("YYYY");

    const handleTime = (d) => {
        setOpenPicker(false)
        let st = moment(d).startOf('minute').valueOf();
        setTime(st);
    }

    const iconClasses = useMemo(() => { return `early-appointment-icon ${!readOnly ? "pointer" : ""}`}, [readOnly]);
    const onClick = useCallback(() => {
        if (!readOnly) {
            setOpenPicker(true);
        }
    }, [readOnly]);

    return <div className="d-flex">
        <div className={iconClasses} onClick={onClick}>
            <CalendarIcon width="23px" height="28px" className="svg-icon"/>
        </div>
        <div className="early-appoitment-time align-self-center">
            <span className="medrec-grey-2">{$$(labelKey)}</span>
            <div
                className="first-time-available">{time ? (isThisYear ? moment(time).format("D MMM, LT") : moment(time).format("D MMM, YYYY LT")) : ""}</div>
        </div>
        <div className="hidden">
            <DateTimePickComponent date={moment(time)} locale={locale} setDate={handleTime} open={openPicker}
                                   onClose={() => setOpenPicker(false)}/>
        </div>
    </div>
}

/* eslint-disable react/prop-types */
function Examinations({clinician, onSelect, organisationId, organisationName}) {
    const onChangeValue = (event) => {
        let eventValue = JSON.parse(event.target.value);
        onSelect(eventValue)
    }

    if (clinician.prices !== undefined) {
        let pricesList = clinician.prices.filter(h => {
            return organisationId === h.organization_id && !appointmentUtils.isPriceForConsultation(h)
        }).map((h, idx) => {
            return <label className="mb-4 w-100 custom-control custom-radio" key={idx}>
                <input type="radio" className="custom-control-input" value={JSON.stringify(h)} name="prices"
                       onChange={onChangeValue}/>
                <div className="row w-100 custom-control-label" style={{marginLeft: "3px"}}>
                    <div className="col-8">
                        <div className=""> {getExaminationName(h)}</div>
                        <div className="capitalized price-type">
                            {$$(h.location_type.toLowerCase()) + ', ' + organisationName}</div>
                    </div>
                    <div className="col-4">{price(h)}</div>
                </div>
            </label>
        });

        return <div className='mt-2'>
            {pricesList.length > 0 && <h5 className='mb-3'>{$$("select_appointment_type_message_for_schedule")}</h5>}
            {pricesList.length === 0 && <h5 className='mb-3 text-danger'>{$$("price_list_is_empty")}</h5>}
            <div>{pricesList}</div>
        </div>;
    }
}

/* eslint-enable react/prop-types */

function price(e) {
    return formatUtils.currencyFormat(e.price_cents, e.currency)
}

/* eslint-disable react/prop-types */
function UserInfoRow({user, isPatient = false}) {
    return <div className="medrec-default-text-color high-shadow-container">
                            <span className="d-flex">
                                <div className="mr-1">
                                    <UserImage userID={user.user_id ? user.user_id : user.id}
                                               classnames="patient-img"/>
                                </div>
                                <div>
                                    <div className="h5">{getClinicianNameWithTitle(user)}</div>

                                    {isPatient && <small className="d-flex"><Email object={user}/><div className="pl-2"></div><Phone object={user}/> </small>}
                                    {!isPatient && user.email && user.email.indexOf('@') > -1 &&
                                        <div className="small"><i
                                            className="fa fa-envelope"/> {user.email} {getPhoneNumbersNodes(user)}
                                        </div>
                                    }
                                    {user.disabled && <div className="text-danger">{$$("account_closed")}</div>}
                                </div>
                            </span>

    </div>
}

/* eslint-enable react/prop-types */

function getPhoneNumbersNodes(u) {
    if (u.phone_numbers && u.phone_numbers.length > 0) {
        return <div className="d-inline-block ml-2"><i className="fa fa-phone"/>
            {u.phone_numbers.join(", ")}
        </div>
    }
    return null;
}

function getPatientPhoneNumbersNodes(user) {
    if (user.phone)
        return (<div className="d-inline-block ml-2">
            <i className="fa fa-phone"/><span className="ml-1">{user.phone}</span>
        </div>)
}

function getExaminationName(e) {
    return e.encounter_type === OTHER ? e.name : $$(e.encounter_type.toLowerCase());
}

function getStartAndEndTime(start, end) {
    return {
        start_time: moment(start).format("HH:mm"),
        end_time: moment(end).format("HH:mm")
    }
}

function timeFromDateAndTime(date, time) {
    let split = time.split(":");
    let res = setMinutes(setHours(date, Number(split[0])), Number(split[1]));
    res.setSeconds(0, 0);
    return res;
}

const PAGE_SIZE = 5;

class SelectPatient extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            page:0,
            filter:""
        }
        this.debouncedSearch = _.debounce(() => {
            this.search();
        }, 250, {leading:true});
    }

    componentDidMount() {
        this.search();
    }

    componentWillUnmount() {
        this.props.clearPatients()
    }

    search = () => {
        this.props.loadPatients(this.props.orgId, {page:this.state.page, size:PAGE_SIZE, search:this.state.filter, includeDisabled:false})
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.filter !== prevState.filter) {
            this.debouncedSearch();
        }
    }

    render() {
        let noPatients = {
            imgClass: 'no-lab-results-img',
            primaryLabelClass: 'no-lab-results-primary-label',
            secondaryLabelClass: '',
            src: no_appointments,
            primaryLabel: $$('no_records_found'),
            secondaryLabel: ''
        }

        return (
            <div>
                <div>
                    <Search placeholder={$$("search_for_patients_label")} filterUsers={(e) => {
                        if (e) {
                            this.setState({filter: e, page:0});
                        } else {
                            this.setState({
                                filter: "",
                                page:0
                            });
                        }
                    }}/>
                </div>
                <div className="patient-table-container mt-2" style={{maxHeight: "500px"}}>
                    <div className="w-100 d-table management-patients-table">
                        {
                            this.props.users.list?.map(user => {
                                return <PatientLine onSelect={this.props.onSelect} user={user} key={user.id}/>
                            })
                        }

                        {this.props.users.list?.length === 0 && this.props.users.request.success &&
                            infoUtils.noData(noPatients)}
                    </div>
                    {this.props.users.list?.length > 0 &&
                    <div className="pt-3">
                        <PaginationComponent alwaysShown={false} itemsCount={this.props.users.totalElements}
                                             itemsPerPage={PAGE_SIZE}
                                             currentPage={this.state.page+1}
                                             setCurrentPage={(p)=>{
                                                 this.setState({page:p-1}, this.search);
                                             }}/>
                    </div>}
                </div>
            </div>
        )
    }
}

const SelectPatientComponent = connect((state) => {
    return {
        orgId: state.management.selectedOrganisation.id,
        users: state.users,
    }
}, {
    loadPatients,
    clearPatients,
})(SelectPatient)


/* eslint-disable react/prop-types */
function PatientLine({onSelect, user}) {
    let classes = classnames("d-table-row", {pointer: onSelect});
    return (
        <div key={user.user_uin} className={classes} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (onSelect) {
                onSelect(user)
            }
        }}>
            <div className="d-table-cell p-2">
                <UserImage userID={user.id}
                           classnames="patient-img"/>
            </div>
            <div className="d-table-cell p-2">
                <h5>{user.fullname}</h5>
                <div className="medrec-grey-2 small">{$$('patient_pin') + ":" + user.user_uin}</div>
            </div>
            <div className="d-table-cell p-2">
                {!user.disabled && user.email.indexOf('@') !== -1 &&
                    <div><a href={"mailto:" + user.email} target="_blank" rel="noreferrer"
                            onClick={(e) => e.stopPropagation()}><i
                        className="fa fa-envelope"/>
                        <span className="ml-1">{user.email}</span></a></div>
                }
                {user.disabled && <div className="text-danger">{$$("account_closed")}</div>}
                {user.phone && <div>
                    <i className="fa fa-phone"/><span className="ml-1">{user.phone}</span>
                </div>
                }
            </div>
        </div>
    )
}

/* eslint-enable react/prop-types */

SelectPatient.propTypes = {
    i18n: PropTypes.any,
    users: PropTypes.any,
    orgId: PropTypes.any,
    loadPatients: PropTypes.func,
    clearPatients: PropTypes.func,
    onSelect: PropTypes.func
}