import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import Checkbox from "./Checkbox";
import PropTypes from "prop-types";


class ProviderCalendars extends Component {
    constructor(props) {
        super(props);
    }



    getOrganisationNames = () => {
        let organisationNames = [];
        for (let i in this.props.organizations) {
            organisationNames.push(this.props.organizations[i].name);
        }
        return organisationNames;
    }

    state = {
        checkboxes: this.getOrganisationNames().reduce(
            (options, option) => ({
                ...options,
                [option]: false
            }),
            {}
        ),
        checked: this.props.userSelections.workTime.calendars.length !== 0
    };

    componentDidMount() {
        let calendars = []
        for (let i in this.props.userSelections.workTime.calendars) {

            this.setState(prevState => ({
                checkboxes: {
                    ...prevState.checkboxes,
                    [this.props.userSelections.workTime.calendars[i]]: true
                }
            }))

            calendars.push(
                {
                    id: this.getOrganisationId(this.props.userSelections.workTime.calendars[i]),
                    name: this.props.userSelections.workTime.calendars[i],
                    value: true,
                    colour: this.getColour(this.props.userSelections.workTime.calendars[i])
                }
            )
        }
        this.props.onSelectCalendar(calendars);
        this.selectAllOrganizations();

    }

    selectAllOrganizations() {
        this.selectAll();
        this.setState({checked: true})
    }

    handleClick = () => {
        if (!this.state.checked) {
            this.selectAll();
            this.setState({checked: true})
        } else {
            this.deselectAll();
            this.setState({checked: false})
        }
        this.getColour();
    }

    handleItemClick = (option) => {
        let value = !this.state.checkboxes[option]
        this.setState(prevState => ({
            checkboxes: {
                ...prevState.checkboxes,
                [option]: value
            }
        }));

        this.returnCheckboxes(option, value)
    }

    getSelectLabel = () => {
        if (!this.state.checked) {
            return $$("mark_all");
        } else {
            return $$("unmark_all");

        }
    }

    selectAllCheckboxes = isSelected => {
        let selected = []
        Object.keys(this.state.checkboxes).forEach(checkbox => {
            this.setState(prevState => ({
                checkboxes: {
                    ...prevState.checkboxes,
                    [checkbox]: isSelected
                }
            }));
            if (isSelected === true) {
                selected.push(checkbox)
            }
        });

        this.props.userSelections.workTime.calendars = selected;
        this.props.setUserSelections(this.props.userSelections, null);


        this.returnAllCheckboxes(isSelected);
    };

    selectAll = () => this.selectAllCheckboxes(true);


    deselectAll = () => this.selectAllCheckboxes(false);

    handleCheckboxChange = changeEvent => {
        const {name} = changeEvent.target;

        this.setState(prevState => ({
            checkboxes: {
                ...prevState.checkboxes,
                [name]: !prevState.checkboxes[name]
            }
        }));
        let value = this.state.checkboxes[name]
        this.returnCheckboxes(name, value)
    };

    returnAllCheckboxes = (selected) => {
        let calenders = []

        for (let i in this.state.checkboxes) {
            calenders.push(
                {
                    id: this.getOrganisationId(i),
                    name: i,
                    value: selected,
                    colour: this.getColour(i)
                }
            )
        }

        this.props.onSelectCalendar(calenders);
    }

    returnCheckboxes = (name, value) => {
        let calenders = []

        for (let i in this.state.checkboxes) {
            if (i !== name) {
                calenders.push(
                    {
                        id: this.getOrganisationId(i),
                        name: i,
                        value: this.state.checkboxes[i],
                        colour: this.getColour(i)
                    }
                )
            }
        }
        calenders.push(
            {
                id: this.getOrganisationId(name),
                name: name,
                value: value,
                colour: this.getColour(name)
            }
        )
        let selected = []
        for (let i in calenders) {
            if (calenders[i].value === true) {
                selected.push(calenders[i].name)
            }
        }
        this.props.userSelections.workTime.calendars = selected;
        this.props.setUserSelections(this.props.userSelections, null);

        this.props.onSelectCalendar(calenders);

    }

    getOrganisationId = (option) => {
        let organisationId = ""
        for (let i in this.props.organizations) {
            if (this.props.organizations[i].name === option) {
                organisationId = this.props.organizations[i].id;
            }
        }
        return organisationId

    }

    createCheckbox = option => {
        if (option !== null) {
            return (
                <div className={this.getStyle(option)} key={this.getOrganisationId(option)}
                     onClick={() => this.handleItemClick(option)}>
                    <Checkbox
                        label={option}
                        isSelected={this.state.checkboxes[option]}
                        onCheckboxChange={this.handleCheckboxChange}
                        key={this.getOrganisationId(option)}
                        colour={this.getColour(option)}
                    />
                </div>
            );
        }
    }

    getColour = (option) => {
        let index
        for (let i in this.props.organizations) {
            if (this.props.organizations[i].name === option) {
                index = i;
            }
        }
        let colourArray = ["#f2c84c", "#16dbcb", "#ff6476", "#bb6bd9"];
        let colourIndex = index % 4;
        return colourArray[colourIndex];
    }

    getStyle = (option) => {
        let index
        for (let i in this.props.organizations) {
            if (this.props.organizations[i].name === option) {
                index = i;
            }
        }

        let colourArray = ["check-container-one", "check-container-two", "check-container-three", "check-container-four"]
        let colourIndex = index % 4;
        return colourArray[colourIndex];
    }
    getHeader = () => {
        return (
            <div className={" provider-cal"}>
                <div className="d-flex provider-cal-header">
                    <div className="bold">{$$("calendars_label")}</div>
                    <a className="work-time-link" onClick={this.handleClick}>{this.getSelectLabel()}</a>
                </div>
                <hr className="hr"/>
            </div>
        )
    }

    createCheckboxes = () => this.getOrganisationNames().map(this.createCheckbox);

    render() {
        return (
            <div className='stacked-right-column dashboard-card'>
                {this.getHeader()}
                {this.createCheckboxes()}
            </div>
        )
    }
}

ProviderCalendars.propTypes = {
    onSelectCalendar:PropTypes.func,
    organizations:PropTypes.array,
    providerId: PropTypes.string,
    setUserSelections: PropTypes.func,
    userSelections: PropTypes.object
}

export default ProviderCalendars
