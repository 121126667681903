import {$$} from "../../helpers/localization";
import {formatUtils} from "../../utils/formatUtils";
import $ from "jquery";

export function addToolTip(calEvent, jsEvent) {
    let appointmentName = $$("all_label")
    let independentSchedule = false;
    let availability = calEvent.timeTable.private_range ? $$("tooltip_schedule_unavailable") : $$("tooltip_schedule_available");

    for (let i in calEvent.prices) {
        if (calEvent.timeTable.encounter_price_id === calEvent.prices[i].id && calEvent.timeTable.encounter_price_id !== null) {

            independentSchedule = calEvent.prices[i].independent_timetable

            if (calEvent.prices[i].private_price) {
                availability = $$("tooltip_appointment_unavailable");
            }

            switch (calEvent.prices[i].encounter_type) {
                case 'PRIMARY_EXAM':
                    appointmentName = $$('primary_exam') + " - " + formatUtils.currencyFormat(calEvent.prices[i].price_cents, calEvent.prices[i].currency);
                    break;
                case 'FOLLOW_UP_EXAM':
                    appointmentName = $$('follow_up_exam') + " - " + formatUtils.currencyFormat(calEvent.prices[i].price_cents, calEvent.prices[i].currency);
                    break;
                case'OTHER':
                    appointmentName = calEvent.prices[i].name + " - " + formatUtils.currencyFormat(calEvent.prices[i].price_cents, calEvent.prices[i].currency);
                    break;
            }
        }
    }

    let location = $$(calEvent.timeTable.location_type.toLowerCase());
    independentSchedule = independentSchedule ? "&bull;" + $$("tooltip_independent_schedule") : ""

    let tooltip = '<div class="tooltipevent">'
        + "&bull;" + appointmentName + '<br>'
        + "&bull;" + location + '<br>'
        + "&bull;" + availability + '<br>'
        + independentSchedule + '</div>';


    if (!calEvent.timeTable.not_available) {

        let $t = $(tooltip);
        $t.css('top', jsEvent.pageY - 60);
        $t.css('left', jsEvent.pageX + 20);
        let $tooltip = $t.appendTo('body');

        $(this).mousemove(function (e) {
            $tooltip.css('top', e.pageY - 60);
            $tooltip.css('left', e.pageX + 20);
        });
    }
}

export function removeToolTip() {
    $('.tooltipevent').remove();
}