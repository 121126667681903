import React, {Component} from 'react'
import AppointmentsHeader from './AppointmentsHeader'
import AppointmentsListView from './AppointmentsListView'
import {connect} from 'react-redux'
import {
    clearProviderAppointments,
    clearSelectedAppointmentInProgress,
    clearSelectedUserData,
    fetchProviderAppointments,
    selectAppointment,
    selectAppointmentInProgress,
    selectUser
} from '../../actions/users_actions'
import {medicalHistoryHelper} from '../../helpers/medical_history_helper'
import {APPOINTMENTS_APPOINTMENT_CHAT} from '../../constants/pages'
import {Routes} from '../../constants/routes'
import {setUserSelections} from '../../actions/user_selections_actions';
import Calendar from '../calendar/Calendar';
import PropTypes from "prop-types";


class Appointments extends Component {
    state = {
        searchValue: '',
        calendarView: this.props.userSelections.appointments.view === "Calendar",
    }

    constructor(props) {
        super(props);
    }

    /**
     * Sets the state based on what is entered by the user
     *
     * @param {string} searchValue - the value entered by the user, used for filtering purposes
     */
    filterUsers = (searchValue) => {
        this.setState({searchValue});
    }

    /**
     * Sets the state based on the view selected by the user
     */
    changeView = () => {
        this.setState({calendarView: !this.state.calendarView});
        // set user preferences

        this.props.userSelections.appointments.view = !this.state.calendarView ? "Calendar" : "List";
        this.props.setUserSelections(this.props.userSelections, null);
    }

    /**
     * Prepares the data for the Messages History page
     *
     * @param {object} patient - the patient object
     * @returns true
     */
    prepareMessagesPageData = (patient) => {
        medicalHistoryHelper.prepareMessagesData(APPOINTMENTS_APPOINTMENT_CHAT, patient, Routes.MESSAGES);
    }

    render() {
        return (
            <div className='patient-card'>
                <AppointmentsHeader
                    filterUsers={this.filterUsers}
                    changeView={this.changeView}
                    calendarView={this.state.calendarView}
                />
                {this.state.calendarView &&
                <Calendar i18n={this.props.i18n}
                          userId={this.props.userId}
                          providerAppointments={this.props.providerAppointments}
                          fetchProviderAppointments={this.props.fetchProviderAppointments}
                          clearSelectedUserData={this.props.clearSelectedUserData}
                          selectAppointment={this.props.selectAppointment}
                          selectAppointmentInProgress={this.props.selectAppointmentInProgress}
                          onMessagesClick={this.prepareMessagesPageData}
                          selectUser={this.props.selectUser}
                          clearProviderAppointments={this.props.clearProviderAppointments}
                          clearSelectedAppointmentInProgress={this.props.clearSelectedAppointmentInProgress}
                          selectedAppointment={this.props.selectedAppointment}
                          userSelections={this.props.userSelections}
                          setUserSelections={this.props.setUserSelections}/>
                }
                {!this.state.calendarView &&
                <AppointmentsListView
                    userId={this.props.userId}
                    providerAppointments={this.props.providerAppointments}
                    fetchProviderAppointments={this.props.fetchProviderAppointments}
                    onMessagesClick={this.prepareMessagesPageData}
                    clearSelectedUserData={this.props.clearSelectedUserData}
                    clearProviderAppointments={this.props.clearProviderAppointments}/>
                }
            </div>
        )
    }
}

Appointments.propTypes = {
    clearProviderAppointments: PropTypes.func,
    userId: PropTypes.string,
    clearSelectedAppointmentInProgress: PropTypes.func,
    clearSelectedUserData:PropTypes.func,
    fetchProviderAppointments:PropTypes.func,
    history:PropTypes.object,
    i18n:PropTypes.object,
    location: PropTypes.object,
    match:PropTypes.object,
    providerAppointments: PropTypes.object,
    selectAppointment:PropTypes.func,
    selectAppointmentInProgress: PropTypes.func,
    selectUser:PropTypes.func,
    selectedAppointment:PropTypes.object,
    setUserSelections: PropTypes.func,
    userSelections: PropTypes.object
}

const mapStateToProps = (state) => ({
    i18n: state.language,
    userId: state.userInfo.data.id,
    providerAppointments: state.providerAppointments,
    selectedAppointment: state.selectedAppointment.data,
    userSelections: state.userSelections
})

export default connect(mapStateToProps, {
    fetchProviderAppointments,
    clearSelectedUserData,
    selectAppointment,
    selectAppointmentInProgress,
    selectUser,
    clearProviderAppointments,
    clearSelectedAppointmentInProgress,
    setUserSelections
})(Appointments)
