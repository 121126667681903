import {Component} from 'react'
import {CHAT_WEBSOCKET_URL} from '../../constants/api_paths'
import {fetchHelper} from '../../helpers/request_helper'
import PropTypes from "prop-types";


export class MessageWebSocketComponent extends Component {
    constructor(props) {
        super(props);
        this.stopRetry = false;
        this.retryCount = 0;

    }

    /**
     * Connect the stomp client via the websocket. Add handlers to get messages and fallback to polling when there is any error
     */
    connectSocket = () => {
        let protocol, webSocketProtocol;
        if (location.protocol.indexOf('https') !== -1) {
            protocol = 'https';
            webSocketProtocol = 'wss';
        } else if (location.protocol.indexOf('http') !== -1) {
            protocol = 'http';
            webSocketProtocol = 'ws';
        }
        const socket = new WebSocket(CHAT_WEBSOCKET_URL.replace(protocol, webSocketProtocol));
        // eslint-disable-next-line no-undef
        this.stompClient = Stomp.over(socket);
        this.stompClient.debug = f => f;
        this.stompClient.heartbeat.outgoing = 25000;
        this.stompClient.heartbeat.incoming = 25000;
        this.stompClient.reconnect_delay = 5000;
        this.stompClient && this.stompClient.connect(fetchHelper.getTokenHeader(), function () {
            this.stompClient.subscribe('/user/queue/messages', function (messageOutput) {
                const message = messageOutput && messageOutput.body ? JSON.parse(messageOutput.body) : null;
                this.props.storeMessageInReduxStore(message);
            }.bind(this));
        }.bind(this), function () {
            this.reConnectSocket();
        }.bind(this));
    }

    /**
     * Re-connect socket function, try to reconnect for several times before stopping
     */
    reConnectSocket = () => {
        if (!this.stopRetry && this.retryCount < 20) {
            const factor = this.retryCount ? this.retryCount : 0;
            setTimeout(() => this.connectSocket(), 10000 * factor);
            this.retryCount++;
        } else if (this.retryCount >= 20) {
            this.stopRetry = true;
        }
    }

    /**
     * Disconnect the stomp client over the socket
     */
    disconnectSocket = () => {
        if (this.stompClient != null) {
            try {
                this.stompClient.disconnect();
            } catch (e) {
                //nop;
            }
        }
    }

    componentDidMount() {
        this.connectSocket();
    }

    componentWillUnmount() {
        this.stopRetry = true;
        this.disconnectSocket();
    }

    render() {
        return null;
    }
}

MessageWebSocketComponent.propTypes = {
    storeMessageInReduxStore: PropTypes.func
}