import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import {CONVERTER} from '../../utils/converter'
import {Routes} from '../../constants/routes'
import MessagesButton from '../shared/MessagesButton'
import EmailButton from '../shared/EmailButton'
import MedicalHistoryButton from '../History/MedicalHistoryButton'
import history from '../../helpers/history'
import PropTypes from "prop-types";
import UserImageWrap from "../shared/UserImageWrap";

class PatientsGridView extends Component {
    constructor(props) {
        super(props);
    }

    /**
     * Creates the main container
     *
     * @param {object} patient - the patient object
     * @param {int} idx - index value
     * @returns {ReactElement} the created container
     */
    getContainer = (patient, idx) => {
        let innerContainers = [];
        innerContainers.push(this.getNameContainer(patient));
        innerContainers.push(this.getHealthIssueListContainer(patient));
        innerContainers.push(this.getDetailsContainer(patient));

        return <div key={idx} className='patient-grid-view'>
            {innerContainers}
        </div>;
    }

    /**
     * Creates the name container
     *
     * @param {object} patient - the patient object
     * @returns {ReactElement} the created container
     */
    getNameContainer = (patient) => {
        let age = CONVERTER.millisecondsToAge(patient.birthday);
        let patientImageClass = 'patient-img';

        return <div key="name-container" className='row patient-name-container'>
            <div className='d-flex justify-content-center patient-img-container-grid'>
                <UserImageWrap userID={patient.id}
                               show_red_dot={patient.has_not_seen_patient_documents}
                               classnames={patientImageClass}/>
            </div>
            <div className={"dropdown-link pointer grid-name"} onClick={() => {
                history.push(Routes.HISTORY);
                return this.props.onMedicalHistoryClick(patient);
            }}>
                {patient.fullname}
                <br/>
                {patient.birthday !== 0 && <span>{age}&nbsp;{age === 1 ? $$('year_old') : $$('years_old')}</span>}
                {(!patient.disabled && patient.email.indexOf('@') !== -1) ?
                    <div style={{lineBreak: "anywhere"}}><a href={"mailto:" + patient.email} target="_blank"
                                                            rel="noreferrer"
                                                            onClick={(e) => e.stopPropagation()}><i
                        className="fa fa-envelope"/> &nbsp;
                        <span>{patient.email}</span></a></div> : <br/>

                }
                {patient.phone ? <div>
                    <div style={{lineBreak: "anywhere"}}><i className="fa fa-phone"/> {patient.phone}</div>
                </div> : <br/>}
            </div>
        </div>
    }

    /**
     * Creates the health issues list container
     *
     * @param {object} patient - the patient object
     * @returns {ReactElement} the created container
     */
    getHealthIssueListContainer = (patient) => {
        let healthIssueList = patient.healthIssueLiteList.map((h, idx) => {
            if (idx === 0) {
                return <div key={idx} className='patient-health-issue-list patient-health-issue-list-none '>
                    {h.name}
                </div>
            } else {
                return <div key={idx} className='patient-health-issue-list'>{h.name}</div>
            }
        });

        return <div key="health-issues-container" className='text-center patient-health-issue-list-container-footer'>
            <div className='row patient-health-issue-list-container'>{healthIssueList}</div>
        </div>
    }

    /**
     * Creates the details container
     *
     * @param {object} patient - the patient object
     * @returns {ReactElement} the created container
     */
    getDetailsContainer = (patient) => {
        return <div key="details-container" className='text-center details-container'>
            <hr/>
            <div className='row patient-details-container'>
                <MessagesButton title={$$('messages_label')} style={{'marginLeft': '0.625rem'}} onClick={() => {
                    this.props.onMessagesClick(patient);
                }}/>
                <EmailButton title={$$('email_label')} style={{'marginLeft': '0.625rem'}} onClick={() => {
                    window.location = "mailto:" + patient.email;
                }}/>
                <MedicalHistoryButton title={$$('medical_history')} style={{'marginLeft': '0.625rem'}} onClick={() => {
                    history.push(Routes.HISTORY);
                    return this.props.onMedicalHistoryClick(patient);
                }}/>
            </div>
        </div>
    }

    /**
     * Creates the full body of the table
     *
     * @returns {Array} the table rows
     */
    getBody = () => {
        let allContainers = [];
        let patients = this.getFilteredPatients();

        for (let i = 0; i < patients.length; ++i) {
            allContainers.push(this.getContainer(patients[i], i));
        }

        return [].concat.apply([], allContainers);
    }

    /**
     * Filters the patients based on the search value
     *
     * @returns {Array} the list of filtered patients
     */
    getFilteredPatients = () => {
        return this.props.users?.list ? this.props.users.list : [];
    }

    render() {
        return (
            <div className='card-body zero-padding'>
                <div className='patient-grid-view-container'>
                    {this.getBody()}
                </div>
            </div>
        )
    }
}

PatientsGridView.propTypes = {
    onMedicalHistoryClick: PropTypes.func,
    onMessagesClick: PropTypes.func,
    users: PropTypes.any,
    searchValue: PropTypes.any
}

export default PatientsGridView
