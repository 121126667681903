import React, {Component} from 'react'
import FreeSlotItem from './FreeSlotItem'
import {infoUtils} from "../../utils/infoUtils"
import no_data from '../../resources/images/no_data.png'
import {$$} from '../../helpers/localization'
import PropTypes from "prop-types";

class FreeSlotsList extends Component {
    constructor(props) {
        super(props);
        this.index = 0;
    }


    /**
     * Prepares the slot list item by item
     *
     * @returns {Array} - the list with slot elements
     */
    getSlotListItems = () => {

        let noHoursAvailableObj = {
            imgClass: 'no-entry-image2',
            objClass: 'no-data-object',
            primaryLabelClass: 'no_hours_available_primary_label',
            secondaryLabelClass: 'no_hours_available_primary_label',
            src: no_data,
            primaryLabel: $$('no_hours_available_primary_label'),
        }

        if (this.props.slots && this.props.slots.length > 0) {
            return this.props.slots.map((a, i) => {
                return <div key={i}>
                    <FreeSlotItem
                        clinician={this.props.clinician}
                        selectedPrice={this.props.selectedPrice}
                        slot={a}
                        onSelect={this.props.onSelect}
                        currentStart={this.props.currentStart}
                        originalStart={this.props.originalStart}
                    />
                </div>
            });
        } else {
            return infoUtils.noData(noHoursAvailableObj)
        }

    }

    render() {
        return (
            <div className='slot-items'>
                {this.getSlotListItems()}
            </div>
        )
    }
}

FreeSlotsList.propTypes = {
    clinician: PropTypes.object,
    onSelect: PropTypes.any,
    selectedPrice: PropTypes.object,
    slots: PropTypes.array
}

export default FreeSlotsList;
