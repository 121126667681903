import React, {useEffect, useState} from 'react';
import {downloadMedicationsReport, downloadMenstruationReport, summaryService} from "../../service/summary_service";
import {CONVERTER} from "../../utils/converter";
import {differenceInDays} from "date-fns";
import addDays from "date-fns/addDays"
import {$$} from "../../helpers/localization";
import no_data from "../../resources/images/no_data.png";
import {infoUtils} from "../../utils/infoUtils";
import {downloadUtils} from "../../utils/downloadUtils";
import moment from "moment";
import DownloadEditDeleteButtons from "../shared/DownloadEditDeleteButtons";

export function MenstruationStatsTab({selectedUser, selectedStart, selectedEnd, i18n, settings}) {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true)
    useEffect(()=>{
        let healthIssueIds = []

        for (let i in selectedUser.health_issues) {
            healthIssueIds.push(selectedUser.health_issues[i].id)
        }

        let params = {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            fromDate: selectedStart ? selectedStart : 0,
            toDate: selectedEnd ? selectedEnd : 0,
            healthIssueIds: healthIssueIds
        }

        summaryService.getMenstruationStats(selectedUser.id, params).then((res) => {
            setData(res);
            setLoading(false);
        }).catch(()=>{
            setLoading(false);
        })

    }, [selectedUser, selectedStart, selectedEnd])


    if (!loading && !data) {
        return <NoData />
    }

    return <MenstruationStatsView data={data} lang={i18n} selectedStart={selectedStart} selectedEnd={selectedEnd} selectedUser={selectedUser} settings={settings}/>
}

function NoData() {
    let emptyResultObj = {
        imgClass: 'no-lab-results-img',
        primaryLabelClass: 'no-lab-results-primary-label',
        secondaryLabelClass: 'no-lab-results-secondary-label',
        src: no_data,
        primaryLabel: $$('no_records_found'),
        secondaryLabel: ''
    }
    return infoUtils.noData(emptyResultObj);
}


function MenstruationStatsView({data, lang, selectedStart, selectedEnd, selectedUser, settings}) {
    if (!data) {
        return null;
    }

    const downloadReport = () => {
        let reportData = {}
        reportData.reportTitle = $$("menstruation_report");
        reportData.patientName = selectedUser.fullname;
        let format = settings.data.dateFormat.toUpperCase();
        reportData.fromDate = moment(selectedStart).format(format);
        reportData.toDate = moment(selectedEnd).format(format);
        reportData.datetime = moment(new Date()).format(format);
        reportData.data = data;
        downloadMenstruationReport(lang, reportData).then((file) => {
            downloadUtils.download(file, "Menstruation Report  - " +
                selectedUser.fullname +
                " - " +
                reportData.fromDate +
                " to " +
                reportData.toDate +
                ".pdf");
        });
    }

    return <div style={{fontWeight:"100"}}>
            {data.entries.length > 0 && <>
                <div className="pb-2 pl-2 pr-2 mb-2 font-weight-normal">
                    <DownloadEditDeleteButtons handleDownloadClick={downloadReport} />
                    <span className={"user-profile-link"}
                        onClick={downloadReport}>{$$("download_menstruation_report")}
                    </span>
                </div>
                <Stats data={data} lang={lang}/>
                <Periods data={data} />
            </>}
        {data.entries.length === 0 && <NoData />}
    </div>
}

function Stats({data, lang}) {
    return <div>
        <CycleDay cycleDay={data.cycleDay} lang={lang}/>
        <CycleData data={data}/>
        <PeriodData data={data}/>
    </div>
}

function toDays(v) {
    if (v === 0) {
        return <Accented>&mdash;</Accented>
    }

    const unit = v > 1 ? "days" : "day";

    return <Accented>
        {Math.round(v)} {$$(unit).toLowerCase()}
    </Accented>
}

function CycleData({data}) {
    return <div className="low-shadow-container mb-3">
            <h4>{$$("cycle_title")}</h4>
            <div className="text-right">{$$("duration")}</div>
            <hr className="mt-1 mb-0"/>
            <table className="table table-borderless table-sm table-striped medrec-default-text-color">
                <tbody>
                    <tr className="d-none">
                    </tr>
                    <tr>
                        <td>{$$("last_cycle_length_label")}</td>
                        <td className="text-right">{toDays(data.lastCycleLength)}</td>
                    </tr>
                    <tr>
                        <td>{$$("average_label")}</td>
                        <td className="text-right">{toDays(data.averageCycleLength)}</td>
                    </tr>
                    <tr>
                        <td>{$$("shortest_male_label")}</td>
                        <td className="text-right">{toDays(data.shortestCycleLength)}</td>
                    </tr>
                    <tr>
                        <td>{$$("longest_male_label")}</td>
                        <td className="text-right">{toDays(data.longestCycleLength)}</td>
                    </tr>
                </tbody>
            </table>
    </div>
}

function PeriodData({data}) {
    return <div className="low-shadow-container mb-3">
        <h4>{$$("period_title")}</h4>
        <div className="text-right">{$$("duration")}</div>
        <hr className="mt-1 mb-0"/>
        <table className="table table-borderless table-sm table-striped medrec-default-text-color">
            <tbody>
                <tr className="d-none">
                </tr>
                <tr>
                    <td>{$$("last_period_length_label")}</td>
                    <td className="text-right">{toDays(data.lastPeriodLength)}</td>
                </tr>
                <tr>
                    <td>{$$("average_label")}</td>
                    <td className="text-right">{toDays(data.averagePeriodLength)}</td>
                </tr>
                <tr>
                    <td>{$$("shortest_female_label")}</td>
                    <td className="text-right">{toDays(data.shortestPeriodLength)}</td>
                </tr>
                <tr>
                    <td>{$$("longest_female_label")}</td>
                    <td className="text-right">{toDays(data.longestPeriodLength)}</td>
                </tr>
            </tbody>
        </table>
    </div>
}

function CycleDay({cycleDay, lang}) {

    return <div className="d-flex space-between-justify low-shadow-container mb-3">
        <div>{$$("cycle_day_label")}</div>
            <div>{toCycleDay(cycleDay, lang)}</div>
    </div>
}

function Accented({children}) {
    return <span className="font-weight-normal">{children}</span>
}

const english_ordinal_rules = new Intl.PluralRules("en", {type: "ordinal"});
const suffixesEn = {
    one: "st",
    two: "nd",
    few: "rd",
    other: "th"
};

const suffixesBg = ["ен", "тен", "и", "ви", "ри", "ти", "и"];

const rules = [
    [1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000], // -> "ен"
    [100, 400, 500, 600, 700, 700, 800, 900], // -> "тен"
    [11, 12, 13, 14, 200, 300], // -> "и"
    [1], // -> "ви"
    [2], // -> "ри"
    [3, 4] // -> "ти"
];
//everything else -> "и"


function suffBg(number) {
    let i = 0;
    for (; i < 6; i++) {
        let rule = rules[i];
        for (const subRule of rule) {
            if (("" + number).endsWith(subRule)) {
                return suffixesBg[i]
            }
        }
    }
    return suffixesBg[i];
}

function ordinalSuffix(number, lang) {
    if (lang === 'en') {
        const category = english_ordinal_rules.select(number);
        const suffix = suffixesEn[category];
        return suffix;
    }
    return suffBg(number);
}

function toCycleDay(number, lang) {
    if (number === 0) {
        return <Accented>&mdash;</Accented>
    }
    return <Accented>{number}<sup>{ordinalSuffix(number, lang)}</sup></Accented>
}

function Periods({data}) {
    return <div className="low-shadow-container">
            <h4>{$$("history")}</h4>
            <hr/>
        {
            data.periodStarts.map(a=>a).sort((a,b)=>{ return new Date(b).getTime() - new Date(a).getTime()}).map((key)=>{
                return <div key={key} className="mb-2">
                    <div>{CONVERTER.formatDate(key, true)}</div>
                    <Bar key={key} idx={key} data={data} />
                </div>
            })
        }
    </div>
}

function Bar({idx, data}) {
    const periodLength = data.periodLengths[idx];
    const cycleLength = data.cycleLengths[idx];
    let normalizedCycleWidth = cycleLength > 50 ? 50 : (cycleLength === 0 ? calcDays(idx, periodLength) : cycleLength - periodLength);
    let cyclePercentWidth = Math.round(normalizedCycleWidth / 50 * 100);
    let periodLengthPercentWidth = Math.round(periodLength * 2);

    return <div className="position-relative d-flex pr-2">
                <div style={{width:`${periodLengthPercentWidth}%`}} className="text-right p-1 pr-2 bg-danger text-light border-top-left-round border-bottom-left-round">{periodLength}</div>
                {normalizedCycleWidth > 0 && <div style={{width:`${cyclePercentWidth}%`}} className="text-right p-1 pr-2 medrec-blue-2-bg border-top-right-round border-bottom-right-round position-relative">
                    {normalizedCycleWidth + periodLength > 50 && <Spacer />}
                    {cycleLength > 0 ? cycleLength : "?"}
                </div>}
                {normalizedCycleWidth === 0 && <div className="p-1">?</div>}
           </div>
}

function calcDays(startDate, periodDays) {
    let res = addDays(new Date(startDate), periodDays - 1)
    let number = differenceInDays(new Date(), res);
    return number < 0 ? 0 : number;
}

function Spacer() {
    return <span className="position-absolute" style={{
        width:"1.2rem",
        backgroundColor:"#fff",
        display:"inline-block",
        top:"0",
        bottom:"0",
        right:"3rem",
        textAlign:"center",
        lineHeight:"2rem"
    }}>{"..."}</span>
}