import {fetchHelper} from '../helpers/request_helper';
import {
    GET_USER_SETTINGS_URL,
    UPDATE_USER_SETTINGS_URL,
    GET_PROVIDER_CONFIGUIRATION_URL,
    UPDATE_PROVIDER_CONFIGUIRATION_URL, GET_PROVIDER_CHAT_SETTINGS_URL, UPDATE_PROVIDER_CHAT_SETTINGS_URL
} from '../constants/api_paths';

export const settingsService = {
    getUserSettings,
    updateUserSettings,
    getProviderConfiguration,
    updateProviderConfiguration,
    getProviderChatSettings,
    updateProviderChatSettings
};

/**
 * Get user settings
 *
 * @param {string} targetUserId id of the user to fetch settings for
 * @returns {object} promise object
 */
export function getUserSettings(targetUserId) {
    return fetchHelper.callGet(GET_USER_SETTINGS_URL.replace('{targetUserId}', targetUserId));
}

/**
 * Update user settings
 *
 * @param {string} targetUserId id of the user to fetch settings for
 * @param {object} body the new settings to save
 * @returns {object} promise object
 */
export function updateUserSettings(targetUserId, body) {
    return fetchHelper.callPut(UPDATE_USER_SETTINGS_URL.replace('{targetUserId}', targetUserId), body);
}

/**
 * Get Provider configuration from the server
 *
 * @returns {object} promise object
 */
export function getProviderConfiguration() {
    return fetchHelper.callGet(GET_PROVIDER_CONFIGUIRATION_URL, {});
}

/**
 * Update provider configuration on the server
 * @param configuration
 *
 * @returns {object} promise object
 */
export function updateProviderConfiguration(configuration) {
    return fetchHelper.callPut(UPDATE_PROVIDER_CONFIGUIRATION_URL, configuration, {});
}

/**
 * Get Provider chat settings from the server
 *
 * @returns {object} promise object
 */
export function getProviderChatSettings() {
    return fetchHelper.callGet(GET_PROVIDER_CHAT_SETTINGS_URL, {});
}

/**
 * Update provider chat settings on the server
 * @param chatSettings
 *
 * @returns {object} promise object
 */
export function updateProviderChatSettings(chatSettings) {
    return fetchHelper.callPut(UPDATE_PROVIDER_CHAT_SETTINGS_URL, chatSettings, {});
}