import React, {Component} from "react";
import {Tooltip} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import PropTypes from "prop-types";


export default class RemoveNotificationButton extends Component {
    constructor(props, context) {
        super(props, context);
    }

    renderTooltip = props => (
        <Tooltip {...props} show={props.show.toString()}>{this.props.title}</Tooltip>
    );

    render() {
        return <OverlayTrigger
            placement={"top"}
            overlay={this.renderTooltip}
        >
            <div {...this.props} className="btn btn-outline-danger btn-icon btn-icon-sm btn-circle btn-sm"
                 style={{"width": "1.2rem", "height": "1.2rem"}}>
                <i className='fas fa-times centered-text'
                   style={{
                       "width": "100%",
                       "height": "100%",
                       'fontSize': '0.75rem',
                       'marginTop': '0.4rem'
                   }}/>
            </div>
        </OverlayTrigger>
    }
}


RemoveNotificationButton.propTypes = {
    onClick: PropTypes.func,
    style:PropTypes.object,
    title: PropTypes.string
}