import React, {useEffect, useState} from "react"
import {$$} from "../../helpers/localization";
import moment from "moment";
import CenteredModal from "../shared/CenteredModal";

export default function CancelAppointmentModal(props){
    const [note, setNote] = useState("");
    const [date, setDate] = useState(moment(props.appointment.starts_at).format('D MMMM HH:mm'));
    const [patientName, setPatientName] = useState(()=>{
        let patient = props.appointment.participants.find(p => p.participant_role === 'PATIENT');
        return patient ? patient.fullname : "";
    });

    useEffect(()=>{
        let patient = props.appointment.participants.find(p => p.participant_role === 'PATIENT');
        setPatientName(patient ? patient.fullname : "");
        setDate(moment(props.appointment.starts_at).format('D MMMM HH:mm'))
    }, [props.appointment])

    useEffect(()=> {
        setNote("");
    }, [props.show])

    let headerKey = props.appointment.text_only ? "cancel_consultation_confirmation_header" : "cancel_appointment_confirmation_header";
    let cancelBtnLabel = props.appointment.text_only ? $$("cancel_consultation_label") : $$("cancel_appointment_label");
    let cancelConfirmationBody = props.appointment.text_only ? $$("cancel_consultation_confirmation_body") : $$("cancel_appointment_confirmation_body");

    return <CenteredModal title={$$(headerKey)
        .replace('{fullname}', patientName)
        .replace('{date}', date)}
                          show={props.show}
                          onHide={props.onHide}
                          onConfirm={() => {
                              let noteObj = null
                              if (note && note.trim() !== "") {
                                  noteObj = {
                                      value: note.trim(),
                                      visibility: "ALL"
                                  }
                              }
                              props.onConfirm(props.appointment, noteObj)
                          }}
                          confirmBtnLabel={cancelBtnLabel}
    >
        {cancelConfirmationBody}
        {!props.hideNote && <div className={"pt-2"}>
            <textarea className="form-control" rows="4"
                      value={note}
                      placeholder={$$('note_label')}
                      name="note"
                      onChange={(e)=>{setNote(e.target.value)}}
            />
        </div>}
    </CenteredModal>
}