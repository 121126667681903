import React, {useCallback, useEffect, useState} from "react"
import {
    nhisCheckEreferral,
    nhisLogin,
    nhisSignCheckEreferral, textXmlToJsonStripNamespaces
} from "../../../../service/nhis_service";
import {$$} from "../../../../helpers/localization";
import Modal from "react-bootstrap/Modal";
import {useNhisEReferralFetchContext} from "./NhisEReferralFetchNomenclatureProvider";
import {ExecutionsView} from "./ExecutionsView";
import {ExaminationsView} from "./ExaminationsView";
import {HospitalizationsView} from "./HospitalizationsView";

export function NhisEReferralFetch({eRef, onClose, lang}) {
    const [res, setRes] = useState();
    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");

    const {
        eRefStatusOptions
    } = useNhisEReferralFetchContext();

    const fetchData = useCallback(() => {
        if (!eRef) {
            return;
        }

        const c = JSON.parse(eRef.content_json);
        const req = c.requester;

        const message = {
            nrnReferral: [eRef.nrn],
            senderId: req.pmi,
        }

        setSending(true);
        setError("");

        nhisLogin((loginSuccess) => {
            nhisSignCheckEreferral(message, (signedRequest) => {
                nhisCheckEreferral(loginSuccess.accessToken, signedRequest)
                    .then(async res => {
                        if (res && res.length === 1) {
                            setRes(res[0]);
                            setSuccess(true);
                        } else {
                            setError($$("nhis.ereferral.no_execution_results"));
                            setSuccess(false);
                        }
                        setSending(false);
                    })
                    .catch(async e => {
                        setError($$("nhis.service_error"));
                        setSuccess(false);
                        setSending(false);
                    })
            }, (e) => {
                setError(e);
                setSending(false);
            })
        }, (e) => {
            setSending(false);
            setError(e)
        }).catch(error => {
            console.log(error);
            setSending(false);
            setError($$("nhis.service_error"));
        })
    }, [eRef.nrn])

    useEffect(() => {
        fetchData();
    }, [eRef.nrn]);

    return <Modal backdrop={"static"} size={"lg"} show={true} >
        <Modal.Header className={"h5"}>
            {$$("nhis.ereferral.modal_header_check")}
        </Modal.Header>
        <Modal.Body>
            {error && <p className="text-danger p-2">{error}</p> }
            {success && <div className="">
                <div>{$$("nhis.ereferral.status")}: {eRefStatusOptions.find(o => o.value === res.referral.status).label}</div>
                <Results eRef={res} />
            </div> }
            {!success && sending && <div>
                <p>{$$("processing")}</p>
            </div>}
        </Modal.Body>
        {!sending && <Modal.Footer>
            <button type="button" className="btn btn-primary" disabled={sending}
                    onClick={onClose}>{$$("close_btn_label")}</button>
        </Modal.Footer>}
    </Modal>
}

function Results({eRef}) {
    //eRef.execution = undefined;
    /*eRef.examination = [
        {

        }
    ]*/

    /*eRef.hospitalization = [{
        status:2,
        reasonCode:2,
        severity:2,
        admissionType:2,
        admissionDate:"2012-12-30T12:34:00"
    }]

    console.log(eRef)*/

    //return false;


    if (eRef.execution) {
        return <ExecutionsView executions={eRef.execution}/>
    }

    if (eRef.examination) {
        return <ExaminationsView examinations={eRef.examination} />
    }

    if (eRef.hospitalization) {
        return <HospitalizationsView hospitalizations={eRef.hospitalization} />
    }

    return false;
}