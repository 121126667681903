export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

export const LOGIN_REQUEST_SENT = "LOGIN_REQUEST_SENT";
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS";
export const LOGIN_REQUEST_ERROR = "LOGIN_REQUEST_ERROR";
export const LOGIN_REQUEST_CLEAR_STATUS = "LOGIN_REQUEST_CLEAR_STATUS";
export const ACTION_2FA_CLEAR_RESULT = "ACTION_2FA_CLEAR_RESULT";
export const ACTION_TOTP_LOADED = "ACTION_TOTP_LOADED";
export const DOCUMENT = "DOCUMENT";
export const ACTION_2FA_CHANGED = "ACTION_2FA_CHANGED";
export const ACTION_2FA_LOADED = "ACTION_2FA_LOADED";
export const ACTION_TOTP_VERIFY_RESP = "ACTION_TOTP_VERIFY_RESP";
export const ACTION_TOTP_VERIFY_RESP_CLEAR = "ACTION_TOTP_VERIFY_RESP_CLEAR";
export const TOKEN_REFRESH_REQUEST_SENT = "TOKEN_REFRESH_REQUEST_SENT";
export const TOKEN_REFRESH_REQUEST_SUCCESS = "TOKEN_REFRESH_REQUEST_SUCCESS";
export const TOKEN_REFRESH_REQUEST_ERROR = "TOKEN_REFRESH_REQUEST_ERROR";
export const LOGOUT = "LOGOUT";
export const AUTO_LOGOUT = "AUTO_LOGOUT";

export const UPDATE_MENU = "SET_MENU_CONFIG";
export const SELECT_ORGANISATION_TO_MANAGE = "SELECT_ORGANISATION_TO_MANAGE";
export const GET_STAFF_LIST_SUCCESS = "GET_STAFF_LIST_SUCCESS";
export const GET_STAFF_LIST_ERROR = "GET_STAFF_LIST_ERROR";

export const REQUEST_SENT = "REQUEST_SENT";
export const REQUEST_SUCCESS = "REQUEST_SUCCESS";
export const REQUEST_ERROR = "REQUEST_ERROR";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTER_CLEAR = "REGISTER_CLEAR";

export const ALL_USERS_REQUEST_SENT = "ALL_USERS_REQUEST_SENT";
export const ALL_USERS_SUCCESS = "ALL_USERS_SUCCESS";
export const ALL_USERS_ERROR = "ALL_USERS_ERROR";
export const CLEAR_PATIENTS = "CLEAR_PATIENTS";

export const SELECT_USER = "SELECT_USER";
export const UPDATE_PATIENT_PERSONAL_INFO_REQUEST_SENT = "UPDATE_PATIENT_PERSONAL_INFO_REQUEST_SENT";
export const UPDATE_PATIENT_PERSONAL_INFO_REQUEST_SUCCESS = "UPDATE_PATIENT_PERSONAL_INFO_REQUEST_SUCCESS";
export const UPDATE_PATIENT_PERSONAL_INFO_REQUEST_ERROR = "UPDATE_PATIENT_PERSONAL_INFO_REQUEST_ERROR";
export const UPDATE_PATIENT_PERSONAL_INFO_CLEAR_REQUEST_STATUS = "UPDATE_PATIENT_PERSONAL_INFO_CLEAR_REQUEST_STATUS";

export const UPDATE_PATIENT_MED_PROFILE_REQUEST_SENT = "UPDATE_PATIENT_MED_PROFILE_REQUEST_SENT";
export const UPDATE_PATIENT_MED_PROFILE_REQUEST_SUCCESS = "UPDATE_PATIENT_MED_PROFILE_REQUEST_SUCCESS";
export const UPDATE_PATIENT_MED_PROFILE_REQUEST_ERROR = "UPDATE_PATIENT_MED_PROFILE_REQUEST_ERROR";
export const UPDATE_PATIENT_MED_PROFILE_CLEAR_REQUEST_STATUS = "UPDATE_PATIENT_MED_PROFILE_CLEAR_REQUEST_STATUS";

export const FETCH_SELECTED_USER_ENTRIES_REQUEST_SENT = "FETCH_SELECTED_USER_ENTRIES_REQUEST_SENT";
export const FETCH_SELECTED_USER_ENTRIES_ERROR = "FETCH_SELECTED_USER_ENTRIES_ERROR";
export const FETCH_SELECTED_USER_ENTRIES_SUCCESS = "FETCH_SELECTED_USER_ENTRIES_SUCCESS";
export const RESET_SELECTED_USER_ENTRIES_SUCCESS = "RESET_SELECTED_USER_ENTRIES_SUCCESS";
export const RESET_SELECTED_USER_ENTRIES = "RESET_SELECTED_USER_ENTRIES";
export const NO_MORE_ENTRIES = "NO_MORE_ENTRIES";
export const CLEAR_SELECTED_USER = "CLEAR_SELECTED_USER";

export const FETCH_CHARTS_DATA_REQUEST_SENT = "FETCH_CHARTS_DATA_REQUEST_SENT";
export const FETCH_CHARTS_DATA_SUCCESS = "FETCH_CHARTS_DATA_SUCCESS";
export const FETCH_CHARTS_DATA_ERROR = "FETCH_CHARTS_DATA_ERROR";
export const CHANGE_CHARTS_FILTERS = "CHANGE_CHARTS_FILTERS";
export const FETCH_DASHBOARD_CHARTS_DATA_SUCCESS = "FETCH_DASHBOARD_CHARTS_DATA_SUCCESS";
export const FETCH_DASHBOARD_CHARTS_DATA_ERROR = "FETCH_DASHBOARD_CHARTS_DATA_ERROR";
export const FETCH_SELECTED_USER_LATEST_MEASUREMENTS_SUCCESS = "FETCH_SELECTED_USER_LATEST_MEASUREMENTS_SUCCESS";
export const FETCH_SELECTED_USER_LATEST_MEASUREMENTS_ERROR = "FETCH_SELECTED_USER_LATEST_MEASUREMENTS_ERROR";

export const GET_USER_SETTINGS = "GET_USER_SETTINGS";
export const GET_USER_SETTINGS_ERROR = "GET_USER_SETTINGS_ERROR";
export const CHANGE_USER_SETTINGS_SUCCESS = "CHANGE_USER_SETTINGS_SUCCESS";
export const CHANGE_USER_SETTINGS_ERROR = "CHANGE_USER_SETTINGS_ERROR";

export const GET_NOMENCLATURE_REQUEST_SENT = "GET_NOMENCLATURE_REQUEST_SENT";
export const GET_NOMENCLATURE_SUCCESS = "GET_NOMENCLATURE_SUCCESS";
export const GET_NOMENCLATURE_ERROR = "GET_NOMENCLATURE_ERROR";

export const FETCH_SELECTED_USER_SYMPTOMS_REQUEST_SENT = "FETCH_SELECTED_USER_SYMPTOMS_REQUEST_SENT";
export const FETCH_SELECTED_USER_SYMPTOMS_ERROR = "FETCH_SELECTED_USER_SYMPTOMS_ERROR";
export const FETCH_SELECTED_USER_SYMPTOMS_SUCCESS = "FETCH_SELECTED_USER_SYMPTOMS_SUCCESS";
export const RESET_SELECTED_USER_SYMPTOMS_SUCCESS = "RESET_SELECTED_USER_SYMPTOMS_SUCCESS";
export const RESET_SELECTED_USER_SYMPTOMS = "RESET_SELECTED_USER_SYMPTOMS";
export const NO_MORE_SYMPTOMS = "NO_MORE_SYMPTOMS";

export const DUMMY = "DUMMY";

export const ALL_PERMISSIONS_REQUEST_SENT = "ALL_PERMISSIONS_REQUEST_SENT";
export const ALL_PERMISSIONS_REQUEST_SUCCESS = "ALL_PERMISSIONS_REQUEST_SUCCESS";
export const ALL_PERMISSIONS_REQUEST_ERROR = "ALL_PERMISSIONS_REQUEST_ERROR";
export const PERMISSION_DENY_REQUEST_SUCCESS = "PERMISSION_DENY_REQUEST_SUCCESS";
export const PERMISSION_APPROVE_REQUEST_SUCCESS = "PERMISSION_APPROVE_REQUEST_SUCCESS";
export const PERMISSION_DENY_REQUEST_ERROR = "PERMISSION_DENY_REQUEST_ERROR";
export const PERMISSION_APPROVE_REQUEST_ERROR = "PERMISSION_APPROVE_REQUEST_ERROR";
export const REQUEST_PERMISSION_SUCCESS = "REQUEST_PERMISSION_SUCCESS";
export const REQUEST_PERMISSION_ERROR = "REQUEST_PERMISSION_ERROR";

export const CLEAR_PERMISSIONS = "CLEAR_PERMISSIONS";

export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_ERROR = "GET_USER_PROFILE_ERROR";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_ERROR = "UPDATE_USER_PROFILE_ERROR";
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export const UPDATE_USER_PASSWORD_ERROR = "UPDATE_USER_PASSWORD_ERROR";
export const CLEAR_USER_PASSWORD = "CLEAR_USER_PASSWORD";

export const CREATE_NEW_DATE_RANGE = "CREATE_NEW_DATE_RANGE";

export const UPDATE_DATE_RANGE = "UPDATE_DATE_RANGE";
export const CREATE_NEW_DATE_RANGE_SUCCESS = "CREATE_NEW_DATE_RANGE_SUCCESS";
export const CREATE_NEW_DATE_RANGE_ERROR = "CREATE_NEW_DATE_RANGE_ERROR";
export const GET_DATE_RANGE_SUCCESS = "GET_DATE_RANGE_SUCCESS";
export const GET_DATE_RANGE_ERROR = "GET_DATE_RANGE_ERROR";
export const UPDATE_DATE_RANGE_ERROR = "UPDATE_DATE_RANGE_ERROR";
export const DELETE_DATE_RANGE_SUCCESS = "DELETE_DATE_RANGE_SUCCESS";
export const DELETE_DATE_RANGE_ERROR = "DELETE_DATE_RANGE_ERROR";

export const MANAGEMENT_CREATE_NEW_DATE_RANGE = "MANAGEMENT_CREATE_NEW_DATE_RANGE";
export const MANAGEMENT_CREATE_NEW_DATE_RANGE_ERROR = "MANAGEMENT_CREATE_NEW_DATE_RANGE_ERROR";
export const MANAGEMENT_UPDATE_DATE_RANGE = "MANAGEMENT_UPDATE_DATE_RANGE";
export const MANAGEMENT_UPDATE_DATE_RANGE_ERROR = "MANAGEMENT_UPDATE_DATE_RANGE_ERROR";
export const MANAGEMENT_DELETE_DATE_RANGE = "MANAGEMENT_DELETE_DATE_RANGE";
export const MANAGEMENT_DELETE_DATE_RANGE_ERROR = "MANAGEMENT_DELETE_DATE_RANGE_ERROR";
export const MANAGEMENT_FETCH_APPOINTMENTS_REQUEST_SENT = "MANAGEMENT_FETCH_APPOINTMENTS_REQUEST_SENT";
export const MANAGEMENT_GET_DATE_RANGE_SUCCESS = "MANAGEMENT_GET_DATE_RANGE_SUCCESS";
export const MANAGEMENT_GET_DATE_RANGE_ERROR = "MANAGEMENT_GET_DATE_RANGE_ERROR";
export const MANAGEMENT_CLEAR_DATE_RANGE = "MANAGEMENT_CLEAR_DATE_RANGE";

export const FETCH_PRICE_LIST_REQUEST_SENT = "FETCH_PRICE_LIST_REQUEST_SENT";
export const GET_PRICE_LIST_SUCCESS = "GET_PRICE_LIST_SUCCESS";
export const GET_PRICE_LIST_ERROR = "GET_PRICE_LIST_SUCCESS";
export const CREATE_NEW_CONSULTATION_MENU_ITEM = "CREATE_NEW_CONSULTATION_MENU_ITEM";
export const UPDATE_CONSULTATION_MENU_ITEM = "UPDATE_CONSULTATION_MENU_ITEM";
export const CREATE_NEW_CONSULTATION_MENU_ITEM_SUCCESS = "CREATE_NEW_CONSULTATION_MENU_ITEM_SUCCESS";
export const CREATE_NEW_CONSULTATION_MENU_ITEM_ERROR = "CREATE_NEW_CONSULTATION_MENU_ITEM_ERROR";
export const UPDATE_CONSULTATION_MENU_ITEM_ERROR = "UPDATE_CONSULTATION_MENU_ITEM_ERROR";
export const DELETE_CONSULTATION_MENU_ITEM_SUCCESS = "DELETE_CONSULTATION_MENU_ITEM_SUCCESS";
export const DELETE_CONSULTATION_MENU_ITEM_ERROR = "DELETE_CONSULTATION_MENU_ITEM_ERROR";

export const MANAGEMENT_DELETE_CONSULTATION_MENU_ITEM_SUCCESS = "MANAGEMENT_DELETE_CONSULTATION_MENU_ITEM_SUCCESS";
export const MANAGEMENT_DELETE_CONSULTATION_MENU_ITEM_ERROR = "MANAGEMENT_DELETE_CONSULTATION_MENU_ITEM_ERROR";
export const MANAGEMENT_UPDATE_CONSULTATION_MENU_ITEM_ERROR = "MANAGEMENT_UPDATE_CONSULTATION_MENU_ITEM_ERROR";
export const MANAGEMENT_UPDATE_NEW_CONSULTATION_MENU_ITEM_SUCCESS = "MANAGEMENT_UPDATE_NEW_CONSULTATION_MENU_ITEM_SUCCESS";
export const MANAGEMENT_CREATE_NEW_CONSULTATION_MENU_ITEM_SUCCESS = "MANAGEMENT_CREATE_NEW_CONSULTATION_MENU_ITEM_SUCCESS";
export const MANAGEMENT_CREATE_NEW_CONSULTATION_MENU_ITEM_ERROR = "MANAGEMENT_CREATE_NEW_CONSULTATION_MENU_ITEM_ERROR";

export const FETCH_SELECTED_USER_DOCUMENTS_REQUEST_SENT = "FETCH_SELECTED_USER_DOCUMENTS_REQUEST_SENT";
export const FETCH_SELECTED_USER_DOCUMENTS_SUCCESS = "FETCH_SELECTED_USER_DOCUMENTS_SUCCESS";
export const FETCH_SELECTED_USER_DOCUMENTS_ERROR = "FETCH_SELECTED_USER_DOCUMENTS_ERROR";
export const NO_MORE_SELECTED_USER_DOCUMENTS = "NO_MORE_SELECTED_USER_DOCUMENTS";
export const CHANGE_DOCUMENTS_FILTERS = "CHANGE_DOCUMENTS_FILTERS";
export const RESET_SELECTED_USER_DOCUMENTS_SUCCESS = "RESET_SELECTED_USER_DOCUMENTS_SUCCESS";
export const FETCH_LOGGED_USER_DOCUMENTS_REQUEST_SENT = "FETCH_LOGGED_USER_DOCUMENTS_REQUEST_SENT";
export const FETCH_LOGGED_USER_DOCUMENTS_SUCCESS = "FETCH_LOGGED_USER_DOCUMENTS_SUCCESS";
export const FETCH_LOGGED_USER_DOCUMENTS_ERROR = "FETCH_LOGGED_USER_DOCUMENTS_ERROR";
export const UPLOAD_LOGGED_USER_DOCUMENT_REQUEST_SENT = "UPLOAD_LOGGED_USER_DOCUMENT_REQUEST_SENT";
export const UPLOAD_LOGGED_USER_DOCUMENT_SUCCESS = "UPLOAD_LOGGED_USER_DOCUMENTS_SUCCESS";
export const UPLOAD_LOGGED_USER_DOCUMENT_ERROR = "UPLOAD_LOGGED_USER_DOCUMENT_ERROR";
export const DELETE_LOGGED_USER_DOCUMENT_REQUEST_SENT = "DELETE_LOGGED_USER_DOCUMENT_REQUEST_SENT";
export const DELETE_LOGGED_USER_DOCUMENT_SUCCESS = "DELETE_LOGGED_USER_DOCUMENTS_SUCCESS";
export const DELETE_LOGGED_USER_DOCUMENT_ERROR = "DELETE_LOGGED_USER_DOCUMENT_ERROR";
export const NO_MORE_LOGGED_USER_DOCUMENTS = "NO_MORE_LOGGED_USER_DOCUMENTS";
export const RESET_LOGGED_USER_DOCUMENTS_SUCCESS = "RESET_LOGGED_USER_DOCUMENTS_SUCCESS";

export const FETCH_SELECTED_USER_MEDICAL_PROFILE_SUCCESS = "FETCH_SELECTED_USER_MEDICAL_PROFILE_SUCCESS";
export const FETCH_SELECTED_USER_MEDICAL_PROFILE_ERROR = "FETCH_SELECTED_USER_MEDICAL_PROFILE_ERROR";

export const FETCH_CHAT_MESSAGES_REQUEST_SENT = "FETCH_CHAT_MESSAGES_REQUEST_SENT";
export const FETCH_CHAT_MESSAGES_REQUEST_SUCCESS = "FETCH_CHAT_MESSAGES_REQUEST_SUCCESS";
export const FETCH_CHAT_MESSAGES_REQUEST_ERROR = "FETCH_CHAT_MESSAGES_REQUEST_ERROR";
export const SEND_MESSAGE_TO_USER = "SEND_MESSAGE_TO_USER"
export const NO_MORE_MESSAGES = "NO_MORE_MESSAGES";
export const CLEAR_CHAT = "CLEAR_CHAT";
export const FETCH_CHAT_UNREAD_MESSAGES_COUNT_SUCCESS = "FETCH_CHAT_UNREAD_MESSAGES_COUNT_SUCCESS";
export const FETCH_CHAT_UNREAD_MESSAGES_COUNT_ERROR = "FETCH_CHAT_UNREAD_MESSAGES_COUNT_ERROR";
export const SET_MESSAGE_AS_SEEN = "SET_MESSAGE_AS_SEEN";
export const NEW_MESSAGE = "NEW_MESSAGE";
export const NEW_MESSAGE_FROM_SOCKET_ARRIVED = "NEW_MESSAGE_FROM_SOCKET_ARRIVED";

export const FETCH_SELECTED_USER_MEDICATIONS_SUCCESS = "FETCH_SELECTED_USER_MEDICATIONS_SUCCESS";
export const FETCH_SELECTED_USER_MEDICATIONS_ERROR = "FETCH_SELECTED_USER_MEDICATIONS_ERROR";

export const FETCH_SELECTED_USER_GENERAL_PRACTITIONER_SUCCESS = "FETCH_SELECTED_USER_GENERAL_PRACTITIONER_SUCCESS";
export const FETCH_SELECTED_USER_GENERAL_PRACTITIONER_ERROR = "FETCH_SELECTED_USER_GENERAL_PRACTITIONER_ERROR";

export const SEARCH_ICD_REQUEST_SENT = "SEARCH_ICD_REQUEST_SENT";
export const SEARCH_ICD_SUCCESS = "SEARCH_ICD_SUCCESS";
export const SEARCH_ICD_ERROR = "SEARCH_ICD_ERROR";
export const CLEAR_ICD = "CLEAR_ICD";


export const FETCH_SELECTED_USER_LAB_RESULTS_REQUEST_SENT = "FETCH_SELECTED_USER_LAB_RESULTS_REQUEST_SENT";
export const FETCH_SELECTED_USER_LAB_RESULTS_SUCCESS = "FETCH_SELECTED_USER_LAB_RESULTS_SUCCESS";
export const FETCH_SELECTED_USER_LAB_RESULTS_ERROR = "FETCH_SELECTED_USER_LAB_RESULTS_ERROR";
export const NO_MORE_SELECTED_USER_LAB_RESULTS = "NO_MORE_SELECTED_USER_LAB_RESULTS";
export const CHANGE_LAB_RESULTS_FILTERS = "CHANGE_LAB_RESULTS_FILTERS";
export const RESET_SELECTED_USER_LAB_RESULTS_SUCCESS = "RESET_SELECTED_USER_LAB_RESULTS_SUCCESS";
export const FETCH_LABORATORIES_REQUEST_SENT = "FETCH_LABORATORIES_REQUEST_SENT";
export const FETCH_LABORATORIES_SUCCESS = "FETCH_LABORATORIES_SUCCESS";
export const FETCH_LABORATORIES_ERROR = "FETCH_LABORATORIES_ERROR";

export const FETCH_APPOINTMENTS_REQUEST_SENT = "FETCH_APPOINTMENTS_REQUEST_SENT";
export const FETCH_APPOINTMENTS_SUCCESS = "FETCH_APPOINTMENTS_SUCCESS";
export const FETCH_APPOINTMENTS_ERROR = "FETCH_APPOINTMENTS_ERROR";
export const FETCH_USER_APPOINTMENTS_REQUEST_SENT = "FETCH_USER_APPOINTMENTS_REQUEST_SENT";
export const FETCH_USER_APPOINTMENTS_SUCCESS = "FETCH_USER_APPOINTMENTS_SUCCESS";
export const FETCH_USER_APPOINTMENTS_ERROR = "FETCH_USER_APPOINTMENTS_ERROR";
export const FETCH_LAST_APPOINTMENT_REQUEST_SENT = "FETCH_LAST_APPOINTMENT_REQUEST_SENT";
export const CLEAR_USER_APPOINTMENTS = "CLEAR_USER_APPOINTMENTS";
export const FETCH_LAST_APPOINTMENT_SUCCESS = "FETCH_LAST_APPOINTMENT_SUCCESS";
export const FETCH_LAST_APPOINTMENT_ERROR = "FETCH_LAST_APPOINTMENT_ERROR";
export const FETCH_APPOINTMENT_USER_REQUEST_SENT = "FETCH_APPOINTMENT_USER_REQUEST_SENT";
export const FETCH_APPOINTMENT_USER_SUCCESS = "FETCH_APPOINTMENT_USER_SUCCESS";
export const FETCH_APPOINTMENT_USER_ERROR = "FETCH_APPOINTMENT_USER_ERROR";
export const SELECT_APPOINTMENT = "SELECT_APPOINTMENT";
export const CLEAR_SELECTED_APPOINTMENT = "CLEAR_SELECTED_APPOINTMENT";
export const FETCH_USER_NOTES_REQUEST_SENT = "FETCH_USER_NOTES_REQUEST_SENT";
export const FETCH_USER_NOTES_SUCCESS = "FETCH_USER_NOTES_SUCCESS";
export const FETCH_USER_NOTES_ERROR = "FETCH_USER_NOTES_ERROR";
export const UPDATE_NOTE_REQUEST_SENT = "UPDATE_NOTE_REQUEST_SENT";
export const UPDATE_NOTE_REQUEST_ERROR = "UPDATE_NOTE_REQUEST_ERROR";
export const DELETE_NOTE_REQUEST_SENT = "DELETE_NOTE_REQUEST_SENT";
export const DELETE_NOTE_REQUEST_ERROR = "DELETE_NOTE_REQUEST_ERROR";
export const CREATE_NOTE_REQUEST_SENT = "CREATE_NOTE_REQUEST_SENT";
export const CREATE_NOTE_REQUEST_ERROR = "CREATE_NOTE_REQUEST_ERROR";

export const SELECT_APPOINTMENT_IN_PROGRESS = "SELECT_APPOINTMENT_IN_PROGRESS";
export const CLEAR_SELECTED_APPOINTMENT_IN_PROGRESS = "CLEAR_SELECTED_APPOINTMENT_IN_PROGRESS";
export const CREATE_ENCOUNTER_REQUEST_SENT = "CREATE_ENCOUNTER_REQUEST_SENT";
export const CREATE_ENCOUNTER_REQUEST_SUCCESS = "CREATE_ENCOUNTER_REQUEST_SUCCESS";
export const CREATE_ENCOUNTER_REQUEST_ERROR = "CREATE_ENCOUNTER_REQUEST_ERROR";
export const UPDATE_ENCOUNTER_REQUEST_SENT = "UPDATE_ENCOUNTER_REQUEST_SENT";
export const UPDATE_ENCOUNTER_REQUEST_SUCCESS = "UPDATE_ENCOUNTER_REQUEST_SUCCESS";
export const UPDATE_ENCOUNTER_REQUEST_ERROR = "UPDATE_ENCOUNTER_REQUEST_ERROR";
export const CLEAR_SELECTED_ENCOUNTER = "CLEAR_SELECTED_ENCOUNTER";

export const FETCH_PROVIDER_APPOINTMENTS_STATS_SUCCESS = "FETCH_PROVIDER_APPOINTMENTS_STATS_SUCCESS";
export const FETCH_PROVIDER_APPOINTMENTS_STATS_ERROR = "FETCH_PROVIDER_APPOINTMENTS_STATS_ERROR";
export const CLEAR_PROVIDER_APPOINTMENTS = "CLEAR_PROVIDER_APPOINTMENTS";
export const UPDATE_APPOINTMENT_STATUS_REQUEST_SENT = "UPDATE_APPOINTMENT_STATUS_REQUEST_SENT";
export const UPDATE_APPOINTMENT_STATUS_REQUEST_ERROR = "UPDATE_APPOINTMENT_STATUS_REQUEST_ERROR";
export const CHANGE_APPOINTMENT_IN_APPOINTMENTS_LIST = "CHANGE_APPOINTMENT_IN_APPOINTMENTS_LIST";

export const UPDATE_CALL_DATA = "UPDATE_CALL_DATA";
export const UPDATE_CAMERA_DEVICES = "UPDATE_CAMERA_DEVICES";
export const UPDATE_SELECTED_CAMERA = "UPDATE_SELECTED_CAMERA";

export const GET_NOTIFICATIONS_REQUEST_SENT = "GET_NOTIFICATIONS_REQUEST_SENT";
export const GET_NOTIFICATIONS_REQUEST_SUCCESS = "GET_NOTIFICATIONS_REQUEST_SUCCESS";
export const GET_MORE_NOTIFICATIONS_REQUEST_SUCCESS = "GET_MORE_NOTIFICATIONS_REQUEST_SUCCESS";
export const GET_NOTIFICATIONS_REQUEST_ERROR = "GET_NOTIFICATIONS_REQUEST_ERROR";
export const UPDATE_NOTIFICATION_STATUS_REQUEST_SENT = "UPDATE_NOTIFICATION_STATUS_REQUEST_SENT";
export const UPDATE_NOTIFICATION_STATUS_REQUEST_SUCCESS = "UPDATE_NOTIFICATION_STATUS_REQUEST_SUCCESS";
export const UPDATE_NOTIFICATION_STATUS_REQUEST_ERROR = "UPDATE_NOTIFICATION_STATUS_REQUEST_ERROR";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";

export const FETCH_SPECIALTIES_REQUEST_SENT = "FETCH_SPECIALTIES_REQUEST_SENT";
export const FETCH_SPECIALTIES_SUCCESS = "FETCH_SPECIALTIES_SUCCESS";
export const FETCH_SPECIALTIES_ERROR = "FETCH_SPECIALTIES_ERROR";

export const PROCESS_PROVIDER_IMAGE = "PROCESS_PROVIDER_IMAGE";

export const DISPLAY_PROVIDER_IMAGE = "DISPLAY_PROVIDER_IMAGE";


export const SET_USER_SELECTIONS = "SET_USER_SELECTIONS";

export const SET_FORM_TO_DISABLED = "SET_FORM_TO_DISABLED";
export const SET_FORM_TO_ENABLED = "SET_FORM_TO_ENABLED";

export const SETTINGS = "SETTINGS";
export const NOTES = "NOTES";
export const PRICES = "PRICES";
export const PROFILE = "PROFILE";
export const CONFIGURATION = "CONFIGURATION";
export const CHAT_SETTINGS = "CHAT_SETTINGS";


export const FETCH_SELECTED_USER_CARDIAC_STATS_REQUEST_SENT = "FETCH_SELECTED_USER_CARDIAC_STATS_REQUEST_SENT";
export const FETCH_SELECTED_CARDIAC_STATS_SUCCESS = "FETCH_SELECTED_CARDIAC_STATS_SUCCESS";
export const FETCH_SELECTED_CARDIAC_STATS_ERROR = "FETCH_SELECTED_CARDIAC_STATS_ERROR";
export const RESET_SELECTED_CARDIAC_STATS_SUCCESS = "RESET_SELECTED_CARDIAC_STATS_SUCCESS";

export const CREATE_PRESCRIPTION_ERROR = "CREATE_PRESCRIPTION_ERROR";
export const CREATE_PRESCRIPTION_SUCCESS = "CREATE_PRESCRIPTION_SUCCESS";
export const CREATE_PRESCRIPTION_REQUEST_SENT = "CREATE_PRESCRIPTION_REQUEST_SENT";

export const PATIENT_REGISTER_SUCCESS = "PATIENT_REGISTER_SUCCESS";
export const PATIENT_REGISTER_ERROR = "PATIENT_REGISTER_ERROR";
export const PATIENT_REGISTER_CLEAR = "PATIENT_REGISTER_CLEAR";

export const CHANGE_APPOINTMENT_IN_USER_APPOINTMENTS_LIST = "CHANGE_APPOINTMENT_IN_USER_APPOINTMENTS_LIST";

export const FETCH_FIRST_AVAILABLE_REQUEST_SENT = "FETCH_FIRST_AVAILABLE_REQUEST_SENT"
export const FETCH_FIRST_AVAILABLE_SUCCESS = "FETCH_FIRST_AVAILABLE_SUCCESS"
export const FETCH_FIRST_AVAILABLE_ERROR = "FETCH_FIRST_AVAILABLE_ERROR"
export const FETCH_PROVIDER_TIMETABLE_REQUEST_SENT = "FETCH_PROVIDER_TIMETABLE_REQUEST_SENT"
export const FETCH_PROVIDER_TIMETABLE_SUCCESS = "FETCH_PROVIDER_TIMETABLE_SUCCESS"
export const FETCH_PROVIDER_TIMETABLE_ERROR = "FETCH_PROVIDER_TIMETABLE_ERROR"

export const FETCH_REVENUE_REQUEST_SENT = "FETCH_REVENUE_REQUEST_SENT"
export const FETCH_REVENUE_SUCCESS = "FETCH_REVENUE_SUCCESS"
export const FETCH_REVENUE_ERROR = "FETCH_REVENUE_ERROR"
export const FETCH_REVENUE_DETAILS_REQUEST_SENT = "FETCH_REVENUE_DETAILS_REQUEST_SENT"
export const FETCH_REVENUE_DETAILS_REQUEST_SUCCESS = "FETCH_REVENUE_DETAILS_REQUEST_SUCCESS"
export const FETCH_REVENUE_DETAILS_REQUEST_ERROR = "FETCH_REVENUE_DETAILS_REQUEST_ERROR"

export const CHANGE_PROVIDER_CONFIGURATION_ERROR = "CHANGE_PROVIDER_CONFIGURATION_ERROR";
export const CHANGE_PROVIDER_CONFIGURATION_SUCCESS = "CHANGE_PROVIDER_CONFIGURATION_SUCCESS";
export const GET_PROVIDER_CONFIGURATION = "GET_PROVIDER_CONFIGURATION";
export const GET_PROVIDER_CONFIGURATION_ERROR = "GET_PROVIDER_CONFIGURATION_ERROR";
export const SELECT_GROUP_APPOINTMENT = "SELECT_GROUP_APPOINTMENT";
export const CLEAR_GROUP_APPOINTMENT = "CLEAR_GROUP_APPOINTMENT";

export const GET_PROVIDER_CHAT_SETTINGS = "GET_PROVIDER_CHAT_SETTINGS";
export const CHANGE_PROVIDER_CHAT_SETTINGS_SUCCESS = "CHANGE_PROVIDER_CHAT_SETTINGS_SUCCESS";
export const GET_PROVIDER_CHAT_SETTINGS_ERROR = "GET_PROVIDER_CHAT_SETTINGS_ERROR";
export const CHANGE_PROVIDER_CHAT_SETTINGS_ERROR = "CHANGE_PROVIDER_CHAT_SETTINGS_ERROR";
export const SAVE_APPOINTMENT_FILTERS = "SAVE_APPOINTMENT_FILTERS";
export const RESTORE_APPOINTMENT_FILTERS = "RESTORE_APPOINTMENT_FILTERS";
export const CLEAR_APPOINTMENT_FILTERS = "CLEAR_APPOINTMENT_FILTERS";