import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import {LOG_ENTRY, SYMPTOMS_ENTRY} from '../../constants/information-retrieval-types'
import {entry_types_array, entryTypeToStringKey, MEDICATION} from '../../constants/entry_types'
import VitalsLogbookEntry from './VitalsLogbookEntry'
import no_vitals_logbook from '../../resources/images/no_vitals_logbook.png'
import {infoUtils} from '../../utils/infoUtils'
import moment from 'moment'
import _ from 'underscore'
import Select from "../shared/Select";
import PropTypes from "prop-types";


export class VitalsLogbook extends Component {
    state = {
        selectedVitalLogbook: {},
        logbook_entry_type: 'ALL',
        only_show_symptoms: false,
        loading: true
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.selectedUser && this.props.selectedUser.id && this.props.selectedFilters.vitalsLogbookTab.logbook_entry_type === "ALL") {
            let params = {
                before_date_time: moment().valueOf(),
                size: 500
            };

            this.prepareParams({...params}, {...params}, true);
        } else {
            this.setState({
                logbook_entry_type: this.props.selectedFilters.vitalsLogbookTab.logbook_entry_type,
                loading: false
            })
        }
    }

        /**
         * Prepares the params to be sent along with the request of fetching logbook and symptoms entries
         *
         * @param {object} logbookParams - the logbook params object
         * @param {object} symptomsParams - the symptoms params object
         * @param {boolean} resetList - variable
         */
        prepareParams = async (logbookParams, symptomsParams, resetList) => {
            let healthIssueIds = this.props.selectedUser.health_issues.map(h => h.id);
            //if (healthIssueIds.length > 0) {
            logbookParams.healthIssueIds = healthIssueIds;
            logbookParams.types = [LOG_ENTRY];
            symptomsParams.healthIssueIds = healthIssueIds;
            symptomsParams.types = [SYMPTOMS_ENTRY];

            this.setState({loading: true})
            if (this.state.logbook_entry_type === 'ALL') {
                await this.props.fetchLogbookEntries(this.props.selectedUser.id, logbookParams, resetList).then(() => {
                    this.setState({loading: false})
                });
                this.props.fetchSymptomsEntries(this.props.selectedUser.id, symptomsParams, resetList)
            } else if (!this.state.only_show_symptoms) {
                await this.props.fetchLogbookEntries(this.props.selectedUser.id, logbookParams, resetList).then(() => {
                    this.setState({loading: false})
                });
                this.props.resetSymptomsEntries()
            } else {
                await this.props.fetchSymptomsEntries(this.props.selectedUser.id, symptomsParams, resetList).then(() => {
                    this.setState({loading: false})
                });
                this.props.resetLogbookEntries();
            }
            //}
        }

        /**
         * Prepares the grouping of vital logbook based on the date_time property of symptoms and logbook
         *
         * @returns {Array} an array of objects containing the grouped logbook and symptoms
         * based on date_time property
         */
        getGroupedVitalsLogbook = () => {
            if (!this.state.loading && ((this.props.logbook && this.props.logbook.entries) ||
                (this.props.symptoms && this.props.symptoms.entries))) {

                let groupedVitalsLogbook = this.manipulateVitalsLogbookData();
                let result = this.prepareVitalsLogbookData(groupedVitalsLogbook);
                let sortedResult = this.getSortedVitalsLogbookData(result);
                return this.getGroupedVitalsLogbookEntries(sortedResult);
            }
        }

        /**
         * Manipulates the data in order to make them ready for preparation
         *
         * @returns {Dictionary} vitals logbook data gruped by date_time property
         */
        manipulateVitalsLogbookData = () => {
            let logbook = this.props.logbook && this.props.logbook.entries ? this.props.logbook.entries.map(l =>
                l = {...l, type: LOG_ENTRY}
            ) : [];

            let symptoms = this.props.symptoms && this.props.symptoms.entries ? this.props.symptoms.entries.map(s =>
                s = {...s, type: SYMPTOMS_ENTRY}
            ) : [];

            let vitalsLogbook = logbook.concat(symptoms);
            return _.groupBy(vitalsLogbook, (result) => moment(result.date_time).format('YYYY-MM-DD'));
        }

        /**
         * Prepares the vitals logbook data to make them ready for display
         *
         * @param {Dictionary} groupedVitalsLogbook - a dictionary key (date time), value (symptoms and logbook arrays)
         * @returns {Array} prepared vitals logbook data
         */
        prepareVitalsLogbookData = (groupedVitalsLogbook) => {
            let result = [];

            for (let date in groupedVitalsLogbook) {
                let vitals = {
                    date: date,
                    logbook: [],
                    symptoms: []
                };

                for (let i = 0; i < groupedVitalsLogbook[date].length; ++i) {
                    if (groupedVitalsLogbook[date][i].type === LOG_ENTRY) {
                        vitals.logbook.push(groupedVitalsLogbook[date][i]);
                    } else {
                        vitals.symptoms.push(groupedVitalsLogbook[date][i]);
                    }
                }

                result.push(vitals);
            }
            return result;
        }

        /**
         * Sorts the vitals logbook data in descending order
         *
         * @param {Array} result - prepared vitals logbook data
         * @returns {Array} sorted vitals logbook data
         */
        getSortedVitalsLogbookData = (result) => {
            return result.sort(function (a, b) {
                return new Date(b.date).getTime() - new Date(a.date).getTime()
            });
        }

        /**
         * Prepares the logbook entries that are going to display the symptoms and logbook per day
         * along with health issue names list
         *
         * @param {Array} result - an array of objects containing the grouped logbook and symptoms
         * based on date_time property
         */
        getGroupedVitalsLogbookEntries = (result) => {
            if (result && result.length > 0) {
                return result.map((r, idx) => {
                    let medications = r.logbook.filter(l => l.entry_type === MEDICATION);
                    return <VitalsLogbookEntry
                        key={idx}
                        isFirst={idx === 0}
                        entry={r}
                        date_time={r.date}
                        logbook={r.logbook.filter(l => l.entry_type !== MEDICATION)}
                        symptoms={r.symptoms}
                        healthIssues={this.props.selectedUser.health_issues.map(h => h.name)}
                        medications={medications}
                        selectedVitalLogbook={this.state.selectedVitalLogbook}
                        selectVitalLogbook={this.selectVitalLogbook}
                        nomenclature={this.props.nomenclature}
                        settings={this.props.settings}
                        i18n={this.props.i18n}
                        inline={this.state.logbook_entry_type !== 'ALL' || this.state.only_show_symptoms}
                    />
                });
            } else if (this.props.logbook.request.finished || this.props.symptoms.request.finished || this.props.selectedUser.health_issues.length === 0) {
                let noVitalsLogbooksObj = {
                    imgClass: 'no-vitals-logbook-img',
                    primaryLabelClass: 'no-lab-results-primary-label',
                    secondaryLabelClass: 'no-lab-results-secondary-label',
                    src: no_vitals_logbook,
                    primaryLabel: $$('vitals_logbook_no_data_primary_label'),
                    secondaryLabel: ''
                }
                return infoUtils.noData(noVitalsLogbooksObj);
            }
        }

        /**
         * Fetch logbook entries function used for the infinite scroll
         */
        onLoadMore = (e) => {
            e.preventDefault();
            e.stopPropagation();
            let logbookParams, symptomsParams;
            if (this.props.selectedUser &&
                this.props.selectedUser.id &&
                (this.props.logbook.request.finished === undefined || this.props.logbook.request.finished) &&
                (this.props.symptoms.request.finished === undefined || this.props.symptoms.request.finished)) {
                if ((this.props.logbook.entries && this.props.logbook.entries.length > 0) ||
                    (this.props.symptoms.entries && this.props.symptoms.entries.length > 0)) {
                    const logbookEntriesLength = this.props.logbook.entries.length;
                    const symptomsEntriesLength = this.props.symptoms.entries.length;
                    const logbook_before_date_time = logbookEntriesLength > 0 ? this.props.logbook.entries[logbookEntriesLength - 1].date_time : moment.valueOf();
                    const symptoms_before_date_time = symptomsEntriesLength > 0 ? this.props.symptoms.entries[symptomsEntriesLength - 1].date_time : moment.valueOf();

                    logbookParams = {
                        before_date_time: logbook_before_date_time,
                        size: 500
                    };

                    if (this.state.logbook_entry_type !== 'ALL' && !this.state.only_show_symptoms) {
                        logbookParams.logbook_entry_type = this.state.logbook_entry_type;
                    }

                    symptomsParams = {
                        before_date_time: symptoms_before_date_time,
                        size: 500
                    }
                }

                this.prepareParams(logbookParams, symptomsParams, false);
            }
        }

        /**
         * Selects a specific vital logbook
         *
         * @param {object} entry - the selected vital logbook
         */
        selectVitalLogbook = (entry) => {
            let selectedVitalLogbook = entry;
            this.setState({selectedVitalLogbook});
        }

        onChangeRadioValue = ({value}) => {
            let params = {
                before_date_time: moment().valueOf(),
                size: 500,
            };

            if (value !== 'ALL' && value !== 'ShowSymptoms') {
                params.logbook_entry_type = value;
            }
            this.setState({
                logbook_entry_type: value,
                only_show_symptoms: value === 'ShowSymptoms'
            }, () => this.prepareParams({...params}, {...params}, true));

            let filters = this.props.selectedFilters;
            filters.vitalsLogbookTab.logbook_entry_type = value;
            this.props.updateFilters(filters);


        }

        render()
        {
            let groupedVitalLogbooks = this.getGroupedVitalsLogbook();
            const hasMore = (!this.props.logbook.isLastPage &&
                    (this.props.logbook.request.finished === undefined || this.props.logbook.request.finished) &&
                    !this.props.logbook.request.error) &&
                (!this.props.symptoms.isLastPage &&
                    (this.props.symptoms.request.finished === undefined || this.props.symptoms.request.finished) &&
                    !this.props.symptoms.request.error) &&
                this.props.selectedUser.health_issues.length > 0 &&
                groupedVitalLogbooks !== undefined &&
                groupedVitalLogbooks.length > 0;

            return (
                <div ref={(ref) => this.listRef = ref}>
                    <div className='lab-results-filters-container'>
                        <div className='lab-results-filters-select-container'>
                            <Select value={this.state.logbook_entry_type}
                                    name="entryTypeSelect"
                                    options={[{text: $$("all"), value: 'ALL'}].concat(entry_types_array.map((t) => {
                                        return {text: $$(entryTypeToStringKey[t]), value: t}
                                    }))
                                        .concat([{
                                            text: '\u2015\u2015\u2015\u2015\u2015\u2015\u2015\u2015\u2015\u2015\u2015',
                                            disabled: true,
                                            value: ''
                                        }, {text: $$("Symptoms"), value: 'ShowSymptoms'}])}
                                    onChange={this.onChangeRadioValue}
                            />
                        </div>
                    </div>

                    {this.state.loading && <div className={"report-loader"}/>}
                    <div className="list-group full-width">
                        {groupedVitalLogbooks}
                    </div>
                    {hasMore &&
                        <div className="text-center margin-top-ten">
                            <a href="#" onClick={this.onLoadMore}>{$$('load_more')}</a>
                        </div>
                    }
                </div>
            )
        }
    }

    VitalsLogbook.propTypes = {
        fetchLogbookEntries: PropTypes.func,
        fetchSymptomsEntries: PropTypes.func,
        i18n: PropTypes.object,
        isFinished: PropTypes.bool,
        logbook: PropTypes.object,
        nomenclature: PropTypes.object,
        resetLogbookEntries: PropTypes.func,
        resetSymptomsEntries: PropTypes.func,
        selectedUser: PropTypes.object,
        settings: PropTypes.object,
        selectedFilters: PropTypes.object,
        updateFilters: PropTypes.func,
        symptoms: PropTypes.object
    }


    export
    default
    VitalsLogbook