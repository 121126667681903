import React, {useEffect, useRef, useState} from "react";
import BackButton from "../shared/BackButton";
import {$$} from "../../helpers/localization";
import {BoolSwitch} from "../examination/nhis/bg/BoolSwitch";
import {FG, Input, InputTextArea} from "../examination/nhis/bg/HelperFormComponents";
import {fetchSpecialties} from "../../service/users_service";
import {nhisGetNomenclatures} from "../../service/nhis_service";
import {languageOptions} from "../../constants/languages";
import CustomTagsInput from "../shared/CustomTagsInput";
import {SearchableSelectAsync} from "../examination/nhis/bg/SearchableSelect";

export function UpdateStaffRole(
    {
        user,
        org_id,
        lang,
        onUpdate,
        onBack,
        canRemoveAdmin,
        loggedInUserId
    }) {

    const [role, setRole] = useState(user.organizations.find(o => o.id === org_id).role);
    const [visibility, setVisibility] = useState(user.organizations.find(o => o.id === org_id).visible);
    const [internal, setInternal] = useState(user.internal);

    const existingRoleIsAdmin = user.organizations.find(o => o.id === org_id).role === 'ADMIN';
    const isCurrentUser = user.id === loggedInUserId;
    const selectedRoleIsNotAdmin = role !== 'ADMIN';

    return <div className="patient-card">
        <div className="patient-card-header">

            <h2 className="card-title card-header-title"><BackButton onClick={onBack} title={$$("back")}/>
                &nbsp;&nbsp;{$$("staff_management.update_staff_member_title")} {user.fullname}</h2>

        </div>
        <div className="card-body">
            <div style={{maxWidth: "600px"}}>
                <div className="low-shadow-container">
                    <label>{$$("staff_management.give_administrative_rights")}</label>
                    <BoolSwitch checked={role === "ADMIN" || false}
                                disabledOff={!canRemoveAdmin}
                                onChange={() => {
                                    let existingRole = user.organizations.find(o => o.id === org_id).role;
                                    if (role === "ADMIN") {
                                        if (existingRole === "ADMIN") {
                                            if (internal) {
                                                setRole("REGISTRATION_DESK")
                                            } else {
                                                setRole("CLINICIAN")
                                            }
                                        } else {
                                            setRole(existingRole);
                                        }
                                    } else {
                                        setRole("ADMIN")
                                    }
                                }} labelOff={$$("nhis.no_label")} labelOn={$$("nhis.yes_label")} offFirst={false}/>
                    {!canRemoveAdmin && <p className={"mt-2"}>
                        {$$("staff_management.cant_remove_admin")}
                    </p>}
                    {isCurrentUser && canRemoveAdmin && existingRoleIsAdmin && selectedRoleIsNotAdmin && <p className="text-danger mt-2">
                        {$$("staff_management.lose_staff_management_rights")}
                    </p>}
                </div>

                {internal && role !== 'ADMIN' && <div className="low-shadow-container mt-3">
                    <label>{$$("staff_management.has_a_role")}</label>
                    <BoolSwitch checked={role === "REGISTRATION_DESK" || false} onChange={() => {
                        let b = role === "LAB_ASSISTANT" ? "REGISTRATION_DESK" : "LAB_ASSISTANT";
                        setRole(b);
                    }} labelOff={$$("ORG_ROLE_LAB_ASSISTANT")} labelOn={$$("ORG_ROLE_REGISTRATION_DESK")}
                                offFirst={false}/>
                </div>}

                {!internal && <div className="low-shadow-container mt-3">
                    <label>{$$("staff_management.is_visible_to_patients")}</label>
                    <BoolSwitch checked={visibility || false} onChange={() => {
                        let b = !visibility;
                        setVisibility(b);
                    }} labelOff={$$("nhis.no_label")} labelOn={$$("nhis.yes_label")} offFirst={false}/>
                </div>}

                <div className="mt-3 text-right">
                    <button type="button" className="btn btn-primary" onClick={() => {
                        onUpdate({
                            visibility,
                            internal,
                            role,
                            user
                        })
                    }}>{$$("save_btn_label")}</button>
                    <button type="button" className="btn btn-danger ml-2" onClick={onBack}>{$$("cancel_btn")}</button>
                </div>
            </div>
        </div>
    </div>
}

export function EditStaffProfile({user, onSave, onCancel, lang}) {
    const [formClass, setFormClass] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState(user.email);
    const [title, setTitle] = useState(user.title || "");
    const [contact_email, setContactEmail] = useState(user.contact_email);
    const [phone_numbers, setPhone_numbers] = useState(user.phone_numbers || []);
    const [specialties, setSpecialties] = useState(user.specialties || []);
    const [nhisSpecialtyOptions, setNhisSpecialtyOptions] = useState([]);
    const [nhis_specialties, setNhisSpecialties] = useState(user.nhis_specialties || []);
    const [uin, setUin] = useState(user.uin);
    const [languages, setLanguages] = useState(user.languages || []);
    const [other_info, setOtherInfo] = useState(user.other_info || "");
    const [specialtyOptions, setSpecialtyOptions] = useState([])
    const [specialtyRawOptions, setSpecialtyRawOptions] = useState([])
    const [langOptions, setLangOptions] = useState([])
    const [existingEmailError, setExistingEmailError] = useState(false);


    useEffect(() => {
        if (!user) {
            return;
        }
        let names = user.fullname.split(" ");
        setFirstName(names[0]);
        if (names.length > 1) {
            setLastName(names.slice(1).join(" "));
        }

    }, [user])

    useEffect(() => {
        fetchSpecialties().then((res) => {
            setSpecialtyRawOptions(res);
        });
        nhisGetNomenclatures(["CL006"]).then((res) => {
            const items = res[0].items;
            let propNames = Object.getOwnPropertyNames(items).sort();
            let arr = propNames.map(prop => ({
                value: prop,
                label: prop + " " + items[prop].description,
                text: items[prop].description,
                item: items[prop]
            })).filter(o => o.item.meta.role?.includes('Лекари') || o.value === "0000");
            setNhisSpecialtyOptions(arr)
        });
    }, [])

    useEffect(() => {
        setLangOptions(languageOptions(lang).map(o => ({...o, label: o.text})));
    }, [lang])

    useEffect(() => {
        setSpecialtyOptions(getSpecialtiesOptions(specialtyRawOptions));
    }, [lang, specialtyRawOptions])

    const onFormSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.target;
        if (!form.checkValidity()) {
            setFormClass("was-validated");
            return;
        }
        let updatedData = {};
        updatedData.fullname = `${firstName} ${lastName}`;
        updatedData.email = email.trim();

        if (!user.internal) {
            updatedData = {
                ...updatedData,
                title,
                contact_email,
                phone_numbers: phone_numbers,
                specialties,
                nhis_specialties,
                uin,
                languages,
                other_info
            }
        }

        onSave(updatedData)
            .catch(e => {
                if (e.status === 403 && e.message === "Username already exists!") {
                    form.elements["email-input"].setCustomValidity("email-exists")
                    setFormClass("was-validated");
                    setExistingEmailError(true)
                }
            });
    }

    return <div className="patient-card">
        <div className="patient-card-header">

            <h2 className="card-title card-header-title"><BackButton onClick={onCancel} title={$$("back")}/>
                &nbsp;&nbsp;{$$("staff_management.update_staff_member_title")} {user.fullname}</h2>

        </div>
        <div className="card-body">
            <div className="nhis-ereferral add-staff-form" style={{maxWidth: "600px"}}>
                <form noValidate={true} className={`has-custom-validation ${formClass}`} onSubmit={onFormSubmit}>

                    <FG>
                        <Input label={"nhis.patient.first_name_label"} value={firstName} required={true}
                               setValue={setFirstName} autoComplete="new-password"/>
                    </FG>
                    <FG>
                        <Input label={"nhis.patient.last_name_label"} value={lastName} required={true}
                               setValue={setLastName} autoComplete="new-password"/>
                    </FG>
                    {!user.internal && <FG>
                        <Input setValue={setTitle} value={title} required={false}
                               label={"wizard_personal_information_title_label"} type="text"
                               autoComplete="new-password"/>
                    </FG>}
                    <FG>
                        <Input label={"email_label"} name={"email-input"} value={email} required={true}
                               setValue={(v, e) => {
                                   setEmail(v);
                                   e.target.setCustomValidity("");
                                   if (existingEmailError) {
                                       setExistingEmailError(false);
                                   }
                               }} type="email" autoComplete="new-password"/>
                        <div className={existingEmailError ? 'custom-invalid-feedback' : 'd-none'}>
                            {$$('register_form_email_exists_message')}
                        </div>
                    </FG>

                    {!user.internal && <>
                        <FG>
                            <input type='text' className='form-control'
                                   value={phone_numbers} name='phone_numbers'
                                   onChange={event => {
                                   }}
                                   required={true} hidden/>
                            <CustomTagsInput
                                tags={phone_numbers}
                                handleAddition={(selectedItem) => {
                                    const v = [...phone_numbers];
                                    v.push(selectedItem.text);
                                    setPhone_numbers(v);
                                }}
                                handleDelete={(index) => {
                                    const fields = phone_numbers.filter((s, i) => i !== index);
                                    setPhone_numbers(fields);
                                }}
                                placeholder={$$('wizard_phone_numbers_label')}
                                autofocus={false}
                                isFieldValid={phone_numbers.length > 0}
                                formClass={formClass}/>
                            <div className='invalid-feedback'>
                                {$$('wizard_phone_numbers_required_label')}
                            </div>
                        </FG>
                        <FG>
                            <Input label={"contact_email"} value={contact_email} required={false}
                                   setValue={setContactEmail} type="email" autoComplete="new-password"/>
                        </FG>
                        <FG>
                            <SearchableSelectAsync
                                loadOptions={(query, callback) => callback(specialtyOptions.filter(opt => opt.label.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1))}
                                isMulti={true}
                                options={specialtyOptions}
                                value={specialties.map(v => specialtyOptions.find(opt => opt.value === v))}
                                placeholder={$$("wizard_personal_information_specialties_label")}
                                onSelect={(o) => {
                                    setSpecialties(o.map(o => o.value));
                                }}
                                required={true}
                                optionHeight={40}
                                defaultMenuIsOpen={false}
                            />
                        </FG>
                        <FG>
                            <SearchableSelectAsync
                                loadOptions={(query, callback) => callback(nhisSpecialtyOptions.filter(opt => opt.label.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1))}
                                isMulti={true}
                                options={nhisSpecialtyOptions}
                                value={nhis_specialties.map(v => nhisSpecialtyOptions.find(opt => opt.value === v))}
                                placeholder={$$("nhis.nhis_specialties_search")}
                                onSelect={(o) => {
                                    setNhisSpecialties(o.map(o => o.value));
                                }}
                                optionHeight={40}
                                required={false}
                            />
                        </FG>
                        <FG>
                            <Input setValue={setUin} value={uin} required={true}
                                   label={"wizard_personal_information_uin_label_2"} type="text"
                                   autoComplete="new-password"/>
                        </FG>
                        <FG>
                            <SearchableSelectAsync
                                loadOptions={(query, callback) => callback(langOptions.filter(opt => opt.label.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1))}
                                isMulti={true}
                                options={langOptions}
                                value={languages.map(v => langOptions.find(opt => opt.value === v))}
                                placeholder={$$("wizard_biography_languages_label")}
                                onSelect={(o) => {
                                    setLanguages(o.map(o => o.value));
                                }}
                                optionHeight={40}
                                required={true}
                            />
                        </FG>
                        <FG>
                            <InputTextArea label="wizard_biography_label_2" required={true} value={other_info}
                                           setValue={setOtherInfo}/>
                        </FG>

                    </>}

                    <div className="mt-3 text-right">
                        <button type="submit" className="btn btn-primary">{$$("save_btn_label")}</button>
                        <button type="button" className="btn btn-danger ml-2"
                                onClick={onCancel}>{$$("cancel_btn")}</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
}

function getSpecialtiesOptions(options) {
    return options.map(o => {
        return {value: o, text: $$(o.toLowerCase()), label: $$(o.toLowerCase())}
    });
}