import React, {Component, useMemo} from 'react'
import {$$} from '../../helpers/localization';
import moment from 'moment'
import _ from 'underscore';
import {cloneDeep} from "lodash"
import {usersService} from "../../service/users_service"
import CenteredModal from '../shared/CenteredModal';
import CenteredModal2 from '../shared/CenteredModal2'
import PrescriptionForm from './PrescriptionForm'
import {fetchPrescriptionTemplates, prescriptionService} from '../../service/prescription_service'
import PrescriptionList from './PrescriptionList'
import PropTypes from "prop-types";
import {getPrescriptionDetailsString} from "../../utils/medicationUtils";
import EncounterDocuments from "../appointments/EncounterDocuments";
import {documentsService} from "../../service/docments_service";
import {summaryService} from "../../service/summary_service";
import TemplateSelector from "./TemplateSelector";
import TestsResultDisplay from "./TestsResultDisplay";
import TestsResultEdit from "./TestsResultEdit";
import {ListGroup, OverlayTrigger, Tooltip} from "react-bootstrap";
import {ReactComponent as DeleteIcon} from "../../../public/media/icons/Delete.svg";
import {ReactComponent as EditIcon} from "../../../public/media/icons/Edit.svg";
import ProviderEncounterDocuments from "./documents/ProviderEncounterDocuments";
import {ReportsWizard} from "./ReportComponents";
import {prepareReport} from "../../utils/reportUtils";
import no_appointments from "../../resources/images/no_appointments.png";
import {infoUtils} from "../../utils/infoUtils";
import Search from "../shared/Search";
import {NhisPrescription} from "./nhis/bg/NhisPrescription";
import {NhisPrescriptionTemplates} from "./nhis/bg/NhisPrescriptionTemplates";
import {
    deleteNhisPrescriptionFromDbByNrn,
    getNhisPrescriptionFromDB,
    nhisCancelPrescription
} from "../../service/nhis_service";
import DeleteNhisPrescriptionModal from "./nhis/bg/DeleteNhisPrescriptionModal";
import {NhisExam} from "./nhis/bg/NhisExamination";
import UploadExamWizard from "./nhis/bg/NhisExaminationSentWizard";
import {NhisExaminationProvider} from "./nhis/bg/NhisExaminationProvider";
import {clinicService} from "../../service/clinic_service";
import NhisCancelExamDialog from "./nhis/bg/NhisCancelExamDialog";
import NhisConvertToNhisExamDialog from "./nhis/bg/NhisConvertToNhisExamDialog";
import {ReportLinkComponent} from "./ReportLinkComponent";
import ExamLinkIcon from "./ExamLinkIcon";
import {NhisMedicalNotice} from "./nhis/bg/NhisMedicalNotice";
import MedicalNoticeList, {NhisCancelMedicalNoticeDialog} from "./MedicalNoticeList";
import TestFetchPrescriptions from "./nhis/bg/TestFetchPrescriptions";
import EditCreateExaminationForm, {CompleteAppointmentModal} from "./EditCreateExaminationForm";
import ImportFromNhisComponent from "./nhis/bg/ImportFromNhisComponent";
import {createEncounterFromNhisImport} from "../../actions/users_actions";
import {NhisEReferral} from "./nhis/bg/NhisEReferral";
import NhisEReferralSendToNhis from './nhis/bg/NhisEReferralSendToNhis';
import {NhisReferralList} from "./nhis/bg/NhisEreferralListItemView";
import {eReferralService} from "../../service/ereferral_service";
import {NhisCancelEreferralDialog} from "./EncounterDetailsEreferralsList";
import {NhisEReferralFetch} from "./nhis/bg/NhisEReferralFetch";
import {NhisEReferralFetchNomenclatureProvider} from "./nhis/bg/NhisEReferralFetchNomenclatureProvider";


export class ExaminationTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            encounter: null,
            selectedTemplateToDelete: {},
            showDeletePrescriptionTemplate: false,
            addUpdateModal: {
                show: false
            },
            selectedEntry: null,
            deletedPlanModal: {
                show: false
            },
            selectedEntryToDelete: null,
            show_pick_template_modal: false,
            documents: [],
            previousCompletedAppointment: {},
            unFilteredPrescriptionTemplates: [],
            prescriptionTemplates: [],
            reloadNhisTemplates: true,
            isNhis: props.isNhis
        }
    }


    /**
     * Fetch encounter from id if exists
     */
    componentDidMount = async () => {
        if (this.props.encounterId) {
            usersService.fetchAppointmentEncounter(this.props.encounterId, null).then((encounter) => {
                if (encounter) {
                    this.setState({
                        encounter: encounter,
                        selectedExaminationType: encounter.nhis_examination_json ? "nhis" : "regular"
                    });
                    if (encounter.document_ids && encounter.document_ids.length > 0) {
                        documentsService.fetchEncounterDocuments(encounter.id)
                            .then(async r => {
                                this.setState({documents: r});

                                let params = {
                                    documentIds: encounter.document_ids.filter(id => !r.find(document => document.id === id))
                                }

                                if (params.documentIds && params.documentIds.length > 0) {
                                    await documentsService.removeMissingUnseenDocuments(encounter.id, params).then(() => {
                                        usersService.fetchAppointmentEncounter(encounter.id, null).then((encounter) => {
                                            if (encounter) {
                                                this.setState({encounter});
                                            }
                                        });
                                    })
                                }
                            })
                    }
                }
            });
        } else {
            if (!this.props.isNhis) {
                this.setState({isEditMode: true})
            }
        }

        await summaryService.getLastCompletedAppointment(this.props.selectedUser.id, this.props.encounterId ? {excludeEncounterId: this.props.encounterId} : {}).then((r) => {
            this.setState({previousCompletedAppointment: r})
        })

        fetchPrescriptionTemplates().then((res) => {
            this.setState({
                unFilteredPrescriptionTemplates: res,
                prescriptionTemplates: res
            })
        })
        usersService.fetchTestsTemplates().then(results => {
            this.setState({doctor_tests_templates: results})
        })
    }

    filterPrescriptionTemplates = () => {
        let filtered = this.state.unFilteredPrescriptionTemplates.filter(template => template.template_name.toLowerCase().includes(this.state.search.toLowerCase()));
        this.setState({
            prescriptionTemplates: filtered
        })

    }

    hideTemplateModal = () => {
        this.setState({show_pick_template_modal: false, search: ""})
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.encounter.id && prevProps.encounter !== this.props.encounter) {
            this.setState({
                encounter: this.props.encounter,
                reloadNhisTemplates: true,
                isFocused: false
            });
            let selectedAppointment = this.props.appointment;
            selectedAppointment.encounter_id = this.props.encounter.id;
            this.props.selectAppointmentInProgress(selectedAppointment);
        }

        if (this.state.search !== prevState.search) {
            this.filterPrescriptionTemplates()
        }
    }


    /**
     * Form submit handler, validate data and set error in state if any. Call create logbook entry action.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        evt.preventDefault();
    }

    /**
     * Get concomitant diseases for the encounter
     */
    getConcomitantDiseases = () => {
        let concomitantDiseases = "";
        for (let i = 0; i < this.state.encounter.concomitant_diseases.length; i++) {
            if (i === 0) {
                concomitantDiseases += this.state.encounter.concomitant_diseases[i];
            } else {
                concomitantDiseases += "    |    " + this.state.encounter.concomitant_diseases[i];
            }
        }

        return concomitantDiseases;
    }


    /**
     * Get tests for the encounter
     */
    getTests = () => {
        let tests = "";

        if (this.state.encounter.tests) {
            for (let i = 0; i < this.state.encounter.tests.length; i++) {
                if (i === 0) {
                    tests += this.state.encounter.tests[i];
                } else {
                    tests += "\n" + this.state.encounter.tests[i];
                }
            }
        }

        return tests;
    }

    /**
     * Hide the modal
     */
    onHideAddUpdateModal = () => {
        this.setState({
            addUpdateModal: {
                show: false
            }, selectedEntry: null, needsUpdate: true, refreshPill: true, prescription_template: null
        });
    }

    /**
     * Show add/update modal
     *
     * @param {entry} entry - The entry to modify or null
     */
    showAddUpdateModal = (entry) => {
        if (entry) {
            this.setState({
                selectedEntry: entry, addUpdateModal: {show: true}
            })
        } else {
            this.setState({
                selectedEntry: null, addUpdateModal: {show: true}
            })
        }
    }

    /**
     * Confirm deletion of the plan and pass to the deletion of the prescription
     */
    onDeletePrescription = () => {
        let prescription = this.state.selectedEntryToDelete;
        prescriptionService.deletePrescription(prescription.id).then(() => {
            usersService.fetchAppointmentEncounter(prescription.encounter_id, null).then((encounter) => {
                if (encounter) {
                    this.setState({encounter});
                    this.setState({
                        deletedPlanModal: {show: false}, selectedEntryToDelete: null
                    });
                }
            });
        });
    }

    /**
     * Show confirmation modal when trying to delete an entry
     *
     * @param {entry} key The entry to delete
     */
    showDeletedConfirmationModal = (key) => {
        this.setState({
            deletedPlanModal: {show: true}, selectedEntryToDelete: key
        })
    }

    onDeleteNhisPrescription = (nrn) => {
        this.setState({
            deleteNhisPrescriptionModal: true,
            selectedNhisPrescriptionNrnToDelete: nrn
        })
    }

    deleteNhisPrescription = async (revokeReason) => {
        let nrn = this.state.selectedNhisPrescriptionNrnToDelete;
        //cancel in nhis
        try {
            this.setState({deleteNhisPrescriptionInProgress: true});
            let nhisP = await getNhisPrescriptionFromDB(nrn);
            let uin = JSON.parse(nhisP.prescription_data_json).senderId;
            await nhisCancelPrescription(nrn, uin, revokeReason);

            //delete from our DB
            await deleteNhisPrescriptionFromDbByNrn(nrn);

            //delete all medications
            const toDelete = this.state.encounter.prescriptions.filter(p => p.nhis_prescription_nrn === nrn);
            for (let p of toDelete) {
                await prescriptionService.deletePrescription(p.id);
            }
            //reload
            usersService.fetchAppointmentEncounter(this.state.encounter.id, null).then((encounter) => {
                this.setState({encounter});
                this.setState({
                    deleteNhisPrescriptionModal: false,
                    selectedNhisPrescriptionNrnToDelete: null,
                    deleteNhisPrescriptionModalError: null,
                    deleteNhisPrescriptionInProgress: false
                });
            });
        } catch (e) {
            console.log(e);
            if (!(typeof e === 'string' || e instanceof String)) {
                e = $$("nhis.service_error");
            }
            this.setState({deleteNhisPrescriptionModalError: e, deleteNhisPrescriptionInProgress: false});
        }
    }

    onDeletePrescriptionTemplate = () => {

        if (this.state.selectedTemplateToDelete !== {}) {
            prescriptionService.deletePrescriptionTemplate(this.state.selectedTemplateToDelete.id).then(() => {
                fetchPrescriptionTemplates().then((res) => {
                    this.setState({unFilteredPrescriptionTemplates: res, prescriptionTemplates: res})
                });
            }).then(() => {
                this.setState({
                    showDeletePrescriptionTemplate: false, show_pick_template_modal: true, selectedTemplateToDelete: {}
                })
            })
        }
    }
    /**
     * If there was a selected encounter clear the selected encounter
     */
    componentWillUnmount = () => {
        if (this.props.encounter) {
            this.props.clearSelectedEncounter();
        }
    }

    showCompleteAppointment = () => {
        this.setState({
            showCompleteAppointment: true,
        })
    }

    completeAppointment = () => {
        this.setState({
            showCompleteAppointment: false
        });
        return this.props.updateAppointmentStatus(this.props.appointment.id, "COMPLETED");
    }

    updatePrescription = (prescription) => {
        let encounter = this.state.encounter;
        prescription.encounter_id = encounter.id;
        prescription.provider_id = this.props.userId;
        prescription.patient_id = encounter.subject;
        let shouldCreateTemplate = prescription.template_name.trim() !== '';
        prescriptionService.updatePrescription(prescription, shouldCreateTemplate).then(() => {
            usersService.fetchAppointmentEncounter(encounter.id, null).then((encounter) => {
                if (encounter) {
                    this.setState({encounter});
                }
            });
            if (shouldCreateTemplate) {
                fetchPrescriptionTemplates().then((res) => {
                    this.setState({unFilteredPrescriptionTemplates: res, prescriptionTemplates: res})
                });
            }
        });
    }

    createPrescription = (prescription) => {
        let encounter = this.state.encounter;
        if (!encounter) {
            encounter = {
                tests: [],
                concomitant_diseases: [],
                concomitant_diseases_codes: [],
                therapy: "",
                main_diagnosis: "",
                main_diagnosis_code: "",
                additional_info: "",
                objective_data: "",
                subjective_data: ""
            };
            encounter.subject = this.props.selectedUser.id;
            this.props.createEncounter(this.props.selectedUser.id, this.props.appointment.id, encounter).then(res => {
                prescription.encounter_id = res.id;
                prescription.provider_id = this.props.userId;
                prescription.patient_id = encounter.subject;
                let shouldCreateTemplate = prescription.template_name.trim() !== '';
                prescriptionService.createPrescription(prescription, shouldCreateTemplate).then(() => {
                    usersService.fetchAppointmentEncounter(res.id, null).then((encounter) => {
                        if (encounter) {
                            this.setState({encounter});
                        }
                    });
                    if (shouldCreateTemplate) {
                        fetchPrescriptionTemplates().then((res) => {
                            this.setState({unFilteredPrescriptionTemplates: res, prescriptionTemplates: res})
                        });
                    }
                });
            });
        } else {
            prescription.encounter_id = encounter.id;
            prescription.provider_id = this.props.userId;
            prescription.patient_id = encounter.subject;
            let shouldCreateTemplate = prescription.template_name.trim() !== '';
            prescriptionService.createPrescription(prescription, shouldCreateTemplate).then(() => {
                usersService.fetchAppointmentEncounter(encounter.id, null).then((encounter) => {
                    if (encounter) {
                        this.setState({encounter});
                    }
                });
                if (shouldCreateTemplate) {
                    fetchPrescriptionTemplates().then((res) => {
                        this.setState({unFilteredPrescriptionTemplates: res, prescriptionTemplates: res})
                    });
                }
            });
        }
    }


    onDeleteTestResults = () => {
        usersService.deleteTestsResults(this.state.encounter.id, this.state.selectedTestResultToDelete.template_id)
            .then(() => {
                usersService.fetchAppointmentEncounter(this.state.encounter.id, null).then((encounter) => {
                    if (encounter) {
                        this.setState({encounter});
                        this.setState({
                            showDeleteTestResultsModal: false, selectedTestResultToDelete: undefined
                        });
                    }
                });
            })
    }

    getAdditionalResults = () => {
        return <ListGroup className={"shadow-boxes-list"}>
            {this.state.encounter.tests_results?.map((entry, idx) => {
                return <ListGroup.Item key={idx}>
                    <div style={{marginRight: '0.5rem', marginTop: '0.5rem'}}>
                        <h6 className="card-title mb-4 pointer btn-link" onClick={() => {
                            this.setState({displayTestResult: entry})
                        }}>{entry.name}</h6>
                        <div className={"smaller-text medrec-grey-2"}>{}</div>
                    </div>
                    <div>
                    <span onClick={() => {
                        this.setState({
                            showDeleteTestResultsModal: true, selectedTestResultToDelete: entry
                        })
                    }}
                          className="pointer prescription-delete-icon">
                        <OverlayTrigger
                            placement={"top"}
                            overlay={this.deleteTooltip}
                            popperConfig={{
                                modifiers: {
                                    preventOverflow: {
                                        enabled: false
                                    },
                                    hide: {
                                        enabled: false
                                    }
                                }
                            }}
                        >
                        <DeleteIcon className="svg-blue" height="17px" width="17px"/>
                    </OverlayTrigger>


                        </span>
                        <span onClick={() => {
                            this.setState({tests_template: entry})
                        }}
                              className="pointer prescription-edit-icon">
                            <OverlayTrigger
                                placement={"top"}
                                overlay={this.editTooltip}
                                popperConfig={{
                                    modifiers: {
                                        preventOverflow: {
                                            enabled: false
                                        },
                                        hide: {
                                            enabled: false
                                        }
                                    }
                                }}
                            >
                        <EditIcon className="svg-blue" height="17px" width="17px"/>
                    </OverlayTrigger>
                        </span>
                    </div>
                </ListGroup.Item>
            })}
        </ListGroup>
    }

    isMainEncounterEmpty = () => {
        return !this.state.encounter.main_diagnosis && !this.state.encounter.objective_data && !this.state.encounter.subjective_data && !this.state.encounter.therapy;
    }

    noResults = () => {
        let noResults = {
            imgClass: 'sm-info-util',
            primaryLabelClass: 'no-lab-results-primary-label',
            secondaryLabelClass: 'no-lab-results-secondary-label',
            src: no_appointments,
            primaryLabel: $$('no_results_found'),
            secondaryLabel: ''
        }
        return infoUtils.noData(noResults);
    }

    getReportData = (reportType, patientData) => {
        let org = this.props.loggedInUser.organizations.find(o => o.id === this.props.appointment.organization_id);
        return prepareReport(reportType, this.state.encounter, this.props.settings, patientData, org)
    }

    updateEncounter = () => {
        usersService.fetchAppointmentEncounter(this.state.encounter.id, null).then((encounter) => {
            if (encounter) {
                this.setState({encounter});
            }
        });
    }

    editTooltip = props => (
        <Tooltip {...props} show={"true"}>{$$("edit_label")}</Tooltip>
    );

    deleteTooltip = props => (
        <Tooltip {...props} show={"true"}>{$$("delete_label")}</Tooltip>
    );

    render() {
        let lang = _.contains(['en', 'sq'], this.props.i18n) ? 'en' : this.props.i18n;
        moment.locale(lang.lang);

        if (this.state.tests_template) {
            return <div>
                <TestsResultEdit template={this.state.tests_template} encounter_id={this.state.encounter.id}
                                 patient_id={this.state.encounter.subject}
                                 onSave={() => {
                                     usersService.saveTestsResults(this.state.encounter.id, [this.state.tests_template]).then(() => {
                                         usersService.fetchAppointmentEncounter(this.state.encounter.id, null).then((encounter) => {
                                             if (encounter) {
                                                 this.setState({encounter});
                                                 this.setState({tests_template: undefined})
                                             }
                                         })
                                     })
                                 }} onCancel={() => {
                    this.setState({tests_template: undefined})
                }}/>
            </div>
        }

        if (this.state.displayTestResult) {
            return <div>
                <TestsResultDisplay result={this.state.displayTestResult}
                                    onCancel={() => {
                                        this.setState({displayTestResult: undefined})
                                    }}/>
            </div>
        }

        const isFree = this.props.appointment.appointment_price.price_cents === 0;
        const canBeCompleted = this.props.appointment.status !== 'COMPLETED' && (isFree || this.props.appointment.payment_status === 'COMPLETED')
        const isPaid = this.props.appointment.payment_status === 'COMPLETED';
        const isTextConsultation = this.props.appointment.text_only;

        if (this.state.showCancelNhisExamModal) {
            return <NhisCancelExamDialog encounter={this.state.encounter}
                                         senderId={this.props.loggedInUser.uin}
                                         onClose={() => {
                                             this.setState({showCancelNhisExamModal: false})
                                         }}
                                         onSuccess={() => {
                                             let encounter = {...this.state.encounter}
                                             encounter.update_nhis = false;
                                             encounter.nhis_examination_nrn = null;
                                             encounter.nhis_examination_json = null;
                                             encounter.main_diagnosis = "";
                                             encounter.main_diagnosis_code = "";
                                             encounter.concomitant_diseases = [];
                                             encounter.concomitant_diseases_codes = [];
                                             encounter.subjective_data = "";
                                             encounter.objective_data = "";
                                             encounter.additional_info = "";
                                             encounter.therapy = "";
                                             encounter.tests = [];
                                             encounter.conclusion = "";
                                             encounter.preliminary_diagnosis = false;

                                             this.props.updateEncounter(this.props.selectedUser.id, encounter)
                                                 .then(enc => {
                                                     this.props.updateAppointmentStatus(this.props.appointment.id, "ACCEPTED");
                                                     this.setState({
                                                         showCancelNhisExamModal: false,
                                                         selectedExaminationType: null
                                                     });
                                                 });
                                         }}
            />
        }

        if (this.state.isNhis) {
            if (this.state.importExamFromNhis) {
                return <NhisExaminationProvider>
                    <ImportFromNhisComponent
                        onClose={() => {
                            this.setState({importExamFromNhis: false})
                        }}
                        onCreate={(encounter) => {
                            return createEncounterFromNhisImport(this.props.appointment.id, encounter).then(() => {
                                this.completeAppointment();
                                this.setState({selectedExaminationType: "nhis"})
                            })
                        }}
                        i18n={this.props.i18n}
                        startTime={this.props.appointment.starts_at}
                        patientId={this.props.selectedUser.id}
                        clinicianUIN={this.props.loggedInUser.uin}
                        orgId={this.props.appointment.organization_id}
                    />
                </NhisExaminationProvider>
            }
            if (!this.props.encounterId && !this.state.selectedExaminationType) {
                return <div>
                    <div className="mt-2">
                        <table className="table table-borderless base-font-color"
                               style={{borderCollapse: "separate", borderSpacing: "1rem"}}>
                            <thead>
                            <tr>
                                <td colSpan={2}>{$$("nhis.examination.choose_exam_type_table_heading")}</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr className="low-shadow-container">
                                <td width={"250px"}>
                                    <button type="button" className="btn btn-primary w-100" onClick={() => {
                                        this.setState({selectedExaminationType: "regular", isEditMode: true})
                                    }}>{$$("nhis.examination.regular_examination")}</button>
                                </td>
                                <td className="text-left">{$$("nhis.examination.exam_type_medrec_m_description")}</td>
                            </tr>
                            <tr className="low-shadow-container mb-3">
                                <td width={"250px"}>
                                    <button type="button" className="btn btn-primary w-100" onClick={() => {
                                        this.setState({selectedExaminationType: "nhis", editNhisEncounter: true})
                                    }}>{$$("nhis.examination.e_examination")}</button>
                                </td>
                                <td className="text-left">{$$("nhis.examination.exam_type_nhis_description")}</td>
                            </tr>
                            <tr className="low-shadow-container">
                                <td width={"250px"}>
                                    <button type="button" className="btn btn-primary w-100" onClick={() => {
                                        this.setState({importExamFromNhis: true})

                                    }}>{$$("nhis.examination.import_examination_btn")}</button>
                                </td>
                                <td className="text-left">{$$("nhis.examination.import_examination_button_description")}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            }

            if (this.state.showConvertToNhisExamDialog) {
                return <NhisConvertToNhisExamDialog
                    encounter={this.state.encounter}
                    onClose={() => {
                        this.setState({showConvertToNhisExamDialog: false, editNhisEncounter: true})
                    }}
                    onConvert={(enc) => {
                        let future = this.props.updateEncounter(this.props.selectedUser.id, enc);
                        this.setState({selectedExaminationType: "nhis"});
                        return future;
                    }}
                />
            }

            if (this.state.selectedExaminationType === 'nhis') {
                if (this.state.showEReferralDialog) {
                    return <NhisEReferral
                        encounter={this.state.encounter}
                        onClose={() => {
                            this.setState({showEReferralDialog: false})
                        }}
                        onSuccess={(eReferral) => {
                            this.setState({showEReferralDialog: false});
                            eReferralService.createEreferral(this.state.encounter.id, {
                                lrn: eReferral.contents.lrn,
                                encounter_id: this.state.encounter.id,
                                version: "1",
                                content_json: JSON.stringify(eReferral),
                                update_nhis: true
                            }).then((x) => {
                                this.updateEncounter();
                                this.setState({selectedEReferral: x, showSendEReferral: true});
                            })
                        }}
                        orgId={this.props.appointment.organization_id}
                        provider={this.props.loggedInUser}
                        patient={this.props.selectedUser}
                        lang={this.props.i18n.lang}
                        eRef={this.state.selectedEReferral}
                    />
                }
                if (this.state.showSendEReferral && this.state.selectedEReferral) {
                    return <NhisEReferralSendToNhis
                        onCancel={() => {
                            this.setState({showSendEReferral: false, selectedEReferral: null})
                        }}
                        onSuccess={(nrn) => {
                            this.state.selectedEReferral.nrn = nrn;
                            eReferralService.updateEreferral(this.state.encounter.id, this.state.selectedEReferral)
                                .then(() => {
                                    this.updateEncounter();
                                })
                        }}
                        eRef={this.state.selectedEReferral}
                        examNrn={this.state.encounter.nhis_examination_nrn}
                        provider={this.props.loggedInUser}
                        lang={this.props.i18n.lang}
                    />
                }
                if (this.state.showMedicalNoticeDialog) {
                    return <NhisMedicalNotice
                        onClose={() => {
                            this.setState({showMedicalNoticeDialog: false})
                        }}
                        orgId={this.props.appointment.organization_id}
                        provider={this.props.loggedInUser}
                        patient={this.props.selectedUser}
                        encounter={this.state.encounter}
                        lang={this.props.i18n.lang}
                        onSuccess={(notice) => {
                            let encounter = {...this.state.encounter};
                            let arr = encounter.nhis_medical_notice_jsons || [];

                            let data = {
                                senderId: notice.senderId,
                                medicalNotice: {...notice.medicalNotice},
                                viewModel: {...notice.viewModel}
                            };

                            data.viewModel.authoredOn = data.medicalNotice.authoredOn;
                            data.viewModel.fromDate = data.medicalNotice.fromDate;
                            data.viewModel.toDate = data.medicalNotice.toDate;
                            data.viewModel.diagnosisCode = data.medicalNotice.code;
                            data.viewModel.nrnMedicalNotice = data.medicalNotice.nrnMedicalNotice;
                            data.viewModel.senderId = notice.senderId;

                            encounter.nhis_medical_notice_jsons = [...arr, JSON.stringify(data)];
                            this.props.updateEncounter(this.props.selectedUser.id, encounter);
                        }}
                    />
                }

                if (!this.state.editNhisEncounter) {
                    if (this.state.showNhisWizard) {
                        return <NhisExaminationProvider><UploadExamWizard
                            orgId={this.props.appointment.organization_id}
                            provider={this.props.loggedInUser}
                            patient={this.props.selectedUser}
                            onCancel={() => {
                                this.setState({showNhisWizard: false})
                            }}
                            onSuccess={(nrn) => {
                                let enc = {...this.state.encounter};
                                enc.nhis_examination_nrn = nrn;
                                enc.update_nhis = false;
                                this.props.updateEncounter(this.props.selectedUser.id, enc);
                            }}
                            examination={this.state.encounter.nhis_examination_json}
                            encounter={this.state.encounter}
                            lang={this.props.i18n.lang}
                        />
                        </NhisExaminationProvider>
                    }
                }

                if (this.state.editNhisEncounter) {
                    return <NhisExam encounter={this.state.encounter}
                                     appointmentPrice={this.props.appointment.appointment_price}
                                     i18n={this.props.i18n}
                                     doctor={this.props.loggedInUser}
                                     orgId={this.props.appointment.organization_id}
                                     patient={this.props.selectedUser}
                                     onSave={(model, keepOpen = false, encounter) => {
                                         document.scrollingElement.scrollTo({top: 0, behavior: "instant"});
                                         this.setState({
                                             editNhisEncounter: keepOpen,
                                             encounter: encounter
                                         })

                                         if (model.examination.rhifAreaNumber) {
                                             clinicService.fetchEncPatientInfo(this.props.selectedUser.id, this.props.appointment.organization_id).then(patient => {
                                                 if (!patient) {
                                                     patient = {}
                                                 }
                                                 patient.rhifAreaNumber = model.examination.rhifAreaNumber;
                                                 clinicService.updateEncPatientInfo(this.props.selectedUser.id, this.props.appointment.organization_id, patient)
                                             })
                                         }

                                         if (model.examination && model.examination.lrn && encounter) {
                                             if (encounter.id) {
                                                 encounter.update_nhis = !!encounter.nhis_examination_nrn;
                                                 this.props.updateEncounter(this.props.selectedUser.id, encounter);
                                             } else {
                                                 this.props.createEncounter(this.props.selectedUser.id, this.props.appointment.id, encounter);
                                             }
                                         }
                                     }}
                                     onCancel={() => {
                                         document.scrollingElement.scrollTo({top: 0, behavior: "instant"});
                                         let selectedExaminationType = this.state.encounter?.nhis_examination_json ? this.state.selectedExaminationType : null;
                                         if (this.state.encounter && !this.state.encounter.id) {
                                             let exam = JSON.parse(this.state.encounter.nhis_examination_json);
                                             if (!exam.examination?.lrn) {
                                                 selectedExaminationType = null;
                                                 this.setState({
                                                     encounter: null
                                                 });
                                             }
                                         }


                                         this.setState({
                                             editNhisEncounter: false,
                                             selectedExaminationType: selectedExaminationType
                                         })
                                     }}
                    />
                }
            }
        }

        return (<div className={"minus-twenty-margin-top"}>
            <div>
                <div className='row' style={{'marginLeft': '0px', 'marginRight': '0px'}}>
                    <div className='col-xs-12 col-md-12'>
                        <div className='row'>
                            <div className="row"
                                 style={{"width": "100%", "marginLeft": "20px", "marginRight": "20px"}}>
                                <div style={{"width": "100%"}}>
                                    <div style={{"textAlign": "right", "marginTop": "15px"}}>
                                        {this.state.encounter && <div className="d-flex justify-content-between ">
                                            <div
                                                className="appointments-tab-column-label medrec-blue-1 align-items-center d-flex">
                                                {this.props.appointment.status === 'COMPLETED' &&
                                                <span
                                                    className="strong">{isTextConsultation ? $$("completed_consultation_label") : $$("completed_label")}
                                                    <i
                                                        className="kt-nav__link-icon flaticon-lock ml-1"
                                                        style={{fontSize: "large"}}/></span>}
                                                {!this.state.isEditMode && canBeCompleted &&
                                                <button className="btn btn-success btn-sm"
                                                        onClick={this.showCompleteAppointment}>
                                                    {isTextConsultation ? $$('complete_consultation_button_label') : $$('complete_appointment_button_label')}
                                                </button>}
                                                {!this.state.isEditMode && !isPaid && <span
                                                    className="text-danger">{$$("complete_text_button_missing_explanation")}</span>}
                                            </div>

                                            {!this.state.isEditMode && this.state.selectedExaminationType !== 'nhis' &&
                                            <div>
                                                {this.state.isNhis &&
                                                <button type="button" className="btn btn-primary btn-sm mr-2"
                                                        onClick={() => {
                                                            this.setState({showConvertToNhisExamDialog: true})
                                                        }}>{$$("nhis.btn.convert_to_nhis_exam")}</button>
                                                }
                                                <div className="appointments-tab-column-label medrec-blue-1"
                                                     style={{"display": "inline-block"}}>
                                                    <a className="nav-link active" onClick={() => {
                                                        if (!this.state.isEditMode) {
                                                            this.setState({
                                                                isEditMode: !this.state.isEditMode,
                                                                reloadNhisTemplates: true
                                                            })
                                                        }
                                                    }} style={{"display": "inline-block"}}>
                                                        <i className="kt-nav__link-icon flaticon-edit"/>&nbsp;{$$("edit_exam_results")}
                                                    </a>
                                                </div>
                                            </div>}
                                            {this.state.selectedExaminationType === 'nhis' && !this.state.editNhisEncounter &&
                                            <div className="p-2 d-flex">
                                                {!this.state.encounter?.nhis_examination_nrn && this.state.encounter?.nhis_examination_json?.includes("lrn")
                                                && this.props.appointment.status === "COMPLETED" &&
                                                <button type="button" className="btn btn-primary btn-sm ml-2"
                                                        onClick={() => {
                                                            this.setState({showNhisWizard: true})
                                                        }}>{$$("nhis.send_to_nhis")}</button>
                                                }
                                                {this.state.encounter?.nhis_examination_nrn && this.state.encounter?.update_nhis
                                                && this.props.appointment.status === "COMPLETED" &&
                                                <button type="button" className="btn btn-primary btn-sm ml-2"
                                                        onClick={() => {
                                                            this.setState({showNhisWizard: true})
                                                        }}>{$$("nhis.send_correction_to_nhis")}</button>
                                                }
                                                {this.state.encounter?.nhis_examination_nrn &&
                                                <button type="button" className="btn btn-danger btn-sm  ml-2"
                                                        onClick={() => {
                                                            this.setState({showCancelNhisExamModal: true})
                                                        }}>{$$("nhis.annul_nhis")}</button>
                                                }

                                                <div className="appointments-tab-column-label medrec-blue-1 ml-auto"
                                                     style={{"display": "inline-block"}}>
                                                    <a className="nav-link active" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.setState({editNhisEncounter: true})
                                                    }} style={{"display": "inline-block"}}>
                                                        <i className="kt-nav__link-icon flaticon-edit"/>&nbsp;{$$("edit_exam_results")}
                                                    </a>
                                                </div>
                                            </div>
                                            }
                                        </div>}

                                        {(this.state.isEditMode || !this.props.encounterId) && this.state.selectedExaminationType !== 'nhis' &&
                                        <EditCreateExaminationForm
                                            encounter={this.state.encounter}
                                            i18n={this.props.i18n}
                                            icdEntries={this.props.icdEntries}
                                            searchIcd={this.props.searchIcd}
                                            clearIcd={this.props.clearIcd}
                                            previousCompletedAppointment={this.state.previousCompletedAppointment}
                                            appointment={this.props.appointment}
                                            isNhis={this.props.isNhis}
                                            onCancel={() => {
                                                this.setState({
                                                    isEditMode: false,
                                                    selectedExaminationType: this.props.isNhis && !this.props.encounterId ? undefined : this.state.selectedExaminationType
                                                }, () => document.scrollingElement.scrollTo({
                                                    top: 0,
                                                    behavior: "instant"
                                                }));
                                            }}
                                        />
                                        }
                                        <CompleteAppointmentModal encounter={this.state.encounter}
                                                                  show={this.state.showCompleteAppointment}
                                                                  onHide={() => {
                                                                      this.setState({showCompleteAppointment: false})
                                                                  }}
                                                                  fromSave={false}
                                                                  isTextConsultation={isTextConsultation}
                                                                  onComplete={this.completeAppointment}/>
                                    </div>
                                    {this.state.encounter && !this.state.isEditMode && <div>
                                        {!this.state.isEditMode && this.state.selectedExaminationType === 'nhis' &&
                                        this.state.encounter.nhis_examination_json && !this.state.encounter.nhis_examination_json.includes('"lrn":') &&
                                        <div className={"text-danger text-right pb-2 mr-4"}>
                                            <i className="fa fa-exclamation-triangle"
                                               aria-hidden="true"></i> {$$("nhis.examination.need_to_edit_before_send")}
                                        </div>
                                        }
                                        {!this.state.isEditMode && this.state.selectedExaminationType === 'nhis' &&
                                        this.state.encounter.nhis_examination_json && this.state.encounter.nhis_examination_json.includes('"lrn":') &&
                                        this.props.appointment.status !== 'COMPLETED' && !this.state.encounter.nhis_examination_nrn &&
                                        <div className={"text-danger text-right pb-2 mr-4"}>
                                            <i className="fa fa-exclamation-triangle"
                                               aria-hidden="true"></i> {$$("nhis.examination.need_to_complete_before_send")}
                                        </div>
                                        }
                                        <div className={"p-2 pb-3"}>
                                            <ReportLinkComponent onClick={() => {
                                                this.setState({showReportsWizard: true})
                                            }}/>
                                        </div>
                                        {this.state.showReportsWizard &&
                                        <ReportsWizard updateEncounter={this.updateEncounter}
                                                       lang={this.props.i18n.lang}
                                                       patient={this.props.selectedUser}
                                                       orgId={this.props.appointment.organization_id}
                                                       getData={this.getReportData}
                                                       onClose={() => {
                                                           this.setState({showReportsWizard: false})
                                                       }}/>}
                                        <div className="card-body">
                                            {this.isMainEncounterEmpty() && <div
                                                className="text-center medrec-grey-2 h4 mb-4">{$$("main_examination_result_fields_are_empty")}</div>}
                                            <EncounterLine value={this.state.encounter?.nhis_examination_nrn}
                                                           label={$$("nhis.examination.nrn_number")}/>
                                            <EncounterLine value={this.state.encounter?.main_diagnosis}
                                                           label={$$("main_diagnosis_label")}/>
                                            <EncounterLine value={this.state.encounter?.concomitant_diseases}
                                                           label={$$("concomitant_diseases_label")}>
                                                {this.getConcomitantDiseases()}
                                            </EncounterLine>
                                            <EncounterLine value={this.state.encounter?.preliminary_diagnosis}
                                                           preliminary_diagnosis={$$("preliminary_diagnosis")}>
                                                <input className="big-checkbox" type="checkbox"
                                                       name="is_preliminary_diagnosis"
                                                       checked={this.state.encounter?.preliminary_diagnosis}
                                                       readOnly={true}

                                                />
                                            </EncounterLine>
                                            <EncounterLine value={this.state.encounter?.subjective_data}
                                                           label={$$("subjective_data_label")}/>
                                            <EncounterLine value={this.state.encounter?.objective_data}
                                                           label={$$("objective_data_label")}/>
                                            <EncounterLine value={this.state.encounter?.tests}
                                                           label={$$("tests_label")}>
                                                {this.getTests()}
                                            </EncounterLine>
                                            <EncounterLine value={this.state.encounter?.therapy}
                                                           label={$$("therapy_label")}/>
                                            <EncounterLine value={this.state.encounter?.additional_info}
                                                           label={$$("additional_info")}/>
                                            <EncounterLine value={this.state.encounter?.conclusion}
                                                           label={$$("conclusion")}/>
                                            {this.state.encounter && this.state.documents && this.state.documents.length > 0 &&
                                            <EncounterDocuments documents={this.state.documents}
                                                                encounter={this.state.encounter}
                                                                appointment={this.props.appointment}
                                                                selectedUser={this.props.selectedUser}
                                                                updateEncounter={() => {
                                                                    this.updateEncounter()
                                                                }}
                                                                i18n={this.props.i18n}/>
                                            }
                                            <ProviderEncounterDocuments
                                                encounterDocuments={true}
                                                encounter={this.state.encounter}
                                                appointment={this.props.appointment}
                                                selectedUser={this.props.selectedUser}
                                                onUpdate={() => {
                                                    this.updateEncounter()
                                                }}
                                            />

                                            <EncounterLine2 value={this.state.encounter?.prescriptions}
                                                            label={$$("prescriptions")}>
                                                <PrescriptionList
                                                    prescriptionList={this.state.encounter ? this.state.encounter.prescriptions : []}
                                                    showDeletedConfirmationModal={this.showDeletedConfirmationModal}
                                                    onDeleteNhisPrescription={this.onDeleteNhisPrescription}
                                                    showAddUpdateModal={this.showAddUpdateModal}>
                                                </PrescriptionList>
                                            </EncounterLine2>

                                            <EncounterLine2 value={this.state.encounter?.tests_results}
                                                            label={$$("additional_results")}>
                                                {this.getAdditionalResults()}
                                            </EncounterLine2>

                                            <EncounterLine2 value={this.state.encounter?.nhis_medical_notice_jsons}
                                                            label={$$("nhis.medicalNotice.medical_notices")}>
                                                <MedicalNoticeList encounter={this.state.encounter}
                                                                   onDelete={(val) => {
                                                                       this.setState({deleteMedicalNotice: val})
                                                                   }}
                                                                   lang={this.props.i18n.lang}/>
                                            </EncounterLine2>

                                            <EncounterLine2 value={this.state.encounter?.ereferrals}
                                                            label={$$("nhis.ereferral.ereferrals")}>
                                                <NhisReferralList encounter={this.state.encounter}
                                                                  onDelete={(val) => {
                                                                      this.setState({
                                                                          deleteEreferral:
                                                                              {
                                                                                  lrn: val.lrn,
                                                                                  nrn: val.nrn,
                                                                                  senderId: val.senderId
                                                                              }
                                                                      });
                                                                  }}
                                                                  onSubmitToNhis={(val) => {
                                                                      this.setState({
                                                                          showSendEReferral: true,
                                                                          selectedEReferral: val
                                                                      })
                                                                  }}
                                                                  onCheckResult={(val) => {
                                                                      this.setState({
                                                                          showCheckEReferral: true,
                                                                          selectedEReferral: val
                                                                      })
                                                                  }}
                                                                  lang={this.props.i18n.lang}/>
                                            </EncounterLine2>

                                            <div className="text-right d-flex flex-column">
                                                <ExamLinkIcon onClick={() => {
                                                    this.showAddUpdateModal(null)
                                                }} labelKey="add_medication"/>
                                                {this.state.unFilteredPrescriptionTemplates && this.state.unFilteredPrescriptionTemplates.length > 0 &&
                                                <ExamLinkIcon onClick={() => {
                                                    this.setState({show_pick_template_modal: true})
                                                }} labelKey="from_prescription_template"/>
                                                }
                                                {this.state.doctor_tests_templates && this.state.doctor_tests_templates.length > 0 &&
                                                <ExamLinkIcon onClick={() => {
                                                    this.setState({show_pick_test_template_modal: true})
                                                }} labelKey="pick_test_results_template"/>
                                                }
                                                {this.state.encounter && this.state.isNhis && <NhisPrescriptionTemplates
                                                    onSelect={(t) => {
                                                        this.setState({nhisTemplate: {...t}})
                                                    }}

                                                    reload={this.state.reloadNhisTemplates}
                                                    onReloaded={() => {
                                                        this.setState({reloadNhisTemplates: false})
                                                    }}
                                                />}
                                                {
                                                    this.state.encounter && this.state.isNhis && !this.state.isEditMode &&
                                                    <NhisPrescription
                                                        lang={this.props.i18n.lang}
                                                        icdCodes={this.state.encounter ? [this.state.encounter.main_diagnosis_code, ...this.state.encounter.concomitant_diseases_codes] : []}
                                                        selectedUser={this.props.selectedUser}
                                                        orgId={this.props.appointment.organization_id}
                                                        i18n={this.props.i18n}
                                                        template={this.state.nhisTemplate}
                                                        provider={this.props.loggedInUser}
                                                        createPrescription={this.createPrescription}
                                                        onTemplateAdded={() => {
                                                            this.setState({reloadNhisTemplates: true})
                                                        }}
                                                        nrnExamination={this.state.encounter.nhis_examination_nrn}
                                                        clearTemplate={() => {
                                                            if (this.state.nhisTemplate) {
                                                                this.setState({nhisTemplate: null})
                                                            }
                                                        }}
                                                    />
                                                }
                                                {this.state.encounter && this.state.isNhis && this.state.encounter.nhis_examination_nrn &&
                                                <ExamLinkIcon onClick={() => {
                                                    this.setState({showMedicalNoticeDialog: true})
                                                }} labelKey={"add_medical_notice"}/>
                                                }
                                                {this.state.encounter && this.state.isNhis && this.state.encounter.nhis_examination_nrn &&
                                                <ExamLinkIcon onClick={() => {
                                                    this.setState({showEReferralDialog: true})
                                                }} labelKey={"nhis.ereferral.add_ereferral"}/>
                                                }
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {this.state.deleteMedicalNotice && <NhisCancelMedicalNoticeDialog
                nrn={this.state.deleteMedicalNotice.nrnMedicalNotice}
                senderId={this.state.deleteMedicalNotice.senderId}
                onSuccess={async (nrn) => {
                    let enc = {...this.state.encounter};
                    enc.nhis_medical_notice_jsons = this.state.encounter.nhis_medical_notice_jsons.filter(k => !k.includes(nrn));
                    await this.props.updateEncounter(this.props.selectedUser.id, enc);
                }}
                onClose={() => this.setState({deleteMedicalNotice: null})}
            />}
            {this.state.deleteEreferral && <NhisCancelEreferralDialog
                nrn={this.state.deleteEreferral.nrn}
                lrn={this.state.deleteEreferral.lrn}
                senderId={this.state.deleteEreferral.senderId}
                onSuccess={async (nrn, lrn) => {
                    eReferralService.deleteEreferral(this.state.encounter.id, lrn)
                        .then(() => this.updateEncounter());
                }}
                onClose={() => this.setState({deleteEreferral: null})}
            />}
            {(this.state.showCheckEReferral && this.state.selectedEReferral) &&
                <NhisEReferralFetchNomenclatureProvider>
                    <NhisEReferralFetch
                        onClose={() => {
                            this.setState({showCheckEReferral: false, selectedEReferral: null})
                        }}
                        eRef={this.state.selectedEReferral}
                        lang={this.props.i18n.lang}
                    />
                </NhisEReferralFetchNomenclatureProvider>
            }
            <CenteredModal2 header={$$('pick_test_template')}
                            dialogClassName='delete-modal'
                            show={this.state.show_pick_test_template_modal}
                            onHide={() => this.setState({show_pick_test_template_modal: false})}
                            cancelBtnLabel={$$('cancel_btn')}
                            onConfirm={() => {
                            }}
                            id="prescription-templates-modal-title"
                            className="delete-modal-body"
                            idFooter="delete-modal-footer"
                            confirmBtnLabel={$$('ok')}
                            confirmBtnClass="success"
                            idBtnPrimary="btn-success"
                            headerClassName="h5 align-items-center"
                            primary={true}
                            idBtnSecondary="btn-secondary">
                <TemplateSelector templates={this.state.doctor_tests_templates} onSelect={(t) => {
                    this.setState({tests_template: cloneDeep(t), show_pick_test_template_modal: false});
                }}/>
            </CenteredModal2>

            {this.state.addUpdateModal.show && <PrescriptionForm
                show={this.state.addUpdateModal.show}
                onHide={this.onHideAddUpdateModal}
                selectedUser={this.props.selectedUser}
                selectedEntry={this.state.selectedEntry}
                updatePrescription={this.updatePrescription}
                createPrescription={this.createPrescription}
                i18n={this.props.i18n}
                provider_id={this.props.userId}
                encounter_id={this.props.encounter.id}
                settings={this.props.settings.data}
                template={this.state.prescription_template}
            />}

            <CenteredModal title={$$('delete_note_modal_header')}
                           show={this.state.showDeletePrescriptionTemplate}
                           onHide={() => this.setState({
                               showDeletePrescriptionTemplate: false, show_pick_template_modal: true
                           })}
                           onConfirm={this.onDeletePrescriptionTemplate}
            >
                {$$('delete_template_modal_message')}
            </CenteredModal>

            <CenteredModal2 header={$$('pick_prescription_template')}
                            dialogClassName='delete-modal'
                            show={this.state.show_pick_template_modal}
                            onHide={this.hideTemplateModal}
                            noScroll={true}
                            cancelBtnLabel={$$('cancel_btn')}
                            onConfirm={this.onDeletePrescription}
                            id="prescription-templates-modal-title"
                            className="delete-modal-body"
                            idFooter="delete-modal-footer"
                            confirmBtnLabel={$$('ok')}
                            confirmBtnClass="success"
                            idBtnPrimary="btn-success"
                            headerClassName="h4"
                            primary={true}
                            idBtnSecondary="btn-secondary">
                <p className={"text-left"}>{$$("pick_prescription_template_message")}</p>
                <Search placeholder={$$("search_templates")} filterUsers={(e) => {
                    if (e) {
                        this.setState({search: e, page: 0});
                    } else {
                        this.setState({
                            search: "",
                            page: 0
                        });
                    }
                }}/>

                <div className={"scrollable-prescriptions"}>
                    <div className="list-group text-left shadow-boxes-list">
                        {this.state.prescriptionTemplates && this.state.prescriptionTemplates.length === 0 &&
                        <div>{this.noResults()}</div>}
                        {this.state.prescriptionTemplates && this.state.prescriptionTemplates.map((t, i) => {
                            return <a key={i} className="list-group-item" href="#" onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.setState({
                                    prescription_template: t,
                                    selectedEntry: null,
                                    show_pick_template_modal: false,
                                    addUpdateModal: {show: true}
                                })
                            }}>
                                <div className={"prescription-title"}>
                                    <h6>{t.template_name}</h6>
                                    <span style={{paddingTop: "5px"}} className={"la la-close"} onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.setState({
                                            showDeletePrescriptionTemplate: true,
                                            selectedTemplateToDelete: t,
                                            show_pick_template_modal: false
                                        })
                                    }}/>
                                </div>

                                <div
                                    className="smaller-text base-font-color">{t.name} - {getPrescriptionDetailsString(t)}</div>
                            </a>
                        })}
                    </div>
                </div>
            </CenteredModal2>
            <CenteredModal2
                title={$$('delete_prescription')}
                headerClassName="modal-header-class"
                body={$$('delete_prescription_message')}
                dialogClassName='delete-modal'
                show={this.state.deletedPlanModal.show}
                onHide={() => this.setState({deletedPlanModal: {show: false}})}
                cancelBtnLabel={$$('cancel_btn')}
                onConfirm={this.onDeletePrescription}
                id="delete-modal-prescription"
                className="delete-modal-body"
                idFooter="delete-modal-footer"
                confirmBtnLabel={$$('delete_label')}
                confirmBtnClass="danger"
                idBtnPrimary="btn-danger"
                idBtnSecondary="btn-secondary">
                <h5 className={"bold"}>
                    {this.state.selectedEntryToDelete ? this.state.selectedEntryToDelete.name : ''}
                </h5>
                <div className="bin-align">
                    <i className="fas fa-trash-alt fa-3x"/>
                </div>
            </CenteredModal2>
            <DeleteNhisPrescriptionModal
                title={$$('nhis.delete_prescription')}
                headerClassName="modal-header-class"
                body={$$('nhis.delete_prescription_message')}
                show={this.state.deleteNhisPrescriptionModal}
                onHide={() => this.setState({
                    deleteNhisPrescriptionModal: false,
                    selectedNhisPrescriptionNrnToDelete: null,
                    deleteNhisPrescriptionModalError: null
                })}
                cancelBtnLabel={$$('cancel_btn')}
                onConfirm={this.deleteNhisPrescription}
                id="delete-modal-nhis-prescription"
                className="delete-modal-body"
                idFooter="delete-modal-footer"
                confirmBtnLabel={$$('delete_label')}
                confirmBtnClass="danger"
                idBtnPrimary="btn-danger"
                idBtnSecondary="btn-secondary"
                deleteNhisPrescriptionModalError={this.state.deleteNhisPrescriptionModalError}
                deleteNhisPrescriptionInProgress={this.state.deleteNhisPrescriptionInProgress}
                selectedNhisPrescriptionNrnToDelete={this.state.selectedNhisPrescriptionNrnToDelete}
            />
            <CenteredModal2
                title={$$('delete_test_results')}
                headerClassName="modal-header-class"
                body={$$('delete_test_results_message')}
                dialogClassName='delete-modal'
                show={this.state.showDeleteTestResultsModal}
                onHide={() => this.setState({
                    showDeleteTestResultsModal: false, selectedTestResultToDelete: undefined
                })}
                cancelBtnLabel={$$('cancel_btn')}
                onConfirm={this.onDeleteTestResults}
                id="delete-modal-test-results"
                className="delete-modal-body"
                idFooter="delete-modal-footer"
                confirmBtnLabel={$$('delete_label')}
                confirmBtnClass="danger"
                idBtnPrimary="btn-danger"
                idBtnSecondary="btn-secondary">
                <h5 className={"bold"}>
                    {this.state.selectedTestResultToDelete ? this.state.selectedTestResultToDelete.name : ''}
                </h5>
                <div className="bin-align">
                    <i className="fas fa-trash-alt fa-3x"/>
                </div>
            </CenteredModal2>

            {/*{this.state.isNhis &&
            <TestFetchPrescriptions provider={this.props.loggedInUser} orgId={this.props.appointment.organization_id}
                                    patient={this.props.selectedUser}/>}*/}
        </div>)
    }
}

ExaminationTab.propTypes = {
    clearIcd: PropTypes.func,
    clearSelectedEncounter: PropTypes.func,
    loggedInUser: PropTypes.any,
    createEncounter: PropTypes.func,
    i18n: PropTypes.object,
    icdEntries: PropTypes.array,
    searchIcd: PropTypes.func,
    selectAppointmentInProgress: PropTypes.func,
    appointment: PropTypes.object,
    encounter: PropTypes.object,
    selectedUser: PropTypes.object,
    settings: PropTypes.object,
    prescription: PropTypes.object,
    prescriptions: PropTypes.object,
    encounterId: PropTypes.string,
    updateAppointmentStatus: PropTypes.func,
    updateEncounter: PropTypes.func,
    userId: PropTypes.string
}

function EncounterLine({label, value, children, paddingLeft = "2rem"}) {
    const show = useMemo(() => {
        if (Array.isArray(value)) {
            return value.length > 0;
        } else return !!value;
    }, [value])

    if (!show) {
        return null;
    }

    return <>
        <div className="row" style={{"width": "100%"}}>
            <div className='medrec-grey-2' style={{
                "width": "20%", "textAlign": "right"
            }}>{label}</div>
            <div className="whitespace-pre-line"
                 style={{"width": "80%", "textAlign": "left", "paddingLeft": paddingLeft}}>
                {children ? children : value}
            </div>
        </div>
        <hr/>
    </>
}

function EncounterLine2({label, value, children}) {
    const show = useMemo(() => {
        if (Array.isArray(value)) {
            return value.length > 0;
        } else return !!value;
    }, [value])

    if (!show) {
        return null;
    }
    return <>
        <div className="row" style={{"width": "100%"}}>
            <div className='medrec-grey-2' style={{
                "width": "20%", "textAlign": "right"
            }}>{label}</div>
            <div className="" style={{
                "display": "inline-block", "width": "80%", "paddingLeft": "1rem"
            }}>
                {children ? children : value}
            </div>
        </div>
        <hr/>
    </>
}
