/* eslint-disable */
import React, {Component} from 'react'
import {connect} from 'react-redux'
import Video from './Video'
import {updateVideoData} from "../../actions/video_actions";
import {
    IDLE
} from "../../constants/call_status";
import classNames from 'classnames'

class VideoCall extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Video />
                <div className="video-position-buttons">
                    <button className={classNames("btn", {"active": this.props.currentView === '2-col-split'})}
                            onClick={this.props.toSplit}><span className="fa fa-border-all"></span></button>
                    <button className={classNames("btn", {"active": this.props.currentView === '2-col-no-split'})}
                            onClick={this.props.toRight}><span className="fa fa-compress-alt"></span></button>
                    <button className={classNames("btn", {"active": this.props.currentView === 'video-only'})}
                            onClick={this.props.toFull}><span className="fa fa-expand"></span></button>
                </div>

                {(!this.props.startVideoCall && !this.props.incomingVideoCall && this.props.callStatus === IDLE) &&
                <>
                    <div className="video-buttons">
                        <span className="btn btn-success" onClick={this.props.onStartCall}><span
                            className="fas fa-phone fa-2x"/></span>
                    </div>
                </>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    selectedUser: state.selectedUser.data,
    i18n: state.language,
    auth: state.authentication,
    loggedUser: state.userInfo.data,
    videoSession: state.video.data.videoSession,
    currentUserQBId: state.video.data.currentUserQBId,
    callerQBId: state.video.data.callerQBId,
    recipientQBId: state.video.data.recipientQBId,
    videoMessageId: state.video.data.videoMessageId,
    callStatus: state.video.data.callStatus,
    audioMuted: state.video.data.audioMuted,
    videoMuted: state.video.data.videoMuted,
    cameraDevices: state.cameraDevices.list,
})

const mapDispatchToProps = {
    updateVideoData
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoCall)
