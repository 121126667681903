import React, {Component} from 'react'
import {connect} from 'react-redux'
import {$$} from '../../helpers/localization'
import moment from 'moment'
import _ from 'underscore'
import UserImage from '../shared/UserImage'
import classNames from 'classnames'
import no_notifcations from "../../resources/images/no_notifcations.png";
import {infoUtils} from "../../utils/infoUtils";
import RemoveNotificationButton from './RemoveNotificationButton'
import {Routes} from '../../constants/routes'
import {medicalHistoryHelper} from '../../helpers/medical_history_helper'
import {APPOINTMENTS_APPOINTMENT_VIDEO} from '../../constants/pages'
import {getAppointmentInfo} from '../../service/users_service'
import PropTypes from "prop-types";
import {dateTimeUtils} from "../../utils/dateTimeUtils"


class NotificationsPanel extends Component {
    state = {
        limit: this.props.limit,
        offset: 0
    }

    constructor(props) {
        super(props);
        this.index = 0;
    }

    redirectToAppointment(appointmentId) {
        getAppointmentInfo(appointmentId).then((appointmentInfo) => {
            if (appointmentInfo.status === "WAITING_FOR_DOCTOR_APPROVAL" ||
                appointmentInfo.status === "COMPLETED" ||
                appointmentInfo.status === "ACCEPTED") {
                let patient = appointmentInfo.participants.filter(a => a.participant_role === 'PATIENT')[0];
                medicalHistoryHelper.prepareMedicalHistoryData(APPOINTMENTS_APPOINTMENT_VIDEO, patient, appointmentInfo, Routes.APPOINTMENT);
            } else null;
        });
    }

    /**
     * Clear notifications from state on unmount.
     */
    componentWillUnmount = () => {
        this.props.clearNotifications();
    }

    /**
     * Creates the message and date cell of the table
     *
     * @param {object} notification - the notification object
     * @returns {ReactElement} the created table cell
     */
    getMessageAndDateCell = (notification) => {
        let appointment = JSON.parse(notification.json_payload);
        let notificationImageClass = 'patient-img';
        let redirectable = (appointment.appointmentStatus === "ACCEPTED"
            || appointment.appointmentStatus === "WAITING_FOR_DOCTOR_APPROVAL"
            || appointment.appointmentStatus === "ACCEPTED"
        );

        return <td key={++this.index}>
            <div className='d-flex p-2'>
                <div className='d-flex justify-content-center notifications-user-picture'>
                    <UserImage userID={appointment.patientId}
                               classnames={notificationImageClass}/>
                </div>
                <div style={{'marginLeft': '5px', 'float': 'left', 'align': 'left', 'alignSelf': 'center'}}>
                    <div className="text-left" onClick={() => {
                        redirectable ?
                            this.redirectToAppointment(appointment.appointmentId) : null
                    }}
                         style={{
                             'textDecoration': redirectable ? "underline" : "none",
                             'cursor': redirectable ? 'pointer' : 'default'
                         }}
                    >
                        {appointment.patientFullName}
                        {this.getNotificationType(notification.type)}  &nbsp;
                        {moment(appointment.startsAt).format('D MMMM HH:mm')}
                        .
                    </div>
                    <div className="row medrec-grey-2" style={{'marginLeft': '5px'}}>
                        <i className='flaticon-calendar-with-a-clock-time-tools medrec-grey-2'/>
                        &nbsp;
                        {dateTimeUtils.getElapsedTime(notification.date_created)}
                    </div>
                </div>
            </div>
        </td>;
    }
    getNotificationType = (notificationType) => {
        switch (notificationType) {
            case "APPOINTMENT_NEW":
                return $$("created_new_appointment_label");
            case "APPOINTMENT_CANCELED":
                return $$("canceled_their_appointment_label");
            case "CONSULTATION_PAID":
                return $$("consultation_paid");
            case "CONSULTATION_CANCELED":
                return $$("consultation_canceled");
            default:
                return;
        }
    }

    /**
     * Creates the actions cell of the table
     *
     * @param {object} notification - the notification object
     * @returns {ReactElement} the created table cell
     */
    getActionsCell = (notification) => {
        return <td key={++this.index} className='text-center notifications-actions-cell'>
            <div className='mark-notification-as-seen left-margin-ten' style={{"float": "right"}}>
                <RemoveNotificationButton title={$$('remove_label')} style={{'marginLeft': '0.625rem'}} onClick={() => {
                    this.markNotificationAsSeen(notification.id);
                }} hidden={notification.seen}/>
                <span className="seen-label" hidden={!notification.seen}>{$$('seen_label')}</span>
            </div>
        </td>;
    }

    /**
     * Creates the rows of the table
     *
     * @param {object} notification - the notification object
     * @param {int} idx - index value
     * @returns {Array} the table rows created
     */
    getRow = (notification, idx) => {
        let cells = [];

        cells.push(this.getMessageAndDateCell(notification));
        cells.push(this.getActionsCell(notification));

        const activeClass = classNames('', {
            'notification-seen': (notification.seen)
        });

        return <tr key={idx} className={activeClass}>{cells}</tr>;
    }

    /**
     * Creates the full body of the table
     *
     * @returns {Array} the table rows
     */
    getBody = () => {
        let allRows = [];

        for (let i = 0; i < this.props.notifications.length; ++i) {
            allRows.push(this.getRow(this.props.notifications[i], i));
        }

        return [].concat.apply([], allRows);
    }

    /**
     * Change notification status to seen for selected notification.
     *
     * @param {number} notificationId - id of the selected notification
     */
    markNotificationAsSeen = (notificationId) => {
        this.props.markNotificationAsSeen(notificationId, this.state.offset + this.state.limit, 0, this.props.notSeenOnly, false);
    }

    /**
     * Load more notifications
     */
    loadMoreNotifications = () => {
        this.props.getAllNotifications(this.state.limit, this.state.offset + this.state.limit, this.props.notSeenOnly, true);
        this.setState({
            offset: this.state.offset + this.state.limit
        });
    }

    render() {

        let noNotificationsObj = {
            imgClass: 'no-pending-notifications-img',
            primaryLabelClass: 'no-pending-notifications-primary-label',
            secondaryLabelClass: '',
            src: no_notifcations,
            primaryLabel: this.props.notSeenOnly ? $$("no_new_notifications_label") : $$("no_notifications_label"),
            secondaryLabel: ''
        }

        let lang = _.contains(['en', 'sq'], this.props.i18n.selected) ? 'en' : this.props.i18n.selected;
        moment.locale(lang.lang);

        return (
            <div className="text-center">
                {this.props.notifications.length > 0 &&
                    <div className='row'>
                        <div className='col-xs-12 col-md-12'>
                            <div className='row p-3'>
                                <table id='patients' style={{'width': '100%'}}>
                                    <tbody>
                                    {this.getBody()}
                                    </tbody>
                                </table>
                                <div className="row text-center" style={{"width": "100%", "marginTop": "1rem"}}>
                                <span className="notifications-load-more"
                                      onClick={this.loadMoreNotifications}>{$$("load_more")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {Object.keys(this.props.notifications).length === 0 &&
                    <div className="row text-center" style={{"width": "100%", "marginTop": "1rem"}}>
                    <span className="notifications-load-more" onClick={this.loadMoreNotifications}>
                        {infoUtils.noData(noNotificationsObj)}
                    </span>
                    </div>
                }
            </div>
        )
    }
}

NotificationsPanel.propTypes = {
    notifications: PropTypes.any,
    i18n: PropTypes.object,
    limit: PropTypes.number,
    notSeenOnly: PropTypes.any,
    getAppointmentInfo: PropTypes.func,
    clearNotifications: PropTypes.func,
    getAllNotifications: PropTypes.func,
    markNotificationAsSeen: PropTypes.func
}

const mapStateToProps = (state) => ({
    i18n: state.language
})

export default connect(mapStateToProps, {getAppointmentInfo})(NotificationsPanel)
