import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import Select from '../shared/Select'
import {CYCLE, DURATION_FOR_X_DAYS, EVERY_X_DAYS,} from '../../constants/medications_constants';
import HowToTake from './HowToTake';
import PropTypes from "prop-types";


export default class NotWhenNeededForms extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.errors?.scheduleDaysInactive) {
            if (this.scheduleDaysInactiveField1) {
                this.scheduleDaysInactiveField1.setCustomValidity(this.props.errors?.scheduleDaysInactive)
            }
            if (this.scheduleDaysInactiveField2) {
                this.scheduleDaysInactiveField2.setCustomValidity(this.props.errors?.scheduleDaysInactive)
            }
        } else {
            if (this.scheduleDaysInactiveField1) {
                this.scheduleDaysInactiveField1.setCustomValidity("")
            }
            if (this.scheduleDaysInactiveField2) {
                this.scheduleDaysInactiveField2.setCustomValidity("")
            }
        }

        if (this.props.errors?.scheduleDaysActive) {
            if (this.scheduleDaysActiveField) {
                this.scheduleDaysActiveField.setCustomValidity(this.props.errors?.scheduleDaysActive)
            }
        } else {
            if (this.scheduleDaysActiveField) {
                this.scheduleDaysActiveField.setCustomValidity("")
            }
        }

        if (this.props.errors?.durationInDays) {
            if (this.durationInDaysField) {
                this.durationInDaysField.setCustomValidity(this.props.errors?.durationInDays)
            }
        } else {
            if (this.durationInDaysField) {
                this.durationInDaysField.setCustomValidity("")
            }
        }
    }

    render() {
        return (
            <div className="form-group prescription-form-label">
                <div className="row form-group prescription-form-label">
                    <div className="col-sm-12">
                        {this.props.scheduleType === EVERY_X_DAYS &&
                        <div className="form-group d-flex align-items-center">
                            <label className="form-control-label flex-grow-0 flex-shrink-0 pr-2 mb-0">{$$("every")}</label>
                            <div className={"flex-grow-1 flex-shrink-0"}>
                                <input type="text" className="form-control"
                                       value={this.props.scheduleDaysInactive} placeholder={$$('days')}
                                       ref = {(ref)=>{this.scheduleDaysInactiveField1 = ref}}
                                       name="scheduleDaysInactive" onChange={this.props.onInputChange} required/>
                            </div>
                            <div className="flex-grow-0 flex-shrink-0 pl-2">
                                {$$("days")}
                            </div>
                        </div>}
                        {this.props.scheduleType === CYCLE &&
                        <>
                            <label>{$$("take")}</label>
                            {<input type="text" className="form-control"
                                    ref = {(ref)=>{this.scheduleDaysActiveField = ref}}
                                    value={this.props.scheduleDaysActive} placeholder={$$('take')}
                                    name="scheduleDaysActive" onChange={this.props.onInputChange} required/>}
                            <label>{$$("rest")}</label>
                            {<input type="text" className="form-control"
                                    ref = {(ref)=>{this.scheduleDaysInactiveField2 = ref}}
                                    value={this.props.scheduleDaysInactive} placeholder={$$('rest')}
                                    name="scheduleDaysInactive" onChange={this.props.onInputChange} required/>}
                        </>}
                    </div>
                </div>
                <HowToTake
                    getMedicationFrequencyString={this.props.getMedicationFrequencyString}
                    howToTake={this.props.howToTake}
                    scheduleTimesPerDay={this.props.scheduleTimesPerDay}
                    scheduleHours={this.props.scheduleHours}
                    onChange={this.props.onChange}
                    errors={this.props.errors}
                />
                <div className="row">
                    <div className="col-sm-6">
                        <Select
                            label={$$("duration")}
                            name="durationType"
                            options={this.props.getMedicationDurationString()}
                            value={this.props.durationType}
                            onChange={this.props.onInputChange}
                            placeHolder={$$('duration')}
                            style={{
                                'minWidth': '100%',
                                'maxWidth': '100%',
                                'zIndex': '1',
                                'opacity': '70%'
                            }}/>

                    </div>
                    {this.props.durationType && this.props.durationType === DURATION_FOR_X_DAYS &&
                    <div className="col-sm-6">
                        <label>{$$("number_of_days")}</label>
                        <input type="text" className="form-control"
                               ref = {(ref)=>{this.durationInDaysField = ref}}
                               value={this.props.durationInDays}
                               placeholder={$$('for_x_days_until')} name="durationInDays"
                               onChange={this.props.onInputChange} required/>
                    </div>}
                </div>
            </div>
        )
    }
}


NotWhenNeededForms.propTypes = {
    durationInDays: PropTypes.number,
    durationType: PropTypes.string,
    ends: PropTypes.number,
    getCheckBoxes: PropTypes.func,
    getCheckBoxesWeekend: PropTypes.func,
    getMedicationDurationString: PropTypes.func,
    getMedicationFrequencyString: PropTypes.func,
    getTimesArrayEveryXHours: PropTypes.func,
    getTimesArrayXTimesADay: PropTypes.func,
    howToTake: PropTypes.string,
    onChange: PropTypes.func,
    onEndDateChange: PropTypes.func,
    onInputChange: PropTypes.func,
    output: PropTypes.array,
    outputTimes: PropTypes.array,
    scheduleDaysActive: PropTypes.number,
    scheduleDaysInactive: PropTypes.number,
    scheduleHours: PropTypes.number,
    scheduleTimesPerDay: PropTypes.number,
    scheduleType: PropTypes.string,
    setWeekend: PropTypes.func,
    showWeekend: PropTypes.bool
}