import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import {
    Button,
    Modal
} from "react-bootstrap";
import PropTypes from "prop-types";


export default class CenteredModal extends Component {

    render() {
        if (!this.props.show) {
            return null;
        }
        return (
            <Modal show={this.props.show} onHide={this.props.onHide} backdropClassName={this.props.backdropClassName ? this.props.backdropClassName : ""}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.children}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={this.props.confirmBtnClass ? this.props.confirmBtnClass : "primary"}
                            onClick={this.props.onConfirm}>
                        {this.props.confirmBtnLabel ? this.props.confirmBtnLabel : $$('confirm_btn_label')}
                    </Button>
                    <Button variant="secondary" onClick={this.props.onCancel ? this.props.onCancel : this.props.onHide}>
                        {this.props.cancelBtnLabel ? this.props.cancelBtnLabel : $$('close_btn_label')}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}


CenteredModal.propTypes = {
    children: PropTypes.any,
    confirmBtnClass: PropTypes.any,
    confirmBtnLabel: PropTypes.string,
    cancelBtnLabel: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    onHide: PropTypes.func,
    show: PropTypes.bool,
    size: PropTypes.string,
    title: PropTypes.any
};