import React from 'react'
import PropTypes from "prop-types";


export class WizardHeader extends React.Component {
    constructor(props) {
        super(props)
        this.index = 0;
    }

    getWizardStepNames = () => {
        return this.props.wizardSteps.map((w, idx) => {
            return <React.Fragment key={idx}>
                <div className="text-center small-padding">
                    <div className={
                        w.step === this.props.wizardStep
                            ? 'wizard-blue-icons'
                            : w.completed
                                ? 'wizard-green-icons'
                                : 'wizard-grey-icons'}
                    >
                        <i className='kt-menu__link-icon patient-details-icon far fa-check-circle'/>
                    </div>
                    <div className={
                        w.step === this.props.wizardStep
                            ? 'wizard-blue-title'
                            : !w.completed
                                ? 'wizard-grey-title'
                                : 'wizard-title'}>{w.name}</div>
                </div>
                {idx < this.props.wizardSteps.length-1 && <div className={
                    this.props.wizardSteps[idx].completed
                        ? 'wizard-header-green-rectangle'
                        : 'wizard-header-grey-rectangle'}>
                </div>}
            </React.Fragment>
        });
    }


    render() {
        return (
            <div className='wizard-header-container'>
                {this.getWizardStepNames()}
            </div>
        )
    }
}

WizardHeader.propTypes = {
    wizardSteps: PropTypes.array,
    i18n:  PropTypes.object,
    wizardStep:  PropTypes.number
}

export default WizardHeader
