import React, {Component} from 'react'
import UserImage from '../shared/UserImage'
import moment from 'moment'
import classNames from 'classnames'
import {appointmentUtils} from '../../utils/appointmentUtils'
import {formatUtils} from '../../utils/formatUtils'
import {Routes} from '../../constants/routes'
import {medicalHistoryHelper} from '../../helpers/medical_history_helper'
import {APPOINTMENTS_APPOINTMENT_VIDEO} from '../../constants/pages'
import {COMPLETED} from '../../constants/appointment_payment_status';
import {$$} from '../../helpers/localization';
import StatusIcon from "./StatusIcon";
import MessagesButton from '../shared/MessagesButton'
import OpenAppointmentButton from './OpenAppointmentButton'
import EmailButton from '../shared/EmailButton'
import CancelAppointmentButton from './CancelAppointmentButton'
import {updateAppointmentStatus} from '../../actions/users_actions';
import {connect} from 'react-redux'
import CenteredModal from '../shared/CenteredModal'
import PropTypes from "prop-types";
import UserImageWrap from "../shared/UserImageWrap";
import {APPOINTMENTS_FILTER, getColorForOption} from "../../constants/select_options";
import CancelAppointmentModal from "../appointment/CancelAppointmentModal";


class AppointmentsListItemForCalendar extends Component {
    state = {
        cancelAppointmentModalOpened: false
    }

    constructor(props) {
        super(props);
    }

    /**
     * An event handler triggered when an appointment is clicked
     */
    onAppointmentClick = () => {
        this.props.onAppointmentChange(this.props.appointment);
    }

    /**
     * Cancel appointment on confirmation
     */
    cancelAppointment = (app, note) => {
        this.props.updateAppointmentStatus(this.props.appointment.id, "CANCELED_BY_PROVIDER", note);
        this.setState({cancelAppointmentModalOpened: false});
        this.props.cancelAppointment(this.props.appointment);
    }

    render() {
        let patientImageClass = 'patient-img';
        const activeClass = classNames('appointment-containter', {
            'appointment-active': (this.props.selectedAppointmentId === this.props.appointment.id)
        });

        let participant = this.props.appointment.participants.find(p => p.participant_role === 'PATIENT');

        return (
            <div id='appointment' className={activeClass} onClick={this.onAppointmentClick}>
                <div className='d-flex w-100'>
                    <StatusIcon status={this.props.appointment.status} className="status-icon"/>
                    <div className='d-flex w-100'>
                        <div className='d-flex justify-content-center appointment-img-container align-items-start'>
                            <UserImageWrap userID={participant?.user_id}
                                           show_red_dot={this.props.appointment.has_not_seen_patient_documents}
                                       classnames={patientImageClass}/>
                        </div>
                        <div className={"dropdown-link ml-2"}>
                            {participant && <div>{participant.fullname}</div>}
                            <div className='medrec-grey-2'>
                                {appointmentUtils.appointmentType(this.props.appointment)}
                            </div>
                            {!appointmentUtils.isGroupEventParent(this.props.appointment) ? <div
                                className={this.props.appointment.payment_status === COMPLETED ? "medrec-green-1" : "medrec-grey-2"}>
                                {formatUtils.currencyFormat(this.props.appointment.appointment_price.price_cents, this.props.appointment.appointment_price.currency)}
                            </div> : <div>
                                {this.props.appointment.used_slots} / {this.props.appointment.max_patients}
                            </div>}
                            <div>
                                {appointmentUtils.location(this.props.appointment)}
                            </div>

                            <div
                                 style={{"color": getColorForOption(APPOINTMENTS_FILTER.COLOR, this.props.appointment.status)}}>
                                {$$(this.props.appointment.status.toLowerCase())}
                            </div>

                        </div>
                    </div>
                    <div className={"dropdown-link format-date flex-0-0-auto"}>
                        <i className='kt-menu__link-icon medrec-grey-2 far fa-clock'/> &nbsp;
                        <b>{moment(this.props.appointment.starts_at).format('HH:mm')}</b>
                    </div>
                </div>
                {this.props.selectedAppointmentId === this.props.appointment.id &&
                <div className='text-center'>
                    <div className='row patient-details-cell-container zero-margin-row'>
                        {participant && <MessagesButton title={$$('messages_label')} style={{'marginLeft': '0.625rem'}}
                                        onClick={() => {
                                            this.props.onMessagesClick(participant);
                                        }}/>}
                        {participant && <EmailButton title={$$('email_label')} style={{'marginLeft': '0.625rem'}} onClick={() => {
                            window.location = "mailto:" + this.props.appointment.participants.filter(a => a.participant_role === 'PATIENT')[0].email;
                        }}/>}
                        {appointmentUtils.canCancel(this.props.appointment) &&
                        <CancelAppointmentButton title={$$('cancel_appointment_label')}
                                                 style={{'marginLeft': '0.625rem'}} onClick={() => {
                            this.setState({cancelAppointmentModalOpened: true});
                        }}/>}
                        {! appointmentUtils.isAppointmentCanceled(this.props.appointment.status) &&
                        <OpenAppointmentButton title={$$('open_appointment_label')} style={{'marginLeft': '0.625rem'}}
                                               onClick={() => {
                                                   if (appointmentUtils.isGroupEventParent(this.props.appointment)) {
                                                       medicalHistoryHelper.openGroupAppointmentPage(this.props.appointment)
                                                   } else {
                                                       let patient = this.props.appointment.participants.filter(a => a.participant_role === 'PATIENT')[0];
                                                       medicalHistoryHelper.prepareMedicalHistoryData(APPOINTMENTS_APPOINTMENT_VIDEO, patient, this.props.appointment, Routes.APPOINTMENT);
                                                   }
                                               }}/>}
                    </div>
                </div>}
                <hr/>
                <CancelAppointmentModal
                    appointment={this.props.appointment}
                    show={this.state.cancelAppointmentModalOpened}
                    onHide={() => {
                        this.setState({cancelAppointmentModalOpened: false})
                    }}
                    onConfirm={this.cancelAppointment}
                />
            </div>
        )
    }
}

AppointmentsListItemForCalendar.propTypes = {
    appointment: PropTypes.object,
    cancelAppointment: PropTypes.func,
    i18n: PropTypes.object,
    onAppointmentChange: PropTypes.func,
    onMessagesClick: PropTypes.func,
    selectAppointment: PropTypes.func,
    selectAppointmentInProgress: PropTypes.func,
    selectedAppointmentId: PropTypes.string,
    updateAppointmentStatus: PropTypes.func
}

export default connect(null, {updateAppointmentStatus})(AppointmentsListItemForCalendar);
