import React, {useCallback, useEffect, useState} from "react"
import PatientProviderForm from "./PatientProviderForm";
import Button from "react-bootstrap/Button";
import {$$} from "../../../../helpers/localization";
import Modal from "react-bootstrap/Modal";
import {NhisMedicalNoticeProvider, useNhisMedicalNoticeContext} from "./NhisMedicalNoticeProvider";
import {v4} from "uuid";
import {FG, FG50SmallL, FG50SmallR, FormRow, Input, InputTextArea, RequiredIndicator} from "./HelperFormComponents";
import {Select} from "../../../shared/Select";
import DatePicker from "react-datepicker";
import {dateAsStr} from "./NhisPrescription";
import {ModalBody} from "react-bootstrap";
import {dateTimeUtils} from "../../../../utils/dateTimeUtils";
import {
    nhisCreateMedicalNotice,
    nhisLogin,
    nhisSignCreateMedicalNotice,
} from "../../../../service/nhis_service";

export function NhisMedicalNotice({onClose, patient, provider, orgId, encounter, lang, onSuccess}) {
    return <NhisMedicalNoticeProvider>
        <NhisMedicalNoticeContent onCancel={onClose} patient={patient} provider={provider} orgId={orgId}
                                  encounter={encounter} lang={lang} onSuccess={onSuccess}/>
    </NhisMedicalNoticeProvider>
}

function NhisMedicalNoticeContent({onCancel, patient, provider, orgId, encounter, lang, onSuccess}) {
    const [step, setStep] = useState(1);
    const [notice, setNotice] = useState({});
    const [sendingInProgress, setSendingInProgress] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState(false);
    const [nrn, setNrn] = useState(false);
    const [viewModel, setViewModel] = useState();

    const {
        isLoading, isError, reload,
        countyOptions,
        specialtyOptions
    } = useNhisMedicalNoticeContext();

    if (isError && !isLoading) {
        return <Modal backdrop="static" show={true} size="lg">
            <MedicalNoticeModalHeader/>
            <Modal.Body>
                <div className="mt-4 mb-4">
                    <p className={"text-center text-danger"}>{$$("nhis.error_loading_nomenclatures")}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" variant="primary" onClick={reload}>{$$("nhis.try_again")}</Button>
                <Button variant={"outline-primary"} type={"button"} className="ml-2"
                        onClick={onCancel}>{$$("cancel_btn")}</Button>
            </Modal.Footer>
        </Modal>
    }

    if (isLoading) {
        return <Modal backdrop="static" show={true} size="lg">
            <Modal.Body>
                <div className="mt-4 mb-4">
                    <div className={"text-center mt-4"}>{$$("nhis.loading_data_message")}</div>
                </div>
            </Modal.Body>
        </Modal>
    }

    if (step === 1) {
        return <Modal backdrop="static" show={true} size="lg">
            <MedicalNoticeModalHeader/>
            <Modal.Body>
                <PatientProviderForm countyOptions={countyOptions}
                                     subject={patient}
                                     provider={provider}
                                     specialtyOptions={specialtyOptions}
                                     orgId={orgId}
                                     exam={notice}
                                     onUpdate={(patient, provider) => {
                                         let noticeCopy = {...notice};
                                         noticeCopy.performer = provider;
                                         noticeCopy.subject = patient;
                                         if (!noticeCopy.medicalNotice) {
                                             noticeCopy.medicalNotice = {};
                                         }
                                         setNotice(noticeCopy);
                                         setStep(2);
                                     }}
                                     lang={lang}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"primary"} form={"patient_provider_form"}
                        type={"submit"}>{$$("nhis.btn.save")}</Button>
                <CloseAndExitBtn onExit={onCancel} />
            </Modal.Footer>
        </Modal>
    }

    const _submitToNhis = () => {
        let message = {
            ...notice,
            senderId: notice.performer.pmi
        }
        message.performer.role = "1";
        message.medicalNotice.authoredOn = dateAsStr(new Date());

        setSendingInProgress(true);
        setError(undefined);
        nhisLogin((loginSuccess) => {
            nhisSignCreateMedicalNotice(message, (signedDoc) => {
                nhisCreateMedicalNotice(loginSuccess.accessToken, signedDoc)
                    .then(async res => {
                        let contents = res["nhis:message"]["nhis:contents"];
                        let error = contents["nhis:error"];
                        if (error) {
                            if (Array.isArray(error)) {
                                setError(error[0]["nhis:reason"]["value"]);
                            } else {
                                setError(error["nhis:reason"]["value"]);
                            }
                        } else {
                            setSuccess(true);
                            let nrn = contents["nhis:nrnMedicalNotice"]["value"];
                            setNrn(nrn);
                            let noticeUpdated = {...message, viewModel:viewModel}
                            noticeUpdated.medicalNotice.nrnMedicalNotice = nrn;
                            setNotice(noticeUpdated);
                            onSuccess(noticeUpdated);
                        }
                        setSendingInProgress(false)
                    })
            }, (e) => {
                setError(e);
                setSendingInProgress(false);
            })
        }, (e) => {
            setSendingInProgress(false);
            setError(e)
        }).catch(error => {
            console.log(error);
            setSendingInProgress(false);
            setError($$("nhis.service_error"));
        })
    }

    if (step === 3) {
        if (sendingInProgress) {
            return <Modal backdrop="static" show={true} size="lg">
                <MedicalNoticeModalHeader/>
                <Modal.Body>
                    <div><p className="text-center">{$$("nhis.processing_body")}</p>
                    </div>
                </Modal.Body>
            </Modal>
        }

        if (success) {
            return <Modal backdrop="static" show={true} size="lg">
                <MedicalNoticeModalHeader/>
                <Modal.Body>
                    <p className="text-center">{$$("nhis.medicalNotice.successfully_sent_to_nhis")}</p>
                    <h5 className="text-center">{$$("nhis.medicalNotice.nrn_number")}: {nrn}</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"primary"}  onClick={onCancel}
                            type={"button"}>{$$("close_btn_label")}</Button>
                </Modal.Footer>
            </Modal>
        }

        return <Modal backdrop="static" show={true} size="lg">
            <MedicalNoticeModalHeader/>
            <Modal.Body>
                {error && <div className="p-2 text-danger">{error}</div>}
                <NoticeView notice={notice} encounter={encounter} viewModel={viewModel}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"primary"} key={"back"}
                        type={"button"} onClick={() => {
                    setStep(step-1);
                    if (error) {
                        setError(undefined);
                    }
                }} className={"mr-auto"}>{$$("back")}</Button>
                <Button variant={"primary"} key={"medical_notice_issue"} onClick={_submitToNhis}
                        type={"button"}>{$$("nhis.btn.complete_med_notice")}</Button>
                <CloseAndExitBtn onExit={onCancel} />
            </Modal.Footer>
        </Modal>
    }

    return <Modal backdrop="static" show={true} size="lg">
        <MedicalNoticeModalHeader/>
        <Modal.Body>
            <MedicalNoticeForm medicalNotice={notice.medicalNotice} onUpdate={(model, viewModel) => {
                let updatedNotice = {...notice};
                updatedNotice.medicalNotice = model;
                setNotice(updatedNotice);
                setViewModel(viewModel);
                setStep(3)
            }} encounter={encounter} lang={lang}/>
        </Modal.Body>
        <Modal.Footer>
            <Button variant={"primary"} key={"back"}
                    type={"button"} onClick={() => {
                setStep(1)
            }} className={"mr-auto"}>{$$("back")}</Button>
            <Button variant={"primary"} key={"medical_notice_form"} form={"medical_notice_form"}
                    type={"submit"}>{$$("nhis.btn.save")}</Button>
            <CloseAndExitBtn onExit={onCancel} />
        </Modal.Footer>
    </Modal>
}

function MedicalNoticeForm({medicalNotice, onUpdate, encounter, lang}) {
    const [formClass, setFormClass] = useState("");
    const [lrn, setLrn] = useState(medicalNotice?.lrn || v4());
    const [basedOn, setBasedOn] = useState(medicalNotice?.basedOn || "")
    const [reason, setReason] = useState(medicalNotice?.reason || "")
    const [location, setLocation] = useState(medicalNotice?.location || "")
    const [institution, setInstitution] = useState(medicalNotice?.institution || "")
    const [note, setNote] = useState(medicalNotice?.note || "")
    const [fromDate, setFromDate] = useState(medicalNotice?.fromDate ? new Date(medicalNotice?.fromDate) : new Date());
    const [toDate, setToDate] = useState(medicalNotice?.toDate ? new Date(medicalNotice?.toDate) : null);

    const [code, setCode] = useState(medicalNotice?.code || "");
    const [icdCodeOptions, setIcdCodeOptions] = useState([]);

    const {
        reasonOptions,
        locationOptions,
    } = useNhisMedicalNoticeContext();

    useEffect(() => {
        if (basedOn !== encounter.nhis_examination_nrn) {
            setBasedOn(encounter.nhis_examination_nrn)
        }
        let codes = [encounter.main_diagnosis_code, ...encounter.concomitant_diseases_codes];
        let texts = [encounter.main_diagnosis, ...encounter.concomitant_diseases];
        let opts = codes.map((code, i) => ({value:code, text:texts[i]}));
        setIcdCodeOptions(opts);
        setCode(codes[0]);

    }, [encounter])

    const _toViewModel = (medicalNotice) => {
        let m = {};
        m.institution = medicalNotice.institution;
        m.basedOn = medicalNotice.basedOn;
        m.note = medicalNotice.note;
        m.fromDate = dateTimeUtils.getFormattedDate(new Date(medicalNotice.fromDate));
        m.toDate = medicalNotice.toDate ? dateTimeUtils.getFormattedDate(new Date(medicalNotice.toDate)) : null;
        m.reason = reasonOptions.find(o => o.value === medicalNotice.reason).text;
        if (medicalNotice.location) {
            m.location = locationOptions.find(o => o.value === medicalNotice.location).text;
        }
        if (medicalNotice.code) {
            let codes = [encounter.main_diagnosis_code, ...encounter.concomitant_diseases_codes];
            let texts = [encounter.main_diagnosis, ...encounter.concomitant_diseases];
            let idx = codes.findIndex(c => c === medicalNotice.code)
            m.diagnosis = texts[idx];
        }
        return m;
    }

    const _onSubmit = (e) => {
        e.preventDefault();
        if (!e.target.checkValidity()) {
            setFormClass("was-validated")
            return;
        }

        let model = {
            lrn:lrn,
            basedOn:basedOn,
            reason:reason,
            institution:institution,
            note: note ? note : null,
            fromDate:dateAsStr(fromDate)
        }

        if (reason !== "4") {
            model.toDate = dateAsStr(toDate);
            model.location = location;
            model.code = code;
        }

        onUpdate(model, _toViewModel(model))
    }

    const isSick = reason !== "4";

    return <form onSubmit={_onSubmit} className={`has-custom-validation ${formClass}`} id={"medical_notice_form"}
                 noValidate={true}>
        <fieldset>
            <FormRow>
                <FG50SmallL>
                    <label>{$$("nhis.medicalNotice.reason_label")}<RequiredIndicator/></label>
                    <Select placeHolder={$$("nhis.select")}
                            emptyOption={true}
                            options={reasonOptions}
                            class="custom-select-sm"
                            value={reason || ""}
                            required={true}
                            onChange={({value}) => {
                                setReason(value);
                            }}
                    />
                </FG50SmallL>
                <FG50SmallR>
                    <Input type="text" value={basedOn} label={"nhis.medicalNotice.based_on_label"}
                           required={true}
                           maxLength={36}
                           readOnly
                           disabled
                           setValue={setBasedOn}/>
                </FG50SmallR>
            </FormRow>
            {isSick && <FG>
                <label>{$$("nhis.medicalNotice.location_label")}<RequiredIndicator/></label>
                <Select placeHolder={$$("nhis.select")}
                        emptyOption={true}
                        options={locationOptions}
                        class="custom-select-sm"
                        value={location || ""}
                        required={isSick}
                        onChange={({value}) => {
                            setLocation(value);
                        }}
                />
            </FG>}
            {isSick && <FG>
                <label>{$$("nhis.medicalNotice.code_label")}<RequiredIndicator/></label>
                <Select placeHolder={$$("nhis.select")}
                        emptyOption={false}
                        options={icdCodeOptions}
                        class="custom-select-sm"
                        value={code || ""}
                        required={true}
                        onChange={({value}) => {
                            setCode(value);
                        }}
                />
            </FG>}
            <FormRow>
                <FG50SmallL>
                <label>{$$("from")}<RequiredIndicator/></label>
                <div>
                    <DatePicker
                        locale={lang || "en"}
                        required={true}
                        selected={fromDate}
                        onChange={setFromDate}
                        selectsStart
                        startDate={fromDate}
                        isClearable
                        dateFormat="dd/MM/yyyy"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        placeholderText={$$('from')}
                        className="form-control form-control-sm"
                        wrapperClassName="w-100"
                    />
                </div>
                </FG50SmallL>

            {isSick && <FG50SmallR>
                <label>{$$("to")}<RequiredIndicator/></label>
                <div>
                    <DatePicker
                        locale={lang || "en"}
                        required={true}
                        selected={toDate}
                        onChange={setToDate}
                        selectsStart
                        startDate={toDate}
                        minDate={fromDate}
                        isClearable
                        dateFormat="dd/MM/yyyy"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        placeholderText={$$('to')}
                        className="form-control form-control-sm"
                        wrapperClassName="w-100"
                    />
                </div>
            </FG50SmallR>}
            </FormRow>
            <FG>
                <InputTextArea required={true} setValue={(value) => {
                    setInstitution(value)
                }} label={"nhis.medicalNotice.institution_label"} value={institution || ""}
                               maxLength={200}/>
            </FG>
            <FG>
                <InputTextArea setValue={(value) => {
                    setNote(value)
                }} label={"nhis.medicalNotice.note_label"} value={note || ""}
                               maxLength={2000}/>
            </FG>
        </fieldset>
    </form>
}

function MedicalNoticeModalHeader() {
    return <Modal.Header className={"h5"}>
        {$$("nhis.medicalNotice.modal_header")}
    </Modal.Header>
}


function CloseAndExitBtn(props) {
    const [showConfirm, setShowConfirm] = useState(false);

    if (showConfirm) {
        return <Modal show={showConfirm} backdropClassName={"confirm-over-other-modal"}>
            <Modal.Header>
                <h5 className={"text-danger"}>{$$("warning_title")}</h5>
            </Modal.Header>
            <ModalBody>
                <p>{$$("nhis.confirm_exit_body")}</p>
            </ModalBody>
            <Modal.Footer>
                <Button type="button" size="sm" variant={"danger"}
                        onClick={props.onExit}>{$$("nhis.btn.confirm")}</Button>
                <Button type="button" size="sm" variant={"secondary"} onClick={() => {
                    setShowConfirm(false)
                }}>{$$("nhis.btn.cancel")}</Button>
            </Modal.Footer>
        </Modal>
    }

    return <button className="btn btn-danger ml-2" type="button"
                   onClick={() => setShowConfirm(true)}>{$$("nhis.btn.leave_prescription")}</button>
}

function NoticeView({notice, viewModel}) {
    const [model, setModel] = useState();

    useEffect(()=>{
        let m = {...viewModel};
        m.patientName = notice.subject.givenName + " " + notice.subject.familyName;
        setModel(m);
    }, [notice, viewModel])

    if (!model) {
        return <p className="mt-3 mb-3">{$$("processing")}</p>;
    }

    return <table className="table table-borderless medrec-default-text-color">
        <tbody>
            <ViewLine model={model} propName="patientName" labelKey="patient_label" />
            <ViewLine model={model} propName="basedOn" labelKey="nhis.medicalNotice.based_on_label" />
            <ViewLine model={model} propName="reason" labelKey="nhis.medicalNotice.reason_label" />
            <ViewLine model={model} propName="diagnosis" labelKey="nhis.medicalNotice.code_label" />
            <ViewLine model={model} propName="location" labelKey="nhis.medicalNotice.location_view_label" />
            <ViewLine model={model} propName="fromDate" labelKey="from" />
            <ViewLine model={model} propName="toDate" labelKey="to"/>
            <ViewLine model={model} propName="institution" labelKey="nhis.medicalNotice.institution_label" />
            <ViewLine model={model} propName="note" labelKey="nhis.medicalNotice.note_label" />
        </tbody>
    </table>
}

function ViewLine({model, propName, labelKey}) {
    if (model[propName]) {
        return <tr><td className="p-1 medrec-grey-2 text-right" style={{minWidth:"220px"}}>{$$(labelKey)}:</td><td className="p-1 whitespace-pre-line w-100 align-bottom">{model[propName]}</td></tr>
    }
    return null;
}