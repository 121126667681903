import React, {Component}  from 'react'
import {connect} from "react-redux";
import {updateConfiguration, fetchConfiguration} from "../../actions/settings_actions";
import {$$} from "../../helpers/localization";
import FormWithLoading from "../shared/FormWithLoading";
import {SETTINGS} from "../../actions/actions";
import classnames from "classnames";
import Select from "../shared/Select";
import {CONFIGURATION_OPTIONS, getResolvedOptions, SETTING_OPTIONS} from "../../constants/select_options";
import {KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import enGB from "date-fns/locale/en-GB";
import bg from "date-fns/locale/bg";
import sq from "date-fns/locale/sq";
import moment from 'moment'
import {QuestionMarkTooltip} from "../common/tooltips";
class ConfigurationForm extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            encounter_duration_mins:30,
            auto_approve_appointments:false,
            book_days_before_appointment:0,
            book_time_on_day_before_appointment:"23:59",
            lock_location_in_hours:4,
            formclass: '',
            errors: {}
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.configuration !== this.props.configuration && this.props.configuration_result.success) {
            this.setState({...this.props.configuration})
        }

        if (prevProps.configuration.server_modified_timestamp < this.props.configuration.server_modified_timestamp) {
            this.setState({successfulUpdate: true});
        }
    }

    componentDidMount() {
        this.props.fetchConfiguration();
    }

    onSelectChange = ({name, value}) => {
        const fields = Object.assign({}, this.state);
        fields[name] = value;
        this.setState(fields);
    }

    onCheckboxChanged = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.checked;
        this.setState(fields);
    };

    isCheckedDeadline = () => {
        return this.state.book_days_before_appointment != 0 || !this.state.book_time_on_day_before_appointment?.startsWith("23:59")
    };

    onDeadlineCheckboxChanged = (evt) => {
        if (evt.target.checked) {
            this.setState({book_days_before_appointment:0, book_time_on_day_before_appointment:"12:00:00"})
        } else {
            this.setState({book_days_before_appointment:0, book_time_on_day_before_appointment:"23:59:59"})
        }
    }

    onBook_days_before_appointmentChanged(v) {
        this.setState({book_days_before_appointment:v})
    }

    onLock_location_in_hoursChanged(v) {
        this.setState({lock_location_in_hours:v})
    }

    onLabelClick = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.htmlFor] = !fields[evt.target.htmlFor];
        this.setState(fields);
    }

    resetConfiguration = () => {
        this.setState({
            ...this.props.configuration,
            formclass: '',
            errors: {},
            successfulUpdate:false
        })
    }

    onSubmit = (evt) => {
        evt.preventDefault();

        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }

        if (evt.target.checkValidity() === true) {
            let req = { ...this.state };
            req.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            this.props.updateConfiguration(req);
            this.setState({formclass: ""});
        }
    }

    onTimeChange = (time) => {
        this.setState({book_time_on_day_before_appointment:moment(time).format("HH:mm:ss")})
    }

    timeToDate = (time) => {
        const [hours, minutes] = time.split(":");
        let date = new Date();
        date.setHours(hours, minutes, 59);
        return date;
    }

    getLocale = () => {
            switch(this.props.i18n.lang) {
                case "en":
                    return enGB
                case "bg":
                    return bg
                case "sq":
                    return sq
                default:
                    return enGB
            }

    }

    canSetupDynamicLocation = () => {
        return this.props.userInfo.organizations.find(org => org.enable_payments_for_on_site_appointments && org.can_enable_video_for_on_site_appointments) != null;
    }

    render() {
        let alert = '';
        if (this.state.successfulUpdate) {
            alert = (
                <div className="alert alert-success">
                    {$$('settings_updated_message')}
                </div>
            );
        }
        return (
            <div className="centered-form">
                <h2 className="text-center card-title">{$$("appointments_configuration")}</h2>
                <div>
                    {alert}
                    <FormWithLoading
                        formDisabled={this.props.formDisabled}
                        currentForm={SETTINGS}
                        marginTop="30%"
                        marginLeft="20%"
                        onSubmit={(evt) => this.onSubmit(evt)}
                        className={classnames(this.state.formclass)}
                        noValidate={true}>
                        {/*<div className="form-group">
                            <Select
                                label={$$('default_time_slot_duration_label')}
                                name="encounter_duration_mins"
                                options={getResolvedOptions(CONFIGURATION_OPTIONS.SLOT_DURATION)}
                                value={this.state.encounter_duration_mins}
                                onChange={this.onSelectChange}
                                class="settings-input-color"/>
                        </div>*/}
                        <div className="form-check mb-3">
                            <input className="form-check-input" type="checkbox" name="auto_approve_appointments"
                                   checked={this.state.auto_approve_appointments}
                                   onChange={this.onCheckboxChanged}
                            />
                            <label className="form-check-label" htmlFor="auto_approve_appointments" onClick={this.onLabelClick}>
                                {$$('auto_approve_appointments_label')}
                                &nbsp;
                                <QuestionMarkTooltip>
                                    {$$('auto_approve_info_text')}
                                </QuestionMarkTooltip>
                            </label>
                        </div>

                        {this.canSetupDynamicLocation() && <div className={"form-group mb-4"}>
                                <label>{$$("lock_location_in_hours_label")}</label>
                                <input type={"text"} className={"form-control"} value={this.state.lock_location_in_hours}
                                       onChange={event => {
                                           event.target.setCustomValidity(event.target.value > 0 ? '' : $$('invalid_value'));
                                           let v = event.target.value.replace(/\D/, '');
                                           this.onLock_location_in_hoursChanged(v)
                                       }}/>
                            </div>
                        }

                        <div className={"mb-2"}>
                            <div className="form-check mb-3">
                                <input className="form-check-input" type="checkbox" name="end_time_for_appointment_booking"
                                       checked={this.isCheckedDeadline()}
                                       onChange={this.onDeadlineCheckboxChanged}
                                />
                                <label className="form-check-label" htmlFor="end_time_for_appointment_booking" onClick={()=>{
                                    this.onDeadlineCheckboxChanged({target:{checked:!this.isCheckedDeadline()}})
                                }}>
                                    {$$('end_time_for_appointment_booking')}
                                </label>
                            </div>
                            {/*<h5 className="mb-2">{$$("end_time_for_appointment_booking")}</h5>*/}
                            {this.isCheckedDeadline() && <div className={"ml-3"}>
                                <div className={"form-group mb-2"}>
                                    <label>{$$("days_before_appointment_label")}</label>
                                    <input type={"text"} className={"form-control"} value={this.state.book_days_before_appointment}
                                           onChange={event => this.onBook_days_before_appointmentChanged(event.target.value.replace(/\D/,''))} />
                                </div>
                                <div className='time-picker-configuration-form'>
                                    <MuiPickersUtilsProvider locale={this.getLocale()}
                                                             utils={DateFnsUtils}>
                                        <KeyboardTimePicker
                                            ampm={!this.props.settings.time24hour}
                                            margin="normal"
                                            id="time-picker"
                                            okLabel={$$("OK")}
                                            cancelLabel={$$("cancel_btn")}
                                            label={$$("end_hour_label")}
                                            value={this.timeToDate(this.state.book_time_on_day_before_appointment)}
                                            KeyboardButtonProps={{'aria-label': 'change time',}}
                                            onChange={this.onTimeChange}
                                            required
                                            invalidDateMessage={$$('invalid_time_format')}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div> }
                        </div>
                        <div className="form-group settings-form">
                            <div className="d-flex settings-form-buttons">
                                <button type="button" onClick={this.resetConfiguration}
                                        className="btn btn-secondary">{$$("reset_btn_label")}</button>
                                <button type="submit" className="btn btn-primary settings-form-submit">
                                    {$$("save_btn_label")}</button>
                            </div>
                        </div>
                    </FormWithLoading>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    configuration: state.provider_configuration.data,
    configuration_result: state.provider_configuration.request,
    selectedUser: state.selectedUser.data,
    userInfo: state.userInfo.data,
    i18n: state.language.selected,
    userSelections: state.userSelections.settings,
    formDisabled: state.formInteractions,
    settings: state.settings.data
})

export default connect(mapStateToProps, {updateConfiguration, fetchConfiguration})(ConfigurationForm)