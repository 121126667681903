/* eslint-disable */

import React from 'react'
import {CONVERTER} from '../../../utils/converter'

export default function HBA1CEntry(props) {
    return (
        <div className="card logbook-entry-card">
            <div className='row zero-margin-row'>
                <div className='medrec-grey-2 vitals-logbook-time logbook-entry-time-icon'>
                    <i className='kt-menu__link-icon far fa-clock'/> &nbsp;
                    {CONVERTER.getEntryDateTimeFormat(props.entry.date_time)}
                </div>
                &nbsp;&nbsp;
                {CONVERTER.hba1cPerUnit(props.entry.hba1c).toFixed(1)} {CONVERTER.getFormattedHBA1CUnit()}
            </div>
        </div>
    )
}
