import React from "react"
import {$$} from "../../helpers/localization";
import {QuestionMarkTooltip} from "../common/tooltips";

export function ReportLinkComponent({onClick}) {
    return <div className="d-inline-block report-link" onClick={onClick}>
        <a href="#" onClick={(e)=>{e.preventDefault()}}>
            <i className="fa fa-download"/>
            &nbsp;
            {$$("download_as_pdf")}
            &nbsp;
            <QuestionMarkTooltip>
                {$$('download_report_info_text')}
            </QuestionMarkTooltip>
        </a>
    </div>
}

