import React, {Component} from 'react'
import PropTypes from "prop-types";
import {$$} from "../../../helpers/localization";

class UserFilterOptions extends Component {
    render() {
        return (
            <div className="dropdown" style={{"alignSelf": "center"}}>
                <a className="nav-link pr-0 pl-0 ml-3 mr-2" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                   aria-expanded="false">
                    <div>
                                <span className="input-group-text">
                                    <i className="flaticon2-console"/>
                                </span>
                    </div>
                </a>

                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                    <label className="check-box-container">{$$('new_messages_only')}
                        <input type="checkbox" checked={this.props.onlyNewMessages}
                               onChange={this.props.onlyNewClicked}/>
                        <span className="check-box-checkmark"></span>
                    </label>

                    <label className="radio-box-container">{$$('sort_by_most_recent')}
                        <input type="radio" name="radio" checked={this.props.defaultSort}
                               onChange={this.props.sortByMostRecent}/>
                        <span className="checkmark"></span>
                    </label>

                    <label className="radio-box-container">{$$('sort_by_name')}
                        <input type="radio" name="radio" checked={!this.props.defaultSort}
                               onChange={this.props.sortByName}/>
                        <span className="checkmark"></span>
                    </label>

                </div>
            </div>
        )
    }
}

UserFilterOptions.propTypes = {
    defaultSort: PropTypes.bool,
    onlyNewMessages: PropTypes.bool,
    sortByName: PropTypes.func,
    onlyNewClicked: PropTypes.func,
    sortByMostRecent: PropTypes.func,

};

export default UserFilterOptions
