import React from 'react'
import {$$} from '../../../helpers/localization'
import CustomMultiselect from '../../shared/CustomMultiSelect'
import {languageOptions} from '../../../constants/languages'
import PropTypes from "prop-types";


export class Biography extends React.Component {
    state = {
        biographyFields: {
            languages: this.props.registrationFields.languages || [],
            other_info: this.props.registrationFields.other_info || '',
        },

        validLanguagesField: true,

        formclass: '',
        errors: {}
    }

    constructor(props) {
        super(props);
    }

    validateLanguagesState = (languages) => {
        if (languages.length === 0) {
            this.setState({validLanguagesField: false});
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.registrationFields.submiting && this.props.registrationFields.submiting) {
            this.displayValidity();
        }
    }

    displayValidity = () => {
        if (this.state.formclass !== 'was-validated') {
            this.setState({formclass: 'was-validated'});
        }
    }

    componentDidMount() {
        if (this.props.registrationFields.submiting) {
            this.validateLanguagesState(this.state.biographyFields.languages);
            this.displayValidity();
        }
    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {

        if (this.props.profilePage) {
            let form = this.form;
            let valid = {name: "biographyValid", value: form.checkValidity()};
            this.props.dataChanged(evt, valid);
        }

        const fields = Object.assign({}, this.state);
        fields['biographyFields'][evt.target.name] = evt.target.value;
        this.setState(fields);
    };


    /**
     * Adds the item in the specialties array and updates the state
     *
     * @param {string} selectedItem - the selected item from the multiselect
     */
    onSelect = (selectedItem) => {
        const fields = Object.assign({}, this.state);
        fields['biographyFields']['languages'].push(selectedItem);
        fields['validLanguagesField'] = true;
        this.setState(fields);

        if (this.props.profilePage) {
            let evt = {target: {name: "languages", value: fields['biographyFields']['languages']}};
            let valid = {name: "biographyValid", value: fields['biographyFields']['languages'].length > 0};
            this.props.dataChanged(evt, valid);
        }
    }

    /**
     * Removes the item from the specialties array and updates the state
     *
     * @param {string} removedItem - the removed item from the multiselect
     */
    onRemove = (removedItem) => {
        const fields = Object.assign({}, this.state);
        fields['biographyFields']['languages'] = fields['biographyFields']['languages'].filter(l => l !== removedItem);
        let form = this.form;
        this.setState(fields, function () {
            if (form['languages'].checkValidity() === false && this.state.formclass !== '') {
                this.setState({validLanguagesField: false});
            }
        });

        if (this.props.profilePage) {
            let evt = {target: {name: "languages", value: fields['biographyFields']['languages']}};
            let valid = {name: "biographyValid", value: fields['biographyFields']['languages'].length > 0};
            this.props.dataChanged(evt, valid);
        }
    }

    /**
     * Handler to be executed when the Continue button is clicked, validates data and sets error in state if any
     *
     * @param {object} evt - The event handler argument
     */
    onContinueClick = (evt) => {
        evt.preventDefault();
        if (this.state.formclass !== 'was-validated') {
            this.setState({formclass: 'was-validated'});
        }

        let form = this.form;
        if (form.checkValidity() === true) {
            let wizardStep = this.props.wizardStep;
            this.props.onWizardStepChange(this.state.biographyFields, ++wizardStep, true);
        } else {
            this.validateLanguagesFormField();
        }
    }

    /**
     * Handler to be executed when the Back button is clicked
     */
    onBackClick = () => {
        let wizardStep = this.props.wizardStep;
        this.props.onWizardStepChange(this.state.biographyFields, --wizardStep, this.props.isStepCompleted);
    }


    validateLanguagesFormField = () => {
        if (this.form['languages'].checkValidity() === false) {
            this.setState({validLanguagesField: false});
        }
    }

    render() {
        return (
            <div>
                <form className={this.state.formclass} noValidate={true} ref={(ref) => {
                    this.form = ref
                }}>
                    <div style={{'minHeight': '43.75rem'}}>
                        <div className='form-group'>
                            <input type='text' className='form-control' value={this.state.biographyFields.languages}
                                   name='languages' onChange={this.onInputChange} required hidden/>
                            <CustomMultiselect options={languageOptions(this.props.i18n.selected.lang)}
                                               selectedValues={this.state.biographyFields.languages}
                                               onSelect={this.onSelect}
                                               onRemove={this.onRemove}
                                               displayValue="text"
                                               placeholder={$$('wizard_biography_languages_label')}
                                               closeIcon='cancel'
                                               avoidHighlightFirstOption={true}
                                               isFieldValid={this.state.validLanguagesField}
                                               formClass={this.state.formclass}/>
                            <div className='invalid-feedback'>
                                {$$('wizard_biography_languages_required_label')}
                            </div>
                        </div>
                        <div className='form-group'>
                            {this.state.biographyFields.other_info && <label>{$$('wizard_biography_label')}</label>}
                            <textarea className='form-control'
                                      value={this.state.biographyFields.other_info}
                                      placeholder={$$('wizard_biography_label')}
                                      name="other_info"
                                      rows="4"
                                      cols="50"
                                      onChange={this.onInputChange}
                                      required/>
                            <div className='invalid-feedback'>
                                {$$('wizard_biography_required_label')}
                            </div>
                        </div>
                    </div>
                    {!this.props.profilePage &&
                    <div className='form-group' style={{'marginTop': '5.25rem'}}>
                        <div className='row flex-end-justify' style={{'float': 'right', 'margin': '0rem'}}>
                            <button type='button' onClick={this.onBackClick} className='btn btn-secondary'
                                    style={{'marginRight': '0.3125rem'}}>{$$('wizard_back_label')}</button>
                            <button type='button' onClick={this.onContinueClick}
                                    className='btn btn-success'>{$$('wizard_continue_label')}</button>
                        </div>
                    </div>}
                    {this.props.profilePage &&
                    <div className='form-group' style={{'marginTop': '5.25rem'}}>
                        <div className='row flex-end-justify' style={{'float': 'right', 'margin': '0rem'}}>
                            <button type='button' onClick={() => {
                                if (this.form.checkValidity() !== true) {
                                    this.validateLanguagesFormField();
                                    this.displayValidity();
                                } else {
                                    this.props.submitForm(false);
                                }
                            }} className='btn btn-success'>{$$('update')}</button>
                        </div>
                    </div>}
                </form>
            </div>
        )
    }
}

Biography.propTypes = {
    i18n: PropTypes.object,
    isStepCompleted: PropTypes.bool,
    profilePage: PropTypes.bool,
    onWizardStepChange: PropTypes.func,
    submitForm: PropTypes.func,
    dataChanged: PropTypes.func,
    registrationFields: PropTypes.object,
    wizardStep: PropTypes.number
}

export default Biography
