import React, {Component} from 'react';
import {connect} from 'react-redux'
import {$$} from "../../helpers/localization";
import {
    load2FAMethod,
    clearTotpVerifyResp,
    clear2FAResult,
    generateTotp,
    update2FA,
    verifyTotp
} from "../../actions/twofactor_auth_actions";
import {TwoFactorAuthenticationWizard} from "../login/TwoFaComponent";
import classNames from 'classnames'
import PropTypes from "prop-types";


class SecurityPage extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.props.load2FAMethod();
    }

    open2FASelector = () => {
        this.setState({show2Fa:true})
    }

    getDisplayMethod = () => {
        switch (this.props.two_fa_settings.settings.method) {
            case "OFF": return $$("two_fa_state_off")
            case "NOT_SET": return $$("two_fa_state_not_set");
            case "EMAIL": return $$("email_label");
            case "TOTP": return $$("authenticator_label");
            default: return ""
        }
    }

    render() {
        let method = this.props.two_fa_settings.settings.method;
        return <div>
            <h3>{$$("security_page_title")}</h3>
            <div className="low-shadow-container">
                <div className={"pb-2"}>
                    <p>{$$("two_fa_current_method")}</p>

                   <div className={classNames("low-shadow-container d-flex align-items-center", {
                       "text-danger": method === 'NOT_SET' || method === 'OFF',
                       "text-success": method === 'EMAIL' || method === 'TOTP'
                   })}><h3 className={"text-thin m-0 mr-5"}>{this.getDisplayMethod()}</h3>
                       <button type="button" className="btn btn-primary" onClick={this.open2FASelector}>{$$("change_btn")}</button></div>
                </div>

            </div>
            { method && <TwoFactorAuthenticationWizard show={this.state.show2Fa}
                                           method={method}
                                           onHide={() => {
                                               this.setState({show2Fa:false})
                                               this.props.clear2FAResult()
                                           }}
                                           totp={this.props.two_fa_settings.totp}
                                           totpVerifyResult={this.props.two_fa_settings.totp_verify_result}
                                           onLoadTOTP={() => {
                                               this.props.generateTotp();
                                           }}
                                           onCancel={() => {
                                               this.props.clear2FAResult()
                                           }}
                                           onChange={(e) => {
                                               this.props.update2FA(e);
                                           }}
                                           onTOTPVerify={(r) => {
                                               this.props.verifyTotp(r);
                                           }}
                                           clearTOTPVerifyResponse={() => {
                                               this.props.clearTotpVerifyResp();
                                           }}
                                           result={this.props.two_fa_settings.result}
            />}
        </div>
    }
}

SecurityPage.propTypes = {
    i18n: PropTypes.any,
    two_fa_settings: PropTypes.any,
    clearTotpVerifyResp: PropTypes.func,
    generateTotp: PropTypes.func,
    load2FAMethod: PropTypes.func,
    clear2FAResult: PropTypes.func,
    update2FA: PropTypes.func,
    verifyTotp: PropTypes.func,
}

function mapStateToProps(state) {
    return {
        two_fa_settings: state.two_fa_settings
    }

}

export default connect(mapStateToProps, {load2FAMethod, clearTotpVerifyResp, verifyTotp, clear2FAResult, generateTotp, update2FA})(SecurityPage)