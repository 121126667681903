import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import Search from '../shared/Search';
import PropTypes from "prop-types";

class PatientsHeader extends Component {
    state = {
        gridDisabled: this.props.userSelections.patients.view !== "List"
    }

    constructor(props) {
        super(props);
    }

    /**
     * The view is changed to grid on grid button click

     */
    changeToGridView = () => {
        let gridDisabled = true;
        this.setState({gridDisabled});
        this.props.changeView();
    }

    /**
     * The view is changed to list on list button click
     */
    changeToListView = () => {
        let gridDisabled = false;
        this.setState({gridDisabled});
        this.props.changeView();
    }

    render() {
        return (
            <div className='patient-card-header'>
                <div className='row space-between-justify'>
                    <h2 className='card-title card-header-title' >{$$('patients_label')}</h2>
                    <div className='row patient-view-form-container form-margin'>
                        <button className='btn patient-view-form-btn' disabled={this.state.gridDisabled}
                                onClick={this.changeToGridView}>
                            <i className='kt-menu__link-icon patient-view-form-icon flaticon2-grids'/>
                            {$$('grid_view_label')}
                        </button>
                        <button className='btn patient-view-form-btn' disabled={!this.state.gridDisabled}
                                onClick={this.changeToListView}>
                            <i className='kt-menu__link-icon patient-view-form-icon flaticon2-list-1'/>
                            {$$('list_view_label')}
                        </button>
                    </div>
                </div>
                <div className='search-box'>
                    <Search filterUsers={this.props.filterUsers}
                            placeholder={$$('search_for_patients_label')}/>
                </div>
            </div>
        )
    }
}

PatientsHeader.propTypes = {
    userSelections:  PropTypes.object,
    changeView:  PropTypes.func,
    filterUsers:  PropTypes.any
}

export default PatientsHeader
