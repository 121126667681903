import React, {useEffect, useState} from "react";
import {ListGroup} from "react-bootstrap";
import { EreferralBody } from "../examination/nhis/bg/NhisEreferralListItemView";
import {
    nhisCancelEreferral,
    nhisLogin,
    nhisSignCancelEreferral,
} from "../../service/nhis_service";
import {$$} from "../../helpers/localization";
import Modal from "react-bootstrap/Modal";
import FormGroup from "react-bootstrap/FormGroup";
import {eReferralService} from "../../service/ereferral_service";

export default function EncounterDetailsMedicalNoticeList({encounter, lang}) {
    const [ereferrals, setEreferrals] = useState();

    useEffect(() => {
        setEreferrals(encounter.ereferrals.map(e => {
            return {nrn: e.nrn, lrn: e.lrn, content: JSON.parse(e.content_json)}
        }))
    }, [encounter.ereferrals]);


    if (!ereferrals) {
        return null;
    }

    return <ListGroup className={"hover-shadow-boxes-list"}>
        {ereferrals.map(e => {
            return <EreferralListItem key={e.lrn} eReferral={e.content} nrn={e.nrn} lang={lang}/>
        })}
    </ListGroup>
}

function EreferralListItem({eReferral, nrn, lang}) {
    return <div className="list-group-item">
        <EreferralBody eReferral={eReferral} nrn={nrn} lang={lang}/>
        <hr className="kt-margin-b-0"/>
    </div>
}


export function NhisCancelEreferralDialog({encounterId, nrn, lrn, senderId, onClose, onSuccess}) {
    const [reason, setReason] = useState("");
    const [formClass, setFormClass] = useState("");
    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");



    const onSubmit = (e) => {
        e.preventDefault();
        if (!e.target.checkValidity()) {
            setFormClass("was-validated")
            return;
        }

        setSending(true);

        if (!nrn) {
            onSuccess(nrn, lrn);
            setSending(false);
            setSuccess(true);
            return;
        }

        let message = {
            senderId : senderId,
            nrnReferral: nrn,
            cancelReason: reason.trim()
        }

        nhisLogin((loginSuccess) => {
            nhisSignCancelEreferral(message, (signedRequest) => {
                nhisCancelEreferral(loginSuccess.accessToken, signedRequest)
                    .then(async res => {
                        let contents = res["nhis:message"]["nhis:contents"];
                        let error = contents["nhis:error"];
                        if (error) {
                            if (Array.isArray(error)) {
                                setError(error[0]["nhis:reason"]["value"]);
                            } else {
                                setError(error["nhis:reason"]["value"]);
                            }
                        } else {
                            setSuccess(true);
                            onSuccess(nrn, lrn)
                        }
                        setSending(false)
                    })
            }, (e) => {
                setError(e);
                setSending(false);
            })
        }, (e) => {
            setSending(false);
            setError(e)
        }).catch(error => {
            console.log(error);
            setSending(false);
            setError($$("nhis.service_error"));
        })
    }

    return <Modal backdrop={"static"} show={true} >
        <Modal.Header className={"h5"}>
            {$$(nrn ? "nhis.ereferral.modal_header_cancel" : "nhis.ereferral.modal_header_delete")}
        </Modal.Header>
        <Modal.Body>
            {success && <p className="p-2">{$$(nrn ? "nhis.ereferral.cancel_success" : "nhis.ereferral.delete_success")}</p> }
            {!success && <form noValidate={true} className={formClass} onSubmit={onSubmit} id="form_cancel_ereferral">
                {error && <p className="text-danger p-2">{error}</p> }
                <p>{$$(nrn ? "nhis.ereferral.modal_body_cancel" : "nhis.ereferral.modal_body_delete")}</p>
                {nrn && <FormGroup>
                    <label>{$$("nhis.ereferral.cancellation_reason")}*</label>
                    <textarea className="form-control"
                              rows={4}
                              value={reason}
                              onChange={(e) => {
                                  setReason(e.target.value)
                              }}
                              maxLength={2000}
                              required={true}/>
                </FormGroup>
                }
            </form>}

        </Modal.Body>
        {!success && <Modal.Footer>
            <button type="submit" className="btn btn-danger" form={"form_cancel_ereferral"} disabled={sending}
            >{$$(nrn ? "nhis.annul_nhis" : "delete_label")}</button>
            <button type="button" className="btn btn-secondary" disabled={sending}
                    onClick={onClose}>{$$("nhis.btn.cancel")}</button>
        </Modal.Footer>
        }
        {success && <Modal.Footer>
            <button type="button" className="btn btn-primary" disabled={sending}
                    onClick={onClose}>{$$("close_btn_label")}</button>
        </Modal.Footer>}
    </Modal>

}