export const validators = {
    validateEmail
};

/**
 * Validate email.
 *
 * @param {string} email - email to  validate.
 * @returns {boolean} true if valid email, false otherwise
 */
export function validateEmail(email) {
    // eslint-disable-next-line
    const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email.toLowerCase());
}