import React, {Component} from 'react'
import {connect} from 'react-redux'
import ProviderDocuments from './ProviderDocuments'
import {
    fetchLoggedUserDocuments,
    uploadLoggedUserDocument
} from '../../actions/documents_actions'
import {$$} from '../../helpers/localization'
import PropTypes from "prop-types";


class MyDocuments extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchLoggedUserDocuments(null, true);
    }

    /**
     * When a document is selected, call the upload action to handle the operation
     *
     * @param {object} evt - The event handler argument
     */
    onChange = (evt) => {
        if (evt.target.files[0]) {
            this.props.uploadLoggedUserDocument(evt.target.files[0]);
        }
    };

    render() {
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div>
                        <div className="card">
                            <div className="card-header row">
                                <div className="col-sm-8 col-m-8"/>
                                <div className="col-sm-4 col-m-4 upload-btn-wrapper">
                                    <label htmlFor="file-upload" className="btn btn-primary" style={{"float": "right"}}>
                                        <i className='kt-menu__link-icon  flaticon-upload-1'/> {$$('upload_documents')}
                                    </label>
                                    <input type="file" id="file-upload" name="document" onChange={this.onChange}/>
                                </div>
                            </div>
                            <div className="card-body main-card" style={{"padding": "0px"}}>
                                <ProviderDocuments
                                    selectedUser={this.props.userInfo}
                                    documents={this.props.documents.loggedUser}
                                    i18n={this.props.i18n.selected}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

MyDocuments.propTypes = {
    documents: PropTypes.object,
    fetchLoggedUserDocuments: PropTypes.func,
    history: PropTypes.object,
    i18n: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    settings: PropTypes.object,
    uploadLoggedUserDocument: PropTypes.func,
    userInfo: PropTypes.object
}

const mapStateToProps = (state) => ({
    userInfo: state.userInfo.data,
    settings: state.settings,
    documents: state.documents,
    i18n: state.language
})

const mapDispatchToProps = {
    fetchLoggedUserDocuments,
    uploadLoggedUserDocument
}

export default connect(mapStateToProps, mapDispatchToProps)(MyDocuments)
