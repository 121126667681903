import React, {useEffect, useState} from 'react'
import {Waypoint} from 'react-waypoint'
import {$$} from '../../helpers/localization'
import {
    addDays,
    addWeeks, endOfWeek,
    format,
    isBefore,
    isSameDay,
    setDay, startOfWeek,
    subDays,
    subWeeks,
    differenceInDays,
    isAfter
} from 'date-fns'
import {
    bg,
    enGB
} from 'date-fns/locale/index'
import {appointmentUtils} from '../../utils/appointmentUtils'
import _ from 'underscore'

const ID_FORMAT_DATE = 'yyyy-MM-dd';

export default React.memo(function ReactHorizontalDatePicker({
                                                                 /* eslint-disable */
                                                                 enableDays,
                                                                 enableScroll,
                                                                 selectedDay,
                                                                 i18n,
                                                                 chosenDate,
                                                                 triggeredFromBtn,
                                                                 appointments,
                                                                 selectToday,
                                                                 children
                                                                 /* eslint-enable */
                                                             }) {
    let now = new Date();
    const [selectedDate, setSelectedDate] = useState(now);
    const [currentWeek, setCurrentWeek] = useState(setDay(now, _.contains(['en'], i18n) ? 0 : 1));
    const [currentDate] = useState(now);
    const [week, setWeek] = useState(setDay(now, _.contains(['en'], i18n) ? 0 : 1));

    enableScroll = enableScroll || false;
    enableDays = enableScroll === true ? enableDays || 90 : 7;

    let endDate = addDays(new Date(), enableDays);
    let endOfWeekOfEndDate = endOfWeek(endDate, { weekStartsOn:_.contains(['en'], i18n) ? 0 : 1});
    enableDays = differenceInDays(endOfWeekOfEndDate, currentWeek) + 2; //adding 2 days since we need to include the first and the last day.

    useEffect(() => {
        if (!isSameDay(selectedDate, chosenDate) || triggeredFromBtn/* && !isSameDay(headingDate, now)*/) {
            onDateClick(new Date(chosenDate));
        }
    }, [chosenDate]);

    useEffect(() => {
        let firstDayCurrentWeek = setDay(now, _.contains(['en'], i18n) ? 0 : 1);
        let firstDayChosenWeek = setDay(week, _.contains(['en'], i18n) ? 0 : 1);

        if (!isSameDay(currentWeek, firstDayCurrentWeek)) {
            setCurrentWeek(firstDayCurrentWeek);
        }
        if (!isSameDay(week, firstDayChosenWeek)) {
            setWeek(firstDayChosenWeek);
        }
    }, [i18n]);

    const applyStyles = day => {
        const classes = [];
        if (isSameDay(day, selectedDate)) {
            classes.push(' date-day-item-selected');
        }

        if (isBefore(day, currentDate)) {
            classes.push(' date-day-item-disabled');
        }

        if (isAfter(day, endDate)) {
            classes.push(' date-day-item-disabled');
        }

        return classes.join(' ');
    };

    /**
     * Filters the appointments for each day, in order to put a mark on the days
     *
     * @param {Date} _formattedDate - the formatted date
     * @returns {Array} the list of filtered appointments
     */
    const getFilteredAppointments = (_formattedDate) => {
        return appointments &&
        /* eslint-disable */
        appointments.length > 0
            ? appointments.filter(a => format(new Date(a.starts_at), 'yyyy-MM-dd') === _formattedDate)
            : [];
        /* eslint-enable */

    }

    const _verticalList = () => {
        const _dayFormat = 'E';
        const _dateFormat = 'dd';
        const _verticalListItems = [];
        const _startDay = currentWeek;

        let shouldDisplay = false;

        for (let i = 0; i < enableDays; i++) {
            let options = {locale: _.contains(['en', 'sq'], i18n) ? enGB : bg};
            let _day = format(addDays(_startDay, i), _dayFormat, options);
            let _date = format(addDays(_startDay, i), _dateFormat);

            let _formattedDate = format(new Date(addDays(_startDay, i)), 'yyyy-MM-dd');
            let appointmentsPerDate = getFilteredAppointments(_formattedDate);
            shouldDisplay = appointmentsPerDate.length > 0;

            let id = format(addDays(_startDay, i), ID_FORMAT_DATE);
            _verticalListItems.push(
                    <div key={id} id={id} className='wrapper'
                         style={{'marginRight': '0.3125rem', 'marginLeft': i === 0 ? '-0.3125rem' : '0rem'}}>
                        <div
                            className={`datepicker-date-day-item wrapper ${applyStyles(
                                addDays(_startDay, i)
                            )}`}
                            onClick={() => onDateClick(addDays(_startDay, i))}
                        >
                            <div className='datepicker-date-label  '>{_date}</div>
                            <div className='datepicker-day-label '>{_day.toUpperCase()}</div>

                        </div>
                        {shouldDisplay &&
                        <div className={appointmentUtils.markClass()}/>
                        }
                    </div>
            );
        }

        return (
            <div
                id='container'
                className={
                    enableScroll === true
                        ? ' datepicker-datelist-scrollable contained-datepicker'
                        : ' datepicker-dateList contained-datepicker'
                }
            >
                {_verticalListItems}
            </div>
        );
    };

    const onDateClick = day => {
        if (isSameDay(day, new Date()) &&
            document.getElementById('container').scrollLeft !== 0 &&
            triggeredFromBtn) {
            document.getElementById('container').scrollLeft = 0;
            setWeek(setDay(now, _.contains(['en'], i18n) ? 0 : 1));
        }
        setSelectedDate(day);
        selectedDay(day);

        let week = endOfWeek(day, {weekStartsOn: _.contains(['en'], i18n) ? 0 : 1});
        let startWeek = startOfWeek(day, {weekStartsOn: _.contains(['en'], i18n) ? 0 : 1});
        let id = format(week, ID_FORMAT_DATE);
        let element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior: 'auto', block: 'end', inline: 'end'});
            document.getElementById(format(subDays(week, 1), ID_FORMAT_DATE)).style.marginRight = '0rem';
            setWeek(startWeek);
        }
    };

    const nextScroll = () => {
        let followingWeek = addWeeks(week, 1);
        let lastDayOfFollowingWeek = addDays(followingWeek, 6);
        let id = format(lastDayOfFollowingWeek, ID_FORMAT_DATE);
        let element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'nearest'});
            document.getElementById(format(subDays(followingWeek, 1), ID_FORMAT_DATE)).style.marginRight = '0rem';
            setWeek(followingWeek);
        }
    };

    const prevScroll = () => {
        let previousWeek = subWeeks(week, 1);
        let id = format(previousWeek, ID_FORMAT_DATE);
        let element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'nearest'});
            if (document.getElementById(format(subDays(previousWeek, 1), ID_FORMAT_DATE))) {
                document.getElementById(format(subDays(previousWeek, 1), ID_FORMAT_DATE)).style.marginRight = '0.3125rem';
            }
            document.getElementById(format(addDays(previousWeek, 6), ID_FORMAT_DATE)).style.marginRight = '0.3125rem';
            setWeek(previousWeek);
        }
    };

    return (
        <div className='datepicker-strip'>
            <div className='row space-between-justify'>
                <div
                    className='scroll-head'>{format(week, 'MMMM yyyy', {locale: _.contains(['en', 'sq'], i18n) ? enGB : bg})}</div>
                {children}
                <div className='today-btn'>
                    <button className='btn btn-secondary' onClick={selectToday}>
                        {$$('select_today_label')}
                    </button>
                </div>
            </div>
            <div className='datepicker'>
                <div className='wrapper'>
                    <div id='prev' className='button-previous'>
                        {' '}
                        <button className='datepicker-button-previous' onClick={prevScroll}>
                            <i className='kt-menu__link-icon fas fa-angle-right'/>
                        </button>
                    </div>
                </div>
                {_verticalList()}
                <div className='wrapper'>
                    <div/>
                    <div id='next' className='button-next'>
                        {' '}
                        <button className='datepicker-button-next' onClick={nextScroll}>
                            <i className='kt-menu__link-icon fas fa-angle-right'/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
});