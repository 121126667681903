import React, {Component} from 'react'
import {connect} from 'react-redux'
import {$$} from "../../helpers/localization";
import NumberOfAppointmentsCard from "./NumberOfAppointmentsCard";
import {getAppointmentStats} from '../../actions/users_actions';
import PropTypes from "prop-types";



class NowNextLater extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getAppointmentStats(Intl.DateTimeFormat().resolvedOptions().timeZone, null);
    }

    render() {
        return (
            <div className='d-flex'>
                <div className='now-next-later-content now-next-later-content-left'>
                    <div className='next-patient-header'>  {$$('select_today_label')}</div>
                    <div className="box-shadow-strong p-2">
                    <NumberOfAppointmentsCard
                        type="day"
                        i18n={this.props.i18n}
                        providerAppointments={this.props.providerAppointments}
                        appointmentStats={this.props.appointmentStats}
                    />
                    </div>
                </div>
                <div className='now-next-later-content now-next-later-content-middle'>
                    <div className='next-patient-header'> {$$('this_week_label')}</div>
                    <div className="box-shadow-strong p-2">
                    <NumberOfAppointmentsCard
                        type="week"
                        i18n={this.props.i18n}
                        providerAppointments={this.props.providerAppointments}
                        appointmentStats={this.props.appointmentStats}
                    />
                    </div>
                </div>
                <div className='now-next-later-content now-next-later-content-right'>
                    <div className='next-patient-header'> {$$('this_month_label')}</div>
                    <div className="box-shadow-strong p-2">
                    <NumberOfAppointmentsCard
                        type="month"
                        i18n={this.props.i18n}
                        providerAppointments={this.props.providerAppointments}
                        appointmentStats={this.props.appointmentStats}
                    />
                    </div>
                </div>
            </div>
        )
    }
}

NowNextLater.propTypes = {
    appointmentStats: PropTypes.object,
    getAppointmentStats:PropTypes.func,
    i18n: PropTypes.string,
    providerAppointments:PropTypes.object
}

function mapStateToProps(state) {
    return {
        appointmentStats: state.appointmentStats.data
    }
}

export default connect(mapStateToProps, {
    getAppointmentStats
})(NowNextLater);
