import {
    CLEAR_CHAT,
    FETCH_CHAT_MESSAGES_REQUEST_ERROR,
    FETCH_CHAT_MESSAGES_REQUEST_SENT,
    FETCH_CHAT_MESSAGES_REQUEST_SUCCESS,
    FETCH_CHAT_UNREAD_MESSAGES_COUNT_ERROR,
    FETCH_CHAT_UNREAD_MESSAGES_COUNT_SUCCESS,
    LOGOUT,
    NEW_MESSAGE,
    NEW_MESSAGE_FROM_SOCKET_ARRIVED,
    NO_MORE_MESSAGES,
    REQUEST_ERROR,
    REQUEST_SENT,
    REQUEST_SUCCESS,
    SET_MESSAGE_AS_SEEN
} from "../actions/actions";
import {requestStatus} from './requests_reducers';

/**
 * Chat reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the symptoms logs
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const initialState = {
    messages: {
        entries: [],
        isLastPage: false,
        request: requestStatus(undefined, {})
    },
    unreadMessages: {
        list: [],
        error: null
    },
    new_message: null
};

export function chat(state = initialState, action) {
    switch (action.type) {
        case FETCH_CHAT_MESSAGES_REQUEST_SENT: {
            return {
                ...state,
                messages: {
                    ...state.messages,
                    request: requestStatus(state.messages.request, {type: REQUEST_SENT}),
                    isLastPage: initialState.messages.isLastPage
                }
            }
        }
        case FETCH_CHAT_MESSAGES_REQUEST_SUCCESS: {
            if (action.reset) {
                return {
                    ...state,
                    messages: {
                        ...state.messages,
                        entries: action.result,
                        request: requestStatus(state.messages.request, {type: REQUEST_SUCCESS})
                    }
                }
            }
            return {
                ...state,
                messages: {
                    ...state.messages,
                    entries: [...state.messages.entries, ...action.result],
                    request: requestStatus(state.messages.request, {type: REQUEST_SUCCESS})
                }
            }
        }
        case NEW_MESSAGE_FROM_SOCKET_ARRIVED: {
            return {...state, new_message: action.message}
        }
        case NEW_MESSAGE: {
            const messages = state.messages.entries;
            if (messages.length <= 0 || messages[messages.length - 1].date_time < action.message.date_time) {
                return {...state, messages: {...state.messages, entries: [...state.messages.entries, action.message]}}
            } else {
                const after = messages.find(m => m.date_time > action.message.date_time);
                if (after) {
                    const idxToAddMessage = messages.indexOf(after) - 1;
                    const newMessagesArray = [
                        ...messages.slice(0, idxToAddMessage),
                        action.message,
                        ...messages.slice(idxToAddMessage)
                    ];
                    return {...state, messages: {...state.messages, entries: newMessagesArray}}
                }
            }
            return state;
        }
        case SET_MESSAGE_AS_SEEN: {
            const messages = state.messages.entries;
            if (messages.length > 0) {
                const newMessagesArray = messages.map(m => {
                    if (m.id === action.message.id) {
                        return action.message;
                    }
                    return m;
                })
                return {...state, messages: {...state.messages, entries: newMessagesArray}}
            }
            return state;
        }
        case FETCH_CHAT_MESSAGES_REQUEST_ERROR: {
            return {
                ...state,
                messages: {
                    ...state.messages,
                    entries: initialState.messages.entries,
                    isLastPage: initialState.messages.isLastPage,
                    request: requestStatus(state.messages.request, {type: REQUEST_ERROR, response: action.result})
                }
            }
        }
        case NO_MORE_MESSAGES: {
            return {...state, messages: {...state.messages, isLastPage: true}}
        }
        case FETCH_CHAT_UNREAD_MESSAGES_COUNT_SUCCESS: {
            return {...state, unreadMessages: {...state.unreadMessages, list: action.result, error: null}}
        }
        case FETCH_CHAT_UNREAD_MESSAGES_COUNT_ERROR: {
            return {
                ...state,
                unreadMessages: {...state.unreadMessages, list: initialState.unreadMessages.list, error: action.result}
            }
        }
        case CLEAR_CHAT: {
            return {...state, messages: initialState.messages};
        }
        case LOGOUT: {
            return {...state, ...initialState};
        }
        default: {
            return state;
        }
    }
}