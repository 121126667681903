import store from '../store';

export const authHelper = {
    isLoggedIn,
    isManagementLogIn,
    loggedUserId
};

/**
 * Check if user is logged in
 *
 * @returns {boolean} true if user is logged in
 */
export function isLoggedIn() {
    const authData = store.getState().authentication.data;
    return authData && authData.access_token !== null && store.getState().authentication.data.access_token !== undefined;
}


export function isManagementLogIn() {
    return store.getState().authentication.management_login;
}

export function loggedUserId() {
    return store.getState().userInfo.data.id;
}
