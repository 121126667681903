export default {
    header: {
        self: {},
        items: []
    },
    aside: {
        self: {},
        items: [
            {
                title: "Main",
                root: true,
                icon: "flaticon-imac",
                page: "dashboard",
                translate: "Dashboard",
                bullet: "dot"
            },
            {
                title: "My appointments",
                root: true,
                icon: "flaticon-calendar-3",
                page: "appointments",
                translate: "appointments_label",
                bullet: "dot"
            },
            {
                title: "Consultations",
                root: true,
                icon: "flaticon-notes",
                page: "consultations",
                translate: "consultations_menu_label",
                bullet: "dot"
            },
            {
                title: "Patients",
                root: true,
                icon: "flaticon-users",
                page: "patients",
                translate: "patients_label",
                bullet: "dot"
            },
            {
                title: "Messages",
                root: true,
                icon: "flaticon-comment",
                page: "messages",
                translate: "messages_label",
                bullet: "dot"
            },
            {
                title: "Schedule",
                root: true,
                icon: "flaticon-calendar-1",
                page: "work-time",
                translate: "schedule_label",
                bullet: "dot",
                separator: true
            },
            {
                title: "Price Settings",
                root: true,
                icon: "flaticon-list",
                page: "price",
                translate: "price_settings_label",
                bullet: "dot"
            },
            {
                title: "Settings",
                root: true,
                icon: "flaticon-settings",
                page: "settings",
                translate: "settings_label",
                bullet: "dot"
            },
            {
                title: "Administrative Portal",
                root: true,
                icon: "flaticon-computer",
                page: "select-practice",
                translate: "administrative_portal_label",
                bullet: "dot",
                fixedButton: false,
                separator: true
            },
            {
                title: "Get Help",
                root: true,
                icon: "flaticon-questions-circular-button",
                page: "",
                externalLink: "https://www.manula.com/manuals/sirma-medical-systems/medrec-m-user-guide/clinic/bg/topic/clinic-introduction",
                translate: "get_help_label",
                bullet: "dot",
                fixedButton: true
            },
            {
                title: "Logout",
                root: true,
                icon: "flaticon-logout",
                page: "logout",
                translate: "logout_label",
                bullet: "dot",
                fixedButton: true
            },
            {
                title: "My documents",
                root: true,
                icon: "flaticon2-document",
                hidden: true,
                page: "documents",
                translate: "my_documents_label",
                bullet: "dot"
            },
            {
                title: "",
                root: true,
                page: "main",
                hidden: true,
                translate: "main_page_label"
            }
        ]
    }
};
