import {
    DUMMY,
    FETCH_SELECTED_CARDIAC_STATS_ERROR,
    FETCH_SELECTED_CARDIAC_STATS_SUCCESS,
    FETCH_SELECTED_USER_CARDIAC_STATS_REQUEST_SENT,
} from "./actions";
import {cardiacStatsService} from "../service/cardiac_stats_service";


/**
 * Fetch the Cardiac stats of the selected user, dispatch the appropriate action.
 *
 * @param {string} userId - id of the selected user
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function fetchSelectedUserCardiacStats(userId, params) {
    return (dispatch, getState) => {
        dispatch({type: FETCH_SELECTED_USER_CARDIAC_STATS_REQUEST_SENT});
        return cardiacStatsService.fetchSelectedUserCardiacStatsResult(userId, params).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            dispatch({type: FETCH_SELECTED_CARDIAC_STATS_SUCCESS, result: res});
        }).catch((err) => {
            dispatch({type: FETCH_SELECTED_CARDIAC_STATS_ERROR, result: err});
        })
    }
}