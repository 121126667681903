import React, {Component} from "react";
import PropTypes from "prop-types";
import {$$} from "../../helpers/localization";
import {APPOINTMENT_FILTER_OPTIONS, getResolvedOptions} from "../../constants/select_options";
import Select from "../shared/Select";

export default class AppointmentFilters extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        return <>
            <div className="d-flex justify-content-between mt-3 mb-4">
                <div className="btn-group dashboard-date-pick-button btn-group-toggle mr-2">
                    <button type="button"
                            className={this.props.range === 'ALL' ? "btn btn-primary active" : "btn btn-secondary"}
                            aria-label="all"
                            style={{minWidth: "80px"}}
                            onClick={() => {
                                this.props.rangeChanged('ALL')
                            }}>
                        <span>{$$("all")}</span>
                    </button>
                    <button type="button"
                            className={this.props.range === 'TODAY' ? "btn btn-primary active" : "btn btn-secondary"}
                            aria-label="today"
                            style={{minWidth: "80px"}}
                            onClick={() => {
                                this.props.rangeChanged('TODAY')
                            }}>
                        <span>{$$("today")}</span>
                    </button>
                </div>
                <div>
                    <Select className='custom-select calendar-select'
                            name="selectedType"
                            value={this.props.selectedType}
                            onChange={this.props.getOnChange}
                            options={getResolvedOptions(APPOINTMENT_FILTER_OPTIONS.TYPE)}/>
                </div>
            </div>
        </>
    }
}

AppointmentFilters.propTypes = {
    getOnChange: PropTypes.func,
    selectedType: PropTypes.any,
    range: PropTypes.any,
    rangeChanged: PropTypes.func
}