import {LOGOUT, UPDATE_MENU} from "../actions/actions";


const initialState = {
    selected: 0
}

export function menu(state = initialState, action) {
    switch (action.type) {
        case UPDATE_MENU: {
            return {...state, selected: action.payload};
        }
        case LOGOUT: {
            return {...initialState};
        }
        default: {
            return state
        }
    }
}