import React, {Component} from 'react'
import {Button} from "react-bootstrap";
import {$$} from "../../helpers/localization";
import ReactDOM from "react-dom"
import PropTypes from "prop-types";
import {testsUtils} from "../../utils/testsUtils";

export default class TestsResultDisplay extends Component {

    fillRest = (l1, l2) => {
        let r = [];
        for (; l2 < l1; l2 ++) {
            r.push(<td key={`l_${l2}`}></td>)
        }
        return r;
    }

    renderValue = (f) => {
        switch (f.type) {
            case "number":
            case "calculate":
            case "string":
            case "date": return <span>{testsUtils.getValue(f)}</span>
            case "boolean":
                // eslint-disable-next-line no-case-declarations
                let value = testsUtils.getValue(f);
                if (value === "") {
                    return "";
                }
                return <input type="checkbox" readOnly={true} size={4} id={f.id} checked={testsUtils.getValue(f)}/>
        }
    }


    componentDidMount() {
        // eslint-disable-next-line react/no-find-dom-node
        ReactDOM.findDOMNode(this).offsetParent.scrollIntoView(true);
    }

    render() {
        return <div className={"tests_result_edit"}>
            <h4>{this.props.result.name}</h4>
            <table className={"tre_outer_table table table-bordered"}>
                <tbody>
                {
                    // eslint-disable-next-line no-unused-vars
                    this.props.result.tests.map((test, tindex) => {
                        if (!testsUtils.testHasValue(test)) {
                            return null;
                        }

                        return <tr key={test.id}><td className={"td-wrap"}>
                            {test.name && <div className={"test-name"}>{test.name}</div>}
                            <table>
                                <tbody>
                                {test.groups.map((g,gindex) => {
                                    if (!testsUtils.groupHasValue(g)) {
                                        return null;
                                    }
                                    return <React.Fragment key={gindex}>
                                            {gindex === 0 && <tr className={"headers"}>
                                                {test.description_headers.map((dh, i) => {
                                                    return <td key={i}>{dh}</td>
                                                })}
                                                {test.field_headers.map((fh, i) => {
                                                    return <td key={i}>{fh}</td>
                                                })}
                                            </tr>}
                                            {!g.hide_name && <tr className="tr-group-name"><td></td><td className={"group-name"}>{g.name}</td><td></td><td colSpan={test.description_headers.length + test.field_headers.length - 3}></td></tr>}
                                            {g.rows.map(r => {
                                                if (!testsUtils.rowHasValue(r)) {
                                                    return null;
                                                }
                                                return <tr key={r.id}>
                                                    {r.description?.values.map((value, i) => {
                                                        return <td key={i}>{value}</td>
                                                    })}
                                                    {r.fields.map(f => {
                                                        return <td key={f.id} className={"value-cell"}>{this.renderValue(f)}</td>
                                                    })}
                                                    {this.fillRest(test.description_headers.length + test.field_headers.length, r.description.values.length + r.fields.length)}
                                                </tr>
                                            })}
                                    </React.Fragment>
                                })}
                                </tbody>
                            </table>
                        </td></tr>
                    })
                }
                </tbody>
            </table>
            <div className={"mt-3"}>
                <Button type={"button"} variant={"secondary"} className="ml-3" onClick={this.props.onCancel} >{$$("back")}</Button>
            </div>
        </div>
    }
}

TestsResultDisplay.propTypes = {
    onCancel:  PropTypes.func,
    result:  PropTypes.any,
}