import React, {useEffect, useState} from "react"
import {Link, Redirect} from "react-router-dom";
import {$$, _$$} from "../helpers/localization";
import {internal_version, version} from "../../package";
import LanguageSelector from "./layout/LanguageSelector";
import {Routes} from "../constants/routes";
import store from "../store"
import {changeLanguage} from "../actions/language_actions";
import PasswordValidity from "./shared/PasswordValidity";
import {isPasswordValid} from "../utils/validatePassword";
import {FG, FieldLabel, Input, InputTextArea} from "./examination/nhis/bg/HelperFormComponents";
import {useSelector} from "react-redux";
import {fetchSpecialties} from "../service/users_service";
//import {nhisGetNomenclatures} from "../service/nhis_service";
import {languageOptions} from "../constants/languages";
import CustomTagsInput from "./shared/CustomTagsInput";
import {SearchableSelectAsync} from "./examination/nhis/bg/SearchableSelect";
import {isLoggedIn} from "../helpers/auth_helper";
import {login, logout, logoutWithRoute} from "../actions/auth_actions";
import {clinicService} from "../service/clinic_service";
import history from "../helpers/history";
import {getUserInfo} from "../actions/users_actions";

export default function InvitationPage({location}) {
    const [invitationId] = useState(location.state?.invitationId);
    const [invitation, setInvitation] = useState();
    const [errors, setErrors] = useState({});
    const [regType, setRegType] = useState("");
    const [notLoggedInAccept, setNotLoggedInAccept] = useState(false);

    const lang = useSelector((s) => s.language.selected.lang);
    const userEmail = useSelector((s) => s.userInfo.data?.email);

    const changeLang = (language) => {
        store.dispatch(changeLanguage(language))
    }

    const fetchInvitation = (invId) => {
        return clinicService.fetchInvitation(invId).then(res => {
            if (res && res.json_data) {
                return JSON.parse(res.json_data)
            }
            return null;
        });

        /*return new Promise((resolve, reject)=>{
            setTimeout(() => {
                resolve({
                    email: "dj+2@sirma.bg",
                    nonMedicalPersonnel:false,
                    org_role: "CLINICIAN",
                    org_id: "1234-3435-6345345-1231323",
                    org_name: "Org Name",
                    visible: false,
                    city: "Sofia",
                    country: "bg"
                })
            }, 2000)
        })*/
    };

    const isLogged = isLoggedIn();

    useEffect(() => {
        if (invitationId) {
            fetchInvitation(invitationId)
                .then((inv) => {
                    if (inv) {
                        setInvitation(inv);
                        if (userEmail && inv.email.toLowerCase() !== userEmail.toLowerCase()) {
                            setErrors({USER_LOGGED_IN_WITH_DIFFERENT_EMAIL:true});
                        }
                    } else {
                        setErrors({MISSING_INVITATION:true});
                    }
                })
        }
    }, [invitationId]);

    console.log(errors)
    useEffect(() => {
        if (isLogged) {
            setRegType("existing")
        }
    }, [isLogged]);

    const acceptInvitation  = (req) => {
        return clinicService.acceptInvitation(invitationId, req);

        /*return new Promise((resolve, reject)=>{
            console.log(req);
            setTimeout(()=>resolve(""), 2000)
        });*/
    };

    const onSave = (o) => {
        let request = {...invitation, ...o};
        request.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const data = {
            request: request
        };

        /*if (regType !== "new") {
            data.email = request.email;
            data.password = request.password;
        }*/

        console.log("accept invitation data", data);
        return acceptInvitation(data).then(()=>{
            return store.dispatch(login(request.email, request.password));
        })
    };

    const onSaveExisting = (o) => {
        let request = {...invitation, ...o};
        request.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const data = {
            request: request
        }
        data.email = request.email;
        data.password = request.password;        
        return acceptInvitation(data).then(()=>{
            return store.dispatch(login(request.email, request.password));
        })
    };

    if (!invitationId) {
        return <Redirect to={Routes.LOGIN}/>
    }

    if (!invitation) {
        if (errors.MISSING_INVITATION) {
            if (isLogged) {
                return <LoggedInWrapper>
                    <p>{$$("invitation_not_found_or_invalid")}</p>
                    <button className="btn btn-primary ml-2" onClick={() => {
                        history.push(Routes.MANAGEMENT_DASHBOARD)
                    }}>{$$("cancel_btn")}</button>
                </LoggedInWrapper>
            } else {
                return <NotLoggedInWrapper changeLang={changeLang} >
                    <p>{$$("invitation_not_found_or_invalid")}</p>

                    <button className="btn btn-primary ml-2" onClick={() => {
                        history.push(Routes.LOGIN)
                    }}>{$$("cancel_btn")}</button>
                </NotLoggedInWrapper>
            }
        }
        return false;
    }

    console.log(invitation)

    if (isLogged) {
        return <LoggedInWrapper>
            <p>{_$$("invite_page_info_message", invitation.org_name)}</p>

            {errors.USER_LOGGED_IN_WITH_DIFFERENT_EMAIL && <div>
                <p>{$$("email_does_not_match_invitation")}</p>
                <div className="mt-2">
                    <button className="btn btn-danger ml-2" onClick={() => {
                        logoutWithRoute(Routes.INVITATION + "?id=" + invitationId)(store.dispatch)
                    }}>{$$("logout_label")}</button>
                    <button className="btn btn-primary ml-2" onClick={() => {
                        history.push(Routes.MANAGEMENT_DASHBOARD)
                    }}>{$$("cancel_btn")}</button>
                </div>
            </div>}

            {Object.keys(errors).length === 0 && <div>
                <button className="btn btn-primary ml-2" onClick={() => {
                    console.log("accept for logged in user");

                    let request = {...invitation};
                    request.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    const data = {
                        request: request
                    };

                    console.log("accept invitation data", data);
                    return acceptInvitation(data).then(()=>{
                        return getUserInfo()(store.dispatch).then(() => {
                            history.push(Routes.MANAGEMENT_DASHBOARD)
                        });
                    })
                }}>{$$("confirm_btn_label")}</button>
                <button className="btn btn-danger ml-2" onClick={() => {
                    let state = {...location.state};
                    delete state.invitationId;
                    history.replace({...location, state});
                    history.push(Routes.MANAGEMENT_DASHBOARD)
                }}>{$$("cancel_btn")}</button>
            </div>}
        </LoggedInWrapper>
    }

    return <NotLoggedInWrapper changeLang={changeLang} >
        <h4>{$$("invitation_page_title")}</h4>

        {!notLoggedInAccept && <div>
            <p>{_$$("invite_page_info_message", invitation.org_name)}</p>
            <button className="btn btn-primary ml-2" onClick={() => {
                setNotLoggedInAccept(true);
            }}>{$$("confirm_btn_label")}</button>
            <button className="btn btn-danger ml-2" onClick={() => {
                let state = {...location.state};
                delete state.invitationId;
                history.replace({...location, state});
                history.push(Routes.LOGIN);
            }}>{$$("cancel_btn")}</button>

            </div>
        }

        {regType !== "new" && notLoggedInAccept && <div>
            <p>{$$("existing_user_invitation_accept_header")}</p>
            <Existing invEmail={invitation.email}
                      onSubmit={onSaveExisting}
                      onCancel={() => {setNotLoggedInAccept(false); setErrors({})}}
            />
            <p className="mt-3">{_$$("new_user_user_invitation_accept_header", invitation.org_name)}</p>
            <div className="text-center">
            <button type="button" className="btn btn-primary mr-2" onClick={() => {
                setRegType("new")
            }}>{$$("register_label")}
            </button>
            </div>
        </div>}



        {/*{!regType && <ChooseExistingOrNew setRegType={setRegType} />}*/}
        {regType === "new" && notLoggedInAccept &&
        <div className="mt-2" style={{maxWidth: "600px"}}>
            {!invitation.nonMedicalPersonnel && <MainFormClinician onCreate={(o) => {
                const ob = {...o};
                return onSave(ob);
            }} onCancel={() => {setRegType(""); setErrors({}); }} lang={lang} invEmail={invitation.email}/>}
            {invitation.nonMedicalPersonnel && <MainFormNonMedical onCancel={() => {setRegType(""); setErrors({}); }}
                                                                     onCreate={(o) => {
                                                                         const ob = {...o};
                                                                         return onSave(ob);
                                                                     }}
                                                                     invEmail={invitation.email}
            />}
        </div>
        }

        {/*{regType == "existing" &&
        <Existing invEmail={invitation.email}
                  onSubmit={onSave}
                  onCancel={() => {setRegType(""); setErrors({})}}
        >
            {
                errors.ACCEPT_ERROR && <FG><p className="text-danger">{$$(errors.ACCEPT_ERROR)}</p></FG>
            }
        </Existing>
        }*/}


    </NotLoggedInWrapper>

}


function MainFormClinician({onCreate, onCancel, lang, invEmail}) {
    const [formClass, setFormClass] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [title, setTitle] = useState("");
    const [email, setEmail] = useState(invEmail || "");
    const [contact_email, setContactEmail] = useState("");
    const [phone_numbers, setPhone_numbers] = useState([]);
    const [specialties, setSpecialties] = useState([]);
    //const [nhisSpecialtyOptions, setNhisSpecialtyOptions] = useState([]);
    //const [nhis_specialties, setNhisSpecialties] = useState([]);
    const [uin, setUin] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [other_info, setOtherInfo] = useState("");
    const [specialtyOptions, setSpecialtyOptions] = useState([])
    const [specialtyRawOptions, setSpecialtyRawOptions] = useState([])
    const [langOptions, setLangOptions] = useState([])
    const [existingEmailError, setExistingEmailError] = useState(false)
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState({});

    useEffect(() => {
        fetchSpecialties().then((res) => {
            setSpecialtyRawOptions(res);
        });
        /*nhisGetNomenclatures(["CL006"]).then((res) => {
            const items = res[0].items;
            let propNames = Object.getOwnPropertyNames(items).sort();
            let arr = propNames.map(prop => ({
                value: prop,
                label: prop + " " + items[prop].description,
                text: items[prop].description,
                item: items[prop]
            })).filter(o => o.item.meta.role?.includes('Лекари') || o.value === "0000");
            setNhisSpecialtyOptions(arr)
        });*/
    }, [])

    useEffect(() => {
        setLangOptions(languageOptions(lang).map(o => ({...o, label: o.text})));
    }, [lang])

    useEffect(() => {
        setSpecialtyOptions(getSpecialtiesOptions(specialtyRawOptions));
    }, [lang, specialtyRawOptions])

    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!e.target.checkValidity() || password !== confirmPassword || !isPasswordValid(password)) {
            setFormClass("was-validated");
            let err = {};
            if (password !== confirmPassword) {
                err = {confirmPassword: "error"}
            }
            if (!isPasswordValid(password)) {
                err = {...err, password: "error"};
            }
            setErrors(err);
            return;
        }
        setErrors({});

        const data = {
            fullname: `${firstName} ${lastName}`,
            email: email.trim(),
            title,
            contact_email,
            phone_numbers: phone_numbers,
            specialties,
            //nhis_specialties,
            uin,
            languages,
            other_info,
            password
        };
        const form = e.target;
        const fieldset = form.getElementsByTagName("fieldset")[0];
        fieldset.disabled = true;
        onCreate(data)
            .catch(e => {
                if (e.status === 403 && e.message.includes("Username already exists")) {
                    form.elements["email-input"].setCustomValidity("email-exists")
                    setFormClass("was-validated");
                    setExistingEmailError(true);
                }
            })
            .finally(() => {
                fieldset.disabled = false;
            });
    };

    return <div className="nhis-ereferral">
        <form noValidate={true} className={`has-custom-validation ${formClass}`} onSubmit={onSubmit} autoComplete="off">
            <fieldset>
                <div style={{'minHeight': '43.75rem'}} className="add-staff-form">
                    <FG>
                        <Input setValue={setFirstName} value={firstName} required={true}
                               label={"nhis.patient.first_name_label"} type="text" autoComplete="new-password"/>
                    </FG>
                    <FG>
                        <Input setValue={setLastName} value={lastName} required={true}
                               label={"nhis.patient.last_name_label"} type="text" autoComplete="new-password"/>
                    </FG>
                    <FG>
                        <Input setValue={setTitle} value={title} required={false}
                               label={"wizard_personal_information_title_label"} type="text" autoComplete="new-password"/>
                    </FG>
                    <FG>
                        <Input label="email_label" name="email-input" value={email} required={true} setValue={(v, e) => {
                            setEmail(v);
                            e.target.setCustomValidity("");
                            if (existingEmailError) {
                                setExistingEmailError(false);
                            }
                        }} type="email" autoComplete="new-password"/>
                        <div className={existingEmailError ? 'custom-invalid-feedback' : 'd-none'}>
                            {$$('register_form_email_exists_message')}
                        </div>
                    </FG>
                    <FG>
                        <FieldLabel labelKey={"password_label"} prop={password} required={true}/>
                        <input autoComplete="new-password"
                               placeholder={`${!password && $$("password_label")}*`}
                               className={`${errors.password ? 'custom-error' : ''} form-control form-control-sm`}
                               type={"password"} value={password || ""} required={true}
                               onChange={(evt) => setPassword(evt.target.value)}/>
                        <PasswordValidity password={password}/>
                    </FG>
                    <FG>
                        <FieldLabel labelKey={"repeat_password_label"} prop={confirmPassword} required={true}/>
                        <input autoComplete="new-password"
                               placeholder={`${!confirmPassword && $$("repeat_password_label")}*`}
                               className={`${errors.confirmPassword ? 'custom-error' : ''} form-control form-control-sm`}
                               type={"password"} value={confirmPassword || ""} required={true}
                               onChange={(evt) => setConfirmPassword(evt.target.value)}/>
                    </FG>
                    <FG>
                        <input type='text' className='form-control'
                               value={phone_numbers} name='phone_numbers'
                               onChange={event => {
                               }}
                               required={true} hidden/>
                        <CustomTagsInput
                            tags={phone_numbers}
                            handleAddition={(selectedItem) => {
                                const v = [...phone_numbers];
                                v.push(selectedItem.text);
                                setPhone_numbers(v);
                            }}
                            handleDelete={(index) => {
                                const fields = phone_numbers.filter((s, i) => i !== index);
                                setPhone_numbers(fields);
                            }}
                            placeholder={$$('wizard_phone_numbers_label')}
                            autofocus={false}
                            isFieldValid={phone_numbers.length > 0}
                            formClass={formClass}/>
                        <div className='invalid-feedback'>
                            {$$('wizard_phone_numbers_required_label')}
                        </div>
                    </FG>
                    <FG>
                        <Input label={"contact_email"} value={contact_email} required={false} setValue={setContactEmail}
                               type="email" autoComplete="new-password"/>
                    </FG>
                    <FG>
                        <SearchableSelectAsync
                            loadOptions={(query, callback) => callback(specialtyOptions.filter(opt => opt.label.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1))}
                            isMulti={true}
                            options={specialtyOptions}
                            value={specialties.map(v => specialtyOptions.find(opt => opt.value === v))}
                            placeholder={$$("wizard_personal_information_specialties_label")}
                            onSelect={(o) => {
                                setSpecialties(o.map(o => o.value));
                            }}
                            required={true}
                            optionHeight={40}
                            defaultMenuIsOpen={false}
                        />
                    </FG>
                    {/*<FG>
                    <SearchableSelectAsync
                        loadOptions={(query, callback)=>callback(nhisSpecialtyOptions.filter(opt => opt.label.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1))}
                        isMulti={true}
                        options={nhisSpecialtyOptions}
                        value={nhis_specialties.map(v => nhisSpecialtyOptions.find(opt => opt.value === v))}
                        placeholder={$$("nhis.nhis_specialties_search")}
                        onSelect={(o) => {
                            setNhisSpecialties(o.map(o => o.value));
                        }}
                        optionHeight={40}
                        required={false}
                    />
                </FG>*/}
                    <FG>
                        <Input setValue={setUin} value={uin} required={true}
                               label={"wizard_personal_information_uin_label_2"} type="text" autoComplete="new-password"/>
                    </FG>
                    <FG>
                        <SearchableSelectAsync
                            loadOptions={(query, callback) => callback(langOptions.filter(opt => opt.label.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1))}
                            isMulti={true}
                            options={langOptions}
                            value={languages.map(v => langOptions.find(opt => opt.value === v))}
                            placeholder={$$("wizard_biography_languages_label")}
                            onSelect={(o) => {
                                setLanguages(o.map(o => o.value));
                            }}
                            optionHeight={40}
                            required={true}
                        />
                    </FG>
                    <FG>
                        <InputTextArea label="wizard_biography_label_2" required={true} value={other_info}
                                       setValue={setOtherInfo}/>
                    </FG>

                    <FG className={"mt-3  text-right"}>
                        <button type="submit" className="btn btn-primary">{$$("confirm_btn_label")}</button>
                        <button className="btn btn-danger ml-2" onClick={onCancel}>{$$("cancel_btn")}</button>
                    </FG>
                </div>
            </fieldset>
        </form>
    </div>
}


function getSpecialtiesOptions(options) {
    return options.map(o => {
        return {value: o, text: $$(o.toLowerCase()), label: $$(o.toLowerCase())}
    });
}

function MainFormNonMedical({onCreate, onCancel, invEmail}) {
    const [formClass, setFormClass] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState(invEmail || "");
    const [existingEmailError, setExistingEmailError] = useState(false)
    const [errors, setErrors] = useState({});
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");


    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!e.target.checkValidity() || password !== confirmPassword || !isPasswordValid(password)) {
            setFormClass("was-validated");
            let err = {};
            if (password !== confirmPassword) {
                err = {confirmPassword: "error"}
            }
            if (!isPasswordValid(password)) {
                err = {...err, password: "error"};
            }
            setErrors(err);
            return;
        }
        setErrors({});

        const data = {
            fullname:`${firstName} ${lastName}`,
            email: email.trim(),
            password,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        const form = e.target;
        const fieldset = form.getElementsByTagName("fieldset")[0];
        fieldset.disabled = true;
        onCreate(data)
            .catch(e => {
                if (e.status === 403 && e.message.includes("Username already exists")) {
                    form.elements["email-input"].setCustomValidity("email-exists")
                    setFormClass("was-validated");
                    setExistingEmailError(true);
                }
            }).finally(() => {
            fieldset.disabled = false;
        });
    };

    return <form noValidate={true} className={formClass} onSubmit={onSubmit} autoComplete="off">
        <fieldset>
            <FG>
                <Input label={"nhis.patient.first_name_label"} value={firstName} required={true} setValue={setFirstName} autoComplete="new-password"/>
            </FG>
            <FG>
                <Input label={"nhis.patient.last_name_label"} value={lastName} required={true} setValue={setLastName} autoComplete="new-password"/>
            </FG>
            <FG>
                <Input label="email_label" name="email-input" value={email} required={true} setValue={(v, e) => {
                    setEmail(v);
                    e.target.setCustomValidity("");
                    if (existingEmailError) {
                        setExistingEmailError(false);
                    }
                }} type="email" autoComplete="new-password"/>
                <div className={existingEmailError ?'custom-invalid-feedback' : 'd-none'}>
                    {$$('register_form_email_exists_message')}
                </div>
            </FG>

            <FG>
                <FieldLabel labelKey={"password_label"} prop={password} required={true}/>
                <input autoComplete="new-password"
                       placeholder={`${!password && $$("password_label")}*`}
                       className={`${errors.password ? 'custom-error' : ''} form-control form-control-sm`}
                       type={"password"} value={password || ""} required={true}
                       onChange={(evt) => setPassword(evt.target.value)}/>
                <PasswordValidity password={password}/>
            </FG>
            <FG>
                <FieldLabel labelKey={"repeat_password_label"} prop={confirmPassword} required={true}/>
                <input autoComplete="new-password"
                       placeholder={`${!confirmPassword && $$("repeat_password_label")}*`}
                       className={`${errors.confirmPassword ? 'custom-error' : ''} form-control form-control-sm`}
                       type={"password"} value={confirmPassword || ""} required={true}
                       onChange={(evt) => setConfirmPassword(evt.target.value)}/>
            </FG>

            <FG className={"mt-3 text-right"}>
                <button type="submit" className="btn btn-primary" >{$$("confirm_btn_label")}</button>
                <button className="btn btn-danger ml-2" onClick={onCancel}>{$$("cancel_btn")}</button>
            </FG>
        </fieldset>
    </form>
}

function Existing({invEmail, onCancel, onSubmit, children}) {
    const [formClass, setFormClass] = useState("");
    const [email, setEmail] = useState(invEmail || "");
    const [invalidPasswordOrUsername, setInvalidPasswordOrUsername] = useState(false)
    const [roleMismatch, setRoleMismatch] = useState(false);
    const [notAHealthProvider, setNotAHealthProvider] = useState(false);
    const [errors, setErrors] = useState({});
    const [password, setPassword] = useState("");

    const formSubmit = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        let form = evt.target;
        if (!form.checkValidity()) {
            setFormClass("was-validated");
            //setErrors(err);
            return;
        }
        setErrors({});
        const fieldset = evt.target.getElementsByTagName("fieldset")[0];
        fieldset.disabled = true;
        onSubmit({
            email:email.trim(),
            password: password.trim()
        }).catch(e => {
            console.log("error while accepting invite", e)
            setFormClass("was-validated");
            if (e.status === 401) {
                setInvalidPasswordOrUsername(true);
            }
            if (e.status === 403) {
                if (e.message === 'Role mismatch') {
                    setRoleMismatch(true);
                }
                if (e.message === 'Not a health provider') {
                    setNotAHealthProvider(true);
                }
            }
        }).finally(() => {
            fieldset.disabled = false;
        })
        return false;
    }

    return <form noValidate={true} onSubmit={formSubmit} className={formClass} autoComplete="off">
        <fieldset>
            <FG>
                <label>{$$("email_label")}</label>
                <input name="email-input" value={email} type="email"
                       autoComplete="new-password"
                       onChange={(e)=>{
                           setEmail(e.target.value);
                           setInvalidPasswordOrUsername(false);
                           setNotAHealthProvider(false);
                           setRoleMismatch(false)}
                       }
                       className="form-control form-control-sm"/>
                {/*<div className={existingEmailError ?'custom-invalid-feedback' : 'd-none'}>
                                            {$$('register_form_email_exists_message')}
                                        </div>*/}
            </FG>
            <FG>
                <FieldLabel labelKey={"password_label"} prop={password} required={true}/>
                <input autoComplete="new-password" name="password-input"
                       placeholder={`${!password && $$("password_label")}*`}
                       className={`${errors.password ? 'custom-error' : ''} form-control form-control-sm`}
                       type={"password"} value={password || ""} required={true}
                       onChange={(evt) => {
                           setPassword(evt.target.value);
                           setInvalidPasswordOrUsername(false);
                           setNotAHealthProvider(false);
                           setRoleMismatch(false);
                       }}/>

            </FG>
            {invalidPasswordOrUsername && <FG>
                <p className={"text-danger"}>{$$("login_form_invalid_credentials_message")}</p>
            </FG>}
            {roleMismatch && <FG>
                <p className={"text-danger"}>{$$("role_mismatch_error_message")}</p>
            </FG>}
            {notAHealthProvider && <FG>
                <p className={"text-danger"}>{$$("account_type_mismatch_error_message")}</p>
            </FG>}
            {children}
            <FG className={"text-center mt-3"}>
                <button type="submit" className="btn btn-primary mr-2">{$$("confirm_btn_label")}</button>
                <button type="button" className="btn btn-danger" onClick={onCancel} >{$$("cancel_btn")}</button>
            </FG>
        </fieldset>
    </form>
}


function ChooseExistingOrNew({setRegType}) {

    return <div>

        <FG className={"mt-3"}>
            <button type="button" className="btn btn-primary mr-2" onClick={() => {
                setRegType("new")
            }}>{$$("Register")}
            </button>
            <button type="button" className="btn btn-primary" onClick={() => {
                setRegType("existing")
            }}>{$$("Login")}
            </button>
        </FG>
    </div>
}

function LoggedInWrapper({children}) {
    return <div style={{maxWidth: "600px"}}>
        <div className="patient-card">
            <div className="patient-card-header">
                <h2 className="card-title card-header-title">{$$("invitation_page_title")}</h2>
            </div>
            <div className="card-body">
                {children}
            </div>
        </div>
    </div>
}

function NotLoggedInWrapper({children, changeLang}) {
    return <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
            id="kt_login"
            className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
            <div
                className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
                <div
                    className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
                >
                    <div className="kt-grid__item">
                        <Link to="">
                            <div className="main-logo-label">MEDREC : M</div>
                            <div className="sub-logo-label">clinic</div>
                        </Link>
                    </div>
                    <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver center-justify">
                        <div className="kt-grid__item upload-img">
                        </div>
                    </div>

                    <div className="kt-grid__item">
                        <div className="kt-login__info small">
                            <div className="kt-login__copyright">
                                &copy; {new Date().getFullYear().toString()} Sirma Medical Systems
                                <div className="small">{$$("version")} {version} ({internal_version})</div>
                            </div>
                            <div className="kt-login__menu">
                                <a
                                    href="https://www.medrec-m.com/#!/remote"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="kt-link"
                                >
                                    {$$("about")}
                                </a>
                                <a
                                    href="https://medrec-m.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="kt-link"
                                >
                                    {$$("contact")}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
                    <div className="kt-login__head">
                        <div className="flex-container flex-row-reverse"><LanguageSelector className="show"
                                                                                           changeLanguage={changeLang}/>
                        </div>
                    </div>
                    <div style={{maxWidth: "600px", "marginLeft": "auto", "marginRight": "auto"}}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    </div>
}