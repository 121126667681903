import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import Select from '../shared/Select'
import {
    getResolvedOptions,
    SETTING_OPTIONS
} from '../../constants/select_options'
import {connect} from 'react-redux'
import {clearSelectedUserData} from '../../actions/users_actions'
import classnames from 'classnames'
import {SETTINGS} from "../../actions/actions";
import FormWithLoading from "../shared/FormWithLoading";
import PropTypes from "prop-types";

export class SettingsForm extends Component {
    state = {
        unitsOfMeasurement: this.props.settings.unitsOfMeasurement,
        cholesterolUnits: this.props.settings.cholesterolUnits,
        classificationMethod: this.props.settings.classificationMethod,
        dateFormat: this.props.settings.dateFormat,
        time24hour: this.props.settings.time24hour,
        glucoseUnits: this.props.settings.glucoseUnits,
        ketonesUnits: this.props.settings.ketonesUnits,
        hba1cUnits: this.props.settings.hba1cUnits,
        morningTime: this.props.settings.morningTime,
        noonTime: this.props.settings.noonTime,
        eveningTime: this.props.settings.eveningTime,
        bedTime: this.props.settings.bedTime,
        formclass: '',
        errors: {}
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.clearSelectedUserData();
    }

    /**
     * Set the state to the latest selected option.
     *
     * @param {object} evt - The event handler argument
     */
    onSelectChange = ({name, value}) => {
        const fields = Object.assign({}, this.state);
        fields[name] = value;
        this.setState(fields);
    };

    /**
     * On checkbox checked handler, set the correct value to state
     *
     * @param {object} evt - The event handler argument
     */
    onCheckboxChanged = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.checked;
        this.setState(fields);
    };

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.value;
        this.setState(fields);
    };

    /**
     * On label clicked handler, set the correct value to state
     *
     * @param {object} evt - The event handler argument
     */
    onLabelClick = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.htmlFor] = !fields[evt.target.htmlFor];
        this.setState(fields);
    }

    /**
     * Form submit handler, update the user settings.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        const formErrors = this.validate();
        this.setState({errors: formErrors});
        evt.preventDefault();

        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }

        if (Object.keys(formErrors).length) {
            return;
        }

        if (evt.target.checkValidity() === true) {
            this.props.updateUserSettings(this.props.userInfo.id, this.state);
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            this.setState({formclass: ""});
        }
    }

    /**
     * Validate form data.
     *
     * @returns {object} errors - Form errors after validate
     */
    validate = () => {
        const errors = {};
        if (!this.state.morningTime) {
            errors.morningTime = 'morning_time_required_message';
        }
        if (!this.state.noonTime) {
            errors.noonTime = 'noon_time_required_message';
        }
        if (!this.state.eveningTime) {
            errors.eveningTime = 'evening_time_required_message';
        }
        if (!this.state.bedTime) {
            errors.bedTime = 'bed_time_required_message';
        }
        return errors;
    }

    /**
     * Reset previous settings.
     */
    resetSettings = () => {
        this.setState({
            unitsOfMeasurement: this.props.settings.unitsOfMeasurement,
            cholesterolUnits: this.props.settings.cholesterolUnits,
            classificationMethod: this.props.settings.classificationMethod,
            dateFormat: this.props.settings.dateFormat,
            time24hour: this.props.settings.time24hour,
            glucoseUnits: this.props.settings.glucoseUnits,
            ketonesUnits: this.props.settings.ketonesUnits,
            hba1cUnits: this.props.settings.hba1cUnits,
            morningTime: this.props.settings.morningTime,
            noonTime: this.props.settings.noonTime,
            eveningTime: this.props.settings.eveningTime,
            bedTime: this.props.settings.bedTime,
            formclass: '',
            errors: {}
        });
    }

    render() {
        let alert = '';
        if (this.props.showSuccessfulAlert) {
            alert = (
                <div className="alert alert-success">
                    {$$('settings_updated_message')}
                </div>
            );
        }

        return (
            <div className="centered-form">
                <h2 className="text-center card-title">{$$("settings_label")}</h2>
                <div>
                    {alert}
                    <FormWithLoading
                        formDisabled={this.props.formDisabled}
                        currentForm={SETTINGS}
                        marginTop="30%"
                        marginLeft="20%"
                        onSubmit={(evt) => this.onSubmit(evt)}
                        className={classnames(this.state.formclass)}
                        noValidate={true}>
                        <div className="form-group">
                            <Select
                                label={$$('unit_of_measurement_label')}
                                name="unitsOfMeasurement"
                                options={getResolvedOptions(SETTING_OPTIONS.UNITS_OF_MEASUREMENTS)}
                                value={this.state.unitsOfMeasurement}
                                onChange={this.onSelectChange}
                                class="settings-input-color"/>
                        </div>
                        <div className="form-group">
                            <Select
                                label={$$('cholesterol_units_label')}
                                name="cholesterolUnits"
                                options={getResolvedOptions(SETTING_OPTIONS.CHOLESTEROL_UNITS)}
                                value={this.state.cholesterolUnits}
                                onChange={this.onSelectChange}
                                class="settings-input-color"/>
                        </div>
                        <div className="form-group">
                            <Select
                                label={$$('classification_method_label')}
                                name="classificationMethod"
                                options={SETTING_OPTIONS.CLASSIFICATION_METHODS}
                                value={this.state.classificationMethod}
                                onChange={this.onSelectChange}
                                class="settings-input-color"/>
                        </div>
                        <div className="form-group">
                            <Select
                                label={$$('glucose_units')}
                                name="glucoseUnits"
                                options={getResolvedOptions(SETTING_OPTIONS.GLUCOSE_UNITS)}
                                value={this.state.glucoseUnits}
                                onChange={this.onSelectChange}
                                class="settings-input-color"/>
                        </div>
                        <div className="form-group">
                            <Select
                                label={$$('ketones_units')}
                                name="ketonesUnits"
                                options={getResolvedOptions(SETTING_OPTIONS.KETONES_UNITS)}
                                value={this.state.ketonesUnits}
                                onChange={this.onSelectChange}
                                class="settings-input-color"/>
                        </div>
                        <div className="form-group">
                            <Select
                                label={$$('hba1c_units')}
                                name="hba1cUnits"
                                options={getResolvedOptions(SETTING_OPTIONS.HBA1C_UNITS)}
                                value={this.state.hba1cUnits}
                                onChange={this.onSelectChange}
                                class="settings-input-color"/>
                        </div>
                        <div className="form-group">
                            <Select
                                label={$$('date_format_label')}
                                name="dateFormat"
                                options={SETTING_OPTIONS.DATE_FORMATS}
                                value={this.state.dateFormat}
                                onChange={this.onSelectChange}
                                class="settings-input-color"/>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name="time24hour"
                                   checked={this.state.time24hour}
                                   onChange={this.onCheckboxChanged}
                            />
                            <label className="form-check-label" htmlFor="time24hour" onClick={this.onLabelClick}>
                                {$$('time_24hour_label')}
                            </label>
                        </div>
                        <div className="form-group settings-form">
                            <div className="d-flex settings-form-buttons">
                                <button type="button" onClick={this.resetSettings}
                                        className="btn btn-secondary">{$$("reset_btn_label")}</button>
                                <button type="submit" className="btn btn-primary settings-form-submit">
                                    {$$("save_btn_label")}</button>
                            </div>
                        </div>
                    </FormWithLoading>
                </div>
            </div>
        )
    }
}

SettingsForm.propTypes = {
    formDisabled: PropTypes.any,
    i18n: PropTypes.object,
    selectedUser: PropTypes.object,
    userInfo: PropTypes.object,
    settings: PropTypes.object,
    showSuccessfulAlert: PropTypes.bool,
    updateUserSettings: PropTypes.func,
    clearSelectedUserData: PropTypes.func
};

export default connect(null, {clearSelectedUserData})(SettingsForm);
