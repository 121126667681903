import React, {useState} from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import classNames from 'classnames'
import {isMobile} from 'react-device-detect';
import {ClickAwayListener} from "@material-ui/core";

export function InformationTooltip({children, className}) {
    return <TooltipComponent icon="i" className={className}>
        <div className={"tooltip-text"}>{children}</div>
    </TooltipComponent>
}

export function QuestionMarkTooltip({children, className}) {
    return <TooltipComponent icon="?" className={className}>
        <div className={"tooltip-text"}>{children}</div>
    </TooltipComponent>
}

function TooltipComponent({icon, children, className}) {
    const [showTooltip, setShowTooltip] = useState(false);

    return <ClickAwayListener onClickAway={() => setShowTooltip(false)}>
        <Tooltip arrow disableFocusListener title={children}
                 open={showTooltip}
                 disableHoverListener={isMobile}
                 disableTouchListener={isMobile}
                 onOpen={() => setShowTooltip(true)}
                 onClose={() => setShowTooltip(false)}
                 classes={{tooltip: "tooltip-content", arrow: "tooltip-arrow"}}>
            <span
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (isMobile) {
                        setShowTooltip(!showTooltip)
                    }
                }}
                onMouseDown={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
                className={classNames("btn-tooltip", {[className]: className})}>{icon}</span>
        </Tooltip>
    </ClickAwayListener>
}