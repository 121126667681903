import {combineReducers} from 'redux'
import {authentication, two_fa_settings} from './auth_reducers'
import {register} from './register_reducers'
import {language} from './language_reducers'
import {
    appointmentEncounter,
    appointmentStats,
    cardiacStats,
    charts,
    dashboardCharts, groupAppointment,
    lastAppointment,
    latestUserMeasurements,
    logBook,
    medicalProfile,
    priceList,
    providerAppointments,
    providerImage,
    providerImageVisibility,
    selectedAppointment,
    selectedAppointmentInProgress,
    selectedEncounter,
    selectedUser,
    selectedUserNotes,
    specialties,
    timeTables,
    userAppointments,
    userInfo,
    userPassword,
    users
} from './users_reducers'
import {settings} from './settings_reducer'
import {nomenclature, symptoms} from './symptoms_reducers'
import {permissions} from './permissions_reducers'
import {metronic} from "../_metronic";
import {documents} from './documents_reducers'
import {chat} from './chat_reducers'
import {medication, prescription} from './medications_reducers'
import {practitioner} from './general_practitioner_reducer'
import {laboratories, labResults} from './lab-results_reducers'
import {cameraDevices, video} from './video_reducer'
import {notifications} from './notifications_reducers'
import {userSelections} from "./user_selections_reducers";
import {icd} from "./icd_reducer";
import {menu} from "./menu_reducers"
import {formInteractions} from "./form_interactions_reducer";
import {firstAvailable, management, providerTimetable} from "./management_reducer";
import {patient_register} from "./patient_registration";
import {revenue} from "./revenue_reducers";
import {provider_configuration} from "./provider_configuration_reducer";
import {chat_settings} from "./chat_settings_reducer";
import {appointmentFilters} from "./appointment_filter_reducers";

const reducer = combineReducers({
    builder: metronic.builder.reducer,
    authentication,
    register,
    language,
    users,
    selectedUser,
    cardiacStats,
    logBook,
    charts,
    dashboardCharts,
    settings,
    nomenclature,
    symptoms,
    permissions,
    latestUserMeasurements,
    userInfo,
    userPassword,
    documents,
    medicalProfile,
    chat,
    medication,
    prescription,
    practitioner,
    icd,
    laboratories,
    labResults,
    providerAppointments,
    userAppointments,
    lastAppointment,
    selectedAppointment,
    selectedAppointmentInProgress,
    appointmentEncounter,
    appointmentStats,
    selectedUserNotes,
    selectedEncounter,
    timeTables,
    video,
    cameraDevices,
    notifications,
    specialties,
    providerImage,
    providerImageVisibility,
    userSelections,
    priceList,
    menu,
    formInteractions,
    management,
    patient_register,
    firstAvailable,
    providerTimetable,
    revenue,
    provider_configuration,
    groupAppointment,
    two_fa_settings,
    appointmentFilters,
    chat_settings
});

export default reducer;
