import React, {Component} from 'react'
import Message from './Message';
import PropTypes from "prop-types";
import DateLine from "./DateLine";
import {isSameDay} from "date-fns";

class ChatMessages extends Component {

    state = {
        firstTimeScrollVisible: false,
        scrollIsAtBottom: null
    }

    constructor(props) {
        super(props);
        this.messagesContainer = React.createRef();
        this.anchorRef = React.createRef();
    }

    componentDidMount() {
        this.messagesContainer.current.addEventListener('scroll', this.handleScroll);
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState) {
        //Scroll to bottom when mew message is received
        if (prevProps.messages !== this.props.messages && this.props.messages.length > 0) {
            this.messagesContainer.current.scrollTop = this.anchorRef.current.offsetTop;
            this.anchorRef.current.scrollIntoView({block: "end"});
        }
    }

    componentWillUnmount() {
        this.messagesContainer.current.removeEventListener('scroll', this.handleScroll);
    }

    /**
     * On scroll handler. Handle scroll position changed, set flag is scroll is at bottom of container
     */
    handleScroll = () => {
        const scrollAtBottomValue = Math.ceil(this.messagesContainer.current.scrollTop) - (this.messagesContainer.current.scrollHeight - this.messagesContainer.current.offsetHeight);
        const currentScrollAtBottom = scrollAtBottomValue >= 0 && scrollAtBottomValue <= 1;
        if (!this.state.scrollIsAtBottom && currentScrollAtBottom) {
            this.setState({scrollIsAtBottom: true});
        } else if (this.state.scrollIsAtBottom && !currentScrollAtBottom) {
            this.setState({scrollIsAtBottom: false});
        }
    }
    getMessages = () => {

        let date = 0

        return this.props.messages ? this.props.messages.map((m, idx) => {
            if (!m.deleted) {
                const isSent = m.from_user_id === this.props.loggedUser.id;

                let dateline = false

                if(!isSameDay(date, m.date_time)){
                    dateline = true
                    date = m.date_time
                }

                return <span key={idx}>
                    {dateline && <DateLine isSent={isSent} message={m}/>}
                    <Message selectedMessages = {this.props.selectedMessages} messageId ={m} isSent={isSent} message={m}/>
                </span>
            }
            return '';
        }) : '';
    }

    render() {
        return (
            <div className="conversation-container sub-text-safari" ref={this.messagesContainer}>
                {this.getMessages()}
                <div id="anchor" ref={this.anchorRef}>
                </div>
            </div>
        )
    }
}

ChatMessages.propTypes = {
    loggedUser: PropTypes.object,
    messages: PropTypes.array,
    selectedMessages: PropTypes.array
}

export default ChatMessages
