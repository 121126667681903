import React, {Component} from "react";

import MedF from "../../resources/images/pill_shapes/pngs/no_pills.png";
import MedFMask from "../../resources/images/pill_shapes/masks/no_pills_mask.png";
import PropTypes from "prop-types";


export default class NoPill extends Component {


    render() {
        return (<div className={ "pill-picker-selected"}
                     style={{position: "relative", height: "75px", display: "flex", justifyContent: "center"}}>
            <img src={MedF} style={{
                position: "absolute",
                top: "10px",
                height: "50px",
                width: "50px",
                filter: "drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5))"
            }} alt={"Med Pill Round Out L One Solid X"}/>

            <div/>
            <div/>
            <div style={{
                marginTop: "10px",
                backgroundImage: `url(${MedF})`,
                mask: `url(${MedFMask})`,
                WebkitMask: `url(${MedFMask})`,
                WebkitMaskSize: "contain",
                maskSize: "contain",
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                backgroundColor: "#FFFFFF",
                backgroundBlendMode: "multiply",
                position: "absolute",
                top: 0,
                height: "50px",
                width: "50px"
            }}/>
        </div>)
    }
}

NoPill.propTypes = {
    MedB:  PropTypes.string,
    MedBMask:  PropTypes.string,
    MedF:  PropTypes.string,
    MedFMask: PropTypes.string,
    MedM:  PropTypes.string,
    MedMMask:  PropTypes.string,
    primaryColour:  PropTypes.string,
    secondaryColour:  PropTypes.string,
    selected:  PropTypes.bool
}