import React from "react";
import LanguageSelector from "../../../components/layout/LanguageSelector"
import {connect} from 'react-redux'
import {logout} from '../../../actions/auth_actions'
import {changeLanguage} from '../../../actions/language_actions'
import {Routes} from '../../../constants/routes'
import {Link} from 'react-router-dom'
import {$$} from '../../../helpers/localization'
import UserImage from "../../../components/shared/UserImage";
import classNames from 'classnames'
import {MessageWebSocketComponent} from '../../../components/shared/MessageWebSocketComponent';
import {authHelper} from '../../../helpers/auth_helper';
import {storeMessageInReduxStore} from '../../../actions/chat_actions'
import {getUserImage} from '../../../service/users_service'
import noImage from '../../../../public/media/profile/no_image.png'
import history from "../../../helpers/history";
import MessageNotifications from "./MessageNotifcations";
import {getClinicianNameWithTitle} from "../../../utils/getClinicianNameWithTitle";
import {VideoContext} from "../../../components/videocall/VideoProvider";
import {updateVideoData} from "../../../actions/video_actions";
import useParticipants from "../../../components/videocall/hooks/useParticipants/useParticipants";
import VideoControlsWrap from "../../../components/videocall/VideoControlsWrap";
import useIsRoomForGroupEvent from "../../../components/videocall/hooks/useIsRoomForGroupEvent/useIsRoomForGroupEvent";
import useRoomState from "../../../components/videocall/hooks/useRoomState/useRoomState";
import usePublications from "../../../components/videocall/hooks/usePublications/usePublications";
import useTrack from "../../../components/videocall/hooks/useTrack/useTrack";
import AudioTrack from "../../../components/videocall/AudioTrack";
import PropTypes from "prop-types";
import {updateUserSettings} from '../../../actions/settings_actions'
import SettingsOption from "./SettingsOption";

class Topbar extends React.Component {
    static contextType = VideoContext;

    constructor(props, context) {
        super(props, context);
        this.isLoggedIn = authHelper.isLoggedIn();

        this.state = {
            showNotification: false,
            settingsModalOpen: false
        }

        this.imgRef = React.createRef();
    }


    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.chat.new_message
            && this.props.chat.new_message.type === "TEXT"
            && this.props.chat.new_message.from_user_id !== this.props.user.data.id
            && this.props.chat.new_message.id !== prevProps.chat.new_message?.id
            && !this.props.chat.new_message.seen
            && !this.state.showNotification
            && history.location.pathname !== Routes.MESSAGES
        ) {
            this.fetchUserImage(this.props.chat.new_message);
            this.setState({
                showNotification: true
            })
        }
    }

    /**
     * Fetch selected user image, if there is not found, display placeholder
     */
    fetchUserImage = (message) => {
        try {
            getUserImage(message.from_user_id).then((res) => {
                this.imgRef.current.type = "image/jpg"
                this.imgRef.current.src = res;
            }).catch(() => {
            });
        } finally {
            if (this.imgRef && this.imgRef.current && message && message.from_user_id !== this.props.chat.new_message.from_user_id) {
                this.imgRef.current.type = "image/png"
                this.imgRef.current.src = noImage;
            }
        }
    }

    render() {
        let videoControlsClass = classNames("video-controls-wrap", {"d-none": !(this.context.room && this.context.room.state === 'connected')});
        return (
            <div className="kt-header__topbar">
                {this.isLoggedIn &&
                    <MessageWebSocketComponent storeMessageInReduxStore={this.props.storeMessageInReduxStore}/>}
                <div className={videoControlsClass}>
                    <RemoteName/>
                    <RemoteAudio/>
                    <VideoControlsWrap onHangup={() => this.props.updateVideoData({initiate_call: false})}
                    />
                </div>
                <div className="dropdown" style={{"alignSelf": "center"}}>
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown"
                       aria-haspopup="true" aria-expanded="false">
                        <UserImage userID={this.props.user.data.id} classnames="topbar-photo"/>
                        <span>{getClinicianNameWithTitle(this.props.user.data)}</span>
                    </a>

                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                        <Link className="dropdown-item" to={Routes.PROFILE}><i
                            className="kt-nav__link-icon flaticon-profile-1"/> <span
                            className="kt-nav__link-text dropdown-link">{$$('profile_label')}</span></Link>
                        <Link className="dropdown-item" to={Routes.SECURITY}><i
                            className="kt-nav__link-icon flaticon-security"/> <span
                            className="kt-nav__link-text dropdown-link">{$$('security_label')}</span></Link>
                        <Link className="dropdown-item" to={Routes.CHANGE_PASSWORD}><i
                            className="kt-nav__link-icon flaticon-lock"/> <span
                            className="kt-nav__link-text dropdown-link">{$$('change_password_label')}</span></Link>
                        {this.props.user.data.internal && <span className="dropdown-item pointer" onClick={() => {
                            this.setState({settingsModalOpen: true})
                        }}><i className="kt-nav__link-icon flaticon-settings"/> <span
                            className="kt-nav__link-text dropdown-link">{$$('settings_label')}</span></span>}
                        <Link className="dropdown-item" to={Routes.LOGIN} onClick={() => this.props.logout()}><i
                            className="kt-nav__link-icon flaticon-logout"/> <span
                            className="kt-nav__link-text dropdown-link">{$$('logout_label')}</span></Link>
                    </div>
                    {this.state.settingsModalOpen && <SettingsOption
                        settingsModalOpen={this.state.settingsModalOpen}
                        closeSettingsModal={() => {
                            this.setState({settingsModalOpen: false})
                        }}
                    />}

                </div>
                <LanguageSelector className="show" changeLanguage={this.props.changeLanguage}/>
                {this.state.showNotification &&
                    <div
                        aria-live="polite"
                        aria-atomic="true"
                        style={{
                            position: 'relative',
                            minHeight: '300px',
                        }}
                    >

                        <MessageNotifications message={this.props.chat.new_message}
                                              imgRef={this.imgRef}
                                              i18n={this.props.i18n}
                                              desktopNotifications={'Notification' in window && Notification.permission === "granted"}
                                              showNotification={this.state.showNotification}
                                              closeNotification={() => this.setState({showNotification: false})}
                        />


                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth_data: state.authentication.data,
        i18n: state.language,
        user: state.userInfo,
        settings: state.settings.data,
        userToChatFullName: state.video.data.userToChatFullName,
        chat: state.chat
    }
}

export default connect(mapStateToProps, {
    logout,
    updateUserSettings,
    changeLanguage,
    storeMessageInReduxStore,
    updateVideoData
})(Topbar);


function RemoteAudio() {
    const roomState = useRoomState();
    const participants = useParticipants();

    if (roomState == 'connected') {
        return <span>
            {participants.map(p => {
                return <PAudio key={p.sid} participant={p}/>
            })
            }
        </span>
    }
    return null;
}

// eslint-disable-next-line react/prop-types
function PAudio({participant}) {
    const publications = usePublications(participant);
    const audioPublication = publications.find(p => p.kind === 'audio');
    const audioTrack = useTrack(audioPublication);
    if (audioTrack) {
        return <AudioTrack track={audioTrack}/>
    }
    return null;
}

function RemoteName() {
    const isRoomForGroupEvent = useIsRoomForGroupEvent();
    let name = useRemoteName();
    if (name && !isRoomForGroupEvent) {
        return (<div className="video-controls-username">{name}</div>);
    }
    return null;
}

function useRemoteName() {
    const participants = useParticipants();
    let p = participants.length > 0 && participants[0];
    if (!p) {
        return null;
    }
    let identity = p.identity;
    let idName = identity.split(":");
    if (idName.length > 1) {
        return idName[1];
    } else {
        return "Unknown";
    }
}

Topbar.propTypes = {
    i18n: PropTypes.any,
    chat: PropTypes.any,
    settings: PropTypes.any,
    updateUserSettings: PropTypes.func,
    user: PropTypes.any,
    storeMessageInReduxStore: PropTypes.any,
    logout: PropTypes.func,
    changeLanguage: PropTypes.func,
    updateVideoData: PropTypes.func
}