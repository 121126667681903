import React from 'react'

export const infoUtils = {
    noData, noDataLargerObj
}

/**
 * Returns the message to be displayed when there's no data
 *
 * @param {object} obj - the object containing all the information needed for
 * rendering the view when there's no data to display
 *
 * @returns {HTMLElement} the appropriate message when there's no data wrapped in a html div
 */
function noData(obj) {
    return <div className={"centered-text"}>
        <img className={obj.imgClass} src={obj.src}/>
        <br/> <br/>
        <span className={obj.primaryLabelClass}>{obj.primaryLabel}</span>
        <br/>
        <span className={obj.secondaryLabelClass}>{obj.secondaryLabel}</span>
    </div>
}

/**
 * Returns the message to be displayed when there's no data
 *
 * @param {object} obj - the object containing all the information needed for
 * rendering the view when there's no data to display
 *
 * @returns {HTMLElement} the appropriate message when there's no data wrapped in a html div
 */
function noDataLargerObj(obj) {
    return <div className={"centered-text"}>
        <img className={obj.imgClass.toString() + " no-data-img" } src={obj.src}/>
        <br/> <br/>
        <span className={obj.primaryLabelClass + " no-data-txt-header"}>{obj.primaryLabel}</span>
        <br/>
        <span className={obj.secondaryLabelClass}>{obj.secondaryLabel}</span>
    </div>
}