import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import classnames from 'classnames';
import moment from "moment";
import PropTypes from "prop-types";
import {ReactComponent as DeleteIcon} from "../../../public/media/icons/Delete.svg";
import {ReactComponent as EditIcon} from "../../../public/media/icons/Edit.svg";
import {OverlayTrigger, Tooltip} from "react-bootstrap";


export class DocumentEntry extends Component {
    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        this.props.resetDocument();
    }

    editTooltip = props => (
        <Tooltip {...props} show={"true"}>{$$("edit_label")}</Tooltip>
    );

    deleteTooltip = props => (
        <Tooltip {...props} show={"true"}>{$$("delete_label")}</Tooltip>
    );


    /**
     * Handles on filename click and calls the appropriate method
     *
     * @param {object} e the event object
     */
    onFileNameClick = (e) => {
        e.preventDefault();
        if (this.props.providerDocuments) {
            this.props.showDownloadModal(this.props.entry);
        } else {
            this.props.showModal(this.props.entry.id, this.props.entry.filename);
        }
    }

    /**
     * Handles the delete icon click and calls the appropriate method
     *
     * @param {object} e the event object
     */
    onDeleteIconClick = (e) => {
        e.preventDefault();
        this.props.showDeleteModal(this.props.entry);
    }

    /**
     * Convert the type enum to text
     *
     * @param {string} type the type of the document
     * @param {string} type_description the type description
     * @returns {string} the text for the corresponding type
     */
    convertTypeToText = (type, type_description) => {
        switch (type) {
            case "NONE":
                return $$("document_type_none");
            case "EPICRISIS":
                return $$("document_type_epicrisis");
            case "PRESCRIPTION":
                return $$("document_type_prescription");
            case "LAB_RESULTS":
                return $$("document_type_lab_results");
            case "AMBULANCE_SHEET":
                return $$("document_type_ambulance_sheet");
            case "REFERRAL":
                return $$("document_type_referral");
            case "DIAGNOSTIC_IMAGING":
                return $$("document_type_diagnostic_imaging");
            case "OTHER":
                return type_description; //use the type description field which is provided from the user for OTHER /custom/ types
            default:
                return ""; //unknown
        }
    }

    /**
     * Selects a document
     */
    selectDocument = () => {


        if (this.props.selectedDocumenttId === '' || this.props.selectedDocumenttId !== this.props.entry.id) {
            this.props.selectDocument(this.props.entry.id);
        }
    }

    onView = () => {
        if (this.props.onOpenInViewer) {
            this.props.onOpenInViewer(this.props.entry);
        }
    }

    fileSize(bytes, si = false, dp = 1) {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10 ** dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


        return bytes.toFixed(dp) + ' ' + units[u];
    }

    render() {

        const activeClass = classnames('document-entry lab-entry-container', {
            'lab-entry-active': (this.props.selectedDocumenttId === this.props.entry.id)
        });

        const dotClass = classnames({'red-dot': this.props.unread})

        return (
            <div className={activeClass} onClick={this.selectDocument}>
                {!this.props.providerDocuments &&
                    <div className="document-entry-container container">
                        <div className="row">
                            <div className='col-xs-2 col-md-2 text-center'>
                                <div className="d-flex justify-content-center">
                                    <div className="d-flex align-items-center p-2">
                                        <span className={dotClass}/>
                                    </div>
                                    <div>
                                        <div
                                            className='medrec-grey-2 lab-entry-label'>{$$('lab_results_date_label')}</div>
                                        <div>
                                            <b>{moment(this.props.entry.date_time).locale(this.props.i18n.lang).format('D MMM')}</b>
                                        </div>
                                        <div
                                            className='lab-entry-year'>{moment(this.props.entry.date_time).locale(this.props.i18n.lang).format('YYYY')}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-3 col-md-3 break-word">
                                <div className='medrec-grey-2 lab-entry-label'>{$$('document_type_label')}</div>
                                <div>
                                    <b>{this.convertTypeToText(this.props.entry.type, this.props.entry.typeDescription)}</b>
                                </div>
                            </div>
                            <div className="col-xs-3 col-md-3 break-word">
                                <div className='medrec-grey-2 lab-entry-label'>{$$('title_label')}</div>
                                <div>{this.props.entry.title}</div>
                            </div>
                            <div className="col-xs-3 col-md-3">
                                <div className='medrec-grey-2 lab-entry-label'>{$$('download_btn')}</div>
                                <div>
                                    <a href="#" onClick={this.onFileNameClick}>
                                        <div className="text-overflow-hidden" style={{maxWidth:"20rem"}}>{this.props.entry.filename}</div>
                                    </a>
                                </div>
                                <div className="small medrec-grey-2">
                                    {this.fileSize(this.props.entry.size, true)}
                                </div>
                            </div>
                            <div className="col-xs-1 col-md-1">
                                {this.props.entry.type === 'DIAGNOSTIC_IMAGING' &&
                                    <button className="btn btn-outline-primary btn-icon btn-icon-sm btn-circle btn-sm"
                                            onClick={this.onView}>
                                        <i className="icon icon-viewer"/>
                                    </button>}
                            </div>
                        </div>
                        {this.props.entry.notes && !this.props.entry.hideNotes ? <div className="row">
                            <div className="col-xs-2 col-md-2 word-break">
                                <div className='flex-0-0-auto medrec-grey-2 text-center'>{$$('notes')}</div>
                            </div>
                            <div
                                className="col-xs-10 col-md-10 text-justify lighter-font"
                                style={{paddingTop: "0.188rem"}}>{this.props.entry.notes}</div>
                        </div> : ""}
                        <hr className="kt-margin-b-0"/>
                    </div>
                }
                {this.props.providerDocuments &&
                    <div>
                        <div className="card-header entry-card-header">
                        </div>
                        <div className="card-body" style={{"paddingTop": "0px"}}>
                            <div className="row">
                                <div className="col-11">
                                    <i className="kt-menu__link-icon flaticon2-clip-symbol"
                                       style={{"marginRight": "5px"}}/>
                                    <a href="#" onClick={this.onFileNameClick}>{this.props.entry}</a>
                                </div>
                                <button className='btn btn-block col-1' onClick={this.onDeleteIconClick}
                                        style={{'padding': '0px 0px 0px 20px', 'marginTop': '0px'}}>
                                    <i className='kt-menu__link-icon flaticon-delete' style={{'float': 'right'}}/>
                                </button>
                            </div>
                        </div>
                    </div>
                }

                {this.props.encounterDocuments && this.props.loggedInUserId === this.props.entry.created_by && <>
                    <span onClick={() => {
                        this.props.showEncounterDocumentsDeleteConfirmationModal(this.props.entry)
                    }}
                          className="pointer prescription-delete-icon mr-1 mt-1">
                                            <OverlayTrigger
                                                placement={"top"}
                                                overlay={this.deleteTooltip}
                                                popperConfig={{
                                                    modifiers: {
                                                        preventOverflow: {
                                                            enabled: false
                                                        },
                                                        hide: {
                                                            enabled: false
                                                        }
                                                    }
                                                }}
                                            >
                        <DeleteIcon className="svg-blue" height="17px" width="17px"/>
                    </OverlayTrigger>


                            </span>
                    <span onClick={() => {
                        this.props.showEncounterDocumentsAddUpdateModal(this.props.entry)
                    }}
                          className="pointer prescription-edit-icon mr-1 mb-1">
                                            <OverlayTrigger
                                                placement={"top"}
                                                overlay={this.editTooltip}
                                                popperConfig={{
                                                    modifiers: {
                                                        preventOverflow: {
                                                            enabled: false
                                                        },
                                                        hide: {
                                                            enabled: false
                                                        }
                                                    }
                                                }}
                                            >
                        <EditIcon className="svg-blue" height="17px" width="17px"/>
                    </OverlayTrigger>
                    </span>
                </>}
            </div>
        )
    }
}

DocumentEntry.propTypes = {
    entry: PropTypes.any,
    filters: PropTypes.object,
    showEncounterDocumentsDeleteConfirmationModal: PropTypes.func,
    showEncounterDocumentsAddUpdateModal: PropTypes.func,
    providerDocuments: PropTypes.object,
    encounterDocuments: PropTypes.bool,
    i18n: PropTypes.object,
    index: PropTypes.number,
    onOpenInViewer: PropTypes.func,
    showDownloadModal: PropTypes.func,
    showDeleteModal: PropTypes.func,
    resetDocument: PropTypes.func,
    selectDocument: PropTypes.func,
    selectedDocumenttId: PropTypes.string,
    showModal: PropTypes.func
}

export default DocumentEntry
