import React, {useCallback, useEffect, useRef, useState} from "react";
import _ from "underscore";
import moment from "moment";
import Search from "../../shared/Search";
import {$$} from "../../../helpers/localization";
import UserFilterOptions from "./UserFilterOptions";
import phoneHand from "../../../../public/media/chat/phone_hand.png";
import classnames from "classnames";
import {getFirstContactInfoName} from "../../../utils/userUtils";
import {getUserImage} from "../../../service/users_service";
import noImage from "../../../../public/media/profile/no_image.png";
import {COMPLETED, IN_PROGRESS, INITIATED, MISSED, REJECTED} from "../../../constants/video_message_status";

export function UsersComponent({users, latestMessages, lang, selectChat, unreadMessages, selectedUserId, small}) {

    const [locale, setLocale] = useState("en");
    const [searchValue, setSearchValue] = useState("");
    const [defaultSort, setDefaultSort] = useState(true);
    const [onlyNewMessages, setOnlyNewMessages] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState(users)

    useEffect(()=>{
        if (!small && !selectedUserId && filteredUsers.length > 0) {
            selectChat(filteredUsers[0]);
        }
    }, [small, selectedUserId, filteredUsers])

    useEffect(() => {
        if (lang) {
            let locale = _.contains(['en', 'sq'], lang) ? 'en' : lang;
            moment.locale(locale);
            setLocale(locale);
        }
    }, [lang])

    useEffect(()=>{
        let filteredUsers = users.filter(u => !searchValue
            || u.fullname.toLowerCase().includes(searchValue.toLowerCase().trim())
            || u.email.toLowerCase().includes(searchValue.toLowerCase().trim())
        )

        if (onlyNewMessages) {
            filteredUsers = filteredUsers.filter(u => {
                let latestMessage = latestMessages.find((m) => {
                    let direct = m.from_user_id === u.id;
                    if (direct) return true;
                    let indirect = u.parentContactInfos?.find(u => m.from_user_id === u.id);
                    return !!indirect;
                });
                return latestMessage && !latestMessage.seen;
            })
        }

        if (!defaultSort) {
            filteredUsers = filteredUsers.sort(function (a, b) {
                return a.fullname < b.fullname ? -1 : (a.fullname > b.fullname ? 1 : 0);
            })
        }

        setFilteredUsers(filteredUsers);

    }, [users, latestMessages, searchValue, defaultSort, onlyNewMessages])


    const search = useCallback((v) => {
        setSearchValue(v);
    }, [])

    const sortByName = useCallback(() => {
        setDefaultSort(false)
    }, [])

    const onlyNewClicked = useCallback(() => {
        setOnlyNewMessages(!onlyNewMessages);
    }, [onlyNewMessages])

    const sortByMostRecent = useCallback(() => {
        setDefaultSort(true)
    }, [])

    return <div className="w-100">
        <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "baseline"
        }}>
            <div className="flex-fill">
                <Search handleSearchChange={search}
                        searchValue={searchValue}
                        placeholder={$$('search_in_messages_label')}/>
            </div>
            <div className="flex-0-0-auto">
                <UserFilterOptions
                    defaultSort={defaultSort}
                    onlyNewMessages={onlyNewMessages}
                    sortByName={sortByName}
                    onlyNewClicked={onlyNewClicked}
                    sortByMostRecent={sortByMostRecent}
                />
            </div>
        </div>
        <UsersList users={filteredUsers} latestMessages={latestMessages} lang={lang} locale={locale} selectChat={selectChat}
                   unreadMessages={unreadMessages}
                   selectedUserId={selectedUserId}
                   onlyNewMessages={onlyNewMessages}
        />
    </div>
}

function UsersList({users, latestMessages, lang, locale, selectChat, unreadMessages, selectedUserId, onlyNewMessages}) {

    return <div className="list-group scrollable-users">

        {users?.length === 0 && !onlyNewMessages &&
        <div className={"centered-text"}>
            <img height={"150px"} width={"150px"} src={phoneHand} alt="phone"/>
            <h4>{$$('no_users_after_filtering')}</h4>
        </div>}

        {users?.length === 0 && onlyNewMessages &&
        <div className={"centered-text"}>
            <img height={"150px"} width={"150px"} src={phoneHand} alt="phone"/>
            <h4>{$$('no_unread_messages')}</h4>
        </div>}

        {users && users.map((u, i) => {
                let latestMessage = latestMessages.find((m) => {
                    let direct = m.to_user_id === u.id || m.from_user_id === u.id;
                    if (direct) return true;
                    let indirect = u.parentContactInfos?.find(u => m.to_user_id === u.id || m.from_user_id === u.id);
                    return !!indirect;
                });

                let userUnreadMessages = unreadMessages.list.find(m =>m.sender === u.id || !!u.parentContactInfos?.find(u => m.sender === u.id));
                return <User key={i} user={u} latestMessage={latestMessage} lang={lang} locale={locale} selectChat={selectChat} unreadMessages={userUnreadMessages} isActive={selectedUserId === u.id} />
            }
        )}
    </div>
}

function User({user, isActive, latestMessage, lang, locale, selectChat, unreadMessages}) {
    const activeClass = classnames('list-group-item list-group-item-action flex-column align-items-start', {
        'selected-message': isActive
    });


    return (
        <a href="#" className={activeClass} onClick={(e) => {
            e.preventDefault();
            selectChat(user);
        }}>
            <div>
                <div>
                    <div className="user-chat-info">
                        <UserImage id={user.id}/>
                        <div className={"chat-blurb"}>
                            <Name user={user}/>
                            <Contact contactInfos={user.parentContactInfos} />
                            <MessageText message={latestMessage} lang={lang} />
                            <UnreadMessages unreadMessages={unreadMessages} />
                        </div>

                    </div>
                </div>
                <MessageTime message={latestMessage} lang={lang} locale={locale}/>
                <div className="message-item-line"/>
            </div>
        </a>
    )
}

function Name({user}) {
    return <div><b>{
        shorten(user.fullname)
    }</b></div>
}

function shorten(text) {
    return text.length > 27 ? text.substr(0, 27) + "..." : text;
}

function UnreadMessages({unreadMessages}) {
    if (unreadMessages && unreadMessages.count > 0) {
        return <span className="message-count-label chat-blurb chat-unread-messages">{unreadMessages.count}</span>
    }
    return null;
}

function Contact({contactInfos}) {
    const [name, setName] = useState(getFirstContactInfoName({parentContactInfos: contactInfos}));

    useEffect(() => {
        setName(getFirstContactInfoName({parentContactInfos: contactInfos}))
    }, [contactInfos])

    if (name) {
        return <div className="small">({shorten(name)})</div>
    }
    return null;
}

function UserImage({id}) {

    const [src, setSrc] = useState();
    const [defaultImage, setDefaultImage] = useState(true);

    const isMounted = useRef(true)

    useEffect(()=>{
        return ()=> {
            isMounted.current = false;
        }
    }, [])

    useEffect(() => {
        setDefaultImage(true);
        fetchUserImage();
    }, [id])

    const fetchUserImage = () => {
        getUserImage(id).then((res) => {
            if (isMounted.current) {
                setSrc(res);
                setDefaultImage(false);
            }
        }).catch(() => {
            if (!defaultImage && isMounted.current) {
                setDefaultImage(true);
            }
        });
    }


    if (defaultImage) {
        return <DefaultImage/>
    }

    return <img className="user-chat-image small-margin" src={src}
                style={{"width": "43px", "height": "43px"}} />
}

function DefaultImage() {
    return <img className="user-chat-image small-margin" src={noImage}
                style={{"width": "43px", "height": "43px"}} />
}

function MessageText({message, lang}) {

    const [videoMessageText, setVideoMessageText] = useState();

    useEffect(() => {
        if (message && message.type === "VIDEO" && message.video_status !== INITIATED) {
            setVideoMessageText(getVideoMessageText(message))
        }
    }, [message, lang])

    if (!message) {
        return null;
    }

    if (message.type === 'TEXT') {
        return <div className="mb-1 shortened-message">{message.message}</div>
    }

    if (message.type === "VIDEO" && message.video_status !== INITIATED) {
        return <div className="mb-1 shortened-message">{videoMessageText}</div>
    }

    return null;
}

function getVideoMessageText(message) {
    if (message.type === "VIDEO") {
        switch (message.video_status) {
            case MISSED:
                return <b>{$$('missed_call_label')}</b>;
            case REJECTED:
                return <b>{$$('rejected_call_label')}</b>;
            case IN_PROGRESS:
                return <b>{$$('in_progress_call_label')}</b>;
            case COMPLETED:
                return <span><b>{$$('completed_call_label')}</b>: {moment.utc(message.video_duration).format('HH:mm:ss')}</span>;
        }
    }
    return '';
}

function MessageTime({message, lang, locale}) {
    const [text, setText] = useState();

    useEffect(() => {
        if (message && message.video_status !== INITIATED) {
            setText(moment(message.date_time).fromNow());
        }
    }, [message, locale])

    if (!text) {
        return null;
    }

    return <div className=" chat-blurb message-time-from-now">
        {text}
    </div>
}