import React, {Component} from 'react'
import PropTypes from "prop-types";
import {$$} from "../../../helpers/localization";
import {DOCUMENTS_TYPES} from "../../../constants/select_options";
import {DOCUMENT} from "../../../actions/actions";
import enGB from "date-fns/locale/en-GB";
import bg from "date-fns/locale/bg";
import {connect} from "react-redux";
import moment from "moment";
import {documentsService} from "../../../service/docments_service";
import {appointmentUtils} from "../../../utils/appointmentUtils";
import DocumentsList from "./DocumentsList";
import AddDocumentButton from "./AddDocumentButton";
import DocumentDeleteModal from "./DocumentDeleteModal";
import DocumentAddEditModal from "./DocumentAddEditModal";

class ProviderEncounterDocuments extends Component {
    constructor(props) {
        super(props)
    }

    state = {
        selectEntry: {},
        deletedConfirmationModal: false,
        documentAddEditModal: false,
        documentTitle: "",
        documentFileName: "",
        documentType: DOCUMENTS_TYPES.TYPE[3].value,
        documentDate: moment().valueOf(),
        documentTime: moment().valueOf(),
        documents: [],
        typeDescription: "",
        fileChanged: false,
        formclass: "",
        provider_document_ids: []
    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        if (evt.target) {
            fields[evt.target.name] = evt.target.value;
        } else {
            fields[evt.name] = evt.value;
        }
        this.setState(fields);
    }

    getLocale = () => {
        switch (this.props.i18n.lang) {
            case "en":
                return enGB
            case "bg":
                return bg
            default:
                return enGB
        }
    }

    onFileChange = (e) => {
        this.setState({
            fileChanged: true,
            file: e.target.files[0],
            documentFileName: e.target.files[0].name,
            mime_type: e.target.files[0].type,
            size: e.target.files[0].size,
        }, this.validate)

    }
    ShowDeletedConfirmationModal = (entry) => {
        this.setState({
            selectEntry: entry,
            deletedConfirmationModal: true
        })
    }
    hideDeletedConfirmationModal = () => {
        this.setState({
            selectEntry: {},
            deletedConfirmationModal: false
        })
    }

    clearValidation = () => {
        this.setState({
            errors: {},
            formclass: ""
        })
    }

    reValidate = () => {
        if (!this.validate()) {
            this.setState({formclass: "was-validated"})
            return;
        }
    }
    ShowDocumentAddEditModal = (entry) => {
        this.setState({
            selectEntry: entry?.id !== undefined ? entry : null,
            documentTitle: entry?.title !== undefined ? entry.title : "",
            documentType: entry?.type !== undefined ? entry.type : DOCUMENTS_TYPES.TYPE[3].value,
            typeDescription: entry?.typeDescription !== undefined ? entry.typeDescription : "",
            documentFileName: entry?.filename !== undefined ? entry.filename : "",
            documentDate: entry && entry.date_time ? entry.date_time : moment().valueOf(),
            documentTime: entry && entry.date_time ? entry.date_time : moment().valueOf(),
            documentAddEditModal: true
        })
    }
    hideDocumentAddEditModal = () => {
        this.setState({
            errors: {},
            formclass: ""
        })

        if (this.props.noList) {
            this.props.closeAddModal()
        }

        this.setState({
            selectEntry: null,
            documentAddEditModal: false,
        })
    }

    confirmDelete = () => {
        documentsService.deleteProviderEncounterDocument(this.props.encounter.id, this.state.selectEntry.id).then(() => {
            this.props.onUpdate();
            this.setState({
                selectEntry: null,
                deletedConfirmationModal: false,
                documentAddEditModal: false,
                documentTitle: "",
                documentFileName: "",
                documentType: DOCUMENTS_TYPES.TYPE[3].value,
                documentDate: moment().valueOf(),
                documentTime: moment().valueOf(),
                fileChanged: false
            })
        })
    }

    validate = () => {
        let errors = {}
        if (!this.state.documentFileName) {
            errors.file = "file_required_message"
        }
        if (!this.state.documentDate) {
            errors.documentDate = "date_required_message"
        }
        if (!this.state.documentTime) {
            errors.documentTime = "time_required_message"
        }
        if (!this.state.typeDescription && this.state.documentType === "OTHER") {
            errors.typeDescription = 'type_description_required_message';
        }
        if (!this.state.documentTitle) {
            errors.title = "document_title_required_message"
        }
        let hasErrors = Object.getOwnPropertyNames(errors).length > 0;
        this.setState({errors: errors})
        return !hasErrors;
    }

    onSaveAddUpdateModal = async () => {
        if (!this.validate()) {
            this.setState({formclass: "was-validated"})
            return;
        }
        let encounterId = this.props.encounter?.id
        let encounter

        if (!this.props.encounter) {
            encounter = await this.props.createEmptyEncounter(this.props.patient.user_id, this.props.providerId);
            encounterId = encounter.id
        }

        let provider

        if (!this.props.encounter) {
            provider = appointmentUtils.getProvider(encounter)
        } else {
            provider = appointmentUtils.getProvider(this.props.encounter)
        }


        let d = new Date(this.state.documentDate);
        let t = new Date(this.state.documentTime);
        d.setHours(t.getHours());
        d.setMinutes(t.getMinutes());

        let datetime = moment(d).valueOf();

        let form = {}

        if (this.state.selectEntry) {
            form = {...this.state.selectEntry}
        }

        form = {
            id: this.state.selectEntry?.id,
            type: this.state.documentType,
            typeDescription: this.state.typeDescription,
            user_id: this.props.selectedUser.id,
            provider_names: provider.title + " " + provider.fullname,
            created_by_provider: true,
            has_metadata: false,
            deleted: false,
            shared: this.state.shared,
            date_time: datetime,
            notes: this.state.notes,
            created_timestamp: moment().valueOf(),
            updated_timestamp: moment().valueOf(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            category: this.state.documentType,
            title: this.state.documentTitle,
            size: this.state.size,
            health_issues_ids: this.props.encounter?.healthIssueIds || [],
            mime_type: this.state.mime_type,
            filename: this.state.documentFileName,
            attachment_updated_timestamp: moment().valueOf(),
        }

        const formData = new FormData();

        formData.append('document', new Blob([JSON.stringify(form)], {type: "application/json"}));
        if (this.state.selectEntry && this.state.fileChanged || this.state.selectEntry === null) {
            if (this.state.file !== null) {
                formData.append("file", this.state.file);
            }
        }


        if (this.state.selectEntry?.id) {
            documentsService.updateProviderEncounterDocument(formData, encounterId).then(() => {
                this.props.onUpdate(encounterId);
                this.setState({
                    selectEntry: null,
                    deletedConfirmationModal: false,
                    documentAddEditModal: false,
                    documentTitle: "",
                    documentFileName: "",
                    documentType: DOCUMENTS_TYPES.TYPE[3].value,
                    documentDate: moment().valueOf(),
                    documentTime: moment().valueOf(),
                    fileChanged: false,
                    formclass: "",
                    errors: {}
                })
            })
        } else {
            documentsService.createProviderEncounterDocument(formData, encounterId).then(() => {
                this.props.onUpdate(encounterId);

                this.setState({
                    selectEntry: null,
                    deletedConfirmationModal: false,
                    documentAddEditModal: false,
                    documentTitle: "",
                    documentFileName: "",
                    documentType: DOCUMENTS_TYPES.TYPE[3].value,
                    documentDate: moment().valueOf(),
                    documentTime: moment().valueOf(),
                    fileChanged: false,
                    formclass: "",
                    errors: {}
                })
            })
        }
        if (this.props.isAdmin) {
            this.props.closeAddModal()
        }

    }

    /**
     * Set the state to the latest selected date.
     *
     * @param {object} evt - The event handler argument
     */
    onDateChange = (date) => {
        this.setState({documentDate: moment(date).valueOf()})
    };

    onTimeChange = (time) => {
        this.setState({documentTime: moment(time).valueOf()})
    }

    /**
     * Set the state to the latest change in the select.
     *
     * @param {object} evt - The event handler argument
     */
    onSelectChange = (e) => {
        this.setState({
            documentType: e.value,
            errors: {},
            formclass: "",
        });
    }

    fetchProviderEncounterDocuments = () => {
        documentsService.fetchProviderEncounterDocuments(this.props.encounter.id)
            .then(r => {
                this.setState({documents: r, provider_document_ids: this.props.encounter.provider_document_ids});
            })
    }


    componentDidMount() {
        if (this.props.encounter?.provider_document_ids && this.props.encounter.provider_document_ids.length > 0) {
            this.fetchProviderEncounterDocuments()
        }

        this.setState({documentType: DOCUMENTS_TYPES.TYPE[3].value})
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.showAdd !== this.props.showAdd && this.props.showAdd) {
            this.ShowDocumentAddEditModal();
        }

        if (this.props.encounter && this.props.encounter?.provider_document_ids !== this.state.provider_document_ids) {
            this.fetchProviderEncounterDocuments();
        }
    }

    render() {
        let errorMsg = '';

        return <div>
            <AddDocumentButton ShowDocumentAddEditModal={this.ShowDocumentAddEditModal}
                               show={!this.props.noList}
                               isAdmin={this.props.isAdmin}/>

            <DocumentsList documents={this.state.documents}
                           encounterDocuments={true}
                           loggedInUserId={this.props.userInfo.id}
                           show={!this.props.noList}
                           showEncounterDocumentsDeleteConfirmationModal={this.ShowDeletedConfirmationModal}
                           showEncounterDocumentsAddUpdateModal={this.ShowDocumentAddEditModal}
                           encounter={this.props.encounter}
                           appointment={this.props.selectedAppointmentInProgress}
                           selectedUser={this.props.selectedUser}
                           i18n={this.props.i18n}/>

            <DocumentDeleteModal show={this.state.deletedConfirmationModal}
                                 onHide={this.hideDeletedConfirmationModal}
                                 onConfirm={this.confirmDelete}
                                 selectEntry={this.state.selectEntry}
            />

            <DocumentAddEditModal title={this.state.selectEntry?.title ? $$('update_document') : $$('document_label')}
                                  show={this.state.documentAddEditModal}
                                  onHide={this.hideDocumentAddEditModal}
                                  formDisabled={this.props.formDisabled}
                                  onSubmit={this.onSubmit}
                                  selectEntry={this.state.selectEntry}
                                  currentForm={DOCUMENT}
                                  className={this.state.formclass}
                                  reValidate={this.reValidate}
                                  errorMsg={errorMsg}
                                  documentTitle={this.state.documentTitle}
                                  onInputChange={this.onInputChange}
                                  onFileChange={this.onFileChange}
                                  documentType={this.state.documentType}
                                  documentFileName={this.state.documentFileName}
                                  formclass={this.state.formclass}
                                  clearValidation={this.clearValidation}
                                  onSelectChange={this.onSelectChange}
                                  errors={this.state.errors}
                                  onTimeChange={this.onTimeChange}
                                  ampm={!this.props.settings.data.time24hour}
                                  documentTime={this.state.documentTime}
                                  validate={this.validate}
                                  typeDescription={this.state.typeDescription}
                                  onDateChange={this.onDateChange}
                                  locale={this.getLocale()}
                                  format={this.props.settings.data.dateFormat}
                                  documentDate={this.state.documentDate}
                                  onSaveAddUpdateModal={this.onSaveAddUpdateModal}/>
        </div>
    }
}

ProviderEncounterDocuments.propTypes = {
    documents: PropTypes.any,
    formDisabled: PropTypes.any,
    patient: PropTypes.any,
    providerId: PropTypes.any,
    encounter: PropTypes.any,
    noList: PropTypes.bool,
    showAdd: PropTypes.bool,
    isAdmin: PropTypes.bool,
    userInfo: PropTypes.any,
    settings: PropTypes.any,
    closeAddModal: PropTypes.func,
    onUpdate: PropTypes.func,
    createEmptyEncounter: PropTypes.func,
    i18n: PropTypes.any,
    selectedUser: PropTypes.any,
    selectedAppointmentInProgress: PropTypes.any,
}

function mapStateToProps(state) {
    return {
        i18n: state.language.selected,
        settings: state.settings,
        formDisabled: state.formInteractions,
        userInfo: state.userInfo.data
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderEncounterDocuments);
