import React, {useEffect, useState} from "react"
import BackButton from "../shared/BackButton";
import {$$} from "../../helpers/localization";
import {FG, Input, InputTextArea} from "../examination/nhis/bg/HelperFormComponents";
import {BoolSwitch} from "../examination/nhis/bg/BoolSwitch";
import CustomTagsInput from "../shared/CustomTagsInput";
import {nhisGetNomenclatures} from "../../service/nhis_service";
import {languageOptions} from "../../constants/languages";
import {fetchSpecialties} from "../../service/users_service";
import {SearchableSelectAsync} from "../examination/nhis/bg/SearchableSelect";

export default function AddNewStaff({onBack, onCreate, permissions, org_id, org_name, lang}) {
    return <div className="patient-card">
            <div className="patient-card-header">

                <h2 className="card-title card-header-title"><BackButton onClick={onBack} title={$$("back")}/>
                    &nbsp;&nbsp;{$$("staff_management.invite_new_staff_member_title")}</h2>

            </div>
        <div className="card-body">
            <Content onCreate={onCreate} permissions={permissions} onCancel={onBack} org_id={org_id} lang={lang} org_name={org_name}/>
        </div>
    </div>
}

function Content({onCreate, permissions, onCancel, org_id, org_name, lang}) {
    const [role, setRole] = useState("REGISTRATION_DESK");
    const [internal, setInternal] = useState(false);
    const [visible, setVisible] = useState(false);
    const [step, setStep] = useState(1);

    if (step === 1) {
        return <div style={{maxWidth:"600px"}}><RolePickerBody
            rle={role}
            intern={internal}
            visible={visible}
            onBack={() => {
                onCancel();
            }}
            onSelect={(role, internal, visible) => {
                setRole(role); setInternal(internal); setVisible(visible);
                setStep(2)}}
            permissions={permissions}/>
        </div>
    }

    return <InviteForm onCreate={(o) => {
            const ob = {...o};
            ob.visible = visible;
            ob.nonMedicalPersonnel = internal;
            ob.org_role = role;
            ob.org_id = org_id;
            ob.org_name = org_name;
            return onCreate(ob);
        }}
    />


    if (internal) {
        return <div style={{maxWidth:"600px"}}>
            <MainFormNonMedical onCreate={(o) => {
                const ob = {...o};
                ob.visible = visible;
                ob.nonMedicalPersonnel = internal;
                ob.org_role = role;
                ob.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
                    ob.org_id = org_id;
                ob.password = "";
                return onCreate(ob);
            }} onCancel={() => setStep(1)}/>
        </div>
    }

    return <div style={{maxWidth:"600px"}}>
        <MainFormClinician onCreate={(o) => {
            const ob = {...o};
            ob.visible = visible;
            ob.nonMedicalPersonnel = internal;
            ob.org_role = role;
            ob.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
            ob.org_id = org_id;
            ob.password = "";
            return onCreate(ob);
    }} onCancel={()=>setStep(1)} lang={lang}/>
    </div>
}

function RolePickerBody({onSelect, intern, rle, visible, onBack}) {
    const [internal, setInternal] = useState(intern);
    const [isAdmin, setIsAdmin] = useState(rle === "ADMIN");
    const [isVisible, setIsVisible] = useState(visible);
    const [role, setRole] = useState(rle);

    return <div>
        <div className="low-shadow-container mt-3">
            <label>{$$("staff_management.is_non_medical_staff")}</label>
            <BoolSwitch checked={internal || false} onChange={() => {
                let b = !internal;
                setInternal(b);
                if (b) {
                    setIsVisible(false);
                }
            }} labelOff={$$("nhis.no_label")} labelOn={$$("nhis.yes_label")} offFirst={false}/>
        </div>
        <div className="low-shadow-container">
            <label>{$$("staff_management.give_administrative_rights")}</label>
            <BoolSwitch checked={isAdmin || false} onChange={() => {
                let b = !isAdmin;
                setIsAdmin(b);
            }} labelOff={$$("nhis.no_label")} labelOn={$$("nhis.yes_label")} offFirst={false}/>
        </div>

        {internal && !isAdmin && <div className="low-shadow-container mt-3">
            <label>{$$("staff_management.has_a_role")}</label>
            <BoolSwitch checked={role === "REGISTRATION_DESK" || false} onChange={() => {
                let b = role === "LAB_ASSISTANT" ? "REGISTRATION_DESK" :  "LAB_ASSISTANT";
                setRole(b);
            }} labelOff={$$("ORG_ROLE_LAB_ASSISTANT")} labelOn={$$("ORG_ROLE_REGISTRATION_DESK")} offFirst={false}/>
        </div>}
        {!internal && <div className="low-shadow-container mt-3">
            <label>{$$("staff_management.is_visible_to_patients")}</label>
            <BoolSwitch checked={isVisible || false} onChange={() => {
                let b = !isVisible;
                setIsVisible(b);
            }} labelOff={$$("nhis.no_label")} labelOn={$$("nhis.yes_label")} offFirst={false}/>
        </div>}

        <div className="mt-3 text-right">
            <button className="btn btn-primary" onClick={()=>{
                if (isAdmin) {
                    onSelect("ADMIN", internal, isVisible && !internal)
                } else {
                    onSelect(internal ? role : "CLINICIAN", internal, isVisible && !internal)
                }
            }}>{$$("forward_btn")}</button>
            <button className="btn btn-danger ml-2" onClick={onBack}>{$$("cancel_btn")}</button>
        </div>
    </div>
}


function MainFormNonMedical({onCreate, onCancel}) {
    const [formClass, setFormClass] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [existingEmailError, setExistingEmailError] = useState(false)

    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!e.target.checkValidity()) {
            setFormClass("was-validated");
            return;
        }

        const data = {
            fullname:`${firstName} ${lastName}`,
            email: email.trim(),
        };
        const form = e.target;
        onCreate(data)
            .catch(e => {
                if (e.status === 403 && e.message.includes("Username already exists")) {
                    form.elements["email-input"].setCustomValidity("email-exists")
                    setFormClass("was-validated");
                    setExistingEmailError(true);
                }
            });
    };

    return <form noValidate={true} className={formClass} onSubmit={onSubmit} autoComplete="off">
        <fieldset>
            <FG>
                <Input label={"nhis.patient.first_name_label"} value={firstName} required={true} setValue={setFirstName} autoComplete="new-password"/>
            </FG>
            <FG>
                <Input label={"nhis.patient.last_name_label"} value={lastName} required={true} setValue={setLastName} autoComplete="new-password"/>
            </FG>
            <FG>
                <Input label="email_label" name="email-input" value={email} required={true} setValue={(v, e) => {
                    setEmail(v);
                    e.target.setCustomValidity("");
                    if (existingEmailError) {
                        setExistingEmailError(false);
                    }
                }} type="email" autoComplete="new-password"/>
                <div className={existingEmailError ?'custom-invalid-feedback' : 'd-none'}>
                    {$$('register_form_email_exists_message')}
                </div>
            </FG>

            <FG className={"mt-3 text-right"}>
                <button type="submit" className="btn btn-primary" >{$$("save_btn_label")}</button>
                <button className="btn btn-danger ml-2" onClick={onCancel}>{$$("cancel_btn")}</button>
            </FG>
        </fieldset>
    </form>
}

function MainFormClinician({onCreate, onCancel, lang}) {
    const [formClass, setFormClass] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [title, setTitle] = useState("");
    const [email, setEmail] = useState("");
    const [contact_email, setContactEmail] = useState("");
    const [phone_numbers, setPhone_numbers] = useState([]);
    const [specialties, setSpecialties] = useState([]);
    const [nhisSpecialtyOptions, setNhisSpecialtyOptions] = useState([]);
    const [nhis_specialties, setNhisSpecialties] = useState([]);
    const [uin, setUin] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [other_info, setOtherInfo] = useState("");
    const [specialtyOptions, setSpecialtyOptions] = useState([])
    const [specialtyRawOptions, setSpecialtyRawOptions] = useState([])
    const [langOptions, setLangOptions] = useState([])
    const [existingEmailError, setExistingEmailError] = useState(false)

    useEffect(()=>{
        fetchSpecialties().then((res)=>{
            setSpecialtyRawOptions(res);
        });
        nhisGetNomenclatures(["CL006"]).then((res) => {
            const items = res[0].items;
            let propNames = Object.getOwnPropertyNames(items).sort();
            let arr = propNames.map(prop => ({
                value: prop,
                label: prop + " " + items[prop].description,
                text: items[prop].description,
                item: items[prop]
            })).filter(o => o.item.meta.role?.includes('Лекари') || o.value === "0000");
            setNhisSpecialtyOptions(arr)
        });
    }, [])

    useEffect(() => {
        setLangOptions(languageOptions(lang).map(o => ({...o, label:o.text})));
    }, [lang])

    useEffect(() => {
        setSpecialtyOptions(getSpecialtiesOptions(specialtyRawOptions));
    }, [lang, specialtyRawOptions])

    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!e.target.checkValidity()) {
            setFormClass("was-validated");
            return;
        }

        const data = {
            fullname:`${firstName} ${lastName}`,
            email:email.trim(),
            title,
            contact_email,
            phone_numbers: phone_numbers,
            specialties,
            nhis_specialties,
            uin,
            languages,
            other_info
        };
        const form = e.target;
        onCreate(data)
            .catch(e => {
                if (e.status === 403 && e.message.includes("Username already exists")) {
                    form.elements["email-input"].setCustomValidity("email-exists")
                    setFormClass("was-validated");
                    setExistingEmailError(true);
                }
            });
    };

    return <div className="nhis-ereferral">
        <form noValidate={true} className={`has-custom-validation ${formClass}`} onSubmit={onSubmit} autoComplete="off">
        <div style={{'minHeight': '43.75rem'}} className="add-staff-form">
            <FG>
                <Input setValue={setFirstName} value={firstName} required={true} label={"nhis.patient.first_name_label"} type="text" autoComplete="new-password" />
            </FG>
            <FG>
                <Input setValue={setLastName} value={lastName} required={true} label={"nhis.patient.last_name_label"} type="text" autoComplete="new-password" />
            </FG>
            <FG>
                <Input setValue={setTitle} value={title} required={false} label={"wizard_personal_information_title_label"} type="text" autoComplete="new-password" />
            </FG>
            <FG>
                <Input label="email_label" name="email-input" value={email} required={true} setValue={(v, e) => {
                    setEmail(v);
                    e.target.setCustomValidity("");
                    if (existingEmailError) {
                        setExistingEmailError(false);
                    }
                }} type="email" autoComplete="new-password"/>
                <div className={existingEmailError ?'custom-invalid-feedback' : 'd-none'}>
                    {$$('register_form_email_exists_message')}
                </div>
            </FG>
            <FG>
                <input type='text' className='form-control'
                       value={phone_numbers} name='phone_numbers'
                       onChange={event => {}}
                       required={true} hidden/>
                <CustomTagsInput
                    tags={phone_numbers}
                    handleAddition={(selectedItem)=>{
                        const v = [...phone_numbers];
                        v.push(selectedItem.text);
                        setPhone_numbers(v);
                    }}
                    handleDelete={(index)=>{
                        const fields = phone_numbers.filter((s,i) => i !== index);
                        setPhone_numbers(fields);
                    }}
                    placeholder={$$('wizard_phone_numbers_label')}
                    autofocus={false}
                    isFieldValid={phone_numbers.length > 0}
                    formClass={formClass}/>
                <div className='invalid-feedback'>
                    {$$('wizard_phone_numbers_required_label')}
                </div>
            </FG>
            <FG>
                <Input label={"contact_email"} value={contact_email} required={false} setValue={setContactEmail} type="email" autoComplete="new-password"/>
            </FG>
            <FG>
                <SearchableSelectAsync
                    loadOptions={(query, callback)=>callback(specialtyOptions.filter(opt => opt.label.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1))}
                    isMulti={true}
                    options={specialtyOptions}
                    value={specialties.map(v => specialtyOptions.find(opt => opt.value === v))}
                    placeholder={$$("wizard_personal_information_specialties_label")}
                    onSelect={(o) => {
                        setSpecialties(o.map(o => o.value));
                    }}
                    required={true}
                    optionHeight={40}
                    defaultMenuIsOpen={false}
                />
            </FG>
            <FG>
                <SearchableSelectAsync
                    loadOptions={(query, callback)=>callback(nhisSpecialtyOptions.filter(opt => opt.label.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1))}
                    isMulti={true}
                    options={nhisSpecialtyOptions}
                    value={nhis_specialties.map(v => nhisSpecialtyOptions.find(opt => opt.value === v))}
                    placeholder={$$("nhis.nhis_specialties_search")}
                    onSelect={(o) => {
                        setNhisSpecialties(o.map(o => o.value));
                    }}
                    optionHeight={40}
                    required={false}
                />
            </FG>
            <FG>
                <Input setValue={setUin} value={uin} required={true} label={"wizard_personal_information_uin_label_2"} type="text" autoComplete="new-password" />
            </FG>
            <FG>
                <SearchableSelectAsync
                    loadOptions={(query, callback)=>callback(langOptions.filter(opt => opt.label.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1))}
                    isMulti={true}
                    options={langOptions}
                    value={languages.map(v => langOptions.find(opt => opt.value === v))}
                    placeholder={$$("wizard_biography_languages_label")}
                    onSelect={(o) => {
                        setLanguages(o.map(o => o.value));
                    }}
                    optionHeight={40}
                    required={true}
                />
            </FG>
            <FG>
                <InputTextArea label="wizard_biography_label_2"required={true} value={other_info} setValue={setOtherInfo} />
            </FG>

            <FG className={"mt-3  text-right"}>
                <button type="submit" className="btn btn-primary" >{$$("save_btn_label")}</button>
                <button className="btn btn-danger ml-2" onClick={onCancel}>{$$("cancel_btn")}</button>
            </FG>
        </div>

    </form>
    </div>
}


function getSpecialtiesOptions(options) {
    return options.map(o => {
        return {value: o, text: $$(o.toLowerCase()), label:$$(o.toLowerCase())}
    });
}

function InviteForm({onCreate, onCancel}) {
    const [formClass, setFormClass] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [existingEmailError, setExistingEmailError] = useState(false)

    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!e.target.checkValidity()) {
            setFormClass("was-validated");
            return;
        }

        const data = {
            email: email.trim(),
            fullname: name.trim()
        };
        const form = e.target;
        onCreate(data)
            .catch(e => {
                if (e.status === 403 && e.message.includes("Username already exists")) {
                    form.elements["email-input"].setCustomValidity("email-exists")
                    setFormClass("was-validated");
                    setExistingEmailError(true);
                }
            });
    };

    return <form noValidate={true} className={formClass} onSubmit={onSubmit} autoComplete="off">
        <fieldset>
            <FG>
                <Input label={"fullname_label"} value={name} required={true} setValue={setName} autoComplete="new-password"/>
            </FG>
            <FG>
                <Input label="email_label" name="email-input" value={email} required={true} setValue={(v, e) => {
                    setEmail(v);
                    e.target.setCustomValidity("");
                    if (existingEmailError) {
                        setExistingEmailError(false);
                    }
                }} type="email" autoComplete="new-password"/>
                <div className={existingEmailError ?'custom-invalid-feedback' : 'd-none'}>
                    {$$('register_form_email_exists_message')}
                </div>
            </FG>

            <FG className={"mt-3 text-right"}>
                <button type="submit" className="btn btn-primary" >{$$("save_btn_label")}</button>
                <button className="btn btn-danger ml-2" onClick={onCancel}>{$$("cancel_btn")}</button>
            </FG>
        </fieldset>
    </form>
}