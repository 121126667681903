import React, {Component} from 'react'
import $ from 'jquery';
import moment from "moment";
import 'fullcalendar';
import 'fullcalendar/dist/fullcalendar.css';
import {$$} from '../../helpers/localization';
import classNames from "classnames";
import {isSameDay} from 'date-fns';
import PropTypes from "prop-types";
import {getTimeFormat} from "../../utils/converter";
import {addToolTip, removeToolTip} from "./CalendarToolTipUtils";

export class Calendar extends Component {
    state = {
        events: [],
        intervalStart: null,
        viewType: this.props.userSelections.workTime.calendarView,

    }

    constructor(props) {
        super(props);
        this.calendarRef = React.createRef();
        this.timeFormat = getTimeFormat();
    }


    componentDidMount = () => {
        let self = this;
        let $calendar = $(this.calendarRef.current);
        let calendar = $calendar.fullCalendar({
            themeSystem: 'bootstrap4',
            locale: this.props.i18n.selected.lang,
            events: this.state.events,
            eventMouseover: addToolTip,
            eventMouseout: removeToolTip,
            editable: false,
            draggable: false,
            selectable: false,
            selectHelper: false,
            unselectAuto: false,
            disableResizing: false,
            droppable: false,
            slotLabelFormat: this.timeFormat,
            timeFormat: 'HH:mm',
            defaultTimedEventDuration: '00:30:00',
            header: {
                left: 'prev,next today',
                center: 'title',
                right: 'month,agendaWeek,agendaDay,listMonth'
            },
            buttonText: {
                today: $$('today'),
                month: $$('month'),
                week: $$('week'),
                day: $$('day'),
                list: $$('list')
            },
            allDayText: $$('all_day'),
            eventClick: this.props.eventClick,
            dayClick: this.props.dayClick,

            select: function (start, end, allDay) {
                let title = prompt('Event Title:');
                if (title) {
                    calendar.fullCalendar('renderEvent', {
                            title: title,
                            start: start,
                            end: end,
                            allDay: allDay
                        }, true // make the event "stick"
                    );
                }
                calendar.fullCalendar('unselect');
            },
            id: 1,
            // eslint-disable-next-line no-unused-vars
            eventRender: function (event, element, view) {
                if (event.timeTable.not_available) {
                    element.addClass("fc-event-red-2")
                } else {
                    element.addClass("fc-event-green-1")
                }
            },
            // eslint-disable-next-line no-unused-vars
            viewRender: function (view, element) {
                self.setState({
                    currentMonth: new Date(view.intervalStart._i).getMonth(),
                    intervalStart: view.intervalStart._i,
                });
            }
        })
        if (self.state.viewType == "Week") {
            $("button.fc-agendaWeek-button.btn.btn-primary").click();
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState) {
        $(".div-container-week").remove();
        if ($(".fc-day-header").length) {
            if (this.state.viewType === "Week") {
                $(this.getWeekDates()).insertAfter("div.fc-toolbar.fc-header-toolbar");
            }
        }

        if (this.props.timeTables.data) {
            this.renderEvents();
        }


        if (this.props.i18n.selected.lang !== prevProps.i18n.selected.lang) {
            $(this.calendarRef.current).fullCalendar("option", "allDayText", $$('all_day'));
            $(this.calendarRef.current).fullCalendar("option", "locale", this.props.i18n.selected.lang);
        }
    }

    createMoment(date, time) {
        let d = moment(date);
        d.set("hour", time.substring(0, 2));
        d.set("minute", time.substring(3, 5));
        d.set("second", 0);
        return d;
    }

    getOrganisationName = (id) => {
        let organisationName = "";
        this.props.organizations.map((org) => {
            if (org.id == id) {
                organisationName = org.name;
            }
        })
        return organisationName;
    }

    getColour = (option, timetable) => {

        if (timetable.not_available && timetable.organization_id === null) {
            return "#FF1919"
        }
        if (timetable.not_available) {
            return "#FF6475"
        }

        let index
        for (let i in this.props.organizations) {
            if (this.props.organizations[i].name === option) {
                index = i;
            }
        }
        let colourArray = ["#f2c84c", "#16dbcb", "#ff6476", "#bb6bd9"];
        let colourIndex = index % 4;
        return colourArray[colourIndex];
    }


    renderEvents() {
        let cal = $(this.calendarRef.current);
        let events = [];
        for (let i = 0; i < this.props.timeTables.data.length; i++) {
            let timeTable = this.props.timeTables.data[i];

            let title = undefined;
            let id = timeTable.id;
            let color = this.getColour(this.getOrganisationName(timeTable.organization_id), timeTable)
            let show = false;
            let start = timeTable.start !== null ? this.createMoment(timeTable.start, timeTable.start_time) : moment(new Date(timeTable.server_created_timestamp));
            let end = timeTable.end !== null ? this.createMoment(timeTable.end, timeTable.end_time) : "";

            if (timeTable.organization_id === null) {
                show = true;
            } else {
                for (let j in this.props.selectedCalendars) {
                    if (this.props.selectedCalendars[j].id === timeTable.organization_id && this.props.selectedCalendars[j].value) {
                        show = true;
                        break;
                    }
                }
            }


            if (show) {
                if (timeTable.days_of_the_week.length > 0) {
                    let view = cal.fullCalendar('getView');
                    let viewStart = moment(view.start.toDate());
                    let viewEnd = moment(view.end.toDate()).startOf('day');
                    let current = moment(viewStart).startOf('day');
                    while (current.isBefore(viewEnd)) {

                        //we're in range
                        if (start.isSameOrBefore(current, 'day') && (end === "" || end.isAfter(current))) {

                            //selected day matches too
                            if (timeTable.days_of_the_week.includes(current.isoWeekday())) {
                                let currentStart = this.createMoment(current, timeTable.start_time);
                                let currentEnd = this.createMoment(current, timeTable.end_time);
                                events.push({
                                    id: id + current.toISOString(),
                                    title: title,
                                    start: currentStart,
                                    end: currentEnd,
                                    timeTable: timeTable,
                                    prices: this.props.prices,
                                    color: color
                                });
                            }
                        }

                        current = moment(current).add(1, 'days'); //next day
                    }
                } else if (!(isSameDay(moment(timeTable.start).toDate(), moment(timeTable.end).toDate()))) {

                    let view = cal.fullCalendar('getView');
                    let viewStart = moment(view.start.toDate());
                    let viewEnd = moment(view.end.toDate()).startOf('day');
                    let current = moment(viewStart).startOf('day');
                    while (current.isBefore(viewEnd)) {

                        //we're in range
                        if (start.isSameOrBefore(current, 'day') && (end === "" || end.isAfter(current))) {

                            //selected day matches too
                            let currentStart = this.createMoment(current, timeTable.start_time);
                            let currentEnd = this.createMoment(current, timeTable.end_time);
                            events.push({
                                id: id + current.toISOString(),
                                title: title,
                                start: currentStart,
                                end: currentEnd,
                                timeTable: timeTable,
                                prices: this.props.prices,
                                color: color
                            });
                        }

                        current = moment(current).add(1, 'days'); //next day
                    }
                } else {
                    events.push({
                        id: id,
                        title: title,
                        start: start,
                        end: end,
                        timeTable: timeTable,
                        prices: this.props.prices,
                        color: color
                    });
                }
            }
        }

        cal.fullCalendar('removeEvents');
        cal.fullCalendar('renderEvents', events, true);
    }

    getWeekDays = () => {
        if ($(".fc-day-header").length) {
            const today = new Date().getDay();
            const activeSunday = classNames('', {
                'current-day-of-week': (today === 0)
            });
            const activeMonday = classNames('', {
                'current-day-of-week': (today === 1)
            });
            const activeTuesday = classNames('', {
                'current-day-of-week': (today === 2)
            });
            const activeWednesday = classNames('', {
                'current-day-of-week': (today === 3)
            });
            const activeThursday = classNames('', {
                'current-day-of-week': (today === 4)
            });
            const activeFriday = classNames('', {
                'current-day-of-week': (today === 5)
            });
            const activeSaturday = classNames('', {
                'current-day-of-week': (today === 6)
            });
            let weekDays = {
                "mon": {
                    "className": activeMonday,
                    "label": "short_monday_label"
                },
                "tue": {
                    "className": activeTuesday,
                    "label": "short_tuesday_label"
                },
                "wed": {
                    "className": activeWednesday,
                    "label": "short_wednesday_label"
                },
                "thu": {
                    "className": activeThursday,
                    "label": "short_thursday_label"
                },
                "fri": {
                    "className": activeFriday,
                    "label": "short_friday_label"
                },
                "sat": {
                    "className": activeSaturday,
                    "label": "short_saturday_label"
                },
                "sun": {
                    "className": activeSunday,
                    "label": "short_sunday_label"
                }
            };
            var orderedWeekDays = [];
            $(".fc-day-header").each(function (i, obj) {
                orderedWeekDays.push($(obj).attr("class").replace(/  +/g, ' ').split(" ")[1].split("-")[1]);
            });
            return (
                <div className="grid-container">
                    <div className="grid-item"><span
                        className={weekDays[orderedWeekDays[0]].className}>{$$(weekDays[orderedWeekDays[0]].label)}</span>
                    </div>
                    <div className="grid-item"><span
                        className={weekDays[orderedWeekDays[1]].className}>{$$(weekDays[orderedWeekDays[1]].label)}</span>
                    </div>
                    <div className="grid-item"><span
                        className={weekDays[orderedWeekDays[2]].className}>{$$(weekDays[orderedWeekDays[2]].label)}</span>
                    </div>
                    <div className="grid-item"><span
                        className={weekDays[orderedWeekDays[3]].className}>{$$(weekDays[orderedWeekDays[3]].label)}</span>
                    </div>
                    <div className="grid-item"><span
                        className={weekDays[orderedWeekDays[4]].className}>{$$(weekDays[orderedWeekDays[4]].label)}</span>
                    </div>
                    <div className="grid-item"><span
                        className={weekDays[orderedWeekDays[5]].className}>{$$(weekDays[orderedWeekDays[5]].label)}</span>
                    </div>
                    <div className="grid-item"><span
                        className={weekDays[orderedWeekDays[6]].className}>{$$(weekDays[orderedWeekDays[6]].label)}</span>
                    </div>
                </div>
            );
        }
    }

    getWeekDates = () => {
        return `
            <div class="div-calendar-week-days">
                <div class="d-flex div-container-week">
                    <div class="grid-container-week">
                        <div class="grid-item-week">
                            <span>
                                ` + moment(new Date(this.state.intervalStart)).format('DD/MM') + `
                            </span>
                        </div>
                        <div class="grid-item-week">
                            <span>
                                ` + moment(new Date(this.state.intervalStart + 86400000)).format('DD/MM') + `
                            </span>
                        </div>
                        <div class="grid-item-week">
                            <span>
                                ` + moment(new Date(this.state.intervalStart + (2 * 86400000))).format('DD/MM') + `
                            </span>
                        </div>
                        <div class="grid-item-week">
                            <span>
                                ` + moment(new Date(this.state.intervalStart + (3 * 86400000))).format('DD/MM') + `
                            </span>
                        </div>
                        <div class="grid-item-week">
                            <span>
                                ` + moment(new Date(this.state.intervalStart + (4 * 86400000))).format('DD/MM') + `
                            </span>
                        </div>
                        <div class="grid-item-week">
                            <span>
                                ` + moment(new Date(this.state.intervalStart + (5 * 86400000))).format('DD/MM') + `
                            </span>
                        </div>
                        <div class="grid-item-week">
                            <span>
                                ` + moment(new Date(this.state.intervalStart + (6 * 86400000))).format('DD/MM') + `
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }


    render() {
        const month = classNames('fc-month-button btn btn-primary', {
            'active-calendar-view': (this.state.viewType === 'Month')
        });
        const week = classNames('fc-agendaWeek-button btn btn-primary', {
            'active-calendar-view': (this.state.viewType === 'Week')
        });

        return (
            <div className="history d-flex flex-container flex-row">
                <div className="appointments-page-left-col">
                    <div className="d-flex change-month-week-day">
                        <div className="btn-group">
                            <button type="button" className={month} onClick={() => {
                                $("button.fc-month-button.btn.btn-primary").click();
                                if (this.state.viewType !== 'Month') {
                                    this.setState({
                                        viewType: 'Month'
                                    });
                                    this.props.userSelections.appointments.calendarView = "Month";
                                    this.props.setUserSelections(this.props.userSelections, null);
                                }
                            }}>{$$('month')}</button>
                            <button type="button" className={week} onClick={() => {
                                $("button.fc-agendaWeek-button.btn.btn-primary").click();
                                if (this.state.viewType !== 'Week') {
                                    this.setState({
                                        viewType: 'Week'
                                    });
                                    this.props.userSelections.appointments.calendarView = "Week";
                                    this.props.setUserSelections(this.props.userSelections, null);
                                }
                            }}>{$$('week')}</button>
                        </div>
                        <div className="btn-group choose-day-button">
                            <button type="button" className="fc-prev-button-custom btn btn-primary" aria-label="prev"
                                    onClick={() => {
                                        $("button.fc-prev-button.btn.btn-primary").click()
                                    }}>
                                <span className="fa fa-chevron-left"></span>
                            </button>
                            <button type="button" className="fc-today-button-custom btn btn-primary" onClick={() => {
                                $("button.fc-today-button.btn.btn-primary").click();
                                var self = this;
                                setTimeout(function () {
                                    self.setState({
                                        selectedDate: new Date()
                                    });
                                    $(".fc-day").removeAttr("style");
                                    $(".fc-today").css('background-color', 'transparent');
                                    if (self.state.viewType == "Month") {
                                        $(".fc-today").css({
                                            'border-left': '5px solid #16DBCC',
                                            'box-shadow': '0px 1px 20px rgba(20, 46, 110, 0.17)',
                                            'border-radius': '8px',
                                            'cursor': 'pointer',
                                            'background-color': 'white'
                                        });
                                    }
                                    $("td.fc-day-top.fc-today.alert-info").removeAttr("style");
                                }, 500);
                            }}>{$$('today')}</button>
                            <button type="button" className="fc-next-button-custom btn btn-primary" aria-label="next"
                                    onClick={() => {
                                        $("button.fc-next-button.btn.btn-primary").click()
                                    }}>
                                <span className="fa fa-chevron-right"></span>
                            </button>
                        </div>
                    </div>
                    {this.getWeekDays()}
                    <div className="calendar-container">
                        <div id="calendar" ref={this.calendarRef}></div>
                    </div>
                </div>
            </div>
        )
    }
}

Calendar.propTypes = {
    dayClick: PropTypes.func,
    eventClick: PropTypes.func,
    fetchTimeTable: PropTypes.func,
    i18n: PropTypes.object,
    settings: PropTypes.object,
    organizations: PropTypes.array,
    selectedCalendars: PropTypes.array,
    setUserSelections: PropTypes.func,
    timeTables: PropTypes.object,
    prices: PropTypes.any,
    userSelections: PropTypes.object
}

export default Calendar
