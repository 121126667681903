import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import DateRangePick from "./DateRangePick";
import moment from 'moment'
import PropTypes from "prop-types";
import {InformationTooltip} from "../common/tooltips";

class HolidayBooking extends Component {


    state = {
        dates: [],
    };

    constructor(props) {
        super(props);
    }

    handleDates = NewDates => {
        this.setState({dates: NewDates})
    }


    getDates = () => {
        let result = [];
        for (let i in this.props.timeTables.data) {
            if (this.props.timeTables.data[i].not_available === true && this.props.timeTables.data[i].start_time === "00:00:00" && this.props.timeTables.data[i].end_time === "23:59:00") {
                result.push(<div key={i}>
                    {moment(this.props.timeTables.data[i].start).format("ddd DD MMM YYYY") != moment(this.props.timeTables.data[i].end).format("ddd DD MMM YYYY") ?
                        moment(this.props.timeTables.data[i].start).format("ddd DD MMM") + " - " + moment(this.props.timeTables.data[i].end).format("ddd DD MMM YYYY")
                        : moment(this.props.timeTables.data[i].start).format("ddd DD MMM YYYY")}
                    <br/>
                    <p className="holiday-label">{$$("holiday")}</p>
                    {!(this.props.management && this.props.timeTables.data[i].organization_id === null) && !this.props.disableEdit &&
                        <a onClick={() => {
                            this.props.editHoliday(this.props.timeTables.data[i])
                        }} className="holiday-label"> {$$("edit")}</a>
                    }
                    <hr className="hr"/>
                </div>)
            }
        }
        return result
    }


    createOption = option => (
        <option
            value={option.value}
            key={option.name}
        >
            {option.name}
        </option>
    );

    getCalendarOptions = () => {
        let values = [{name: $$('provider_calendars_all'), value: ""}];

        for (let i in this.props.organizations) {
            values.push({name: this.props.organizations[i].name, value: this.props.organizations[i].id});
        }
        return values;
    }

    createCalendarOptions = () => this.getCalendarOptions().map(this.createOption);


    getHeader = () => {
        return (
            <div className={"provider-cal"}>
                <div className="d-flex provider-cal-header">
                    <div className="bold">{$$("holidays_label")}
                        &nbsp;
                        <InformationTooltip>
                            {$$('holiday_info_text')}
                        </InformationTooltip></div>
                    {!this.props.disableEdit && <DateRangePick
                        i18n={this.props.i18n}
                        management={this.props.management}
                        organizations={this.props.organizations}
                        createCalendarOptions={this.createCalendarOptions}
                        onSetDates={this.handleDates}
                        settings ={this.props.settings}
                        bookTimeOff={this.props.bookTimeOff}
                        providerId={this.props.providerId}
                    />}
                </div>
                <hr className="hr"/>
                {this.getDates()}
            </div>
        )
    }


    render() {
        return (
            <div className='stacked-right-column dashboard-card'>
                {this.getHeader()}
            </div>
        )
    }
}

HolidayBooking.propTypes = {
    bookTimeOff: PropTypes.func,
    i18n: PropTypes.object,
    organizations: PropTypes.array,
    editHoliday: PropTypes.func,
    providerId: PropTypes.string,
    management: PropTypes.bool,
    settings: PropTypes.object,
    timeTables: PropTypes.object
}

export default HolidayBooking
