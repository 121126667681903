import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import {infoUtils} from "../../utils/infoUtils";
import no_data from "../../resources/images/no_data.png";
import {formatDate} from "../../utils/converter";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {downloadCardiacReport} from "../../service/summary_service";
import {downloadUtils} from "../../utils/downloadUtils";
import DownloadEditDeleteButtons from "../shared/DownloadEditDeleteButtons";
import {dateTimeUtils} from "../../utils/dateTimeUtils";


const classificationStringsMap = {
    'ACC_AHA': 'ACC/AHA',
    'ESC_ESH': 'ESC/ESH',
    'JNC': 'JNC8',
    'NICE': 'NICE 2019',
    'CANADA': 'Hypertension Canada'
};

export class CardiacStats extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        this.onRangeUpdate({})
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onRangeUpdate(prevProps);
    }

    cardiacStats = async (start, end) => {
        let params = {
            after_date_time: start,
            before_date_time: end
        };

        let healthIssueIds = this.props.selectedUser.health_issues.map(h => h.id);
        //if (healthIssueIds.length > 0) {
        params.healthIssueIds = healthIssueIds;
        this.setState({loading: true})
        await this.props.fetchStats(this.props.selectedUser.id, params).then(() => {
            this.setState({loading: false})
        });
        //}
    }

    onRangeUpdate = (prevProps) => {
        if (this.props.selectedStart && this.props.selectedEnd && (this.props.selectedStart !== prevProps.selectedStart || this.props.selectedEnd !== prevProps.selectedEnd)) {
            this.cardiacStats(this.props.selectedStart, this.props.selectedEnd)
        }
    }

    downloadReport = () => {
        // eslint-disable-next-line no-undef
        let data = _.cloneDeep(this.props.stats.stats);
        data.categoryName = $$(data.category);
        data.classificationMethodName = this.mapMethodToString(data.classificationMethod);

        let obj = {
            reportTitle: $$("cardiac_report_title"),
            patientName: this.props.selectedUser.fullname,
            fromDate: dateTimeUtils.getFormattedDateWithTime(this.state.selectedStart),
            toDate: dateTimeUtils.getFormattedDateWithTime(this.state.selectedEnd),
            datetime: dateTimeUtils.getFormattedDateWithTime(new Date()),
            data: data
        }
        downloadCardiacReport(this.props.i18n.lang, obj).then((file) => {
            downloadUtils.download(file, "Cardiac Report  - " +
                this.props.selectedUser.fullname +
                " - " +
                obj.fromDate +
                " to " +
                obj.toDate +
                ".pdf");
        });
    }

    result = () => {
        if (!this.state.loading && (this.props.stats.stats.list && this.props.stats.stats.list.length > 0)) {
            return this.renderPage()
        } else if (!this.state.loading && (this.props.stats.request.finished || this.props.selectedUser.health_issues.length === 0)) {
            let emptyStatsResultObj = {
                imgClass: 'no-lab-results-img',
                primaryLabelClass: 'no-lab-results-primary-label',
                secondaryLabelClass: 'no-lab-results-secondary-label',
                src: no_data,
                primaryLabel: $$('no_records_found'),
                secondaryLabel: ''
            }
            return infoUtils.noData(emptyStatsResultObj);
        } else {
            return <div className={"report-loader"}/>
        }
    }

    renderCCTable = () => {
        let iToKey = ['Systolic', 'Diastolic', 'pulse_pressure_label', 'mean_arterial_pressure_label']
        return <table className="table table-striped">
            <thead>
            <tr>
                <th/>
                <th width="200px" className="text-center">{$$('table_header_low_label')}</th>
                <th width="200px" className="text-center">{$$('table_header_normal_label')}</th>
                <th width="200px" className="text-center">{$$('table_header_high_label')}</th>
            </tr>
            </thead>
            <tbody>
            {this.props.stats.stats.categoryCounts.map((row, i) => {
                return <tr key={i}>
                    <td>{$$(iToKey[i])}</td>
                    {row.map((val, j) => {
                        return <td className="text-center" key={j}>
                            {val === 0 ? '-' : val}
                        </td>
                    })}
                </tr>
            })}
            </tbody>
        </table>
    }

    renderMinMaxAvgTable = () => {
        let iToKey = ['Systolic', 'Diastolic', 'Pulse', 'pulse_pressure_label', 'mean_arterial_pressure_label']
        return <table className="table table-striped">
            <thead>
            <tr>
                <th/>
                <th width="200px" className="text-center">{$$('table_header_min_label')}</th>
                <th width="200px" className="text-center">{$$('table_header_max_label')}</th>
                <th width="200px" className="text-center">{$$('table_header_avg_label')}</th>
            </tr>
            </thead>
            <tbody>
            {this.props.stats.stats.minMaxAvg.map((row, i) => {
                return <tr key={i}>
                    <td>{$$(iToKey[i])}</td>
                    {row.map((val, j) => {
                        return <td className="text-center" key={j}
                                   style={{color: this.props.stats.stats.minMaxAvgColors[i][j]}}>
                            {val === 0 ? '-' : val}
                        </td>
                    })}
                </tr>
            })}
            </tbody>
        </table>
    }

    renderDailyAvgTable = () => {
        let iToKey = ['Systolic', 'Diastolic', 'Pulse', 'pulse_pressure_label', 'mean_arterial_pressure_label']
        return <table className="table table-striped">
            <thead>
            <tr>
                <th>&nbsp;</th>
                <th width="200px" className="text-center">{$$('table_header_morning_label')}</th>
                <th width="200px" className="text-center">{$$('table_header_afternoon_label')}</th>
                <th width="200px" className="text-center">{$$('table_header_evening_label')}</th>
                <th width="200px" className="text-center">{$$('table_header_night_label')}</th>
            </tr>
            </thead>
            <tbody>
            {this.props.stats.stats.daily.map((row, i) => {
                return <tr key={i}>
                    <td>{$$(iToKey[i])}</td>
                    {row.map((val, j) => {
                        return <td className="text-center" key={j}
                                   style={{color: this.props.stats.stats.dailyColors[i][j]}}>
                            {val === 0 ? '-' : val}
                        </td>
                    })}
                </tr>
            })}
            </tbody>
        </table>
    }

    renderListDaysAvgTable = () => {
        return <table className="table table-striped">
            <thead>
            <tr>
                <th>&nbsp;</th>
                <th width="200px" className="text-center">{$$('table_header_morning_label')}</th>
                <th width="200px" className="text-center">{$$('table_header_afternoon_label')}</th>
                <th width="200px" className="text-center">{$$('table_header_evening_label')}</th>
                <th width="200px" className="text-center">{$$('table_header_night_label')}</th>
            </tr>
            </thead>
            <tbody>
            {this.props.stats.stats.list.map((row, i) => {
                return <tr key={i}>
                    <td>{formatDate(row.date, true)}</td>
                    {row.entry.map((val, j) => {
                        return <td className="text-center" key={j}>
                            {val.sys === 0 ? '-' :
                                <span><span style={{color: val.bpColor}}>{val.sys}/{val.dia}</span><span
                                    className="pl-2">
                                     {val.pulse > 0 &&
                                         <i className='fas fa-heart vitals-logbook-icon-label medrec-grey-2'/>} {val.pulse > 0 ? val.pulse : ""}</span></span>}
                        </td>
                    })}
                </tr>
            })}
            </tbody>
        </table>
    }

    mapMethodToString = (v) => {
        return classificationStringsMap[v];
    }

    renderPage = () => {
        return <div className="cardiac_stats--container">
            <div>
                <span>{$$('classification_method_label')}</span>:&nbsp;
                <span>{this.mapMethodToString(this.props.stats.stats.classificationMethod)}</span>
            </div>
            <div>
                <span>{$$('classification_category_label')}</span>:&nbsp;
                <span style={{color: this.props.stats.stats.categoryColor}}>{$$(this.props.stats.stats.category)}</span>
            </div>
            <div>
                <span>{$$('total_count_measurements_label')}</span>:&nbsp;
                <span>{this.props.stats.stats.count}</span>
            </div>
            <div className="kt-margin-t-45">
                <h4>{$$('category_counts_title')}</h4>
            </div>
            {this.renderCCTable()}
            <div>
                <h4>{$$('min_max_avg_title')}</h4>
            </div>
            {this.renderMinMaxAvgTable()}
            <div>
                <h4>{$$('avg_by_category')}</h4>
            </div>
            {this.renderDailyAvgTable()}
            <div>
                <h4>{$$('daily_avg_title')}</h4>
            </div>
            {this.renderListDaysAvgTable()}
            <div>
                <DownloadEditDeleteButtons
                    handleDownloadClick={this.downloadReport}
                />
                <span className={"user-profile-link"}
                      onClick={this.downloadReport}>{$$("download_cardiac_report")} </span>
            </div>
        </div>
    }

    render() {
        return (<div>
            {this.result()}
        </div>)
    }
}

CardiacStats.propTypes = {
    fetchStats: PropTypes.func,
    i18n: PropTypes.object,
    selectedStart: PropTypes.any,
    selectedEnd: PropTypes.any,
    selectedUser: PropTypes.object,
    settings: PropTypes.any,
    stats: PropTypes.object
}


function mapStateToProps(state) {
    return {
        settings: state.settings.data,
        i18n: state.language.selected
    }
}

export default connect(mapStateToProps, {})(CardiacStats)
