import React, {Component} from 'react'
import AppointmentsListItemForCalendar from './AppointmentsListItemForCalendar'
import {infoUtils} from '../../utils/infoUtils'
import no_appointments from '../../resources/images/no_appointments.png'
import {$$} from '../../helpers/localization'
import moment from 'moment'
import _ from 'underscore';
import PropTypes from "prop-types";


class AppointmentsListForCalendar extends Component {
    state = {
        selectedAppointmentId: ''
    }

    constructor(props) {
        super(props);
        this.index = 0;
    }

    componentWillUnmount() {
        let selectedAppointmentId = '';
        this.setState({selectedAppointmentId});
    }

    componentDidUpdate(prevProps) {
        if ((!prevProps.appointments || (prevProps.appointments && prevProps.appointments.length === 0)) &&
            this.props.appointments && this.props.appointments.length > 0) {
            this.selectFirstAppointment();
        }

        if (prevProps && prevProps.chosenDate !== this.props.chosenDate) {
            let selectedAppointmentId = '';
            this.setState({selectedAppointmentId}, function () {
                this.props.onAppointmentChange({});
            });
        } else if (!this.state.selectedAppointmentId && this.props.appointments && this.props.appointments.length > 0) {
            this.selectFirstAppointment();
        }
    }

    /**
     * Sets the first appointment in the list as the selected one
     */
    selectFirstAppointment() {
        let selectedAppointmentId = this.props.appointments[0].id;
        this.setState({selectedAppointmentId: selectedAppointmentId}, function () {
            this.props.onAppointmentChange(this.props.appointments[0]);
        });
    }

    /**
     * An event handler triggered when a new appointment is choosen
     * from the list of appointments
     *
     * @param {object} appointment - the appointment object
     */
    onAppointmentChange = (appointment) => {
        let selectedAppointmentId = appointment.id;
        this.setState({selectedAppointmentId}, function () {
            this.props.onAppointmentChange(appointment);
        });
    }

    /**
     * Prepares the appointments list item by item
     *
     * @returns {Array} - the list with appointment elements
     */
    getAppointmentsListItems = () => {
        let noAppointmentssObj = {
            imgClass: 'no-pending-appointments-img',
            primaryLabelClass: 'no-pending-appointments-primary-label',
            secondaryLabelClass: '',
            src: no_appointments,
            primaryLabel: $$('no_pending_appointments_primary_label'),
            secondaryLabel: ''
        }

        if (this.props.appointments && this.props.appointments.length > 0) {
            return this.props.appointments.map((a, i) => {
                return <AppointmentsListItemForCalendar key={i}
                                                        appointment={a}
                                                        selectedAppointmentId={this.state.selectedAppointmentId}
                                                        onAppointmentChange={this.onAppointmentChange}
                                                        //selectAppointment={this.props.selectAppointment}
                                                        //selectAppointmentInProgress={this.props.selectAppointmentInProgress}
                                                        onMessagesClick={this.props.onMessagesClick}
                                                        i18n={this.props.i18n}
                                                        cancelAppointment={this.props.cancelAppointment}/>
            });
        } else {
            return infoUtils.noData(noAppointmentssObj);
        }

    }

    render() {
        let lang = _.contains(['en', 'sq'], this.props.i18n) ? 'en' : this.props.i18n;
        if (lang.lang) {
            moment.locale(lang.lang);
        } else {
            moment.locale(lang);
        }

        return (
            <div className="landing-page-appointments-list">
                <div className={"format-date"}>{moment(this.props.chosenDate).format('DD MMMM YYYY')}</div>
                <div className='appointments-card-body'>
                    {this.getAppointmentsListItems()}
                </div>
            </div>
        )
    }
}

AppointmentsListForCalendar.propTypes = {
    appointments:PropTypes.array,
    cancelAppointment:  PropTypes.func,
    chosenDate: PropTypes.string,
    i18n:PropTypes.object,
    onAppointmentChange:  PropTypes.func,
    onMessagesClick:  PropTypes.func,
    selectAppointment:  PropTypes.func,
    selectAppointmentInProgress:  PropTypes.func
}

export default AppointmentsListForCalendar;
