import React, {Component} from 'react'
import classnames from 'classnames';
import PropTypes from "prop-types";


export default class Tab extends Component {

    constructor(props) {
        super(props);
    }

    /**
     * On click handler for the tab button
     *
     * @param {object} e the event object
     */
    onClick = (e) => {
        e.preventDefault();
        this.props.onClick(this.props.tabIndex);
    }

    render() {
        var tabClass = classnames({
            'nav-link': true,
            'active': this.props.isActive
        });

        return (
            <li className="nav-item">
                <a className={tabClass} href="#" onClick={(e) => this.onClick(e)}> {this.props.tabName} </a>
            </li>
        )
    }
}

Tab.propTypes = {
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    tabIndex: PropTypes.number,
    tabName: PropTypes.string,
    tabNumber: PropTypes.number
}
