import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import Select from '../shared/Select'
import PropTypes from "prop-types";


export class HowToTake extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.scheduleTimesPerDay && this.props.howToTake === 'TAKE_X_TIMES') {
            let XHoursTimes;
            let times = this.props.scheduleTimesPerDay;
            XHoursTimes = {
                howToTake: "TAKE_X_TIMES",
                scheduleHours: 0,
                scheduleTimesPerDay: times
            }
            this.props.onChange(XHoursTimes)
        }
        if (this.props.scheduleHours && this.props.howToTake === 'TAKE_EVERY_X_HOURS') {
            let XHours;
            let times = this.props.scheduleHours;
            XHours = {
                howToTake: "TAKE_EVERY_X_HOURS",
                scheduleHours: times,
                scheduleTimesPerDay: 0, //this might not be necessary tbh,
            }
            this.props.onChange(XHours)
        }

    }

    onFrequencyChange = (e) => {
        let XHoursTimes;
        if (e.value === "TAKE_EVERY_X_HOURS") {
            XHoursTimes = {
                howToTake: "TAKE_EVERY_X_HOURS",
                scheduleHours: 8,  //default value,
                scheduleTimesPerDay: 0, //clean the other input since its not in use
            }
        } else { //this is for the other TAKE_X_TIMES
            XHoursTimes = {
                howToTake: "TAKE_X_TIMES",
                scheduleHours: 0,
                scheduleTimesPerDay: 1,
            }
        }
        this.props.onChange(XHoursTimes)
    }


    onXTimesChange = (e) => {
        let XTimes = {
            scheduleHours: 0,
            scheduleTimesPerDay: e.target.value, //this might not be necessary tbh,
        }
        this.props.onChange(XTimes)
    }

    onXHoursChange = (e) => {
        let XHours = {
            scheduleHours: e.target.value,
            scheduleTimesPerDay: 0, //this might not be necessary tbh,
        }
        this.props.onChange(XHours)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.errors?.scheduleTimesPerDay) {
            if (this.scheduleTimesPerDayField) {
                this.scheduleTimesPerDayField.setCustomValidity(this.props.errors?.scheduleTimesPerDay)
                this.scheduleTimesPerDayField.checkValidity();
            }
        } else {
            if (this.scheduleTimesPerDayField) {
                this.scheduleTimesPerDayField.setCustomValidity("")
                this.scheduleTimesPerDayField.checkValidity();
            }
        }

        if (this.props.errors?.scheduleHours) {
            if (this.scheduleHoursField) {
                this.scheduleHoursField.setCustomValidity(this.props.errors?.scheduleHours)
                this.scheduleHoursField.checkValidity();
            }
        } else {
            if (this.scheduleHoursField) {
                this.scheduleHoursField.setCustomValidity("")
                this.scheduleHoursField.checkValidity();
            }
        }
    }

    render() {
        return (
            <div>
                <div className="row form-group prescription-form-label">
                    <div className="col-sm-6">
                        <Select
                            label={$$("frequency")}
                            name="howToTake"
                            options={this.props.getMedicationFrequencyString()}
                            value={this.props.howToTake}
                            onChange={this.onFrequencyChange}
                            placeHolder={$$('schedule')}
                            style={{
                                'minWidth': '100%',
                                'maxWidth': '100%',
                                'zIndex': '1',
                                'opacity': '70%'
                            }}/>
                    </div>
                    {this.props.howToTake && this.props.howToTake === 'TAKE_X_TIMES' &&
                    <div className="col-sm-6">
                        <label>{$$("number_times_per_day")}</label>
                        <input type="text" className="form-control" value={this.props.scheduleTimesPerDay}
                               ref = {(ref)=>{this.scheduleTimesPerDayField = ref}}
                               placeholder={$$('frequency_times_day')} name="scheduleTimesPerDay"
                               onChange={this.onXTimesChange} required/>
                    </div>}
                    {this.props.howToTake && this.props.howToTake === 'TAKE_EVERY_X_HOURS' &&
                    <div className="col-sm-6">
                        <label>{$$("frequency_hours")}</label>
                        <input type="text" className="form-control" value={this.props.scheduleHours}
                               ref = {(ref)=>{this.scheduleHoursField = ref}}
                               placeholder={$$('frequency_every_hours')} name="scheduleHours"
                               onChange={this.onXHoursChange} required/>
                    </div>}
                </div>
            </div>
        )
    }
}

HowToTake.propTypes = {
    getMedicationFrequencyString:  PropTypes.func,
    howToTake: PropTypes.string,
    onChange: PropTypes.func,
    scheduleHours: PropTypes.number,
    scheduleTimesPerDay: PropTypes.number
}

export default HowToTake