export const DASHBOARD_NEXT_PATIENT_CHAT = 'DASHBOARD_NEXT_PATIENT_CHAT';
export const DASHBOARD_NEXT_PATIENT_VIDEO = 'DASHBOARD_NEXT_PATIENT_VIDEO';
export const DASHBOARD_NEXT_PATIENT_MED_HISTORY = 'DASHBOARD_NEXT_PATIENT_MED_HISTORY';
export const RETURN_TO_PATIENT_MED_HISTORY = 'RETURN_TO_PATIENT_MED_HISTORY';
export const APPOINTMENTS_APPOINTMENT_CHAT = 'APPOINTMENTS_APPOINTMENT_CHAT';
export const APPOINTMENTS_APPOINTMENT_VIDEO = 'APPOINTMENTS_APPOINTMENT_VIDEO';
export const APPOINTMENTS_APPOINTMENT_EVENT = 'APPOINTMENTS_APPOINTMENT_EVENT';
export const APPOINTMENTS_APPOINTMENT_EVENT_PRELOAD = 'APPOINTMENTS_APPOINTMENT_EVENT_PRELOAD';
export const PATIENTS_PATIENT_CHAT = 'PATIENTS_PATIENT_CHAT';
export const PATIENTS_PATIENT_MED_HISTORY = 'PATIENTS_PATIENT_MED_HISTORY';
export const MESSAGES_PATIENT_MED_HISTORY = 'MESSAGES_PATIENT_MED_HISTORY';