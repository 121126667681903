export const TAKE_X_TIMES = "TAKE_X_TIMES";
export const TAKE_EVERY_X_HOURS = "TAKE_EVERY_X_HOURS";

export const WHEN_NEEDED = "WHEN_NEEDED";
export const DAILY = "DAILY";
export const EVERY_X_DAYS = "EVERY_X_DAYS";
export const SPECIFIC_DAYS_OF_WEEK = "SPECIFIC_DAYS_OF_WEEK";
export const CYCLE = "CYCLE";

export const DURATION_ONGOING = "ONGOING";
export const DURATION_UNTIL_DATE = "UNTIL_DATE";
export const DURATION_FOR_X_DAYS = "FOR_X_DAYS";

export const NONE = "NONE";
export const FORGOT_TO_TAKE = "FORGOT_TO_TAKE";
export const MED_NOT_NEAR_ME = "MED_NOT_NEAR_ME";
export const ASLEEP = "ASLEEP";
export const TOO_BUSY = "TOO_BUSY";
export const OUT_OF_MED = "OUT_OF_MED";
export const SIDE_EFFECTS = "SIDE_EFFECTS";
export const OTHER = "OTHER";
export const EXPENSIVE = "EXPENSIVE";
export const DOSE_NOT_NEEDED = "DOSE_NOT_NEEDED";