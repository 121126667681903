import React, {createContext, useContext, useEffect, useState} from "react";
import {nhisGetNomenclatures} from "../../../../service/nhis_service";
import {sortByLabel, sortByProp, convertToOptions} from "./utils";

export const NhisEReferralContext = createContext(null);

const nomenclatureCodes = [
    "CL005",
    "CL006",
    "CL014",
    "CL021",
    "CL022",
    "CL023",
    "CL029",
    "CL041",
    "CL044",
    "CL050",
    "CL051",
    "CL052",
    "CL053",
    "CL059",
    "CL060",
    "CL062",
    "CL063",
    "CL069",
    "CL102"
];


export function NhisEReferralProvider({children}) {
    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState(false);
    const [nomenclatures, setNomenclatures] = useState();
    const [options, setOptions] = useState();

    const [specialtyOptions, setSpecialtyOptions] = useState();
    const [countryOptions, setCountryOptions] = useState();
    const [rhifAreaNumberOptions, setRhifAreaNumberOptions] = useState();
    const [financingSourceOptions, setFinancingSourceOptions] = useState();
    const [categoryOptions, setCategoryOptions] = useState();
    const [typeOptions, setTypeOptions] = useState();
    const [activityCodeOptions, setActivityCodeOptions] = useState();
    const [activityCodeOptionsFiltered, setActivityCodeOptionsFiltered] = useState();
    const [specializedActivityCodeOptions, setSpecializedActivityCodeOptions] = useState();
    const [examTypeOptions, setExamTypeOptions] = useState();
    const [admissionTypeOptions, setAdmissionTypeOptions] = useState();
    const [directedByOptions, setDirectedByOptions] = useState();
    const [clinicalPathwayOptions, setClinicalPathwayOptions] = useState();
    const [outpatientProcedureOptions, setOutpatientProcedureOptions] = useState();
    const [workIncapacityReasonOptions, setWorkIncapacityReasonOptions] = useState();
    const [workIncapacityAddressTypeOptions, setWorkIncapacityAddressTypeOptions] = useState();
    const [documentTypeOptions, setDocumentTypeOptions] = useState();

    const [countyOptions, setCountyOptions] = useState();
    const [ekatteOptions, setEkatteOptions] = useState();


    useEffect(() => {
        load();
    }, []);

    const load = () => nhisGetNomenclatures(nomenclatureCodes).then((res) => {
        const object = res.reduce(
            (obj, item) => Object.assign(obj, {[item.nomenclatureId]: item}), {});
        setNomenclatures(object);
        const items = Object.getOwnPropertyNames(object);
        const opt = {};
        const toSortbyValue = ['CL069', 'CL014'];
        const unsorted = [];
        for (const item of items) {
            opt[item] = toSortbyValue.includes(item) ? convertToOptions(object[item], sortByProp("value")) : (unsorted.includes(item) ? convertToOptions(object[item], null) : convertToOptions(object[item], sortByProp("label")));
        }

        setOptions(opt);
        setSpecialtyOptions(opt["CL006"].filter(o => o.item.meta.role?.includes('Лекари') || o.value === "0000"))
        setCountryOptions(opt["CL005"]);
        setCountyOptions(opt["CL041"]);
        setEkatteOptions(opt["CL044"]);
        setRhifAreaNumberOptions(opt["CL029"]);
        setFinancingSourceOptions(opt["CL069"]);
        setCategoryOptions(opt["CL014"]);
        setTypeOptions(opt["CL021"]);
        setActivityCodeOptions(opt["CL022"]);
        setActivityCodeOptionsFiltered(opt["CL022"].filter(o => o.item.meta["nhif code"]));
        setSpecializedActivityCodeOptions(opt["CL050"]);
        setExamTypeOptions(opt["CL051"]);
        setAdmissionTypeOptions(opt["CL059"]);
        setDirectedByOptions(opt["CL060"]);
        setClinicalPathwayOptions(opt["CL062"]);
        setOutpatientProcedureOptions(opt["CL063"]);
        setWorkIncapacityReasonOptions(opt["CL052"]);
        setWorkIncapacityAddressTypeOptions(opt["CL053"]);
        setDocumentTypeOptions(opt["CL102"]);
        setLoading(false);
        setError(false);
    }).catch(e => {
        setError(true);
        setLoading(false);
    });

    const reload = () => {
        setLoading(true);
        load()
    }


    return <NhisEReferralContext.Provider
        value={{
            nomenclatures,
            options,
            specialtyOptions,
            countyOptions,
            countryOptions,
            ekatteOptions,
            rhifAreaNumberOptions,
            financingSourceOptions,
            categoryOptions,
            typeOptions,
            activityCodeOptions,
            activityCodeOptionsFiltered,
            specializedActivityCodeOptions,
            examTypeOptions,
            admissionTypeOptions,
            directedByOptions,
            clinicalPathwayOptions,
            outpatientProcedureOptions,
            workIncapacityReasonOptions,
            workIncapacityAddressTypeOptions,
            documentTypeOptions,
            isLoading,
            isError,
            reload
        }}
    >{children}</NhisEReferralContext.Provider>
}

export function useNhisEReferralContext() {
    const context = useContext(NhisEReferralContext);
    if (!context) {
        throw new Error('NhisEReferralContext must be used within a NhisEReferralProvider');
    }
    return context;
}