import useVideoContext from "../videocall/hooks/useVideoContext/useVideoContext";
import useRoomState from "../videocall/hooks/useRoomState/useRoomState";
import React, {useCallback, useState} from "react";
import {Modal} from "react-bootstrap";
import PreJoinScreens from "../videocall/PreJoinScreens/PreJoinScreens";
import useIsTrackEnabled from "../videocall/hooks/useIsTrackEnabled/useIsTrackEnabled";
import useIsTrackSwitchedOff from "../videocall/hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff";
import {VideoControlsOverlay} from "../videocall/VideoControlsOverlay";
import classNames from "classnames";
import Stopwatch from "../videocall/Stopwatch";
import useParticipants from "../videocall/hooks/useParticipants/useParticipants";
import {$$} from "../../helpers/localization";
import {makeStyles} from "@material-ui/core";
import usePublications from "../videocall/hooks/usePublications/usePublications";
import useTrack from "../videocall/hooks/useTrack/useTrack";
import AudioLevelIndicator from "../videocall/AudioLevelIndicator";
import AvatarIcon from "../videocall/icons/AvatarIcon";
import VideoTrack from "../videocall/VideoTrack";
import useRoomName from "../videocall/hooks/useRoomName/useRoomName";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVideo, faArrowAltCircleLeft} from "@fortawesome/free-solid-svg-icons";
import useIsRoomForGroupEvent from "../videocall/hooks/useIsRoomForGroupEvent/useIsRoomForGroupEvent";
import useSelectedParticipant from "../videocall/VideoProvider/useSelectedParticipant/useSelectedParticipant";
import useMainParticipant, {useDominantSpeakerMode} from "../videocall/hooks/useMainParticipant/useMainParticipant";
import useDominantSpeaker from "../videocall/hooks/useDominantSpeaker/useDominantSpeaker";

// eslint-disable-next-line react/prop-types
export function VideoApp({name, roomName, groupEvent, displayName, onHangup, toSplit, toRight, toFull, currentView, hideIfInAnotherCall}) {
    const {error, setError} = useVideoContext();
    const roomState = useRoomState();
    const existingRoomName = useRoomName();

    let roomsMatch = existingRoomName && existingRoomName.split(":")[0] === roomName

    const onCancel = useCallback(() => {
        setError();
        onHangup();
    }, [setError, onHangup])

    return roomState !== 'connected' && roomState !== 'reconnecting' && roomState !== 'reconnected' ?
        <Modal show={true} size="lg">
            <Modal.Body>
                <PreJoinScreens name={name} roomName={roomName} groupEvent={groupEvent} displayName={displayName}
                                onCancel={onCancel} error={error?.code === 53405 ? error : null}/>
            </Modal.Body>
        </Modal>
        :
        <div className="video-container" key="key-video-container">
            {roomsMatch &&
            <Room onHangup={onHangup} toSplit={toSplit} toRight={toRight} toFull={toFull}
                  currentView={currentView}/>}
            {!roomsMatch && !hideIfInAnotherCall && <InAnotherCall/>}
        </div>
}

function InAnotherCall() {
    return <div className="d-flex justify-content-center">
        <div className="qb-video-logo">
            <FontAwesomeIcon icon={faVideo} className="fas fa-camera fa-7x"/>
        </div>
    </div>
}

// eslint-disable-next-line react/prop-types
function Room({onHangup, toSplit, toRight, toFull, currentView}) {
    const context = useVideoContext();
    const localParticipant = (context.room && context.room.localParticipant) || undefined;
    const roomState = useRoomState();
    let audioTrack = context.localTracks.find(track => track.kind === 'audio');
    let isAudioTrackEnabled = useIsTrackEnabled(audioTrack);
    let isAudioTrackSwitchedOff = useIsTrackSwitchedOff(audioTrack);
    let audioMuted = !isAudioTrackEnabled || isAudioTrackSwitchedOff;
    let videoTrack = context.localTracks.find(track => track.kind === 'video');
    let isVideoTrackEnabled = useIsTrackEnabled(videoTrack);
    let isVideoTrackSwitchedOff = useIsTrackSwitchedOff(videoTrack);
    let videoMuted = !isVideoTrackEnabled || isVideoTrackSwitchedOff;
    const isRoomForGroupEvent = useIsRoomForGroupEvent();
    const mainParticipant = useMainParticipant();
    const participants = useParticipants();
    const [dominantSpeakerMode, setDominantSpeakerMode] = useDominantSpeakerMode();
    // eslint-disable-next-line no-unused-vars
    const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
    const [mainParticipantVideoMode, setMainParticipantVideoMode] = useState(isRoomForGroupEvent ? "contain" : "cover")
    return <>
        <div className="videocall-contianer">
            <div className="frames__main">
                {mainParticipant && isRoomForGroupEvent && <div className={classNames("main-participant", {"video-cover": mainParticipantVideoMode === 'cover'})}>
                    {participants.length > 0 && <Participant
                        participant={mainParticipant}
                        isLocalParticipant={localParticipant === mainParticipant} mainParticipant={true}/>}
                    <VideoControlsOverlay modal={true} videoMuted={videoMuted} audioMuted={audioMuted}
                                          onHangup={onHangup}/>
                </div>}
                {roomState === 'connected' &&
                <ParticipantsList localParticipant={localParticipant} mainParticipant={mainParticipant} isRoomForGroupEvent={isRoomForGroupEvent} mainParticipantVideoMode={mainParticipantVideoMode}/>}
                {
                    participants.length === 0 && <div
                        className="waiting-for-participant-message">{$$("please_wait_for_the_other_participants_to_join")}</div>
                }
                {/*{localParticipant && <Participant participant={localParticipant} isLocalParticipant={true}/>}*/}
            </div>
            {!isRoomForGroupEvent &&
            <VideoControlsOverlay modal={true} videoMuted={videoMuted} audioMuted={audioMuted} onHangup={onHangup}/>}
        </div>
        <div className="video-position-buttons">
            {participants.length > 0 && isRoomForGroupEvent &&
                <button className={classNames("btn", {"active": dominantSpeakerMode})} onClick={() => {
                    setDominantSpeakerMode(true);
                    setSelectedParticipant(null);
                }}><span className="fa fa-exchange-alt" /></button>
            }

            <button className={classNames("btn", {"active": mainParticipantVideoMode === "cover"})}
                                            onClick={() => {
                                                mainParticipantVideoMode === "cover" ? setMainParticipantVideoMode("contain") : setMainParticipantVideoMode("cover")
                                            }}><span className={classNames("fa", "fa-search-plus")}></span></button>
            {!isRoomForGroupEvent && <span>
                <button className={classNames("btn", {"active": currentView === '2-col-split'})}
                        onClick={toSplit}><span className="fa fa-border-all"></span></button>
                <button className={classNames("btn", {"active": currentView === '2-col-no-split'})}
                        onClick={toRight}><span className="fa fa-compress-alt"></span></button>
                <button className={classNames("btn", {"active": currentView === 'video-only'})}
                        onClick={toFull}><span className="fa fa-expand"></span></button>
            </span>}

        </div>
        {roomState === 'connected' && <Stopwatch/>}
    </>
}


// eslint-disable-next-line react/prop-types
function ParticipantsList({localParticipant, mainParticipant, isRoomForGroupEvent, mainParticipantVideoMode}) {
    const participants = useParticipants();
    let classes = classNames("participants-list-inner", {"video-contain": !isRoomForGroupEvent && mainParticipantVideoMode === 'contain'});
    return <div className={"participants-list"}>
        <div className={classes}>
            {localParticipant && <Participant participant={localParticipant} isLocalParticipant={true} avatarOnly={localParticipant === mainParticipant && participants && participants.length > 0}/>}
            {
                participants.map((participant) => {
                    return <Participant participant={participant} key={participant.sid}
                                         avatarOnly={participant === mainParticipant && isRoomForGroupEvent}/>
                })
            }
        </div>
    </div>
}


const usePStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    identity: {
        background: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        padding: '0.1em 0.3em 0.1em 0',
        display: 'inline',
        '& svg': {
            marginLeft: '0.3em',
        },
        marginRight: '0',
        alignItems: 'center',
        borderBottomLeftRadius: '8px',
        position: 'absolute',
        bottom: '0',
        left: '0',
        zIndex: '10',
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    infoContainer: {
        position: 'absolute',
        zIndex: 2,
        height: '100%',
        width: '100%',
    },
    reconnectingContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(40, 42, 43, 0.75)',
        zIndex: 1,
    },
    fullWidth: {
        gridArea: '1 / 1 / 2 / 3',
        [theme.breakpoints.down('sm')]: {
            gridArea: '1 / 1 / 3 / 3',
        },
    },
    avatarContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'black',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
        '& svg': {
            transform: 'scale(2)',
        },
    },
    recordingIndicator: {
        position: 'absolute',
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        background: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        padding: '0.1em 0.3em 0.1em 0',
        fontSize: '1.2rem',
        height: '28px',
        [theme.breakpoints.down('sm')]: {
            bottom: 'auto',
            right: 0,
            top: 0,
        },
    },
    circle: {
        height: '12px',
        width: '12px',
        background: 'red',
        borderRadius: '100%',
        margin: '0 0.6em',
        animation: `1.25s $pulsate ease-out infinite`,
    },
    '@keyframes pulsate': {
        '0%': {
            background: `#A90000`,
        },
        '50%': {
            background: '#f00',
        },
        '100%': {
            background: '#A90000',
        },
    },
}));


// eslint-disable-next-line react/prop-types
function Participant({participant, isLocalParticipant, mainParticipant, avatarOnly}) {
    const classes = usePStyles();
    const publications = usePublications(participant);
    const audioPublication = publications.find(p => p.kind === 'audio');
    const audioTrack = useTrack(audioPublication);
    // eslint-disable-next-line react/prop-types
    const pName = useParticipantName({identity: participant.identity})
    const name = isLocalParticipant ? `(${$$("You")})` : pName;
    const videoPublication = publications.find(p => p.kind === 'video');
    const [, setSelectedParticipant] = useSelectedParticipant();
    const [, setDominantSpeakerMode] = useDominantSpeakerMode();
    const isRoomForGroupEvent = useIsRoomForGroupEvent();
    const dominantSpeaker = useDominantSpeaker()
    let namesClass = `${classes.identity} p-names`;
    return <div className={isLocalParticipant ? "local-video p-wrap" : "qb-video p-wrap"}
                onClick={() => {
                    if (isRoomForGroupEvent && !mainParticipant) {
                        setSelectedParticipant(participant);
                        setDominantSpeakerMode(false)
                    }
                }}>
        <div className={namesClass} title={name}>
            <AudioLevelIndicator audioTrack={audioTrack}/>
            {name}
        </div>
        <DominantSpeakerIndicator show={dominantSpeaker === participant} />

        {/*{!mainParticipant && !isLocalParticipant && audioTrack && <AudioTrack track={audioTrack}/>}*/}
        {(!videoPublication || avatarOnly) &&
        <div className="d-flex w-100 h-100 justify-content-center align-items-center avatar-wrap">
            {avatarOnly ? <FontAwesomeIcon icon={faArrowAltCircleLeft} size={"1x"} /> : <span className={"avatar"}><AvatarIcon/></span>}
        </div>}
        {!avatarOnly && publications.map(p => {
            return <Publication publication={p} key={p.kind} isLocal={isLocalParticipant}
                                mainParticipant={mainParticipant} isRoomForGroupEvent={isRoomForGroupEvent}/>
        })}
    </div>
}

// eslint-disable-next-line react/prop-types
function DominantSpeakerIndicator({show}) {
    return <div className={show ? "dominant-speaker-indicator" : "d-none"}></div>
}

function useParticipantName({identity}) {
    let idName = identity.split(":");
    if (idName.length > 1) {
        return idName[1];
    } else {
        return "Unknown";
    }
}

// eslint-disable-next-line react/prop-types
function Publication({publication, isLocal, mainParticipant, isRoomForGroupEvent}) {
    // eslint-disable-next-line no-unused-vars
    const classes = usePStyles();
    let track = useTrack(publication);
    const isTrackEnabled = useIsTrackEnabled(track);
    const isTrackSwitchedOff = useIsTrackSwitchedOff(track);
    if (!track) return null;

    if (track.kind === 'video' && !isLocal && isRoomForGroupEvent) {
        if (mainParticipant) {
            track.setPriority && track.setPriority("high")
        } else {
            track.setPriority && track.setPriority("low")
        }
    }

    switch (track.kind) {
        case 'video':

            if (!isTrackEnabled || isTrackSwitchedOff) {
                return <div className="d-flex w-100 h-100 justify-content-center align-items-center avatar-wrap" >
                    <span className={"avatar"}><AvatarIcon/></span></div>
            }

            return isLocal ? <VideoTrack track={track} isLocal={isLocal} classes="qb-video_source"/> :
                <div className="w-100 h-100">
                    <VideoTrack track={track} isLocal={isLocal} classes={"main_video"}/>
                </div>
        /*case 'audio':
            return <AudioTrack track={track}/>;*/
        default:
            return null;
    }
}