import {SET_USER_SELECTIONS} from './actions';

/**
 * Fetch selected users general practitioner
 *
 * @param {object} userSelections - id of the selected user
 * @returns {function} dispatch function
 */
export function setUserSelections(userSelections) {
    return (dispatch) => {
        dispatch({type: SET_USER_SELECTIONS, result: userSelections})
    }
}
