import React, {Component} from 'react'
import {GetStaffList} from "../../actions/management_actions";
import {connect} from "react-redux";
import {Button, Form, ListGroup, Modal} from "react-bootstrap";
import {$$} from "../../helpers/localization";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import Search from "../shared/Search";
import UserImage from "../shared/UserImage";
import {formatUtils} from "../../utils/formatUtils";
import {isSameDay} from "date-fns";
import moment from 'moment';
import {createAppointment, fetchFirstHour, fetchTimetable} from "../../actions/clinic_actions";
import {ReactComponent as CalendarIcon} from '../../../public/media/icons/calendar-1.svg'
import ReactHorizontalDatePicker from "../shared/ReactHorizontalDatePicker";
import FreeSlotsList from "./FreeSlotsList";
import {OTHER} from "../../constants/appointment_types";
import _ from "underscore";
import {medicalHistoryHelper} from "../../helpers/medical_history_helper";
import {PATIENTS_PATIENT_MED_HISTORY} from "../../constants/pages";
import {Routes} from "../../constants/routes";
import PropTypes from "prop-types";
import {Select} from "../shared/Select";
import {getClinicianNameWithTitle} from "../../utils/getClinicianNameWithTitle";
import {appointmentUtils} from "../../utils/appointmentUtils";
import Phone from "../common/Phone";
import Email from "../common/Email";
import {DateTimePickComponent} from "../appointment/AppointmentsManagement";
import enGB from "date-fns/locale/en-GB";
import bg from "date-fns/locale/bg";
import sq from "date-fns/locale/sq";
import {Permissions} from "../../utils/permissions/types";


class StaffPicker extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {filtered_list: [], filter: "", buttonsDisabled: true, chosenDate: new Date(), openPicker: false}
        let lang = _.contains(['en', 'sq'], this.props.i18n.selected.lang) ? 'en' : this.props.i18n.selected.lang;
        moment.locale(lang);
    }

    componentDidMount() {
        this.props.GetStaffList(this.props.organisationId);
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.users_data !== this.props.users_data) {
            let filteredList = this.filter(this.props.users_data);
            this.setState({filtered_list: filteredList});
        }
        if (prevState.filter !== this.state.filter) {
            this.setState({filtered_list: this.filter(this.props.users_data)})
        }

        if (this.props.i18n.selected.lang !== prevProps.i18n.selected.lang) {
            let lang = _.contains(['en', 'sq'], this.props.i18n.selected.lang) ? 'en' : this.props.i18n.selected.lang;
            moment.locale(lang);
        }


    }

    filter = (list) => {

        let filterOwnOnly = !this.props.permissions.canCreateOthersAppointment()

        if (this.state.filter === "") {
            return list.filter((u) => filterOwnOnly ? u.id === this.props.loggedInUserId : true);
        }

        return list.filter((u => {
            let s = this.state.filter.toLowerCase();
            return u.fullname.toLowerCase().includes(s) || u.email.toLowerCase().includes(s) || (u.phone && u.phone.toLowerCase().includes(s))
        })).filter((u) => filterOwnOnly ? u.id === this.props.loggedInUserId : true);
    }

    onChangeValue = async event => {
        let eventValue = JSON.parse(event.target.value);
        this.setState({selectedExamination: eventValue})
        let clinician = this.state.clinician;
        await this.props.fetchFirstHour(clinician.id, eventValue.location_type, eventValue.organization_id ? eventValue.organization_id : null, eventValue.id);
        await this.props.fetchTimetable(clinician.id, eventValue.location_type, eventValue.organization_id ? eventValue.organization_id : null, eventValue.id);
        this.setAvailableHours()
    }

    createAvailableOptions(timetableAvailableOnly) {
        return [{value: moment().format("YYYY-MM-DD"), text: $$("jump_to_date")},
            ...timetableAvailableOnly.map(day => {
                let momentDate = moment(day.date);
                let isSameYear = momentDate.format('YYYY') === moment().format("YYYY");
                let d = isSameYear ? momentDate.format("D MMM") : momentDate.format("D MMM YYYY");
                return {value: day.date, text: `${d} (${day.count})`}
            })];
    }

    /**
     * An event handler triggered when a new date is choosen
     * from the datepicker
     *
     * @param {Date} date - the new choosen date
     * @param triggeredFromBtn - triggeredFromBtn
     */
    onSelectDate = (date, triggeredFromBtn) => {
        this.setState({chosenDate: date, triggeredFromBtn: triggeredFromBtn, todaySlots: this.getFilteredSlots(date)});
    };

    /**
     * An event handler triggered when 'Today' button is pressed
     *
     */
    selectToday = () => {
        this.onSelectDate(new Date(), true);
    }

    getFirstAvailableTime(o) {
        return o ? moment(o) : o;
    }

    getLocale = () => {
        switch (this.props.i18n.selected.lang) {
            case "en":
                return enGB;
            case "bg":
                return bg;
            case "sq":
                return sq;
            default:
                return enGB;
        }
    }

    getChosenExaminationWithTime = () => {
        let start = this.state.selectedTimeSlot.start;
        let isThisYear = moment(start).format("YYYY") === moment().format("YYYY");
        return <div className="high-shadow-container">
            <div className="d-flex w-100">
                <div className="d-flex">
                    <div className="early-appointment-icon">
                        <CalendarIcon width="23px" height="28px" className="svg-icon"/>
                    </div>
                    <div className="early-appoitment-time align-self-center">
                        <div
                            className="first-time-available">{start ? (isThisYear ? moment(start).format("D MMM, (LT)") : moment(start).format("D MMM, YYYY (LT)")) : ""}</div>
                    </div>
                </div>
                <div className="ml-2 flex-fill text-center">
                    <div
                        className=""> {this.getExaminationName(this.state.selectedExamination)}</div>
                    <div className="capitalized">
                        {$$(this.state.selectedExamination.location_type.toLowerCase()) + ", " + this.props.organisationName}</div>
                </div>
                <div className="ml-2 align-self-center text-right flex-shrink-0" style={{
                    display: "flex",
                    alignSelf: "center"
                }}>{formatUtils.currencyFormat(this.state.selectedExamination.price_cents, this.state.selectedExamination.currency)}</div>
            </div>
        </div>;
    }

    getCreateAppointmentMode = () => {
        let isThisYear = this.state.firstHourAvailable ? moment(this.state.firstHourAvailable).format("YYYY") === moment().format("YYYY") : true;
        return <>
            {/*<div className="examinations-label">
                <span className="ml-2"> <h5>{$$("make_an_appointment_label")}</h5></span>
            </div>*/}
            {this.getChosenExamination()}
            <div className="mt-2 high-shadow-container">

                <div className="mt-3 flex-space-between align-items-center">
                    <div className="early-appointment">
                        <div className="early-appointment-icon">
                            <CalendarIcon width="23px" height="28px" className="svg-icon"/>
                        </div>
                        {this.state.firstHourAvailable &&
                            <div className="early-appoitment-time">
                                <div className="small-gray-text">{$$("first_available_hour_label")}</div>
                                <div
                                    className="first-time-available">{this.state.firstHourAvailable ? (isThisYear ? moment(this.state.firstHourAvailable).format("D MMM, (LT)") : moment(this.state.firstHourAvailable).format("D MMM, YYYY (LT)")) : ""}</div>
                            </div>
                        }
                        {
                            !this.state.firstHourAvailable && <div className="early-appoitment-time">
                                {$$("no_hours_available_primary_label")}
                            </div>
                        }
                    </div>
                    <div>{this.state.firstHourAvailable && <button className="btn btn-primary"
                                                                   disabled={this.state.buttonsDisabled}
                                                                   onClick={
                                                                       () => {
                                                                           this.setState({selectedTimeSlot: this.props.firstHour})
                                                                       }
                                                                   }>
                        {$$("select_first_available_hour")}
                    </button>}

                        <button className="btn btn-primary ml-3" onClick={() => {
                            this.setState({openPicker: true})
                        }}>
                            <span className="fa fa-calendar"/>
                        </button>
                        <div className="hidden">
                            <DateTimePickComponent locale={this.getLocale()} setDate={(d) => {
                                let start = moment(d).startOf('minute').valueOf();
                                let end = start + this.state.selectedExamination.duration_mins * 60 * 1000;
                                this.setState({
                                    selectedTimeSlot: {
                                        start: start,
                                        end: end,
                                        status: 'AVAILABLE'
                                    }, openPicker: false
                                });
                            }}
                                                   open={this.state.openPicker}
                                                   onClose={() => this.setState({openPicker: false})}/>
                        </div>
                    </div>
                </div>
                {!this.state.buttonsDisabled && this.state.timetableData && <div>
                    {this.state.showDropDown && <Select
                        name="organization"
                        options={this.getOrganizationOptions(this.props.clinician.organizations)}
                        value={this.props.selectedDropDownValue}
                        onChange={this.onSelectChange}
                        placeHolder={$$('organization_label')}/>
                    }
                    {this.state.firstHourAvailable &&
                        <>
                            <div className="mt-5">
                                <ReactHorizontalDatePicker
                                    bookAppointmentPanel={true}
                                    selectedDay={this.onSelectDate}
                                    enableScroll={true}
                                    enableDays={appointmentUtils.FUTURE_DAYS_TO_FETCH}
                                    i18n={this.props.i18n.selected.lang}
                                    chosenDate={this.state.chosenDate}
                                    triggeredFromBtn={this.state.triggeredFromBtn}
                                    slots={this.state.timetableData}
                                    selectToday={this.selectToday}>
                                    {this.state.firstHourAvailable && <Select
                                        options={this.state.availableDaysOptions}
                                        onChange={({name, value}) => {
                                            this.onSelectDate(new Date(value), true);
                                        }}
                                    />}
                                </ReactHorizontalDatePicker>
                            </div>
                            <FreeSlotsList
                                slots={this.state.todaySlots}
                                clinician={this.props.clinician}
                                selectedPrice={this.state.selectedExamination}
                                onSelect={(slot => this.setState({selectedTimeSlot: slot}))}
                            />
                        </>
                    }
                </div>
                }
            </div>
        </>
    }

    getChosenExamination = () => {
        return <div className="high-shadow-container">
            <div className="d-flex w-100">
                <div className="" style={{marginTop: "0.8rem"}}>
                    <div className="outer-circle">
                        <div className="inner-circle">
                        </div>
                    </div>
                </div>
                <div className="ml-2 flex-fill">
                    <div
                        className=""> {this.getExaminationName(this.state.selectedExamination)}</div>
                    <div className="capitalized">
                        {$$(this.state.selectedExamination.location_type.toLowerCase()) + ", " + this.props.organisationName}</div>
                </div>
                <div className="ml-2 align-self-center text-right flex-shrink-0" style={{
                    display: "flex",
                    alignSelf: "center"
                }}>{formatUtils.currencyFormat(this.state.selectedExamination.price_cents, this.state.selectedExamination.currency)}</div>
            </div>
        </div>;

    }

    onSelectChange = async e => {
        let organization = JSON.parse(e.value);
        this.setState(
            {
                selectedDropDownValue: organization
            }
        )
        await this.props.fetchFirstHour(this.props.clinician.id, this.state.selectedExamination.location_type, organization.id, this.state.selectedExamination.id);
        await this.props.fetchTimetable(this.props.clinician.id, this.state.selectedExamination.location_type, organization.id, this.state.selectedExamination.id);

        for (const [key, value] of Object.entries(this.props.timeTableEntries)) {
            if (key === organization.id) {
                this.setState({timetableData: value}, () => {
                    let firstHourAvailable = this.getFirstAvailableTime(this.props.firstHour.start);
                    this.onSelectDate(firstHourAvailable, false)
                    this.setState({
                        buttonsDisabled: false,
                        firstHourAvailable: firstHourAvailable,
                        todaySlots: this.getFilteredSlots(firstHourAvailable)
                    });
                })
                break;
            }
        }
    }

    /**
     * Filters the slots based on the selected date
     *
     * @returns {Array} the list of filtered slots
     */
    getFilteredSlots = (date) => {
        let dateToFilter = date ? date : this.state.chosenDate;
        let todaySlots = null;
        if (this.state.timetableData != null && this.state.timetableData.data != null) {
            for (let i = 0; i < this.state.timetableData.data.length; i++) {
                if (isSameDay(moment(this.state.timetableData.data[i].date).toDate(), dateToFilter)) {
                    todaySlots = this.state.timetableData.data[i].slots
                    break;
                }
            }
        }
        return todaySlots;
    }

    getExaminations = (clinician) => {
        if (clinician.prices !== undefined) {
            let pricesList = clinician.prices.filter(h => {
                return this.props.organisationId === h.organization_id && !appointmentUtils.isPriceForConsultation(h)
            }).map((h, idx) => {
                return <label className="mb-4 w-100 custom-control custom-radio" key={idx}>
                    <input type="radio" className="custom-control-input" value={JSON.stringify(h)} name="prices"
                           onChange={this.onChangeValue}/>
                    <div className="row w-100 custom-control-label" style={{marginLeft: "3px"}}>
                        <div className="col-8">
                            <div className=""> {this.getExaminationName(h)}</div>
                            <div className="capitalized price-type">
                                {$$(h.location_type.toLowerCase()) + ', ' + this.props.organisationName}</div>
                        </div>
                        <div className="col-4">{formatUtils.currencyFormat(h.price_cents, h.currency)}</div>
                    </div>
                </label>
            });

            return <div className='mt-2'>
                {pricesList.length > 0 && <h6 className='mb-3'>{$$("select_appointment_type_message")}</h6>}
                {pricesList.length === 0 && <h6 className='mb-3 text-danger'>{$$("price_list_is_empty")}</h6>}
                <div>{pricesList}</div>
            </div>;
        }
    }

    getExaminationName(e) {
        return e.encounter_type === OTHER ? e.name : $$(e.encounter_type.toLowerCase());
    }

    onNoteInputChange = (e) => {
        this.setState({note: e.target.value})
    }

    getCreateAppointmentFinalStep = () => {
        return <div>
            <div className="mb-2">
                <span className="d-flex high-shadow-container">
                    <div className="mr-1">
                        <UserImage userID={this.props.patient.id}
                                   classnames="patient-img"/>
                    </div>
                    <div className="flex-fill align-self-center">
                        <h5>{this.props.patient.fullname}</h5>
                    </div>
                    <div className="flex-fill text-right ml-2 align-self-center">
                        <Email object={this.props.patient}/>
                        <Phone object={this.props.patient}/>
                    </div>
                </span>
            </div>
            <div className="form-group">
                {this.getChosenExaminationWithTime()}
            </div>
            <div className="form-group">
                {this.state.fullname && <label>{$$('note_label')}</label>}
                <textarea
                    className="form-control"
                    value={this.state.note || ""}
                    placeholder={$$('note_label')}
                    name="note"
                    onChange={this.onNoteInputChange}
                />
            </div>
            <div>
                <Form.Check
                    className={'custom-control-inline flex-grow-1 flex-shrink-1'}
                    id={'force-create-appointment-check'}
                    checked={!!this.state.forceCreateAppointment}
                    value={!!this.state.forceCreateAppointment}
                    type={"checkbox"}
                    custom
                    label={$$("force_create_appointment")}
                    onChange={() => {
                        this.setState({
                            forceCreateAppointment:!this.state.forceCreateAppointment
                        })
                    }}/>
            </div>
        </div>
    }

    setAvailableHours = () => {
        let firstHourAvailable = this.getFirstAvailableTime(this.props.firstHour.start);
        this.onSelectDate(firstHourAvailable, false)
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(this.props.timeTableEntries)) {
            this.setState({showDropDown: false})
            let timetableAvailableOnly = value.data.filter(o => {
                return o.slots.find(slot => slot.status === 'AVAILABLE') !== undefined;
            }).map(day => {
                return {date: day.date, count: day.slots.filter(slot => slot.status === 'AVAILABLE').length}
            });
            let availableOptions = this.createAvailableOptions(timetableAvailableOnly)
            this.setState({
                timetableData: value,
                timetableAvailableOnly: timetableAvailableOnly,
                availableDaysOptions: availableOptions
            }, () => {
                this.setState({
                    buttonsDisabled: false,
                    firstHourAvailable: firstHourAvailable,
                    todaySlots: this.getFilteredSlots(firstHourAvailable)
                });
            })
        }
    }

    getPhoneNumbersNodes(u) {
        if (u.phone_numbers && u.phone_numbers.length > 0) {
            return <div className="d-inline-block ml-2"><i className="fa fa-phone"/>
                {u.phone_numbers.join(", ")}
            </div>
        }
        return null;
    }

    render() {
        return <Modal show={this.props.show} onHide={this.props.onHide} backdrop={"static"}
                      dialogClassName="max-width-800px">
            <Modal.Header closeButton>
                <Modal.Title>
                    {
                        this.state.clinician && <div className="medrec-default-text-color">
                            <span className="d-flex">
                                <div className="mr-1">
                                    <UserImage userID={this.state.clinician.id}
                                               classnames="patient-img"/>
                                </div>
                                <div>
                                    <div className="h5">{getClinicianNameWithTitle(this.state.clinician)}</div>
                                    <div className="small"><i
                                        className="fa fa-envelope"/> {this.state.clinician.email} {this.getPhoneNumbersNodes(this.state.clinician)}</div>
                                </div>
                            </span>
                        </div>
                    }
                    {
                        !this.state.clinician && <div className="medrec-default-text-color">
                            {$$("choose_clinician_modal_title")}
                        </div>
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.state.clinician && !this.state.selectedExamination && this.getExaminations(this.state.clinician)}
                {!this.state.clinician &&
                    <div>
                        <div className="mb-3">
                            <Search filterUsers={(s) => {
                                this.setState({filter: s})
                            }} placeholder={$$("search_for_staff")}/>
                        </div>
                        <ListGroup style={{maxHeight: "300px", overflowY: "auto"}}>
                            {
                                this.state.filtered_list.map(u => {
                                    /* eslint-disable no-unused-vars */
                                    return <ListGroupItem action={true} key={u.id}
                                                          onClick={() => this.setState({clinician: u})}>
                                    <span className="d-flex medrec-default-text-color">
                                        <div className="mr-1">
                                            <UserImage userID={u.id}
                                                       classnames="patient-img"/>
                                        </div>
                                        <div>
                                            <div className="h5">{getClinicianNameWithTitle(u)}</div>
                                            <div><i
                                                className="fa fa-envelope"/> {u.email} {this.getPhoneNumbersNodes(u)}</div>
                                        </div>
                                    </span>
                                    </ListGroupItem>
                                    /* eslint-enable no-unused-vars */
                                })
                            }
                        </ListGroup>
                    </div>
                }

                {!this.state.selectedTimeSlot && !this.state.buttonsDisabled && this.getCreateAppointmentMode()}

                {this.state.clinician && this.state.selectedTimeSlot && this.state.selectedExamination && !this.state.create_appointment_request && this.getCreateAppointmentFinalStep()}

                {this.state.create_appointment_request === 'success' &&
                    <div className="text-success">{$$("appointment_created")}</div>}
                {this.state.create_appointment_request === 'failure' &&
                    <div className="text-danger">{$$("appointment_creation_failed")} {this.state.starting_time_unavailable ? $$("appointment_starting_time_unavailable") : ""}</div>}
            </Modal.Body>
            <Modal.Footer>
                <div className="flex-grow-1 flex-shrink-1">
                    {this.state.clinician && <Button type={"button"} variant={"secondary"}
                                                     disabled={this.state.create_appointment_request === 'sent' || this.state.create_appointment_request === 'success' || this.state.disableCancel}
                                                     onClick={async () => {
                                                         if (this.state.disabledCancel) {
                                                             return;
                                                         }
                                                         if (this.state.create_appointment_request === 'failure') {
                                                             this.setState({disableCancel:true});
                                                             await this.props.fetchFirstHour(this.state.clinician.id, this.state.selectedExamination.location_type, this.props.organisationId, this.state.selectedExamination.id);
                                                             await this.props.fetchTimetable(this.state.clinician.id, this.state.selectedExamination.location_type, this.props.organisationId, this.state.selectedExamination.id);
                                                             this.setAvailableHours()
                                                             this.setState({disableCancel:false});
                                                         }
                                                         if (this.state.selectedTimeSlot) {
                                                             this.setState({
                                                                 selectedTimeSlot: undefined,
                                                                 create_appointment_request: undefined
                                                             })
                                                         } else if (this.state.selectedExamination) {
                                                             this.setState({
                                                                 selectedExamination: undefined,
                                                                 buttonsDisabled: true
                                                             })
                                                         } else {
                                                             this.setState({clinician: undefined})
                                                         }
                                                     }}>{$$("back")}</Button>}
                </div>

                {!this.state.create_appointment_request &&
                    <Button variant={this.props.confirmBtnClass ? this.props.confirmBtnClass : "primary"}
                            disabled={!this.state.clinician || !this.state.selectedExamination || !this.state.selectedTimeSlot}
                            onClick={() => {
                                this.setState({create_appointment_request: 'sent'})
                                let pay_before_timestamp = appointmentUtils.calculatePayBeforeTimestamp(this.state.selectedTimeSlot.start, this.state.selectedExamination.price_cents);
                                this.props.createAppointment(this.state.clinician, this.props.patient, this.state.selectedExamination, this.state.selectedTimeSlot, this.state.note, this.props.organisationId, pay_before_timestamp, this.state.forceCreateAppointment).then(() => {
                                    this.setState({create_appointment_request: 'success'});
                                }).catch(async e => {
                                    console.error(e);
                                    if (e.message === 'Starting time is unavailable') {
                                        this.setState({starting_time_unavailable: true})
                                    } else {
                                        this.setState({starting_time_unavailable: false})
                                    }
                                    this.setState({create_appointment_request: 'failure'})
                                });
                            }}>
                        {this.props.confirmBtnLabel ? this.props.confirmBtnLabel : $$('save_btn_label')}
                    </Button>
                }
                <Button variant="secondary" onClick={() => {
                    if (this.state.create_appointment_request === 'success')
                        medicalHistoryHelper.prepareMedicalHistoryData(PATIENTS_PATIENT_MED_HISTORY, this.props.patient, null, Routes.MANAGE_PATIENT_PAGE);
                    else {
                        this.props.onHide()
                    }
                }}
                        disabled={this.state.create_appointment_request === 'sent'}>
                    {this.props.cancelBtnLabel ? this.props.cancelBtnLabel : $$('close_btn_label')}
                </Button>

            </Modal.Footer>
        </Modal>
    }
}


function mapStateToProps(state) {
    return {
        organisationName: state.management.selectedOrganisation.name,
        organisationId: state.management.selectedOrganisation.id,
        users_data: state.management.staff,
        formDisabled: state.formInteractions,
        firstHour: state.firstAvailable.data,
        timeTableEntries: state.providerTimetable.entries,
        i18n: state.language,
        permissions: Permissions.fromOrg(state.userInfo.data.organizations.find(o => o.id === state.management.selectedOrganisation.id)),
        loggedInUserId: state.userInfo.data.id
    }
}

StaffPicker.propTypes = {
    cancelBtnLabel: PropTypes.string,
    users_data: PropTypes.array,
    i18n: PropTypes.any,
    selectedDropDownValue: PropTypes.any,
    firstHour: PropTypes.object,
    clinician: PropTypes.object,
    timeTableEntries: PropTypes.any,
    GetStaffList: PropTypes.func,
    fetchFirstHour: PropTypes.func,
    fetchTimetable: PropTypes.func,
    organisationName: PropTypes.string,
    confirmBtnClass: PropTypes.any,
    show: PropTypes.bool,
    organisationId: PropTypes.any,
    confirmBtnLabel: PropTypes.string,
    patient: PropTypes.object,
    onHide: PropTypes.func,
    createAppointment: PropTypes.func,
    permissions: PropTypes.object,
    loggedInUserId: PropTypes.string
};


const mapDispatchToProps = {
    GetStaffList,
    fetchFirstHour,
    fetchTimetable,
    createAppointment
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffPicker)
