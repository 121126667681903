import React from 'react'
import SettingsPage from './components/settings/SettingsPage'
import {Redirect, Route, Router, Switch} from 'react-router-dom'
import {Routes} from './constants/routes';
import RouteWrapper from './components/wrapper/RouteWrapper'
import history from './helpers/history'
import {LayoutContextProvider} from "./_metronic";
import {connect} from 'react-redux'
import UserProfile from './components/profile/UserProfile'
import MyDocuments from './components/documents/MyDocuments'
import Appointments from './components/appointments/Appointments'
import Patients from './components/patients/Patients'
import Password from './components/password/Password';
import AuthPage from "./components/login/AuthPage";
import MedHistoryPage from "./components/History/MedHistoryPage";
import AppointmentPage from "./components/appointment/AppointmentPage";
import WorkTimePage from "./components/work-time/WorkTimePage";
import AllNotifications from './components/notifications/AllNotifications'
import PriceSettings from "./components/price-settings/PriceSettings";
import Dashboard from "./components/main/Dashboard";
import PropTypes from "prop-types";
import SelectPracticePage from "./components/select-practice/SelectPracticePage";
import ManagementDashboard from "./components/management_dashboard/ManagementDashboard";
import StaffPage from "./components/staff/StaffPage";
import ManagerPatients from "./components/patient-management/ManagerPatients";
import PatientManagement from "./components/patient-management/PatientPage";
import AccountingPage from "./components/accounting/AccountingPage";
import useConnectionOptions from "./components/videocall/utils/useConnectionOptions/useConnectionOptions";
import {VideoProvider} from "./components/videocall/VideoProvider";
import AppointmentsManagement from "./components/appointment/AppointmentsManagement";
import GroupAppointmentPage from "./components/appointment/GroupAppointmentPage";
import Select2FAComponent from "./components/login/TwoFaComponent";
import SecurityPage from "./components/security/SecurityPage";
import Consultations from "./components/consultations/Consultations";
import ChatPage from "./components/messages/ChatPage";
import InvitationPage from "./components/InvitationPage";

class App extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Router history={history}>
                <LayoutContextProvider history={history} menuConfig={this.props.menuConfig} lang={this.props.lang}>
                    <VideoContextProvider>
                        <Select2FAComponent/>
                        <Switch>
                            <RouteWrapper isPrivate={false} path={Routes.LOGIN} component={AuthPage}/>
                            <RouteWrapper isPrivate={false} path={Routes.REGISTER} component={AuthPage}/>
                            <RouteWrapper isPrivate={false} path={Routes.INVITATION} component={InvitationPage}/>
                            <Route exact path='/' render={() => (
                                <Redirect
                                    to={Routes.DASHBOARD}
                                />
                            )}/>
                            <RouteWrapper isPrivate={true} path={Routes.INVITATION_LOGGED_IN} administrativeRoute={true} clinicRoute={true} component={InvitationPage}/>
                            <RouteWrapper isPrivate={true} managementLogin={true} path={Routes.SELECT_PRACTICE}
                                          component={SelectPracticePage} administrativeRoute={true}/>
                            <RouteWrapper isPrivate={true} path={Routes.DASHBOARD} component={Dashboard}/>
                            <RouteWrapper isPrivate={true} path={Routes.MANAGEMENT_DASHBOARD}
                                          component={ManagementDashboard} administrativeRoute={true}/>
                            <RouteWrapper isPrivate={true} path={Routes.STAFF}
                                          component={StaffPage} administrativeRoute={true}/>
                            <RouteWrapper isPrivate={true} path={Routes.PRICE} component={PriceSettings}/>
                            <RouteWrapper isPrivate={true} path={Routes.SETTINGS} component={SettingsPage}/>
                            <RouteWrapper isPrivate={true} path={Routes.PROFILE} component={UserProfile} administrativeRoute={true} clinicRoute={true} />
                            <RouteWrapper isPrivate={true} path={Routes.MY_DOCUMENTS} component={MyDocuments}/>
                            <RouteWrapper isPrivate={true} path={Routes.MY_APPOINTMENTS} component={Appointments}/>
                            <RouteWrapper isPrivate={true} path={Routes.CONSULTATIONS} component={Consultations}/>
                            <RouteWrapper isPrivate={true} path={Routes.PATIENTS} component={Patients}/>
                            <RouteWrapper isPrivate={true} path={Routes.CHANGE_PASSWORD} component={Password} administrativeRoute={true} clinicRoute={true} />
                            <RouteWrapper isPrivate={true} path={Routes.SECURITY} component={SecurityPage} administrativeRoute={true} clinicRoute={true} />
                            <RouteWrapper isPrivate={true} path={Routes.MESSAGES} component={ChatPage}/>
                            <RouteWrapper isPrivate={true} path={Routes.HISTORY} component={MedHistoryPage}/>
                            <RouteWrapper isPrivate={true} path={Routes.APPOINTMENT} component={AppointmentPage}/>
                            <RouteWrapper isPrivate={true} path={Routes.WORK_TIME} component={WorkTimePage}/>
                            <RouteWrapper isPrivate={true} path={Routes.NOTIFICATIONS} component={AllNotifications}/>
                            <RouteWrapper isPrivate={true} path={Routes.GROUP_APPOINTMENT_PAGE}
                                          component={GroupAppointmentPage}/>
                            <RouteWrapper isPrivate={true} path={Routes.MANAGE_PATIENTS_PAGE}
                                          component={ManagerPatients} administrativeRoute={true}/>
                            <RouteWrapper isPrivate={true} path={Routes.MANAGE_PATIENT_PAGE}
                                          component={PatientManagement} administrativeRoute={true}/>
                            <RouteWrapper isPrivate={true} path={Routes.MANAGE_ACCOUNTING_PAGE}
                                          component={AccountingPage} administrativeRoute={true}/>
                            <RouteWrapper isPrivate={true} path={Routes.MANAGE_APPOINTMENTS}
                                          component={AppointmentsManagement} administrativeRoute={true}/>
                        </Switch>
                    </VideoContextProvider>
                </LayoutContextProvider>
            </Router>
        );
    }
}

App.propTypes = {
    menuConfig: PropTypes.any,
    lang: PropTypes.any
};


function mapStateToProps(state) {
    return {
        menuConfig: state.builder.menuConfig,
        lang: state.language.selected
    }
}

export default connect(mapStateToProps, null)(App);


function VideoContextProvider({children}) {
    let options = useConnectionOptions();
    return <VideoProvider options={options} onError={(error) => {
        console.log("Error occurred:", error.code);
    }}>
        {children}
    </VideoProvider>
}