/* eslint-disable no-undef */
let languages = require("@cospired/i18n-iso-languages");
languages.registerLocale(require("./languages/en.json"));
languages.registerLocale(require("./languages/bg.json"));
//languages.registerLocale(require("./languages/sq.json"));
/* eslint-enable no-undef */

export function languageOptions(language) {
    if (!language) {
        language = 'EN';
    }
    return Object.entries(languages.getNames(language)).map(([key, value]) => {
        return {value: key.toLowerCase(), text: value}
    });
}