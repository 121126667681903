import React, {Component} from 'react'
import Symptoms from './Symptoms'
import Medications from './Medications'
import Logbook from './Logbook'
import PropTypes from "prop-types";


export class VitalsLogbookEntryDetails extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className='vitals-logbook-entry-cols-padding'>
                    <hr/>
                </div>
                <div className='vitals-logbook-padding vitals-logbook-entries-container'>
                    {this.props.symptoms && this.props.symptoms.length > 0 &&
                    <div className='vitals-logbook-entry'>
                        <Symptoms symptoms={this.props.symptoms}
                                  nomenclature={this.props.nomenclature.data.nomenclature}
                                  i18n={this.props.i18n}/>
                    </div>
                    }
                    {this.props.medications && this.props.medications.length > 0 &&
                    <div className='medication-entry-container'>
                        <Medications medications={this.props.medications}/>
                    </div>
                    }
                    {(this.props.symptoms && this.props.symptoms.length > 0) ||
                    (this.props.medications && this.props.medications.length > 0) &&
                    <br/>
                    }
                </div>
                {this.props.logbook && this.props.logbook.length > 0 &&
                <div className='vitals-logbook-padding'>
                    <Logbook logbook={this.props.logbook}
                             settings={this.props.settings}/>
                </div>
                }
            </div>
        )
    }
}

VitalsLogbookEntryDetails.propTypes = {
    i18n: PropTypes.object,
    logbook: PropTypes.array,
    medications: PropTypes.array,
    nomenclature: PropTypes.object,
    settings: PropTypes.object,
    symptoms: PropTypes.array
}

export default VitalsLogbookEntryDetails